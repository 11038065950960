<template>
    <div class="popup confirm two" v-if="props.isShowPopup">
        <div class="popup-inner">
            <p v-html="props.text" class="confirm-text"></p>
            <div class="flex">
                <button class="cancel" @click="close">취소</button>
                <button @click="ok" :class="{'ok':route.name === 'Setting'}">확인</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    text: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["close", "ok"]);

const ok = () => {
    emit("ok");
};

const close = () => {
    emit("close");
};
</script>
