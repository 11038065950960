<template>
    <div class="header">
        <img class="back-btn" src="../assets/images/header-back.png" alt="" :class="{ 'on': props.type === 'left' }" @click="close">
        <p v-html="props.text"></p>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = defineProps({
    text: {
        type: String,
        default: "",
    },
    type: {
        type: String,
        default: "",
    },
});

const close = () => {
    if(route.name === 'LiveAlarm') {
        router.push('/m/Setting')
    }
}

</script>
