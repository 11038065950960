<template>
    <div class="wrap">
        <Header  :text="'설정'" /> 
        <div class="Setting innerHeight">
            <div class="menu-box live" @click="showLiveAlarm()">
                <p class="title">실시간 알림</p>
                <div class="click-box">
                    <p class="text">실시간 알림 내역 <i class="right-arrow"/></p>
                </div>
            </div>
            <div class="menu-box" >
                <p class="title">주문확인 알림 설정</p>
                <div v-for="item in state.orderCheck" :key="item.id">
                    <input type="checkbox" :id="item.id" v-model="item.checked">
                    <label class="click-box" :for="item.id" >
                        <p class="text">{{item.label}} <i class="icon-ball" :class="{'on': item.checked}" /></p>
                    </label>
                </div>
            </div>
            <div class="menu-box" >
                <p class="title">주문체결 알림 설정</p>
                <div v-for="item in state.orderComplete" :key="item.id">
                    <input type="checkbox" :id="item.id" v-model="item.checked">
                    <label class="click-box" :for="item.id" >
                        <p class="text">{{item.label}} <i class="icon-ball" :class="{'on': item.checked}" /></p>
                    </label>
                </div>
            </div>
            <div class="menu-box" >
                <p class="title">입출금 관련 알림</p>
                <div v-for="item in state.transaction" :key="item.id">
                    <input type="checkbox" :id="item.id" v-model="item.checked">
                    <label class="click-box" :for="item.id" >
                        <p class="text">{{item.label}} <i class="icon-ball" :class="{'on': item.checked}" /></p>
                    </label>
                </div>
            </div>
            <div class="menu-box" >
                <p class="title">일반 설정</p>
                <!-- <div class="click-box bdbn" @click="openSetting('setPassword')">
                    <p class="text">계좌 비밀번호 설정<i class="right-arrow"/></p>
                </div>
                <div class="click-box bdbn" @click="openSetting('changePassword')">
                    <p class="text">계좌 비밀번호 변경<i class="right-arrow"/></p>
                </div> -->
                <div class="click-box bdbn" @click="openSetting('loginPassword')">
                    <p class="text">접속 비밀번호 변경<i class="right-arrow"/></p>
                </div>
                <div class="click-box bdbn" @click="openSetting('leverageSetting')">
                    <p class="text">레버리지 설정<i class="right-arrow"/></p>
                </div>
            </div>
            <div class="menu-box modeSelect">
                <p class="title">기타</p>
                <div class="click-box bdbn">
                    <div class="text">
                        <p class="text">테마설정</p>
                        <div class="radio-wrap">
                            <label class="label-radio first">
                            <input type="radio" value="white" v-model="state.themeMode" @change="toggleMode">
                                화이트테마
                            </label>
                            <label class="label-radio">
                            <input type="radio" value="dark" v-model="state.themeMode" @change="toggleMode">
                                다크테마
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-box">
                <button class="reset" @click="logout">
                    <span class="text">로그아웃</span>
                </button>
                <button class="reset" @click="resetSet">
                    <span class="text">설정 초기화</span>
                </button>
                <button class="save" @click="saveSet">
                    <span class="text">설정 저장</span>
                </button>
            </div>
        </div>
    </div>
    <LiveAlarm :isShowPopup="state.isShowLiveAlarm" :type="'left'" @close="popupClose('LiveAlarm')"/>
    <SetPassword :isShowPopup="state.isShowSetPassword" :isIgnoreClose="state.initIsIgnoreClose" :type="'left'" @ok="popupOk('SetPassword')" @close="popupClose('SetPassword')" v-if="state.isShowSetPassword"/>
    <ChangePassword :isShowPopup="state.isShowChangePassword" :type="'left'" @ok="popupOk('ChangePassword')" @close="popupClose('ChangePassword')" v-if="state.isShowChangePassword"/>
    <LoginPassword :isShowPopup="state.isShowLoginPassword" :type="'left'" @ok="popupOk('LoginPassword')" @close="popupClose('LoginPassword')" v-if="state.isShowLoginPassword"/>
    <LeverageSetting :isShowPopup="state.isShowLeverageSetting" :type="'left'" @ok="popupOk('LeverageSetting')" @close="popupClose('LeverageSetting')" v-if="state.isShowLeverageSetting"/>
    <PopupConfirm :isShowPopup="state.initShowOkpopup" :initPopupMessage1="state.initPopupOkMessage" @close="popupClose('PopupConfirm')"/>
    <PopupTwoButton :isShowPopup="state.initShowTwoPopup" :text="state.initPopupTwoMessage" @ok="popupOk('PopupTwoButton')" @close="popupClose('PopupTwoButton')"/>
    <!-- <MenuTab /> -->
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, onMounted, onUnmounted, reactive, store} = useBaseComponent();
import { NetworkService, Emit_Success } from "@/network"
import SettingsService from '../services/SettingsService';
import * as AccPwd from '../constants/AccPwd';

//공용 팝업 종류
const TYPE_POPUP_NONE = 0;
const TYPE_POPUP_RESET = 1;
const TYPE_POPUP_LOGOUT = 2;
// const TYPE_POPUP_LOGOUT = 3;
// const TYPE_POPUP_LOGOUT = 4;
// const TYPE_POPUP_LOGOUT = 5;

let state = reactive({
    orderCheck:[
        { id: 'orderCheck1', label: '매수주문 확인', checked: false },
        { id: 'orderCheck2', label: '매도주문 확인', checked: false },
        { id: 'orderCheck3', label: '정정주문 확인', checked: false },
        { id: 'orderCheck4', label: '취소주문 확인', checked: false },
    ],
    orderComplete:[
        { id: 'orderComplete1', label: '매수주문 체결시', checked: false },
        { id: 'orderComplete2', label: '매도주문 체결시', checked: false },
        { id: 'orderComplete3', label: '주문 거부시', checked: false },
    ],
    transaction:[
        {id: 'transaction', label: '입출금 관련', checked: false}
    ],
    themeMode: 'white',
    currPopupType: TYPE_POPUP_NONE, //현재 열려있는 팝업 종류(공용팝업일 경우)
    initShowOkpopup:false,
    initPopupOkMessage:"",
    initShowTwoPopup:false,
    initPopupTwoMessage:"",
    //셋팅팝업
    isShowSetPassword:false,
    isShowChangePassword:false,
    isShowLoginPassword:false,
    isShowLeverageSetting:false,
    // isShowSelectTime:false,
    isShowLiveAlarm:false,  //실시간 알림 팝업

    initIsIgnoreClose: false,
    
})
onMounted(() => {
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('Setting');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) initData();
});

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
});

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};
const initData = () => {
    loadSetting();

    // 계좌 비밀번호 체크 생략
    // const configState = Number(store.SettingsState.settings.accPwdApply);
    // if(configState === AccPwd.cAccPwdApply_OncePopup || (configState === AccPwd.cAccPwdApply_Forever && store.getDomesticAccPwd() === '')) {
    //     store.getSettingAccountPopupState().then(result => {
    //         if(result === 1) {
    //             openSetting('setPassword');
    //             state.initIsIgnoreClose = true;
    //         }
    //     });
    // }
}

const loadSetting = async() => {
    let s1 = await SettingsService.getSettings();
    let s2 = await SettingsService.getSettingsEx();
    if(s1 === null || s1 === undefined) {
        SettingsService.initializeSettings();
        s1 = await SettingsService.getSettings();
    }
    if(s2 === null || s2 === undefined) {
        SettingsService.initializeSettingsEx();
        s2 = await SettingsService.getSettingsEx();
    }

    state.orderCheck[0].checked = s1?.bBuyConfirm ? true : false;
    state.orderCheck[1].checked = s1?.bSellConfirm ? true : false;
    state.orderCheck[2].checked = s1?.bModifyConfirm ? true : false;
    state.orderCheck[3].checked = s1?.bCancelConfirm ? true : false;

    state.orderComplete[0].checked = s1?.bBuyPush ? true : false;
    state.orderComplete[1].checked = s1?.bSellPush ? true : false;
    state.orderComplete[2].checked = s1?.bRefusePush ? true : false;

    state.transaction[0].checked = s1?.bDepoWithPush ? true : false;

    state.themeMode = store.CommonState.themeMode;
    if(state.themeMode === '' || state.themeMode !== 'dark'){
        state.themeMode = 'white';
    }
}
const saveCurrentSetting = async() => {
    store.updateSettings({
        bBuyConfirm: state.orderCheck[0].checked,
        bSellConfirm: state.orderCheck[1].checked,
        bModifyConfirm: state.orderCheck[2].checked,
        bCancelConfirm: state.orderCheck[3].checked,

        bBuyPush: state.orderComplete[0].checked,
        bSellPush: state.orderComplete[1].checked,
        bRefusePush: state.orderComplete[2].checked,

        bDepoWithPush: state.transaction[0].checked,

        theme: state.themeMode
    });
}


const toggleMode = async() => {
    store.setThemeMode(state.themeMode);

    if('dark' === state.themeMode)
        window.dispatchEvent(new CustomEvent("customTvChangeTheme",{ detail:  { data: 'dark' }}));
    if('white' === state.themeMode)
        window.dispatchEvent(new CustomEvent("customTvChangeTheme",{ detail:  { data: 'light' }}));
        
    // rootStore.themeMode = state.mode;
    // router.go(0)
}

const logout = () => {
    state.currPopupType = TYPE_POPUP_LOGOUT;
    state.initShowTwoPopup = true;
    state.initPopupTwoMessage = "로그아웃 하시겠습니까?"
}

const resetSet = () => {
    state.currPopupType = TYPE_POPUP_RESET;
    state.initShowTwoPopup = true;
    state.initPopupTwoMessage = "모든 설정을 초기화<br />하시겠습니까?"
}

const onResetSetting = () => {
    state.orderCheck[0].checked = true;
    state.orderCheck[1].checked = true;
    state.orderCheck[2].checked = true;
    state.orderCheck[3].checked = true;

    state.orderComplete[0].checked = true;
    state.orderComplete[1].checked = true;
    state.orderComplete[2].checked = true;

    state.transaction[0].checked = true;

    saveCurrentSetting();
    loadSetting();
}

const onLogout = () => {
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
    if(accPwdApply !== AccPwd.cAccPwdApply_Forever) {
        store.checkAccountPassword({pwd1:'', pwd2:'', checkType:accPwdApply});
    }

    NetworkService.socket.send(JSON.stringify({t: 'logout', d: {}}));

    store.LoginPageState.isReloadRequired = true;

    router.push('/');
}

const saveSet = () => {
    state.initShowOkpopup = true;
    state.initPopupOkMessage = "설정을 저장했습니다"
    saveCurrentSetting();
}

const showLiveAlarm = () => {
    state.isShowLiveAlarm = true;
    document.body.style.overflow = "hidden";
}

const openSetting = (type: string) => {
    document.body.style.overflow = "hidden";
    if(type === 'setPassword'){
        state.isShowSetPassword = true;
    } else if(type === 'changePassword'){
        state.isShowChangePassword = true;
    } else if(type === 'loginPassword'){
        state.isShowLoginPassword = true;
    } else if(type === 'leverageSetting'){
        state.isShowLeverageSetting = true;
    } 
    // else if(type === 'selectTime'){
    //     state.isShowSelectTime = true;
    //     document.body.style.overflow = "hidden";
    // } else {
    //     //
    // }
}

const popupOk = (popupType: string) => {
    document.body.style.overflow = "auto";
    switch (popupType) {
        case "PopupTwoButton":
            if(state.currPopupType === TYPE_POPUP_RESET){
                onResetSetting();
            }
            else if(state.currPopupType === TYPE_POPUP_LOGOUT){
                onLogout();
            }
            state.currPopupType = TYPE_POPUP_NONE;
            state.initShowTwoPopup = false;
            break;
        case "SetPassword":
            state.isShowSetPassword = false;
            state.initIsIgnoreClose = false;
            break;
        case "ChangePassword":
            state.isShowChangePassword = false;
            break;
        case "LoginPassword":
            state.isShowLoginPassword = false;
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            break;
    }
};

const popupClose = (popupType: string) => {
    document.body.style.overflow = "auto";
    switch (popupType) {
        case "PopupConfirm":
            state.initShowOkpopup = false;
            break;
        case "PopupTwoButton":
            state.currPopupType = TYPE_POPUP_NONE;
            state.initShowTwoPopup = false;
            break;
        case "SetPassword":
            state.isShowSetPassword = false;
            state.initIsIgnoreClose = false;
            break;
        case "ChangePassword":
            state.isShowChangePassword = false;
            break;
        case "LoginPassword":
            state.isShowLoginPassword = false;
            break;
        // case "SelectTime":
        //     state.isShowSelectTime = false;
        // 
        //     break;
        case "LiveAlarm":
            state.isShowLiveAlarm = false;
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            break;
    }
};
</script>
