import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/pc/popup-close.png'


const _hoisted_1 = {
  key: 0,
  class: "popupPc"
}
const _hoisted_2 = { class: "popupPc-inner" }
const _hoisted_3 = { class: "leverage" }
const _hoisted_4 = { class: "table-box" }
const _hoisted_5 = { class: "table-body" }
const _hoisted_6 = { class: "inner-item" }
const _hoisted_7 = { class: "table-item value" }
const _hoisted_8 = { class: "inner-item" }
const _hoisted_9 = { class: "table-item value" }
const _hoisted_10 = { class: "table-body" }
const _hoisted_11 = { class: "inner-item" }
const _hoisted_12 = { class: "table-item value" }
const _hoisted_13 = { class: "inner-item" }
const _hoisted_14 = { class: "table-item value" }
const _hoisted_15 = { class: "table-body" }
const _hoisted_16 = { class: "inner-item" }
const _hoisted_17 = { class: "table-item value" }
const _hoisted_18 = { class: "inner-item" }
const _hoisted_19 = { class: "table-item value" }
const _hoisted_20 = { class: "table-box" }
const _hoisted_21 = { class: "table-body" }
const _hoisted_22 = { class: "inner-item" }
const _hoisted_23 = { class: "table-item value" }
const _hoisted_24 = { class: "drop-leverage" }
const _hoisted_25 = {
  for: "dropLeverage",
  class: "for-dropsort pageCount"
}
const _hoisted_26 = { class: "out" }
const _hoisted_27 = {
  key: 0,
  class: "section-sort"
}
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "inner-item" }
const _hoisted_30 = { class: "table-item value" }
const _hoisted_31 = { class: "table-body" }
const _hoisted_32 = { class: "inner-item" }
const _hoisted_33 = { class: "table-item value" }
const _hoisted_34 = { class: "inner-item" }
const _hoisted_35 = { class: "table-item value" }
const _hoisted_36 = { class: "table-body" }
const _hoisted_37 = { class: "inner-item" }
const _hoisted_38 = { class: "table-item value" }
const _hoisted_39 = { class: "inner-item" }
const _hoisted_40 = { class: "table-item value" }
const _hoisted_41 = { class: "btn-box" }

import useBaseComponent from "@/baseComponent";
import { ref } from 'vue';
import * as Common from '@/constants/Common';
import { toLocaleString } from '@/utils/number';
import { onUnmounted } from "vue";
import { NetworkService, Emit_DrawTrans, Emit_UserOption, Emit_ResetAmount, XingAPIService, Emit_ContractData, Emit_CallData, Emit_Success } from '../../network';
import { UserLog } from '../../network/UserLog';

// 화면 갱신
const REFRESH_INTERVAL = 300;  //100;


export default /*@__PURE__*/_defineComponent({
  __name: 'PcLeveragePopup',
  props: {
    initIsShowPopup: {
        type: Boolean,
        default: false,
    }
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { router, store, onMounted, reactive, defineProps, defineEmits, watch} = useBaseComponent();
const props = __props;

let state = reactive({
    futures: Common.G_FUTURES_DAY,
    isOversea: Common.eNation_Domestic,

    pickOpenLeverage: false,
    pickDescLeverage: '',
    pickValueLeverage: '',

    depoAmount: 0,				// 국내예탁금액
    depoAmountOversea: 0,		// 해외예탁금액
    estimatedAmount: 0,			// 국내평가예탁금액
    estimatedAmountOversea: 0,	// 해외평가예탁금액
    totalProfit: 0,				// 총평가손익
    netDomestic: 0,				// 국내실현손익
    netOversea: 0,				// 해외실현손익

    // 계좌 정보 세팅
    timerInitData: null as any,
});

watch(props, (initIsShowPopup) => {
    if (initIsShowPopup) {
        let futures = store.FuturesByNow();
        futures = futures >= 1 ? 2: 0;
        state.futures = futures;
        state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
        
        const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro; 
        onPickChangeLeverage(currLeverage);
    }
},{ deep: true });

onMounted(()=>{
    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) {
        initData();
    }
})

onUnmounted(() =>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);

})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {
	//로그인 후 계좌정보가 store에 저장됐는지
	if(!store.LoginPageState.isPacketAccInfoReceived){
		state.timerInitData = setTimeout(() => {
			initData();
		}, 100)
		return;
	}

    let futures = store.FuturesByNow();
    futures = futures >= 1 ? 2: 0;
    state.futures = futures;
    state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    
    const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro; 
    onPickChangeLeverage(currLeverage);

	const autoRefreshing = async () => {
		// 예탁금, 평가예탁금, 평가손익
		calcuAmount();
		setTimeout(autoRefreshing, REFRESH_INTERVAL);
	};

	autoRefreshing();
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const getMaxContract = (isOversea: number, leverage: number) => {
    // 보유 레버리지 평가금
    const estimatedDepo = isOversea === Common.eNation_Domestic ? state.depoAmount * leverage : state.depoAmountOversea * leverage; 
    // 선물 가격
    const futuresPrice = isOversea === Common.eNation_Domestic ? store.BrandState.iDomesticFuturePrice : store.BrandState.iOverseaFuturePrice;
    // 마켓 최대 계약수
    const maxContract = store.MarketState.bIndivMaxContract ? (isOversea === Common.eNation_Domestic ? store.MarketState.iDomesticMaxContract : store.MarketState.iOverseaMaxContract) : store.MarketState.iMaxContract[state.futures];
    // 최대 계약 가능 수
    const possibleMaxContract = Math.floor((estimatedDepo / futuresPrice < maxContract) ? estimatedDepo / futuresPrice : maxContract);

    return possibleMaxContract;
}

const onPickChangeLeverage = (item:any) => {
    state.pickOpenLeverage = false;
    state.pickDescLeverage = "1 : " + item;
    state.pickValueLeverage = item;
}

const onActionChangeLeverage = () => { 
    const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro;

    // console.log(currLeverage);
    // console.log(state.pickValueLeverage);
    // if(currLeverage === Number(state.pickValueLeverage)){
    //     return;
    // }

    const callback = () => {
        NetworkService.send.newLeverageReq({
            Futures: state.futures, 
            NewLeverage: state.pickValueLeverage,
        });
        close();
    }

    const szMsg = `레버리지를 변경 하시겠습니까?`;
    store.setVisibleDialog({ message: szMsg, title: `레버리지 변경`, okCancel: true, callback });
    
};

const emit = __emit;

const close = () => { 
    emit("close"); 
};

return (_ctx: any,_cache: any) => {
  return (props.initIsShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "title-box flex-center-center" }, [
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text" }, "레버리지 설정", -1)),
            _createElementVNode("img", {
              class: "close",
              src: _imports_0,
              alt: "",
              onClick: close
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-box" }, [
              _createElementVNode("p", { class: "text" }, "레버리지 변경 신청시 미체결 주문 또는 미결제 약정이 없어야 합니다.")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "table-head" }, [
                _createElementVNode("p", { class: "table-item title" }, "현재 계좌 기본정보")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "table-item category" }, "현재 레버리지", -1)),
                  _createElementVNode("p", _hoisted_7, "1 : " + _toDisplayString(_unref(state).isOversea === Common.eNation_Domestic ? _unref(store).AccountState.iLeverage : _unref(store).AccountState.iLeverageEuro), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "table-item category" }, [
                    _createTextVNode("최대 주문 계약수"),
                    _createElementVNode("br"),
                    _createTextVNode("(해외 / 국내)")
                  ], -1)),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(getMaxContract(Common.eNation_Oversea, _unref(store).AccountState.iLeverageEuro)) + " / " + _toDisplayString(getMaxContract(Common.eNation_Domestic, _unref(store).AccountState.iLeverage)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "table-item category" }, "해외 보유잔고", -1)),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea)), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "table-item category" }, "해외 평가금액", -1)),
                  _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea * _unref(store).AccountState.iLeverageEuro)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "table-item category" }, "국내 보유잔고", -1)),
                  _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount)), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "table-item category" }, "국내 평가금액", -1)),
                  _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount * _unref(store).AccountState.iLeverage)), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "table-head" }, [
                _createElementVNode("p", { class: "table-item title" }, "운영 레버리지 변경")
              ], -1)),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "table-item category" }, "변경 레버리지", -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _withDirectives(_createElementVNode("input", {
                        class: "drop-sort",
                        type: "checkbox",
                        id: "dropLeverage",
                        name: "dropLeverage",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickOpenLeverage) = $event))
                      }, null, 512), [
                        [_vModelCheckbox, _unref(state).pickOpenLeverage]
                      ]),
                      _createElementVNode("label", _hoisted_25, [
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(state).pickDescLeverage), 1),
                        _createElementVNode("i", {
                          class: _normalizeClass(["icon-down", [{ on: _unref(state).pickOpenLeverage }]])
                        }, null, 2)
                      ]),
                      (_unref(state).pickOpenLeverage)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).BrandState.leverages, (item, index) => {
                              return (_openBlock(), _createElementBlock("p", {
                                key: index,
                                onClick: ($event: any) => (onPickChangeLeverage(item)),
                                class: "text"
                              }, " 1 : " + _toDisplayString(item), 9, _hoisted_28))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "table-item category" }, [
                    _createTextVNode("최대 주문 계약수"),
                    _createElementVNode("br"),
                    _createTextVNode("(해외 / 국내)")
                  ], -1)),
                  _createElementVNode("p", _hoisted_30, _toDisplayString(getMaxContract(Common.eNation_Oversea, Number(_unref(state).pickValueLeverage))) + " / " + _toDisplayString(getMaxContract(Common.eNation_Domestic, Number(_unref(state).pickValueLeverage))), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "table-item category" }, "해외 보유잔고", -1)),
                  _createElementVNode("p", _hoisted_33, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea)), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _cache[13] || (_cache[13] = _createElementVNode("p", { class: "table-item category" }, "해외 평가금액", -1)),
                  _createElementVNode("p", _hoisted_35, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea * Number(_unref(state).pickValueLeverage))), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, [
                  _cache[14] || (_cache[14] = _createElementVNode("p", { class: "table-item category" }, "국내 보유잔고", -1)),
                  _createElementVNode("p", _hoisted_38, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount)), 1)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _cache[15] || (_cache[15] = _createElementVNode("p", { class: "table-item category" }, "국내 평가금액", -1)),
                  _createElementVNode("p", _hoisted_40, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount * Number(_unref(state).pickValueLeverage))), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("button", {
                class: "cancel",
                onClick: close
              }, _cache[17] || (_cache[17] = [
                _createElementVNode("span", { class: "text" }, "닫기", -1)
              ])),
              _createElementVNode("button", {
                class: "ok",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (onActionChangeLeverage()))
              }, _cache[18] || (_cache[18] = [
                _createElementVNode("span", { class: "text" }, "적용", -1)
              ]))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})