<template>
    <div class="popupPc">
        <div class="popupPc-inner">
            <div class="popupSet" v-if="props.isShowPopup">
                <div class="changePopup">
                    <div class="setPassword  Setting ">
                        <div class="title-box flex-center-center">
                            <p class="text ">계좌 비밀번호 변경</p>
                        </div>
                        <div class="scroll-wrap-set">
                            <div class="menu-box">
                                <p class="title">계좌비밀번호 입력방식</p>
                                <div class="click-box pt-0">
                                    <div class="radio-wrap">
                                        <input type="radio" id="inputRoot1" value="0" v-model="state.accPwdApply">
                                        <label for="inputRoot1">계좌비밀번호 한번만 입력후 계속 저장<span>(설정한 기기에서만 적용되며, 재로그인시에도 유지)</span></label>
                                    </div>
                                    <div class="radio-wrap">
                                        <input type="radio" id="inputRoot2" value="1" v-model="state.accPwdApply" disabled>
                                        <label for="inputRoot2">계좌비밀번호 매번 입력</label>
                                    </div>
                                    <div class="radio-wrap">
                                        <input type="radio" id="inputRoot3" value="2" v-model="state.accPwdApply" disabled>
                                        <label for="inputRoot3">계좌비밀번호 한번만 입력후 저장</label>
                                    </div>
                                    <div>
                                        <div class="radio-wrap on bdbn">
                                            <input type="radio" id="inputRoot4" value="3" v-model="state.accPwdApply" disabled>
                                            <label for="inputRoot4">
                                                <p class="text" >계좌비밀번호 일괄저장(로그아웃시 저장해제)</p> 
                                                <div class="checkbox-wrap">
                                                    <input type="checkbox" id="optionA" :disabled="state.accPwdApply != 3" @change="screenOnCheck" >
                                                    <label class="checkBox-label" for="optionA" :class="{'disabled':state.accPwdApply !== 3 }"><i class="checkBox" :class="{'on':state.screenOnCheck && state.accPwdApply === 3}" /> 프로그램 시작시 계좌비밀번호 일괄저장 화면 띄우기</label>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="menu-box">
                                <p class="title">계좌비밀번호 일괄저장</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox" id="allCheck" @change="onAccountHeaderChecked">
                                                <label for="allCheck"><i class="checkBox" :class="{'on': state.accountHeaderChecked }"/></label>
                                            </th>
                                            <th>계좌번호</th>
                                            <th>계좌명</th>
                                            <th>계좌비밀번호</th>
                                        </tr>
                                    </thead>
                                    <tbody>                     
                                        <tr v-for="(item, i) in state.accountArray" :key="i">
                                            <td>
                                                <input type="checkbox" :id="`no`+i" @change="onAccountChecked(i)">
                                                <label :for="`no`+i"><i class="checkBox" :class="{'on': item.checked }"/></label>
                                            </td>
                                            <td>{{ item.id }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.pwd }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="save-box">
                                <div class="input-box">
                                    <div class="left">
                                        <p class="text">계좌비밀번호</p>
                                    </div>
                                    <div class="right">
                                        <input type="password" placeholder="비밀번호를 입력해주세요" v-model="state.inputPassword">
                                    </div>
                                </div>
                                <div class="radio-wrap">
                                    <label class="label-radio first">
                                        <input type="radio" value="0" v-model="state.passwordUpdateType" @change="onPasswordUpdateTypeChanged">
                                            모든계좌에 저장
                                    </label>
                                    <label class="label-radio">
                                        <input type="radio" value="1" v-model="state.passwordUpdateType" @change="onPasswordUpdateTypeChanged" disabled>
                                            선택계좌에 저장
                                    </label>
                                    <label class="label-radio">
                                        <input type="radio" value="2" v-model="state.passwordUpdateType" @change="onPasswordUpdateTypeChanged" disabled>
                                            모든계좌 저장해제
                                    </label>
                                </div>
                            </div>
                            <p class="caution">*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.</p>
                            <p class="caution">*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.</p>
                            <p class="caution">*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능 이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.</p>
                        </div>
                        <div class="btn-box">
                            <button class="cancel" @click="close" v-if="props.isIgnoreClose === false">
                                <span class="text">취소</span>
                            </button>
                            <button class="ok" @click="ok">
                                <span class="text">확인</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
import { NetworkService, Emit_AccountPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';
import * as AccPwd from '../../constants/AccPwd';

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
    isIgnoreClose: {
        type: Boolean,
        default: false,
    }
});


let state = reactive({
    accountHeaderChecked: 1,
    accountArray: [
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
        },
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
        }
    ],

    accPwdApply: 0,     // 계좌번호 입력방식, AccPwd
    screenOnCheck:true, //프로그램시작시 계좌비밀번호 일괄저장 화면 띄우기

    passwordUpdateType:0,
    inputPassword: '',

    timerInitData: null as any,
})

onMounted(() => {
    initData();
 });



const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    state.accountArray[0].id = store.AccountState.szDepoAccNo;
    state.accountArray[1].id = store.AccountState.szDepoAccNoOversea;
    state.accountArray[0].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Domestic]})`;
    state.accountArray[1].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Oversea]})`;
    state.accountArray[0].pwd = store.getDomesticAccPwd(false);
    state.accountArray[1].pwd = store.getOverseaAccPwd(false);
    
    state.accPwdApply = store.SettingsState.settings.accPwdApply;
}

const screenOnCheck = () => {
    // 개별 변경기능 없대서 일단 주석
    //console.log(state.screenOnCheck);
    //state.screenOnCheck = !state.screenOnCheck;
}

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const onPasswordUpdateTypeChanged = () => {
    //console.log(state.passwordUpdateType);
}

const emit = defineEmits(["close", "ok", "settingAllClose"]);

const settingAllClose = () => {
    state.inputPassword = '';
    emit("settingAllClose");
}

const close = () => { 
    state.inputPassword = '';
    emit("close");
};

const ok = () => {
    if(Number(state.accPwdApply) === 1){
        //계좌비밀번호 매번 입력 선택한 경우 패스워드 체크없이 창 닫음
        store.checkAccountPassword({checkType: state.accPwdApply});
        state.inputPassword = '';

        //패스워드 설정 후 store 통해서 액션 감지하도록 함
        store.CommonState.isChangePwdSetting = true;
        close();
        if(props.isIgnoreClose){
            //로그인 후 강제 비밀번호 설정 시
            store.setSettingAccountPopupState(0);
            store.setVisibleDialog({message: '비밀번호를 변경하였습니다.', callback : () =>{
                settingAllClose();
            }})
        } 
        return;
    }

    if(state.inputPassword !== '') {
        
        store.checkAccountPassword({pwd1: state.inputPassword, pwd2: state.inputPassword, checkType: state.accPwdApply});
        // API 호출이 아니라 그냥 비교만 하면됨
        //NetworkService.send.newAccPwdReq({ DomesticPwd: state.inputPassword, OverseaPwd: state.inputPassword });

        // WTS 에서는 국내/해외 동일 취급
        const bAccPassed = state.inputPassword === store.AccountState.szDomesticAccPwd ? true : state.inputPassword === store.AccountState.szOverseaAccPwd ? true : false;
        // if(bAccPassed) {
        //     //
        // }
        // else {
        //     //
        // }

        //패스워드 설정 후 store 통해서 액션 감지하도록 함
        store.CommonState.isChangePwdSetting = true;
        close();
        if(props.isIgnoreClose){
            //로그인 후 강제 비밀번호 설정 시
            // router.push('/m/Home');
            settingAllClose();
        }
    }
    else {
        store.setVisibleDialog({message: '계좌비밀번호를 입력해 주세요.'});
        return;
    }
};
</script>
