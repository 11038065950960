// 상담 상태
export const eCounselingState_No = 0;	// 상담중 아님
export const eCounselingState_Req = 1;		// 상담 요청 중
export const eCounselingState_Proceed = 2;	// 상담 진행 중
export const eCounselingState_Max = 3;

// 채팅방 
export class ChatRoomObj {

  // 채팅방 상태
  static eState_No = 0;	// 디폴트,
  static eState_Enter = 1;		// 입장
  static eState_Leave = 2;		// 퇴장
  static eState_Max = 3;

  // 방을 초기화
	Reset() {
		// 2023-10-23: 모바일에서 리셋 없음
		//this.UserState = ChatRoomObj.eState_No;
		//this.CounselorState = ChatRoomObj.eState_No;

		//this.Messages = [];
	}

	// 유저가 상담 요청 중인가?
	IsUserReq() {
		return this.UserState === ChatRoomObj.eState_Enter && this.CounselorState !== ChatRoomObj.eState_Enter;
	}
	// 상담 진행 중
	IsCounseling() {
		return this.UserState === ChatRoomObj.eState_Enter && this.CounselorState === ChatRoomObj.eState_Enter;
	}
	// 상담 상태 ECounselingState
	GetCounselingState() {
		if (this.UserState === ChatRoomObj.eState_Enter && this.CounselorState === ChatRoomObj.eState_No) {
			return eCounselingState_Req;
    }
		if (this.UserState === ChatRoomObj.eState_Enter && this.CounselorState === ChatRoomObj.eState_Enter) {
			return eCounselingState_Proceed;
    }

		return eCounselingState_No;
	}

	// 유저 방상태
	UserState = ChatRoomObj.eState_No;
	// 상담원 방상태
	CounselorState = ChatRoomObj.eState_No;
  // ChatMsg
	Messages = [] as any;
}
