import * as Common from '../../src/constants/Common';
import {useStore} from '../../src/pinia';
// static data
const customStreamingData = {
    symbolInfo: null,
    lastDailyBar: null,
    callback: null,
    resolution: null,
    subscriberUID: null,
    reservedChartDataArray: null,
}

function getNextDailyBarTime(resolution, barTime) {
    let timeModifier;
    switch('' + resolution) {
        case '1T': timeModifier = 1; break;
        case '3T': timeModifier = 3; break;
        case '5T': timeModifier = 5; break;
        case '10T': timeModifier = 10; break;
        case '20T': timeModifier = 20; break;
        case '30T': timeModifier = 30; break;
        case '1': timeModifier = 60000; break;
        case '3': timeModifier = 60000 * 3; break;
        case '5': timeModifier = 60000 * 5; break;
        case '10': timeModifier = 60000 * 10; break;
        case '20': timeModifier = 60000 * 20; break;
        case '30': timeModifier = 60000 * 30; break;
        case '1D': timeModifier = 86400000; break;
        case '1W': timeModifier = 86400000 * 7; break;
        case '1M': timeModifier = 86400000 * 30; break;
        default: {
            //console.error('out of resolution: ' + resolution);
            timeModifier = 60000;
        }
        
    }
    if(barTime > 1000000000000000) {
        return new Date((barTime / 1000) + timeModifier).getTime() * 1000;
    } else {
        return new Date(barTime  + timeModifier).getTime();
    }    
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
    lastDailyBar,
)
{
    customStreamingData.symbolInfo = symbolInfo;
    customStreamingData.callback = onRealtimeCallback;
    customStreamingData.resolution = resolution;
    customStreamingData.subscriberUID = subscriberUID;
}

export function unsubscribeFromStream(subscriberUID) {
    // nothing to do
}

export function resetStreamingStoredData() {
    customStreamingData.lastDailyBar = null;
    customStreamingData.symbolInfo = null;
    customStreamingData.callback = null;
    customStreamingData.resolution = null;
    customStreamingData.subscriberUID = null;
    customStreamingData.reservedChartDataArray = [];
}

export function setLastHistoryData(req, data) {
    if(data == null) {
        //console.log("저장된 히스토리 데이터 없음")
        customStreamingData.lastDailyBar = {
            time: new Date().getTime()
        }
    }
    else {
        customStreamingData.lastDailyBar = data;
    }
}

//https://www.tradingview.com/charting-library-docs/latest/tutorials/implement_datafeed_tutorial/Streaming-Implementation/#complete-code
//https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-Issues/
export function onChartDataReceived(data) {
    //console.log('[onChartDataReceived]', data);
    // 데이터 처리중 누락되는 데이터 있음
    if( ! customStreamingData.callback) {
        return; 
    }
    if( ! customStreamingData.lastDailyBar) { 
        // 마지막 데이터 없으면 데이터 누적
        if(customStreamingData.reservedChartDataArray)
            customStreamingData.reservedChartDataArray.push(data);
        return; 
    }
    for (let i = 0; i < customStreamingData.reservedChartDataArray.length; i++) {
        appendChartData(customStreamingData.reservedChartDataArray[i]);
    }
    customStreamingData.reservedChartDataArray=[];
    appendChartData(data);
}

export function appendChartData(data) {
    //console.log('[onChartDataReceived]', data);
    const nextDailyBarTime = getNextDailyBarTime(customStreamingData.resolution, customStreamingData.lastDailyBar.time);
    
    const tradePrice = data.close;
    let tradeTime = data.time;

    const store = useStore();
    let timeModifier = customStreamingData.symbolInfo.customTimeModifier;
    let timeBase = 0;
    if (customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_OIL || customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_GOLD || customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_SILVER || customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_GAS || customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_COPPER)
    {
        // 뉴욕거래소
        timeBase = store.SettingsState.timeGapNYMEX - (timeModifier / 3600000);
    }
    else if (customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_HANGSENG)
    {
        // 홍콩 거래소
        //timeBase = store.SettingsState.timeGapHKEX - (timeModifier / 3600000);
    }
    else if (customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_CHINA50)
    {
        // 싱가포르 거래소
        //timeBase = store.SettingsState.timeGapSGX - (timeModifier / 3600000);
    }
    else if (customStreamingData.symbolInfo.customFuturesCode >= Common.G_FUTURES_OVERSEAS)
    {
        // 시카고거래소
        timeBase = store.SettingsState.timeGapCME - (timeModifier / 3600000);
    }
    else if (customStreamingData.symbolInfo.customFuturesCode === Common.G_FUTURES_DAY)
    {
        timeBase = 0;
    }
    
    timeModifier += (3600000 * timeBase);

    //console.log('--', new Date(data.time), new Date(tradeTime), new Date(customStreamingData.lastDailyBar.time));

    let bar;
    // 2024-08-26: 일주봉, 미래 기준으로 마지막 bar 그려서 예외처리 제거
    // if(customStreamingData.lastDailyBar.time > tradeTime) {
    //     //console.warn('skip elapsed data', new Date(tradeTime), new Date(customStreamingData.lastDailyBar.time), data);
    //     return;
    // }
    if (tradeTime >= nextDailyBarTime) {
        bar = {
            time: 0 + nextDailyBarTime,
            //open: tradePrice,
            //open: customStreamingData.lastDailyBar.close,
            open: 0 + customStreamingData.lastDailyBar.close,
            high: 0 + tradePrice,
            low: 0 + tradePrice,
            close: 0 + tradePrice,
            volume: 0 + data.volume,
        };

        //console.log('[socket] Generate new bar', customStreamingData.resolution,  bar, new Date(tradeTime), new Date(customStreamingData.lastDailyBar.time), new Date(nextDailyBarTime));
    }
    else {
        bar = {
            time: 0 + customStreamingData.lastDailyBar.time,
            //open: customStreamingData.lastDailyBar.open,
            //high: Math.max(customStreamingData.lastDailyBar.high, tradePrice),
            //low: Math.min(customStreamingData.lastDailyBar.low, tradePrice),
            open: 0 + customStreamingData.lastDailyBar.open,
            close: 0 + tradePrice,
            volume: customStreamingData.lastDailyBar.volume + data.volume,
        };
        bar.high = 0 + (customStreamingData.lastDailyBar.high > tradePrice) ? customStreamingData.lastDailyBar.high : tradePrice;
        bar.low = 0 + (customStreamingData.lastDailyBar.low > tradePrice) ? tradePrice : customStreamingData.lastDailyBar.low;

        //console.log('[socket] Update the latest bar by price', tradePrice, new Date(tradeTime), new Date(customStreamingData.lastDailyBar.time), new Date(nextDailyBarTime));
    }

    customStreamingData.lastDailyBar.time = 0 + bar.time;
    customStreamingData.lastDailyBar.open = 0 + bar.open;
    customStreamingData.lastDailyBar.high = 0 + bar.high;
    customStreamingData.lastDailyBar.low = 0 + bar.low;
    customStreamingData.lastDailyBar.close = 0 + bar.close;
    customStreamingData.lastDailyBar.volume = 0 + bar.volume;
    customStreamingData.callback(bar);
}