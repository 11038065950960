// 채팅 이벤트
export const eChatEvent_Init = 0;	// 초기화
export const eChatEvent_Message = 1;	// 메세지
export const eChatEvent_Counselor = 2;	// 상담원
export const eChatEvent_Transfer = 3;	// 전송
export const eChatEvent_Logs = 4;    // 로그

export class ChatMsg {
  // 채팅 메세지 종류
  static eType_Fixed = 0;	// 고정 메세지
  static eType_System = 1;			// 시스템 메세지
  static eType_User = 2;				// 사용자 메세지
  static eType_Counselor = 3;			// 상담원 메세지
  static eType_Info = 4;			// 안내 메세지
  static eType_Transfer = 5;		// 파일 전송
  static eType_TransferFile = 6;    // 전송파일
  static eType_Max = 7;

    // 파일전송 종류
  static eTransfer_No = 0;    // 파일 전송 없음.
  static eTransfer_User = 1;        // 사용자 전송
  static eTransfer_Counselor = 2;    // 상담원 전송
  static eTransfer_Max = 3;

  static CFM_BOLD =	0x00000001;
  static CFM_ITALIC =	0x00000002;
  static CFM_UNDERLINE = 0x00000004;
  static CFM_STRIKEOUT = 0x00000008;
  static CFM_PROTECTED = 0x00000010;
  static CFM_LINK = 0x00000020;			// Exchange hyperlink extension 
  static CFM_SIZE = 0x80000000;
  static CFM_COLOR = 0x40000000;
  static CFM_FACE = 0x20000000;
  static CFM_OFFSET = 0x10000000;
  static CFM_CHARSET = 0x08000000;

  static CFM_BACKCOLOR = 0x04000000;
  static CFM_LCID = 0x02000000;
  static CFM_UNDERLINETYPE = 0x00800000;        // Many displayed by 3.0 
  static CFM_WEIGHT = 0x00400000;
  static CFM_SPACING = 0x00200000;        // Displayed by 3.0    
  static CFM_KERNING = 0x00100000;        // (*)    
  static CFM_STYLE = 0x00080000;        // (*)    
  static CFM_ANIMATION = 0x00040000;        // (*)    
  static CFM_REVAUTHOR = 0x00008000;
  
  static cEdit_DefOffset = 0;
  
  static cLength_Max =	1024;		// 메세지 최대 길이

  constructor(data: any) {
    if (data) {
      const {
        Type = ChatMsg.eType_Fixed,
        stTime = new Date(),
        szMsg = '',
        TransferType = ChatMsg.eTransfer_No,
        szName = '',
      } = data;

      this.Type = Type;
      this.stTime = stTime;
      this.szMsg = szMsg;
      this.TransferType = TransferType;
      this.szName = szName;
    }
  }

  Type = ChatMsg.eType_Fixed;
  stTime = new Date();
  szMsg = '';
  TransferType = ChatMsg.eTransfer_No;

  // 채팅 로그에서 사용
  szName = '';
}

export class ChatTransfer extends ChatMsg {
  constructor(data: any) {
    super(data);

    if (data) {
      const {
        // 유저 여부
        bUser = false,
        // 업로드/다운로드 여부
        bUpload = false,
        // 클립보드의 이미지 여부
        bClipboard = false,
        // 파일 리스트 CStringArray
        szFileNames = [],
        // 원격 파일이름 CStringArray
        szRemoteNames = [],
      } = data;

      this.bUser = bUser;
      this.bUpload = bUpload;
      this.bClipboard = bClipboard;
      this.szFileNames = szFileNames;
      this.szRemoteNames = szRemoteNames;
    }
  }

	// 유저 여부
	bUser = 1;
	// 업로드/다운로드 여부
	bUpload = 1;
	// 클립보드의 이미지 여부
	bClipboard = 1;
	// 파일 리스트 CStringArray
	szFileNames = [];
	// 원격 파일이름 CStringArray
	szRemoteNames = [];
}
