import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popupSet"
}
const _hoisted_2 = { class: "changePopup" }
const _hoisted_3 = { class: "setPassword Setting changePassword" }
const _hoisted_4 = { class: "save-box" }
const _hoisted_5 = { class: "input-box" }
const _hoisted_6 = { class: "right" }
const _hoisted_7 = { class: "input-box mt10" }
const _hoisted_8 = { class: "right" }
const _hoisted_9 = { class: "input-box mt10" }
const _hoisted_10 = { class: "right" }

import useBaseComponent from "../../baseComponent";
import { NetworkService, Emit_LoginPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcLoginPassword',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
},
  emits: ["close", "ok"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
const props = __props;


let state = reactive({
    prior: '',
    new1: '',
    new2: '',
})

onMounted(() => { NetworkService.emitter.on(Emit_LoginPasswordUpdate, event_LoginPasswordResult ); });
onUnmounted(()=> { NetworkService.emitter.off(Emit_LoginPasswordUpdate, event_LoginPasswordResult ); })
const event_LoginPasswordResult = (params: any) => {
    const { nResult, szMsg } = params;
    if(nResult=== NetConstants.R_CHNGPWD_SUCCESS) {
        close();
    }
    else {
        // PacketService 에서 처리중
    }
};

const emit = __emit;
const close = () => { 
    state.prior = '';
    state.new1 = '';
    state.new2 = '';
    emit("close"); 
};
const ok = () => {
    let errorMessage = '';
    if( ! state.prior ||  ! state.new1 ||  ! state.new2 ) {
        errorMessage = '비밀번호를 입력해주세요.';
    }
    else {
        if(store.SettingsState.signinPwd != state.prior) {
            errorMessage = '접속 비밀번호가 일치하지 않습니다.';
        }
        else if(state.new1 != state.new2) {
            errorMessage = '변경할 비밀번호가 일치하지 않습니다.';
        }
        //else if (!(/^[A-Za-z0-9]{6,8}$/.test(state.new1))) {
        //    errorMessage = '계좌 비밀번호는 영문, 숫자 6~8자리입니다.';
        //}
        else if (store.SettingsState.signinPwd === state.new1) {
            errorMessage = '기존 접속 비밀번호와 같습니다.\n다른 비밀번호를 입력해주세요.';
        }
    }

    if(errorMessage) {
        store.setVisibleDialog({ message: errorMessage });
    }
    else {
        NetworkService.send.passwordWriteReq({ Pwd: state.prior, NewPwd: state.new1 });
    }
};

return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title-box flex-center-center" }, [
              _createElementVNode("p", { class: "text" }, "접속 비밀번호 변경")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "left" }, [
                  _createElementVNode("p", { class: "text" }, "현재 비밀번호")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "비밀번호를 입력해주세요",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).prior) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).prior]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "left" }, [
                  _createElementVNode("p", { class: "text" }, "신규 접속비밀번호")
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "변경할 비밀번호를 입력해주세요",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).new1) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).new1]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "left" }, [
                  _createElementVNode("p", { class: "text" }, "접속비밀번호 확인")
                ], -1)),
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("input", {
                    type: "password",
                    placeholder: "변경할 비밀번호를 다시 입력해주세요",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).new2) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(state).new2]
                  ])
                ])
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "caution" }, "*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능 이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.", -1)),
            _createElementVNode("div", { class: "btn-box" }, [
              _createElementVNode("button", {
                class: "cancel",
                onClick: close
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("span", { class: "text" }, "취소", -1)
              ])),
              _createElementVNode("button", {
                class: "ok",
                onClick: ok
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("span", { class: "text" }, "비밀번호 변경", -1)
              ]))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})