<template>
    <div class="popupSet" v-if="props.isShowPopup">
        <div class="wrap">
            <div class="header">
                <img class="back-btn" src="../../assets/images/header-back.png" alt="" :class="{ 'on': props.type === 'left' }" @click="close">
                <p>종목검색</p>
            </div>
            <div class=" innerHeight Setting Notice" :class="{'scroll-area':route.name === `TotalOrder`}">
                <table >
					<tr>
						<th class="col-2 left">종목코드</th>
						<th class="col-8 right">종목명</th>
					</tr>
					<tr v-for="(item, idx) in props.initFuturesItems" :key="idx" @click="onPickFuturesChangeValue(item)">
                        <td>
                            <p class="date">{{ item.value }}</p>
                        </td>
                        <td>
                            <p class="value">{{ item.label }} ({{ item.expiryDate }})</p>
                        </td>
					</tr>
				</table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();


const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
    initFuturesItems: {
        type: Object,
        default: () =>{
            return []
        }
    }
});


let state = reactive({
    
})

// onMounted(() => { });
// onUnmounted(()=> {  })


const emit = defineEmits(["close", "onPickFuturesChangeValue"]);

const onPickFuturesChangeValue = (item: any) => {
    emit("onPickFuturesChangeValue", item);
    emit("close");
}

const close = () => { 
    emit("close"); 
};

</script>
