// 시작시 app pincode를 입력할지 여부 - 개발 모드에서만 제가 가능
export const skipAppPincode = true; //__DEV__ ? true : false;
// store async load할지 여부, splash 로딩을 기다리지 않고 async로 로딩한다.
export const storeAsyncLoad = true;

// app이 'inactive' 또는 'background' 상태에서 active' 상태가 되었을때 pincode를 다시 입력할 시간(ms)
export const ReactiveAutoLockDelay = 20 * 60 * 1000;
// 앱을 재시작할 시간
export const RestartSecurityDelay = 6 * 60 * 60 * 1000;
// 앱 재접속 시간
export const ReactiveReconnetDelay = 10 * 1000;

// app 재시작시 딜레이 시간 - deeplink를 store에 저장하는 시간을 위해
// handleLinkingHandler()가 트리거되길 대기
export const RestartDelay = 2 * 1000;

// mainnet, test을 사용할지 여부
// updates.url 변경을 해야한다.  update <-> update_dev
export const MainNet = true;
// 데브 서버
export const Dev = true;
