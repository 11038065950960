import { resetStreamingStoredData, setLastHistoryData, subscribeOnStream, unsubscribeFromStream } from "../../public/custom_data/streaming.js";
import * as Common from '../../src/constants/Common';
import { NetworkService } from "@/network"
import { XingAPIService } from '../../src/network/XingAPIService';
import * as ChartConst from '../../src/constants/Chart';
import {useStore} from '../../src/pinia';

// 0: none, 1: start, 2: history received, 3: history generated
export const FEED_STATE_NONE = 0;
export const FEED_STATE_START = 1;
export const FEED_STATE_HISTORY_RECEIVED = 2;
export const FEED_STATE_HISTORY_RENDERED = 3;

//https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.DatafeedConfiguration/
// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: Common.DEFAULT_RESOLUTION_ARRAY,
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    // exchanges: [
    //     // { value: 'Ex1', name: 'Ex1', desc: 'Ex1'},
    //     // { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex'},
    //     // { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange'},
    // ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    // symbols_types: [
    //     { name: 'futures', value: 'futures'},
    //     { name: 'crypto', value: 'crypto'},
    //     { name: 'stock', value: 'stock'}
    // ]
};

// static data
const customDatafeedData = {
    currentState: FEED_STATE_NONE,
    currentResolution: null,
    term: null,
    period: null,
    symbolInfo: null
}

export function getCurrentState() { return customDatafeedData.currentState; }

// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map();

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
    let allSymbols = [];

    // "symbol": "AAPL",
    // "description": "Apple Inc.",
    // "type": "stock",
    // "exchange": "NASDAQ",
    // "currency_code": "USD",
    // "logoid": "apple",
    // "provider_id": "ice",
    // "country": "US",
    // "is_primary_listing": true,

    //https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API/
    //https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Datafeed.LibrarySymbolInfo/
    //https://www.tradingview.com/charting-library-docs/latest/api/modules/Charting_Library/#symboltype
    //stock,index,forex,futures,bitcoin,crypto,undefined,expression,spread,cfd,economic,equity,dr,bond,right,warrant,fund,structured,commodity,fundamental,spot
    //https://www.tradingview.com/charting-library-docs/latest/ui_elements/timezones/

    //Decimal Format
    //pricescale — a number of decimal places or fractions that the price has.
    //minmov — a number of units that represents the price tick.
    //pricescale should be 10^n, where n is the number of decimal places. For example, if the price is 1.01, set pricescale to 100.
    //minmov depends on the tick size that is calculated as minmov / pricescale. For example, if the tick size is 0.25, set minmov to 25.

    const store = useStore();
    for(let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
        if(store.MarketState.bDrawMenu[futCode]) {
            let item = {
                base_name: Common.g_szFuturesName[futCode],
                description: Common.g_szFuturesName[futCode],
                full_name: Common.g_szFuturesName[futCode],
                symbol: Common.g_szFuturesNameMini[futCode],
                minmov: Common.g_bFuturesUnitOfDigit[futCode] * Common.G_ONETICK[futCode],
                pricescale: 10 ** Common.g_bFuturesUnitOfDigit[futCode],
                type: '', // 우측 끝 표기
                exchange: '',
                //timezone: 'Etc/UTC',
                //timezone: 'Asia/Hong_Kong',
                //session: moment(store.MarketState.tOpen[futCode].getTime()).format('HHmm') + '-' + moment(store.MarketState.tClose[futCode].getTime()).format('HHmm'),
                session: '24x7',
                customFuturesCode: futCode,
                customTimeModifier: Common.g_timezoneModifier[futCode],
                customDecimal: Common.g_bFuturesUnitOfDigit[futCode],
            }
            if (futCode === Common.G_FUTURES_OIL || futCode === Common.G_FUTURES_GOLD || futCode === Common.G_FUTURES_SILVER || futCode === Common.G_FUTURES_GAS || futCode === Common.G_FUTURES_COPPER)
            {
                // 뉴욕거래소
                item.timezone = 'America/New_York';
            }
            else if (futCode === Common.G_FUTURES_HANGSENG)
            {
                // 홍콩 거래소
                item.timezone = 'Asia/Hong_Kong';
            }
            else if (futCode === Common.G_FUTURES_CHINA50)
            {
                // 싱가포르 거래소
                item.timezone = 'Asia/Singapore';
            }
            else if (futCode >= Common.G_FUTURES_OVERSEAS)
            {
                // 시카고거래소
                item.timezone = 'America/Chicago';
            }
            else
            {
                item.timezone = 'Asia/Seoul';
            }

            item.timezone = 'Etc/UTC';

            allSymbols.push(item);
        }
    }
    return allSymbols;
}

export default {
    onReady: (callback) => { setTimeout(() => callback(configurationData)); },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        onResultReadyCallback(newSymbols);
    },

    //https://www.tradingview.com/charting-library-docs/latest/connecting_data/Symbology/
    //https://www.tradingview.com/charting-library-docs/latest/tutorials/implement_datafeed_tutorial/Datafeed-Implementation

    //session
    //https://www.tradingview.com/charting-library-docs/latest/connecting_data/Trading-Sessions/
    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        const symbols = await getAllSymbols();
        let symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
        // const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);

        if (!symbolItem) { symbolItem = symbols.find(({ base_name }) => base_name === symbolName); }
        if (!symbolItem) { symbolItem = symbols.find(({ full_name }) => full_name === symbolName); }

        if (!symbolItem) {
            onResolveErrorCallback('Cannot resolve symbol: ' + symbolName);
            return;
        }
        const symbolInfo = {
            customFuturesCode: symbolItem.customFuturesCode,
            customTimeModifier: symbolItem.customTimeModifier,
            customDecimal: symbolItem.customDecimal,
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            timezone: symbolItem.timezone,
            session: symbolItem.session,
            exchange: symbolItem.exchange,
            minmov: symbolItem.minmov,
            pricescale: symbolItem.pricescale,
            supported_resolutions: configurationData.supported_resolutions,
            ticks_multipliers: ['1T', '3T', '5T'],  // 틱 설정값 안먹음, 라이브러리에도 없음
            tick_multipliers: ['1T', '3T'],         // 틱 설정값 안먹음, 라이브러리에도 없음
            data_status: 'streaming',
            visible_plots_set: 'ohlcv',
            has_intraday: true,
            has_ticks: true,
            //fractional: false,
            //has_seconds: false,
            //has_weekly_and_monthly: false,
            //volume_precision: 2,
        };
        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {    
        const { firstDataRequest } = periodParams;
        // 차트 종목, 틱/분 선택 이벤트
        const store = useStore();

        // 서머타임 매번 다시계산
        store.GetTimeGap();


        // 조작 불가
        //periodParams.from = moment('20231112000000', 'YYYYMMDDHHmmss').toDate().getTime() / 1000;
        //periodParams.to = moment('20231108192800', 'YYYYMMDDHHmmss').toDate().getTime() / 1000;
        //periodParams.to = new Date().getTime() / 1000;

        // const isPrintLog = store.SystemState._debugChartLog >= Constant.DebugLevel_Normal;
        const isPrintLog = false;
        //console.log('--- [getBars]: call', firstDataRequest, new Date(periodParams.from * 1000), new Date(periodParams.to * 1000));

        if(firstDataRequest) {
            if(isPrintLog)
                console.log('[getBars]: call', symbolInfo.customFuturesCode, resolution, firstDataRequest, periodParams);

            // reset data
            resetStreamingStoredData();

            let reqPeriod;
            let reqTerm;
            switch('' + resolution) {
                case '1T':
                case '3T':
                case '5T':
                case '10T':
                case '20T':
                case '30T': reqPeriod = ChartConst.cPeriod_Tick; break;
                case '1':
                case '3':
                case '5':
                case '10':
                case '20':
                case '30': reqPeriod = ChartConst.cPeriod_Minute; break;
                case '1D': reqPeriod = ChartConst.cPeriod_Daily; break;
                case '1W': reqPeriod = ChartConst.cPeriod_Weekly; break;
                case '1M': reqPeriod = ChartConst.cPeriod_Monthly; break;
                default: {
                    reqPeriod = ChartConst.cPeriod_Minute;
                    if(isPrintLog)
                        console.error('out of resolution: ' + resolution);
                }
            }

            switch('' + resolution) {
                case '1T': reqTerm = ChartConst.cTerm_1; break;
                case '3T': reqTerm = ChartConst.cTerm_3; break;
                case '5T': reqTerm = ChartConst.cTerm_5; break;
                case '10T': reqTerm = ChartConst.cTerm_10; break;
                case '20T': reqTerm = ChartConst.cTerm_20; break;
                case '30T': reqTerm = ChartConst.cTerm_30; break;
                case '1': reqTerm = ChartConst.cTerm_1; break;
                case '3': reqTerm = ChartConst.cTerm_3; break;
                case '5': reqTerm = ChartConst.cTerm_5; break;
                case '10': reqTerm = ChartConst.cTerm_10; break;
                case '20': reqTerm = ChartConst.cTerm_20; break;
                case '30': reqTerm = ChartConst.cTerm_30; break;
                case '1D':
                case '1W':
                case '1M': reqTerm = ChartConst.cTerm_1; break;
                default: {
                    reqTerm = ChartConst.cTerm_1;
                    if(isPrintLog)
                        console.error('out of resolution: ' + resolution);
                }
            }

            // API 요청전
            // store data 필요한것들 변경필요
            // 이거.. 필요없을듯..
            //store.AccountState.G_Future_Code = symbolInfo.customFuturesCode;

            // 차트에서 사용할것들
            store.setCurrentChartLayoutInfo(symbolInfo.customFuturesCode, symbolInfo.name, resolution);

            // set static data
            customDatafeedData.term = reqTerm;
            customDatafeedData.period = reqPeriod;
            customDatafeedData.symbolInfo = symbolInfo;
            customDatafeedData.onHistoryCallback = onHistoryCallback;
            customDatafeedData.currentState = FEED_STATE_START;
            customDatafeedData.countBarReq = 1;
            customDatafeedData.currentResolution = resolution;

            NetworkService.send.chartDataReq({ ReqId: symbolInfo.customFuturesCode, Futures: symbolInfo.customFuturesCode, Period: reqPeriod, Term: reqTerm });
        }
        else {
            customDatafeedData.countBarReq++;
        }

        let bars = [];
        try {
            // 히스토리 다 받았느지 확인 이후 히스토리 콜백 리턴
            if(customDatafeedData.currentState < FEED_STATE_HISTORY_RECEIVED) {
                if(isPrintLog) {
                    // 다 안받았으면 대기
                    console.log('[getBars]: receiving retry: ' + customDatafeedData.countBarReq);
                }
                await setTimeout(() => {
                    onHistoryCallback([], { noData: false });
                    return;
                }, 500);
            }
            else if(customDatafeedData.currentState === FEED_STATE_HISTORY_RENDERED) {
                if(isPrintLog) {
                    // 히스토리 생성 이후 호출
                    console.log('[getBars]: already generated: ' + customDatafeedData.countBarReq);
                }
                onHistoryCallback([], { noData: true });
                return;
            }
            else {
                if(isPrintLog) {
                    console.log('[getBars]: generate history start: ' + customDatafeedData.countBarReq);
                }
                customDatafeedData.currentState = FEED_STATE_HISTORY_RENDERED;

                // 1일 데이터만 시간 예외처리
                let timeModifier = customDatafeedData.symbolInfo.customTimeModifier;
                switch('' + resolution) {
                    case '1D': 
                    case '1W': 
                    case '1M': 
                    timeModifier = 3600000*9;
                        break;
                }

                let timeBase = 0;
                if (symbolInfo.customFuturesCode === Common.G_FUTURES_OIL || symbolInfo.customFuturesCode === Common.G_FUTURES_GOLD || symbolInfo.customFuturesCode === Common.G_FUTURES_SILVER || symbolInfo.customFuturesCode === Common.G_FUTURES_GAS || symbolInfo.customFuturesCode === Common.G_FUTURES_COPPER)
                {
                    // 뉴욕거래소
                    timeBase = store.SettingsState.timeGapNYMEX - (timeModifier / 3600000);
                }
                else if (symbolInfo.customFuturesCode === Common.G_FUTURES_HANGSENG)
                {
                    // 홍콩 거래소
                    //timeBase = store.SettingsState.timeGapHKEX - (timeModifier / 3600000);
                }
                else if (symbolInfo.customFuturesCode === Common.G_FUTURES_CHINA50)
                {
                    // 싱가포르 거래소
                    //timeBase = store.SettingsState.timeGapSGX - (timeModifier / 3600000);
                }
                else if (symbolInfo.customFuturesCode >= Common.G_FUTURES_OVERSEAS)
                {
                    // 시카고거래소
                    timeBase = store.SettingsState.timeGapCME - (timeModifier / 3600000);
                }
                else if (symbolInfo.customFuturesCode === Common.G_FUTURES_DAY)
                {   
                    timeBase = 0;
                }
                timeModifier += (3600000 * timeBase);

                let storedObj = XingAPIService.getStoredBlockObj(customDatafeedData.symbolInfo.customFuturesCode, customDatafeedData.period, customDatafeedData.term );
                storedObj.forEach(bar => {
                    bars = [...bars, {
                        time: bar.date + timeModifier,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                        volume: bar.volume,
                    }];
                    //console.log('--', new Date(bar.date + timeModifier));
                });
                

                // 최종 체결가 넣기
                // 마지막값 조절하면 차트 우측 체결가 값은 바뀌는데 차트 데이터 종가가 안맞음
                // if (store.MasterState.isReadyRECV && bars.length > 0) {
                //     //bars[bars.length-1].close = store.MasterState.dbCurPrice[customDatafeedData.symbolInfo.customFuturesCode];
                //     //dbCurPrice: store.MasterState.dbCurPrice[futures],
                //     //dbBPrice: store.MasterState.dbBPrice[futures],
                //     //console.log('', store.MasterState.szCTime[customDatafeedData.symbolInfo.customFuturesCode], new Date(bars[bars.length-1].time), bars[bars.length-1].time);
                // }

                // 날짜별 정렬, 보류
                //bars = bars.sort((a, b) => a.time - b.time);
                //console.log('-------', bars.length);
                
                if(isPrintLog) {
                    //console.log('[getBars]: storedObj', storedObj);
                    //console.log('bars', bars);
                    console.log(`[getBars]: returned ${bars.length} bar(s)`);
                }

                onHistoryCallback(bars, { noData: false });

                
            }
        } catch (error) {
            if(isPrintLog)
                console.error('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID,
        onResetCacheNeededCallback
    ) => {
        //console.log('[subscribeBars]: subscriberUID:', subscriberUID, resolution, symbolInfo);
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.full_name)
        );
    },

    unsubscribeBars: (subscriberUID) => {
        //console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        unsubscribeFromStream(subscriberUID);
    },

    onHistoryData: (data) => {
        // 기존 히스토리 분할되어 받아짐, 별도 처리할 수 있는것 없음
        //console.log('[onHistoryData]: ', data);
    },

    onHistoryReceiveFinished: (data) => {
        // PROTOCOL_CHART_DATA_ACK 이벤트
        const store = useStore();
        if(customDatafeedData.currentState === FEED_STATE_START) {
            // 1일 데이터만 시간 예외처리
            let timeModifier = customDatafeedData.symbolInfo.customTimeModifier;
            switch('' + customDatafeedData.currentResolution) {
                case '1D': 
                case '1W': 
                case '1M': 
                timeModifier = 3600000*9;
                    break;
            }
            let timeBase = 0;
            if (customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_OIL || customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_GOLD || customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_SILVER || customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_GAS || customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_COPPER)
            {
                // 뉴욕거래소
                timeBase = store.SettingsState.timeGapNYMEX - (timeModifier / 3600000);
            }
            else if (customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_HANGSENG)
            {
                // 홍콩 거래소
                //timeBase = store.SettingsState.timeGapHKEX - (timeModifier / 3600000);
            }
            else if (customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_CHINA50)
            {
                // 싱가포르 거래소
                //timeBase = store.SettingsState.timeGapSGX - (timeModifier / 3600000);
            }
            else if (customDatafeedData.symbolInfo.customFuturesCode >= Common.G_FUTURES_OVERSEAS)
            {
                // 시카고거래소
                timeBase = store.SettingsState.timeGapCME - (timeModifier / 3600000);
            }
            else if (customDatafeedData.symbolInfo.customFuturesCode === Common.G_FUTURES_DAY)
            {
                timeBase = 0;
            }
            
            timeModifier += (3600000 * timeBase);

            let lastData = null;
            let storedObj = XingAPIService.getStoredBlockObj(customDatafeedData.symbolInfo.customFuturesCode, customDatafeedData.period, customDatafeedData.term );
            if(storedObj.length > 0) {
                let lastItem = storedObj[storedObj.length-1];
                lastData = {
                    time: lastItem.date + timeModifier,
                    low: lastItem.low,
                    high: lastItem.high,
                    open: lastItem.open,
                    close: lastItem.close,
                    volume: lastItem.volume
                }
            }
            else {
                //console.error('저장된 데이터 없음');
            }

            //console.log('----last ',timeBase, lastData, new Date(lastData.time));

            setLastHistoryData(data, lastData);

            // 모든 데이터 처리후
            customDatafeedData.currentState = FEED_STATE_HISTORY_RECEIVED;
        }
    },
};