<template>
    <div class="wrapPC">
		<!-- /헤더/ -->
		<PcHeader />
		<!-- /몸통/ -->
		<div class="body-box " style="padding-bottom:0">
			<div class="con-box top">
				<div class="left">
					<div class="flex-center mb10">
						<div class="stock-select-box designBox">
							<div class="flex-center-between">
								<div class="dropdown-sort">
									<div class="select-sort">
										<input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickFuturesOpen" />
										<label for="dropPageCount" class="for-dropsort pageCount">
											<div class="drop-out-wrap">
												<p class="out " v-html="state.pickFuturesDesc"></p>
												<i class="icon-down" :class="[{ on: state.pickFuturesOpen }]" />
											</div>
										</label>
										<div class="section-sort" v-if="state.pickFuturesValue">
											<p v-for="(item, index) in state.pickFuturesItems" :key="index" @click="onPickFuturesChangeValue(item)" class="text" :class="{ active: item.value === state.pickFuturesValue }">
												<span class="big-span">{{ item.label }}</span>
												<span class="small-span">{{ item.desc }}</span>
											</p>
										</div>
									</div>
								</div>
								<div class="price-box">
									<div class="flex price">
										<div class="flex" :style="{color:state.diffColor}">
											{{ XingAPIService.GetSignData(state.전일대비기호) }} 
											<p class="percent ml0" :style="{color:state.diffColor}">{{ Utils.PriceFormatStr(state.전일대비, state.futures) }}</p>
										</div>
										<p class="percent" :style="{color:state.diffColor}">
											{{ state.등락률?.toFixed(2) }}%
										</p>
										<h1 class="main"  :style="{color:state.priceColor}">
											{{ Utils.PriceFormatStr(state.현재가, state.futures) }}
										</h1>
									</div>
								</div>
							</div>
						</div>
						<button class="chart-view-btn" @click="chartView" :class="{'on': state.zIndex}">
							<span class="text">차트</span>
						</button>
					</div>
					<div class="relative-box">
						<div class="chart-box designBox" :class="{'on' : state.zIndex}">
                            <PcTVChartContainer :initFuturesItem="state.pickFuturesSelectedItem" @onActionMarketTrade="onActionMarketTrade" />
						</div>
						<div class="order-box" :class="{'on' : !state.zIndex}">
							<div class="amount-box designBox">
								<ul class="manual-line">
									<li class="case manual-item">건수</li>
									<li class="amount manual-item">잔량</li>
									<li class="fix manual-item">호가</li>
									<li class="amount manual-item">잔량</li>
									<li class="case manual-item last">건수</li>
								</ul>
								<div class="content-box">
									<div class="flex-start">
										<div class="box sell">
											<div class="sell-item" v-for="(item, idx) in state.sellData[0]" :key="idx">
												<p class="case bdrWhite" >{{ state.sellData[2][idx].title }}</p>
												<p class="amount bdrWhite" :style="state.sellData[1][idx].textColor">{{ state.sellData[1][idx].title }}</p>
												<p class="fix bdrWhite" :style="item.textColor" @click="onPricePress(item.title)">{{ item.title }}</p>
											</div>
										</div>
										<div class="info-box">
											<div class="info-item flex-center-between">
												<p class="title">국내예탁금</p>
												<p class="value">{{ toLocaleString(state.depoAmount) }}</p>
											</div>
											<div class="info-item flex-center-between">
												<p class="title">국내평가예탁금</p>
												<p class="value">{{ toLocaleString(state.estimatedAmount) }}</p>
											</div>
											<div class="info-item flex-center-between">
												<p class="title">해외예탁금</p>
												<p class="value">{{ toLocaleString(state.depoAmountOversea) }}</p>
											</div>
											<div class="info-item flex-center-between">
												<p class="title">해외평가예탁금</p>
												<p class="value">{{ toLocaleString(state.estimatedAmountOversea) }}</p>
											</div>
											<div class="info-item flex-center-between">
												<p class="title">총평가손익</p>
												<p class="value" :class="[{ up: state.totalProfit > 0}, {down: state.totalProfit < 0}]">{{ toLocaleString(state.totalProfit) }}</p>
											</div>
											<div class="info-item flex-center-between">
												<p class="title">국내실현손익</p>
												<p class="value" :class="[{ up: state.netDomestic > 0}, {down: state.netDomestic < 0}]">{{ toLocaleString(state.netDomestic) }}</p>
											</div>
											<div class="info-item flex-center-between mb-0">
												<p class="title">해외실현손익</p>
												<p class="value" :class="[{ up: state.netOversea > 0}, {down: state.netOversea < 0}]">{{ toLocaleString(state.netOversea) }}</p>
											</div>
										</div>
									</div>
									<div class="flex-start">
										<div class="live-box ">
											<div class="head-box">
												<p class="case ">가격</p>
												<p class="amount bdlWhite">수량</p>
											</div>
											<div class="live-item" v-for="(quote, idx) in state.quoteData.slice().reverse()" :key="idx">
												<p class="case" :style="{color: getQuoteDataColor('price', quote)}">{{ Utils.PriceFormatStr(quote.dbCurPrice, quote.iFutures) }}</p>
												<p class="amount" :style="{color: getQuoteDataColor('count', quote)}">{{ quote.iTrdq }}</p>
											</div>
										</div>
										<div class="box buy">
											<div class="buy-item" v-for="(item, idx) in state.buyData[0]" :key="idx">
												<p class="fix bdlWhite" :style="item.textColor" @click="onPricePress(item.title)">{{ item.title }}</p>
												<p class="amount bdlWhite" :style="state.buyData[1][idx].textColor">{{ state.buyData[1][idx].title }}</p>
												<p class="case bdlWhite">{{ state.buyData[2][idx].title }}</p>
											</div>
										</div>
									</div>
								</div>
								<ul class="number-line" v-if="state.totalData.length > 0">
									<li class="number-item case bdrWhite lb-radius">{{ state.totalData[0][2] }}</li>
									<li class="number-item amount bdrWhite">{{ state.totalData[0][1] }}</li>
									<li class="number-item fix bdrWhite">{{ state.clock }}</li>
									<li class="number-item amount bdrWhite">{{ state.totalData[0][1] }}</li>
									<li class="number-item case rb-radius">{{ state.totalData[0][2] }}</li>
								</ul>
							</div>
							<!-- /small amount box/ -->
							<div class="small-amount-box designBox">
								<div class="title-box flex-center top">
									<p class="text after" :class="{ on: state.tabIdx === Quote_Call }" @click="onQuoteTabPress(Quote_Call)">호가</p>
									<p class="text" :class="{ on: state.tabIdx === Quote_Contract }"  @click="onQuoteTabPress(Quote_Contract)">체결</p>
								</div>
								<div class="number-box ">
									<div v-if="state.tabIdx === Quote_Call">
										<div class="title-box flex-center">
											<p class="text line-box">고가</p>
											<p class="text value">{{ state.highData.title }}</p>
										</div>
										<div class="list-box ">
											<div class="sell-area flex-center"  v-for="(item,idx) in state.sellData[0]" :key="idx" @click="onPricePress(item.title)">
												<div class="price box line-box">
													<p :style="item.textColor"> {{ item.title }} <span>{{ item.changeRate }}</span> </p>
												</div>
												<div class="amount box">
													<p>{{ state.sellData[1][idx].title }}</p>
												</div>
											</div>
											<div class="buy-area flex-center"  v-for="(item, idx) in state.buyData[0]" :key="idx" @click="onPricePress(item.title)">
												<div class="price box line-box">
													<p :style="item.textColor"> {{ item.title }} <span>{{ item.changeRate }}</span> </p>
												</div>
												<div class="amount box">
													<p>{{ state.buyData[1][idx].title }}</p>
												</div>
											</div>
										</div>
										<div class="under-box flex-center">
											<p class="text line-box">저가</p>
											<p class="text value">{{ state.lowData.title }}</p>
										</div>
									</div>
									<div class="contract-box" v-else>
										<div class="title-box">
											<div class="info-box flex-center-between mt-0">
												<p class="name">시가</p>
												<p class="price" :style="{color: state.infoData.시가.color}">{{ state.infoData.시가.value }}</p>
											</div>
											<div class="info-box flex-center-between">
												<p class="name">고가</p>
												<p class="price" :style="{color: state.infoData.고가.color}">{{ state.infoData.고가.value }}</p>
											</div>
											<div class="info-box flex-center-between">
												<p class="name">저가</p>
												<p class="price" :style="{color: state.infoData.저가.color}">{{ state.infoData.저가.value }}</p>
											</div>
											<div class="info-box flex-center-between">
												<p class="name">거래량</p>
												<p class="price gray" :style="{color: state.infoData.거래량.color}">{{ state.infoData.거래량.value }}</p>
											</div>
										</div>
										<div class="list-box">
											<div class="sell-area flex-center" v-for="(quote, idx) in state.quoteData.slice().reverse()" :key="idx">
												<div class="price box line-box">
													<p :style="{color: getQuoteDataColor('price', quote)}">
														{{ Utils.PriceFormatStr(quote.dbCurPrice, quote.iFutures) }}
														<!-- <span>88.88%</span> -->
													</p>
												</div>
												<div class="amount box">
													<p :style="{color: getQuoteDataColor('count', quote)}">{{ quote.iTrdq }}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="timer-box flex-center">
									<p class="text"> {{state.clock}}</p>
								</div>
							</div>
							<!-- // -->
							<div class="control-box designBox">
								<ul class="select-tab">
									<li class="tab-item" @click="onTabPress(Trade_Buy)" :class="{ onBuy: state.tradingIdx === Trade_Buy }">매수</li>
									<li class="tab-item" @click="onTabPress(Trade_Sell)" :class="{ onSell: state.tradingIdx === Trade_Sell }">매도</li>
									<li class="tab-item SL" @click="onTabPress(Trade_Stoploss)" :class="{ onSL: state.tradingIdx === Trade_Stoploss }">S/L예약주문</li>
									<li class="tab-item" @click="onTabPress(Trade_Correct)" :class="{ onCancel: state.tradingIdx === Trade_Correct }">정정/취소</li>
								</ul>
								<div class="control-item">
									<div v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell ">
										<div class="divide box" >
											<p class="text">주문구분</p>
											<div class="radio-box">
												<label class="label-radio first" >
													<input type="radio"  :value="Common.G_PRICE_APPOINT" v-model="state.selectedPriceType" @change="onChangePriceType" />
													지정가
												</label>
												<label class="label-radio ">
													<input type="radio"  :value="Common.G_PRICE_MARKET"  v-model="state.selectedPriceType" @change="onChangePriceType" />
													시장가
												</label>
											</div>
										</div>
										<div class="my-money box">
											<p class="text">예탁금</p>
											<p class="price" v-if="state.isOversea === Common.eNation_Domestic">{{ toLocaleString(state.depoAmount) }}</p>
                                        	<p class="price" v-else>{{ toLocaleString(state.depoAmountOversea) }}</p>
										</div>
										<div class="able-order box">
											<p class="text">주문가능</p>
											<div class="btn-box">
												<button class="new" @click="refreshOrderCount('orderLeft')">
													<span class="text">신규(주문)</span>
												</button>
												<button class="clear" @click="refreshOrderCount('settleLeft')">
													<span class="text">청산</span>
												</button>
											</div>
										</div>
									</div>
									<div class="order-number box" v-if="state.tradingIdx === Trade_Correct ">
										<p class="text">주문번호</p>
										<div class="input-area">
											<input type="text" placeholder="주문번호" v-model="state.orgOrderNo" readonly/>
											<button class="flex-center-center">
												<span class="text" @click="onLeftPress()">미체결</span>
											</button>
										</div>
									</div>
									<div class="order-amount box">
										<p class="text">주문수량</p>
										<div class="edit-box flex-center">
											<button class="minus" v-if="state.tradingIdx !== Trade_Stoploss && state.tradingIdx !== Trade_Correct" @click="onChangeAmount(-1)">
												<img src="../../assets/images/minus.svg" alt="">
											</button>
											<input  class="editInput" type="text" v-model="state.amount" :readonly="state.tradingIdx === Trade_Stoploss || state.tradingIdx === Trade_Correct" @keyup="onChangeAmount()">
											<button class="plus" v-if="state.tradingIdx !== Trade_Stoploss && state.tradingIdx !== Trade_Correct" @click="onChangeAmount(1)">
												<img src="../../assets/images/plus.svg" alt="">
											</button>
										</div>
									</div>
									<div class="order-price box">
										<p class="text">주문가격</p>
										<div class="edit-box flex-center">
											<button class="minus" v-if="state.tradingIdx !== Trade_Stoploss" @click="onChangePrice(-1)" >
												<img src="../../assets/images/minus.svg" alt="">
											</button>
											<input class="editInput" type="text" value="시장가" v-if="Number(state.selectedPriceType) === Common.G_PRICE_MARKET && (state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell)" readonly/>
                                    		<input class="editInput" type="text" v-model="state.price" v-else :readonly="state.tradingIdx === Trade_Stoploss"/>
											<button class="plus" v-if="state.tradingIdx !== Trade_Stoploss" @click="onChangePrice(1)" >
												<img src="../../assets/images/plus.svg" alt="">
											</button>
										</div>
									</div>
									<div v-if="state.tradingIdx === Trade_Stoploss ">
										<div class="clear box">
											<p class="text">청산조건(이익)</p>
											<div class="edit-box flex-center">
												<button class="minus" @click="onProfitAmount(-1)">
													<img src="../../assets/images/minus.svg" alt="">
												</button>
												<input  class="editInput"  type="text" placeholder="이익" v-model="state.profitTicks" />
												<button class="plus" @click="onProfitAmount(1)">
													<img src="../../assets/images/plus.svg" alt="">
												</button>
											</div>
										</div>
										<div class="clear box">
											<p class="text">청산조건(손실)</p>
											<div class="edit-box flex-center">
												<button class="minus" @click="onLossAmount(-1)">
													<img src="../../assets/images/minus.svg" alt="">
												</button>
												<input  class="editInput"  type="text" placeholder="손실" v-model="state.lossTicks" />
												<button class="plus" @click="onLossAmount(1)">
													<img src="../../assets/images/plus.svg" alt="">
												</button>
											</div>
										</div>
									</div>
                                    <div v-if="store.SystemState._appMode === 2">
                                        <p class="leverage-text" @click="showPopupLeverage()" v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell">
                                            <span> 레버리지 [1 : {{ state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro }}]</span>
                                        </p>
                                    </div>
								</div>
								<div class="confirm-btn-box flex-center">
									<!-- /cancel -->
									<button class="cancel buy" v-if="state.tradingIdx === Trade_Buy" @click="onPressInit()">초기화</button>
									<button class="cancel sell" v-else-if="state.tradingIdx === Trade_Sell" @click="onPressInit()">초기화</button>
									<button class="cancel sl" v-else-if="state.tradingIdx === Trade_Stoploss" @click="onCancelCorrect()">S/L취소</button>
									<button class="cancel edit" v-else @click="onCancelCorrect()">취소주문</button>
									<!-- /ok/ -->
									<button class="confirm buy" v-if="state.tradingIdx === Trade_Buy" @click="onActionPress()">매수</button>
									<button class="confirm sell" v-else-if="state.tradingIdx === Trade_Sell" @click="onActionPress()">매도</button>
									<button class="confirm sl" v-else-if="state.tradingIdx === Trade_Stoploss" @click="onActionPress()">S/L집행</button>
									<button class="confirm edit" v-else @click="onActionPress()">정정주문</button>
								</div>
							</div>
						</div>
						<!-- /small-order-box/ -->
					</div>
				</div>
				<PcStockList :initIsOnEvent="state.initIsOnEvent" @reqChangeFutures="reqChangeFutures" />
			</div>
		</div>
		<div class="body-box subPage transactionForm tradeList" style="padding-top:0" :class="{'main':route.name === 'PcIndex'}">
			<div class="con-box bottom">
				<div class="left index">
					<div class="trade-list-box designBox">
						<PcTradeTable :initBAccPAssed="state.bAccPassed" @reqCheckAccPassword="reqCheckAccPassword"/>
					</div>
				</div>
			</div>
		</div>
        <!-- <PopupLeftOrders :isShowPopup="state.isShowPopupLeftOrders" :initLeftOrders="state.leftOrders" @onCorrectSelectedPress="onCorrectSelectedPress" /> -->
        <PcAccountInfoPopup :isShowPopup="state.isShowAccountInfoPopup" :initFutures="state.futures" :initBAccPAssed="state.bAccPassed" @close="close('PopupAccountInfo')" @checkAccPwdSuccess="checkAccPwdSuccess" />
        <PcPopupNotice :initShowNotice="state.initShowNoticePopup" :initTitle="SLNoticeTitle" :initNoticeMessage="SLNoticeContent" @noToday="onPressNotToday" @close="close('PopupNotice')" />
        <PcLeftOrdersPopup :isShowPopup="state.isShowPopupLeftOrders" :initLeftOrders="state.leftOrders" @onCorrectSelectedPress="onCorrectSelectedPress"/>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive, watch,route } = useBaseComponent();

import { Theme } from '../../constants';
// import i18n from '../services/i18n';
import PcLeftOrdersPopupVue from "@/components/PC/PcLeftOrdersPopup.vue";
import moment from '../../utils/moment';
import * as Common from '../../constants/Common';
import { toLocaleString } from '../../utils/number';
import * as Utils from '../../utils/Common';
import { NetworkService, Emit_DrawTrans, Emit_UserOption, Emit_ResetAmount, XingAPIService, Emit_ContractData, Emit_CallData, Emit_Success } from '../../network';
import { UserLog } from '../../network/UserLog';
import * as AccPwd from '../../constants/AccPwd';

// 탭 구분
const Trade_Buy = 0;     //매수
const Trade_Sell = 1;    //매도
const Trade_Stoploss = 2; //S/L예약주문
const Trade_Correct = 3; //정정/취소

// 화면 갱신
const REFRESH_INTERVAL = 300;  //100;

// 호가/체결 탭 구분
const Quote_Call = 0; // 초가
const Quote_Contract = 1;   // 체결
// const Quote_Max = 2;

const SELL_LINE_MAX = 5;
const BUY_LINE_MAX = 5;

// 체결 데이터 수
const QUOTE_DATA_MAX = 20;

// 패스워드 확인 요청 이벤트
const CHECK_REQ_TYPE_NONE = 0
const CHECK_REQ_TYPE_ACTION = 1
const CHECK_REQ_TYPE_SEARCH = 2

const GRID_SELL_COLOR = { backgroundColor: Theme.mts.gridSellBg };
const GRID_BUY_COLOR = { backgroundColor: Theme.mts.gridBuyBg };
const GRID_CENTER_COLOR = { backgroundColor: Theme.mts.gridCenter };
const GRID_CURPRICE_COLOR = { backgroundColor: Theme.mts.bgCurPrice };
const GRID_TEXT_CURPRICE_COLOR = { color: Theme.mts.textCurPrice };
const GRID_TEXT_BASEPRICE_COLOR = { color: Theme.mts.textBasePrice };
const TEXT_COLOR = { color: Theme.mts.text };

const TradingInfo = [
    // Trade_Buy
    { name: '매수', button: '매수', color: '#e74e36', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#f7392c', },
    // Trade_Sell
    { name: '매도', button: '매도', color: '#2754a0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#35569a', }, 
    // Trade_Stoploss
    { name: 'S/L 주문', button: 'S/L 집행', button2: 'S/L 취소', color: '#0bbfe0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#68badb', }, 
    // Trade_Correct
    { name: '정정/취소', button: '정정주문', button2: '취소주문', color: '#2ec751', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#5fc25b', },
];

// S/L 예약 유의사항
const SLNoticeTitle = '해외선물 STOP 예약주문 유의사항';
const SLNoticeContent = `
    <div class="totalOrder-text">
        <p class="text blue">
            해외선물 STOP 예약주문은 네트워크 장애, 시세지연, 천재지변등 다양한 요인에 의해 정상적으로 작동하지 않을 수 있으니 반드시 아래의 사용법과 유의사항을 숙지하신 후 이용하여 주시기 바랍니다.
        </p>
        <p class="text ">
            1. STOP 예약주문의 정의 <br /> 기본 개념은 손절매을 위한 주문으로 조건 주문 중 하나, 현재가격이 설정한 조건가격에 도달하게 되면 조건주문이 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            2. STOP 예약주문 집행 방식 및 절차 <br /> 현재가격이 조건가격에 다다를 경우 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            현재가 >= 조건가 (이익/손실 틱)> 시장가 주문
        </p>
        <p class="text red">
            [S/L 집행] 실행 이후 조건을 변경 하면  [S/L 집행] 를 다시 실행해야 합니다. 
        </p>
        <p class="text ">
            3. STOP 예약주문 유의사항 <br /> STOP 예약주문은 현재가격이 조건가격에 <br /> 도달했을 시 시장가 주문으로 접수 / 처리 됩니다.
        </p>
        <p class="text red">
            이로 인해 시장이 급등락하거나, 호가공백이 발생할 경우 체결이 되지 않거나 STOP 가격보다 불리한 가격에 체결이 될 가능성이 있으니 이점 반드시 숙지하시기 바랍니다.
        </p>
    </div>`;

let state = reactive({
    //계좌정보입력팝업
    isShowAccountInfoPopup:false,

    // sl팝업
    initShowNoticePopup:false,

	//zindex 
	zIndex:false,

	// 종목 선택
    pickFuturesOpen: false,
    pickFuturesValue: '',
    pickFuturesItems: [ ] as any,
    pickFuturesDesc: '',
    pickFuturesSelectedItem: null as any,

	// 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false, // 계좌 비밀번호 체크 생략

	//미체결 리스트
    leftOrders: [] as any,
    leftSelected: -1,

    futures: Common.G_FUTURES_DAY,
    oneTick: Common.G_ONETICK[Common.G_FUTURES_DAY],
    isOversea: Common.eNation_Domestic,
	
    depoAmount: 0,				// 국내예탁금액
    depoAmountOversea: 0,		// 해외예탁금액
    estimatedAmount: 0,			// 국내평가예탁금액
    estimatedAmountOversea: 0,	// 해외평가예탁금액
    totalProfit: 0,				// 총평가손익
    netDomestic: 0,				// 국내실현손익
    netOversea: 0,				// 해외실현손익

	// 현재 호가/체결 탭
	tabIdx: Quote_Call,
    //현재 거래 탭
    tradingIdx: Trade_Buy,

	//가격 종류 0:시장가 1:지정가
    selectedPriceType: Common.G_PRICE_APPOINT,  //라디오 버튼

	//주문 입력 정보
	amount: '0',
    amountNumber: 0,
    price: '0',

	//원 주문 정보
    orgOrderNo: '', //원주문번호
    orgPrice: '0', // 정정시 원 가격
    orgTradeType: Common.G_TRADE_SELL, // 정정시 원 유형

    // 주문가능
    orderLeft: 0,

	// 청산가능
    settleLeft: 0,

	// 미체결 / 체결
    leftAmount: 0,
    oppositeAmount: 0,

	// 익절 / 손절
    oppOrder: null as any,
    profitTicks: '',
    lossTicks: '',

	전일가: 0.,
    현재가: 0.,
    전일대비기호: '1',
    전일대비: 0.,
    등락률: 0.,
    priceColor: '',
    diffColor: '', 

	highData: {} as any,
	lowData: {} as any,
	// 매수
    buyData: [[], [], [],] as any,
    // 매도
    sellData: [[], [], [],] as any,
    // 정보
    infoData: {
        종목: Common.G_FUTURES_DAY,
        현재가: 0,
        전일가: 0,
        대비기호: '',
        대비: 0,
        대비율: 0,
        시가: 0,
        고가: 0,
        저가: 0,
        만기일: '',
        환율: '',
        틱가치: '',
        수수료: '',
        잔존일: 0,
        거래량: 0,
    } as any,
    // 체결
    quoteData: [] as any,
    // 합계: 건수 매도 가격 매수 건수
    totalData: [] as any,

	refreshingTime: null as any,

	// 현재시간
    clock: '',
    timerClock: null as any,

	// 종목 선택 세팅 타이머
    timerInitFuturesPicker: null as any,

	// 계좌 정보 세팅
    timerInitData: null as any,

    // 패스워드 확인 요청한 이벤트
    chkPassReqType: CHECK_REQ_TYPE_NONE,

    //실시간 선물 시세에 종목 변경 이벤트 넣을지 여부
    initIsOnEvent: true,

    // 미체결 리스트 팝업
    isShowPopupLeftOrders: false,
});

watch(store.CommonState, (CommonState) => {
    if (CommonState.isChangePwdSetting) {
      // 설정 저장후 바로 반영안됨
      setTimeout(() => {
        initAccPassed();
      }, 1000)
        store.CommonState.isChangePwdSetting = false;
    }
},{ deep: true });

onMounted(()=>{
	if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TotalOrder');

	NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    
    if (store.LoginPageState.isPacketLoginAckReceived) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_UserOption, event_UserOption);
        NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
        NetworkService.emitter.on(Emit_ContractData, event_ContractData);
        NetworkService.emitter.on(Emit_CallData, event_CallData);
        initData();
    }
})

onUnmounted(()=> {
    document.body.style.overflow = "auto";
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_UserOption, event_UserOption);
    NetworkService.emitter.off(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.off(Emit_ContractData, event_ContractData);
    NetworkService.emitter.off(Emit_CallData, event_CallData);

    // NetworkService.emitter.off(Emit_UserOption, event_UserOption);

    // window.removeEventListener('resize', resizeEnded);
    if(state.timerClock){
        clearTimeout(state.timerClock);
    }
    // if(state.timerInitFuturesPicker){
    //     clearTimeout(state.timerInitFuturesPicker);
    // }
    // if(state.timerInitData){
    //     clearTimeout(state.timerInitData);
    // }
    // if(state.resizeTimer){
    //     clearTimeout(state.resizeTimer);
    // }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_UserOption, event_UserOption);
        NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
        NetworkService.emitter.on(Emit_ContractData, event_ContractData);
        NetworkService.emitter.on(Emit_CallData, event_CallData);
        initData();
    }
};

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 업데이트
    refreshOrderCount();
    if (state.tradingIdx === Trade_Stoploss) {
        refreshStoploss();
    }
}

const event_UserOption = (params: any) => {
    // 유저 설정
    // 체결 / 미체결 업데이트
    refreshOrderCount();
}

const event_ResetAmount = (params: any) => {
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    // 체결 / 미체결 업데이트
    refreshOrderCount();
}

const event_ContractData = (params: any) => {
    // ContractObj
    const { contractData } = params;

    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = contractData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    const numOfQuoteData = QUOTE_DATA_MAX;
    // const onContractData = state.onContractData;

    const item = {
        iFutures: contractData.iFutures,
        szCTime: contractData.szCTime,
        iTrdq: contractData.iTrdq,
        dbCurPrice: contractData.dbCurPrice,
        dbBPrice: contractData.dbBPrice,
        szCGubun: contractData.szCGubun,
    };
    state.quoteData.push(item);
    if (state.quoteData.length > numOfQuoteData) {
        state.quoteData.shift();
    }

    updateInfoData(futures, contractData);
    onContractData(contractData);
}

const event_CallData = async (params: any) => {
    // CallObj
    const { callData } = params;
    const futures = callData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    const now = Date.now();
    if (state.refreshingTime === null || (now - state.refreshingTime) > REFRESH_INTERVAL) {
        // 마스터에 이미 업데이트 되었다.
        onRefresh();

        state.refreshingTime = now;
    }
}

const initData = () => {
	//로그인 후 계좌정보가 store에 저장됐는지
	if(!store.LoginPageState.isPacketAccInfoReceived){
		state.timerInitData = setTimeout(() => {
			initData();
		}, 100)
		return;
	}

	const futures = store.FuturesByNow();
	state.futures = futures;

	initFuturesPicker();
	initAccPassed();
	initClock();

	const autoRefreshing = async () => {
		// 예탁금, 평가예탁금, 평가손익
		calcuAmount();
		setTimeout(autoRefreshing, REFRESH_INTERVAL);
	};

	autoRefreshing();
}

// 종목 선택 세팅
const initFuturesPicker = async () => {
    if (!store.MasterState.isReadyRECV) {
        state.timerInitFuturesPicker = setTimeout(() => {initFuturesPicker()}, 200);
        return;
    }

    let pickItems = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        if (store.MarketState.bDrawMenu[futures]) {
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            const expiryDay = _expiryDate.substring(2, 10); // YY-MM-DD
            const futuresName = Common.g_szFuturesNameMini[futures];
            const code = store.MarketState.szFutureCode[futures];
            pickItems.push({
                label: futuresName,
                value: code,
                futures,
                expiryDate: expiryDay,
                desc: `${code} (${expiryDay})`,
                selected: false
            });
        }
    }
    
    let futIdx = pickItems.findIndex(p => p.futures === state.futures);
    if (futIdx < 0) {
        futIdx = 0;
    }
    const pickValue = pickItems[futIdx]?.value;
    state.pickFuturesItems = pickItems;
    state.pickFuturesValue = pickValue;
    state.pickFuturesDesc = pickItems[futIdx]?.label + '<br/>' +  pickItems[futIdx]?.desc;
    state.pickFuturesSelectedItem = pickItems[futIdx];

    onPickFuturesChangeValue(pickItems[futIdx]);
}

const onPickFuturesChangeValue = (param: any) => {
    state.pickFuturesOpen = false;

    state.pickFuturesItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.pickFuturesValue = item.value;
            state.pickFuturesDesc = item.desc;
            state.pickFuturesDesc = item.label + '<span>' + item.desc+ '</span>' ;
            state.pickFuturesItems[index].selected = true;
            state.pickFuturesSelectedItem = item;
            onFuturesChange(item.futures);

        } else {
            state.pickFuturesItems[index].selected = false;
        }
    });

    refreshOrderCount();
}

// 비밀번호 인증 패스 여부
const initAccPassed = () => {
    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;
    // const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();
    // }
}

// 현재 시간
const initClock = () => {
    const tick_on = async () => {
        state.clock = moment(new Date()).format('HH:mm:ss');
        
        state.timerClock = setTimeout(tick_on, 1000);
    }
  
    tick_on();
}

// 체결 정보창
const initInfoData = () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = state.futures;
    if (futures === Common.G_FUTURES_MAX) {
        return;
    }

    // 2자리 년도 제거
    // const expiryDate = store.MarketState.szFutureExpireDate[futures]?.substring(2, 10);
    // const expiryLeftDay = store.MarketState.iFutureExpireLeftDay[futures];
    // const exchRate = futures === Common.G_FUTURES_HANGSENG ? store.BrandState.ExchangeRateHKD : store.BrandState.ExchangeRate;
    // const commissionRate = store.GetFutureCommissionRate(futures);
    // let szCommission = `${commissionRate.toFixed(4)}%`;
    // if (futures >= Common.G_FUTURES_OVERSEAS) {
    //     szCommission = `${futures === Common.G_FUTURES_HANGSENG ? 'HKD' : 'USD'} ${commissionRate.toFixed(2)}`;
    // }

    // const 현재가 = store.MasterState.dbCurPrice[futures];
    const 전일가 = store.MasterState.dbBPrice[futures];
    const 시가 = store.MasterState.dbOpenPrice[futures];
    const 고가 = store.MasterState.dbHighPrice[futures];
    const 저가 = store.MasterState.dbLowPrice[futures];
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = store.MasterState.szYDiffSign[futures];
    // const 대비 = store.MasterState.dbYDiffPrice[futures];
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = store.MasterState.totq[futures];

    // let infoData = {} as any;
    //infoData.종목 = { value: futures, color: null };
    //infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //infoData.대비율 = { value: `${store.MasterState.dbChgRate[futures]}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    //infoData.만기일 = { value: expiryDate, color: Theme.mts.text };
    //infoData.환율 = { value: `₩${toLocaleString(exchRate.toFixed(2))}`, color: Theme.mts.text };
    //infoData.틱가치 = { value: `${toLocaleString(BrandStore.G_TICK_PRICE[futures])}원`, color: Theme.mts.text };
    //infoData.수수료 = { value: szCommission, color: Theme.mts.text };
    //infoData.잔존일 = { value: `${expiryLeftDay}일`, color: Theme.mts.text };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };

    // state.infoData = infoData;
}

// 체결 정보창 갱신
const updateInfoData = (futures: any, contractData: any) => {
    // const 현재가 = contractData.dbCurPrice;
    const 전일가 = contractData.dbBPrice;
    const 시가 = contractData.dbOpenPrice;
    const 고가 = contractData.dbHighPrice;
    const 저가 = contractData.dbLowPrice;
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = contractData.szYDiffSign;
    // const 대비 = contractData.dbYDiffPrice;
    // const 대비율 = contractData.dbChgRate;
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = contractData.totq;

    //this.infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //this.infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //this.infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //this.infoData.대비율 = { value: `${대비율}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };
}

const onRefresh = async() => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    // 종목 찾기
    const futures = state.futures;
    if (futures >= Common.G_FUTURES_MAX) {
        return;
    }

    try {
        // 매도 1호가
        // const open = store.MasterState.dbOpenPrice[futures];  // 시작가
        const base = store.MasterState.dbBPrice[futures]; // 기준가
        const high = store.MasterState.dbHighPrice[futures]; // 고가
        const low = store.MasterState.dbLowPrice[futures]; // 저가
        const current = store.MasterState.dbCurPrice[futures]; // 현재가
        const offerhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.offerho5[futures], store.MasterState.offerrem5[futures], store.MasterState.offerno5[futures], ],
            [ store.MasterState.offerho4[futures], store.MasterState.offerrem4[futures], store.MasterState.offerno4[futures], ],
            [ store.MasterState.offerho3[futures], store.MasterState.offerrem3[futures], store.MasterState.offerno3[futures], ],
            [ store.MasterState.offerho2[futures], store.MasterState.offerrem2[futures], store.MasterState.offerno2[futures], ],
            [ store.MasterState.offerho1[futures], store.MasterState.offerrem1[futures], store.MasterState.offerno1[futures], ],
        ];
        const bidhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.bidho1[futures], store.MasterState.bidrem1[futures], store.MasterState.bidno1[futures], ],
            [ store.MasterState.bidho2[futures], store.MasterState.bidrem2[futures], store.MasterState.bidno2[futures], ],
            [ store.MasterState.bidho3[futures], store.MasterState.bidrem3[futures], store.MasterState.bidno3[futures], ],
            [ store.MasterState.bidho4[futures], store.MasterState.bidrem4[futures], store.MasterState.bidno4[futures], ],
            [ store.MasterState.bidho5[futures], store.MasterState.bidrem5[futures], store.MasterState.bidno5[futures], ],
        ];
        // 매도
        let sell1Data = [], sell2Data = [], sell3Data = [];
        // 고가
        // sell1Data.push({
        //     title: '고가',
        //     textColor: { color: Theme.mts.lightSlate },
        //     bgColor: { backgroundColor: Theme.mts.white },
        // });
        // sell2Data.push({
        //     title: Utils.PriceFormatStr(high, futures),
        //     textColor: high > base ? { color: Theme.mts.up } : high < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
        //     bgColor: { backgroundColor: Theme.mts.white },
        // });
        // sell3Data.push({ title: '' });
		state.highData = {
            title: Utils.PriceFormatStr(high, futures),
            textColor: high > base ? { color: Theme.mts.up } : high < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        };
        // 5호가
        for (let idx = 0; idx < SELL_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const offerho = offerhoData[idx];
            const price = offerho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_SELL_COLOR;
            // 잔량
            data2.title = `${offerho[1]}`;
            data2.bgColor = GRID_SELL_COLOR;
            // 건수
            data3.title = `${offerho[2]}`;
            data3.bgColor = GRID_SELL_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            sell1Data.push(data1);
            sell2Data.push(data2);
            sell3Data.push(data3);
        }

        // 매수
        let buy1Data = [], buy2Data = [], buy3Data = [];
        // 5호가
        for (let idx = 0; idx < BUY_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const bidho = bidhoData[idx];
            const price = bidho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_BUY_COLOR;
            // 잔량
            data2.title = `${bidho[1]}`;
            data2.bgColor = GRID_BUY_COLOR;
            // 건수
            data3.title = `${bidho[2]}`;
            data3.bgColor = GRID_BUY_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            buy1Data.push(data1);
            buy2Data.push(data2);
            buy3Data.push(data3);
        }
        // 저가
        // buy1Data.push({
        //     title: '저가',
        //     textColor: { color: Theme.mts.lightSlate },
        //     bgColor: { backgroundColor: Theme.mts.white },
        // });
        // buy2Data.push({
        //     title: Utils.PriceFormatStr(low, futures),
        //     textColor: low > base ? { color: Theme.mts.up } : low < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
        //     bgColor: { backgroundColor: Theme.mts.white },
        // });
        // buy3Data.push({ title: '' });
		state.lowData = {
            title: Utils.PriceFormatStr(low, futures),
            textColor: low > base ? { color: Theme.mts.up } : low < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        };

        // 합계: 건수 매도 가격 매수 건수
        let totalSell = [], totalBuy = [];
        const totoffercnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerno1[futures] + store.MasterState.offerno2[futures] + store.MasterState.offerno3[futures] + store.MasterState.offerno4[futures] + store.MasterState.offerno5[futures] : store.MasterState.totoffercnt[futures];
        const totofferrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerrem1[futures] + store.MasterState.offerrem2[futures] + store.MasterState.offerrem3[futures] + store.MasterState.offerrem4[futures] + store.MasterState.offerrem5[futures] : store.MasterState.totofferrem[futures];
        totalSell.push('');   // 가격
        totalSell.push(totofferrem); // 잔량
        totalSell.push(totoffercnt); // 건수
        const totbidcnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidno1[futures] + store.MasterState.bidno2[futures] + store.MasterState.bidno3[futures] + store.MasterState.bidno4[futures] + store.MasterState.bidno5[futures] : store.MasterState.totbidcnt[futures];
        const totbidrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidrem1[futures] + store.MasterState.bidrem2[futures] + store.MasterState.bidrem3[futures] + store.MasterState.bidrem4[futures] + store.MasterState.bidrem5[futures] : store.MasterState.totbidrem[futures];
        totalBuy.push('');   // 가격
        totalBuy.push(totbidrem); // 잔량
        totalBuy.push(totbidcnt); // 건수

        // 화면 갱신 속도 조절
        state.sellData = [ sell1Data, sell2Data, sell3Data ];
        state.buyData = [ buy1Data, buy2Data, buy3Data ];
        state.totalData = [ totalSell, totalBuy ];

		// console.log(state.totalData);
    } catch (e) {
        console.error(`데이터 리플래시 중 예외발생: ${JSON.stringify(e)}`);
    }
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const onChangePriceType = (e:any) => {
    state.selectedPriceType = e.target.value;
    // console.log(state.selectedPriceType)
}

const onChangeAmount = (value = 0 as number) =>{
    // const totalAmount = Number(state.amount) + value;

    // if (totalAmount > state.orderLeft) {
    //     state.amount = state.orderLeft.toString();
    // } else if(totalAmount < 0){
    //     state.amount = '0';
    // } else {
    //     state.amount = totalAmount.toString();
    // }

    const amount = state.amount.replace(/[^0-9]/g, "");
    state.amountNumber = Number(amount);
    const totalAmount = state.amountNumber + value;

    if(totalAmount < 0 ){
        state.amountNumber = 0;
    } else if (totalAmount > state.orderLeft) {
        state.amountNumber = state.orderLeft;
    } else {
        state.amountNumber = totalAmount;
    }

    state.amount = state.amountNumber.toString();
}

const onChangePrice = (tick: number) => {
    const digit = Common.g_bFuturesUnitOfDigit[state.futures];
    const calcTick = state.oneTick * tick;
    const totalPrice = Number(state.price) + calcTick;
    state.price = totalPrice.toFixed(digit);
}

const onProfitAmount = (value: number) =>{
    const totalProfit = Number(state.profitTicks) + value;

    if(totalProfit < 1){
        state.profitTicks = '1';
    } else {
        state.profitTicks = totalProfit.toString();
    }
}

const onLossAmount = (value: number) =>{
    const totalLoss = Number(state.lossTicks) + value;

    if(totalLoss < 1){
        state.lossTicks = '1';
    } else {
        state.lossTicks = totalLoss.toString();
    }
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        state.chkPassReqType = CHECK_REQ_TYPE_ACTION;
        state.isShowAccountInfoPopup = true;
        return;
        // const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        // const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        // const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        // const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
        //     store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        // }

        // // 비번 확인
        // if (state.password === '') {
        //     store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
        //     return false;
        // }
        
        // const password = selectedItem?.pwd;
        // if (state.password !== password) {
        //     const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
        //     store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
        //     return false;
        // }
        
        // state.bAccPassed = true;
        // store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    checkAccPwdSuccess();
}

const checkAccPwdSuccess = () => {
    close("PopupAccountInfo");
    state.bAccPassed = true;

    switch(state.chkPassReqType){
        case CHECK_REQ_TYPE_ACTION:
            onActionPress();
            break;
        case CHECK_REQ_TYPE_SEARCH:
            break;
    }

    state.chkPassReqType = CHECK_REQ_TYPE_NONE;
}

const onTabPress = (tradingIdx: any) => {
    const options = store.SettingsState.settingsEx.options;

    if (state.tradingIdx !== tradingIdx) {
        state.tradingIdx = tradingIdx;

        // const futures = state.futures;
        // onFuturesChange(futures);

        if (tradingIdx === Trade_Buy || tradingIdx === Trade_Sell) {
            // 매수/매도 가격유형 복원
            const pickValue = state.selectedPriceType;
            state.pickAccValue = pickValue.toString();
            document.body.style.overflow = "auto";
        } else {
            // S/L, 정정 가격유형 지정가 고정
            const pickValue = Common.G_PRICE_APPOINT;
            state.pickAccValue = pickValue.toString();
        }
        if(tradingIdx === Trade_Correct) {
            document.body.style.overflow = "auto";
        }

        // S/L인 경우 체결된 주문을 넣는다.
        if (tradingIdx === Trade_Stoploss) {
            // 오늘하루 안보기 설정
            const today = moment(Date.now()).format('YYYY-MM-DD');
            if(options.stoplossToday !== today){
                state.initShowNoticePopup = true;
                document.body.style.overflow = "hidden";
            }

            refreshStoploss();
        }
    }
}

const onQuoteTabPress = (tabIdx: any) => {
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;
        // if (tabIdx === Quote_Contract) {
        //     this.scrollToTop();
        // }
        
    }
}

const onPressNotToday = () => {
    // 오늘하루 안보기 설정
    const today = moment(Date.now()).format('YYYY-MM-DD');
    store.updateSettingsEx({ options: { stoplossToday: today } });

    close('PopupNotice');
}

const refreshStoploss = () => {
    const futures = state.futures;

    // const tradeType = store.AccountState.iOrderPos[futures]===Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    // 총 잔량(반대매매)의 수량과 총 가격을 얻는다.
    const oppositeOrders = store.GetClientOppositeList(futures);
    const oppOrder = oppositeOrders?.length > 0 ? oppositeOrders[0] : null;
    let oppAverage = store.MasterState.dbCurPrice[futures];
    if (oppOrder) {
        const oppAmount = oppOrder.iOppositeAmount;
        const profitTicks = oppOrder.iProfitTicks;
        const lossTicks = oppOrder.iLossTicks;
        oppAverage = oppOrder.dbContractPrice;

        state.amount = `${oppAmount}`;
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = `${profitTicks === 0 ? '' : profitTicks}`;
        state.lossTicks = `${lossTicks === 0 ? '' : lossTicks}`;
    } else {
        state.amount = '';
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = '';
        state.lossTicks = '';
    }
    state.oppOrder = oppOrder;
}

const onFuturesChange = (futures: any) => {

    store.setLastFuturesSelected(futures);
    store.AccountState.G_Future_Code = futures;
    state.futures = futures;
    
    state.oneTick = Common.G_ONETICK[futures];
    state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;

    // 마지막 체결가
    state.전일가 = store.MasterState.dbBPrice[futures];
    state.현재가 = store.MasterState.dbCurPrice[futures];
    state.전일대비기호 = store.MasterState.szYDiffSign[futures];
    state.전일대비 = store.MasterState.dbYDiffPrice[futures];
    state.등락률 = store.MasterState.dbChgRate[futures];
    state.priceColor = state.현재가 > state.전일가 ? Theme.mts.up : state.현재가 < state.전일가 ? Theme.mts.down : Theme.mts.normal;
    state.diffColor = state.전일대비기호 === '1' || state.전일대비기호 === '2' ? Theme.mts.up : state.전일대비기호 === '4' || state.전일대비기호 === '5' ? Theme.mts.down : Theme.mts.normal;

    state.quoteData = [];
    // 최종 체결가 넣기
    if (store.MasterState.isReadyRECV) {
        const quote = {
            iFutures: futures,
            szCTime: store.MasterState.szCTime[futures],
            iTrdq: store.MasterState.iTrdq[futures],
            dbCurPrice: store.MasterState.dbCurPrice[futures],
            dbBPrice: store.MasterState.dbBPrice[futures],
            szCGubun: store.MasterState.szCGubun[futures],
        };
        state.quoteData.push(quote);
    }

    onPressInit();
    // initValue(futures);
    initInfoData();
    refreshOrderCount();
    onRefresh();

    // const { onFuturesChange } = this.props;
    // onFuturesChange && onFuturesChange(futures);
    // onFuturesChange(futures);

    // this.scrollToCenter(this.quoteLayoutHeight, this.quoteContentHeight);
}

const initValue = (futures: any) => {
    state.amount = '';
    const curPrice = store.MasterState.dbCurPrice[futures];
    state.price = Utils.PriceFormatStr(curPrice, futures);

    state.orgOrderNo = '';

    state.profitTicks = '';
    state.lossTicks = '';
}

const onPressInit = () => {
    const futures = state.futures;
    const tradingIdx  = state.tradingIdx;

    // S/L인 경우 체결된 주문을 넣는다.
    if (tradingIdx === Trade_Stoploss) {
      refreshStoploss();
    } else {
      initValue(futures);
    }
}

const onPricePress = (price: string) => {

    // if(price === '고가' || price === '저가'){return;}
    
    // console.log(`가격이 눌렸다. ${Common.g_szFuturesMenuName[futures]}, ${price}, ${index}`);
    if (state.tradingIdx === Trade_Stoploss) {
        // S/L인 경우 손/익 틱을 변동시킨다.
    } else {
        state.price = price;
    }
}

const onContractData = (contractData: any) => {
    // 마스터 수신여부, 종목 등, 이미 걸러진 상태로 들어온다.
    // store.MasterState.isReadyRECV, this.futures==contractData.iFutures

    // ContractObj
    const { dbCurPrice, dbBPrice, szYDiffSign, dbYDiffPrice, dbChgRate } = contractData;

    // 마지막 체결가
    state.전일가 = dbBPrice;
    state.현재가 = dbCurPrice;
    state.전일대비기호 = szYDiffSign;
    state.전일대비 = dbYDiffPrice;
    state.등락률 = dbChgRate;
}

const refreshOrderCount = (eventType = '' as string) => {
    const futures = state.futures;
    const tradingIdx = state.tradingIdx;

    store.AccountState.G_Future_Code = state.futures;
    store.DrawPossibleOrder();

    const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    const oppTradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
    const orderLeft = store.AccountState.iOrderMax[futures][tradeType];
    state.orderLeft = orderLeft;
    // 체결의 청산가능
    const settleAmount = store.GetClientTotalOppositeAmount(futures, oppTradeType);
    state.settleLeft = settleAmount;

    // 전체 체결/미체결
    const oppAmount = store.GetClientTotalOppositeAmount(futures);
    state.leftAmount = store.GetClientLeftAmountCounts(futures);
    state.oppositeAmount = oppAmount;

    if(eventType !== ''){
        if(eventType === 'orderLeft'){
            //신규(주문)
            state.amount = state.orderLeft.toString();
        } else if(eventType === 'settleLeft'){
            //청산
            state.amount = state.settleLeft.toString();
        }
    }
}

const onActionPress = (bCancel = false) => {
    
    // 계정 비번 확인
    if (!state.bAccPassed) {
        checkAccPwd();
        return;
    }

    const price = state.price;
    const amount = state.amount;
    const orderLeft = state.orderLeft;
    const tradingIdx = state.tradingIdx;

    // const priceType = state.pickPriceValue;
    const orderPriceType = state.selectedPriceType;

    const futures = store.GetFuturesNExchangeable(state.futures, true);
    if (futures >= Common.G_FUTURES_MAX) {
        // 원래 종목 값이 들어간다.
        store.TimeMsg(state.futures);
        return;
    }

    const info = TradingInfo[tradingIdx];

    // 주문수량 확인
    const orderAmount = parseInt(amount);

    const orderPrice = parseFloat(price);
    if (orderPriceType === Common.G_PRICE_APPOINT && (orderPrice <= 0 || isNaN(orderPrice))) {
        const szMsg = '가격을 확인해 주세요.';
        store.setVisibleDialog({ message: szMsg, title: info.name });
        // console.log(szMsg);
        return;
    }

    const orderMethod = Common.C_ORDERMETHOD_MobileOverall;

    if (tradingIdx === Trade_Correct) {
        const tradeType = state.orgTradeType;
        
        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }

        // 주문 정정 (가격 정정)
        const orgOrderNo = parseInt(state.orgOrderNo);
        if (isNaN(orgOrderNo) || !orgOrderNo) {
            const szMsg = '원주문번호를 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }

        // 정정인경우 가격이 같으면 정정이 안된다.
        if (!bCancel) {
            if ((state.price === state.orgPrice)) {
                const szMsg = '정정 주문가격이 원 주문가격과 같습니다.';
                store.setVisibleDialog({ message: szMsg, title: info.name });
                // console.log(szMsg);
                return;
            }
        }

        const callback = () => {
            const modifyMethod = bCancel ? Common.C_ORDERMODIFY_Cancel : Common.C_ORDERMODIFY_ModifyPrice;

            UserLog.modifyOrder(futures, tradeType, orderPrice, orderAmount, modifyMethod, orderMethod, orgOrderNo);
            NetworkService.send.modifyOrderReq({
                futures: futures, 
                tradeType: tradeType,
                modifyMethod: modifyMethod, 
                orderIdx: orgOrderNo,
                orderPrice: orderPrice, 
                orderAmount: orderAmount, 
                orderMethod: orderMethod, 
            });
        }

        const szModify = bCancel ? '취소' : '정정';
        const szMsg = `주문을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `주문 ${szModify}`, okCancel: true, callback });

    } else if (tradingIdx === Trade_Stoploss) {
        // S/L 집행/취소
        const oppOrder = state.oppOrder;
        // 주문 확인
        if (!oppOrder) {
            const szMsg = '체결된 주문을 선택해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }

        const _profitTicks = parseInt(state.profitTicks);
        const _lossTicks = parseInt(state.lossTicks);
        const profitTicks = isNaN(_profitTicks) || _profitTicks < 0 ? 0 : _profitTicks;
        const lossTicks = isNaN(_lossTicks) || _lossTicks < 0 ? 0 : _lossTicks;

        const { iFutures, iTradeType, iOrderIdx, iOppositAmount, iStopLossLogIdx, } = oppOrder;

        // 취소가 아닌경우
        if (bCancel) {
        if (iStopLossLogIdx === Common.INDEX_NONE) {
            const szMsg = '예약된 S/L이 없습니다. S/L집행만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }
        } else {
        if (iStopLossLogIdx >= 0) {
            const szMsg = '예약된 S/L이 있습니다. S/L취소만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }

        // 익절/손절 확인
        if (profitTicks === 0 && lossTicks === 0) {
            const szMsg = '이익틱 또는 손절틱을 설정해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }
        // 익절/손절 수정 확인
        if (profitTicks === oppOrder.iProfitTicks && lossTicks === oppOrder.iLossTicks) {
            const szMsg = '이익틱, 손절틱이 같아 수정할 수 없습니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }
        }

        // S/L이 사용되는 매매구분, stoploss 로그용.
        // const slTradeType = (iTradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const iSLReserved = bCancel ? Common.eSLReserved_Cancel : iStopLossLogIdx === Common.INDEX_NONE ?  Common.eSLReserved_Request : Common.eSLReserved_Modify;
        const szModify = iSLReserved === Common.eSLReserved_Request ? '집행' : iSLReserved === Common.eSLReserved_Modify ? '수정' : '취소';

        const callback = () => {
            const useStop = profitTicks > 0;
            const useLoss = lossTicks > 0;
            const szExMessage = `S/L ${szModify}`;
            UserLog.stopLoss(iFutures, orderPrice, useStop, profitTicks, useLoss, lossTicks, szExMessage, iStopLossLogIdx, bCancel ? 1 : 0 );
            NetworkService.send.stopLossReservedReq({
                iSLReserved, 
                iOrderIdx,
                iFutures, 
                iTradeType,
                iOppositAmount, 
                iProfitTicks: profitTicks, 
                iLossTicks: lossTicks, 
            });
        }

        const szMsg = `S/L을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `S/L ${szModify}`, okCancel: true, callback });

    } else {
        // 매수/매도
        const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;

        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '주문 수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }
        if (orderAmount > orderLeft) {
            const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
            store.setVisibleDialog({ message: szMsg, title: info.name });
            // console.log(szMsg);
            return;
        }

        // 매도/매수 주문
        UserLog.order(futures, tradeType, orderPriceType, orderPrice, orderAmount, orderMethod);
        NetworkService.send.registOrderReq({
            futures: futures, 
            tradeType: tradeType, 
            priceType: orderPriceType, 
            orderPrice: orderPrice, 
            orderAmount: orderAmount, 
            orderMethod: orderMethod, 
        });
    }
}

const onActionMarketTrade = (param: any) => {

  // 계정 비번 확인
  if (!state.bAccPassed) {
    checkAccPwd();
    return;
  }

  const symbol = param.symbol;
  const paramTradeType = param.tradeType;
  const tradeType = paramTradeType === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
  const orderAmount = param.orderAmount;

  const futures = state.pickFuturesItems.find((p: any) => p.label === symbol).futures;
  const info = TradingInfo[paramTradeType];
  const priceType = Common.G_PRICE_MARKET;
  const orderMethod = Common.C_ORDERMETHOD_MobileOverall;

  if(futures){
    const orderLeft = store.AccountState.iOrderMax[futures][tradeType]; //그래프로 선택된 종목의 구매가능 수량

    if (orderAmount > orderLeft) {
      const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
      store.setVisibleDialog({ message: szMsg, title: info.name });
      return;
    }

    // 매도/매수 주문
    UserLog.order(futures, tradeType, priceType, 0, 1, orderMethod);
    NetworkService.send.registOrderReq({
      futures: futures,
      tradeType: tradeType,
      priceType: priceType,
      orderPrice: 0,
      orderAmount: orderAmount,
      orderMethod: orderMethod,
    });
  }

  // 챠트 팝업을 닫을 경우
  // state.initIsShowPopupChart = false;
}

// 미체결 버튼
const onLeftPress = () => {
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;

    if (!leftOrders || leftOrders.length === 0) {
        const szMsg = '미체결 주문이 없습니다.';
        store.setVisibleDialog({ message: szMsg, title: '알림' });
        // console.log(szMsg);
        return;
    }

    state.leftOrders = leftOrders;
    state.isShowPopupLeftOrders = true;
}

/// 미체결 선택(주문 정정)
const onCorrectSelectedPress = (params: any) => {
    const leftOrders = state.leftOrders ;
    const leftSelected = params.selectedIdx;
    const selectedLeftOrder = params.selectedOrder;

    if (leftSelected >= 0 && leftSelected < leftOrders.length) {
        state.amount = `${selectedLeftOrder.iLeftAmount}`;
        const price = Utils.PriceFormatStr(selectedLeftOrder.dbOrderPrice, selectedLeftOrder.iFutures);
        state.price = price;
        state.orgPrice = price;
        state.orgTradeType = selectedLeftOrder.iTradeType;
        state.orgOrderNo = `${selectedLeftOrder.iOrderIdx}`;
    }

    state.isShowPopupLeftOrders = false;
}

const onCancelCorrect = () => {
    const bCancel = true;
    onActionPress(bCancel);
}

const getQuoteDataColor = (type: string, quote: any) => {
    if(type === 'count'){
        let color = Theme.mts.slate;

        if (quote.szCGubun === '+') {
            color = Theme.mts.up;
        } else if (quote.szCGubun === '-') {
            color = Theme.mts.down;
        }
        return color;
    } else if(type === 'price'){
        let priceColor = Theme.mts.slate;
        if (quote.dbCurPrice > quote.dbBPrice) {
            priceColor = Theme.mts.up;
        } else {
            priceColor = Theme.mts.down;
        }
        return priceColor;
    }
}

const chartView = () => {
	state.zIndex = !state.zIndex;
}

const reqCheckAccPassword = () => {
    state.chkPassReqType = CHECK_REQ_TYPE_SEARCH;
    state.isShowAccountInfoPopup = true;
}

const reqChangeFutures = (param: any) => {
    const code = param.code;
    onPickFuturesChangeValue({value : code});
}

const showPopupLeverage = () => {
    document.body.style.overflow = "hidden";
    store.CommonState.isShowLeverage = true;
}

const close = (popupType: string) => {
    switch (popupType) {
        case "PopupAccountInfo":
            state.chkPassReqType = CHECK_REQ_TYPE_NONE;    
            state.isShowAccountInfoPopup = false;
            document.body.style.overflow = "auto";
            break;
        case "PopupNotice":
            state.initShowNoticePopup = false;
            document.body.style.overflow = "auto";
            break;
        // case "PopupLeftOrders":
        //     state.initShowPopupLeftOrders = false;
        //     document.body.style.overflow = "auto";
        //     break;
        // case "Leverage":
		// 	store.CommonState.isShowLeverage = false;
        //     document.body.style.overflow = "auto";
        //     break;
    }
}

</script>
