import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelRadio as _vModelRadio, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "Setting innerHeight" }
const _hoisted_3 = { class: "menu-box" }
const _hoisted_4 = ["id", "onUpdate:modelValue"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "menu-box" }
const _hoisted_8 = ["id", "onUpdate:modelValue"]
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "text" }
const _hoisted_11 = { class: "menu-box" }
const _hoisted_12 = ["id", "onUpdate:modelValue"]
const _hoisted_13 = ["for"]
const _hoisted_14 = { class: "text" }
const _hoisted_15 = { class: "menu-box" }
const _hoisted_16 = { class: "menu-box modeSelect" }
const _hoisted_17 = { class: "click-box bdbn" }
const _hoisted_18 = { class: "text" }
const _hoisted_19 = { class: "radio-wrap" }
const _hoisted_20 = { class: "label-radio first" }
const _hoisted_21 = { class: "label-radio" }

import useBaseComponent from "@/baseComponent";
import { NetworkService, Emit_Success } from "@/network"
import SettingsService from '../services/SettingsService';
import * as AccPwd from '../constants/AccPwd';

//공용 팝업 종류
const TYPE_POPUP_NONE = 0;
const TYPE_POPUP_RESET = 1;
const TYPE_POPUP_LOGOUT = 2;
// const TYPE_POPUP_LOGOUT = 3;
// const TYPE_POPUP_LOGOUT = 4;
// const TYPE_POPUP_LOGOUT = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'Setting',
  setup(__props) {

const { router, onMounted, onUnmounted, reactive, store} = useBaseComponent();
let state = reactive({
    orderCheck:[
        { id: 'orderCheck1', label: '매수주문 확인', checked: false },
        { id: 'orderCheck2', label: '매도주문 확인', checked: false },
        { id: 'orderCheck3', label: '정정주문 확인', checked: false },
        { id: 'orderCheck4', label: '취소주문 확인', checked: false },
    ],
    orderComplete:[
        { id: 'orderComplete1', label: '매수주문 체결시', checked: false },
        { id: 'orderComplete2', label: '매도주문 체결시', checked: false },
        { id: 'orderComplete3', label: '주문 거부시', checked: false },
    ],
    transaction:[
        {id: 'transaction', label: '입출금 관련', checked: false}
    ],
    themeMode: 'white',
    currPopupType: TYPE_POPUP_NONE, //현재 열려있는 팝업 종류(공용팝업일 경우)
    initShowOkpopup:false,
    initPopupOkMessage:"",
    initShowTwoPopup:false,
    initPopupTwoMessage:"",
    //셋팅팝업
    isShowSetPassword:false,
    isShowChangePassword:false,
    isShowLoginPassword:false,
    isShowLeverageSetting:false,
    // isShowSelectTime:false,
    isShowLiveAlarm:false,  //실시간 알림 팝업

    initIsIgnoreClose: false,
    
})
onMounted(() => {
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('Setting');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) initData();
});

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
});

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};
const initData = () => {
    loadSetting();

    // 계좌 비밀번호 체크 생략
    // const configState = Number(store.SettingsState.settings.accPwdApply);
    // if(configState === AccPwd.cAccPwdApply_OncePopup || (configState === AccPwd.cAccPwdApply_Forever && store.getDomesticAccPwd() === '')) {
    //     store.getSettingAccountPopupState().then(result => {
    //         if(result === 1) {
    //             openSetting('setPassword');
    //             state.initIsIgnoreClose = true;
    //         }
    //     });
    // }
}

const loadSetting = async() => {
    let s1 = await SettingsService.getSettings();
    let s2 = await SettingsService.getSettingsEx();
    if(s1 === null || s1 === undefined) {
        SettingsService.initializeSettings();
        s1 = await SettingsService.getSettings();
    }
    if(s2 === null || s2 === undefined) {
        SettingsService.initializeSettingsEx();
        s2 = await SettingsService.getSettingsEx();
    }

    state.orderCheck[0].checked = s1?.bBuyConfirm ? true : false;
    state.orderCheck[1].checked = s1?.bSellConfirm ? true : false;
    state.orderCheck[2].checked = s1?.bModifyConfirm ? true : false;
    state.orderCheck[3].checked = s1?.bCancelConfirm ? true : false;

    state.orderComplete[0].checked = s1?.bBuyPush ? true : false;
    state.orderComplete[1].checked = s1?.bSellPush ? true : false;
    state.orderComplete[2].checked = s1?.bRefusePush ? true : false;

    state.transaction[0].checked = s1?.bDepoWithPush ? true : false;

    state.themeMode = store.CommonState.themeMode;
    if(state.themeMode === '' || state.themeMode !== 'dark'){
        state.themeMode = 'white';
    }
}
const saveCurrentSetting = async() => {
    store.updateSettings({
        bBuyConfirm: state.orderCheck[0].checked,
        bSellConfirm: state.orderCheck[1].checked,
        bModifyConfirm: state.orderCheck[2].checked,
        bCancelConfirm: state.orderCheck[3].checked,

        bBuyPush: state.orderComplete[0].checked,
        bSellPush: state.orderComplete[1].checked,
        bRefusePush: state.orderComplete[2].checked,

        bDepoWithPush: state.transaction[0].checked,

        theme: state.themeMode
    });
}


const toggleMode = async() => {
    store.setThemeMode(state.themeMode);

    if('dark' === state.themeMode)
        window.dispatchEvent(new CustomEvent("customTvChangeTheme",{ detail:  { data: 'dark' }}));
    if('white' === state.themeMode)
        window.dispatchEvent(new CustomEvent("customTvChangeTheme",{ detail:  { data: 'light' }}));
        
    // rootStore.themeMode = state.mode;
    // router.go(0)
}

const logout = () => {
    state.currPopupType = TYPE_POPUP_LOGOUT;
    state.initShowTwoPopup = true;
    state.initPopupTwoMessage = "로그아웃 하시겠습니까?"
}

const resetSet = () => {
    state.currPopupType = TYPE_POPUP_RESET;
    state.initShowTwoPopup = true;
    state.initPopupTwoMessage = "모든 설정을 초기화<br />하시겠습니까?"
}

const onResetSetting = () => {
    state.orderCheck[0].checked = true;
    state.orderCheck[1].checked = true;
    state.orderCheck[2].checked = true;
    state.orderCheck[3].checked = true;

    state.orderComplete[0].checked = true;
    state.orderComplete[1].checked = true;
    state.orderComplete[2].checked = true;

    state.transaction[0].checked = true;

    saveCurrentSetting();
    loadSetting();
}

const onLogout = () => {
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
    if(accPwdApply !== AccPwd.cAccPwdApply_Forever) {
        store.checkAccountPassword({pwd1:'', pwd2:'', checkType:accPwdApply});
    }

    NetworkService.socket.send(JSON.stringify({t: 'logout', d: {}}));

    store.LoginPageState.isReloadRequired = true;

    router.push('/');
}

const saveSet = () => {
    state.initShowOkpopup = true;
    state.initPopupOkMessage = "설정을 저장했습니다"
    saveCurrentSetting();
}

const showLiveAlarm = () => {
    state.isShowLiveAlarm = true;
    document.body.style.overflow = "hidden";
}

const openSetting = (type: string) => {
    document.body.style.overflow = "hidden";
    if(type === 'setPassword'){
        state.isShowSetPassword = true;
    } else if(type === 'changePassword'){
        state.isShowChangePassword = true;
    } else if(type === 'loginPassword'){
        state.isShowLoginPassword = true;
    } else if(type === 'leverageSetting'){
        state.isShowLeverageSetting = true;
    } 
    // else if(type === 'selectTime'){
    //     state.isShowSelectTime = true;
    //     document.body.style.overflow = "hidden";
    // } else {
    //     //
    // }
}

const popupOk = (popupType: string) => {
    document.body.style.overflow = "auto";
    switch (popupType) {
        case "PopupTwoButton":
            if(state.currPopupType === TYPE_POPUP_RESET){
                onResetSetting();
            }
            else if(state.currPopupType === TYPE_POPUP_LOGOUT){
                onLogout();
            }
            state.currPopupType = TYPE_POPUP_NONE;
            state.initShowTwoPopup = false;
            break;
        case "SetPassword":
            state.isShowSetPassword = false;
            state.initIsIgnoreClose = false;
            break;
        case "ChangePassword":
            state.isShowChangePassword = false;
            break;
        case "LoginPassword":
            state.isShowLoginPassword = false;
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            break;
    }
};

const popupClose = (popupType: string) => {
    document.body.style.overflow = "auto";
    switch (popupType) {
        case "PopupConfirm":
            state.initShowOkpopup = false;
            break;
        case "PopupTwoButton":
            state.currPopupType = TYPE_POPUP_NONE;
            state.initShowTwoPopup = false;
            break;
        case "SetPassword":
            state.isShowSetPassword = false;
            state.initIsIgnoreClose = false;
            break;
        case "ChangePassword":
            state.isShowChangePassword = false;
            break;
        case "LoginPassword":
            state.isShowLoginPassword = false;
            break;
        // case "SelectTime":
        //     state.isShowSelectTime = false;
        // 
        //     break;
        case "LiveAlarm":
            state.isShowLiveAlarm = false;
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            break;
    }
};

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_LiveAlarm = _resolveComponent("LiveAlarm")!
  const _component_SetPassword = _resolveComponent("SetPassword")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_LoginPassword = _resolveComponent("LoginPassword")!
  const _component_LeverageSetting = _resolveComponent("LeverageSetting")!
  const _component_PopupConfirm = _resolveComponent("PopupConfirm")!
  const _component_PopupTwoButton = _resolveComponent("PopupTwoButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { text: '설정' }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "menu-box live",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showLiveAlarm()))
        }, _cache[17] || (_cache[17] = [
          _createElementVNode("p", { class: "title" }, "실시간 알림", -1),
          _createElementVNode("div", { class: "click-box" }, [
            _createElementVNode("p", { class: "text" }, [
              _createTextVNode("실시간 알림 내역 "),
              _createElementVNode("i", { class: "right-arrow" })
            ])
          ], -1)
        ])),
        _createElementVNode("div", _hoisted_3, [
          _cache[18] || (_cache[18] = _createElementVNode("p", { class: "title" }, "주문확인 알림 설정", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).orderCheck, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: item.id,
                "onUpdate:modelValue": ($event: any) => ((item.checked) = $event)
              }, null, 8, _hoisted_4), [
                [_vModelCheckbox, item.checked]
              ]),
              _createElementVNode("label", {
                class: "click-box",
                for: item.id
              }, [
                _createElementVNode("p", _hoisted_6, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["icon-ball", {'on': item.checked}])
                  }, null, 2)
                ])
              ], 8, _hoisted_5)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[19] || (_cache[19] = _createElementVNode("p", { class: "title" }, "주문체결 알림 설정", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).orderComplete, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: item.id,
                "onUpdate:modelValue": ($event: any) => ((item.checked) = $event)
              }, null, 8, _hoisted_8), [
                [_vModelCheckbox, item.checked]
              ]),
              _createElementVNode("label", {
                class: "click-box",
                for: item.id
              }, [
                _createElementVNode("p", _hoisted_10, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["icon-ball", {'on': item.checked}])
                  }, null, 2)
                ])
              ], 8, _hoisted_9)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[20] || (_cache[20] = _createElementVNode("p", { class: "title" }, "입출금 관련 알림", -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).transaction, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: item.id,
                "onUpdate:modelValue": ($event: any) => ((item.checked) = $event)
              }, null, 8, _hoisted_12), [
                [_vModelCheckbox, item.checked]
              ]),
              _createElementVNode("label", {
                class: "click-box",
                for: item.id
              }, [
                _createElementVNode("p", _hoisted_14, [
                  _createTextVNode(_toDisplayString(item.label) + " ", 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["icon-ball", {'on': item.checked}])
                  }, null, 2)
                ])
              ], 8, _hoisted_13)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[23] || (_cache[23] = _createElementVNode("p", { class: "title" }, "일반 설정", -1)),
          _createElementVNode("div", {
            class: "click-box bdbn",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (openSetting('loginPassword')))
          }, _cache[21] || (_cache[21] = [
            _createElementVNode("p", { class: "text" }, [
              _createTextVNode("접속 비밀번호 변경"),
              _createElementVNode("i", { class: "right-arrow" })
            ], -1)
          ])),
          _createElementVNode("div", {
            class: "click-box bdbn",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (openSetting('leverageSetting')))
          }, _cache[22] || (_cache[22] = [
            _createElementVNode("p", { class: "text" }, [
              _createTextVNode("레버리지 설정"),
              _createElementVNode("i", { class: "right-arrow" })
            ], -1)
          ]))
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[27] || (_cache[27] = _createElementVNode("p", { class: "title" }, "기타", -1)),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[26] || (_cache[26] = _createElementVNode("p", { class: "text" }, "테마설정", -1)),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", _hoisted_20, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    value: "white",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).themeMode) = $event)),
                    onChange: toggleMode
                  }, null, 544), [
                    [_vModelRadio, _unref(state).themeMode]
                  ]),
                  _cache[24] || (_cache[24] = _createTextVNode(" 화이트테마 "))
                ]),
                _createElementVNode("label", _hoisted_21, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    value: "dark",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).themeMode) = $event)),
                    onChange: toggleMode
                  }, null, 544), [
                    [_vModelRadio, _unref(state).themeMode]
                  ]),
                  _cache[25] || (_cache[25] = _createTextVNode(" 다크테마 "))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", { class: "btn-box" }, [
          _createElementVNode("button", {
            class: "reset",
            onClick: logout
          }, _cache[28] || (_cache[28] = [
            _createElementVNode("span", { class: "text" }, "로그아웃", -1)
          ])),
          _createElementVNode("button", {
            class: "reset",
            onClick: resetSet
          }, _cache[29] || (_cache[29] = [
            _createElementVNode("span", { class: "text" }, "설정 초기화", -1)
          ])),
          _createElementVNode("button", {
            class: "save",
            onClick: saveSet
          }, _cache[30] || (_cache[30] = [
            _createElementVNode("span", { class: "text" }, "설정 저장", -1)
          ]))
        ])
      ])
    ]),
    _createVNode(_component_LiveAlarm, {
      isShowPopup: _unref(state).isShowLiveAlarm,
      type: 'left',
      onClose: _cache[5] || (_cache[5] = ($event: any) => (popupClose('LiveAlarm')))
    }, null, 8, ["isShowPopup"]),
    (_unref(state).isShowSetPassword)
      ? (_openBlock(), _createBlock(_component_SetPassword, {
          key: 0,
          isShowPopup: _unref(state).isShowSetPassword,
          isIgnoreClose: _unref(state).initIsIgnoreClose,
          type: 'left',
          onOk: _cache[6] || (_cache[6] = ($event: any) => (popupOk('SetPassword'))),
          onClose: _cache[7] || (_cache[7] = ($event: any) => (popupClose('SetPassword')))
        }, null, 8, ["isShowPopup", "isIgnoreClose"]))
      : _createCommentVNode("", true),
    (_unref(state).isShowChangePassword)
      ? (_openBlock(), _createBlock(_component_ChangePassword, {
          key: 1,
          isShowPopup: _unref(state).isShowChangePassword,
          type: 'left',
          onOk: _cache[8] || (_cache[8] = ($event: any) => (popupOk('ChangePassword'))),
          onClose: _cache[9] || (_cache[9] = ($event: any) => (popupClose('ChangePassword')))
        }, null, 8, ["isShowPopup"]))
      : _createCommentVNode("", true),
    (_unref(state).isShowLoginPassword)
      ? (_openBlock(), _createBlock(_component_LoginPassword, {
          key: 2,
          isShowPopup: _unref(state).isShowLoginPassword,
          type: 'left',
          onOk: _cache[10] || (_cache[10] = ($event: any) => (popupOk('LoginPassword'))),
          onClose: _cache[11] || (_cache[11] = ($event: any) => (popupClose('LoginPassword')))
        }, null, 8, ["isShowPopup"]))
      : _createCommentVNode("", true),
    (_unref(state).isShowLeverageSetting)
      ? (_openBlock(), _createBlock(_component_LeverageSetting, {
          key: 3,
          isShowPopup: _unref(state).isShowLeverageSetting,
          type: 'left',
          onOk: _cache[12] || (_cache[12] = ($event: any) => (popupOk('LeverageSetting'))),
          onClose: _cache[13] || (_cache[13] = ($event: any) => (popupClose('LeverageSetting')))
        }, null, 8, ["isShowPopup"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupConfirm, {
      isShowPopup: _unref(state).initShowOkpopup,
      initPopupMessage1: _unref(state).initPopupOkMessage,
      onClose: _cache[14] || (_cache[14] = ($event: any) => (popupClose('PopupConfirm')))
    }, null, 8, ["isShowPopup", "initPopupMessage1"]),
    _createVNode(_component_PopupTwoButton, {
      isShowPopup: _unref(state).initShowTwoPopup,
      text: _unref(state).initPopupTwoMessage,
      onOk: _cache[15] || (_cache[15] = ($event: any) => (popupOk('PopupTwoButton'))),
      onClose: _cache[16] || (_cache[16] = ($event: any) => (popupClose('PopupTwoButton')))
    }, null, 8, ["isShowPopup", "text"])
  ], 64))
}
}

})