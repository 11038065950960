import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "helpDesk innerHeight" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "input-box" }

import useBaseComponent from "@/baseComponent";
import { ref } from 'vue';
import { Theme } from '../constants';
import moment from '../utils/moment';
import { NetworkService, Emit_Chat, Emit_Success } from '../network';
import { ChatMsg, ChatRoomObj, eChatEvent_Init, eChatEvent_Message, eChatEvent_Counselor, eChatEvent_Transfer, eChatEvent_Logs, eCounselingState_No, eCounselingState_Req, eCounselingState_Proceed } from '../models';
import * as Utils from '../utils/Common';
import { onUnmounted } from "vue";
import * as Constant from '../constants/Global';


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpDesk',
  setup(__props) {

const { router, store, onMounted, reactive,} = useBaseComponent();
let state = reactive({
    initialized: false,

    refreshCount: 0,
    chatText: '',
    
    chatList: [] as any,

    timerId: null as any,

    chatHeight: 0,
    resizeTimer: null as any,

    restoredLastTime: ''
});

const bottomEl = ref();

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('HelpDesk');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess );
    NetworkService.emitter.on(Emit_Chat, event_Chat);

    window.addEventListener('resize', resizeEnded);
    resizeHandler();

    if(store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(() =>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess );
    NetworkService.emitter.off(Emit_Chat, event_Chat);
    window.removeEventListener('resize', resizeEnded);

    // 마지막 채팅시간 로컬 저장
    store.setChatLastOpenTime(moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'));
    
    if(state.timerId){
        clearTimeout(state.timerId);
    }
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
})

const event_LoginSuccess = (params: any) => {
    if(store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        // 페이지 새로고침시 저장된 데이터 가져오는게 느림..
        state.timerId = setTimeout(() => { initData(); }, 700);
    }
}

const scrollToEnd = () => { bottomEl.value.scrollIntoView({ behavior: 'smooth' }); }

const initData = () => {
    if(state.initialized){
        return;
    }
    state.initialized = true;

    state.chatText = '';
    //this.chatInputRef && this.chatInputRef.focus();
    const lpChatRoom = store.GetChatRoomPtr();
    lpChatRoom.Reset();

    // 상담시간 안내 메세지 출력
    IntroMessage();


    //기존 메세지 복원
    store.HelpDeskStore.chatArray.forEach((item: any)=> {
        state.chatList.push(item);
        state.restoredLastTime = item.time;
    });


    // 상담시간이 아닐경우 메세지 출력
    CheckOpenTime();

    // 관리자가 남긴 메세지 추가
    // 한번만 보이고 안보이게

    // 2023-09-26:
    // 탭 변경시 메세지 보여야 하고
    // 새로고침하면 안보이게......

    let szStartDate;
    const lastChatOpenTime = store.getChatLastOpenTime();
    if(lastChatOpenTime) {
        szStartDate = lastChatOpenTime;
    } else {
        const { stChatTime } = store.AccountState;
        if(stChatTime)
            szStartDate = moment(stChatTime).format('YYYY-MM-DD HH:mm:ss');
    }
    if(szStartDate)
        NetworkService.send.chatLogReq({ szStartDate });

/*
    // 2023-09-25: 최근 xx 분 이내의 대화내역 복구
    // 보류
    const chatRestoreMinute = Number(Constant.CHAT_RESTORE_MINUTE);
    let nowMoment = moment();
    const szStartDate = nowMoment.add( - chatRestoreMinute, "minutes").format('YYYY-MM-DD HH:mm:ss');
    NetworkService.send.chatLogReq({ szStartDate });
*/

    store.setChatLastOpenTime(moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'));

    state.timerId = setTimeout(() => { scrollToEnd(); }, 100);
}

const event_Chat = (params: any) => {
    // 채팅 이벤트
    const { event } = params;
    // const { navigation, isFocused, } = this.props;

    if (event === eChatEvent_Init) {
        //Init();
    } else if (event === eChatEvent_Counselor) {
        const { bEnter } = params;
        EnterCounselor(bEnter);
    } else if (event === eChatEvent_Message) {
        const { szMessage, MsgType, MsgTime } = params;
        Message(MsgType, szMessage, MsgTime);
        state.refreshCount++;
    } else if (event === eChatEvent_Transfer) {
        const { bSendToServer, lpChatTransfer } = params;
        // console.log(`채팅 파일이 전송되었다. 보냄:${bSendToServer}`);
    } else if (event === eChatEvent_Logs) {
        // 채팅 로그를 받았다.
        const { ChatLogTotalCount, ChatLogs, stChatTime } = store.AccountState;

        let bNewMessage = false, iNewMsgLine = -1;
        for (let idx = 0; idx < ChatLogTotalCount; idx++) {
            const { Type, stTime, szMsg, TransferType, szName } = ChatLogs[idx];

            if(state.restoredLastTime && state.restoredLastTime >= stTime) {
                continue;
            }

            //if (!bNewMessage && (stTime > stChatTime || (__DEV__ && idx === 6))) {
            if (!bNewMessage && stTime > stChatTime) {
                iNewMsgLine = idx;

                // 보류  // 기간내 로그 복구하도록 변경하면서 제거
                Message(ChatMsg.eType_Info, '\n*****  읽지 않은 메세지가 있습니다.  *****\n');
                bNewMessage = true;
            }

            // 상담시간 안내 메세지 출력
            const bScrollToEnd = !bNewMessage;
            //this.IntroMessage(bScrollToEnd);
            // 상담시간이 아닐경우 메세지 출력
            //this.CheckOpenTime();

            // 새로운 메세지로 이동한다.
            if (bNewMessage) {
                // 신규 메세지가 화면 중간에 위치
                // viewPosition 0 places it at the top, 1 at the bottom, and 0.5 centered in the middle.
                // setTimeout(() => this.chatMessageRef && this.chatMessageRef.scrollToIndex({ animated: false, index: iNewMsgLine, viewPosition: 0.5 }), 500);
            }

            Message(Type, szMsg, stTime);


            // 탭 변경시 복구 용도로 store 저장
            // 새로고침하면 복구안됨
            const item = {
                message: szMsg,
                type: Type === ChatMsg.eType_User ? 1 : 0,
            }
            store.HelpDeskStore.chatArray.push(item);
        }

        /*
              // 상담시간 안내 메세지 출력
              const bScrollToEnd = !bNewMessage;
              //this.IntroMessage(bScrollToEnd);
              // 상담시간이 아닐경우 메세지 출력
              //this.CheckOpenTime();
        
              // 새로운 메세지로 이동한다.
              if (bNewMessage) {
                // 신규 메세지가 화면 중간에 위치
                // viewPosition 0 places it at the top, 1 at the bottom, and 0.5 centered in the middle.
                //setTimeout(() => this.chatMessageRef && this.chatMessageRef.scrollToIndex({ animated: false, index: iNewMsgLine, viewPosition: 0.5 }), 500);
              }
         */
    }
}

const CheckOpenTime = () => {
    // 시작 hhmm, 종료시간hhmm(string)으로 현재 시간이 해당 시간인지 확인
    const bOpen = Utils.IsOpenTime(store.BrandState.szBrandChatO, store.BrandState.szBrandChatC);
    if (!bOpen) {
        const szText = `상담 운영시간이 아닙니다. 상담가능 시간은\n[${store.BrandState.szBrandChatO.substring(0, 2)}:${store.BrandState.szBrandChatO.substring(2, 4)} ~ 익일${store.BrandState.szBrandChatC.substring(0, 2)}:${store.BrandState.szBrandChatC.substring(2, 4)}]까지 입니다.`;
        Message(ChatMsg.eType_System, szText);
    }
    return bOpen;
}
  
const IntroMessage = () => {
    // 시작 hhmm, 종료시간hhmm(string)으로 현재 시간이 해당 시간인지 확인
    const bOpen = Utils.IsOpenTime(store.BrandState.szBrandChatO, store.BrandState.szBrandChatC);
    const szText = `상담 운영시간은\n[${store.BrandState.szBrandChatO.substring(0, 2)}:${store.BrandState.szBrandChatO.substring(2, 4)} ~ 익일${store.BrandState.szBrandChatC.substring(0, 2)}:${store.BrandState.szBrandChatC.substring(2, 4)}]까지 입니다.`;
    Message(ChatMsg.eType_Fixed, szText);
  
    Message(ChatMsg.eType_Fixed, '실시간 상담 서비스입니다. 무엇을 도와드릴까요?');
    state.refreshCount++;
}
  
const Message = (MsgType: number, szMessage: string, lpChatTime = null, szNick = null, szLink = null, TransferType = ChatMsg.eTransfer_No) => {
    let szText, szSpeaker;
    const chatTime = lpChatTime ? lpChatTime : Date.now();
    let szTime = '';
    switch (MsgType) {
        case ChatMsg.eType_Fixed:
            ChatTextOut(MsgType, szTime, '', szMessage, Theme.chat.cColor_Fixed);
            break;

        case ChatMsg.eType_System:
            ChatTextOut(MsgType, szTime, '', szMessage, Theme.chat.cColor_System, ChatMsg.CFM_COLOR | ChatMsg.CFM_BOLD);
            break;

        case ChatMsg.eType_User:
            //szSpeaker = `${szNick ? szNick : `${AccountStore.szUserID}(${AccountStore.szName})`}`;
            szSpeaker = '';
            szTime = `[${moment(chatTime).format('HH:mm')}]`;
            szText = szMessage;
            ChatTextOut(MsgType, szTime, szSpeaker, szText, Theme.chat.cColor_User, ChatMsg.CFM_COLOR);
            break;

        case ChatMsg.eType_Counselor:
            szSpeaker = '상담원';
            szTime = `[${moment(chatTime).format('HH:mm')}]`;
            szText = szMessage;
            ChatTextOut(MsgType, szTime, szSpeaker, szText, Theme.chat.cColor_Counselor, ChatMsg.CFM_COLOR);
            break;

        case ChatMsg.eType_Info:
        case ChatMsg.eType_Transfer:
        default:
            szText = szMessage;
            ChatTextOut(MsgType, szTime, '', szText, Theme.chat.cColor_Info, ChatMsg.CFM_COLOR | ChatMsg.CFM_BOLD);
            break;
    }
}
  
const ChatTextOut = (MsgType: any, szTime: any, szSpeaker: any, szText: any, TextColor = Theme.mts.text, dwTextMask = ChatMsg.CFM_COLOR, Offset = ChatMsg.cEdit_DefOffset, Size = 0) => {
    let type = 0;
    switch(MsgType){
        case ChatMsg.eType_Fixed: type = 0; break;	    // 고정 메세지
        case ChatMsg.eType_System: type = 0; break;		// 시스템 메세지
        case ChatMsg.eType_User: type = 1; break;		// 사용자 메세지
        case ChatMsg.eType_Counselor: type = 0; break;	// 상담원 메세지
        case ChatMsg.eType_Info: type = 0; break;		// 안내 메세지
        case ChatMsg.eType_Transfer: type = 0; break;	// 파일 전송
        case ChatMsg.eType_TransferFile: type = 0; break;    // 전송파일
    }

    const item = {
        message: szText,
        type: type,
    }
    state.chatList.push(item);
    
    scrollToEnd();
}
  
const EnterCounselor = (bEnter: boolean) => {
    if (bEnter) {
        // 유저 강제 입장
        Message(ChatMsg.eType_System, '상담원이 입장하였습니다. 대화가 시작되었습니다.');
    } else {
        Message(ChatMsg.eType_System, '상담원이 퇴장하였습니다. 대화가 종료되었습니다.');
    }
    
    state.refreshCount++;
}
  
const onSubmitChat = () => {
    // 채팅을 전송
    // Keyboard.dismiss();

    const szMessage = state.chatText;
    if (szMessage.length <= 0) {
        return;
    }

    if (szMessage.length > ChatMsg.cLength_Max) {
        store.setVisibleToast({message: '메세지 내용이 너무 길어 보낼 수 없습니다.'})
        return;
    }
  
    const lpChatRoom = store.GetChatRoomPtr();
    const counseling = lpChatRoom.GetCounselingState();
    if (counseling != eCounselingState_Proceed) {
        store.setVisibleToast({message: '상담 진행중이 아닙니다.\n메세지를 보낼 수 없습니다.'})
        return;
    }
  
    NetworkService.send.chatMessage({ szMessage });

    // 입력내용 삭제
    state.chatText = '';
  }

const onReqChatPress = () => {
    // 대화신청
    // 상담 가능시간 확인
    if (!CheckOpenTime()) {
        // Keyboard.dismiss();
        return;
    }

    const lpChatRoom = store.GetChatRoomPtr();

    if(lpChatRoom.UserState != ChatRoomObj.eState_Enter) {
        lpChatRoom.UserState = ChatRoomObj.eState_Enter;

        const msg = '대화를 신청 중입니다. 잠시만 기다려 주십시오.';
        Message(ChatMsg.eType_System, msg);

        const item = {
            message: msg,
            type: 0,
        }
        store.HelpDeskStore.chatArray.push(item);

        // 상담을 요청한다.
        NetworkService.send.customerChatReq();

        state.refreshCount++;
    }
}

const onQuitChatPress = () => {
    const lpChatRoom = store.GetChatRoomPtr();
    const counseling = lpChatRoom.GetCounselingState();
    if (counseling !== eCounselingState_No) {
        const szMsg = '1:1 상담 진행 중입니다. 실시간 상담을 종료하고 창을 닫으시겠습니까?';
        store.setVisibleDialog({ message: szMsg, title: '상담종료 요청', okCancel: true, callback: () => {
            // 대화 종료
            NetworkService.send.customerChatReq({ bEnter: 0 });
            lpChatRoom.UserState = ChatRoomObj.eState_No;

            const msg = '상담원과 채팅이 종료되었습니다.';
            Message(ChatMsg.eType_System, msg);
            state.refreshCount++;
            
            const item = {
                message: msg,
                type: 0,
            }
            store.HelpDeskStore.chatArray.push(item);
        }});
    }
}

const onFocusChat = () => {
    // 대화창 키보드 팝업
    // 상담 가능시간 확인
    if (!CheckOpenTime()) {
        // Keyboard.dismiss();
        return;
    }

    // 상담 요청 여부 확인
    const lpChatRoom = store.GetChatRoomPtr();
    if (lpChatRoom.GetCounselingState() === eCounselingState_No) {
        // Keyboard.dismiss();
        const szMsg = '상담원에게 대화신청 후 상담이 가능합니다. 상담원에게 대화신청을 하시겠습니까?';
        // store.setVisibleDialog({ message: szMsg, title: '상담원 대화신청', okCancel: true, callback: () => {
        //     onReqChatPress();
        // }});
    } else if (lpChatRoom.GetCounselingState() === eCounselingState_Req) {
        // Keyboard.dismiss();
        // ToastHolder.show(ToastHolder.Type.Warn, '상담원이 상담을 준비할 동안 잠시 기다려주시기 바랍니다.');
    }
}

const resizeEnded = () => {
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
    state.resizeTimer = setTimeout(()=>{
        resizeHandler()
    }, 500);
}

const resizeHandler = () => {
    var currWinWidth = window.innerWidth;       //resize 후 브라우저 가로 길이
    var currWinHeight = window.innerHeight;     //resize 후 브라우저 세로 길이

    if(currWinWidth > 700){
        state.chatHeight = currWinHeight - 440;
    } else if(currWinWidth > 600) {
        state.chatHeight = currWinHeight - 340;
    } else if(currWinWidth > 500) {
        state.chatHeight = currWinHeight - 280;
    } else if(currWinWidth > 430) {
        state.chatHeight = currWinHeight - 260;
    } else if(currWinWidth > 360) {
        state.chatHeight = currWinHeight - 235;
    } else {
        state.chatHeight = currWinHeight - 230;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { text: '상담센터' }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "chat-box",
        id: "chat",
        style: _normalizeStyle({height: _unref(state).chatHeight + 'px'})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).chatList, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["message-box", [{ take: item.type === 0 }, { send: item.type === 1 }]]),
            key: i
          }, [
            _createElementVNode("p", _hoisted_3, _toDisplayString(item.message), 1)
          ], 2))
        }), 128)),
        _createElementVNode("div", {
          class: "message-box take",
          ref_key: "bottomEl",
          ref: bottomEl,
          style: {"visibility":"hidden"}
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("p", { class: "text" }, "hidden...", -1)
        ]), 512)
      ], 4),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          id: "sendText",
          type: "text",
          placeholder: "문의내용을 입력해주세요",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).chatText) = $event)),
          onKeyup: _withKeys(onSubmitChat, ["enter"]),
          autocomplete: "off"
        }, null, 544), [
          [_vModelText, _unref(state).chatText]
        ]),
        _createElementVNode("button", {
          class: "btn send",
          onClick: onSubmitChat
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "test" }, "전송", -1)
        ]))
      ]),
      _createElementVNode("div", { class: "btn-box" }, [
        _createElementVNode("button", {
          class: "connect",
          onClick: onReqChatPress
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "text" }, "대화신청", -1)
        ])),
        _createElementVNode("button", {
          class: "out",
          onClick: onQuitChatPress
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "text" }, "퇴장", -1)
        ]))
      ])
    ])
  ]))
}
}

})