import { Buffer } from 'buffer';
import { PacketID } from './PacketID';
import { Packet } from './Packet';
import { PacketService } from './PacketService';
import { PACKET_HEADER_SIZE, PACKET_BUFFER_SIZE } from './Packet';
import NetworkEmitter, { Emit_Disconnected, Emit_ResetMoveToLogin } from './NetworkEmitter';
import * as Constant from '../constants/Global';
import * as Common from '../constants/Common';
import * as Utils from '../utils/Common';
import * as ChartConst from '../constants/Chart';
import moment from '../utils/moment';
import { ChatMsg } from '../models';
import { useStore } from '../pinia';

// 서버접속 대기시간
const CONNECTION_TIMEOUT = 10000;
// 재접속 카운트
const MAX_RETRY_COUNT = 5;
// 재접속 딜레이
const RETRY_DELAY = 500;

// 버퍼 사이즈
const RECEIVE_BUFF_LENGTH = (1024 * 128);


export class NetworkService {
  static _socket = {} as WebSocket;
  static _receiveBuff = Buffer.allocUnsafe(RECEIVE_BUFF_LENGTH);
  static _receiveLength = 0; 
  // 패킷 처리중 들어오는 패킷을 담아둔다.
  static _pendingBuff = Buffer.allocUnsafe(RECEIVE_BUFF_LENGTH);
  static _pendingLength = 0; 
  static _connected = false;
  static _logout = false;
  static _retryCount = 0;
  static _loginInfo = {};
  static _options = {} as NetworkService;
  static _emitter = new NetworkEmitter();
  static _bHandling = false;

  static _socketState = Constant.SOCKET_STATE_NONE;

  // 디버그용
  static _lastData = { };

  // 소켓을 설정한다.
  static setSocket(_s: WebSocket) { NetworkService._socket = _s; }
  static get socket() { return NetworkService._socket; }
  static get connected() { return NetworkService._connected; }
  static setLogout(bLogout = true) { NetworkService._logout = bLogout; }
  static get logout() { return NetworkService._logout; }
  static setLoginInfo(loginInfo: any) { NetworkService._loginInfo = loginInfo; }
  static get loginInfo() { return NetworkService._loginInfo; }
  static setOptions(options: any) { NetworkService._options = options; }
  static get options() { return NetworkService._options; }

  // "opening" | "open" | "readOnly" | "writeOnly"
  static get socketReadyState() {
    return NetworkService._socket; // ? NetworkService._socket.readyState : '';
  }
  
  static get emitter() { return NetworkService._emitter; }

  static printNoSocketError() { 
    const store = useStore();
    if (store.debugLog >= Constant.DebugLevel_Minimal)
      console.log('연결된 소켓이 없습니다.');
  }

  static printApiRequest(msg: any) { 
    const store = useStore();
    if (store.debugLog >= Constant.DebugLevel_Minimal)
      console.log(msg);
  }

  static async connect(options = null as any, loginInfo = null as any, bRecon = false) {
    const store = useStore();
    try {
      const serverUrl = process.env.VUE_APP_WEBSOCKET_URL?.toString();
      if(typeof serverUrl !== 'string'){
        return null;
      }

      NetworkService.setLoginInfo(loginInfo);
      NetworkService.setOptions(options);

      // Create Web Socket
      const client = new WebSocket(serverUrl);

      // Web Socket 이 연결된 경우 로그인 시도
      client.onopen = (event) => {
        NetworkService._socketState = Constant.SOCKET_STATE_OPEN;
        NetworkService._connected = true;
        NetworkService._logout = false;
        NetworkService._retryCount = 0;

        const errorState = store.LoginPageState.errorState;
        const loginState = store.LoginPageState.loginState;
        const id = store.SettingsState.loginID;
        const pwd = store.SettingsState.signinPwd;


        if (store.debugLog >= Constant.DebugLevel_Minimal)
          console.log(`websocket connected: url => ${serverUrl} , state => ${client.readyState}`, loginState, errorState);
        if(loginState === 1 && id && pwd) {
          // 자동 로그인
          const loginInfo = {
            userID: id,
            userPwd: pwd,
            bFirstLogin: 1,
            bMock: store.LoginPageState.isChecked2 ? 1 : 0,
            eventFrom: 'autoLogin',
          }
          store.LoginPageState.loginFrom = "autoLogin";

          // 이거 풀면 자동 로그인
          NetworkService.send.login(loginInfo);
        }
        else {
          const lastViewPage = store.getLastViewPage();
          if( (loginState !== 1) || (loginState === 1 && ( ! id || ! pwd ))) {
            // 로그인화면 아니고
            // 아이디, 비번이 중복로그인 등으로 삭제됨
            if(lastViewPage && lastViewPage != '')
              NetworkService.emitter.emit(Emit_ResetMoveToLogin, { });
          }
        }
      }

      // 웹소켓 로그인 후 받아오는 바이너리데이터를 파싱 후 onReceived 로 전달
      client.onmessage = (event) => {
        try {
          const fileReader = new FileReader();
          fileReader.onload = (_event) =>{
            if(_event.target !== null && _event.target.result !== null && typeof _event.target.result !== "string"){
              NetworkService.onReceived(_event.target.result);
            }
          }
          fileReader.readAsArrayBuffer(event.data);
        }
        catch(e){
          if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.error(`websocket data receive error: ${e}`);
        }
      }

      client.onerror = (event) =>{
        NetworkService._socketState = Constant.SOCKET_STATE_ERROR;
        if (store.debugLog >= Constant.DebugLevel_Minimal)
          console.log(`websocket error => ${event}`);
      }

      client.onclose = (event) => {
        NetworkService._socketState = Constant.SOCKET_STATE_CLOSE;
        NetworkService._connected = false;
        if (store.debugLog >= Constant.DebugLevel_Minimal)
          console.log('websocket closed!');

        // 로그아웃을 위해 끊어진게 아니면 다시 연결
        if (!NetworkService.logout) {          
          const retryCount = ++ NetworkService._retryCount;
          const bRecon = retryCount <= MAX_RETRY_COUNT;
          const message = bRecon ? `서버와의 연결이 종료되어 다시 연결합니다. (${retryCount}/${MAX_RETRY_COUNT}회)` : '서버와의 연결이 종료되었습니다. 네트워크 상태를 확인해주세요.';
          
          if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.log(`Connection retry! ${retryCount}`);

            // 바로 재연결하면 기존 연결 설정을 가져와 에러가 발생한다.
          setTimeout(() => {
            NetworkService.emitter.emit(Emit_Disconnected, { options, loginInfo: { ...loginInfo, bFirstLogin: false }, bRecon, message });
          }, RETRY_DELAY);
        }
      }

      NetworkService.setSocket(client);

      return client;
    } catch(e) {
      if (store.debugLog >= Constant.DebugLevel_Minimal)
        console.log(`exception: ${JSON.stringify(e)}`);
    }

    return null;
  }

  static destorySocket(bLogout = false) {
    const store = useStore();
    if (NetworkService.socket && NetworkService._connected) {
      NetworkService.socket.close()
      if (store.debugLog >= Constant.DebugLevel_Minimal)
        console.log('socket destroyed!!!!!');
    }
    // NetworkService.setSocket(undefined);
    NetworkService._connected = false;
    NetworkService._receiveLength = 0;
    NetworkService._pendingLength = 0;
    NetworkService.setLogout(bLogout);
  }

  // onReceived() 처리하는 동안 새로운 패킷이 올 수 있기 때문에, 패킷 처리(_receiveBuff) 중에는 페딩버퍼(_pendingBuff)에
  // 새로운 데이터를 넣어둔다.
  //
  // onReceived() 비동기 처리라 순차적으로 호출되지만 여러개가 실행 중일 수 있다.
  // _receiveBuff 에 새 데이터를 추가하고 _receiveLength 변경하여 이후에 바로 새 데이터가 추가될 수 있게 처리해줘야한다.
  //    NetworkService._receiveLength = newLen;
  // 처리되고 남은 데이터는 처리 중에 데이터가 추가되어 _receiveLength이 변경될 수 있기에 leftLen이아닌 처리된 길이만큼 가감으로 처리한다.
  //   if (NetworkService._receiveLength - readPos > 0) {
  //     buff.copy(NetworkService._receiveBuff, 0, readPos, NetworkService._receiveLength);
  //   }
  //   NetworkService._receiveLength -= readPos;
  //
  // async 제외하면 별도 테스트 필요. onReceived(), handler(), [PacketID.PROTOCOL_NO](packet)...
  static async onReceived(data: any) {
    const store = useStore();

    // 펜딩 버퍼에 데이터를 저장한다.
    /*
    let pendingLen = NetworkService._pendingLength + data.length;
    //console.log(`패킷 처리 => pendingLen: ${pendingLen}, left: ${NetworkService._pendingLength}`)
    data.copy(NetworkService._pendingBuff, NetworkService._pendingLength, 0, data.length);
    NetworkService._pendingLength = pendingLen;
    */

    // const receivedData = {
    //   msgType: msgType,
    //   arrayBuffer: arrayBuffer,
    //   cvtU8: cvtU8
    // }
    
    // 데이터 처리
    NetworkService._bHandling = true;

    let buff = null;
    let packetLen = 0, newLen = 0, readPos = 0, leftLen = 0;
    try {
      // 기존 데이터가 있으면 데이터를 합친다.
      //data.copy(NetworkService._receiveBuff, NetworkService._receiveLength, 0, data.length);
      const cvtU8 = new Uint8Array(data);
      const dataLength = cvtU8.byteLength;
      for(let i = 0; i < dataLength; i++) {
        NetworkService._receiveBuff[NetworkService._receiveLength+i] = cvtU8[i];
      }
      newLen = NetworkService._receiveLength + dataLength;
      buff = NetworkService._receiveBuff;
      NetworkService._receiveLength = newLen;
      
      /*
      // 버퍼 신규 할당
      newLen = NetworkService._receiveLength + data.length;
      buff = Buffer.allocUnsafe(newLen);
      if (NetworkService._receiveLength > 0) {
        NetworkService._receiveBuff.copy(buff, 0, 0, NetworkService._receiveLength);
      }
      data.copy(buff, NetworkService._receiveLength, 0, data.length);
      */
      /*
      // 펜딩에서 넣는다.
      NetworkService._pendingBuff.copy(NetworkService._receiveBuff, NetworkService._receiveLength, 0, pendingLen);
      NetworkService._pendingLength = 0;
      newLen = NetworkService._receiveLength + pendingLen;
      buff = NetworkService._receiveBuff;
      */
  
      leftLen = newLen;
      let lastPacketId = 0, lastPacketLength = 0;
      // 파싱 가능한 사이즈 확인(최소팻킷 사이즈)
      if (newLen >= PACKET_HEADER_SIZE) {
        packetLen = buff.readInt32LE(readPos);
        while (packetLen > 0 && packetLen <= leftLen) {
          const newBuff = buff.subarray(readPos, readPos + packetLen);
          const newPacket = new Packet(0, packetLen, newBuff);
          lastPacketId = newPacket.id;
          lastPacketLength = packetLen;
          // 패킷 처리
          await PacketService.handler(newPacket);
  
          readPos += packetLen;
          leftLen -= packetLen;
          if (leftLen < PACKET_HEADER_SIZE) {
            break;
          }
  
          packetLen = buff.readInt32LE(readPos);
        }
      }
  
      // 처리하고 남은 데이터 정리
      if (readPos > 0) {
        /*
        if (leftLen > 0) {
          buff.copy(NetworkService._receiveBuff, 0, readPos, newLen);
        }
        NetworkService._receiveLength = leftLen;
        */
        if (NetworkService._receiveLength - readPos > 0) {
          buff.copy(NetworkService._receiveBuff, 0, readPos, NetworkService._receiveLength);
        }
        NetworkService._receiveLength -= readPos;

        if (packetLen <= 0 || packetLen > PACKET_BUFFER_SIZE) {
          NetworkService._receiveLength = 0;
          
          if (store.debugLog >= Constant.DebugLevel_Minimal) {
            //console.error(`비정상 패킷 수신 => last packet id: ${lastPacketId}, last packet length: ${lastPacketLength}, packet len: ${packetLen}, readPos:${readPos}, leftLen:${leftLen}, buff Length: ${newLen}(recvLen: ${recvLen} + dataLen: ${dataLen})`);
            console.error(`비정상 패킷 수신 => last packet id: ${lastPacketId}, last packet length: ${lastPacketLength}, packet len: ${packetLen}, readPos:${readPos}, leftLen:${leftLen}, buff Length: ${newLen}`);
            // 비정상 패킷 수신 => last packet id: 7, last packet length: 200, packet len: 1202590843, readPos:200, leftLen:413
            console.error(`비정상 패킷 수신(이전 패킷) => ${JSON.stringify(NetworkService._lastData)}`);
          }
          // 연결 종료 후 재연결을 시도한다.
          NetworkService.destorySocket();
        }
      }


      if (store.debugLog >= Constant.DebugLevel_Minimal) {
        NetworkService._lastData = { readPos, newLen, leftLen, packetLen }; 
      }
    
      if (store.debugLog >= Constant.DebugLevel_Verbose) {
        NetworkService._lastData = { readPos, newLen, leftLen, packetLen }; 
        console.log(`패킷 처리 완료=> read: ${readPos}, buff Length: ${newLen}, left: ${leftLen}, packet len: ${packetLen}`);
      }
    } catch (e) {
      NetworkService._receiveLength = 0;
      if (store.debugLog >= Constant.DebugLevel_Minimal) {
        console.error(`패킷처리 중 예외발생: ${JSON.stringify(e)}`);
        console.log(e);
      }
      
    } finally {
      NetworkService._bHandling = false;
    }

    return;
  }

  static get send() {
    return {
      login: async (params: any) => {
        const store = useStore();
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { userID, userPwd, bFirstLogin = 1, bMock = 0, } = params;

        const reqObj = {
          brandName: process.env.VUE_APP_BRAND_NAME?.toString(),
          loginKey: process.env.VUE_APP_LOGIN_KEY?.toString(),
          userID: userID?.toLowerCase(),
          userPwd: userPwd,
          bFirstLogin: bFirstLogin,
          bMock: bMock
        }
        try {
          NetworkService.printApiRequest(`send.login fetched: ${JSON.stringify(params)}`);
          NetworkService.socket.send(JSON.stringify({t: 'login', d: reqObj}));
        }
         catch (e)
         {
          if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.error(e)
         }
      },

      tradeWait: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { orderIdx, bStop, } = params;
        const reqObj = {
          orderIdx: orderIdx,
          bStop: bStop
        }
        
        NetworkService.printApiRequest(`send.tradeWait fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'tradeWait', d: reqObj}));
      },

      depoNWithrawReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { futures, isWithraw, isOversea, amounts, } = params;
        const reqObj = {
          futures: futures,
          isWithraw: isWithraw,
          isOversea: isOversea,
          amounts: amounts,
        }
        
        NetworkService.printApiRequest(`send.depoNWithrawReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'depoNWithrawReq', d: reqObj}));
      },

      accPwdReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { bNewPwdConfirm, isOversea, accPwd, } = params;
        const reqObj = {
          bNewPwdConfirm: bNewPwdConfirm,
          isOversea: isOversea,
          accPwd: accPwd,
        }
        NetworkService.printApiRequest(`send.sendAccPwdReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'accPwdReq', d: reqObj}));
      },

      userLog: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { szLog, logEventPos = Common.eLogEventPos_ClientIntOrd, OrderIdx = Common.INDEX_NONE, } = params;
        const szCurTime = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
        // 사용자시간
        // 로그발생 위치
        // 로그
        // 주문번호

        const reqObj = {
          szCurTime: szCurTime,
          logEventPos: logEventPos,
          szLog: szLog,
          OrderIdx: OrderIdx,
        }
        NetworkService.printApiRequest(`send.userLog fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'userLog', d: reqObj}));
      },
 
      registOrderReq: async (params: any) => {
        const store = useStore();
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { futures, tradeType, priceType, orderPrice, orderAmount, orderMethod, ticks = 0, logNum = Common.INDEX_NONE, oppAverage = 0., } = params;
        const bidho1 = 0.;
        const opperho1 = 0.;
        const curPrice = 0.;
      
        // 동시호가 체결 오류로 인해 마스터패킷을 제외한 첫번째 패킷을 받았을때만 매수1, 매도1을 보냄
        // 수정 2015.12.15
        // if (MasterStore.isRecvHoga[futures] && MasterStore.isRecvContract[futures]) {
        //   bidho1 = MasterStore.bidho1[futures];
        //   opperho1 = MasterStore.offerho1[futures];
        //   curPrice = MasterStore.dbCurPrice[futures];
        // }

        const reqObj = {
          futures: futures,
          tradeType: tradeType,
          priceType: priceType,
          orderPrice: orderPrice,
          orderAmount: orderAmount,
          orderMethod: orderMethod,
          ticks: ticks,
          logNum: logNum,
          oppAverage: oppAverage,
          curPrice: curPrice,
          bidho1: bidho1,
          opperho1: opperho1,
        }
        NetworkService.printApiRequest(`send.registOrderReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'registOrderReq', d: reqObj}));

        // 로그
        let strLog;
        if (orderMethod === Common.C_ORDERMETHOD_MIT) {
          strLog = `[${Common.g_szFuturesNameMini[futures]}] MIT ${Common.g_szTradeTypeName[tradeType]} 주문 요청 (가격:${Utils.PriceFormatStr(orderPrice, futures)}, 수량:${orderAmount})`;
          store.LogMsg(strLog);
        } else if (orderMethod === Common.C_ORDERMETHOD_StopLoss) {
          strLog = `[${Common.g_szFuturesNameMini[futures]}] 스탑로스 ${Common.g_szTradeTypeName[tradeType]} 주문 요청 (가격:${Utils.PriceFormatStr(orderPrice, futures)}, 수량:${orderAmount})`;
          store.LogMsg(strLog);

          // StopLoss 설정 초기화
          store.InitStopLoss(futures);
        }
      },

      modifyOrderReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount, orderMethod, ticks = 0, logNum = Common.INDEX_NONE } = params;
        const bidho1 = 0.;
        const opperho1 = 0.;
        const curPrice = 0.;
      
        // 동시호가 체결 오류로 인해 마스터패킷을 제외한 첫번째 패킷을 받았을때만 매수1, 매도1을 보냄
        // 수정 2015.12.15
        // if (MasterStore.isRecvHoga[futures] && MasterStore.isRecvContract[futures]) {
        //   bidho1 = MasterStore.bidho1[futures];
        //   opperho1 = MasterStore.offerho1[futures];
        //   curPrice = MasterStore.dbCurPrice[futures];
        // }

        const averagePrice = 0.;

        const reqObj = {
          futures: futures,
          tradeType: tradeType,
          orderIdx: orderIdx,
          modifyMethod: modifyMethod,
          orderPrice: orderPrice,
          orderAmount: orderAmount,
          orderMethod: orderMethod,
          ticks: ticks,
          logNum: logNum,
          averagePrice: averagePrice,
          curPrice: curPrice,
          bidho1: bidho1,
          opperho1: opperho1,

        }
        NetworkService.printApiRequest(`send.modifyOrderReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'modifyOrderReq', d: reqObj}));
      },

      transactionListReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { futures, isOversea, szStartDate, szEndDate } = params;
        const reqObj = {
          futures: futures,
          isOversea: isOversea,
          szStartDate: szStartDate,
          szEndDate: szEndDate,
        }
        NetworkService.printApiRequest(`send.transactionListReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'transactionListReq', d: reqObj}));
      },

      overnightReq: async (params: any) => {
        const store = useStore();
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { reqList } = params;
        if (!reqList || reqList.length === 0) {
          if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.log('send.overnightReq: 데이터가 없습니다.');
          return;
        }

        const itemList = [] as any;

        // 요청
        reqList.forEach((overnightReq: any) => {
            const data = {
                iOrderIdx: overnightReq.iOrderIdx,
                iFutures: overnightReq.iFutures,
                iTradeType: overnightReq.iTradeType,
                iOppositeAmount: overnightReq.iOppositeAmount,
                iOverTrading: overnightReq.iOverTrading,
                iOvernightState: overnightReq.iOvernightState
            }
            itemList.push(data);
        });

        const reqObj = {
            d: itemList
        }
        NetworkService.printApiRequest(`send.overnightReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'overnightReq', d: reqObj}));
      },

      // 공지 리스트 요청
      noticeTitleReq: async () => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        NetworkService.printApiRequest('send.noticeTitleReq fetched');
        NetworkService._socket.send(JSON.stringify({t: 'noticeTitleReq', d: {}}));
      },

      // 공지 내용 요청
      noticeContentReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { noticeIdx } = params;
        const reqObj = {
          noticeIdx: noticeIdx,
        }
        NetworkService.printApiRequest(`send.noticeContentReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'noticeContentReq', d: reqObj}));
      },

      // 채팅 요청
      customerChatReq: async (params = {} as any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { bEnter = 1 } = params || {};
        const reqObj = {
          bEnter: bEnter,
        }
        NetworkService.printApiRequest(`send.customerChatReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'customerChatReq', d: reqObj}));
      },

      // 채팅 메세지 전송
      chatMessage: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { szMessage = '' } = params || {};
        const type = ChatMsg.eType_User;
        const reqObj = {
          type: type,
          szMessage: szMessage,
        }
        NetworkService.printApiRequest(`send.chatMessage fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'chatMessage', d: reqObj}));
      },

      // 채팅 파일 전송, 서버 처리 안되어있음
      chatTransfer: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { ChatTransfer = null } = params || {};
        // const type = ChatMsg.eType_Transfer;

        const _packet = new Packet(PacketID.PROTOCOL_USER_CHAT_TRANSFER_REQ);

        // _packet.writeInt(type);
        // _packet.writeInt(ChatTransfer.bUser);
        // _packet.writeInt(ChatTransfer.bUpload);
        // _packet.writeInt(ChatTransfer.bClipboard);
        const count = Math.min(ChatTransfer.szFileNames.length, ChatTransfer.szRemoteNames.length);
        // _packet.writeInt(count);
        // for (let idx = 0; idx < count; idx++) {
        //   _packet.writeString(ChatTransfer.szFileNames[idx]);
        //   _packet.writeString(ChatTransfer.szRemoteNames[idx]);
        // }

        const objNames = [] as any;
        for (let idx = 0; idx < count; idx++) {
          const names = {
            szFileNames: ChatTransfer.szFileNames[idx],
            szRemoteNames: ChatTransfer.szRemoteNames[idx]
          }
          objNames.push(names);
        }

        const reqObj = {
          bUser: ChatTransfer.bUser,
          bUpload: ChatTransfer.bUpload,
          bClipboard: ChatTransfer.bClipboard,
          count: count,
          nameList: objNames,
        }

        NetworkService.printApiRequest(`send.chatTransfer fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'chatTransfer', d: reqObj}));
        
        _packet.shrink();
      },

      // 채팅 로그 조회
      chatLogReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { szStartDate = '', szEndDate = '' } = params || {};

        const reqObj = {
          szStartDate: szStartDate,
          szEndDate: szEndDate,
        }
        NetworkService.printApiRequest(`send.chatLogReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'chatLogReq', d: reqObj}));
      },

      // 채팅 시간
      chatTime: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { stChatTime = Date.now() } = params || {};
        const szTime = moment(stChatTime).format('YYYY-MM-DD HH:mm:ss');
        const reqObj = {
          szTime: szTime,
        }
        NetworkService.printApiRequest(`send.chatTime fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'chatTime', d: reqObj}));
      },
      

      // 차트요청
      chartDataReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { ReqId, Futures, Period, Term = ChartConst.cTerm_1, Count = ChartConst.cChartData_Count, szStartDate = '', szEndDate = '' } = params || {};
        const reqObj = {
          ReqId: ReqId,
          Futures: Futures,
          Period: Period,
          Term: Term,
          Count: Count,
          szStartDate: szStartDate,
          szEndDate: szEndDate,
        }
        NetworkService.printApiRequest(`send.chartDataReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'chartDataReq', d: reqObj}));
      },

      // 입출금 로그 요청
      depositNWithdrawListReq(params: any) {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { szStartDate, szEndDate, transType, transState, bTransfer } = params || {};
        const reqObj = {
          szStartDate: szStartDate,
          szEndDate: szEndDate,
          transType: transType,
          transState: transState,
          bTransfer: bTransfer,
        }
        NetworkService.printApiRequest(`send.depositNWithdrawListReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'depositNWithdrawListReq', d: reqObj}));
      },

      // S/L 예약 요청
      stopLossReservedReq: async (params: any) => {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { iSLReserved, iOrderIdx, iFutures, iTradeType, iOppositeAmount, iProfitTicks, iLossTicks, szState = '' } = params || {};
        const reqObj = {
          iSLReserved: iSLReserved,
          iOrderIdx: iOrderIdx,
          iFutures: iFutures,
          iTradeType: iTradeType,
          iOppositeAmount: iOppositeAmount,
          iProfitTicks: iProfitTicks,
          iLossTicks: iLossTicks,
          szState: szState,
        }
        NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
      },

      // 스탑로스 로그 요청
      stopLossLogReq(params: any) {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { futures, szStartDate, szEndDate } = params || {};
        const reqObj = {
          futures: futures,
          szStartDate: szStartDate,
          szEndDate: szEndDate,
        }
        NetworkService.printApiRequest(`send.stopLossLogReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'stopLossLogReq', d: reqObj}));
      },

      // 계좌비번 변경
      newAccPwdReq(params: any) {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { DomesticPwd = '', OverseaPwd = '' } = params || {};
        // _T("")은 변경하지 않음

        const reqObj = {
          DomesticPwd: DomesticPwd,
          OverseaPwd: OverseaPwd,
        }
        NetworkService.printApiRequest(`send.newAccPwdReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'newAccPwdReq', d: reqObj}));
      },

      passwordWriteReq(params: any) {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        const { Pwd, NewPwd } = params || {};
        const reqObj = {
          Pwd: Pwd,
          NewPwd: NewPwd,
        }
        NetworkService.printApiRequest(`send.passwordWriteReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'passwordWriteReq', d: reqObj}));
      },

      // BrandStore.szBrandLeverageInfo 레버리지 정보에 설정가능한 레버리지가 있다.
      //  예) 1:2:5:10:20:50:100  => 1, 2, 5, 10, 20, 50, 100 설정가능하다.
      newLeverageReq(params: any) {
        if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }

        // const { Futures, NewLeverage } = params || {};
        // const _packet = new Packet(PacketID.PROTOCOL_USER_LEVERAGE_REQ);
        // _packet.writeInt(Futures);
        // _packet.writeInt(NewLeverage);
        // _packet.shrink();
        // NetworkService.socket.write(_packet.buffer, null, () => {
        //   console.log(`send.newLeverageReq success: ${JSON.stringify(params)}`);
        // });

        const { Futures, NewLeverage } = params || {};
        const reqObj = {
          Futures: Futures,
          NewLeverage: NewLeverage,
        }
        NetworkService.printApiRequest(`send.newLeverageReq fetched: ${JSON.stringify(params)}`);
        NetworkService.socket.send(JSON.stringify({t: 'newLeverageReq', d: reqObj}));
      },
    }
  }
}