<template>
    <div class="menu-tab" >
        <img class="left-arrow" :class="{ on: isLeftArrowOn }" src="../assets/images/mb-right-arrow.png" alt="" @click="goLeft">
        <div class="row" id="menuTab" @scroll="scrollHandler">
            <div class="col" :class="{ select: $route.path === '/m/Home' }" @click="router.push('/m/Home')">종합시세</div>
            <div class="col" :class="{ select: $route.path === '/m/TotalOrder' }" @click="router.push('/m/TotalOrder')">통합주문</div>
            <div class="col" :class="{ select: $route.path === '/m/TradeList' }" @click="router.push('/m/TradeList')">거래내역</div>
            <div class="col" :class="{ select: $route.path === '/m/TransactionForm' }" @click="router.push('/m/TransactionForm')">입/출금</div>
            <div class="col" :class="{ select: $route.path === '/m/HelpDesk' }" @click="clickHelpDesk('/m/HelpDesk')">상담센터</div>
            <div class="col" :class="{ select: $route.path === '/m/OverNight' }" @click="router.push('/m/OverNight')" v-if="store.SystemState._appMode === 1">오버나잇</div>
            <div class="col" :class="{ select: $route.path === '/m/Notice' }" @click="router.push('/m/Notice')">공지사항</div>
            <div class="col" :class="{ select: $route.path === '/m/Setting' }" @click="router.push('/m/Setting')">설정</div>
        </div>
        <img  class="right-arrow" :class="{ off: isRightArrowOff }" src="../assets/images/mb-right-arrow.png" alt="" @click="goRight">
    </div>
    <PopupHelpDesk :initShowNotice="state.initShowHelpDeskPopup" :initTitle="'상담센터'"  @close="close('PopupHelpDesk')" />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
import { ref } from "vue";
const { route, router, defineEmits, defineProps, onMounted,onUnmounted, reactive, store } = useBaseComponent();

let state = reactive({
    timer: null as any,
    initShowHelpDeskPopup:false,
})

onMounted(()=>{
    window.addEventListener('scroll',handleEnded)
})

onUnmounted(()=> {
    window.removeEventListener('scroll', handleEnded);
})

const handleEnded = () => {
    if(state.timer){
        clearTimeout(state.timer);
    }
    state.timer = setTimeout(()=>{
        scrollHandler();
    }, 500);
}
const scrollPosition = ref(0);

const isLeftArrowOn = ref(false);

const isRightArrowOff = ref(false);

const scrollHandler = () => {
    scrollPosition.value = document.getElementById('menuTab')?.scrollLeft;
    isLeftArrowOn.value = scrollPosition.value > 34;
    isRightArrowOff.value = scrollPosition.value> 200;
}

const goLeft = () => {
    const scroll = document.getElementById('menuTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
}
const goRight = () => {
    const scroll = document.getElementById('menuTab') as HTMLElement;
    scroll.scrollTo({left:9999,behavior:'smooth'})
}

const clickHelpDesk = (page: string) => {
    if (!store.LoginPageState.isPacketLoginAckReceived) return;
    
	if(store.BrandState.bExternalChat === 0) {
		router.push(page)
	} else {
		document.body.style.overflow = "hidden";
		state.initShowHelpDeskPopup = true; 
	}
}
const close = (popupType: string) => {
    switch (popupType) {
        case "PopupHelpDesk":
            document.body.style.overflow = "auto";
            state.initShowHelpDeskPopup = false;
            break;
    }
}
</script>
