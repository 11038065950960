import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "view-change" }
const _hoisted_2 = { class: "select-tab" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "control-box flex-center-end" }
const _hoisted_5 = {
  key: 0,
  class: "date-box flex-center ml0"
}
const _hoisted_6 = { class: "label-wrap flex-center" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 1,
  class: "date-box flex-center"
}
const _hoisted_11 = ["value", "min", "max", "disabled"]
const _hoisted_12 = ["value", "min", "max", "disabled"]
const _hoisted_13 = { class: "mid table-area" }
const _hoisted_14 = { class: "item" }
const _hoisted_15 = { class: "item-inner" }
const _hoisted_16 = {
  key: 0,
  class: "text top"
}
const _hoisted_17 = {
  key: 1,
  class: "text top"
}
const _hoisted_18 = {
  key: 2,
  class: "text top"
}
const _hoisted_19 = {
  key: 3,
  class: "text top"
}
const _hoisted_20 = { class: "item" }
const _hoisted_21 = { class: "item-inner" }
const _hoisted_22 = { class: "text" }
const _hoisted_23 = {
  key: 0,
  class: "text top"
}
const _hoisted_24 = {
  key: 1,
  class: "text top"
}
const _hoisted_25 = { class: "item" }
const _hoisted_26 = { class: "item-inner" }
const _hoisted_27 = { class: "text" }
const _hoisted_28 = {
  key: 0,
  class: "text top"
}
const _hoisted_29 = { style: {"color":"#fc3d5b"} }
const _hoisted_30 = {
  key: 1,
  class: "text top"
}
const _hoisted_31 = { style: {"color":"#006eff"} }
const _hoisted_32 = {
  key: 2,
  class: "text top"
}
const _hoisted_33 = {
  key: 0,
  style: {"color":"#fc3d5b"}
}
const _hoisted_34 = {
  key: 3,
  class: "text top"
}
const _hoisted_35 = {
  key: 0,
  style: {"color":"#006eff"}
}
const _hoisted_36 = { class: "item" }
const _hoisted_37 = { class: "item-inner" }
const _hoisted_38 = { class: "text" }
const _hoisted_39 = {
  key: 0,
  class: "text top"
}
const _hoisted_40 = {
  key: 1,
  class: "text top"
}
const _hoisted_41 = { class: "item" }
const _hoisted_42 = { class: "item-inner" }
const _hoisted_43 = { class: "text" }
const _hoisted_44 = {
  key: 0,
  class: "text top"
}
const _hoisted_45 = {
  key: 1,
  class: "text top"
}
const _hoisted_46 = { class: "item" }
const _hoisted_47 = { class: "item-inner" }
const _hoisted_48 = { class: "text" }
const _hoisted_49 = {
  key: 0,
  class: "text top"
}
const _hoisted_50 = {
  key: 1,
  class: "text top"
}
const _hoisted_51 = { class: "item" }
const _hoisted_52 = { class: "item-inner" }
const _hoisted_53 = { class: "text" }
const _hoisted_54 = {
  key: 0,
  class: "text top"
}
const _hoisted_55 = {
  class: "",
  style: {"color":"#fc3d5b"}
}
const _hoisted_56 = {
  key: 1,
  class: "text top"
}
const _hoisted_57 = {
  class: "",
  style: {"color":"#006eff"}
}
const _hoisted_58 = {
  key: 2,
  class: "text top"
}
const _hoisted_59 = {
  class: "",
  style: {"color":"#fc3d5b"}
}
const _hoisted_60 = {
  key: 3,
  class: "text top"
}
const _hoisted_61 = {
  class: "",
  style: {"color":"#006eff"}
}
const _hoisted_62 = { class: "item" }
const _hoisted_63 = { class: "item-inner" }
const _hoisted_64 = {
  key: 0,
  class: "text top"
}
const _hoisted_65 = {
  key: 1,
  class: "text top"
}
const _hoisted_66 = { class: "item" }
const _hoisted_67 = { class: "item-inner" }
const _hoisted_68 = {
  key: 0,
  class: "text top"
}
const _hoisted_69 = {
  key: 1,
  class: "text top"
}
const _hoisted_70 = { class: "item status" }
const _hoisted_71 = { class: "item-inner" }
const _hoisted_72 = ["onClick"]
const _hoisted_73 = {
  key: 0,
  class: "text top"
}
const _hoisted_74 = ["onClick"]
const _hoisted_75 = {
  key: 1,
  class: "text top"
}
const _hoisted_76 = ["onClick"]
const _hoisted_77 = { class: "item" }
const _hoisted_78 = { class: "item-inner" }
const _hoisted_79 = {
  key: 0,
  class: "text top"
}
const _hoisted_80 = {
  key: 0,
  style: {"color":"#fc3d5b"}
}
const _hoisted_81 = { key: 1 }
const _hoisted_82 = {
  key: 2,
  style: {"color":"#006eff"}
}
const _hoisted_83 = { class: "item" }
const _hoisted_84 = { class: "item-inner" }
const _hoisted_85 = { class: "text" }
const _hoisted_86 = {
  key: 0,
  class: "text top"
}
const _hoisted_87 = { class: "item" }
const _hoisted_88 = { class: "item-inner" }
const _hoisted_89 = { class: "text" }
const _hoisted_90 = {
  key: 0,
  class: "text top"
}
const _hoisted_91 = {
  key: 0,
  style: {"color":"#fc3d5b"}
}
const _hoisted_92 = { key: 1 }
const _hoisted_93 = {
  key: 2,
  style: {"color":"#006eff"}
}
const _hoisted_94 = { class: "item" }
const _hoisted_95 = { class: "item-inner" }
const _hoisted_96 = { class: "text" }
const _hoisted_97 = {
  key: 0,
  class: "text top"
}
const _hoisted_98 = { class: "item" }
const _hoisted_99 = { class: "item-inner" }
const _hoisted_100 = { class: "text" }
const _hoisted_101 = {
  key: 0,
  class: "text top"
}
const _hoisted_102 = { class: "item" }
const _hoisted_103 = { class: "item-inner" }
const _hoisted_104 = { class: "text" }
const _hoisted_105 = {
  key: 0,
  class: "text top"
}
const _hoisted_106 = { class: "item" }
const _hoisted_107 = { class: "item-inner" }
const _hoisted_108 = { class: "text" }
const _hoisted_109 = {
  key: 0,
  class: "text top"
}
const _hoisted_110 = { class: "item" }
const _hoisted_111 = { class: "item-inner" }
const _hoisted_112 = {
  key: 0,
  class: "text top"
}
const _hoisted_113 = { class: "item" }
const _hoisted_114 = { class: "item-inner" }
const _hoisted_115 = {
  key: 0,
  class: "text top"
}
const _hoisted_116 = { class: "item status" }
const _hoisted_117 = { class: "item-inner" }
const _hoisted_118 = ["onClick"]
const _hoisted_119 = {
  key: 0,
  class: "text top"
}
const _hoisted_120 = { class: "table-head flex-center" }
const _hoisted_121 = { class: "item price" }
const _hoisted_122 = { class: "text" }
const _hoisted_123 = { class: "item name" }
const _hoisted_124 = { class: "text" }
const _hoisted_125 = { class: "item event" }
const _hoisted_126 = { class: "text" }
const _hoisted_127 = { class: "item event" }
const _hoisted_128 = { class: "text" }
const _hoisted_129 = { class: "item event" }
const _hoisted_130 = { class: "text" }
const _hoisted_131 = { class: "item price" }
const _hoisted_132 = { class: "text" }
const _hoisted_133 = { class: "item event" }
const _hoisted_134 = { class: "text" }
const _hoisted_135 = { class: "item price" }
const _hoisted_136 = { class: "text" }
const _hoisted_137 = { class: "item price" }
const _hoisted_138 = { class: "text" }
const _hoisted_139 = { class: "item price" }
const _hoisted_140 = { class: "text" }
const _hoisted_141 = { class: "item price" }
const _hoisted_142 = { class: "text" }
const _hoisted_143 = { class: "item price" }
const _hoisted_144 = { class: "item price" }
const _hoisted_145 = { class: "item price" }
const _hoisted_146 = { class: "text" }
const _hoisted_147 = { class: "item event" }
const _hoisted_148 = { class: "text" }
const _hoisted_149 = { class: "item event" }
const _hoisted_150 = { class: "text" }
const _hoisted_151 = { class: "item date" }
const _hoisted_152 = { class: "text" }
const _hoisted_153 = { class: "item name" }
const _hoisted_154 = { class: "text" }
const _hoisted_155 = { class: "item event" }
const _hoisted_156 = { class: "text" }
const _hoisted_157 = { class: "item date" }
const _hoisted_158 = { class: "text" }
const _hoisted_159 = { class: "item event" }
const _hoisted_160 = { class: "text" }
const _hoisted_161 = { class: "item price" }
const _hoisted_162 = { class: "text" }
const _hoisted_163 = { class: "item price" }
const _hoisted_164 = { class: "text" }
const _hoisted_165 = { class: "item event" }
const _hoisted_166 = { class: "text" }
const _hoisted_167 = { class: "item price" }
const _hoisted_168 = { class: "text" }
const _hoisted_169 = { class: "item price" }
const _hoisted_170 = { class: "text" }
const _hoisted_171 = { class: "item price" }
const _hoisted_172 = { class: "text" }
const _hoisted_173 = { class: "item date" }
const _hoisted_174 = { class: "text" }
const _hoisted_175 = { class: "item date" }
const _hoisted_176 = { class: "text" }
const _hoisted_177 = { key: 3 }

import useBaseComponent from "@/baseComponent";
import * as Common from '../../constants/Common';

import { Theme } from '../../constants';
import {
  NetworkService,
  Emit_DrawTrans,
  Emit_TradingLog,
  Emit_StopLossLog,
  Emit_Success,
  Emit_DrawTransProfit
} from '../../network';
import { toLocaleString } from '../../utils/number';
import { UserLog } from '../../network/UserLog';
import * as Utils from '../../utils/Common';
import moment from '../../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../../constants/AccPwd';
import { start } from "repl";
import { ref } from "vue";

const Order_All = 99; // 체결
const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;


export default /*@__PURE__*/_defineComponent({
  __name: 'PcTradeTable',
  props: {
    initBAccPAssed: {
        type: Boolean,
        default: false,
    },
},
  emits: ["reqCheckAccPassword"],
  setup(__props, { emit: __emit }) {

const { route, router, store, onMounted, onUnmounted, defineEmits, defineProps, reactive, watch } = useBaseComponent();

const props = __props;

let state = reactive({
	
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결 / 미체결", value: Order_All},
        // {title: "체결", value: Order_Contract},
        // {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_All,
    selectedItem: null as any,
    
    //체결내역
    oppositeOrders: [] as any,
    renderOppositeList: [] as any,

    //미체결내역
    leftOrders: [] as any,
    renderLeftsList: [] as any,
    
    //거래내역
    orderLogs: [] as any,
    renderLogList: [] as any,
    
    //S/L내역
    stopLossLogs: [] as any,
    renderSLLogsList: [] as any,

    isOversea: Common.eNation_Domestic,
    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,

    //날짜 선택 필터
    selectedDays:"6",
    startDate:'',
    endDate:'',

    //날짜 직접 입력시 선택할 수 있는 날짜 제한
    minStartDate: '',
    maxStartDate: '',
    minEndDate: '',
    maxEndDate: '',
    isFilterDisabled: false,

    timer: null as any,
});

watch(props, (initBAccPAssed) => {
    if (initBAccPAssed) {
        state.bAccPassed = true;
        initData();
    }
},{ deep: true });

onMounted(()=>{
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.on(Emit_StopLossLog, event_StopLossLog);
    NetworkService.emitter.on(Emit_DrawTransProfit, event_DrawTrans);
    window.addEventListener('scroll',handleEnded)
})
onUnmounted(()=> {
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.off(Emit_StopLossLog, event_StopLossLog);
    NetworkService.emitter.off(Emit_DrawTransProfit, event_DrawTrans);
    window.removeEventListener('scroll', handleEnded);
})


const handleEnded = () => {
    if(state.timer){
        clearTimeout(state.timer);
    }
    state.timer = setTimeout(()=>{
        scrollHandler();
    }, 500);
}
const scrollPosition = ref(0);

const isLeftArrowOn = ref(false);

const isRightArrowOff = ref(false);

const scrollHandler = () => {
    scrollPosition.value = document.getElementById('tableTab')?.scrollLeft;
    isLeftArrowOn.value = scrollPosition.value > 34;
    isRightArrowOff.value = scrollPosition.value > 400;
}

const goLeft = () => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
}
const goRight = () => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:9999,behavior:'smooth'})
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 리스트 업데이트
    refreshOrders();
}

const event_TradingLog = (params: any) => {
    // 거래 로그 수신 완료.
    const TradingLogs = store.AccountState.TradingLogs;
    state.orderLogs = TradingLogs;
    refreshOrders();
}

const event_StopLossLog = (params: any) => {
    // 스탑로스 로그 수신 완료.
    const StopLossLogs = store.AccountState.StopLossLogs;
    state.stopLossLogs = StopLossLogs;
    refreshOrders();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        // calcuAmount();
        state.refreshCount++;

        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
    onResetDateFilter();

    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }
}

const onResetDateFilter = () => {
    const nowDate = Date.now();

    state.selectedDays = '6';
    state.minStartDate = moment(nowDate).add(-30, 'days').format('YYYY-MM-DD');
    state.maxStartDate = moment(nowDate).format('YYYY-MM-DD');

    setDatePicker({selectedDays: state.selectedDays});
};

//date picker 에 날짜 입력
const setDatePicker = ({selectedDays = null as any, pickStartDate = '' as string, pickEndDate = '' as string }) => {
    if(state.isFilterDisabled) return;

    const nowDate = Date.now();

    if(selectedDays !== null){
        state.selectedDays = selectedDays;
        state.startDate = moment(nowDate).add(-(Number(selectedDays) -1), 'days').format('YYYY-MM-DD');
        state.endDate = moment(nowDate).format('YYYY-MM-DD');
        setDatePickerLimit();
    } else if(pickStartDate !== ''){
        state.selectedDays = '';
        state.startDate = pickStartDate;
        state.endDate = pickStartDate;
        setDatePickerLimit();
    }  else if(pickEndDate !== ''){
        state.endDate = pickEndDate;
    }

    if(state.tabIdx !== Order_Log && state.tabIdx !== Order_SL){
        state.isFilterDisabled = true;
    } else {
        state.isFilterDisabled = false;
    }

}

//Date picker 날짜 선택 제한을 설정
const setDatePickerLimit = (dayRange = 5) => {
    const nowDate = moment(Date.now());

    state.minEndDate = state.startDate;
    state.maxEndDate = moment(state.minEndDate).add(dayRange, 'days').format('YYYY-MM-DD');
    const diffDay = moment(state.maxEndDate).diff(nowDate, 'days');

    if(diffDay > 0){
        state.maxEndDate = nowDate.format('YYYY-MM-DD');
    }
}

const onTabPress = (tabIdx: number) => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
    isRightArrowOff.value = false;
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;
        
        if(state.tabIdx !== Order_Log && state.tabIdx !== Order_SL){
            state.isFilterDisabled = true;
        } else {
            state.isFilterDisabled = false;
        }

        if(state.bAccPassed){
            onSearchPress();
        }
    }
}

//검색 시작 날짜 직접 선택
const onChangeStartDate = (e: any) => {
    const pickDate = e.target.value;
    setDatePicker({pickStartDate: pickDate})
}

//검색 종료 날짜 직접 선택
const onChangeEndDate = (e: any) => {
    const pickDate = e.target.value;
    setDatePicker({pickEndDate: pickDate})
}

const onSearchPress = () => {
    if(!state.bAccPassed) {
        reqCheckAccPassword();
        return;
    }

    if(state.tabIdx === Order_All ||state.tabIdx === Order_Contract || state.tabIdx === Order_Left){
        // 체결 / 미체결 리스트 업데이트
        refreshOrders();   
        
    } else if (state.tabIdx === Order_Log){
        // 거래내역 요청
        tradingLogsReq();
    } else if (state.tabIdx === Order_SL){
        // 스탑로스내역 요청
        stopLossLogsReq();
    }
}

// 거래내역 요청
const tradingLogsReq = () => {
    state.orderLogs = [];
    state.renderLogList = [];
    const szStartDate = moment(state.startDate).format('YYYYMMDD');
    const szEndDate = moment(state.endDate).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체
    // 0 = 국내, 1 = 해외, 2 = 국내/해외
    const isOversea = Common.eNation_Max;

    const szUserLog = `[${Common.g_szNationName[isOversea]} 거래내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.transactionListReq({
        futures, 
        isOversea, 
        szStartDate, 
        szEndDate,
    });
}

// 스탑로스내역 요청
const stopLossLogsReq = () => {
    state.stopLossLogs = [];
    state.renderSLLogsList = [];
    const szStartDate = moment(state.startDate).format('YYYYMMDD');
    const szEndDate = moment(state.endDate).format('YYYYMMDD');
    const futures = Common.G_FUTURES_MAX; // 전체

    const szUserLog = `[${Common.g_szFuturesNameMini[futures]} S/L내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);
    console.log(szUserLog)
    NetworkService.send.stopLossLogReq({
        futures, 
        szStartDate, 
        szEndDate,
    });
}

// 청산 / 취소
const onActionPress = (order: any) => {
    // 계정 비번 확인
    // if (!checkAccPwd()) {
    //     return;
    // }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderPrice = order.dbOrderPrice;
    const orderIdx = order.iOrderIdx;

    // 체결창
    if (state.tabIdx === Order_All &&order.iOppositeAmount > 0 ) {
        // 청산
        if (order.iOppositeAmount === 0) {
            store.setVisibleDialog({ message: '체결된 주문만 청산이 가능합니다.' });
            // console.log('체결된 주문만 청산이 가능합니다.');
            return;
        }

        // 청산주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_BatchSettle;
        const orderMethod = Common.C_ORDERMETHOD_SellBtn;
        const oppTradeType = (tradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const orderMax = store.AccountState.iOrderMax[futures][oppTradeType];
        if (orderMax < order.iOppositeAmount) {
            store.setVisibleDialog({ message: `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.` });
            // console.log(`주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.`);
            return;
        } else {
            UserLog.modifyOrder(futures, tradeType, orderPrice, order.iOppositeAmount, modifyMethod, orderMethod, orderIdx);
            NetworkService.send.modifyOrderReq({
                futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iOppositeAmount, orderMethod,
            });
        }
    } else if (state.tabIdx === Order_All && order.iLeftAmount > 0 ) {
        // 미체결창
        // 취소
        if (order.iLeftAmount === 0) {
            store.setVisibleDialog({ message: '미체결된 주문만 취소가 가능합니다.' });
            // console.log('미체결된 주문만 취소가 가능합니다.');
            return;
        }

        // 취소주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_Cancel;
        const orderMethod = Common.C_ORDERMETHOD_CancelBtn;
        UserLog.modifyOrder(futures, tradeType, orderPrice, order.iLeftAmount, modifyMethod, orderMethod, orderIdx);
        NetworkService.send.modifyOrderReq({
            futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iLeftAmount, orderMethod,
        });
    }
}
const slCancelBtn = (params: any) => {
    if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { iSLReserved, iOrderIdx, iFutures, iTradeType, iOppositeAmount, iProfitTicks, iLossTicks, szState = '' } = params || {};
        if(params.iProfitTicks > 0 && params.iLossTicks >0){
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: 0,
            iLossTicks: 0,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else if(params.iProfitTicks > 0 && params.iLossTicks === 0) {
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: 0,
            iLossTicks: iLossTicks,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else if(params.iLossTicks > 0 && params.iProfitTicks === 0){
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: iProfitTicks,
            iLossTicks: 0,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else{
            // /
        }
}

const refreshOrders = () => {
    if(!state.bAccPassed) {
        return;
    }

    // 체결 리스트를 얻는다.
    const oppositeOrders = store.GetClientOppositeList();
    //const oppositeOrders = TEST_ORDERS;
    state.oppositeOrders = oppositeOrders;
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;
    state.leftOrders = leftOrders;

    renderOrders();
}

const renderOrders = () => {
    const orderIdx = state.tabIdx;
    if(orderIdx === Order_All){
        renderOpposite(state.oppositeOrders);
        renderLefts(state.leftOrders);
    } else if(orderIdx === Order_Contract){
        //체결
        renderOpposite(state.oppositeOrders)
    } else if(orderIdx === Order_Left){
        //미체결
        renderLefts(state.leftOrders)
    } else if(orderIdx === Order_Log) {
        //거래내역
        renderLogs(state.orderLogs)
    } else {
        //SL내역
        renderSLs(state.stopLossLogs);
    }
}

//체결 리스트
const renderOpposite = (orderList: any) => {
    state.renderOppositeList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);
        const objOrder = {
            order: order,
            futures: order.iFutures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            priceType:Common.g_szPriceTypeName[order.iPriceType],
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            orderIdx: order.iOrderIdx,
            amount: order.iOppositeAmount,
            profit: profit,
            fee: fee,
            stopTicks: order.bUseStop ? `${order.iStopTicks}` : '사용안함',
            lossTicks: order.bUseLoss ? `${order.iLossTicks}` : '사용안함',
            averagePrice: Utils.MicroPriceFormatStr(order.dbAvePrice, futures),
            // 2023-10-27: 당일 누적 금액으로 변경
            //net: profit - fee,
            //net: ' / ' + store.AccountState.iRealGainNLossFutures[futures] + ' / ' + store.AccountState.iDepoCommissionFutures[futures]
            //net: store.AccountState.iDepoCommissionFutures[futures]
            net: store.AccountState.iRealGainNLossFutures[futures] - store.AccountState.iDepoCommissionFutures[futures]
        }
        state.renderOppositeList.push(objOrder)
        // console.log('A', state.renderOppositeList)
    })
}

//미체결 리스트
const renderLefts = (orderList: any) => {
    state.renderLeftsList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const objOrder = {
            order: order,
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            priceType: Common.g_szPriceTypeName[order.iPriceType],
            orderIdx: order.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[Common.C_ORDERACCEPT_Acceptance],
            amount: order.iOrderAmount,
            oppAmount: order.iOppositeAmount,
            stopTicks: order.bUseStop ? `${order.iStopTicks}` : '사용안함',
            lossTicks: order.bUseLoss ? `${order.iLossTicks}` : '사용안함',
            averagePrice: Utils.MicroPriceFormatStr(order.dbAvePrice, futures),
        }
        state.renderLeftsList.push(objOrder)
        // console.log('B', state.renderLeftsList)
    })
}

//거래내역
const renderLogs = (orderLog: any) => {
    // TradingLog
    state.renderLogList = [];
    orderLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(log.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            priceType: Common.g_szPriceTypeName[log.iPriceType],
            orderIdx: log.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[log.iAcceptState],
            amount: log.iOrderAmount,
            contAmount: log.iContractedAmount,
            averagePrice: Utils.MicroPriceFormatStr(log.dbContractedPrice, futures),   // 평균
            contractDate: log.szContractedDate, // 체결시
            overTrading: log.szOverTrading,
            leverage: log.iLeverage,
            strProfit: toLocaleString(log.iProfit),
            fee: toLocaleString(log.iCommission),
            strTotalProfit: toLocaleString(log.iTotalProfit),
            evalProfit: toLocaleString(log.nEvaluateProfit),
            depoAmount: toLocaleString(log.iDepoAmount),
            profit: log.iProfit,
            totalProfit: log.iTotalProfit
        }
        state.renderLogList.push(objOrder)
    })
}

//SL내역
const renderSLs = (stopLossLog: any) => {
    // TradingLog
    state.renderSLLogsList = [];
    // console.log(stopLossLog);
    stopLossLog.forEach((log: any)=> {
        const futures = log.iFutures;
        // console.log('SLs',log)
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            state: log.szState,
            averagePrice: Utils.MicroPriceFormatStr(log.dbAvePrice, futures),   // 평균가,
            stopPrice: Utils.MicroPriceFormatStr(log.dbStopPrice, futures),   // 스탑가,
            contractPrice: Utils.MicroPriceFormatStr(log.dbContractPrice, futures),   // 체결가,
            stopTicks: log.bUseStop ? `${log.iStopTicks}` : '사용안함',
            stopColor: log.iUsedTick === 1 ? Theme.palette.red : Theme.text.color,
            stopWeight: log.iUsedTick === 1 ? 'bold' : 'normal',
            lossTicks: log.bUseLoss ? `${log.iLossTicks}` : '사용안함',
            lossColor: log.iUsedTick === 2 ? Theme.palette.red : Theme.text.color,
            lossWeight: log.iUsedTick === 2 ? 'bold' : 'normal',
            slName: log.bReserved ? 'S/L' : '',
            orderIdx: log.iOrderIdx >= 0 ? `${log.iOrderIdx}` : '',
            regDate: log.szRegDate, // 등록시간,
            useDate: log.szUseDate, // 완료시긴,
        }
        state.renderSLLogsList.push(objOrder)
    });
}


const viewValue = (e:any) => {
    console.log(e.target.value)
}

const emit = __emit;

const reqCheckAccPassword = () =>{
    emit('reqCheckAccPassword');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).tabList, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["tab-item", [{ on: _unref(state).tabIdx === item.value }, `no`+ item.value]]),
            key: item.value,
            onClick: ($event: any) => (onTabPress(item.value))
          }, _toDisplayString(item.title), 11, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_unref(state).isFilterDisabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text" }, "기간 선택", -1)),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: "1d",
                  name: "1d",
                  value: "1",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).selectedDays) = $event)),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (setDatePicker({selectedDays: 1}))),
                  disabled: _unref(state).isFilterDisabled
                }, null, 40, _hoisted_7), [
                  [_vModelRadio, _unref(state).selectedDays]
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("label", { for: "1d" }, "1일", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: "3d",
                  name: "3d",
                  value: "3",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).selectedDays) = $event)),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (setDatePicker({selectedDays: 3}))),
                  disabled: _unref(state).isFilterDisabled
                }, null, 40, _hoisted_8), [
                  [_vModelRadio, _unref(state).selectedDays]
                ]),
                _cache[8] || (_cache[8] = _createElementVNode("label", { for: "3d" }, "3일", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "radio",
                  id: "1w",
                  name: "6d",
                  value: "6",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).selectedDays) = $event)),
                  onChange: _cache[5] || (_cache[5] = ($event: any) => (setDatePicker({selectedDays: 6}))),
                  disabled: _unref(state).isFilterDisabled
                }, null, 40, _hoisted_9), [
                  [_vModelRadio, _unref(state).selectedDays]
                ]),
                _cache[9] || (_cache[9] = _createElementVNode("label", { for: "1w" }, "6일", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        (!_unref(state).isFilterDisabled)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text" }, "직접 입력", -1)),
              _createElementVNode("input", {
                type: "date",
                id: "start",
                name: "search-start",
                value: _unref(state).startDate,
                min: _unref(state).minStartDate,
                max: _unref(state).maxStartDate,
                onChange: onChangeStartDate,
                disabled: _unref(state).isFilterDisabled
              }, null, 40, _hoisted_11),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "empty-line" }, "-", -1)),
              _createElementVNode("input", {
                type: "date",
                id: "end",
                name: "search-end",
                value: _unref(state).endDate,
                min: _unref(state).minEndDate,
                max: _unref(state).maxEndDate,
                onChange: onChangeEndDate,
                disabled: _unref(state).isFilterDisabled
              }, null, 40, _hoisted_12)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "submit",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (onSearchPress()))
        }, "조회")
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      (_unref(state).tabIdx === Order_All)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "scroll-wrap",
            id: "tableTab",
            onScroll: scrollHandler
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["table-box orderAllTab", [{'tradePage':_unref(route).name === 'PcTradeList'}]])
            }, [
              _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"table-head flex-center\"><div class=\"item\"><p class=\"text\">상태</p></div><div class=\"item\"><p class=\"text\">거래종목</p></div><div class=\"item\"><p class=\"text\">가격유형</p></div><div class=\"item\"><p class=\"text\">매매구분</p></div><div class=\"item\"><p class=\"text\">주문번호</p></div><div class=\"item\"><p class=\"text\">체결 / 주문수량</p></div><div class=\"item\"><p class=\"text\">평단가 / 주문가</p></div><div class=\"item\"><p class=\"text\">평가손익</p></div><div class=\"item\"><p class=\"text\">실현손익</p></div><div class=\"item\"><p class=\"text\">청산 / 취소</p></div></div>", 1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderOppositeList, (item, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "table-body flex-center",
                  key: idx
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text" }, "체결", -1)),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_16, _cache[13] || (_cache[13] = [
                            _createElementVNode("span", { style: {"color":"#fc3d5b"} }, "익절", -1)
                          ])))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iLossTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_17, _cache[14] || (_cache[14] = [
                            _createElementVNode("span", { style: {"color":"#006eff"} }, "손절", -1)
                          ])))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_18, _cache[15] || (_cache[15] = [
                            _createElementVNode("span", { style: {"color":"#fc3d5b"} }, "익절", -1)
                          ])))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도' && item.order.iLossTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_19, _cache[16] || (_cache[16] = [
                            _createElementVNode("span", { style: {"color":"#006eff"} }, "손절", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("p", _hoisted_22, _toDisplayString(item.name), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(item.name), 1))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(item.name), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("p", _hoisted_27, _toDisplayString(item.priceType), 1),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks !== 0 )
                        ? (_openBlock(), _createElementBlock("p", _hoisted_28, [
                            _createElementVNode("span", _hoisted_29, "익: " + _toDisplayString(item.order.iProfitTicks), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iLossTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_30, [
                            _createElementVNode("span", _hoisted_31, "손: " + _toDisplayString(item.order.iLossTicks), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_32, [
                            (item.order.iProfitTicks !== 0 )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_33, "익: " + _toDisplayString(item.order.iProfitTicks), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iLossTicks !== 0 )
                        ? (_openBlock(), _createElementBlock("p", _hoisted_34, [
                            (item.order.iLossTicks !== 0 )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_35, "손: " + _toDisplayString(item.order.iLossTicks), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("p", _hoisted_38, _toDisplayString(item.tradeType), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_39, _toDisplayString(item.tradeType), 1))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_40, _toDisplayString(item.tradeType), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("p", _hoisted_43, _toDisplayString(item.orderIdx), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_44, _toDisplayString(item.orderIdx), 1))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_45, _toDisplayString(item.orderIdx), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("p", _hoisted_48, _toDisplayString(item.amount), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_49, _toDisplayString(item.amount), 1))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_50, _toDisplayString(item.amount), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("p", _hoisted_53, _toDisplayString(item.price), 1),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks  > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                            _createElementVNode("span", _hoisted_55, _toDisplayString(Utils.MicroPriceFormatStr(item.order.dbOrderPrice + Common.G_ONETICK[item.order.iFutures]* item.order.iProfitTicks,item.order.iFutures)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매수'&& item.order.iLossTicks > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                            _createElementVNode("span", _hoisted_57, _toDisplayString(Utils.MicroPriceFormatStr(item.order.dbOrderPrice - Common.G_ONETICK[item.order.iFutures]* item.order.iLossTicks,item.order.iFutures)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도' &&  item.order.iProfitTicks  > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                            _createElementVNode("span", _hoisted_59, _toDisplayString(Utils.MicroPriceFormatStr(item.order.dbOrderPrice - Common.G_ONETICK[item.order.iFutures]* item.order.iProfitTicks,item.order.iFutures)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iLossTicks > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                            _createElementVNode("span", _hoisted_61, _toDisplayString(Utils.MicroPriceFormatStr(item.order.dbOrderPrice + Common.G_ONETICK[item.order.iFutures]* item.order.iLossTicks,item.order.iFutures)), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.profit > 0}, {down: item.profit < 0}]])
                      }, _toDisplayString(item.futures >= Common.G_FUTURES_OVERSEAS ? _unref(toLocaleString)(item.profit / _unref(store).AccountState.iLeverageEuro) : _unref(toLocaleString)(item.profit / _unref(store).AccountState.iLeverage)), 3),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_64, "-"))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_65, "-"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("div", _hoisted_67, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.net > 0}, {down: item.net < 0}]])
                      }, _toDisplayString(item.futures >= Common.G_FUTURES_OVERSEAS ? _unref(toLocaleString)(item.net) : _unref(toLocaleString)(item.net)), 3),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_68, "-"))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_69, "-"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("div", _hoisted_71, [
                      _createElementVNode("button", {
                        class: "settlement",
                        onClick: ($event: any) => (onActionPress(item.order))
                      }, _cache[18] || (_cache[18] = [
                        _createElementVNode("span", { class: "text" }, "청산", -1)
                      ]), 8, _hoisted_72),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                            _createElementVNode("button", {
                              class: "settlement",
                              onClick: ($event: any) => (slCancelBtn(item.order))
                            }, _cache[19] || (_cache[19] = [
                              _createElementVNode("span", { class: "" }, "취소", -1)
                            ]), 8, _hoisted_74)
                          ]))
                        : _createCommentVNode("", true),
                      (item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                            _createElementVNode("button", {
                              class: "settlement",
                              onClick: ($event: any) => (slCancelBtn(item.order))
                            }, _cache[20] || (_cache[20] = [
                              _createElementVNode("span", { class: "text" }, "취소", -1)
                            ]), 8, _hoisted_76)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderLeftsList, (item, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "table-body flex-center",
                  key: idx
                }, [
                  _createElementVNode("div", _hoisted_77, [
                    _createElementVNode("div", _hoisted_78, [
                      _cache[21] || (_cache[21] = _createElementVNode("p", { class: "text" }, "미체결", -1)),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_79, [
                            (item.order.iLossTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_80, "익절"))
                              : _createCommentVNode("", true),
                            (item.order.iLossTicks !== 0 && item.order.iStopTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_81, " / "))
                              : _createCommentVNode("", true),
                            (item.order.iStopTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_82, "손절"))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_83, [
                    _createElementVNode("div", _hoisted_84, [
                      _createElementVNode("p", _hoisted_85, _toDisplayString(item.name), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_86, _toDisplayString(item.name), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_87, [
                    _createElementVNode("div", _hoisted_88, [
                      _createElementVNode("p", _hoisted_89, _toDisplayString(item.priceType), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_90, [
                            (item.order.iLossTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_91, "익: " + _toDisplayString(item.order.iLossTicks), 1))
                              : _createCommentVNode("", true),
                            (item.order.iLossTicks !== 0 && item.order.iStopTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_92, " / "))
                              : _createCommentVNode("", true),
                            (item.order.iStopTicks !== 0)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_93, "손: " + _toDisplayString(item.order.iStopTicks), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_94, [
                    _createElementVNode("div", _hoisted_95, [
                      _createElementVNode("p", _hoisted_96, _toDisplayString(item.tradeType), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_97, _toDisplayString(item.tradeType), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_98, [
                    _createElementVNode("div", _hoisted_99, [
                      _createElementVNode("p", _hoisted_100, _toDisplayString(item.orderIdx), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_101, _toDisplayString(item.orderIdx), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_102, [
                    _createElementVNode("div", _hoisted_103, [
                      _createElementVNode("p", _hoisted_104, _toDisplayString(item.amount), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_105, _toDisplayString(item.amount), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_106, [
                    _createElementVNode("div", _hoisted_107, [
                      _createElementVNode("p", _hoisted_108, _toDisplayString(item.price), 1),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_109, _toDisplayString(item.averagePrice), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_110, [
                    _createElementVNode("div", _hoisted_111, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.profit > 0}, {down: item.profit < 0}]])
                      }, " - ", 2),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_112, "-"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_113, [
                    _createElementVNode("div", _hoisted_114, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.net > 0}, {down: item.net < 0}]])
                      }, " - ", 2),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_115, "-"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_116, [
                    _createElementVNode("div", _hoisted_117, [
                      _createElementVNode("button", {
                        class: "settlement",
                        onClick: ($event: any) => (onActionPress(item.order))
                      }, _cache[22] || (_cache[22] = [
                        _createElementVNode("span", { class: "text" }, "취소", -1)
                      ]), 8, _hoisted_118),
                      (item.order.iStopLossLogIdx > 0)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_119, " - "))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              }), 128))
            ], 2)
          ], 32))
        : (_unref(state).tabIdx === Order_Log)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "scroll-wrap",
              id: "tableTab",
              onScroll: scrollHandler
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["table-box", {'tradePage':_unref(route).name === 'PcTradeList'}])
              }, [
                _createElementVNode("div", _hoisted_120, [
                  _cache[24] || (_cache[24] = _createStaticVNode("<div class=\"item name\"><p class=\"text\">거래종목</p></div><div class=\"item event\"><p class=\"text\">매매구분</p></div><div class=\"item event\"><p class=\"text\">주문번호</p></div><div class=\"item event\"><p class=\"text\">주문량</p></div><div class=\"item price\"><p class=\"text\">주문가격</p></div><div class=\"item event\"><p class=\"text\">가격유형</p></div><div class=\"item price\"><p class=\"text\">평균단가</p></div>", 7)),
                  _createElementVNode("div", _hoisted_121, [
                    _createElementVNode("p", _hoisted_122, _toDisplayString(_unref(store).SystemState._appMode === 2 ? '레버리지' : '오버나잇'), 1)
                  ]),
                  _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"item price\"><p class=\"text\">수수료</p></div><div class=\"item price\"><p class=\"text\">평가금액</p></div><div class=\"item price\"><p class=\"text\">손익</p></div><div class=\"item price\"><p class=\"text\">총손익</p></div><div class=\"item price\"><p class=\"text\">보유잔고</p></div><div class=\"item event\"><p class=\"text\">접수상태</p></div><div class=\"item event\"><p class=\"text\">체결량</p></div><div class=\"item date\"><p class=\"text\">체결시간</p></div>", 8))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderLogList, (item, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "table-body flex-center",
                    key: idx
                  }, [
                    _createElementVNode("div", _hoisted_123, [
                      _createElementVNode("p", _hoisted_124, _toDisplayString(item.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_125, [
                      _createElementVNode("p", _hoisted_126, _toDisplayString(item.tradeType), 1)
                    ]),
                    _createElementVNode("div", _hoisted_127, [
                      _createElementVNode("p", _hoisted_128, _toDisplayString(item.orderIdx), 1)
                    ]),
                    _createElementVNode("div", _hoisted_129, [
                      _createElementVNode("p", _hoisted_130, _toDisplayString(item.amount), 1)
                    ]),
                    _createElementVNode("div", _hoisted_131, [
                      _createElementVNode("p", _hoisted_132, _toDisplayString(item.price), 1)
                    ]),
                    _createElementVNode("div", _hoisted_133, [
                      _createElementVNode("p", _hoisted_134, _toDisplayString(item.priceType), 1)
                    ]),
                    _createElementVNode("div", _hoisted_135, [
                      _createElementVNode("p", _hoisted_136, _toDisplayString(item.averagePrice), 1)
                    ]),
                    _createElementVNode("div", _hoisted_137, [
                      _createElementVNode("p", _hoisted_138, _toDisplayString(_unref(store).SystemState._appMode === 2 ? item.leverage : item.overTrading), 1)
                    ]),
                    _createElementVNode("div", _hoisted_139, [
                      _createElementVNode("p", _hoisted_140, _toDisplayString(item.fee), 1)
                    ]),
                    _createElementVNode("div", _hoisted_141, [
                      _createElementVNode("p", _hoisted_142, _toDisplayString(item.evalProfit), 1)
                    ]),
                    _createElementVNode("div", _hoisted_143, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.profit > 0}, {down: item.profit < 0}]])
                      }, _toDisplayString(item.strProfit), 3)
                    ]),
                    _createElementVNode("div", _hoisted_144, [
                      _createElementVNode("p", {
                        class: _normalizeClass(["text", [{ up: item.totalProfit > 0}, {down: item.totalProfit < 0}]])
                      }, _toDisplayString(item.strTotalProfit), 3)
                    ]),
                    _createElementVNode("div", _hoisted_145, [
                      _createElementVNode("p", _hoisted_146, _toDisplayString(item.depoAmount), 1)
                    ]),
                    _createElementVNode("div", _hoisted_147, [
                      _createElementVNode("p", _hoisted_148, _toDisplayString(item.acceptState), 1)
                    ]),
                    _createElementVNode("div", _hoisted_149, [
                      _createElementVNode("p", _hoisted_150, _toDisplayString(item.contAmount), 1)
                    ]),
                    _createElementVNode("div", _hoisted_151, [
                      _createElementVNode("p", _hoisted_152, [
                        _createTextVNode(_toDisplayString(item.contractDate.substr(0,8)), 1),
                        _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(_toDisplayString(item.contractDate.substr(9,8)), 1)
                      ])
                    ])
                  ]))
                }), 128))
              ], 2)
            ], 32))
          : (_unref(state).tabIdx === Order_SL)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "scroll-wrap",
                id: "tableTab",
                onScroll: scrollHandler
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["table-box orderSLTab", {'tradePage':_unref(route).name === 'PcTradeList'}])
                }, [
                  _cache[29] || (_cache[29] = _createStaticVNode("<div class=\"table-head flex-center\"><div class=\"item name\"><p class=\"text\">거래종목</p></div><div class=\"item event\"><p class=\"text\">매매구분</p></div><div class=\"item date\"><p class=\"text\">주문번호</p></div><div class=\"item event\"><p class=\"text\">상태</p></div><div class=\"item price\"><p class=\"text\">이익틱</p></div><div class=\"item price\"><p class=\"text\">손실틱</p></div><div class=\"item event\"><p class=\"text\">S/L여부</p></div><div class=\"item price\"><p class=\"text\">스탑가격</p></div><div class=\"item price\"><p class=\"text\">평균가격</p></div><div class=\"item price\"><p class=\"text\">체결가격</p></div><div class=\"item date\"><p class=\"text\">등록시간</p></div><div class=\"item date\"><p class=\"text\">완료시간</p></div></div>", 1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderSLLogsList, (item, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "table-body flex-center",
                      key: idx
                    }, [
                      _createElementVNode("div", _hoisted_153, [
                        _createElementVNode("p", _hoisted_154, _toDisplayString(item.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_155, [
                        _createElementVNode("p", _hoisted_156, _toDisplayString(item.tradeType), 1)
                      ]),
                      _createElementVNode("div", _hoisted_157, [
                        _createElementVNode("p", _hoisted_158, _toDisplayString(item.orderIdx), 1)
                      ]),
                      _createElementVNode("div", _hoisted_159, [
                        _createElementVNode("p", _hoisted_160, _toDisplayString(item.state), 1)
                      ]),
                      _createElementVNode("div", _hoisted_161, [
                        _createElementVNode("p", _hoisted_162, _toDisplayString(item.stopTicks), 1)
                      ]),
                      _createElementVNode("div", _hoisted_163, [
                        _createElementVNode("p", _hoisted_164, _toDisplayString(item.lossTicks), 1)
                      ]),
                      _createElementVNode("div", _hoisted_165, [
                        _createElementVNode("p", _hoisted_166, _toDisplayString(item.slName), 1)
                      ]),
                      _createElementVNode("div", _hoisted_167, [
                        _createElementVNode("p", _hoisted_168, _toDisplayString(item.stopPrice), 1)
                      ]),
                      _createElementVNode("div", _hoisted_169, [
                        _createElementVNode("p", _hoisted_170, _toDisplayString(item.averagePrice), 1)
                      ]),
                      _createElementVNode("div", _hoisted_171, [
                        _createElementVNode("p", _hoisted_172, _toDisplayString(item.contractPrice), 1)
                      ]),
                      _createElementVNode("div", _hoisted_173, [
                        _createElementVNode("p", _hoisted_174, [
                          _createTextVNode(_toDisplayString(item.regDate.substr(0,8)), 1),
                          _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(_toDisplayString(item.regDate.substr(9,8)), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_175, [
                        _createElementVNode("p", _hoisted_176, [
                          _createTextVNode(_toDisplayString(item.useDate.substr(0,8)), 1),
                          _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                          _createTextVNode(_toDisplayString(item.useDate.substr(9,8)), 1)
                        ])
                      ])
                    ]))
                  }), 128))
                ], 2)
              ], 32))
            : (_openBlock(), _createElementBlock("div", _hoisted_177))
    ])
  ], 64))
}
}

})