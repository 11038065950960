<template>
    <div class="wrap">
        <Header :text="'실시간 선물시세'" />
        <div class="real-time innerHeight">
            <table>
                <tr>
                    <th class="col-2">종목</th>
                    <th class="col-2">현재가</th>
                    <th class="col-2">대비</th>
                    <th class="col-2">등락률</th>
                    <th class="col-2">거래량</th>
                </tr>
                <tr v-for="(item, idx) in state.marketPrices" :key="idx" :class="[{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]">
                    <td class="event">
                        <p>{{ item.이름 }}<br/><span>{{ item.코드 }}({{ item.만기일 }})</span></p>
                    </td>
                    <td class="now">
                        <p>{{ Utils.PriceFormatStr(item.현재가, item.종목) }}</p>
                    </td>
                    <td class="prepare">
                        <p>{{ XingAPIService.GetSignData(item.전일대비기호) }} {{ Utils.PriceFormatStr(item.전일대비, item.종목) }}</p>
                    </td>
                    <td class="rate">
                        <p>{{ item.등락률?.toFixed(2) }}%</p>
                    </td>
                    <td class="volume">
                        <p>{{ toLocaleString(item.거래량) }}</p>
                    </td>
                </tr>
            </table>
        </div>
        <!-- <MenuTab /> -->
    </div>
    <PopupNotice :initShowNotice="state.initShowNotice" :initTitle="state.initNoticeTitle" :initNoticeDate="state.initNoticeDate" :initNoticeMessage="state.initNoticeContent" @noToday="noToday" @close="close('PopupNotice')" />
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive } = useBaseComponent();

import * as Common from '../constants/Common';
import * as Utils from '../utils/Common';
import { toLocaleString } from '../utils/number';
import { XingAPIService } from '../network/XingAPIService';

import { Emit_Success, Emit_NoticeContent, NetworkService } from "@/network"
import * as NetConstants from '../network/Constants';
import moment from '../utils/moment';
import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import iconv from 'iconv-lite';
import * as Constant from '../constants/Global';

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

const REFRESH_INTERVAL = 200;

let state = reactive({

    refreshing : false,
    refreshCount : 0,
    marketPrices: [] as any,

    popupAnnounce: null as any,

    initShowNotice: false,
    initNoticeTitle: "",
    initNoticeDate: "",
    initNoticeContent: "",

    timerReqNotice: null as any,
    regDate: [] as any,

    //오늘 더안보기
    noToday:false,
});

onMounted(()=>{

    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('Home');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess );
    NetworkService.emitter.on(Emit_NoticeContent, event_NoticeContent);

    const autoRefreshing = async () => {
        onRefresh();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();

    // 이걸 보내야하는데...... 타이밍 오류남
    // 어떤 패킷까지 받고 호출해야되는지 확인필요
    if(store.LoginPageState.isPacketLoginAckReceived) NetworkService.send.noticeTitleReq();
})
onUnmounted(()=> { 
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess );
    NetworkService.emitter.off(Emit_NoticeContent, event_NoticeContent);
})

const onRefresh = async () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    //this.refreshing = true;

    let marketPrices = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        
        if (store.MarketState.bDrawMenu[futures]) {
            // // EURO FX 6월물, EURO FX 12월물
            const monthIdx = store.MarketState.szFutureName[futures]?.length - 4;
            const month = store.MarketState.szFutureName[futures]?.substring(monthIdx, monthIdx + 2);
            const yearIdx = store.MarketState.szFutureCode[futures]?.length - 2;
            const year = store.MarketState.szFutureCode[futures]?.substring(yearIdx);
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            let expiryDay = _expiryDate;
            if (futures < Common.G_FUTURES_OVERSEAS) {
                expiryDay = `${_expiryDate.substring(2, 5)}${month}`;
            } else {
                expiryDay = `${year}-${month}`;
            }

            const _marketPrice = {
                종목: futures,
                이름: store.MarketState.szFutureName[futures], //Common.g_szFuturesName[futures],
                코드: store.MarketState.szFutureCode[futures],
                만기일: expiryDay,
                전일가: store.MasterState.dbBPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbBPrice[futures], futures),
                시가: store.MasterState.dbOpenPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbOpenPrice[futures], futures),
                고가: store.MasterState.dbHighPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbHighPrice[futures], futures),
                저가: store.MasterState.dbLowPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbLowPrice[futures], futures),
                현재가: store.MasterState.dbCurPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbCurPrice[futures], futures),
                전일대비기호: store.MasterState.szYDiffSign[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                전일대비: store.MasterState.dbYDiffPrice[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                등락률: store.MasterState.dbChgRate[futures], //`${store.MasterState.dbChgRate[futures]?.toFixed(2)}%`,
                거래량: store.MasterState.totq[futures],
            };
            
            marketPrices.push(_marketPrice);
        }
    }
    state.marketPrices = marketPrices;
    state.refreshCount++;

    //this.refreshing = false;

}

const event_LoginSuccess = (params: any) => {

    if(store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        NetworkService.send.noticeTitleReq();
    }
}

const event_NoticeContent = (params: any) => {
    // 공지 내용 받음
    const { notice } = params;

    // 오늘하루 안보기 설정
    const today = moment(Date.now()).format('YYYY-MM-DD');
    //const bVisible = store.NoticeState.popupIdx === notice.iNoticeIdx && options.watchingToday !== today;
    const hideDate1 = store.getHideToday1();
    const bShowToday = ! (hideDate1 && hideDate1 === today);
    const bVisible = store.NoticeState.popupIdx === notice.iNoticeIdx && bShowToday;
    if (bVisible) {
        state.popupAnnounce = notice;
        const rtfText = iconv.decode(notice.contentBuff, NetConstants.NETWORK_STRING_ENCODE);
        const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
        
        // 코드는 Notice 에서 주워옴
        const div = document.createElement("div");
        doc
        .render()
        .then((htmlElements) => {
            div.append(...htmlElements);
            state.initNoticeContent = div.outerHTML;
            state.initShowNotice = true;
            document.body.style.overflow = "hidden";
            state.initNoticeTitle = notice.szTitle;
            
        })
        .catch((error) => {
            if (store.debugLog >= Constant.DebugLevel_Minimal)
                console.error(error);
        }
        );
    }
    else {
        // 중복 팝업 방지
        checkManagerMessage();
    }
}

const stringToArrayBuffer = (string: any) => {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
}

// const onClosePopup = (isHideToday: boolean) => {
//     if(isHideToday === true)
//         store.setHideToday1(moment(Date.now()).format('YYYY-MM-DD'));

//     // 팝업 닫을때 추가 팝업 체크
//     checkManagerMessage();
// }

const checkManagerMessage = () => {
    const bChatPopup = store.AccountState.bChatPopup;
    if (bChatPopup) {
        let isShow = true;
        const now = new Date().getTime();
        const hideDate2 = store.getHideToday2();
        if(hideDate2) {
            // 10초 무시
            isShow = (Number(hideDate2) + 10000) < now;
        }

        if(isShow) {
            // 2023-09-22: 자동으로 상담페이지로 이동하게 변경
            const szMsg = '관리자가 메세지를 남겼습니다.\n\n[상담센터]에서 메세지를 확인하시겠습니까?';
            store.setVisibleDialog({ message: szMsg, title:'', callback: () => { router.push('/HelpDesk'); }})
        }
        store.AccountState.bChatPopup = false;
    }
}

const close = (popupType: string) => {
    switch (popupType) {
        case "PopupNotice":
            state.initShowNotice = false;
            document.body.style.overflow = "auto";
            state.initNoticeContent = "";
            state.initNoticeTitle = "";

            // 팝업 닫을때 추가 팝업 체크
            checkManagerMessage();
        break;
    }
};

const noToday = () => {
    const today = moment(Date.now()).format('YYYY-MM-DD');
    store.setHideToday1(today);

    state.noToday = true;
    state.initShowNotice = false;
    document.body.style.overflow = "auto";
    state.initNoticeContent = "";
    state.initNoticeTitle = "";

    // 팝업 닫을때 추가 팝업 체크
    checkManagerMessage();
}

</script>
