<template>
    <div class="popupSet" v-if="props.isShowPopup">
        <div class="wrap changePopup">
            <div class="header">
                <img class="back-btn" src="../../assets/images/header-back.png" alt="" :class="{ 'on': props.type === 'left' }" @click="close">
                <p >접속 비밀번호 변경</p>
            </div>
            <div class="setPassword innerHeight Setting changePassword ">
                <div class="menu-box">
                    <p class="title">접속 비밀번호 변경</p>
                </div>
                <div class="save-box">
                    <div class="input-box">
                        <div class="left">
                            <p class="text">현재 비밀번호</p>
                        </div>
                        <div class="right">
                            <input type="password" placeholder="비밀번호를 입력해주세요" v-model="state.prior">
                        </div>
                    </div>
                    <div class="input-box mt10">
                        <div class="left">
                            <p class="text">신규 접속비밀번호</p>
                        </div>
                        <div class="right">
                            <input type="password" placeholder="변경할 비밀번호를 입력해주세요" v-model="state.new1">
                        </div>
                    </div>
                    <div class="input-box mt10">
                        <div class="left">
                            <p class="text">접속비밀번호 확인</p>
                        </div>
                        <div class="right">
                            <input type="password" placeholder="변경할 비밀번호를 다시 입력해주세요" v-model="state.new2">
                        </div>
                    </div>
                </div>
                <p class="caution">*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.</p>
                <p class="caution">*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.</p>
                <p class="caution">*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능  이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.</p>
                <div class="btn-box">
                    <button class="cancel" @click="close">
                        <span class="text">취소</span>
                    </button>
                    <button class="ok" @click="ok">
                        <span class="text">비밀번호 변경</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
import { NetworkService, Emit_LoginPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
});


let state = reactive({
    prior: '',
    new1: '',
    new2: '',
})

onMounted(() => { NetworkService.emitter.on(Emit_LoginPasswordUpdate, event_LoginPasswordResult ); });
onUnmounted(()=> { NetworkService.emitter.off(Emit_LoginPasswordUpdate, event_LoginPasswordResult ); })
const event_LoginPasswordResult = (params: any) => {
    const { nResult, szMsg } = params;
    if(nResult=== NetConstants.R_CHNGPWD_SUCCESS) {
        close();
    }
    else {
        // PacketService 에서 처리중
    }
};

const emit = defineEmits(["close", "ok"]);
const close = () => { 
    state.prior = '';
    state.new1 = '';
    state.new2 = '';
    emit("close"); 
};
const ok = () => {
    let errorMessage = '';
    if( ! state.prior ||  ! state.new1 ||  ! state.new2 ) {
        errorMessage = '비밀번호를 입력해주세요.';
    }
    else {
        if(store.SettingsState.signinPwd != state.prior) {
            errorMessage = '접속 비밀번호가 일치하지 않습니다.';
        }
        else if(state.new1 != state.new2) {
            errorMessage = '변경할 비밀번호가 일치하지 않습니다.';
        }
        //else if (!(/^[A-Za-z0-9]{6,8}$/.test(state.new1))) {
        //    errorMessage = '계좌 비밀번호는 영문, 숫자 6~8자리입니다.';
        //}
        else if (store.SettingsState.signinPwd === state.new1) {
            errorMessage = '기존 접속 비밀번호와 같습니다.\n다른 비밀번호를 입력해주세요.';
        }
    }

    if(errorMessage) {
        store.setVisibleDialog({ message: errorMessage });
    }
    else {
        NetworkService.send.passwordWriteReq({ Pwd: state.prior, NewPwd: state.new1 });
    }
};
</script>
