import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

//Pc 종합시세
import PcTotalOrder from "@/views/PC/PcTotalOrder.vue";

//Pc 입/출금
import PcTransactionForm from "@/views/PC/PcTransactionForm.vue";

//Pc 거래내역
import PcTradeList from "@/views/PC/PcTradeList.vue";

//Pc 오버나잇
import PcOverNight from "@/views/PC/PcOverNight.vue";

//Pc 고객센터
import PcHelpDesk from "@/views/PC/PcHelpDesk.vue";

// 
import Index from "@/views/index.vue";

// 로그인 관련
import Login from "@/views/Login.vue";

// 주문 관련
import TotalOrder from "@/views/TotalOrder.vue"

// 거래 내역
import TradeList from "@/views/TradeList.vue"

// 입/출금
import TransactionForm from "@/views/TransactionForm.vue"

// 상담센터
import HelpDesk from "@/views/HelpDesk.vue"

// 오버나잇
import OverNight from "@/views/OverNight.vue"

// 공지사항
import Notice from "@/views/Notice.vue"

// 설정
import Setting from "@/views/Setting.vue"


import { addListener, launch } from 'devtools-detector';
const view = document.createElement('div');
document.body.appendChild(view);


const isMobileTestOnly = window.location.protocol.toLowerCase().startsWith('https:');
if(isMobileTestOnly) {
// 1. add listener
addListener(
isOpen => {
view.innerText = isOpen ? 'devtools status: open' : 'devtools status: close';
let target = window.location.origin;
if( ! target.endsWith('/'))
target += "/";
target += "error.html";
location.href= target;
}
);
// 2. launch detect
launch();
}

const appName = process.env.VUE_APP_TITLE?.toString();


const routes: Array<RouteRecordRaw> = [
    
    //Mobile
    { path: "/", name: "Login", component: Login }, //
    { path: "/m/Home", name: "Home", component: Index }, // 홈 페이지
    // { path: "/Login", name: "Login", component: Login }, // 로그인 페이지
    { path: "/m/TotalOrder", name: "TotalOrder", component: TotalOrder }, // 통합 주문 페이지
    { path: "/m/TradeList", name: "TradeList", component: TradeList }, // 거래 내역
    { path: "/m/TransactionForm", name: "TransactionForm", component: TransactionForm }, // 입/출금
    { path: "/m/HelpDesk", name: "HelpDesk", component: HelpDesk }, // 상담센터
    { path: "/m/OverNight", name: "OverNight", component: OverNight }, // 오버나잇
    { path: "/m/Notice", name: "Notice", component: Notice }, // 공지사항
    { path: "/m/Setting", name: "Setting", component: Setting }, // 설정

    //PC 
    { path: "/TotalOrder", name: "PcIndex", component: PcTotalOrder }, // PCHome
    { path: "/TransactionForm", name: "PcTransactionForm", component: PcTransactionForm }, // PC입출금
    { path: "/TradeList", name: "PcTradeList", component: PcTradeList }, // PC거래내역
    { path: "/OverNight", name: "PcOverNight", component: PcOverNight }, // PC오버나잇
    { path: "/HelpDesk", name: "PcHelpDesk", component: PcHelpDesk }, // PC고객센터
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to, from) => {
document.title = appName === undefined || appName === null ? '' : appName;
});

export default router;
