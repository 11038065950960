<template>
    <div class="popup confirm" v-if="props.isShowPopup"  >
        <div class="popup-inner">
            <p v-html="props.initPopupMessage1" class="confirm-text"></p>
            <p v-html="props.initPopupMessage2" v-if="props.popupType === 1" :class="{ blue: route.name === 'TransactionForm' }"></p>
            <p v-html="props.initPopupMessage3" v-if="props.popupType === 2"></p>
            <div class="btn-box">
                <button @click="close" :class="{ suc: route.name === 'TransactionForm' && props.popupType === 1 }">확인</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
import * as Constant from "../../../src/constants";
const { route, router, defineEmits, defineProps, onMounted, reactive,store} = useBaseComponent();

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initPopupMessage1: {
        type: String,
        default: "",
    },
    initPopupMessage2: {
        type: String,
        default: "",
    },
    initPopupMessage3: {
        type: String,
        default: "",
    },
    popupType: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(["close"]);

const close = () => {
    emit("close");
};
</script>
