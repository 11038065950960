<template>
    <div class="popupPc"  v-if="props.isShowPopup">
        <div class="popupPc-inner">
            <div class="popupSet">
                <div class="changePopup">
                    <div class="setPassword  Setting changePassword ">
                        <div class="title-box flex-center-center">
                            <p class="text ">계좌 비밀번호 입력</p>
                        </div>
                        <div class="top-box">
                            <div class="bank-account">
                                <div class="dropdown-sort">
                                    <div class="select-sort">
                                        <input class="drop-sort" type="checkbox" id="accountInfo" name="accountInfo" v-model="state.pickAccOpen" />
                                        <label for="accountInfo" class="for-dropsort pageCount">
                                            <p class="out">{{ state.pickAccDesc }}<i class="icon-down" :class="[{ on: state.pickAccOpen }]" /></p>
                                        </label>
                                        <div class="section-sort" v-if="state.pickAccValue">
                                            <p v-for="(item, index) in state.pickAccItems" :key="index" @click="onPickAccChangeValue(item)" class="text" :class="{ active: item.value === state.pickAccValue }">
                                                {{ item.desc }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="bank-password">
                            <input type="password" v-model="state.password" />
                        </div>
                    </div>
                    <div class="btn-box">
                        <button class="cancel" @click="close">
                            <span class="text">취소</span>
                        </button>
                        <button class="ok" @click="checkAccPwd">
                            <span class="text">확인</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive, watch } = useBaseComponent();
import { NetworkService, Emit_Success } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';
import * as AccPwd from '../../constants/AccPwd';

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initFutures: {
        type: Number,
        default: Common.G_FUTURES_DAY
    },
    initBAccPAssed: {
        type: Boolean,
        default: false,
    },
});

let state = reactive({
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    futures: Common.G_FUTURES_DAY,

    isLoadedPopup: false,
})

watch(props, ({isShowPopup, initFutures, initBAccPAssed}) => {
    
    //팝업 오픈 액션이 발생하면
    if(isShowPopup){

        //이미 패스워드 인증이 완료된 상태인지 체크
        if(initBAccPAssed){
            //이미 패스워드 인증된 상태라면 성공 응답을 보내고 팝업을 띄우지 않음
            checkAccPwdSuccess();
        } else {
            //패스워드 인증이되지 않은 상태라면
            state.futures = props.initFutures;
            state.bAccPassed = props.initBAccPAssed;
            //계좌 정보 세팅(드랍다운) 후 팝업 표시
            initAccPicker();
        }
    }
},{ deep: true });

// onMounted(() => {
//  });

// onUnmounted(()=> {
// })

// 계좌 선택 세팅
const initAccPicker = () => {
    const pickItems = [];
    // const futures = store.FuturesByNow();
    const futures = props.initFutures;
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
        // 비밀번호 인증 패스 여부
        state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {            
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }
        
        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    checkAccPwdSuccess();
}

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const emit = defineEmits(["close", "checkAccPwdSuccess"]);

const checkAccPwdSuccess = () => {
    emit("checkAccPwdSuccess");
}

const close = () => { 
    state.password = '';
    emit("close"); 
};

</script>
