import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/images/icon-close.png'
import _imports_1 from '../../assets/images/url-kakao.png'
import _imports_2 from '../../assets/images/url-tel.png'


const _hoisted_1 = { class: "popupPc-inner" }
const _hoisted_2 = { class: "main-title" }
const _hoisted_3 = { class: "img-box flex-center" }
const _hoisted_4 = {
  key: 0,
  class: "btn-box notice"
}

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PcPopupHelpDesk',
  props: {
    initShowNotice: {
        type: Boolean,
        default: false,
    },
    initTitle: {
        type: String,
        default: "",
    },
	viewType: {
        type: String,
        default: "",
    },
},
  emits: ["close","noToday"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits,store, defineProps, onMounted, reactive,} = useBaseComponent();

const props = __props;

const newUrl = (type:string) => { 
    if(type === 'kakao'){
        window.open(store.BrandState.szKakaoUrl, '_blank');
    } else if (type === 'telegram') {
        window.open(store.BrandState.szTelegramUrl, '_blank');
    } else {
        // 
    }
}

const emit = __emit;

const noToday = () => {
    emit("noToday")
}

const close = () => {
    emit("close");
};

return (_ctx: any,_cache: any) => {
  return (props.initShowNotice)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["popupPc notice popupHelp", {'indexNotice': props.viewType !== 'Detail' }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", { class: "close-box" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              onClick: close
            })
          ]),
          _createElementVNode("p", _hoisted_2, _toDisplayString(props.initTitle), 1),
          _createElementVNode("div", _hoisted_3, [
            (_unref(store).BrandState.szKakaoUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "url-box Kakao",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (newUrl('kakao')))
                }, _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    src: _imports_1,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", { class: "text" }, "Kakao talk", -1)
                ])))
              : _createCommentVNode("", true),
            (_unref(store).BrandState.szTelegramUrl)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "url-box Telegram",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (newUrl('telegram')))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("img", {
                    src: _imports_2,
                    alt: ""
                  }, null, -1),
                  _createElementVNode("p", { class: "text" }, "Telegram", -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          _createTextVNode(" " + _toDisplayString(props.viewType) + " ", 1),
          (props.viewType !== 'Detail' )
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "ok",
                  onClick: close
                }, "닫기")
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})