<template>
	<div class="wrapPC">
		<!-- /헤더/ -->
		<PcHeader />
		<!-- /몸통/ -->
		<div class="body-box subPage transactionForm tradeList ">
			<div class="con-box">
				<div class="left">
                    <div class="designBox">
                        <div class="flex-center-between border-box">
                            <ul class="select-tab bdbn m-0">
                                <li class="tab-item title" >거래내역</li>
                            </ul>
                            <div class="mid flex-center-between account">
                                <div class="big-box ">
                                    <div class="top flex-center-between m-0">
                                        <div class="bank-account">
                                            <div class="dropdown-sort">
                                                <div class="select-sort">
                                                    <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickAccOpen" />
                                                    <label for="dropPageCount" class="for-dropsort pageCount">
                                                        <p class="out"> {{ state.pickAccDesc }}<i class="icon-down" :class="[{ on: state.pickAccOpen }]" /> </p>
                                                    </label>
                                                    <div class="section-sort">
                                                        <p v-for="(item, index) in state.pickAccItems" :key="index" @click="onPickAccChangeValue(item)" class="text" :class="{ active: item.value === state.pickAccValue }" > {{ item.desc }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="bank-password">
                                            <input type="password" placeholder="계좌비밀번호" v-model="state.password" :readonly="state.bAccPassed"/>
                                        </div> -->
                                        <button class="look-up" @click="checkAccPwd()">조회</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mid info-box">
                            <div class="table-box" >
                                <div class="table-head flex-center">
                                    <p class="item title bdrWhite">국내예탁금</p>
                                    <p class="item title bdrWhite">국내평가예탁금</p>
                                    <p class="item title bdrWhite">해외예탁금</p>
                                    <p class="item title">해외평가예탁금</p>
                                </div>
                                <div class="table-body flex-center bdbn">
                                    <p class="item title bdrGray">{{ toLocaleString(state.depoAmount) }}</p>
                                    <p class="item title bdrGray">{{ toLocaleString(state.estimatedAmount) }}</p>
                                    <p class="item title bdrGray">{{ toLocaleString(state.depoAmountOversea) }}</p>
                                    <p class="item title">{{ toLocaleString(state.estimatedAmountOversea) }}</p>
                                </div>
                            </div>
                            <div class="table-box">
                                <div class="table-head flex-center">
                                    <p class="item title bdrWhite">총평가손익</p>
                                    <p class="item title bdrWhite">국내실현손익</p>
                                    <p class="item title">해외실현손익</p>
                                </div>
                                <div class="table-body flex-center">
                                    <p class="item title bdrGray" :class="[{ up: state.totalProfit > 0}, {down: state.totalProfit < 0}]">{{ toLocaleString(state.totalProfit) }}</p>
                                    <p class="item title bdrGray" :class="[{ up: state.netDomestic > 0}, {down: state.netDomestic < 0}]">{{ toLocaleString(state.netDomestic) }}</p>
                                    <p class="item title" :class="[{ up: state.netOversea > 0}, {down: state.netOversea < 0}]">{{ toLocaleString(state.netOversea) }}</p>
                                </div>
                            </div>
                        </div>
                        <PcTradeTable :initBAccPAssed="state.bAccPassed" @reqCheckAccPassword="reqCheckAccPassword" />
                    </div>
				</div>
				<PcStockList />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route,router, store, onMounted, onUnmounted, reactive } = useBaseComponent();

import * as Common from '../../constants/Common';

import { Theme } from '../../constants';
import { NetworkService, Emit_DrawTrans, Emit_TradingLog, Emit_StopLossLog, Emit_Success } from '../../network';
import { toLocaleString } from '../../utils/number';
import { UserLog } from '../../network/UserLog';
import * as Utils from '../../utils/Common';
import moment from '../../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../../constants/AccPwd';

const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;

let state = reactive({
	
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결", value: Order_Contract},
        {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_Contract,
    selectedItem: null as any,

    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,
});

onMounted(()=>{
    
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TradeList');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) {
        initData();
    }
});

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    initAccPicker();

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const reqCheckAccPassword = () => {
    checkAccPwd();
}
</script>
