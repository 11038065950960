//
// 패킷을 정의한다.
//
export const PacketID = {
	PROTOCOL_NO: 0,

	PROTOCOL_KEEPALIVE: 1,			// 클라 <-> 서버 : 클라이언트가 살아있나?

	PROTOCOL_BRANDINFO: 2,			// 서버 -> 브로커 : 브랜드 정보를 보낸다.
	PROTOCOL_ADMIN_CMMD: 3,			// 브로커 <-> 서버 : admin 패킷을 전달한다.
	PROTOCOL_FUT_MASTER: 4,			// 브로커 -> 서버 : 종목코드를 보낸다

	PROTOCOL_FUT_t2101: 5,			// 브로커 -> 서버 : 주간 선물 현재가를 보낸다
	PROTOCOL_FUT_FC0: 6,				// 브로커 -> 서버 : 주간 선물 체결을 보낸다
	PROTOCOL_FUT_FH0: 7,				// 브로커 -> 서버 : 주간 선물 호가를 보낸다
	PROTOCOL_FUT_t2801: 8,			// 브로커 -> 서버 : 야간 선물 현재가를 보낸다
	PROTOCOL_FUT_NC0: 9,				// 브로커 -> 서버 : 야간 선물 체결을 보낸다
	PROTOCOL_FUT_NH0: 10,				// 브로커 -> 서버 : 야간 선물 호가를 보낸다
	PROTOCOL_FUT_OVC: 11,				// 브로커 -> 서버 : 유로 선물 체결을 보낸다
	PROTOCOL_FUT_OVH: 12,				// 브로커 -> 서버 : 유로 선물 호가를 보낸다

	PROTOCOL_FUT_FDC: 13,	// 브로커 -> 서버 : 주간선물 일봉
	PROTOCOL_FUT_FMC: 14,	// 브로커 -> 서버 : 주간선물 분봉
	PROTOCOL_FUT_FTC: 15,	// 브로커 -> 서버 : 주간선물 틱봉
	PROTOCOL_FUT_OVDC: 16,	// 브로커 -> 서버 : 해외선물 일봉
	PROTOCOL_FUT_OVMC: 17,	// 브로커 -> 서버 : 해외선물 분봉
	PROTOCOL_FUT_OVTC: 18,	// 브로커 -> 서버 : 해외선물 틱봉

	PROTOCOL_EXCHAGE_RATE: 19,		// 서버 -> 브로커 : 환율 정보를 보낸다.
	PROTOCOL_INDEX_DATA: 20,		// 서버 -> 브로커 : 인덱스 정보를 보낸다.

	PROTOCOL_SIGNUP: 21,				// 클라 <-> 서버 : 회원가입 요청 / 응답
	PROTOCOL_CHECK_AGENT: 22,			// 클라 <-> 서버 : 에이전트 확인 / 응답

	PROTOCOL_REALDATASESSION: 23,		// 클라 -> 서버 : 받고자하는 실시간 호가를 보낸다.
	PROTOCOL_CONTRACT_PRICE: 24,		// 서버 -> 클라 : 체결가를 보낸다.
	PROTOCOL_CALL_PRICE: 25,			// 서버 -> 클라 : 호가를 보낸다.

	PROTOCOL_ACCINFO: 26,				//브로커 -> 서버 : 계좌정보를 서버에 보낸다.
	PROTOCOL_ACCINFO_DEPOSITS: 27,		//브로커 <-> 서버 : 계좌 증거금정보를 서버에 보낸다.

	PROTOCOL_REAL_ORDER: 28,			// 실 주문을 요청
	PROTOCOL_REAL_MODIFY_ORDER: 29,		// 실 주문 수정 요청
	PROTOCOL_REAL_CANCEL_ORDER: 30,		// 실 주문 취소 요청

	PROTOCOL_BANKACCOUNTS_INFO: 31,		// 은행 정보를 보낸다.

	PROTOCOL_KOSPI200_REQ: 32,			//브로커 <- 서버 : 만기결재를 위해 코스피 요청
	PROTOCOL_KOSPI200_ANS: 33,			//브로커 -> 서버 : 만기결재를 위해 코스피를 보낸다.

	PROTOCOL_FUT_EXPIRE: 64,

	PROTOCOL_USER_BASE: 65,

	PROTOCOL_USER_LOGIN_REQ: 66,			PROTOCOL_USER_LOGIN_ACK: 67,  // "LREQ", "LANS"
	PROTOCOL_USER_INFO_ACK: 68, // "UINF"
	PROTOCOL_USER_TIME_ACK: 69, // "TIME"

	PROTOCOL_USER_FUTURESTIME_ACK: 70, // "FMHR"
	PROTOCOL_USER_FUTURESENABLE_ACK: 71, // "EXCH"

	PROTOCOL_USER_FUTURESINFO_REQ: 72, 		PROTOCOL_USER_FUTURESINFO_ACK: 73, // "MIRQ" // "MIAN"

	PROTOCOL_USER_MASTER_REQ: 74,			PROTOCOL_USER_MASTER_ACK: 75, // "MARQ"  ""

	PROTOCOL_USER_ACCOUNT_REQ: 76, 			PROTOCOL_USER_ACCOUNT_ACK: 77, // "ACCR" "ACCA"

	PROTOCOL_USER_LOGOUT_REQ: 78,			PROTOCOL_USER_LOGOUT_ACK: 79, // "LORQ" LOAN

	PROTOCOL_USER_NOTICST_REQ: 80, 			PROTOCOL_USER_NOTICST_ACK: 81, // NoTR NoTA

	PROTOCOL_USER_NOTICSC_REQ: 82, 			PROTOCOL_USER_NOTICSC_ACK: 83, // NoCR NoCA

	PROTOCOL_USER_EVENT_REQ: 84, 			PROTOCOL_USER_EVENT_ACK: 85, // EvtR EvtA

	PROTOCOL_USER_MONEYIN_REQ: 86, 			PROTOCOL_USER_MONEYIN_ACK: 87, // DnWR DnWA

	PROTOCOL_USER_MONEY_ACK: 88, // NwDA

	PROTOCOL_USER_BRANDINFO_ACK: 89, // BrIn

	PROTOCOL_USER_PASSWORD_REQ: 90,			PROTOCOL_USER_PASSWORD_ACK: 91, // PwdR PwdA

	PROTOCOL_USER_LEVERAGE_REQ: 92,			PROTOCOL_USER_LEVERAGE_ACK: 93, // CLeR CLeA

	PROTOCOL_USER_ORDER_REQ: 94, 			PROTOCOL_USER_ORDER_ACK: 95, // OrdR OrdA

	PROTOCOL_USER_MODIFY_REQ: 96, 			PROTOCOL_USER_MODIFY_ACK: 97, // OrMR OrMA

	PROTOCOL_USER_ORDERLIST_REQ: 98, 		PROTOCOL_USER_ORDERLIST_ACK: 99, // OrLR OrdL

	PROTOCOL_USER_PRIORITY_ACK: 100, // OrPr

	PROTOCOL_USER_CONTRACT_ACK: 101, // Cntr

	PROTOCOL_USER_CONTRACTLIST_ACK: 102, // CnrL

	PROTOCOL_USER_MEMO_REQ: 103,				PROTOCOL_USER_MEMO_ACK: 104,// MemR MemA

	PROTOCOL_USER_MEMOT_REQ: 105,			PROTOCOL_USER_MEMOT_ACK: 106, // MeTR MeTA

	PROTOCOL_USER_MEMOC_REQ: 107,			PROTOCOL_USER_MEMOC_ACK: 108, // MeCR MeCA

	PROTOCOL_USER_MEMOR_REQ: 109, // MeRR
	PROTOCOL_USER_MEMOTA_ACK: 110, // MeTA 1:1문의 내용 요청결과를 처리한다.

	PROTOCOL_USER_LOSSCUT_ACK: 111, // LoCt

	PROTOCOL_USER_MIT_REQ: 112,				PROTOCOL_USER_MIT_ACK: 113, // MITL MITA

	PROTOCOL_USER_MITM_REQ: 114, // MTMR
	PROTOCOL_USER_MITC_REQ: 115, // MTCR
	PROTOCOL_USER_MITA_ACK: 116, // MT_A

	PROTOCOL_USER_STOPLOSS_REQ: 117, 		PROTOCOL_USER_STOPLOSS_ACK: 118, // StLL StLA

	PROTOCOL_USER_STOPLOSSM_REQ: 119, // StMR
	PROTOCOL_USER_STOPLOSSC_REQ: 120, // StCR
	PROTOCOL_USER_STOPLOSSA_ACK: 121, // St_A


	PROTOCOL_USER_BATCHCANCLE_REQ: 122, 		PROTOCOL_USER_BATCHCANCLE_ACK: 123, // OBtC OBCA

	PROTOCOL_USER_RECOMMAND_REQ: 124, 		PROTOCOL_USER_RECOMMAND_ACK: 125, // Rcmd RcmL

	PROTOCOL_USER_TRADELIST_REQ: 126, 		PROTOCOL_USER_TRADELIST_ACK: 127, // CLR2 CLL2
	PROTOCOL_USER_TLCONTRACT_ACK: 128,		PROTOCOL_USER_TLLEFT_ACK: 129, 	// CLLC, CLLL


	PROTOCOL_USER_PROFITLIST_REQ: 130, 		PROTOCOL_USER_PROFITLIST_ACK: 131, // GnLR GnLL

	PROTOCOL_USER_MITLIST_REQ: 132,			PROTOCOL_USER_MITLIST_ACK: 133, // MtLR MtLA

	PROTOCOL_USER_STOPLOSSLIST_REQ: 134, 	PROTOCOL_USER_STOPLOSSLIST_ACK: 135, // StLR StLN

	PROTOCOL_USER_DEPOSITLIST_REQ: 136,		PROTOCOL_USER_DEPOSITLIST_ACK: 137, // DWLR DWLA

	PROTOCOL_USER_TICKVALUE_ACK: 138, // TicV


	PROTOCOL_USER_PROFITDETAIL_REQ: 139, 	PROTOCOL_USER_PROFITDETAIL_ACK: 140, // DpIR DpIA

	PROTOCOL_USER_LOSSCUTINFO_ACK: 141, // LoRt

	PROTOCOL_USER_QUIT_ACK: 142,											// 강제 종료. "FExt"
	PROTOCOL_CMM_MEMOANSER_ACK: 143,									// 메모 답변 알림. "UMeA"


	PROTOCOL_USER_TRADINGREPORT_REQ: 144,	PROTOCOL_USER_TRADINGREPORT_ACK: 145,  // "DTLR", DTLA

	PROTOCOL_USER_PAUSE_REQ: 146, // "PAUT"
	PROTOCOL_USER_DEBUGLOG_REQ: 147,			// "DBGM"

	PROTOCOL_USER_OVERNIGHT_REQ: 148,			PROTOCOL_USER_OVERNIGHT_ACK: 149,
	PROTOCOL_USER_OVERNIGHTLIST_REQ: 150,		PROTOCOL_USER_OVERNIGHTLIST_ACK: 151, // StLR StLN
	PROTOCOL_USER_NEWACCPWD_REQ: 152,			PROTOCOL_USER_NEWACCPWD_ACK: 153,
	PROTOCOL_USER_ACCPWD_REQ: 154,				PROTOCOL_USER_ACCPWD_ACK: 155,
	PROTOCOL_USER_USERLOG_LIST_REQ: 156,			PROTOCOL_USER_USERLOG_LIST_ACK: 157,

	PROTOCOL_USER_CHAT_REQ: 158,					PROTOCOL_USER_CHAT_ACK: 159,	// 채팅 요청
	PROTOCOL_USER_CHAT_COUNSELOR: 160,		// 유저 채팅 상담자 입장여부
	PROTOCOL_USER_CHAT_MESSAGE_REQ: 161,			PROTOCOL_USER_CHAT_MESSAGE_ACK: 162,	// 채팅 메세지 전송
	PROTOCOL_USER_CHAT_TRANSFER_REQ: 163,		PROTOCOL_USER_CHAT_TRANSFER_ACK: 164,	// 채팅 파일 전송

	PROTOCOL_CMM_POPUP_ACK: 165,				// "PopU"
	PROTOCOL_CMM_NEWNOTIC_ACK: 166,			// "NeNo"

	PROTOCOL_USER_IMAGE_TRANSFER_REQ: 167,		PROTOCOL_USER_IMAGE_TRANSFER_ACK: 168,	// 이미지 전송 요청
	PROTOCOL_USER_SL_RESERVED_REQ: 169, 		PROTOCOL_USER_SL_RESERVED_ACK: 170, // S/L 예약
	PROTOCOL_USER_CHAT_LOG_REQ: 171,                PROTOCOL_USER_CHAT_LOG_ACK: 172,    // 채팅 로그 조회
    PROTOCOL_USER_CHATTIME_REQ: 173,                PROTOCOL_USER_CHATTIME_ACK: 174,    // 채팅 시간

	PROTOCOL_CHART_BASE: 360,
	PROTOCOL_CHART_DATA_REQ: 361,	PROTOCOL_CHART_DATA_ACK: 362,				// 차트 요청

	PROTOCOL_AGENT_LOGIN: 384,
	PROTOCOL_AGENT_DAILY_LIST: 385,
	PROTOCOL_AGENT_DAILY_REPORT: 386,
	PROTOCOL_AGENT_USER_LIST: 387,
	PROTOCOL_AGENT_TRADE: 388,
	PROTOCOL_AGNET_CONTRACTED_S: 389,
	PROTOCOL_AGNET_LEFT_S: 390, 

	PROTOCOL_USER_MARKET_TIME: 448,
	PROTOCOL_USER_SERVICE: 449,
	PROTOCOL_USER_OPTIONS: 450,
	PROTOCOL_USER_LOG: 451,

	PROTOCOL_MAX: 452					// 구분자
};
