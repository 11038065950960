<template>
    <div class="popupSet" v-if="props.isShowPopup">
        <div class="wrap changePopup">
            <div class="header">
                <img class="back-btn" src="../../assets/images/header-back.png" alt="" :class="{ 'on': props.type === 'left' }" @click="close">
                <p >레버리지 설정</p>
            </div>
            <div class="setPassword innerHeight Setting changePassword ">
                <div class="leverage">
                <div class="text-box">
                    <p class="text">레버리지 변경 신청시 미체결 주문 또는 미결제 약정이 없어야 합니다.</p>
                </div>
                <div class="table-box">
                    <div class="table-head">
                        <p class="table-item title">현재 계좌 기본정보</p>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">현재 레버리지</p>
                            <p class="table-item value">1 : {{ state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro }}</p>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">최대 주문 계약수<br/>(해외 / 국내)</p>
                            <p class="table-item value">{{ getMaxContract(Common.eNation_Oversea, store.AccountState.iLeverageEuro) }} / {{ getMaxContract(Common.eNation_Domestic, store.AccountState.iLeverage) }}</p>
                        </div>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">해외 보유잔고</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmountOversea) }}</p>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">해외 평가금액</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmountOversea * store.AccountState.iLeverageEuro) }}</p>
                        </div>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">국내 보유잔고</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmount) }}</p>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">국내 평가금액</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmount * store.AccountState.iLeverage) }}</p>
                        </div>
                    </div>
                </div>
                <div class="table-box">
                    <div class="table-head">
                        <p class="table-item title">운영 레버리지 변경</p>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">변경 레버리지</p>
                            <div class="table-item value dropPosition">
                                <div class="drop-leverage">
                                    <input class="drop-sort" type="checkbox" id="dropLeverage" name="dropLeverage" v-model="state.pickOpenLeverage" />
                                    <label for="dropLeverage" class="for-dropsort pageCount">
                                        <p class="out">{{ state.pickDescLeverage }}</p>
                                        <i class="icon-down" :class="[{ on: state.pickOpenLeverage }]" />
                                    </label>
                                    <div class="section-sort" v-if="state.pickOpenLeverage">
                                        <p v-for="(item, index) in store.BrandState.leverages" :key="index" @click="onPickChangeLeverage(item)" class="text" >
                                            1 : {{ item }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">최대 주문 계약수<br/>(해외 / 국내)</p>
                            <p class="table-item value">{{ getMaxContract(Common.eNation_Oversea, Number(state.pickValueLeverage)) }} / {{ getMaxContract(Common.eNation_Domestic, Number(state.pickValueLeverage)) }}</p>
                        </div>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">해외 보유잔고</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmountOversea) }}</p>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">해외 평가금액</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmountOversea * Number(state.pickValueLeverage)) }}</p>
                        </div>
                    </div>
                    <div class="table-body"> 
                        <div class="inner-item">
                            <p class="table-item category">국내 보유잔고</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmount) }}</p>
                        </div>
                        <div class="inner-item">
                            <p class="table-item category">국내 평가금액</p>
                            <p class="table-item value">{{ toLocaleString(state.depoAmount * Number(state.pickValueLeverage)) }}</p>
                        </div>
                    </div>
                </div>
                <div class="btn-box">
                    <button class="cancel" @click="close">
						<span class="text">닫기</span>
					</button>
					<button class="ok" @click="onActionChangeLeverage()">
						<span class="text">적용</span>
					</button>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
import * as Common from '@/constants/Common';
import { toLocaleString } from '@/utils/number';
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive,watch } = useBaseComponent();
import { Emit_LoginPasswordUpdate } from "@/network"
import { NetworkService, Emit_DrawTrans, Emit_UserOption, Emit_ResetAmount, XingAPIService, Emit_ContractData, Emit_CallData, Emit_Success } from '@/network';
import * as NetConstants from '../../network/Constants';
import { UserLog } from '../../network/UserLog';
import { ref } from 'vue';



// 화면 갱신
const REFRESH_INTERVAL = 300;  //100;

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
});


let state = reactive({
    futures: Common.G_FUTURES_DAY,
    isOversea: Common.eNation_Domestic,

    pickOpenLeverage: false,
    pickDescLeverage: '',
    pickValueLeverage: '',

    depoAmount: 0,				// 국내예탁금액
    depoAmountOversea: 0,		// 해외예탁금액
    estimatedAmount: 0,			// 국내평가예탁금액
    estimatedAmountOversea: 0,	// 해외평가예탁금액
    totalProfit: 0,				// 총평가손익
    netDomestic: 0,				// 국내실현손익
    netOversea: 0,				// 해외실현손익

    // 계좌 정보 세팅
    timerInitData: null as any,
});

watch(props, (initIsShowPopup) => {
    if (initIsShowPopup) {
        let futures = store.FuturesByNow();
        futures = futures >= 1 ? 2: 0;
        state.futures = futures;
        state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
        
        const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro; 
        onPickChangeLeverage(currLeverage);
    }
},{ deep: true });

onMounted(()=>{
    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    if (store.LoginPageState.isPacketLoginAckReceived) {
        initData();
    }
})

onUnmounted(() =>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {
	//로그인 후 계좌정보가 store에 저장됐는지
	if(!store.LoginPageState.isPacketAccInfoReceived){
		state.timerInitData = setTimeout(() => {
			initData();
		}, 100)
		return;
	}

    let futures = store.FuturesByNow();
    futures = futures >= 1 ? 2: 0;
    state.futures = futures;
    state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    
    const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro; 
    onPickChangeLeverage(currLeverage);

	const autoRefreshing = async () => {
		// 예탁금, 평가예탁금, 평가손익
		calcuAmount();
		setTimeout(autoRefreshing, REFRESH_INTERVAL);
	};

	autoRefreshing();
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const getMaxContract = (isOversea: number, leverage: number) => {
    // 보유 레버리지 평가금
    const estimatedDepo = isOversea === Common.eNation_Domestic ? state.depoAmount * leverage : state.depoAmountOversea * leverage; 
    // 선물 가격
    const futuresPrice = isOversea === Common.eNation_Domestic ? store.BrandState.iDomesticFuturePrice : store.BrandState.iOverseaFuturePrice;
    // 마켓 최대 계약수
    const maxContract = store.MarketState.bIndivMaxContract ? (isOversea === Common.eNation_Domestic ? store.MarketState.iDomesticMaxContract : store.MarketState.iOverseaMaxContract) : store.MarketState.iMaxContract[state.futures];
    // 최대 계약 가능 수
    const possibleMaxContract = Math.floor((estimatedDepo / futuresPrice < maxContract) ? estimatedDepo / futuresPrice : maxContract);

    return possibleMaxContract;
}

const onPickChangeLeverage = (item:any) => {
    state.pickOpenLeverage = false;
    state.pickDescLeverage = "1 : " + item;
    state.pickValueLeverage = item;
}

const onActionChangeLeverage = () => { 
    const currLeverage = state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro;

    // console.log(currLeverage);
    // console.log(state.pickValueLeverage);
    // if(currLeverage === Number(state.pickValueLeverage)){
    //     return;
    // }

    const callback = () => {
        NetworkService.send.newLeverageReq({
            Futures: state.futures, 
            NewLeverage: state.pickValueLeverage,
        });
        close();
    }

    const szMsg = `레버리지를 변경 하시겠습니까?`;
    store.setVisibleDialog({ message: szMsg, title: `레버리지 변경`, okCancel: true, callback });
    
};

const emit = defineEmits(["close"]);

const close = () => { 
    emit("close"); 
};

</script>