import { Buffer } from 'buffer';
import iconv from 'iconv-lite';

import * as NetConstants from './Constants';
import * as Common from '../constants/Common';
import * as ChartConst from '../constants/Chart';
import { NetworkService } from './NetworkService';
import { Packet } from './Packet';
import * as Utils from '../utils/Common';
import { euckr_trim } from '../utils/euckr_trim';
import { NETWORK_STRING_ENCODE } from './Constants';
import moment from '../utils/moment';
import { useStore } from '../pinia'
import Datafeed from "../../public/custom_data/datafeed.js";

export class XingAPIService {
	// 주간선물 틱봉 조회
	//t8414OutBlock1_N	
  // [cTerm_Max][cChartData_Max]
  //static m_t8414OutBlock1 = Utils.array2d(ChartConst.cTerm_Max, ChartConst.cChartData_Max, []);
  static m_t8414OutBlock1 = new Array(ChartConst.cTerm_Max).fill(0).map(v => []) as any;
	static m_t8414OutBlock1Count = new Array(ChartConst.cTerm_Max).fill(0) as any;

	// 주간선물 분봉 조회
	//t8415OutBlock1_N	
  // [cTerm_Max][cChartData_Max]
  static m_t8415OutBlock1 = new Array(ChartConst.cTerm_Max).fill(0).map(v => []) as any;
	static m_t8415OutBlock1Count = new Array(ChartConst.cTerm_Max).fill(0) as any;

	// 주간선물 일봉 조회
	//t8416OutBlock1_N	
  // [3][cChartData_Max]
  static m_t8416OutBlock1 = new Array(3).fill(0).map(v => []) as any;
	static m_t8416OutBlock1Count = new Array(3).fill(0) as any;

	// 해외선물 틱봉 조회
	//o3137OutBlock1_N	
  // [G_FUTURES_OVERSEA_MAX][cTerm_Max][cChartData_Max]
  //static m_o3137OutBlock1 = new Utils.array3d(Common.G_FUTURES_OVERSEA_MAX, ChartConst.cTerm_Max, ChartConst.cChartData_Max, []);
  static m_o3137OutBlock1 = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, ChartConst.cTerm_Max, []) as any;
	static m_o3137OutBlock1Count = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, ChartConst.cTerm_Max, 0) as any;

	// 해외선물 분봉 조회
	//o3123OutBlock1_N	
  static m_o3123OutBlock1 = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, ChartConst.cTerm_Max, []) as any;
	static m_o3123OutBlock1Count = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, ChartConst.cTerm_Max, 0) as any;

	// 해외선물 일봉 조회
	//o3128OutBlock1_N	
  static m_o3128OutBlock1 = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, 3, []) as any;
	static m_o3128OutBlock1Count = Utils.array2d(Common.G_FUTURES_OVERSEA_MAX, 3, 0) as any;

  // 챠트 요청 id
  static _chartReqId = 0;
  static get newChartReqId() {
    return XingAPIService._chartReqId++;
  }

  /*
  // 주간선물 체결
  typedef struct _FC0_OutBlock_N
  {
    char    chetime             [   6];    char    _chetime             ;    // [string,    6] 체결시간                       StartPos 0, Length 6
    char    sign                [   1];    char    _sign                ;    // [string,    1] 전일대비구분                   StartPos 7, Length 1
    DOUBLE    change;    // [float ,  6.2] 전일대비                       StartPos 9, Length 6
    DOUBLE    drate;    // [float ,  6.2] 등락율                         StartPos 16, Length 6
    DOUBLE    price;    // [float ,  6.2] 현재가                         StartPos 23, Length 6
    DOUBLE    open;    // [float ,  6.2] 시가                           StartPos 30, Length 6
    DOUBLE    high;    // [float ,  6.2] 고가                           StartPos 37, Length 6
    DOUBLE    low;    // [float ,  6.2] 저가                           StartPos 44, Length 6
    char    cgubun              [   1];    char    _cgubun              ;    // [string,    1] 체결구분                       StartPos 51, Length 1
    INT    cvolume;    // [long  ,    6] 체결량                         StartPos 53, Length 6
    INT    volume;    // [long  ,   12] 누적거래량                     StartPos 60, Length 12
    INT    value;    // [long  ,   12] 누적거래대금                   StartPos 73, Length 12
    INT    mdvolume;    // [long  ,   12] 매도누적체결량                 StartPos 86, Length 12
    INT    mdchecnt;    // [long  ,    8] 매도누적체결건수               StartPos 99, Length 8
    INT    msvolume;    // [long  ,   12] 매수누적체결량                 StartPos 108, Length 12
    INT    mschecnt;    // [long  ,    8] 매수누적체결건수               StartPos 121, Length 8
    DOUBLE    cpower;    // [float ,  9.2] 체결강도                       StartPos 130, Length 9
    DOUBLE    offerho1;    // [float ,  6.2] 매도호가1                      StartPos 140, Length 6
    DOUBLE    bidho1;    // [float ,  6.2] 매수호가1                      StartPos 147, Length 6
    INT    openyak;    // [long  ,    8] 미결제약정수량                 StartPos 154, Length 8
    DOUBLE    k200jisu;    // [float ,  6.2] KOSPI200지수                   StartPos 163, Length 6
    DOUBLE    theoryprice;    // [float ,  6.2] 이론가                         StartPos 170, Length 6
    DOUBLE    kasis;    // [float ,  6.2] 괴리율                         StartPos 177, Length 6
    DOUBLE    sbasis;    // [float ,  6.2] 시장BASIS                      StartPos 184, Length 6
    DOUBLE    ibasis;    // [float ,  6.2] 이론BASIS                      StartPos 191, Length 6
    INT    openyakcha;    // [long  ,    8] 미결제약정증감                 StartPos 198, Length 8
    char    jgubun              [   2];    char    _jgubun              ;    // [string,    2] 장운영정보                     StartPos 207, Length 2
    //INT    jnilvolume;    // [long  ,   12] 전일동시간대거래량             StartPos 210, Length 12
    char    futcode             [   8];    char    _futcode             ;    // [string,    8] 단축코드                       StartPos 223, Length 8
  } FC0_OutBlock_N, *LPFC0_OutBlock_N;
  */
  static MasterDayO(futCode: any, outBlock:any) {
    const store = useStore();
    
    // 주간 선물 체결 셋팅한다.
    // 체결시간
    const szTime = outBlock.readNString(6);
    store.MasterState.szCTime[futCode] = `${szTime.substring(0, 2)}:${szTime.substring(2, 4)}:${szTime.substring(4, 6)}`;
    store.MasterState.szOrgTime[futCode] = szTime;
    // 전일대비구분
    const szSign = outBlock.readNString(1);
    store.MasterState.szYDiffSign[futCode] = szSign;
    //전일대비
    store.MasterState.dbYDiffPrice[futCode] = outBlock.readDouble();
    //등락율
    store.MasterState.dbChgRate[futCode] = outBlock.readDouble();
    //체결가격(현재가)
    store.MasterState.dbCurPrice[futCode] = outBlock.readDouble();
    // 시가
    store.MasterState.dbOpenPrice[futCode] = outBlock.readDouble();
    // 고가
    store.MasterState.dbHighPrice[futCode] = outBlock.readDouble();
    // 저가
    store.MasterState.dbLowPrice[futCode] = outBlock.readDouble();
    //체결구분
    const szCGubun = outBlock.readNString(1);
    store.MasterState.szCGubun[futCode] = szCGubun;
    // 체결수량
    store.MasterState.iTrdq[futCode] = outBlock.readInt();
    // 누적체결 수량
    store.MasterState.totq[futCode] = outBlock.readInt();
    // 누적거래대금
    store.MasterState.value[futCode] = outBlock.readInt();
    // 매도누적체결량
    const mdvolume = outBlock.readInt();
    // 매도누적체결건수
    const mdchecnt = outBlock.readInt();
    // 매수누적체결량
    const msvolume = outBlock.readInt();
    // 매수누적체결건수
    const mschecnt = outBlock.readInt();
    // 체결강도
    const cpower = outBlock.readDouble();
    // 매도호가1
    const offerho1 = outBlock.readDouble();
    // 매수호가1
    const bidho1 = outBlock.readDouble();
    // 미결제약정수량
    store.MasterState.openyak = outBlock.readInt();
    // KOSPI200지수
    store.MasterState.k200jisu = outBlock.readDouble();
    // 이론가
    const theoryprice = outBlock.readDouble();
    store.MasterState.theoryprice = theoryprice;
    // 괴리율
    const kasis = outBlock.readDouble();
    store.MasterState.kasis = kasis;
    // 시장BASIS
    store.MasterState.sbasis = outBlock.readDouble();
    // 이론BASIS
    const ibasis = outBlock.readDouble();
    // 미결제약정증감
    store.MasterState.openyakcha = outBlock.readInt();

    //기준가
    if(szSign === '4' || szSign === '5') {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] + store.MasterState.dbYDiffPrice[futCode];
    } else {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] - store.MasterState.dbYDiffPrice[futCode];
    }
  }

  static OnRecvMasterDayO(packet: any) {
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 주간 선물 체결 셋팅한다.
    const futCode = Common.G_FUTURES_DAY;
    XingAPIService.MasterDayO(futCode, outBlock);
    
    // 마스터 코드 이면 이전현재가를 저장한다.
    store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    store.MasterState.fixedRow[futCode] = 2; // fixedRow
    const t_hoga1 = parseInt(store.MasterState.dbBPrice[futCode] + (store.MasterState.dbBPrice[futCode] * 0.5));
    store.MasterState.sHoga[futCode] = parseFloat(Utils.PriceFormatStr(t_hoga1, futCode));
  }
  
  /*
  // 주간선물 호가
  typedef struct _FH0_OutBlock_N
  {
    char    hotime              [   6];    char    _hotime              ;    // [string,    6] 호가시간                       StartPos 0, Length 6
    DOUBLE    offerho1;    // [double,  6.2] 매도호가1                      StartPos 7, Length 6
    DOUBLE    bidho1;    // [double,  6.2] 매수호가1                      StartPos 14, Length 6
    INT    offerrem1;    // [long  ,    6] 매도호가수량1                  StartPos 21, Length 6
    INT    bidrem1;    // [long  ,    6] 매수호가수량1                  StartPos 28, Length 6
    INT    offercnt1;    // [long  ,    5] 매도호가건수1                  StartPos 35, Length 5
    INT    bidcnt1;    // [long  ,    5] 매수호가건수1                  StartPos 41, Length 5
    DOUBLE    offerho2;    // [double,  6.2] 매도호가2                      StartPos 47, Length 6
    DOUBLE    bidho2;    // [double,  6.2] 매수호가2                      StartPos 54, Length 6
    INT    offerrem2;    // [long  ,    6] 매도호가수량2                  StartPos 61, Length 6
    INT    bidrem2;    // [long  ,    6] 매수호가수량2                  StartPos 68, Length 6
    INT    offercnt2;    // [long  ,    5] 매도호가건수2                  StartPos 75, Length 5
    INT    bidcnt2;    // [long  ,    5] 매수호가건수2                  StartPos 81, Length 5
    DOUBLE    offerho3;    // [double,  6.2] 매도호가3                      StartPos 87, Length 6
    DOUBLE    bidho3;    // [double,  6.2] 매수호가3                      StartPos 94, Length 6
    INT    offerrem3;    // [long  ,    6] 매도호가수량3                  StartPos 101, Length 6
    INT    bidrem3;    // [long  ,    6] 매수호가수량3                  StartPos 108, Length 6
    INT    offercnt3;    // [long  ,    5] 매도호가건수3                  StartPos 115, Length 5
    INT    bidcnt3;    // [long  ,    5] 매수호가건수3                  StartPos 121, Length 5
    DOUBLE    offerho4;    // [double,  6.2] 매도호가4                      StartPos 127, Length 6
    DOUBLE    bidho4;    // [double,  6.2] 매수호가4                      StartPos 134, Length 6
    INT    offerrem4;    // [long  ,    6] 매도호가수량4                  StartPos 141, Length 6
    INT    bidrem4;    // [long  ,    6] 매수호가수량4                  StartPos 148, Length 6
    INT    offercnt4;    // [long  ,    5] 매도호가건수4                  StartPos 155, Length 5
    INT    bidcnt4;    // [long  ,    5] 매수호가건수4                  StartPos 161, Length 5
    DOUBLE    offerho5;    // [double,  6.2] 매도호가5                      StartPos 167, Length 6
    DOUBLE    bidho5;    // [double,  6.2] 매수호가5                      StartPos 174, Length 6
    INT    offerrem5;    // [long  ,    6] 매도호가수량5                  StartPos 181, Length 6
    INT    bidrem5 ;    // [long  ,    6] 매수호가수량5                  StartPos 188, Length 6
    INT    offercnt5;    // [long  ,    5] 매도호가건수5                  StartPos 195, Length 5
    INT    bidcnt5;    // [long  ,    5] 매수호가건수5                  StartPos 201, Length 5
    INT    totofferrem;    // [long  ,    6] 매도호가총수량                 StartPos 207, Length 6
    INT    totbidrem;    // [long  ,    6] 매수호가총수량                 StartPos 214, Length 6
    INT    totoffercnt;    // [long  ,    5] 매도호가총건수                 StartPos 221, Length 5
    INT    totbidcnt;    // [long  ,    5] 매수호가총건수                 StartPos 227, Length 5
    char    futcode             [   8];    char    _futcode             ;    // [string,    8] 단축코드                       StartPos 233, Length 8
    //char    danhochk            [   1];    char    _danhochk            ;    // [string,    1] 단일가호가여부                 StartPos 242, Length 1
    //char    alloc_gubun         [   1];    char    _alloc_gubun         ;    // [string,    1] 배분적용구분                   StartPos 244, Length 1
  } FH0_OutBlock_N, *LPFH0_OutBlock_N;
  */
  static MasterDayB(futCode: any, outBlock: any) {
    const store = useStore();

    // 주간 선물 호가 셋팅한다.
    const oneTick = Common.G_ONETICK[futCode];
    // 호가시간
    const szTime = outBlock.readNString(6);
    // 매도호가1
    store.MasterState.offerho1[futCode] = outBlock.readDouble();
    // 매수호가1
    store.MasterState.bidho1[futCode] = outBlock.readDouble();
    // 매도호가잔량1
    store.MasterState.offerrem1[futCode] = outBlock.readInt();
    // 매수호가잔량1
    store.MasterState.bidrem1[futCode] = outBlock.readInt();
    // 매도호가건수1
    store.MasterState.offerno1[futCode] = outBlock.readInt();
    // 매수호가건수1
    store.MasterState.bidno1[futCode] = outBlock.readInt();
    // 매도호가2
    store.MasterState.offerho2[futCode] = outBlock.readDouble();
    // 매수호가2
    store.MasterState.bidho2[futCode] = outBlock.readDouble();
    // 매도호가잔량2
    store.MasterState.offerrem2[futCode] = outBlock.readInt();
    // 매수호가잔량2
    store.MasterState.bidrem2[futCode] = outBlock.readInt();
    // 매도호가건수2
    store.MasterState.offerno2[futCode] = outBlock.readInt();
    // 매수호가건수2
    store.MasterState.bidno2[futCode] = outBlock.readInt();
    // 매도호가3
    store.MasterState.offerho3[futCode] = outBlock.readDouble();
    // 매수호가3
    store.MasterState.bidho3[futCode] = outBlock.readDouble();
    // 매도호가잔량3
    store.MasterState.offerrem3[futCode] = outBlock.readInt();
    // 매수호가잔량3
    store.MasterState.bidrem3[futCode] = outBlock.readInt();
    // 매도호가건수3
    store.MasterState.offerno3[futCode] = outBlock.readInt();
    // 매수호가건수3
    store.MasterState.bidno3[futCode] = outBlock.readInt();
    // 매도호가4
    store.MasterState.offerho4[futCode] = outBlock.readDouble();
    // 매수호가4
    store.MasterState.bidho4[futCode] = outBlock.readDouble();
    // 매도호가잔량4
    store.MasterState.offerrem4[futCode] = outBlock.readInt();
    // 매수호가잔량4
    store.MasterState.bidrem4[futCode] = outBlock.readInt();
    // 매도호가건수4
    store.MasterState.offerno4[futCode] = outBlock.readInt();
    // 매수호가건수4
    store.MasterState.bidno4[futCode] = outBlock.readInt();
    // 매도호가5
    store.MasterState.offerho5[futCode] = outBlock.readDouble();
    // 매수호가5
    store.MasterState.bidho5[futCode] = outBlock.readDouble();
    // 매도호가잔량5
    store.MasterState.offerrem5[futCode] = outBlock.readInt();
    // 매수호가잔량5
    store.MasterState.bidrem5[futCode] = outBlock.readInt();
    // 매도호가건수5
    store.MasterState.offerno5[futCode] = outBlock.readInt();
    // 매수호가건수5
    store.MasterState.bidno5[futCode] = outBlock.readInt();

    // 매도호가총수량
    store.MasterState.totofferrem[futCode] = outBlock.readInt();
    // 매수호가총수량
    store.MasterState.totbidrem[futCode] = outBlock.readInt();
    // 매도호가총건수
    store.MasterState.totoffercnt[futCode] = outBlock.readInt();
    // 매수호가총건수
    store.MasterState.totbidcnt[futCode] = outBlock.readInt();
  }

  static OnRecvMasterDayB(packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 주간 선물 호가 셋팅한다.
    const futCode = Common.G_FUTURES_DAY;
    XingAPIService.MasterDayB(futCode, outBlock);
  }
  
  /*
  // 야간선물 체결
  typedef struct _NC0_OutBlock_N
  {
    char    chetime             [   6];    char    _chetime             ;    // [string,    6] 체결시간(24시간)               StartPos 0, Length 6
    char    chetime1            [   6];    char    _chetime1            ;    // [string,    6] 체결시간(36시간)               StartPos 7, Length 6
    char    sign                [   1];    char    _sign                ;    // [string,    1] 전일대비구분                   StartPos 14, Length 1
    DOUBLE    change;    // [float ,  6.2] 전일대비                       StartPos 16, Length 6
    DOUBLE    drate;    // [float ,  6.2] 등락율                         StartPos 23, Length 6
    DOUBLE    price;    // [float ,  6.2] 현재가                         StartPos 30, Length 6
    DOUBLE    open;    // [float ,  6.2] 시가                           StartPos 37, Length 6
    DOUBLE    high;    // [float ,  6.2] 고가                           StartPos 44, Length 6
    DOUBLE    low;    // [float ,  6.2] 저가                           StartPos 51, Length 6
    char    cgubun              [   1];    char    _cgubun              ;    // [string,    1] 체결구분                       StartPos 58, Length 1
    INT    cvolume;    // [long  ,    6] 체결량                         StartPos 60, Length 6
    INT    volume;    // [long  ,   12] 누적거래량                     StartPos 67, Length 12
    INT    value;    // [long  ,   12] 누적거래대금                   StartPos 80, Length 12
    INT    mdvolume;    // [long  ,   12] 매도누적체결량                 StartPos 93, Length 12
    INT    mdchecnt;    // [long  ,    8] 매도누적체결건수               StartPos 106, Length 8
    INT    msvolume;    // [long  ,   12] 매수누적체결량                 StartPos 115, Length 12
    INT    mschecnt;    // [long  ,    8] 매수누적체결건수               StartPos 128, Length 8
    DOUBLE    cpower;    // [float ,  9.2] 체결강도                       StartPos 137, Length 9
    DOUBLE    offerho1;    // [float ,  6.2] 매도호가1                      StartPos 147, Length 6
    DOUBLE    bidho1;    // [float ,  6.2] 매수호가1                      StartPos 154, Length 6
    INT    openyak;    // [long  ,    8] 미결제약정수량                 StartPos 161, Length 8
    DOUBLE    k200jisu;    // [float ,  6.2] KOSPI200지수                   StartPos 170, Length 6
    DOUBLE    theoryprice;    // [float ,  6.2] 이론가                         StartPos 177, Length 6
    DOUBLE    kasis;    // [float ,  6.2] 괴리율                         StartPos 184, Length 6
    DOUBLE    sbasis;    // [float ,  6.2] 시장BASIS                      StartPos 191, Length 6
    DOUBLE    ibasis;    // [float ,  6.2] 이론BASIS                      StartPos 198, Length 6
    INT    openyakcha;    // [long  ,    8] 미결제약정증감                 StartPos 205, Length 8
    char    jgubun              [   2];    char    _jgubun              ;    // [string,    2] 장운영정보                     StartPos 214, Length 2
    //char    jnilvolume          [  12];    char    _jnilvolume          ;    // [long  ,   12] 미사용filler                   StartPos 217, Length 12
    char    futcode             [   8];    char    _futcode             ;    // [string,    8] 단축코드                       StartPos 230, Length 8
  } NC0_OutBlock_N, *LPNC0_OutBlock_N;
  */
  static MasterNightO(futCode: any, outBlock: any) {
    const store = useStore();

    // 야간 선물 체결 셋팅한다.
    // 체결시간(24시간)
    const szTime = outBlock.readNString(6);
    store.MasterState.szCTime[futCode] = `${szTime.substring(0, 2)}:${szTime.substring(2, 4)}:${szTime.substring(4, 6)}`;
    store.MasterState.szOrgTime[futCode] = szTime;
    // 체결시간(36시간)
    const szTime36 = outBlock.readNString(6);
    // 전일대비구분
    const szSign = outBlock.readNString(1);
    store.MasterState.szYDiffSign[futCode] = szSign;
    //전일대비
    store.MasterState.dbYDiffPrice[futCode] = outBlock.readDouble();
    //등락율
    store.MasterState.dbChgRate[futCode] = outBlock.readDouble();
    //체결가격(현재가)
    store.MasterState.dbCurPrice[futCode] = outBlock.readDouble();
    // 시가
    store.MasterState.dbOpenPrice[futCode] = outBlock.readDouble();
    // 고가
    store.MasterState.dbHighPrice[futCode] = outBlock.readDouble();
    // 저가
    store.MasterState.dbLowPrice[futCode] = outBlock.readDouble();
    //체결구분
    const szCGubun = outBlock.readNString(1);
    store.MasterState.szCGubun[futCode] = szCGubun;
    // 체결수량
    store.MasterState.iTrdq[futCode] = outBlock.readInt();
    // 누적체결 수량
    store.MasterState.totq[futCode] = outBlock.readInt();
    // 누적거래대금
    store.MasterState.value[futCode] = outBlock.readInt();
    // 매도누적체결량
    const mdvolume = outBlock.readInt();
    // 매도누적체결건수
    const mdchecnt = outBlock.readInt();
    // 매수누적체결량
    const msvolume = outBlock.readInt();
    // 매수누적체결건수
    const mschecnt = outBlock.readInt();
    // 체결강도
    const cpower = outBlock.readDouble();
    // 매도호가1
    const offerho1 = outBlock.readDouble();
    // 매수호가1
    const bidho1 = outBlock.readDouble();
    // 미결제약정수량
    const openyak = outBlock.readInt();
    // KOSPI200지수
    store.MasterState.k200jisu = outBlock.readDouble();
    // 이론가
    const theoryprice = outBlock.readDouble();
    // 괴리율
    const kasis = outBlock.readDouble();
    // 시장BASIS
    store.MasterState.sbasis = outBlock.readDouble();
    // 이론BASIS
    const ibasis = outBlock.readDouble();
    // 미결제약정증감
    const openyakcha = outBlock.readInt();

    //기준가
    if(szSign === '4' || szSign === '5') {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] + store.MasterState.dbYDiffPrice[futCode];
    } else {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] - store.MasterState.dbYDiffPrice[futCode];
    }
  }

  static OnRecvMasterNightO(packet: any) {
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 야간 선물 체결 셋팅한다.
    const futCode = Common.G_FUTURES_NIGHT;
    XingAPIService.MasterNightO(futCode, outBlock);
  
    // 마스터 코드 이면 이전현재가를 저장한다.
    store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    store.MasterState.fixedRow[futCode] = 2; // fixedRow
    const t_hoga1 = parseInt(store.MasterState.dbBPrice[futCode] + (store.MasterState.dbBPrice[futCode] * 0.5));
    store.MasterState.sHoga[futCode] = parseFloat(Utils.PriceFormatStr(t_hoga1, futCode));
  }
  
  /*
  // 야간선물 호가
  typedef struct _NH0_OutBlock_N
  {
    char    hotime              [   6];    char    _hotime              ;    // [string,    6] 호가시간(24시간)               StartPos 0, Length 6
    char    hotime1             [   6];    char    _hotime1             ;    // [string,    6] 호가시간(36시간)               StartPos 7, Length 6
    DOUBLE    offerho1;    // [double,  6.2] 매도호가1                      StartPos 14, Length 6
    DOUBLE    bidho1;    // [double,  6.2] 매수호가1                      StartPos 21, Length 6
    INT    offerrem1;    // [long  ,    6] 매도호가수량1                  StartPos 28, Length 6
    INT    bidrem1;    // [long  ,    6] 매수호가수량1                  StartPos 35, Length 6
    INT    offercnt1;    // [long  ,    5] 매도호가건수1                  StartPos 42, Length 5
    INT    bidcnt1;    // [long  ,    5] 매수호가건수1                  StartPos 48, Length 5
    DOUBLE    offerho2;    // [double,  6.2] 매도호가2                      StartPos 54, Length 6
    DOUBLE    bidho2;    // [double,  6.2] 매수호가2                      StartPos 61, Length 6
    INT    offerrem2;    // [long  ,    6] 매도호가수량2                  StartPos 68, Length 6
    INT    bidrem2;    // [long  ,    6] 매수호가수량2                  StartPos 75, Length 6
    INT    offercnt2;    // [long  ,    5] 매도호가건수2                  StartPos 82, Length 5
    INT    bidcnt2;    // [long  ,    5] 매수호가건수2                  StartPos 88, Length 5
    DOUBLE    offerho3;    // [double,  6.2] 매도호가3                      StartPos 94, Length 6
    DOUBLE    bidho3;    // [double,  6.2] 매수호가3                      StartPos 101, Length 6
    INT    offerrem3;    // [long  ,    6] 매도호가수량3                  StartPos 108, Length 6
    INT    bidrem3;    // [long  ,    6] 매수호가수량3                  StartPos 115, Length 6
    INT    offercnt3;    // [long  ,    5] 매도호가건수3                  StartPos 122, Length 5
    INT    bidcnt3;    // [long  ,    5] 매수호가건수3                  StartPos 128, Length 5
    DOUBLE    offerho4;    // [double,  6.2] 매도호가4                      StartPos 134, Length 6
    DOUBLE    bidho4;    // [double,  6.2] 매수호가4                      StartPos 141, Length 6
    INT    offerrem4;    // [long  ,    6] 매도호가수량4                  StartPos 148, Length 6
    INT    bidrem4;    // [long  ,    6] 매수호가수량4                  StartPos 155, Length 6
    INT    offercnt4;    // [long  ,    5] 매도호가건수4                  StartPos 162, Length 5
    INT    bidcnt4;    // [long  ,    5] 매수호가건수4                  StartPos 168, Length 5
    DOUBLE    offerho5;    // [double,  6.2] 매도호가5                      StartPos 174, Length 6
    DOUBLE    bidho5;    // [double,  6.2] 매수호가5                      StartPos 181, Length 6
    INT    offerrem5;    // [long  ,    6] 매도호가수량5                  StartPos 188, Length 6
    INT    bidrem5;    // [long  ,    6] 매수호가수량5                  StartPos 195, Length 6
    INT    offercnt5;    // [long  ,    5] 매도호가건수5                  StartPos 202, Length 5
    INT    bidcnt5;    // [long  ,    5] 매수호가건수5                  StartPos 208, Length 5
    INT    totofferrem;    // [long  ,    6] 매도호가총수량                 StartPos 214, Length 6
    INT    totbidrem;    // [long  ,    6] 매수호가총수량                 StartPos 221, Length 6
    INT    totoffercnt;    // [long  ,    5] 매도호가총건수                 StartPos 228, Length 5
    INT    totbidcnt;    // [long  ,    5] 매수호가총건수                 StartPos 234, Length 5
    char    futcode             [   8];    char    _futcode             ;    // [string,    8] 단축코드                       StartPos 240, Length 8
  } NH0_OutBlock_N, *LPNH0_OutBlock_N;
  */
  static MasterNightB(futCode: any, outBlock: any) {
    const store = useStore();

    // 야간 선물 호가 셋팅한다.
    const oneTick = Common.G_ONETICK[futCode];
    // 호가시간(24시간)
    const szTime = outBlock.readNString(6);
    // 호가시간(36시간)
    const szTime36 = outBlock.readNString(6);
    // 매도호가1
    store.MasterState.offerho1[futCode] = outBlock.readDouble();
    // 매수호가1
    store.MasterState.bidho1[futCode] = outBlock.readDouble();
    // 매도호가잔량1
    store.MasterState.offerrem1[futCode] = outBlock.readInt();
    // 매수호가잔량1
    store.MasterState.bidrem1[futCode] = outBlock.readInt();
    // 매도호가건수1
    store.MasterState.offerno1[futCode] = outBlock.readInt();
    // 매수호가건수1
    store.MasterState.bidno1[futCode] = outBlock.readInt();
    // 매도호가2
    store.MasterState.offerho2[futCode] = outBlock.readDouble();
    // 매수호가2
    store.MasterState.bidho2[futCode] = outBlock.readDouble();
    // 매도호가잔량2
    store.MasterState.offerrem2[futCode] = outBlock.readInt();
    // 매수호가잔량2
    store.MasterState.bidrem2[futCode] = outBlock.readInt();
    // 매도호가건수2
    store.MasterState.offerno2[futCode] = outBlock.readInt();
    // 매수호가건수2
    store.MasterState.bidno2[futCode] = outBlock.readInt();
    // 매도호가3
    store.MasterState.offerho3[futCode] = outBlock.readDouble();
    // 매수호가3
    store.MasterState.bidho3[futCode] = outBlock.readDouble();
    // 매도호가잔량3
    store.MasterState.offerrem3[futCode] = outBlock.readInt();
    // 매수호가잔량3
    store.MasterState.bidrem3[futCode] = outBlock.readInt();
    // 매도호가건수3
    store.MasterState.offerno3[futCode] = outBlock.readInt();
    // 매수호가건수3
    store.MasterState.bidno3[futCode] = outBlock.readInt();
    // 매도호가4
    store.MasterState.offerho4[futCode] = outBlock.readDouble();
    // 매수호가4
    store.MasterState.bidho4[futCode] = outBlock.readDouble();
    // 매도호가잔량4
    store.MasterState.offerrem4[futCode] = outBlock.readInt();
    // 매수호가잔량4
    store.MasterState.bidrem4[futCode] = outBlock.readInt();
    // 매도호가건수4
    store.MasterState.offerno4[futCode] = outBlock.readInt();
    // 매수호가건수4
    store.MasterState.bidno4[futCode] = outBlock.readInt();
    // 매도호가5
    store.MasterState.offerho5[futCode] = outBlock.readDouble();
    // 매수호가5
    store.MasterState.bidho5[futCode] = outBlock.readDouble();
    // 매도호가잔량5
    store.MasterState.offerrem5[futCode] = outBlock.readInt();
    // 매수호가잔량5
    store.MasterState.bidrem5[futCode] = outBlock.readInt();
    // 매도호가건수5
    store.MasterState.offerno5[futCode] = outBlock.readInt();
    // 매수호가건수5
    store.MasterState.bidno5[futCode] = outBlock.readInt();

    // 매도호가총수량
    store.MasterState.totofferrem[futCode] = outBlock.readInt();
    // 매수호가총수량
    store.MasterState.totbidrem[futCode] = outBlock.readInt();
    // 매도호가총건수
    store.MasterState.totoffercnt[futCode] = outBlock.readInt();
    // 매수호가총건수
    store.MasterState.totbidcnt[futCode] = outBlock.readInt();
  }

  static OnRecvMasterNightB(packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 야간 선물 호가 셋팅한다.
    const futCode = Common.G_FUTURES_NIGHT;
    XingAPIService.MasterNightB(futCode, outBlock);
  }
  
  /*
  // 해외선물 체결
  typedef struct _OVC_OutBlock_N
  {
    char    symbol              [   8];    char    _symbol              ;    // [string,    8] 종목코드                        StartPos 0, Length 8
    char    ovsdate             [   8];    char    _ovsdate             ;    // [string,    8] 체결일자(현지)                  StartPos 9, Length 8
    char    kordate             [   8];    char    _kordate             ;    // [string,    8] 체결일자(한국)                  StartPos 18, Length 8
    char    trdtm               [   6];    char    _trdtm               ;    // [string,    6] 체결시간(현지)                  StartPos 27, Length 6
    char    kortm               [   6];    char    _kortm               ;    // [string,    6] 체결시간(한국)                  StartPos 34, Length 6
    DOUBLE    curpr;    // [double, 15.8] 체결가격                        StartPos 41, Length 15
    DOUBLE    ydiffpr;    // [double, 15.8] 전일대비                        StartPos 57, Length 15
    char    ydiffSign           [   1];    char    _ydiffSign           ;    // [string,    1] 전일대비기호                    StartPos 73, Length 1
    DOUBLE    open;    // [double, 15.8] 시가                            StartPos 75, Length 15
    DOUBLE    high;    // [double, 15.8] 고가                            StartPos 91, Length 15
    DOUBLE    low;    // [double, 15.8] 저가                            StartPos 107, Length 15
    DOUBLE    chgrate;    // [float ,  6.2] 등락율                          StartPos 123, Length 6
    INT    trdq;    // [long  ,   10] 건별체결수량                    StartPos 130, Length 10
    INT    totq;    // [string,   15] 누적체결수량                    StartPos 141, Length 15
    char    cgubun              [   1];    char    _cgubun              ;    // [string,    1] 체결구분                        StartPos 157, Length 1
    INT    mdvolume;    // [string,   15] 매도누적체결수량                StartPos 159, Length 15
    INT    msvolume;    // [string,   15] 매수누적체결수량                StartPos 175, Length 15
    char    ovsmkend            [   8];    char    _ovsmkend            ;    // [string,    8] 장마감일                        StartPos 191, Length 8
  } OVC_OutBlock_N, *LPOVC_OutBlock_N;
  */
  static MasterEuroO(futCode: any, outBlock: any) {
    const store = useStore();

    // 해외 선물 체결을 셋팅한다.
 
    // 체결일자(현지)
    const ovsdate = outBlock.readNString(8);
    // 체결일자(한국)
    const kordate = outBlock.readNString(8);
    store.MasterState.szOrgDate[futCode] = kordate;
    // 체결시간(현지)
    const trdtm = outBlock.readNString(6);
    // 체결시간(한국)
    const kortm = outBlock.readNString(6);
    store.MasterState.szCTime[futCode] = `${kortm.substring(0, 2)}:${kortm.substring(2, 4)}:${kortm.substring(4, 6)}`;
    store.MasterState.szOrgTime[futCode] = kortm;
    //체결가격(현재가)
    store.MasterState.dbCurPrice[futCode] = outBlock.readDouble();
    //전일대비
    store.MasterState.dbYDiffPrice[futCode] = outBlock.readDouble();
    // 전일대비기호
    const szSign = outBlock.readNString(1);
    store.MasterState.szYDiffSign[futCode] = szSign;
    // 시가
    store.MasterState.dbOpenPrice[futCode] = outBlock.readDouble();
    // 고가
    store.MasterState.dbHighPrice[futCode] = outBlock.readDouble();
    // 저가
    store.MasterState.dbLowPrice[futCode] = outBlock.readDouble();
    //등락율
    store.MasterState.dbChgRate[futCode] = outBlock.readDouble();
    // 체결수량
    store.MasterState.iTrdq[futCode] = outBlock.readInt();
    // 누적체결 수량
    store.MasterState.totq[futCode] = outBlock.readInt();
    //체결구분
    const szCGubun = outBlock.readNString(1);
    store.MasterState.szCGubun[futCode] = szCGubun;
    // 매도누적체결량
    const mdvolume = outBlock.readInt();
    // 매수누적체결량
    const msvolume = outBlock.readInt();
    //장마감일
    const ovsmkend = outBlock.readNString(8);
    store.MasterState.ovsmkend[futCode] = ovsmkend;

    //기준가
    if(szSign === '4' || szSign === '5') {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] + store.MasterState.dbYDiffPrice[futCode];
    } else {
      store.MasterState.dbBPrice[futCode] = store.MasterState.dbCurPrice[futCode] - store.MasterState.dbYDiffPrice[futCode];
    }
  }

  static OnRecvMasterEuroO(packet: any) {
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 해외 선물 체결을 셋팅한다.
    let futCode = Common.G_FUTURES_MAX;
    // 종목코드
    const szSymbol = outBlock.readNString(8).trim();
    for(let idx = 0; idx < Common.G_FUTURES_MAX; idx++) {
      if (store.MarketState.szFutureCode[idx] === szSymbol) {
        futCode = idx;
        break;
      }
    }
  
    if (futCode === Common.G_FUTURES_MAX) {
      return Common.NO_ERROR;
    }

    XingAPIService.MasterEuroO(futCode, outBlock);
  
    // 마스터 코드 이면 이전현재가를 저장한다.
    store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    store.MasterState.fixedRow[futCode] = 2; // fixedRow
    // 호가범위를 30%->80%로 수정
    let t_hoga1 = store.MasterState.dbBPrice[futCode] + (Common.G_ONETICK[futCode] * Common.C_GRID_HOGA_DIFF_TICKS);
    if (futCode < Common.G_FUTURES_MAX) {
      t_hoga1 = Common.ROUNDOFFOFF(t_hoga1, 1);
      const t1 = Common.ROUNDOFFOFF(Utils.fmod(t_hoga1, Common.ROUNDOFFOFF(Common.G_ONETICK[Common.G_FUTURES_CHINA50], Common.g_bFuturesUnitOfDigit[futCode])), Common.g_bFuturesUnitOfDigit[futCode]);
      t_hoga1 -= t1;
    }

    store.MasterState.sHoga[futCode] = parseFloat(Utils.PriceFormatStr(t_hoga1, futCode));
  }
  
  /*
  // 해외선물 호가
  typedef struct _OVH_OutBlock_N
  {
    char    symbol              [   8];    char    _symbol              ;    // [string,    8] 종목코드                        StartPos 0, Length 8
    char    hotime              [   6];    char    _hotime              ;    // [string,    6] 호가시간                        StartPos 9, Length 6
    DOUBLE    offerho1;    // [double, 15.8] 매도호가 1                      StartPos 16, Length 15
    DOUBLE    bidho1;    // [double, 15.8] 매수호가 1                      StartPos 32, Length 15
    INT    offerrem1;    // [long  ,   10] 매도호가 잔량 1                 StartPos 48, Length 10
    INT    bidrem1;    // [long  ,   10] 매수호가 잔량 1                 StartPos 59, Length 10
    INT    offerno1;    // [long  ,   10] 매도호가 건수 1                 StartPos 70, Length 10
    INT    bidno1;    // [long  ,   10] 매수호가 건수 1                 StartPos 81, Length 10
    DOUBLE    offerho2;    // [double, 15.8] 매도호가 2                      StartPos 92, Length 15
    DOUBLE    bidho2;    // [double, 15.8] 매수호가 2                      StartPos 108, Length 15
    INT    offerrem2;    // [long  ,   10] 매도호가 잔량 2                 StartPos 124, Length 10
    INT    bidrem2;    // [long  ,   10] 매수호가 잔량 2                 StartPos 135, Length 10
    INT    offerno2;    // [long  ,   10] 매도호가 건수 2                 StartPos 146, Length 10
    INT    bidno2;    // [long  ,   10] 매수호가 건수 2                 StartPos 157, Length 10
    DOUBLE    offerho3;    // [double, 15.8] 매도호가 3                      StartPos 168, Length 15
    DOUBLE    bidho3;    // [double, 15.8] 매수호가 3                      StartPos 184, Length 15
    INT    offerrem3;    // [long  ,   10] 매도호가 잔량 3                 StartPos 200, Length 10
    INT    bidrem3;    // [long  ,   10] 매수호가 잔량 3                 StartPos 211, Length 10
    INT    offerno3;    // [long  ,   10] 매도호가 건수 3                 StartPos 222, Length 10
    INT    bidno3;    // [long  ,   10] 매수호가 건수 3                 StartPos 233, Length 10
    DOUBLE    offerho4;    // [double, 15.8] 매도호가 4                      StartPos 244, Length 15
    DOUBLE    bidho4;    // [double, 15.8] 매수호가 4                      StartPos 260, Length 15
    INT    offerrem4;    // [long  ,   10] 매도호가 잔량 4                 StartPos 276, Length 10
    INT    bidrem4;    // [long  ,   10] 매수호가 잔량 4                 StartPos 287, Length 10
    INT    offerno4;    // [long  ,   10] 매도호가 건수 4                 StartPos 298, Length 10
    INT    bidno4;    // [long  ,   10] 매수호가 건수 4                 StartPos 309, Length 10
    DOUBLE    offerho5;    // [double, 15.8] 매도호가 5                      StartPos 320, Length 15
    DOUBLE    bidho5;    // [double, 15.8] 매수호가 5                      StartPos 336, Length 15
    INT    offerrem5;    // [long  ,   10] 매도호가 잔량 5                 StartPos 352, Length 10
    INT    bidrem5;    // [long  ,   10] 매수호가 잔량 5                 StartPos 363, Length 10
    INT    offerno5;    // [long  ,   10] 매도호가 건수 5                 StartPos 374, Length 10
    INT    bidno5;    // [long  ,   10] 매수호가 건수 5                 StartPos 385, Length 10
    INT    totoffercnt;    // [long  ,   10] 매도호가총건수                  StartPos 396, Length 10
    INT    totbidcnt;    // [long  ,   10] 매수호가총건수                  StartPos 407, Length 10
    INT    totofferrem;    // [long  ,   10] 매도호가총수량                  StartPos 418, Length 10
    INT    totbidrem;    // [long  ,   10] 매수호가총수량                  StartPos 429, Length 10
  } OVH_OutBlock_N, *LPOVH_OutBlock_N;
  */
  static MasterEuroB(futCode: any, outBlock: any) {
    const store = useStore();

    // 호가시간
    const szTime = outBlock.readNString(6);
    // 매도호가1
    store.MasterState.offerho1[futCode] = outBlock.readDouble();
    // 매수호가1
    store.MasterState.bidho1[futCode] = outBlock.readDouble();
    // 매도호가잔량1
    store.MasterState.offerrem1[futCode] = outBlock.readInt();
    // 매수호가잔량1
    store.MasterState.bidrem1[futCode] = outBlock.readInt();
    // 매도호가건수1
    store.MasterState.offerno1[futCode] = outBlock.readInt();
    // 매수호가건수1
    store.MasterState.bidno1[futCode] = outBlock.readInt();
    // 매도호가2
    store.MasterState.offerho2[futCode] = outBlock.readDouble();
    // 매수호가2
    store.MasterState.bidho2[futCode] = outBlock.readDouble();
    // 매도호가잔량2
    store.MasterState.offerrem2[futCode] = outBlock.readInt();
    // 매수호가잔량2
    store.MasterState.bidrem2[futCode] = outBlock.readInt();
    // 매도호가건수2
    store.MasterState.offerno2[futCode] = outBlock.readInt();
    // 매수호가건수2
    store.MasterState.bidno2[futCode] = outBlock.readInt();
    // 매도호가3
    store.MasterState.offerho3[futCode] = outBlock.readDouble();
    // 매수호가3
    store.MasterState.bidho3[futCode] = outBlock.readDouble();
    // 매도호가잔량3
    store.MasterState.offerrem3[futCode] = outBlock.readInt();
    // 매수호가잔량3
    store.MasterState.bidrem3[futCode] = outBlock.readInt();
    // 매도호가건수3
    store.MasterState.offerno3[futCode] = outBlock.readInt();
    // 매수호가건수3
    store.MasterState.bidno3[futCode] = outBlock.readInt();
    // 매도호가4
    store.MasterState.offerho4[futCode] = outBlock.readDouble();
    // 매수호가4
    store.MasterState.bidho4[futCode] = outBlock.readDouble();
    // 매도호가잔량4
    store.MasterState.offerrem4[futCode] = outBlock.readInt();
    // 매수호가잔량4
    store.MasterState.bidrem4[futCode] = outBlock.readInt();
    // 매도호가건수4
    store.MasterState.offerno4[futCode] = outBlock.readInt();
    // 매수호가건수4
    store.MasterState.bidno4[futCode] = outBlock.readInt();
    // 매도호가5
    store.MasterState.offerho5[futCode] = outBlock.readDouble();
    // 매수호가5
    store.MasterState.bidho5[futCode] = outBlock.readDouble();
    // 매도호가잔량5
    store.MasterState.offerrem5[futCode] = outBlock.readInt();
    // 매수호가잔량5
    store.MasterState.bidrem5[futCode] = outBlock.readInt();
    // 매도호가건수5
    store.MasterState.offerno5[futCode] = outBlock.readInt();
    // 매수호가건수5
    store.MasterState.bidno5[futCode] = outBlock.readInt();

    // 국내 선물과 순서가 다름.
    // 매도호가총건수
    store.MasterState.totoffercnt[futCode] = outBlock.readInt();
    // 매수호가총건수
    store.MasterState.totbidcnt[futCode] = outBlock.readInt();
    // 매도호가총수량
    store.MasterState.totofferrem[futCode] = outBlock.readInt();
    // 매수호가총수량
    store.MasterState.totbidrem[futCode] = outBlock.readInt();
  }
  
  static OnRecvMasterEuroB(packet: any) {
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 해외 선물 호가를 셋팅한다.
    let futCode = Common.G_FUTURES_MAX;
    // 종목코드
    //const szSymbol = outBlock.readNString(8).trim();
    const szSymbol = outBlock.readNString(8).trim();
    for(let idx = 0; idx < Common.G_FUTURES_MAX; idx++) {
      if (store.MarketState.szFutureCode[idx] === szSymbol) {
        futCode = idx;
        break;
      }
    }

    // console.log(`마스터코드를 받았다. ${szSymbol}, ${futCode}`);

    if (futCode === Common.G_FUTURES_MAX) {
      return Common.NO_ERROR;
    }

    XingAPIService.MasterEuroB(futCode, outBlock);

    // 마지막 선물 호가를 받으면 마스터코드 실행
    if (futCode === Common.G_FUTURES_MAX-1 && !store.MasterState.isReadyRECV) {
      // 마스터 코드 실행
      // InitMasterCode();
  
      store.MasterState.isReadyRECV = true;
      // console.log('set isReadyRECV is true: 마스터코드를 모두 받았다.');
    }
  }
  
  // 주간선물 일봉
  /*
  typedef struct _t8416OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    DOUBLE    open;    // [float ,  6.2] 시가                            StartPos 9, Length 6
    DOUBLE    high;    // [float ,  6.2] 고가                            StartPos 16, Length 6
    DOUBLE    low;    // [float ,  6.2] 저가                            StartPos 23, Length 6
    DOUBLE    close;    // [float ,  6.2] 종가                            StartPos 30, Length 6
    INT64    jdiff_vol;    // [long  ,   12] 누적거래량                      StartPos 37, Length 12
    INT64    value;    // [long  ,   12] 거래대금                        StartPos 50, Length 12
    INT64    openyak;    // [long  ,   12] 미결제약정                      StartPos 63, Length 12
  } t8416OutBlock1_N, * LPt8416OutBlock1_N;
  */
  static Append_t8416OutBlock1(Futures: any, TermPeriod: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Common.G_FUTURES_DAY, ChartConst.cPeriod_Daily, TermPeriod, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_t8416OutBlock1Count[TermPeriod];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [] as any;
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_t8416OutBlock1[TermPeriod].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_t8416OutBlock1[TermPeriod].slice(0, blockIdx);
    }
    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      const szDate = outBlock.readNString(8);
      const date = moment(szDate, 'YYYYMMDD').toDate().getTime();
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      // 거래대금
      const value = Number(outBlock.readInt64());
      // 미결제약정
      const openyak = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_t8416OutBlock1[TermPeriod] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_t8416OutBlock1Count[TermPeriod] = blockIdx + BlockCount;

    return Common.NOERROR;
  }

  // 주간선물 분봉
  /*
  typedef struct _t8415OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    char    time[10];    char    _time;    // [string,   10] 시간                            StartPos 9, Length 10
    DOUBLE    open;    // [float ,  6.2] 시가                            StartPos 20, Length 6
    DOUBLE    high;    // [float ,  6.2] 고가                            StartPos 27, Length 6
    DOUBLE    low;    // [float ,  6.2] 저가                            StartPos 34, Length 6
    DOUBLE    close;    // [float ,  6.2] 종가                            StartPos 41, Length 6
    INT64    jdiff_vol;    // [long  ,   12] 누적거래량                      StartPos 48, Length 12
    INT64    value;    // [long  ,   12] 거래대금                        StartPos 61, Length 12
    INT64    openyak;    // [long  ,   12] 미결제약정                      StartPos 74, Length 12
  } t8415OutBlock1_N, * LPt8415OutBlock1_N;
  */
  static Append_t8415OutBlock1(Futures: any, Term: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Common.G_FUTURES_DAY, ChartConst.cPeriod_Minute, Term, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_t8415OutBlock1Count[Term];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [];
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_t8415OutBlock1[Term].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_t8415OutBlock1[Term].slice(0, blockIdx);
    }
    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      const szDate = outBlock.readNString(8);
      const szTime = outBlock.readNString(10);
      const szDateTime = `${szDate}${szTime.substring(0, 6)}`;
      const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      // 거래대금
      const value = Number(outBlock.readInt64());
      // 미결제약정
      const openyak = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_t8415OutBlock1[Term] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_t8415OutBlock1Count[Term] = blockIdx + BlockCount;
  
    return Common.NOERROR;
  }

  // 주간선물 틱봉
  /*
  typedef struct _t8414OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    char    time[10];    char    _time;    // [string,   10] 시간                            StartPos 9, Length 10
    DOUBLE    open;    // [float ,  6.2] 시가                            StartPos 20, Length 6
    DOUBLE    high;       // [float ,  6.2] 고가                            StartPos 27, Length 6
    DOUBLE    low;    // [float ,  6.2] 저가                            StartPos 34, Length 6
    DOUBLE    close;    // [float ,  6.2] 종가                            StartPos 41, Length 6
    INT64    jdiff_vol;    // [long  ,   12] 거래량                          StartPos 48, Length 12
    INT64    openyak;    // [long  ,   12] 미결제약정                      StartPos 61, Length 12
  } t8414OutBlock1_N, * LPt8414OutBlock1_N;
  */
  static Append_t8414OutBlock1(Futures: any, Term: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Common.G_FUTURES_DAY, ChartConst.cPeriod_Tick, Term, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_t8414OutBlock1Count[Term];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [];
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_t8414OutBlock1[Term].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_t8414OutBlock1[Term].slice(0, blockIdx);
    }
    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      let szDate = outBlock.readNString(8);
      // 주간 틱봉에서 날자는 들어오지 않는다.
      szDate = moment(Date.now()).format('YYYYMMDD');
      const szTime = outBlock.readNString(10);
      const szDateTime = `${szDate}${szTime.substring(0, 6)}`;
      const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      // 미결제약정
      const openyak = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_t8414OutBlock1[Term] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_t8414OutBlock1Count[Term] = blockIdx + BlockCount;
  
    Datafeed.onHistoryData(newBlocks);
    
    return Common.NOERROR;
  }
  
  // 해외선물 일봉
  /*
  typedef struct _o3128OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    DOUBLE    open;    // [double, 15.9] 시가                            StartPos 9, Length 15
    DOUBLE    high;    // [double, 15.9] 고가                            StartPos 25, Length 15
    DOUBLE    low;    // [double, 15.9] 저가                            StartPos 41, Length 15
    DOUBLE    close;    // [double, 15.9] 종가                            StartPos 57, Length 15
    INT64    volume;    // [long  ,   12] 거래량                          StartPos 73, Length 12
  } o3128OutBlock1_N, * LPo3128OutBlock1_N;
  */
  static Append_o3128OutBlock1(Futures: any, TermPeriod: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    const overseaIdx = Futures - Common.G_FUTURES_OVERSEAS;
  
    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Futures, ChartConst.cPeriod_Daily, TermPeriod, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_o3128OutBlock1Count[overseaIdx][TermPeriod];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [];
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_o3128OutBlock1[overseaIdx][TermPeriod].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_o3128OutBlock1[overseaIdx][TermPeriod].slice(0, blockIdx);
    }
    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      const szDate = outBlock.readNString(8);
      const date = moment(szDate, 'YYYYMMDD').toDate().getTime();
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_o3128OutBlock1[overseaIdx][TermPeriod] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_o3128OutBlock1Count[overseaIdx][TermPeriod] = blockIdx + BlockCount;
  
    return Common.NOERROR;
  }
  
  // 해외선물 분봉
  /*
  typedef struct _o3123OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    char    time[6];    char    _time;    // [string,    6] 현지시간                        StartPos 9, Length 6
    DOUBLE    open;    // [double, 15.9] 시가                            StartPos 16, Length 15
    DOUBLE    high;    // [double, 15.9] 고가                            StartPos 32, Length 15
    DOUBLE    low;    // [double, 15.9] 저가                            StartPos 48, Length 15
    DOUBLE    close;    // [double, 15.9] 종가                            StartPos 64, Length 15
    INT64    volume;    // [long  ,   12] 거래량                          StartPos 80, Length 12
  } o3123OutBlock1_N, * LPo3123OutBlock1_N;
  */
  static Append_o3123OutBlock1(Futures: any, Term: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    const overseaIdx = Futures - Common.G_FUTURES_OVERSEAS;
  
    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Futures, ChartConst.cPeriod_Minute, Term, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_o3123OutBlock1Count[overseaIdx][Term];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [];
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_o3123OutBlock1[overseaIdx][Term].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_o3123OutBlock1[overseaIdx][Term].slice(0, blockIdx);
    }

    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      const szDate = outBlock.readNString(8);
      const szTime = outBlock.readNString(6);
      const szDateTime = `${szDate}${szTime}`;
      const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
      //const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime() + (now.getTimezoneOffset() * 60 * 1000);
      //const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime() + (now.getTimezoneOffset() * 60 * 1000) + (3600000*24);
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_o3123OutBlock1[overseaIdx][Term] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_o3123OutBlock1Count[overseaIdx][Term] = blockIdx + BlockCount;
  
    return Common.NOERROR;
  }
  
  // 해외선물 틱봉
  /*
  typedef struct _o3137OutBlock1_N
  {
    char    date[8];    char    _date;    // [string,    8] 날짜                            StartPos 0, Length 8
    char    time[6];    char    _time;    // [string,    6] 시간                            StartPos 9, Length 6
    DOUBLE    open;    // [double, 15.9] 시가                            StartPos 16, Length 15
    DOUBLE    high;    // [double, 15.9] 고가                            StartPos 32, Length 15
    DOUBLE    low;    // [double, 15.9] 저가                            StartPos 48, Length 15
    DOUBLE    close;    // [double, 15.9] 종가                            StartPos 64, Length 15
    INT64    volume;    // [long  ,   12] 거래량                          StartPos 80, Length 12
  } o3137OutBlock1_N, * LPo3137OutBlock1_N;
  */
  static Append_o3137OutBlock1(Futures: any, Term: any, BlockCount: any, packet: any) {
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);

    const overseaIdx = Futures - Common.G_FUTURES_OVERSEAS;
  
    // 해당 날자가 있는지 검색
    let blockIdx = XingAPIService.FindDateTimeOnBlock1(Futures, ChartConst.cPeriod_Tick, Term, outBlock);
    // 없으면 뒤에 추가
    if (blockIdx < 0) {
      blockIdx = XingAPIService.m_o3137OutBlock1Count[overseaIdx][Term];
    }
    // 추가가 사이즈 넘어서면 사이즈에 맞게 조절
    if (BlockCount > ChartConst.cChartData_Max) {
      BlockCount = ChartConst.cChartData_Max;
    }
    let leftBlocks = [];
    if (blockIdx + BlockCount > ChartConst.cChartData_Max) {
      const moveCount = (blockIdx + BlockCount) - ChartConst.cChartData_Max;
      if (moveCount < ChartConst.cChartData_Max) {
        leftBlocks = XingAPIService.m_o3137OutBlock1[overseaIdx][Term].slice(moveCount, ChartConst.cChartData_Max);
      }
      blockIdx -= moveCount;
    } else {
      leftBlocks = XingAPIService.m_o3137OutBlock1[overseaIdx][Term].slice(0, blockIdx);
    }
    
    // 패킷에서 읽기
    const newBlocks = [];
    for (let idx = 0; idx < BlockCount; idx++) {
      // 날짜
      const szDate = outBlock.readNString(8);
      const szTime = outBlock.readNString(6);
      const szDateTime = `${szDate}${szTime}`;
      const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
      const open = outBlock.readDouble();
      const high = outBlock.readDouble();
      const low = outBlock.readDouble();
      const close = outBlock.readDouble();
      // 누적거래량
      const volume = Number(outBlock.readInt64());
      newBlocks.push({
        date,
        open,
        high,
        low,
        close,
        volume,
      });
    }
    XingAPIService.m_o3137OutBlock1[overseaIdx][Term] = [...leftBlocks, ...newBlocks ];
    XingAPIService.m_o3137OutBlock1Count[overseaIdx][Term] = blockIdx + BlockCount;
  
    return Common.NOERROR;
  }
  
  // 숫자로된 사인을 표시용으로 변환한다.
  static GetSignData(szSignDigit: any) {
    let signTxt;
    if (szSignDigit === '1') {
      signTxt = '↑';
    } else if (szSignDigit === '2') {
      signTxt = '▲';
    }  else if (szSignDigit === '4') {
      signTxt = '↓';
    } else if (szSignDigit === '5') {
      signTxt = '▼';
    } else {
      signTxt = '';
    }

    return signTxt;
  }

  // 블럭에서 날자시간을 조회
  static FindDateTimeOnBlock1(Futures: any, Period: any, Term: any, outBlock: any) {
    const readPos = outBlock.readPos;
    let foundIdx = -1;

    if (Futures === Common.G_FUTURES_DAY) {
      if (Period === ChartConst.cPeriod_Tick) {
        // t8414OutBlock1_N::date(8) + t8414OutBlock1_N::_date(1) + t8414OutBlock1_N::time(10);
        const szDate = outBlock.readNString(8);
        const szTime = outBlock.readNString(10);
        const szDateTime = `${szDate}${szTime.substring(0, 6)}`;
        const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_t8414OutBlock1Count[Term]; idx++) {
          if (XingAPIService.m_t8414OutBlock1[Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      } else if (Period === ChartConst.cPeriod_Minute) {
        // t8415OutBlock1_N::date(8) + t8415OutBlock1_N::_date(1) + t8415OutBlock1_N::time(10);
        const szDate = outBlock.readNString(8);
        const szTime = outBlock.readNString(10);
        const szDateTime = `${szDate}${szTime.substring(0, 6)}`;
        const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_t8415OutBlock1Count[Term]; idx++) {
          if (XingAPIService.m_t8415OutBlock1[Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      } else if (Period === ChartConst.cPeriod_Daily) {
        // t8416OutBlock1_N::date(8);
        const szDate = outBlock.readNString(8);
        const date = moment(szDate, 'YYYYMMDD').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_t8416OutBlock1Count[Term]; idx++) {
          if (XingAPIService.m_t8416OutBlock1[Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      }
    } else {
      // 해외선물
      const overseaIdx = Futures - Common.G_FUTURES_OVERSEAS;
      if (Period === ChartConst.cPeriod_Tick) {
        // o3137OutBlock1_N::date(8) + o3137OutBlock1_N::_date(1) + o3137OutBlock1_N::time(6);
        const szDate = outBlock.readNString(8);
        const szTime = outBlock.readNString(6);
        const szDateTime = `${szDate}${szTime}`;
        const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_o3137OutBlock1Count[overseaIdx][Term]; idx++) {
          if (XingAPIService.m_o3137OutBlock1[overseaIdx][Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      } else if (Period === ChartConst.cPeriod_Minute) {
        // o3123OutBlock1_N::date(8) + o3123OutBlock1_N::_date(1) + o3123OutBlock1_N::time(6);
        const szDate = outBlock.readNString(8);
        const szTime = outBlock.readNString(6);
        const szDateTime = `${szDate}${szTime}`;
        const date = moment(szDateTime, 'YYYYMMDDHHmmss').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_o3123OutBlock1Count[overseaIdx][Term]; idx++) {
          if (XingAPIService.m_o3123OutBlock1[overseaIdx][Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      } else if (Period === ChartConst.cPeriod_Daily) {
        // o3128OutBlock1_N::date(8);
        const szDate = outBlock.readNString(8);
        const date = moment(szDate, 'YYYYMMDD').toDate().getTime();
        for (let idx = 0; idx < XingAPIService.m_o3128OutBlock1Count[overseaIdx][Term]; idx++) {
          if (XingAPIService.m_o3128OutBlock1[overseaIdx][Term][idx].date === date) {
            foundIdx = idx;
            break;
          }
        }
      }
    }

    // 읽은 것 복원
    outBlock.setReadPos(readPos);

    return foundIdx;
  }

  static getStoredBlockObj(Futures: any, Period: any, Term: any) {
    if (Futures === Common.G_FUTURES_DAY) {
      if (Period === ChartConst.cPeriod_Tick) {
        return XingAPIService.m_t8414OutBlock1[Term];
      } else if (Period === ChartConst.cPeriod_Minute) {
        return XingAPIService.m_t8415OutBlock1[Term];
      } else if (Period === ChartConst.cPeriod_Daily) {
        return XingAPIService.m_t8416OutBlock1[Term];
      }
    } else {
      // 해외선물
      const overseaIdx = Futures - Common.G_FUTURES_OVERSEAS;
      if (Period === ChartConst.cPeriod_Tick) {
        return XingAPIService.m_o3137OutBlock1[overseaIdx][Term];
      } else if (Period === ChartConst.cPeriod_Minute) {
        return XingAPIService.m_o3123OutBlock1[overseaIdx][Term];
      } else if (Period === ChartConst.cPeriod_Daily) {
        return XingAPIService.m_o3128OutBlock1[overseaIdx][Term];
      }
    }
  }
}