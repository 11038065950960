<template>
	<div class="popupPc" v-if="props.initIsShowPopup">
		<div class="popupPc-inner">
			<div class="title-box flex-center-center">
                <p class="text ">공지사항</p>
                <img class="close" src="../../assets/images/pc/popup-close.png" alt="" @click="close">
			</div>
			<div class="Notice scroll-wrap">
				<table >
					<tr>
						<th class="col-2 left">일자/시간</th>
						<th class="col-8 right">공지사항</th>
					</tr>
					<tr class="scroll-wrap" v-for="notice in store.NoticeState.notices" :key="notice.iNoticeIdx" @click="openNotice(notice)">
						<td>
							<p class="date">
							{{ notice.szRegDate.split(" ")[0] }}<br />
							{{ notice.szRegDate.split(" ")[1].substring(0, 5) }}
							</p>
						</td>
						<td>
							<p>{{ notice.szTitle }}</p>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
	<PcPopupNotice :initShowNotice="state.initShowNotice"
        :initTitle="state.initNoticeTitle"
        :initNoticeDate="state.initNoticeDate"
        :initNoticeMessage="state.initNoticeContent"
		:viewType ="state.viewType"
        @close="closePopupNotice('PopupNotice')"/>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive, defineProps, defineEmits } = useBaseComponent();
import { EMFJS, RTFJS, WMFJS } from "rtf.js";
import iconv from "iconv-lite";
import * as NetConstants from "@/network/Constants";
import { NetworkService, Emit_Success, Emit_NoticeContent } from "@/network";

const props = defineProps({
    initIsShowPopup: {
        type: Boolean,
        default: false,
    },
});


let state = reactive({
	initShowNotice: false,
    initNoticeTitle: "",
    initNoticeDate: "",
    initNoticeContent: "",

    selectedNoticeIdx: 0,

    // timerReqNotice: null as any,
    // regDate: [] as, any,
	viewType:'Detail'
});

onMounted(async () => {
    if (store.LoginPageState.loginState != 1) {
        router.push("/");
        return;
    }
    store.setLastViewPage("Notice");

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_NoticeContent, event_NoticeContent);

    store.NoticeState.notices = [];
    if (store.LoginPageState.isPacketLoginAckReceived){
        NetworkService.send.noticeTitleReq();
    } 
});

onUnmounted(() => {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_NoticeContent, event_NoticeContent);
    // if(state.timerReqNotice){
    //     clearInterval(state.timerReqNotice);
    // }
});

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        store.NoticeState.notices = [];
        NetworkService.send.noticeTitleReq();
    }
};

const openNotice = (notice: any) => {
    // // 공지 팝업 띄우기 전 항상 이전 공지 내용을 초기화(달라질 수 있음)
    // store.removeNoticeContent(notice.iNoticeIdx);

    // state.initNoticeTitle = "[" + notice.szTypeName + "] " + notice.szTitle;
    state.initNoticeTitle = notice.szTitle;
    state.initNoticeDate = notice.szRegDate;
    state.selectedNoticeIdx = notice.iNoticeIdx;

    //웹소켓을 통해 공지 내용 요청
    NetworkService.send.noticeContentReq({ noticeIdx: notice.iNoticeIdx });

    // // state.initNoticeTitle = "[" + notice.szTypeName + "] " + notice.szTitle;
    // state.initNoticeTitle = notice.szTitle;
    // state.initNoticeDate = notice.szRegDate;

    // let timerCount = 0;

    // //요청한 공지 내용이 정상적으로 세팅되면 팝업을 띄움
    // state.timerReqNotice = setInterval(() => {
    //     timerCount++;
    //     //pinia 에 content 내용이 세팅된 상태라면
    //     const resNoticeContent = store.existNoticeCotent(notice.iNoticeIdx);
    //     // console.log(resNoticeContent);
    //     if (resNoticeContent.result) {
    //         //contentBuff 에 내용이 입력이 되었을 경우 -> RTF 변환 후 HTML 로 변환후 출력
    //         if (resNoticeContent.contentBuff !== null) {
    //             const rtfText = iconv.decode(
    //                 resNoticeContent.contentBuff,
    //                 NetConstants.NETWORK_STRING_ENCODE
    //             );
    //             const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
    //             const div = document.createElement("div");
    //             doc.render().then((htmlElements) => {
    //                 div.append(...htmlElements);
    //                 state.initNoticeContent = div.outerHTML;
    //                 state.initShowNotice = true;
    //                 document.body.style.overflow = "hidden";
    //             })
    //             .catch((error) => console.error(error));
    //         } else if (resNoticeContent.szContents !== "") {
    //             //szContent 에 내용이 입력된 경우 그대로 출력
    //             state.initNoticeContent = resNoticeContent.szContents;
    //             state.initShowNotice = true;
    //             document.body.style.overflow = "hidden";
    //         }
    //         clearInterval(state.timerReqNotice);
    //     }

    //     //20번 체크 후 내용이 세팅이 되지 않으면 팝업 생성 안함
    //     if (timerCount > 20) {
    //         clearInterval(state.timerReqNotice);
    //     }
    // }, 100);
};

const event_NoticeContent = () => {
    const resNoticeContent = store.existNoticeCotent(state.selectedNoticeIdx);
    // console.log(resNoticeContent);
    // console.log(resNoticeContent);
    if (resNoticeContent.result) {
        //contentBuff 에 내용이 입력이 되었을 경우 -> RTF 변환 후 HTML 로 변환후 출력
        if (resNoticeContent.contentBuff !== null) {
            const rtfText = iconv.decode(
                resNoticeContent.contentBuff,
                NetConstants.NETWORK_STRING_ENCODE
            );
            const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
            const div = document.createElement("div");
            doc.render().then((htmlElements) => {
                div.append(...htmlElements);
                state.initNoticeContent = div.outerHTML;
                state.initShowNotice = true;
                document.body.style.overflow = "hidden";
            })
            .catch((error) => console.error(error));
        } else if (resNoticeContent.szContents !== "") {
            //szContent 에 내용이 입력된 경우 그대로 출력
            state.initNoticeContent = resNoticeContent.szContents;
            state.initShowNotice = true;
            document.body.style.overflow = "hidden";
        }
        // clearInterval(state.timerReqNotice);
    }
}


const stringToArrayBuffer = (string: any) => {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
};

const emit = defineEmits(["close"]);

const close = () => { 
    emit("close"); 
};

const closePopupNotice = (popupType: any) => {
	switch (popupType) {
        case "PopupNotice":
            state.initShowNotice = false;
            document.body.style.overflow = "auto";
            state.initNoticeContent = "";
            state.initNoticeTitle = "";
            break;
    }
}


</script>
