import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popupPc"
}
const _hoisted_2 = { class: "popupPc-inner leftOrder" }
const _hoisted_3 = { class: "table-box" }
const _hoisted_4 = ["id", "onClick"]
const _hoisted_5 = { class: "item name bdr" }
const _hoisted_6 = { class: "item type bdr" }
const _hoisted_7 = { class: "item amount bdr" }
const _hoisted_8 = { class: "item price bdr" }
const _hoisted_9 = { class: "item status" }

import useBaseComponent from "@/baseComponent";
import * as Common from '../../constants/Common';
import * as Utils from '../../utils/Common';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcLeftOrdersPopup',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initLeftOrders: {
        type: Object,
        defualt: []
        // default(){
        //     return []
        // }
    }
},
  emits: ["onCorrectSelectedPress"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = __props;

const state = reactive({
    selectedIdx: -1,
    selectedOrder: {} as any
});

const emit = __emit;

const onCorrectSelectedPress = () => {

    emit("onCorrectSelectedPress", {selectedIdx: state.selectedIdx, selectedOrder: state.selectedOrder});
    state.selectedIdx = -1;
    state.selectedOrder = {};

    // props.initLeftOrders?.forEach((item: any, index: number) => {
    //     delClass('list' + index, 'blue');
    // });
};

const selectLeftItem = (idx: number, order: any) => {

    if(state.selectedIdx === idx){
        //선택 해제한 경우
        state.selectedIdx = -1;
        state.selectedOrder = {};
        return;
    } else {
        //미체결 건을 선택한 경우
        state.selectedIdx = idx;
        state.selectedOrder = order;

    }
    
    // if(document.getElementById('list' + idx)?.classList.contains('blue') === false){
    //     props.initLeftOrders?.forEach((item: any, index: number) => {
    //         delClass('list' + index, 'blue');
    //     });

    //     addClass('list' + idx, 'blue');
    // } else {
    //     delClass('list' + idx, 'blue');
    // };
};

const addClass = (id: string, classe: string) => {
    try {
        let element = document.getElementById(id);
        let classes = element?.className.split(" ") as string[];
        let getIndex = classes.indexOf(classe);
        if (getIndex === -1) {
            classes.push(classe);
            if (element) element.className = classes.join(" ");
        }
    } catch (err) {
        console.error(err);
    }
};

const delClass = (id: string, classe: string) => {
    try {
        let element = document.getElementById(id);
        let classes = element?.className.split(" ") as string[];
        let getIndex = classes.indexOf(classe);
        if (getIndex > -1) {
            classes.splice(getIndex, 1);
        }
        if (element) element.className = classes.join(" ");
    } catch (err) {
        console.error(err);
    }
};

return (_ctx: any,_cache: any) => {
  return (props.isShowPopup && props.initLeftOrders?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-box flex-center-center" }, [
            _createElementVNode("p", { class: "text" }, "미체결 조회")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"table-head\"><div class=\"item name\"><p>거래종목</p></div><div class=\"item type\"><p>구분</p></div><div class=\"item amount\"><p>주문량</p></div><div class=\"item price\"><p>주문가</p></div><div class=\"item status\"><p>미체결</p></div></div>", 1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.initLeftOrders, (order, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["table-body", {'blue': _unref(state).selectedIdx === Number(idx) }]),
                key: idx,
                id: 'list' + idx,
                onClick: ($event: any) => (selectLeftItem(Number(idx), order))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", null, _toDisplayString(Common.g_szFuturesNameMini[order.iFutures]), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(Common.g_szTradeTypeName[order.iTradeType]), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(order.iOrderAmount), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", null, _toDisplayString(Utils.PriceFormatStr(order.dbOrderPrice, order.iFutures)), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(order.iLeftAmount), 1)
                ])
              ], 10, _hoisted_4))
            }), 128))
          ]),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onCorrectSelectedPress()))
          }, "확인")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})