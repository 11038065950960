import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "wrapPC" }
const _hoisted_2 = { class: "body-box subPage transactionForm tradeList" }
const _hoisted_3 = { class: "con-box" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "designBox" }
const _hoisted_6 = { class: "flex-center-between border-box" }
const _hoisted_7 = { class: "mid flex-center-between account" }
const _hoisted_8 = { class: "big-box" }
const _hoisted_9 = { class: "top flex-center-between m-0" }
const _hoisted_10 = { class: "bank-account" }
const _hoisted_11 = { class: "dropdown-sort" }
const _hoisted_12 = { class: "select-sort" }
const _hoisted_13 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_14 = { class: "out" }
const _hoisted_15 = { class: "section-sort" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "mid info-box" }
const _hoisted_18 = { class: "table-box" }
const _hoisted_19 = { class: "table-body flex-center bdbn" }
const _hoisted_20 = { class: "item title bdrGray" }
const _hoisted_21 = { class: "item title bdrGray" }
const _hoisted_22 = { class: "item title bdrGray" }
const _hoisted_23 = { class: "item title" }
const _hoisted_24 = { class: "table-box" }
const _hoisted_25 = { class: "table-body flex-center" }

import useBaseComponent from "@/baseComponent";
import * as Common from '../../constants/Common';

import { Theme } from '../../constants';
import { NetworkService, Emit_DrawTrans, Emit_TradingLog, Emit_StopLossLog, Emit_Success } from '../../network';
import { toLocaleString } from '../../utils/number';
import { UserLog } from '../../network/UserLog';
import * as Utils from '../../utils/Common';
import moment from '../../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../../constants/AccPwd';

const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;


export default /*@__PURE__*/_defineComponent({
  __name: 'PcTradeList',
  setup(__props) {

const { route,router, store, onMounted, onUnmounted, reactive } = useBaseComponent();

let state = reactive({
	
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결", value: Order_Contract},
        {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_Contract,
    selectedItem: null as any,

    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,
});

onMounted(()=>{
    
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TradeList');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) {
        initData();
    }
});

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    initAccPicker();

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const reqCheckAccPassword = () => {
    checkAccPwd();
}

return (_ctx: any,_cache: any) => {
  const _component_PcHeader = _resolveComponent("PcHeader")!
  const _component_PcTradeTable = _resolveComponent("PcTradeTable")!
  const _component_PcStockList = _resolveComponent("PcStockList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PcHeader),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("ul", { class: "select-tab bdbn m-0" }, [
                _createElementVNode("li", { class: "tab-item title" }, "거래내역")
              ], -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _withDirectives(_createElementVNode("input", {
                            class: "drop-sort",
                            type: "checkbox",
                            id: "dropPageCount",
                            name: "dropPageCount",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickAccOpen) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _unref(state).pickAccOpen]
                          ]),
                          _createElementVNode("label", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, [
                              _createTextVNode(_toDisplayString(_unref(state).pickAccDesc), 1),
                              _createElementVNode("i", {
                                class: _normalizeClass(["icon-down", [{ on: _unref(state).pickAccOpen }]])
                              }, null, 2)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickAccItems, (item, index) => {
                              return (_openBlock(), _createElementBlock("p", {
                                key: index,
                                onClick: ($event: any) => (onPickAccChangeValue(item)),
                                class: _normalizeClass(["text", { active: item.value === _unref(state).pickAccValue }])
                              }, _toDisplayString(item.desc), 11, _hoisted_16))
                            }), 128))
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("button", {
                      class: "look-up",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (checkAccPwd()))
                    }, "조회")
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"table-head flex-center\"><p class=\"item title bdrWhite\">국내예탁금</p><p class=\"item title bdrWhite\">국내평가예탁금</p><p class=\"item title bdrWhite\">해외예탁금</p><p class=\"item title\">해외평가예탁금</p></div>", 1)),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount)), 1),
                  _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(toLocaleString)(_unref(state).estimatedAmount)), 1),
                  _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea)), 1),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(toLocaleString)(_unref(state).estimatedAmountOversea)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "table-head flex-center" }, [
                  _createElementVNode("p", { class: "item title bdrWhite" }, "총평가손익"),
                  _createElementVNode("p", { class: "item title bdrWhite" }, "국내실현손익"),
                  _createElementVNode("p", { class: "item title" }, "해외실현손익")
                ], -1)),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("p", {
                    class: _normalizeClass(["item title bdrGray", [{ up: _unref(state).totalProfit > 0}, {down: _unref(state).totalProfit < 0}]])
                  }, _toDisplayString(_unref(toLocaleString)(_unref(state).totalProfit)), 3),
                  _createElementVNode("p", {
                    class: _normalizeClass(["item title bdrGray", [{ up: _unref(state).netDomestic > 0}, {down: _unref(state).netDomestic < 0}]])
                  }, _toDisplayString(_unref(toLocaleString)(_unref(state).netDomestic)), 3),
                  _createElementVNode("p", {
                    class: _normalizeClass(["item title", [{ up: _unref(state).netOversea > 0}, {down: _unref(state).netOversea < 0}]])
                  }, _toDisplayString(_unref(toLocaleString)(_unref(state).netOversea)), 3)
                ])
              ])
            ]),
            _createVNode(_component_PcTradeTable, {
              initBAccPAssed: _unref(state).bAccPassed,
              onReqCheckAccPassword: reqCheckAccPassword
            }, null, 8, ["initBAccPAssed"])
          ])
        ]),
        _createVNode(_component_PcStockList)
      ])
    ])
  ]))
}
}

})