import * as Constant from '../constants/Global';

const bDaesang =  true;//Constant.AppName === '대상증권';
const bLaON = false; //Constant.AppName === '라온에셋';
const bNC = false; //Constant.AppName === 'NC Investment';

//const tintColor = bDaesang ? '#0027ff' : '#2172f9';
const tintColor = bNC ? '#a5debf' : bLaON ? '#478cf2' : '#2172f9';

const palette = {
  primary: '#ffffff',
  background: '#ffffff',
  seconaryBackground: '#f4f4f4',

  active: tintColor,
  inActive: '#576c80',
  info: '#539cf8',
  error: '#fa464e',

  send: '#53d460',
  receive: '#54c4f2',

  black: '#000000',
  darkBlack: '#101010',
  lightBlack: '#2a2a2a',

  brightGray: '#f4f4f4',
  lightGray: '#eaeaea',
  silver: '#e1e3e6',
  dimGray: '#DdDdDd',
  gray: '#a1a1a1',
  heavyGray: '#9e9e9e',
  darkGray: '#777777',
  blackGray: '#656565',
  white: '#ffffff',
  transparent: 'transparent',
  red: '#ec0101',
  darkRed: '#880011',
  blue: '#1766ff',
  skyblue: 'rgb(86,189,252)',

  tintColor: tintColor,
  secondaryTintColor: '#235d9d',
  darkTintColor: '#0e2d55',
  heavyTintColor: '#c30b22',
  lightTintColor: '#ff727b',
  olive: '#a6c94e',
  yellow: '#fafe36',
  indigo: '#012563',
  purple: '#9e4cdf',
  orange: '#ff9f3e',
};

const mts = {
  normal: '#000000',
  white: '#ffffff',
  gray: '#cccccc',
  gray2: '#d0d5dd',
  gray3: '#b4b4b4',
  lightGray: '#eaeaea',
  bgBorder: '#dbdbdb',
  //border: '#efefef',
  border: '#b4b4b4',
  lightBorder: '#e0deda',
  black: '#010101',
  text: '#192e51',
  up: '#d54c4a',
  down: '#006eff',
  gridSellBg: '#dbedff',
  gridSell: '#1d52a4',
  gridBuyBg: '#fceeed',
  gridBuy: '#f4988e',
  gridCenter: '#f4edff',
  bgCurPrice: '#cfd5dd',
  textBasePrice: '#868383',
  textCurPrice: '#4c5768',
  buy: 'rgb(243,87,88)',
  sell: 'rgb(2,142,240)',
  correct: 'rgb(53,205,6)',
  button: '#c1c1c1',
  slate: '#4a5769',
  lightSlate: '#536c81',
  brightSlate: '#8d98a2',
  steel: '#797f8c',
  boardBg: '#e4e8ef',
  fontGray: '#515764',
  fixedBg: '#5376b4',
  fixedGrayBg: '#f4f4f4',
  fixedSkyBg: '#cad9f0',
};

// 채팅 색
const chat = {
  cColor_Fixed: 'rgb(88, 88, 88)',
  cColor_System: 'rgb(51, 153, 255)',
  cColor_User: 'rgb(0, 0, 0)',
  //cColor_Counselor: 'rgb(212, 183, 151)',
  cColor_Counselor: 'rgb(51, 153, 255)',
  cColor_Info: '#fd8180',
  cColor_Placeholder: 'rgb(140, 140, 140)',
  cColor_Transfer: '#f4920c',
};

const app = {
  backgroundColor: palette.background,
  color: '#464646'
};

const header = {
  backgroundColor: '#ffffff',
  lightColor: '#d72934',
  color: '#142e53',
};

const tab = {
  backgroundColor: '#ffffff',
  active: tintColor,
  inactive: '#576c80',
};

const surface = {
  backgroundColor: '#ffffff',
  borderRadius: 7,
  overflow: 'hidden'
};

const button = {
  backgroundColor: tintColor,
  borderRadius: 4,
  elevation: 0,
  //fontFamily: 'NanumSquareR'
};

const buttonHeavy = {
  backgroundColor: palette.secondaryTintColor,
  borderRadius: 4,
  elevation: 0,
  //fontFamily: 'NanumSquareR'
};

const buttonDisable = {
  color: palette.black,
  backgroundColor: palette.dimGray,
  borderRadius: 4,
  elevation: 0,
  //fontFamily: 'NanumSquareR'
};

const sstext = {
  //fontFamily: 'NanumSquareR',
  color: '#142e53',
  fontSize: 8,
};

const stext = {
  ...sstext,
  fontSize: 10
};

const text = {
  ...stext,
  fontSize: 12
};

const p = {
  ...text,
  fontSize: 14
};

const generateHeadings = (color: any) =>
  [32, 28, 24, 20, 16].reduce((pv, cv, i) => {
    return {
      ...pv,
      [`h${i + 1}`]: {
        ...text,
        fontSize: cv,
        color
      }
    };
  }, {});

export const Theme = {
  paper: {
    colors: {
      ...palette,
      primary: text.color
    }
  },

  palette,
  mts,
  chat,

  app,
  header,
  tab,

  surface,
  button,
  buttonHeavy,
  buttonDisable,

  sstext,
  stext,
  text,
  p,
  ...generateHeadings(text.color),

  innerSpacing: 16,
  innerPadding: 25,
  innerBorderRadius: 7,
  transferSpacing: 50,
  shadow: {
    elevation: 4,
    // ios shadow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 2
  },
  textShadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.9)',
    textShadowOffset: {
      width: 0,
      height: 0.5
    },
    textShadowRadius: 2
  }
};

export const DarkTheme = {
  paper: {
    colors: palette
  },

  palette,

  app: {
    ...app,
    backgroundColor: tintColor,
  },
  header: {
    ...header,
    backgroundColor: tintColor
  },
  tab,

  surface: {
    ...surface,
    backgroundColor: tintColor
  },
  button,

  text: {
    ...text,
    color: palette.white
  },
  p: {
    ...p,
    color: palette.white
  },
  ...generateHeadings(palette.white)
};
