<template>
    <div class="popupSet" v-if="props.isShowPopup">
        <div class="wrap">
            <div class="header">
                <img class="back-btn" src="../../assets/images/header-back.png" alt="" :class="{ 'on': props.type === 'left' }" @click="close">
                <p>실시간 알림</p>
            </div>
            <div class="liveAlarm innerHeight Notice">
                <table>
                    <tr>
                        <th class="col-2 left">시간</th>
                        <th class="col-8 right">알림 메세지</th>
                    </tr>
                    <tr v-for="(item, i) in state.dataArray" :key="i">
                        <td >
                            <p class="date">{{ item.szRegTime }}</p>
                        </td>
                        <td >
                            <p class="title">{{ item.szMessage }}</p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive, watch } = useBaseComponent();

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
});

watch(props, (isShowPopup) => {
    if (isShowPopup) {
        initLiveAlarm();
    } else {
        clearLiveAlarm();
    }
},{ deep: true });

let state = reactive({
    dataArray: [] as any,
    lastTiemstamp: 0,
})

// onMounted(() => {
// });
// onUnmounted(()=> { 
// })

const initLiveAlarm = () => {
    store.DialogState.m_LogMsg.forEach((item: any)=> {
        if(item.timestamp > state.lastTiemstamp) {
            state.lastTiemstamp = item.timestamp;
            state.dataArray.push(item);                
        }
    });
}

const clearLiveAlarm = () => {
    state.dataArray = [];
    state.lastTiemstamp = 0;
}

const emit = defineEmits(["close"]);

const close = () => { 
    emit("close"); 
};

</script>
