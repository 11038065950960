// import commaNumber from 'comma-number';

import * as Constant from '../constants/Global';
import * as Common from '../constants/Common';

import {useStore} from "../pinia"

export function PriceFormatStr(t_hoga: any, futCode: any) {
	switch(futCode) {
    case Common.G_FUTURES_DAY: // 주간선물
    case Common.G_FUTURES_NIGHT: // 야간선물
    case Common.G_FUTURES_OIL:  // 크루드 오일
    case Common.G_FUTURES_SNP500: // 미니S&P 500
    case Common.G_FUTURES_DOW: // 미니 다우존스 100
    case Common.G_FUTURES_NASDAQ: // 미니 나스닥 100
      return t_hoga?.toFixed(2);

    case Common.G_FUTURES_EURO:  // 외환유로
    case Common.G_FUTURES_CANADA: // 캐나다달러
    case Common.G_FUTURES_AUS: // 호주달러
      return t_hoga?.toFixed(5);

    case Common.G_FUTURES_POUND: // 영국파운드(BP)
    case Common.G_FUTURES_COPPER: // 구리
      return t_hoga?.toFixed(4);

    case Common.G_FUTURES_GOLD: // 금 (Gold)
    case Common.G_FUTURES_CHINA50:	// 차이나 A50지수
    case Common.G_FUTURES_YEN: // 일본엔(JY)
    case Common.G_FUTURES_DAX:						// 독일DAX
      return t_hoga?.toFixed(1);

    case Common.G_FUTURES_HANGSENG: // 항셍지수
    case Common.G_FUTURES_NIKKEI:					// 니케이지수
      return t_hoga?.toFixed(0);

    case Common.G_FUTURES_GAS: // 천연가스
    case Common.G_FUTURES_SILVER: // 은 (Silver)
      return t_hoga?.toFixed(3);

    default: 
      return t_hoga?.toFixed(0);
	}
}

export function MicroPriceFormatStr(t_hoga: any, futCode: any) {
	switch(futCode) {
    // case Common.G_FUTURES_DAY: // 주간선물
    case Common.G_FUTURES_NIGHT: // 야간선물
    case Common.G_FUTURES_DOW: // 미니 다우존스 100
    case Common.G_FUTURES_SILVER: // 은 (Silver)
    case Common.G_FUTURES_GAS: // 천연가스
      return t_hoga?.toFixed(3);

    
    // case Common.G_FUTURES_CANADA: // 캐나다달러
    // case Common.G_FUTURES_AUS: // 호주달러
      return t_hoga?.toFixed(6);

    case Common.G_FUTURES_EURO:  // 외환유로
    case Common.G_FUTURES_COPPER: // 구리
    case Common.G_FUTURES_CANADA: // 캐나다달러
    case Common.G_FUTURES_AUS: // 호주달러
      return t_hoga?.toFixed(5);

    case Common.G_FUTURES_DAY: // 주간선물
    case Common.G_FUTURES_CHINA50:	// 차이나 A50지수
    case Common.G_FUTURES_DAX:						// 독일DAX
    case Common.G_FUTURES_OIL:  // 크루드 오일
    case Common.G_FUTURES_SNP500: // 미니S&P 500
    case Common.G_FUTURES_NASDAQ: // 미니 나스닥 100
      return t_hoga?.toFixed(2);

    case Common.G_FUTURES_GOLD: // 금 (Gold)
    case Common.G_FUTURES_HANGSENG: // 항셍지수
    case Common.G_FUTURES_YEN: // 일본엔(JY)
      return t_hoga?.toFixed(1); 

    // case Common.G_FUTURES_NIKKEI:	 // 니케이지수
    // case Common.G_FUTURES_HANGSENG: // 항셍지수
    //   return t_hoga?.toFixed(0); 

    // case Common.G_FUTURES_SILVER: // 은 (Silver)
    case Common.G_FUTURES_POUND: // 영국파운드(BP)
      return t_hoga?.toFixed(4);

    default:
      return t_hoga?.toFixed(0);
  }
}

export function GetMicroOneTick(futCode: any) {
  const store = useStore();
	return store.BrandState.G_TICK_PRICE[futCode] * Common.G_MICRO_ONETICK[futCode] / Common.G_ONETICK[futCode];
}

// float modulus
export function fmod(a: any,b: any) { 
  return Number((a - (Math.floor(a / b) * b)).toPrecision(8)); 
}

// 2차원 배열
export function array2d(r: any, c: any, f: any) {
  return Array.from(new Array(r), () => new Array(c).fill(f).map(v => f));
}

// 3차원 배열
export function array3d(r: any, c: any, z: any, f: any) {
  return Array.from(new Array(r), () => Array.from(new Array(c), () => new Array(z).fill(f).map(v => f)));
}

// yyyy-mm-dd 오전 HH:MM:ss -> yyyy-mm-dd HH:MM:ss  변환
// 2023-03-09 오후 15:40:00, 2021-03-11 오전 5:40:00
export function OleToSysTimeStr(szOleTime: any) {
  // 2023-03-09 오후 15:40:00, 2021-03-11 오전 5:40:00
  // 오전, 오후를 제거한다.
  let szSysDate = szOleTime.replace('오전 ', '').replace('오후 ', '');
  // 시간이 한자리면 2자리로 수정
  if (szSysDate && szSysDate.length === 18) {
    szSysDate = `${szSysDate.substring(0, 11)}0${szSysDate.substring(11)}`;
  }

  return szSysDate;
}

// 호가 라인을 찾는다.
export function GetPriceLine(/*DOUBLE*/ sHoga: any, /*DOUBLE*/ x: any, futCode: any, fixedRows = 2) {
	return fixedRows + Common.ROUNDOFF((sHoga - x) / Common.G_ONETICK[futCode]);
}

export function SetComma(number: any) {
  // return commaNumber(number);
  return number;
}

// 시작 hhmm, 종료시간hhmm(string)으로 현재 시간이 해당 시간인지 확인
export function IsOpenTime(szOpenTime: any, szCloseTime: any) {
	let bInTime = false;
	const timeNow = new Date(Date.now());

	const hourO = parseInt(szOpenTime.substr(0, 2));
	const minO = parseInt(szOpenTime.substr(2, 2));
	const hourC = parseInt(szCloseTime.substr(0, 2));
	const minC = parseInt(szCloseTime.substr(2, 2));
	if (hourO < hourC) {
		if ((timeNow.getHours() > hourO || (timeNow.getHours() === hourO && timeNow.getMinutes() >= minO)) &&
			(timeNow.getHours() < hourC || (timeNow.getHours() === hourC && timeNow.getMinutes() <= minC))) {
      bInTime = true;
    }
	} else {
		// 24시간 초과 (ex. 밤 부터 새벽)
		if ((timeNow.getHours() > hourO || (timeNow.getHours() === hourO && timeNow.getMinutes() >= minO)) ||
			(timeNow.getHours() < hourC || (timeNow.getHours() === hourC && timeNow.getMinutes() <= minC))) {
      bInTime = true;
    }
	}

	return bInTime;
}

export function GetDeviceType(){
  let deviceType = Constant.DEVICE_TYPE_PC_TABLET;
  const ua = window.navigator.userAgent.toString().toLowerCase() || '';
    if (ua.match(/windows (nt|98)|macintosh|cros/)) {
      deviceType =  Constant.DEVICE_TYPE_PC_TABLET;
    } 
    else if(window.innerWidth < 500){
      deviceType= Constant.DEVICE_TYPE_MOBILE;
    }
    else if (ua.match(/ipad/) || (ua.match(/android/) || ua.match(/iPad|tablet|Tablet|Kindle|Tab|GalaxyTab/) && !ua.match(/mobi|mini|fennec/))) {
      deviceType= Constant.DEVICE_TYPE_PC_TABLET;
    } 
    else if (ua.match(/ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/)) {
      deviceType= Constant.DEVICE_TYPE_MOBILE;
    } 
    else {
      deviceType= Constant.DEVICE_TYPE_PC_TABLET;
    }
  // if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
  //   deviceType = Constant.DEVICE_TYPE_PC_TABLET;
  // } else if (ua.match(/ipad/) || (ua.match(/android/) && !ua.match(/mobi|mini|fennec/))) {
  //   deviceType = Constant.DEVICE_TYPE_MOBILE;
  // }
  return deviceType;
}