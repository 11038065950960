<template>
	<div class="view-change">
        <ul class="select-tab">
            <li class="tab-item" :class="[{ on: state.tabIdx === item.value }, `no`+ item.value]" v-for="(item) in state.tabList" :key="item.value" @click="onTabPress(item.value)" > {{ item.title }} </li>
        </ul>
        <div class="control-box flex-center-end" >
            <div class="date-box flex-center ml0"  v-if="!state.isFilterDisabled"> 
                <p class="text">기간 선택</p>
                <div class="label-wrap flex-center">
                    <input type="radio" id="1d" name="1d" value="1" v-model="state.selectedDays" @change="setDatePicker({selectedDays: 1})" :disabled="state.isFilterDisabled"/>
                    <label for="1d">1일</label>
                    <input type="radio" id="3d" name="3d" value="3" v-model="state.selectedDays" @change="setDatePicker({selectedDays: 3})" :disabled="state.isFilterDisabled"/>
                    <label for="3d">3일</label>
                    <input type="radio" id="1w" name="6d" value="6" v-model="state.selectedDays" @change="setDatePicker({selectedDays: 6})" :disabled="state.isFilterDisabled"/>
                    <label for="1w">6일</label>
                </div>
            </div>
            <div class="date-box flex-center" v-if="!state.isFilterDisabled">
                <p class="text">직접 입력</p>
                <input type="date" id="start" name="search-start" :value="state.startDate" :min="state.minStartDate" :max="state.maxStartDate" @change="onChangeStartDate" :disabled="state.isFilterDisabled" />
                <p class="empty-line">-</p>
                <input type="date" id="end" name="search-end" :value="state.endDate" :min="state.minEndDate" :max="state.maxEndDate" @change="onChangeEndDate" :disabled="state.isFilterDisabled"/>
            </div>
            <button class="submit" @click="onSearchPress()">조회</button>
            <!-- <button class="reset" @click="onResetDateFilter()" v-if="!state.isFilterDisabled">초기화</button> -->
        </div>
    </div>
    <div class="mid table-area">
        <div class="scroll-wrap" v-if="state.tabIdx === Order_All" id="tableTab" @scroll="scrollHandler">
            
            <div class="table-box orderAllTab" :class="[{'tradePage':route.name === 'PcTradeList'}]">
                <div class="table-head flex-center">
                    <div class="item">
                        <p class="text">상태</p>
                    </div>
                    <div class="item">
                        <p class="text">거래종목</p>
                    </div>
                    <div class="item">
                        <p class="text">가격유형</p>
                    </div>
                    <div class="item">
                        <p class="text">매매구분</p>
                    </div>
                    <div class="item">
                        <p class="text">주문번호</p>
                    </div>
                    <div class="item">
                        <p class="text">체결 / 주문수량</p>
                    </div>
                    <div class="item">
                        <p class="text">평단가 / 주문가</p>
                    </div>
                    <div class="item">
                        <p class="text">평가손익</p>
                    </div>
                    <div class="item">
                        <p class="text">실현손익</p>
                    </div>
                    <div class="item">
                        <p class="text">청산 / 취소</p>
                    </div>
                </div>
                <div class="table-body flex-center"  v-for="(item, idx) in state.renderOppositeList" :key="idx">
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" >체결</p>
                            <!-- /매수/ -->
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks !== 0"  >
                                <span style="color:#fc3d5b" >익절</span>
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iLossTicks !== 0" >
                                <span style="color:#006eff" >손절</span>
                            </p>
                            <!-- /매도/ -->
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iProfitTicks !== 0" >
                                <span style="color:#fc3d5b">익절</span>
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도' && item.order.iLossTicks !== 0" >
                                <span style="color:#006eff" >손절</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{item.name}}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{item.name}}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">{{item.name}}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" >{{ item.priceType }}</p>
                            <!-- // -->
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks !== 0 " >
                                <span style="color:#fc3d5b">익: {{ item.order.iProfitTicks }}</span>
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iLossTicks !== 0" >
                                <span style="color:#006eff"  >손: {{ item.order.iLossTicks }}</span>
                            </p>
                            <!-- // -->
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iProfitTicks !== 0" >
                                <span style="color:#fc3d5b" v-if="item.order.iProfitTicks !== 0 ">익: {{ item.order.iProfitTicks }}</span>
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iLossTicks !== 0 " >
                                <span style="color:#006eff" v-if="item.order.iLossTicks !== 0 " >손: {{ item.order.iLossTicks }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.tradeType }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{ item.tradeType }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">{{ item.tradeType }}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.orderIdx }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{item.orderIdx}}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">{{item.orderIdx}}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.amount }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{ item.amount }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">{{ item.amount }}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.price }}</p>
                            <!-- /매도일때 이익틱 손실틱 매수일때 이익틱 손실틱 총 6개 가격 작업을 해야한다. 그렇다면 가격은 3개를 뿌려야하는가?/ -->
                            <!-- /매수/ -->
                            <!-- <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks > 0" >{{item.order.dbOrderPrice - Common.G_ONETICK[item.order.iFutures]* item.order.iStopTicks}} </p> -->
                            <div class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수' && item.order.iProfitTicks  > 0">
                                <span class=""  style="color:#fc3d5b" >{{Utils.MicroPriceFormatStr(item.order.dbOrderPrice + Common.G_ONETICK[item.order.iFutures]* item.order.iProfitTicks,item.order.iFutures)}} </span>
                            </div>
                            <div class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매수'&& item.order.iLossTicks > 0">
                                <span class=""  style="color:#006eff" >{{Utils.MicroPriceFormatStr(item.order.dbOrderPrice - Common.G_ONETICK[item.order.iFutures]* item.order.iLossTicks,item.order.iFutures)}} </span>
                            </div>
                            <!-- /매도/ -->
                            <div class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도' &&  item.order.iProfitTicks  > 0">
                                <span class="" style="color:#fc3d5b" >{{Utils.MicroPriceFormatStr(item.order.dbOrderPrice - Common.G_ONETICK[item.order.iFutures]* item.order.iProfitTicks,item.order.iFutures)}} </span>
                            </div>
                            <div class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.tradeType === '매도'&& item.order.iLossTicks > 0">
                                <span class=""  style="color:#006eff">{{Utils.MicroPriceFormatStr(item.order.dbOrderPrice + Common.G_ONETICK[item.order.iFutures]* item.order.iLossTicks,item.order.iFutures)}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" :class="[{ up: item.profit > 0}, {down: item.profit < 0}]">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.profit / store.AccountState.iLeverageEuro) : toLocaleString(item.profit / store.AccountState.iLeverage) }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">-</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">-</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" :class="[{ up: item.net > 0}, {down: item.net < 0}]">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.net) : toLocaleString(item.net) }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">-</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">-</p>
                        </div>
                    </div>
                    <div class="item status">
                        <div class="item-inner" >
                            <button class="settlement" @click="onActionPress(item.order)" >
                                <span class="text">청산</span>
                            </button>
                            <div class="text top"  v-if="item.order.iStopLossLogIdx > 0" >
                                <button class="settlement" @click="slCancelBtn(item.order)">
                                    <span class="">취소</span>
                                </button>
                            </div>
                            <div class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0">
                                <button class="settlement" @click="slCancelBtn(item.order)" >
                                    <span class="text">취소</span>
                                </button>
                            </div>
                            <!-- <p class="text top" v-if="item.order.iStopLossLogIdx > 0"> - </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0 && item.order.iLossTicks !== 0 && item.order.iProfitTicks !== 0"> - </p> -->
                        </div>
                    </div>
                </div>
                <div class="table-body flex-center"  v-for="(item, idx) in state.renderLeftsList" :key="idx">
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" >미체결</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0" >
                                <span style="color:#fc3d5b" v-if="item.order.iLossTicks !== 0">익절</span>
                                <span v-if="item.order.iLossTicks !== 0 && item.order.iStopTicks !== 0"> / </span>
                                <span style="color:#006eff" v-if="item.order.iStopTicks !== 0">손절</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{item.name}}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{item.name}}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" >{{ item.priceType }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0" >
                                <span style="color:#fc3d5b" v-if="item.order.iLossTicks !== 0">익: {{ item.order.iLossTicks }}</span>
                                <span v-if="item.order.iLossTicks !== 0 && item.order.iStopTicks !== 0"> / </span>
                                <span style="color:#006eff" v-if="item.order.iStopTicks !== 0">손: {{ item.order.iStopTicks }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.tradeType }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{ item.tradeType }}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{item.orderIdx}}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{item.orderIdx}}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.amount }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{ item.amount }}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text">{{ item.price }}</p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">{{item.averagePrice}}</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" :class="[{ up: item.profit > 0}, {down: item.profit < 0}]">
                                <!-- {{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.profit / store.AccountState.iLeverageEuro) : toLocaleString(item.profit / store.AccountState.iLeverage) }} -->
                                -
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">-</p>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-inner">
                            <p class="text" :class="[{ up: item.net > 0}, {down: item.net < 0}]">
                                <!-- {{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.net) : toLocaleString(item.net) }} -->
                                -
                            </p>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0">-</p>
                        </div>
                    </div>
                    <div class="item status">
                        <div class="item-inner" >
                            <button class="settlement" @click="onActionPress(item.order)" >
                                <span class="text">취소</span>
                            </button>
                            <p class="text top" v-if="item.order.iStopLossLogIdx > 0"> - </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
<!-- 
    <div class="mid table-area">
        <div class="scroll-wrap" v-if="state.tabIdx === Order_Contract">
            <div class="table-box" :class="{'tradePage':route.name === 'PcTradeList'}">
                <div class="table-head flex-center">
                    <div class="item name">
                        <p class="text">거래종목</p>
                    </div>
                    <div class="item event">
                        <p class="text">매매구분</p>
                    </div>
                    <div class="item event">
                        <p class="text">보유체결</p>
                    </div>
                    <div class="item price">
                        <p class="text">평균단가</p>
                    </div>
                    <div class="item price">
                        <p class="text">평가손익</p>
                    </div>
                    <div class="item price">
                        <p class="text">실현손익</p>
                    </div>
                    <div class="item status">
                        <p class="text">청산</p>
                    </div>
                </div>
                <div class="table-body flex-center" v-for="(item, idx) in state.renderOppositeList" :key="idx">
                    <div class="item name">
                        <p class="text">{{ item.name }}</p>
                    </div>
                    <div class="item event">
                        <p class="text red blue">{{ item.tradeType }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.amount }}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{ item.price }}</p>
                    </div>                    
                    <div class="item price">
                        <p class="text" :class="[{ up: item.profit > 0}, {down: item.profit < 0}]">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.profit / store.AccountState.iLeverageEuro) : toLocaleString(item.profit / store.AccountState.iLeverage) }}</p>
                    </div>
                    <div class="item price">
                        <p class="text" :class="[{ up: item.net > 0}, {down: item.net < 0}]">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.net) : toLocaleString(item.net) }}</p>
                    </div>
                    <div class="item status">
                        <button class="settlement" @click="onActionPress(item.order)">
                            <span class="text">청산</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-wrap" v-else-if="state.tabIdx === Order_Left" >
            <div class="table-box" :class="{'tradePage':route.name === 'PcTradeList'}">
                <div class="table-head flex-center">
                    <div class="item name">
                        <p class="text">거래종목</p>
                    </div>
                    <div class="item event">
                        <p class="text">매매구분</p>
                    </div>
                    <div class="item event">
                        <p class="text">주문번호</p>
                    </div>
                    <div class="item event">
                        <p class="text">주문량</p>
                    </div>
                    <div class="item price">
                        <p class="text">주문가격</p>
                    </div>
                    <div class="item event">
                        <p class="text">가격유형</p>
                    </div>
                    <div class="item event">
                        <p class="text">접수상태</p>
                    </div>
                    <div class="item event">
                        <p class="text">체결량</p>
                    </div>
                    <div class="item status">
                        <p class="text">청산</p>
                    </div>
                </div>
                <div class="table-body flex-center" v-for="(item, idx) in state.renderLeftsList" :key="idx">
                    <div class="item name">
                        <p class="text">{{ item.name }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.tradeType }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.orderIdx }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.amount }}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{ item.price }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.priceType }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.state }}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{ item.oppAmount }}</p>
                    </div>
                    <div class="item status">
                        <button class="settlement" @click="onActionPress(item.order)">
                            <span class="text">취소</span>
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- /거래내역/ -->
        <div class="scroll-wrap" v-else-if="state.tabIdx === Order_Log" id="tableTab" @scroll="scrollHandler">
            
            <div class="table-box" :class="{'tradePage':route.name === 'PcTradeList'}">
                <div class="table-head flex-center">
                    <div class="item name">
                        <p class="text">거래종목</p>
                    </div>
                    <div class="item event">
                        <p class="text">매매구분</p>
                    </div>
                    <div class="item event">
                        <p class="text">주문번호</p>
                    </div>
                    <div class="item event">
                        <p class="text">주문량</p>
                    </div>
                    <div class="item price">
                        <p class="text">주문가격</p>
                    </div>
                    <div class="item event">
                        <p class="text">가격유형</p>
                    </div>
                    <div class="item price">
                        <p class="text">평균단가</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{store.SystemState._appMode === 2 ? '레버리지' : '오버나잇'}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">수수료</p>
                    </div>
                    <div class="item price">
                        <p class="text">평가금액</p>
                    </div>
                    <div class="item price">
                        <p class="text">손익</p>
                    </div>
                    <div class="item price">
                        <p class="text">총손익</p>
                    </div>
                    <div class="item price">
                        <p class="text">보유잔고</p>
                    </div>
                    <div class="item event">
                        <p class="text">접수상태</p>
                    </div>
                    <div class="item event">
                        <p class="text">체결량</p>
                    </div>
                    <div class="item date">
                        <p class="text">체결시간</p>
                    </div>
                </div>
                <!-- // -->
                <div class="table-body flex-center"  v-for="(item, idx) in state.renderLogList" :key="idx">
                    <div class="item name">
                        <p class="text">{{item.name}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.tradeType}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.orderIdx}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.amount}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.price}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.priceType}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.averagePrice}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{store.SystemState._appMode === 2 ? item.leverage : item.overTrading}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.fee}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.evalProfit}}</p>
                    </div>
                    <div class="item price">
                        <p class="text" :class="[{ up: item.profit > 0}, {down: item.profit < 0}]">{{item.strProfit}}</p>
                    </div>
                    <div class="item price">
                        <p class="text" :class="[{ up: item.totalProfit > 0}, {down: item.totalProfit < 0}]">{{item.strTotalProfit}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.depoAmount}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.acceptState}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.contAmount}}</p>
                    </div>
                    <div class="item date">
                        <p class="text">{{item.contractDate.substr(0,8)}}<br/>{{item.contractDate.substr(9,8)}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- /S/L내역/ -->
        <div class="scroll-wrap" v-else-if="state.tabIdx === Order_SL" id="tableTab" @scroll="scrollHandler">
            
            <div class="table-box  orderSLTab" :class="{'tradePage':route.name === 'PcTradeList'}">
                <div class="table-head flex-center">
                    <div class="item name">
                        <p class="text">거래종목</p>
                    </div>
                    <div class="item event">
                        <p class="text">매매구분</p>
                    </div>
                    <div class="item date">
                        <p class="text">주문번호</p>
                    </div>
                    <div class="item event">
                        <p class="text">상태</p>
                    </div>
                    <div class="item price">
                        <p class="text">이익틱</p>
                    </div>
                    <div class="item price">
                        <p class="text">손실틱</p>
                    </div>
                    <div class="item event">
                        <p class="text">S/L여부</p>
                    </div>
                    <div class="item price">
                        <p class="text">스탑가격</p>
                    </div>
                    <div class="item price">
                        <p class="text">평균가격</p>
                    </div>
                    <div class="item price">
                        <p class="text">체결가격</p>
                    </div>
                    <!-- <div class="item price">
                        <p class="text">체결량</p>
                    </div> -->
                    <div class="item date">
                        <p class="text">등록시간</p>
                    </div>
                    <div class="item date">
                        <p class="text">완료시간</p>
                    </div>
                </div>
                <!-- // -->
                <div class="table-body flex-center" v-for="(item, idx) in state.renderSLLogsList" :key="idx">
                    <div class="item name">
                        <p class="text">{{item.name}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.tradeType}}</p>
                    </div>
                    <div class="item date">
                        <p class="text">{{item.orderIdx}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.state}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.stopTicks}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.lossTicks}}</p>
                    </div>
                    <div class="item event">
                        <p class="text">{{item.slName}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.stopPrice}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.averagePrice}}</p>
                    </div>
                    <div class="item price">
                        <p class="text">{{item.contractPrice}}</p>
                    </div>
                    <!-- <div class="item price">
                        <p class="text">체결량</p>
                    </div> -->
                    <div class="item date">
                        <p class="text">{{item.regDate.substr(0,8)}}<br/>{{item.regDate.substr(9,8)}}</p>
                    </div>
                    <div class="item date">
                        <p class="text">{{item.useDate.substr(0,8)}}<br/>{{item.useDate.substr(9,8)}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else></div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, store, onMounted, onUnmounted, defineEmits, defineProps, reactive, watch } = useBaseComponent();

import * as Common from '../../constants/Common';

import { Theme } from '../../constants';
import {
  NetworkService,
  Emit_DrawTrans,
  Emit_TradingLog,
  Emit_StopLossLog,
  Emit_Success,
  Emit_DrawTransProfit
} from '../../network';
import { toLocaleString } from '../../utils/number';
import { UserLog } from '../../network/UserLog';
import * as Utils from '../../utils/Common';
import moment from '../../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../../constants/AccPwd';
import { start } from "repl";
import { ref } from "vue";

const Order_All = 99; // 체결
const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;

const props = defineProps({
    initBAccPAssed: {
        type: Boolean,
        default: false,
    },
});

let state = reactive({
	
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결 / 미체결", value: Order_All},
        // {title: "체결", value: Order_Contract},
        // {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_All,
    selectedItem: null as any,
    
    //체결내역
    oppositeOrders: [] as any,
    renderOppositeList: [] as any,

    //미체결내역
    leftOrders: [] as any,
    renderLeftsList: [] as any,
    
    //거래내역
    orderLogs: [] as any,
    renderLogList: [] as any,
    
    //S/L내역
    stopLossLogs: [] as any,
    renderSLLogsList: [] as any,

    isOversea: Common.eNation_Domestic,
    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,

    //날짜 선택 필터
    selectedDays:"6",
    startDate:'',
    endDate:'',

    //날짜 직접 입력시 선택할 수 있는 날짜 제한
    minStartDate: '',
    maxStartDate: '',
    minEndDate: '',
    maxEndDate: '',
    isFilterDisabled: false,

    timer: null as any,
});

watch(props, (initBAccPAssed) => {
    if (initBAccPAssed) {
        state.bAccPassed = true;
        initData();
    }
},{ deep: true });

onMounted(()=>{
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.on(Emit_StopLossLog, event_StopLossLog);
    NetworkService.emitter.on(Emit_DrawTransProfit, event_DrawTrans);
    window.addEventListener('scroll',handleEnded)
})
onUnmounted(()=> {
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.off(Emit_StopLossLog, event_StopLossLog);
    NetworkService.emitter.off(Emit_DrawTransProfit, event_DrawTrans);
    window.removeEventListener('scroll', handleEnded);
})


const handleEnded = () => {
    if(state.timer){
        clearTimeout(state.timer);
    }
    state.timer = setTimeout(()=>{
        scrollHandler();
    }, 500);
}
const scrollPosition = ref(0);

const isLeftArrowOn = ref(false);

const isRightArrowOff = ref(false);

const scrollHandler = () => {
    scrollPosition.value = document.getElementById('tableTab')?.scrollLeft;
    isLeftArrowOn.value = scrollPosition.value > 34;
    isRightArrowOff.value = scrollPosition.value > 400;
}

const goLeft = () => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
}
const goRight = () => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:9999,behavior:'smooth'})
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 리스트 업데이트
    refreshOrders();
}

const event_TradingLog = (params: any) => {
    // 거래 로그 수신 완료.
    const TradingLogs = store.AccountState.TradingLogs;
    state.orderLogs = TradingLogs;
    refreshOrders();
}

const event_StopLossLog = (params: any) => {
    // 스탑로스 로그 수신 완료.
    const StopLossLogs = store.AccountState.StopLossLogs;
    state.stopLossLogs = StopLossLogs;
    refreshOrders();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        // calcuAmount();
        state.refreshCount++;

        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
    onResetDateFilter();

    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }
}

const onResetDateFilter = () => {
    const nowDate = Date.now();

    state.selectedDays = '6';
    state.minStartDate = moment(nowDate).add(-30, 'days').format('YYYY-MM-DD');
    state.maxStartDate = moment(nowDate).format('YYYY-MM-DD');

    setDatePicker({selectedDays: state.selectedDays});
};

//date picker 에 날짜 입력
const setDatePicker = ({selectedDays = null as any, pickStartDate = '' as string, pickEndDate = '' as string }) => {
    if(state.isFilterDisabled) return;

    const nowDate = Date.now();

    if(selectedDays !== null){
        state.selectedDays = selectedDays;
        state.startDate = moment(nowDate).add(-(Number(selectedDays) -1), 'days').format('YYYY-MM-DD');
        state.endDate = moment(nowDate).format('YYYY-MM-DD');
        setDatePickerLimit();
    } else if(pickStartDate !== ''){
        state.selectedDays = '';
        state.startDate = pickStartDate;
        state.endDate = pickStartDate;
        setDatePickerLimit();
    }  else if(pickEndDate !== ''){
        state.endDate = pickEndDate;
    }

    if(state.tabIdx !== Order_Log && state.tabIdx !== Order_SL){
        state.isFilterDisabled = true;
    } else {
        state.isFilterDisabled = false;
    }

}

//Date picker 날짜 선택 제한을 설정
const setDatePickerLimit = (dayRange = 5) => {
    const nowDate = moment(Date.now());

    state.minEndDate = state.startDate;
    state.maxEndDate = moment(state.minEndDate).add(dayRange, 'days').format('YYYY-MM-DD');
    const diffDay = moment(state.maxEndDate).diff(nowDate, 'days');

    if(diffDay > 0){
        state.maxEndDate = nowDate.format('YYYY-MM-DD');
    }
}

const onTabPress = (tabIdx: number) => {
    const scroll = document.getElementById('tableTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
    isRightArrowOff.value = false;
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;
        
        if(state.tabIdx !== Order_Log && state.tabIdx !== Order_SL){
            state.isFilterDisabled = true;
        } else {
            state.isFilterDisabled = false;
        }

        if(state.bAccPassed){
            onSearchPress();
        }
    }
}

//검색 시작 날짜 직접 선택
const onChangeStartDate = (e: any) => {
    const pickDate = e.target.value;
    setDatePicker({pickStartDate: pickDate})
}

//검색 종료 날짜 직접 선택
const onChangeEndDate = (e: any) => {
    const pickDate = e.target.value;
    setDatePicker({pickEndDate: pickDate})
}

const onSearchPress = () => {
    if(!state.bAccPassed) {
        reqCheckAccPassword();
        return;
    }

    if(state.tabIdx === Order_All ||state.tabIdx === Order_Contract || state.tabIdx === Order_Left){
        // 체결 / 미체결 리스트 업데이트
        refreshOrders();   
        
    } else if (state.tabIdx === Order_Log){
        // 거래내역 요청
        tradingLogsReq();
    } else if (state.tabIdx === Order_SL){
        // 스탑로스내역 요청
        stopLossLogsReq();
    }
}

// 거래내역 요청
const tradingLogsReq = () => {
    state.orderLogs = [];
    state.renderLogList = [];
    const szStartDate = moment(state.startDate).format('YYYYMMDD');
    const szEndDate = moment(state.endDate).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체
    // 0 = 국내, 1 = 해외, 2 = 국내/해외
    const isOversea = Common.eNation_Max;

    const szUserLog = `[${Common.g_szNationName[isOversea]} 거래내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.transactionListReq({
        futures, 
        isOversea, 
        szStartDate, 
        szEndDate,
    });
}

// 스탑로스내역 요청
const stopLossLogsReq = () => {
    state.stopLossLogs = [];
    state.renderSLLogsList = [];
    const szStartDate = moment(state.startDate).format('YYYYMMDD');
    const szEndDate = moment(state.endDate).format('YYYYMMDD');
    const futures = Common.G_FUTURES_MAX; // 전체

    const szUserLog = `[${Common.g_szFuturesNameMini[futures]} S/L내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);
    console.log(szUserLog)
    NetworkService.send.stopLossLogReq({
        futures, 
        szStartDate, 
        szEndDate,
    });
}

// 청산 / 취소
const onActionPress = (order: any) => {
    // 계정 비번 확인
    // if (!checkAccPwd()) {
    //     return;
    // }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderPrice = order.dbOrderPrice;
    const orderIdx = order.iOrderIdx;

    // 체결창
    if (state.tabIdx === Order_All &&order.iOppositeAmount > 0 ) {
        // 청산
        if (order.iOppositeAmount === 0) {
            store.setVisibleDialog({ message: '체결된 주문만 청산이 가능합니다.' });
            // console.log('체결된 주문만 청산이 가능합니다.');
            return;
        }

        // 청산주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_BatchSettle;
        const orderMethod = Common.C_ORDERMETHOD_SellBtn;
        const oppTradeType = (tradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const orderMax = store.AccountState.iOrderMax[futures][oppTradeType];
        if (orderMax < order.iOppositeAmount) {
            store.setVisibleDialog({ message: `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.` });
            // console.log(`주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.`);
            return;
        } else {
            UserLog.modifyOrder(futures, tradeType, orderPrice, order.iOppositeAmount, modifyMethod, orderMethod, orderIdx);
            NetworkService.send.modifyOrderReq({
                futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iOppositeAmount, orderMethod,
            });
        }
    } else if (state.tabIdx === Order_All && order.iLeftAmount > 0 ) {
        // 미체결창
        // 취소
        if (order.iLeftAmount === 0) {
            store.setVisibleDialog({ message: '미체결된 주문만 취소가 가능합니다.' });
            // console.log('미체결된 주문만 취소가 가능합니다.');
            return;
        }

        // 취소주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_Cancel;
        const orderMethod = Common.C_ORDERMETHOD_CancelBtn;
        UserLog.modifyOrder(futures, tradeType, orderPrice, order.iLeftAmount, modifyMethod, orderMethod, orderIdx);
        NetworkService.send.modifyOrderReq({
            futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iLeftAmount, orderMethod,
        });
    }
}
const slCancelBtn = (params: any) => {
    if (!NetworkService.socket) {
          NetworkService.printNoSocketError();
          return;
        }
        
        const { iSLReserved, iOrderIdx, iFutures, iTradeType, iOppositeAmount, iProfitTicks, iLossTicks, szState = '' } = params || {};
        if(params.iProfitTicks > 0 && params.iLossTicks >0){
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: 0,
            iLossTicks: 0,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else if(params.iProfitTicks > 0 && params.iLossTicks === 0) {
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: 0,
            iLossTicks: iLossTicks,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else if(params.iLossTicks > 0 && params.iProfitTicks === 0){
            const reqObj = {
            iSLReserved: 2,
            iOrderIdx: iOrderIdx,
            iFutures: iFutures,
            iTradeType: iTradeType,
            iOppositeAmount: iOppositeAmount,
            iProfitTicks: iProfitTicks,
            iLossTicks: 0,
            szState: szState,
            }
            NetworkService.printApiRequest(`send.stopLossReservedReq fetched: ${JSON.stringify(params)}`);
            NetworkService.socket.send(JSON.stringify({t: 'stopLossReservedReq', d: reqObj}));
        } else{
            // /
        }
}

const refreshOrders = () => {
    if(!state.bAccPassed) {
        return;
    }

    // 체결 리스트를 얻는다.
    const oppositeOrders = store.GetClientOppositeList();
    //const oppositeOrders = TEST_ORDERS;
    state.oppositeOrders = oppositeOrders;
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;
    state.leftOrders = leftOrders;

    renderOrders();
}

const renderOrders = () => {
    const orderIdx = state.tabIdx;
    if(orderIdx === Order_All){
        renderOpposite(state.oppositeOrders);
        renderLefts(state.leftOrders);
    } else if(orderIdx === Order_Contract){
        //체결
        renderOpposite(state.oppositeOrders)
    } else if(orderIdx === Order_Left){
        //미체결
        renderLefts(state.leftOrders)
    } else if(orderIdx === Order_Log) {
        //거래내역
        renderLogs(state.orderLogs)
    } else {
        //SL내역
        renderSLs(state.stopLossLogs);
    }
}

//체결 리스트
const renderOpposite = (orderList: any) => {
    state.renderOppositeList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);
        const objOrder = {
            order: order,
            futures: order.iFutures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            priceType:Common.g_szPriceTypeName[order.iPriceType],
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            orderIdx: order.iOrderIdx,
            amount: order.iOppositeAmount,
            profit: profit,
            fee: fee,
            stopTicks: order.bUseStop ? `${order.iStopTicks}` : '사용안함',
            lossTicks: order.bUseLoss ? `${order.iLossTicks}` : '사용안함',
            averagePrice: Utils.MicroPriceFormatStr(order.dbAvePrice, futures),
            // 2023-10-27: 당일 누적 금액으로 변경
            //net: profit - fee,
            //net: ' / ' + store.AccountState.iRealGainNLossFutures[futures] + ' / ' + store.AccountState.iDepoCommissionFutures[futures]
            //net: store.AccountState.iDepoCommissionFutures[futures]
            net: store.AccountState.iRealGainNLossFutures[futures] - store.AccountState.iDepoCommissionFutures[futures]
        }
        state.renderOppositeList.push(objOrder)
        // console.log('A', state.renderOppositeList)
    })
}

//미체결 리스트
const renderLefts = (orderList: any) => {
    state.renderLeftsList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const objOrder = {
            order: order,
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            priceType: Common.g_szPriceTypeName[order.iPriceType],
            orderIdx: order.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[Common.C_ORDERACCEPT_Acceptance],
            amount: order.iOrderAmount,
            oppAmount: order.iOppositeAmount,
            stopTicks: order.bUseStop ? `${order.iStopTicks}` : '사용안함',
            lossTicks: order.bUseLoss ? `${order.iLossTicks}` : '사용안함',
            averagePrice: Utils.MicroPriceFormatStr(order.dbAvePrice, futures),
        }
        state.renderLeftsList.push(objOrder)
        // console.log('B', state.renderLeftsList)
    })
}

//거래내역
const renderLogs = (orderLog: any) => {
    // TradingLog
    state.renderLogList = [];
    orderLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(log.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            priceType: Common.g_szPriceTypeName[log.iPriceType],
            orderIdx: log.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[log.iAcceptState],
            amount: log.iOrderAmount,
            contAmount: log.iContractedAmount,
            averagePrice: Utils.MicroPriceFormatStr(log.dbContractedPrice, futures),   // 평균
            contractDate: log.szContractedDate, // 체결시
            overTrading: log.szOverTrading,
            leverage: log.iLeverage,
            strProfit: toLocaleString(log.iProfit),
            fee: toLocaleString(log.iCommission),
            strTotalProfit: toLocaleString(log.iTotalProfit),
            evalProfit: toLocaleString(log.nEvaluateProfit),
            depoAmount: toLocaleString(log.iDepoAmount),
            profit: log.iProfit,
            totalProfit: log.iTotalProfit
        }
        state.renderLogList.push(objOrder)
    })
}

//SL내역
const renderSLs = (stopLossLog: any) => {
    // TradingLog
    state.renderSLLogsList = [];
    // console.log(stopLossLog);
    stopLossLog.forEach((log: any)=> {
        const futures = log.iFutures;
        // console.log('SLs',log)
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            state: log.szState,
            averagePrice: Utils.MicroPriceFormatStr(log.dbAvePrice, futures),   // 평균가,
            stopPrice: Utils.MicroPriceFormatStr(log.dbStopPrice, futures),   // 스탑가,
            contractPrice: Utils.MicroPriceFormatStr(log.dbContractPrice, futures),   // 체결가,
            stopTicks: log.bUseStop ? `${log.iStopTicks}` : '사용안함',
            stopColor: log.iUsedTick === 1 ? Theme.palette.red : Theme.text.color,
            stopWeight: log.iUsedTick === 1 ? 'bold' : 'normal',
            lossTicks: log.bUseLoss ? `${log.iLossTicks}` : '사용안함',
            lossColor: log.iUsedTick === 2 ? Theme.palette.red : Theme.text.color,
            lossWeight: log.iUsedTick === 2 ? 'bold' : 'normal',
            slName: log.bReserved ? 'S/L' : '',
            orderIdx: log.iOrderIdx >= 0 ? `${log.iOrderIdx}` : '',
            regDate: log.szRegDate, // 등록시간,
            useDate: log.szUseDate, // 완료시긴,
        }
        state.renderSLLogsList.push(objOrder)
    });
}


const viewValue = (e:any) => {
    console.log(e.target.value)
}

const emit = defineEmits(["reqCheckAccPassword"]);

const reqCheckAccPassword = () =>{
    emit('reqCheckAccPassword');
}

</script>
