import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio } from "vue"
import _imports_0 from '../../assets/images/header-back.png'


const _hoisted_1 = {
  key: 0,
  class: "popupSet"
}
const _hoisted_2 = { class: "wrap changePopup" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "setPassword innerHeight Setting changePassword" }
const _hoisted_5 = { class: "top-box" }
const _hoisted_6 = { class: "bank-account" }
const _hoisted_7 = { class: "dropdown-sort" }
const _hoisted_8 = { class: "select-sort" }
const _hoisted_9 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_10 = { class: "out" }
const _hoisted_11 = { class: "section-sort" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "bank-password" }
const _hoisted_14 = { class: "btn-box" }
const _hoisted_15 = {
  key: 0,
  class: "menu-box"
}
const _hoisted_16 = ["id", "onChange"]
const _hoisted_17 = ["for"]
const _hoisted_18 = {
  key: 1,
  class: "save-box"
}
const _hoisted_19 = { class: "input-box" }
const _hoisted_20 = { class: "right" }
const _hoisted_21 = { class: "input-box mt10" }
const _hoisted_22 = { class: "right" }
const _hoisted_23 = { class: "radio-wrap" }
const _hoisted_24 = { class: "label-radio first" }
const _hoisted_25 = { class: "label-radio" }
const _hoisted_26 = { class: "btn-box" }

import useBaseComponent from "../../baseComponent";
import { NetworkService, Emit_AccountPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePassword',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
},
  emits: ["close", "ok"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
const props = __props;


let state = reactive({
    accountHeaderChecked: 1,
    accountArray: [
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
            arrayIdx: 0,
        },
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
            arrayIdx: 1,
        }
    ],

    inputPassword: '',

    passwordUpdateType:0,
    inputNewPassword1: '',
    inputNewPassword2: '',

    isPasswordChecked: false,



    templateIdx:0,
    inputRoot:"",
    screenOnCheck:false,//프로그램시작시 계좌비밀번호 일괄저장 화면 띄우기
    savePassword:false,

    exList2: [
        {
            string: "해외예탁금",
            number: "94,225,626",
        },
        {
            string: "계좌번호",
            number: "102,225,626",
        },
        {
            string: "해외평가예탁금",
            number: "102,225,626",
        },
        {
            string: "해외평가예탁금",
            number: "102,225,626",
        },
    ],
    selectChange:0,
    accountName:[
        { value: 0, text: "02-13322-31 [김병목 / durkts123] (국내)" },
    ],
    selectedAccountNameIdx:0,
    //example
    pickOpen: false,
    pickDesc: '계좌를 선택하세요',
    pickValue: '',
    pickItems: [] as any,

    accPwdApply: null as any,
})

onMounted(() => {
    NetworkService.emitter.on(Emit_AccountPasswordUpdate, event_AccountPasswordResult );

    state.accountArray[0].id = store.AccountState.szDepoAccNo;
    state.accountArray[1].id = store.AccountState.szDepoAccNoOversea;
    state.accountArray[0].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Domestic]})`;
    state.accountArray[1].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Oversea]})`;
    state.accountArray[0].pwd = store.AccountState.szDomesticAccPwd ? "****" : "";
    state.accountArray[1].pwd = store.AccountState.szOverseaAccPwd ? "****" : "";
    
    state.accPwdApply = store.SettingsState.settings.accPwdApply;
 });
onUnmounted(()=> { NetworkService.emitter.off(Emit_AccountPasswordUpdate, event_AccountPasswordResult ); })
const event_AccountPasswordResult = (params: any) => {
    const { nResult, szMsg } = params;
    if(nResult=== NetConstants.R_CHNGPWD_SUCCESS) {
        close();
    }
    else {
        // PacketService 에서 처리중
    }
}; 

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const onSearch = () => {
    let errorMessage = '';
    let isChecked = false;
    if(state.pickValue + '' === '0') isChecked = store.AccountState.szDomesticAccPwd === '' || state.inputPassword === store.AccountState.szDomesticAccPwd;
    if(state.pickValue + '' === '1') isChecked = store.AccountState.szOverseaAccPwd === '' || state.inputPassword === store.AccountState.szOverseaAccPwd;;

    state.isPasswordChecked = isChecked;

    if( ! isChecked) {
        if( ! state.inputPassword) {
            errorMessage = '비밀번호를 입력해주세요.';
        }
        else {
            errorMessage = '비밀번호가 일치하지 않습니다.';
        }
    }
    
    if(errorMessage)
        alert(errorMessage);
}

const toggleSelect = () => {
    //
}

const emit = __emit;

const close = () => { 
    state.inputPassword = '';
    state.inputNewPassword1 = '';
    state.inputNewPassword2 = '';
    state.isPasswordChecked = false;
    state.pickValue = '';
    emit("close"); 
};
const ok = () => { 
    let errorMessage = '';
    if( !state.inputNewPassword1 || !state.inputNewPassword2) {
        errorMessage = '비밀번호를 입력해주세요';
    }
    else if(state.inputNewPassword1 != state.inputNewPassword2) {
        errorMessage = '비밀번호가 일치하지 않습니다.';
    }

    if(errorMessage)
        alert(errorMessage);
    else
        NetworkService.send.newAccPwdReq({ DomesticPwd: state.inputNewPassword1, OverseaPwd: state.inputNewPassword1 });
};

const onPickChangeValue = (item:any) => {
    state.inputPassword = '';
    state.pickOpen = false;
    state.pickDesc = item.name;
    state.pickValue = item.arrayIdx;
}


return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: _normalizeClass(["back-btn", { 'on': props.type === 'left' }]),
              src: _imports_0,
              alt: "",
              onClick: close
            }, null, 2),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, "계좌비밀번호 변경", -1))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      class: "drop-sort",
                      type: "checkbox",
                      id: "dropPageCount",
                      name: "dropPageCount",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickOpen) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, _unref(state).pickOpen]
                    ]),
                    _createElementVNode("label", _hoisted_9, [
                      _createElementVNode("p", _hoisted_10, [
                        _createTextVNode(_toDisplayString(_unref(state).pickDesc), 1),
                        _createElementVNode("i", {
                          class: _normalizeClass(["icon-down", [{ on: _unref(state).pickOpen }]])
                        }, null, 2)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).accountArray, (item, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          onClick: ($event: any) => (onPickChangeValue(item)),
                          class: "text"
                        }, _toDisplayString(item.name), 9, _hoisted_12))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  placeholder: "계좌비밀번호",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).inputPassword) = $event))
                }, null, 512), [
                  [_vModelText, _unref(state).inputPassword]
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("button", {
                  class: "inquire",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (onSearch()))
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("span", { class: "text" }, "조회", -1)
                ]))
              ])
            ]),
            (_unref(state).isPasswordChecked)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "title" }, "계좌비밀번호 일괄저장", -1)),
                  _createElementVNode("table", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", null, [
                          _createElementVNode("input", {
                            type: "checkbox",
                            id: "allCheck"
                          }),
                          _createElementVNode("label", { for: "allCheck" }, [
                            _createElementVNode("i", { class: "checkBox on" })
                          ])
                        ]),
                        _createElementVNode("th", null, "계좌번호"),
                        _createElementVNode("th", null, "계좌명"),
                        _createElementVNode("th", null, "계좌비밀번호")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).accountArray, (item, i) => {
                        return (_openBlock(), _createElementBlock("tr", { key: i }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              id: `no`+i,
                              onChange: ($event: any) => (onAccountChecked(i))
                            }, null, 40, _hoisted_16),
                            _createElementVNode("label", {
                              for: `no`+i
                            }, [
                              _createElementVNode("i", {
                                class: _normalizeClass(["checkBox", {'on': item.checked }])
                              }, null, 2)
                            ], 8, _hoisted_17)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(item.id), 1),
                          _createElementVNode("td", null, _toDisplayString(item.name), 1),
                          _createElementVNode("td", null, _toDisplayString(item.pwd), 1)
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_unref(state).isPasswordChecked)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "계좌비밀번호 변경")
                    ], -1)),
                    _createElementVNode("div", _hoisted_20, [
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        placeholder: "비밀번호를 입력해주세요",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).inputNewPassword1) = $event))
                      }, null, 512), [
                        [_vModelText, _unref(state).inputNewPassword1]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "계좌비밀번호 확인")
                    ], -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _withDirectives(_createElementVNode("input", {
                        type: "password",
                        placeholder: "비밀번호를 입력해주세요",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).inputNewPassword2) = $event))
                      }, null, 512), [
                        [_vModelText, _unref(state).inputNewPassword2]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("label", _hoisted_24, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        value: "0",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(state).selectChange) = $event)),
                        onChange: toggleSelect
                      }, null, 544), [
                        [_vModelRadio, _unref(state).selectChange]
                      ]),
                      _cache[14] || (_cache[14] = _createTextVNode(" 모든 계좌 변경 "))
                    ]),
                    _createElementVNode("label", _hoisted_25, [
                      _withDirectives(_createElementVNode("input", {
                        type: "radio",
                        value: "1",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(state).selectChange) = $event)),
                        onChange: toggleSelect,
                        disabled: ""
                      }, null, 544), [
                        [_vModelRadio, _unref(state).selectChange]
                      ]),
                      _cache[15] || (_cache[15] = _createTextVNode(" 선택 계좌 변경 "))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[18] || (_cache[18] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("p", { class: "caution" }, "*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.", -1)),
            _cache[20] || (_cache[20] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능 이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.", -1)),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("button", {
                class: "cancel",
                onClick: close
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("span", { class: "text" }, "취소", -1)
              ])),
              _createElementVNode("button", {
                class: "ok",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (ok    && ok   (...args)))
              }, _cache[17] || (_cache[17] = [
                _createElementVNode("span", { class: "text" }, "확인", -1)
              ]))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})