import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/header-back.png'


const _hoisted_1 = {
  key: 0,
  class: "popupSet"
}
const _hoisted_2 = { class: "wrap" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "value" }

import useBaseComponent from "../../baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PopupStockSearch',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
    initFuturesItems: {
        type: Object,
        default: () =>{
            return []
        }
    }
},
  emits: ["close", "onPickFuturesChangeValue"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();


const props = __props;


let state = reactive({
    
})

// onMounted(() => { });
// onUnmounted(()=> {  })


const emit = __emit;

const onPickFuturesChangeValue = (item: any) => {
    emit("onPickFuturesChangeValue", item);
    emit("close");
}

const close = () => { 
    emit("close"); 
};


return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: _normalizeClass(["back-btn", { 'on': props.type === 'left' }]),
              src: _imports_0,
              alt: "",
              onClick: close
            }, null, 2),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "종목검색", -1))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["innerHeight Setting Notice", {'scroll-area':_unref(route).name === `TotalOrder`}])
          }, [
            _createElementVNode("table", null, [
              _cache[1] || (_cache[1] = _createElementVNode("tr", null, [
                _createElementVNode("th", { class: "col-2 left" }, "종목코드"),
                _createElementVNode("th", { class: "col-8 right" }, "종목명")
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.initFuturesItems, (item, idx) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: idx,
                  onClick: ($event: any) => (onPickFuturesChangeValue(item))
                }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(item.value), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(item.label) + " (" + _toDisplayString(item.expiryDate) + ")", 1)
                  ])
                ], 8, _hoisted_4))
              }), 128))
            ])
          ], 2)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})