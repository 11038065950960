import * as Common from '../constants/Common';

// 스탑로스 틱설정
export const C_StopLossTick_Profit	= 0;
export const C_StopLossTick_Loss = 1;
export const C_StopLossTick_Max = 2;

// StopLoss 관련 데이터
export class StopLossObj {
  /*
  constructor(data) {
    if (data) {
      const {
        bUse = false,				// 사용여부
        iTradeType = 0,			// 매매구분
        dbOrderPrice = 0.0,	// 주문가격(평균가)
        iRow = 0,				// 주문 들어가기전 표시를 위해(평균가는 0.이다)
        bUseTick = new Array(C_StopLossTick_Max),			// 틱사용여부
        iTicks = new Array(C_StopLossTick_Max),				// 틱
        iLogIdx = 0,			// 로그번호
        drawLine = false,		// 라인을 그릴지 여부 
        bExam = false,	// 예시 여부(그리는 위치가 다름)
      } = data;

      this.bUse = bUse;
      this.iTradeType = iTradeType;
      this.dbOrderPrice = dbOrderPrice;
      this.iRow = iRow;
      this.bUseTick = bUseTick;
      this.iTicks = iTicks;
      this.iLogIdx = iLogIdx;
      this.drawLine = drawLine;
      this.bExam = bExam;
    }
  }
  */

  bUse = true;				// 사용여부
	iTradeType = 0;			// 매매구분
	dbOrderPrice = 0.0;	// 주문가격(평균가)
	iRow = 0;				// 주문 들어가기전 표시를 위해(평균가는 0.이다)
	bUseTick = new Array(C_StopLossTick_Max);			// 틱사용여부
	iTicks = new Array(C_StopLossTick_Max);				// 틱
	iLogIdx = 0;			// 로그번호
	drawLine = false;		// 라인을 그릴지 여부 
	bExam = false;	// 예시 여부(그리는 위치가 다름)
}

// StopLoss 로그
export class StopLossLog {
  iLogIdx = Common.INDEX_NONE;				// 로그코드
  iFutures = 0; // 선물종류
	iTradeType = 0;			// 매매구분
  szUse = '';   // 사용구분
	dbAvePrice = 0.0;	// 평균가
  dbStopPrice = 0.0;  // 스탑가격
  dbContractPrice = 0.0; // 체결가격
  bUseStop = 0; // 이익틱사용
  iStopTicks = 0;
  bUseLoss = 0; // 손실틱사용
  iLossTicks = 0;
  szState = '';
  szRegDate = ''; // 등록시간
  szUseDate = ''; // 사용시간
  bMobile = Common.cMedia_HTS;  
  bReserved = 0;  
  iOrderIdx = Common.INDEX_NONE;  // 주문번호
  // 사용된 틱
  // 1: 이익틱 사용, 2: 손실틱 사용
  iUsedTick = 0;
}
