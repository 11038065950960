import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popupPc"
}
const _hoisted_2 = { class: "popupPc-inner" }
const _hoisted_3 = { class: "popupSet" }
const _hoisted_4 = { class: "changePopup" }
const _hoisted_5 = { class: "setPassword Setting changePassword" }
const _hoisted_6 = { class: "top-box" }
const _hoisted_7 = { class: "bank-account" }
const _hoisted_8 = { class: "dropdown-sort" }
const _hoisted_9 = { class: "select-sort" }
const _hoisted_10 = {
  for: "accountInfo",
  class: "for-dropsort pageCount"
}
const _hoisted_11 = { class: "out" }
const _hoisted_12 = {
  key: 0,
  class: "section-sort"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "bank-password" }

import useBaseComponent from "../../baseComponent";
import { NetworkService, Emit_Success } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';
import * as AccPwd from '../../constants/AccPwd';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcAccountInfoPopup',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initFutures: {
        type: Number,
        default: Common.G_FUTURES_DAY
    },
    initBAccPAssed: {
        type: Boolean,
        default: false,
    },
},
  emits: ["close", "checkAccPwdSuccess"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive, watch } = useBaseComponent();
const props = __props;

let state = reactive({
    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    futures: Common.G_FUTURES_DAY,

    isLoadedPopup: false,
})

watch(props, ({isShowPopup, initFutures, initBAccPAssed}) => {
    
    //팝업 오픈 액션이 발생하면
    if(isShowPopup){

        //이미 패스워드 인증이 완료된 상태인지 체크
        if(initBAccPAssed){
            //이미 패스워드 인증된 상태라면 성공 응답을 보내고 팝업을 띄우지 않음
            checkAccPwdSuccess();
        } else {
            //패스워드 인증이되지 않은 상태라면
            state.futures = props.initFutures;
            state.bAccPassed = props.initBAccPAssed;
            //계좌 정보 세팅(드랍다운) 후 팝업 표시
            initAccPicker();
        }
    }
},{ deep: true });

// onMounted(() => {
//  });

// onUnmounted(()=> {
// })

// 계좌 선택 세팅
const initAccPicker = () => {
    const pickItems = [];
    // const futures = store.FuturesByNow();
    const futures = props.initFutures;
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
        // 비밀번호 인증 패스 여부
        state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {            
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }
        
        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    checkAccPwdSuccess();
}

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const emit = __emit;

const checkAccPwdSuccess = () => {
    emit("checkAccPwdSuccess");
}

const close = () => { 
    state.password = '';
    emit("close"); 
};


return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title-box flex-center-center" }, [
                  _createElementVNode("p", { class: "text" }, "계좌 비밀번호 입력")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _withDirectives(_createElementVNode("input", {
                          class: "drop-sort",
                          type: "checkbox",
                          id: "accountInfo",
                          name: "accountInfo",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickAccOpen) = $event))
                        }, null, 512), [
                          [_vModelCheckbox, _unref(state).pickAccOpen]
                        ]),
                        _createElementVNode("label", _hoisted_10, [
                          _createElementVNode("p", _hoisted_11, [
                            _createTextVNode(_toDisplayString(_unref(state).pickAccDesc), 1),
                            _createElementVNode("i", {
                              class: _normalizeClass(["icon-down", [{ on: _unref(state).pickAccOpen }]])
                            }, null, 2)
                          ])
                        ]),
                        (_unref(state).pickAccValue)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickAccItems, (item, index) => {
                                return (_openBlock(), _createElementBlock("p", {
                                  key: index,
                                  onClick: ($event: any) => (onPickAccChangeValue(item)),
                                  class: _normalizeClass(["text", { active: item.value === _unref(state).pickAccValue }])
                                }, _toDisplayString(item.desc), 11, _hoisted_13))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      type: "password",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).password) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(state).password]
                    ])
                  ])
                ]),
                _createElementVNode("div", { class: "btn-box" }, [
                  _createElementVNode("button", {
                    class: "cancel",
                    onClick: close
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "text" }, "취소", -1)
                  ])),
                  _createElementVNode("button", {
                    class: "ok",
                    onClick: checkAccPwd
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("span", { class: "text" }, "확인", -1)
                  ]))
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})