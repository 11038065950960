import axios, { AxiosInstance } from "axios";
// import {
//     IParamDepositAction, 
//     IParamDepositCalc, 
//     IParamSwapAction, 
//     IParamSwapCalc,
//     IParamSwapList,
//     IParamSwapCheck,

//     IResConfigAddressInfo,
//     IResConfigSwapInfo,
//     IResConfigTokenInfo,
//     IResSwapCheck,
//     IResSwapList,
// } from "../interface";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Pragma: "no-cache",
    },
});

const axiosCommonResponse = (url: string, data?: any) => {
    return new Promise<any>((resolve, reject) => {
        axiosInstance
            .post(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const axiosMethods = {
    // //주소 정보
    // getConfigAddressInfo(): Promise<IResConfigAddressInfo> {
    //     return axiosCommonResponse("/front/config/address/info");
    // },
    // //스왑 정보
    // getConfigSwapInfo(): Promise<IResConfigSwapInfo> {
    //     return axiosCommonResponse("/front/config/swap/info");
    // },
    // //코인 정보
    // getConfigTokenInfo(): Promise<IResConfigTokenInfo> {
    //     return axiosCommonResponse("/front/config/token/info");
    // },
    // //입금 요청
    // depositAction(param: IParamDepositAction): Promise<any> {
    //     return axiosCommonResponse("/front/deposit/action", param);
    // },
    // //입금 시세확인
    // depositCalc(param: IParamDepositCalc): Promise<any> {
    //     return axiosCommonResponse("/front/deposit/calc", param);
    // },
    // //swap 요청
    // swapAction(param: IParamSwapAction): Promise<any> {
    //     return axiosCommonResponse("/front/swap/action", param);
    // },
    // //swap 시세확인
    // swapCalc(param: IParamSwapCalc): Promise<any> {
    //     return axiosCommonResponse("/front/swap/calc", param);
    // },
    // //일일 swap 상태 조회
    // swapCheck(param: IParamSwapCheck): Promise<IResSwapCheck> {
    //     return axiosCommonResponse("/front/swap/check", param);
    // },
    // //swap 리스트
    // swapList(param: IParamSwapList): Promise<IResSwapList> {
    //     return axiosCommonResponse("/front/swap/list", param);
    // },
};

export default { axiosInstance, axiosMethods };
