import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "wrapPC" }
const _hoisted_2 = { class: "body-box subPage overNight transactionForm" }
const _hoisted_3 = { class: "con-box" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "designBox" }
const _hoisted_6 = { class: "flex-center-between border-box" }
const _hoisted_7 = { class: "mid flex-center-between account" }
const _hoisted_8 = { class: "big-box" }
const _hoisted_9 = { class: "top flex-center-between m-0" }
const _hoisted_10 = { class: "bank-account" }
const _hoisted_11 = { class: "dropdown-sort" }
const _hoisted_12 = { class: "select-sort" }
const _hoisted_13 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_14 = { class: "out" }
const _hoisted_15 = { class: "section-sort" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "p-10" }
const _hoisted_18 = { class: "card-box flex-center-between" }
const _hoisted_19 = { class: "value-box" }
const _hoisted_20 = { class: "text-box" }
const _hoisted_21 = { class: "text flex-center-between" }
const _hoisted_22 = { class: "text flex-center-between" }
const _hoisted_23 = { class: "mid under" }
const _hoisted_24 = { class: "scroll-wrap" }
const _hoisted_25 = { class: "table-box" }
const _hoisted_26 = { class: "item name" }
const _hoisted_27 = { class: "text" }
const _hoisted_28 = { class: "item event" }
const _hoisted_29 = { class: "text red blue" }
const _hoisted_30 = { class: "item event" }
const _hoisted_31 = { class: "text" }
const _hoisted_32 = { class: "item price" }
const _hoisted_33 = { class: "text" }
const _hoisted_34 = { class: "item price" }
const _hoisted_35 = { class: "item price" }
const _hoisted_36 = { class: "item status" }
const _hoisted_37 = ["onClick"]

import useBaseComponent from "@/baseComponent";
import * as Common from '@/constants/Common';
import { toLocaleString } from '@/utils/number';
import { NetworkService, Emit_DrawTrans, Emit_Success } from '@/network';
import { UserLog } from '@/network/UserLog';
import * as Utils from '@/utils/Common';
import { OvernightReq } from '@/models';
import * as AccPwd from '@/constants/AccPwd';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcOverNight',
  setup(__props) {

const { router, store, onMounted, onUnmounted, reactive, watch } = useBaseComponent();


// import { Theme } from '../constants';
// import i18n from '../services/i18n';
let state = reactive({
    btnType:false,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    selectedItem: null as any,
    overnights: [] as any,
    isOversea: Common.eNation_Domestic,

    renderOvernightList: [] as any,

    // 계좌 정보 세팅
    timerInitData: null as any,
})

onMounted(()=>{
	
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('OverNight');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);


    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

// watch(props, (isShowPopup) => {
//     if (isShowPopup) {
//         initLiveAlarm();
//     } else {
//         clearLiveAlarm();
//     }
// },{ deep: true });

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 오버나잇 리스트 업데이트
    // console.log('event_DrawTrans 수신');
    refreshOrders();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS;
    state.isOversea = isOversea ? 1 : 0;

    initAccPicker();
    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }
    // onSearchPress();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.isOversea = item.bOversea;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const checkAccPwd = () => {
    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const onSearchPress = () => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const selectedItem = state.selectedItem;

    if (selectedItem) {
        state.isOversea = selectedItem.bOversea;

        // 오버나잇 리스트 업데이트
        refreshOrders();
    }

    // const message = '오버나잇(체결주문)을 조회하였습니다.';
    // store.setVisibleToast({message: message, duration: 1200})
}

const refreshOrders = () => {
    // 체결 리스트를 얻는다.
    const overnights = store.GetClientOppositeList();
    //const overnights = TEST_ORDERS;
    state.overnights = overnights;
    renderOvernight();
}

// 오버나잇 / 취소
const onActionPress = (order: any, bCancel: any) => {
     // 계정 비번 확인
     if (!checkAccPwd()) {
        return;
    }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderIdx = order.iOrderIdx;
    const ovState = order.iOvernightState;

    let overnight = new OvernightReq();
    overnight.iOrderIdx = orderIdx;
    overnight.iFutures = futures;
    overnight.iTradeType = tradeType;
    overnight.iOppositeAmount = order.iOppositeAmount;
    overnight.iOverTrading = Common.eOverTrading_Overnight;

    // 오버나잇
    if (!bCancel) {

        if (order.iOppositeAmount === 0 || ovState !== Common.eOvernight_No) {
            store.setVisibleDialog({ message: '오버나잇 신청이 불가능한 주문입니다.' });
            // console.log('오버나잇 신청이 불가능한 주문입니다.');
            return;
        }

        // 오버나잇 신청주문을 보낸다.
        overnight.iOvernightState = Common.eOvernight_Request;
        // OvernightReq list
        let reqList = [
            overnight
        ];

        UserLog.overnight(reqList);
        NetworkService.send.overnightReq({
            reqList,
        });

    } else {

        // 취소
        if (order.iOppositeAmount === 0 || ovState !== Common.eOvernight_Request) {
            store.setVisibleDialog({ message: '오버나잇 취소가 불가능한 주문입니다.' });
            // console.log('오버나잇 취소가 불가능한 주문입니다.');
            return;
        }

        // 오버나잇 신청주문을 보낸다.
        overnight.iOvernightState = Common.eOvernight_Cancel;
        // OvernightReq list
        let reqList = [
            overnight
        ];
        UserLog.overnight(reqList);
        NetworkService.send.overnightReq({
            reqList,
        });
    }
}

const renderOvernight = () => {

    state.renderOvernightList = [];
    state.overnights.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);

        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            amount: order.iOppositeAmount,
            iFutures: order.iFutures,
            iOrderIdx: order.iOrderIdx,
            iTradeType: order.iTradeType,
            iOppositeAmount: order.iOppositeAmount,
            iOvernightState: order.iOvernightState,
            profit:profit,
            fee: fee,
            net: profit - fee,
            ovState: order.iOvernightState,
            bOvernight: order.iOvernightState === Common.eOvernight_Request,
            bRequest: order.iOvernightState === Common.eOvernight_No,
        }
        state.renderOvernightList.push(objOrder)
    })
}


return (_ctx: any,_cache: any) => {
  const _component_PcHeader = _resolveComponent("PcHeader")!
  const _component_PcStockList = _resolveComponent("PcStockList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PcHeader),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[2] || (_cache[2] = _createElementVNode("ul", { class: "select-tab bdbn m-0" }, [
                _createElementVNode("li", { class: "tab-item title" }, "오버나잇")
              ], -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _withDirectives(_createElementVNode("input", {
                            class: "drop-sort",
                            type: "checkbox",
                            id: "dropPageCount",
                            name: "dropPageCount",
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickAccOpen) = $event))
                          }, null, 512), [
                            [_vModelCheckbox, _unref(state).pickAccOpen]
                          ]),
                          _createElementVNode("label", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, [
                              _createTextVNode(_toDisplayString(_unref(state).pickAccDesc), 1),
                              _createElementVNode("i", {
                                class: _normalizeClass(["icon-down", [{ on: _unref(state).pickAccOpen }]])
                              }, null, 2)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickAccItems, (item, index) => {
                              return (_openBlock(), _createElementBlock("p", {
                                key: index,
                                onClick: ($event: any) => (onPickAccChangeValue(item)),
                                class: _normalizeClass(["text", { active: item.value === _unref(state).pickAccValue }])
                              }, _toDisplayString(item.desc), 11, _hoisted_16))
                            }), 128))
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("button", {
                      class: "look-up",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (checkAccPwd()))
                    }, "조회")
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "name-box" }, [
                  _createElementVNode("p", { class: "text" }, "오버나잇 가능 기준 (담보금액)")
                ], -1)),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("p", _hoisted_21, [
                      _cache[3] || (_cache[3] = _createTextVNode("국내선물:")),
                      _createElementVNode("span", null, _toDisplayString(_unref(toLocaleString)(_unref(store).BrandState.iDomesticOvernightDeposit)) + "원", 1)
                    ]),
                    _createElementVNode("p", _hoisted_22, [
                      _cache[4] || (_cache[4] = _createTextVNode("해외선물:")),
                      _createElementVNode("span", null, _toDisplayString(_unref(toLocaleString)(_unref(store).BrandState.iOverseaOvernightDeposit)) + "원", 1)
                    ])
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "caution flex-center-end" }, "*보다 자세한 사항은 공지사항을 통해 확인해주십시오.", -1))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "title-box flex-center-between" }, [
                _createElementVNode("p", { class: "title" }, "세부내용")
              ], -1)),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"table-head flex-center\"><div class=\"item name\"><p class=\"text\">거래종목</p></div><div class=\"item event\"><p class=\"text\">매매구분</p></div><div class=\"item event\"><p class=\"text\">보유체결</p></div><div class=\"item price\"><p class=\"text\">평균단가</p></div><div class=\"item price\"><p class=\"text\">평가손익</p></div><div class=\"item price\"><p class=\"text\">실현손익</p></div><div class=\"item status\"><p class=\"text\">OV</p></div></div>", 1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderOvernightList, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "table-body flex-center",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("p", _hoisted_27, _toDisplayString(item.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("p", _hoisted_29, _toDisplayString(item.tradeType), 1)
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("p", _hoisted_31, _toDisplayString(item.amount), 1)
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("p", _hoisted_33, _toDisplayString(item.price), 1)
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["text", [{ up: item.profit > 0}, {down: item.profit < 0}]])
                        }, _toDisplayString(_unref(toLocaleString)(item.profit)), 3)
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createElementVNode("p", {
                          class: _normalizeClass(["text", [{ up: item.net > 0}, {down: item.net < 0}]])
                        }, _toDisplayString(_unref(toLocaleString)(item.net)), 3)
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["settlement", {'off':item.bOvernight}]),
                          onClick: ($event: any) => (onActionPress(item, item.bOvernight))
                        }, _toDisplayString(item.bOvernight ? '취소' : item.bRequest ? '오버나잇' : Common.g_szOvernightStateName[item.ovState]), 11, _hoisted_37)
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _createVNode(_component_PcStockList)
      ])
    ])
  ]))
}
}

})