import * as Common from '../constants/Common';

export class TradingLog {
	iLogIndex = Common.INDEX_NONE;
	iOrderIdx = Common.INDEX_NONE;			// 주문번호
	iOrgOrderIdx = Common.INDEX_NONE;			// 원주문번호
	szOrderDate = '';	// 주문일시 YYYY-MM-DD hh:mm:ss
	szContractedDate = '';	// 체결일시 YYYY-MM-DD hh:mm:ss
	iFutures = 0;			// 선물종류
	iTradeType = 0;			// 매매구분
	iPriceType = 0;			// 가격유형
	szOrderMethod = '';		// 주문방법
	dbOrderPrice = 0.;	// 주문가격
	iOrderAmount = 0;		// 주문수량
	dbContractedPrice = 0.;	// 체결가격
	iContractedAmount = 0;	// 체결 수량
	iLeftAmount = 0;		// 미체결 수량
	iOppositeAmount = 0;		// 잔고수량
	iCommission = 0;			// 수수료
	iLeverage = 0;			// 레버리지
	iAcceptState = 0;		// 접수상태
	iProfit = 0;  // 평가손익
	iTotalProfit = 0;   // 실현손익
	iDepoAmount = 0;
	nEvaluateProfit = 0;  // 평가금액
	dbBuyPrice1 = 0.;
	dbSellPrice1 = 0.;
	dbCurPrice = 0.;
	szOvernightState = '';
	szOverTrading = '';
	szMemo = '';
}