import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popup confirm two"
}
const _hoisted_2 = { class: "popup-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex" }

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PopupTwoButton',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    text: {
        type: String,
        default: "",
    },
},
  emits: ["close", "ok"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = __props;

const emit = __emit;

const ok = () => {
    emit("ok");
};

const close = () => {
    emit("close");
};

return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", {
            innerHTML: props.text,
            class: "confirm-text"
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "cancel",
              onClick: close
            }, "취소"),
            _createElementVNode("button", {
              onClick: ok,
              class: _normalizeClass({'ok':_unref(route).name === 'Setting'})
            }, "확인", 2)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})