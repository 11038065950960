import * as Common from '../constants/Common';

// 체결 데이터
export class ContractObj {
  constructor(data: any) {
    if (data) {
      const {
        iFutures = Common.G_FUTURES_MAX,
        szCTime = '', //체결시간 00:00:00
        dbCurPrice = 0., //현재가
        iTrdq = 0, //체결수량
        szCGubun = '', //체결구분
        dbOpenPrice = 0., //시가
        dbHighPrice = 0., //고가
        dbLowPrice = 0., //저가
        dbYDiffPrice = 0., //전일대비
        szYDiffSign = '', //전일대비 기호
        dbChgRate = 0., // 등락율
        openyak = 0, //미결제약정 수량
        k200jisu = 0., //KOSPI200
        sbasis = 0., //BASIS
        openyakcha = 0, //미결제약정증감
        totq = 0, // 누적체결수량
        dbBPrice = 0., //기준가
        ovsmkend = '', // 장마감일
      
        // 차트용으로 추가
        szShortCode = '',	// 단축 코드
        szOrgTime = '', //체결시간(현지) hhmmss
        szOrgDate = '', //체결날짜(현지) yyyyMMdd
      } = data;

      this.iFutures = iFutures;
      this.szCTime = szCTime;
      this.dbCurPrice = dbCurPrice;
      this.iTrdq = iTrdq;
      this.szCGubun = szCGubun;
      this.dbOpenPrice = dbOpenPrice;
      this.dbHighPrice = dbHighPrice;
      this.dbLowPrice = dbLowPrice;
      this.dbYDiffPrice = dbYDiffPrice;
      this.szYDiffSign = szYDiffSign;
      this.dbChgRate = dbChgRate;
      this.openyak = openyak;
      this.k200jisu = k200jisu;
      this.sbasis = sbasis;
      this.openyakcha = openyakcha;
      this.totq = totq;
      this.dbBPrice = dbBPrice;
      this.ovsmkend = ovsmkend;
      this.szShortCode = szShortCode;
      this.szOrgTime = szOrgTime;
      this.szOrgDate = szOrgDate;
    }
  }

	iFutures = Common.G_FUTURES_MAX;
	szCTime = ''; //체결시간 00:00:00
	dbCurPrice = 0.; //현재가
	iTrdq = 0; //체결수량
	szCGubun = ''; //체결구분
	dbOpenPrice = 0.; //시가
	dbHighPrice = 0.; //고가
	dbLowPrice = 0.; //저가
	dbYDiffPrice = 0.; //전일대비
	szYDiffSign = ''; //전일대비 기호
	dbChgRate = 0.; // 등락율
	openyak = 0; //미결제약정 수량
	k200jisu = 0.; //KOSPI200
	sbasis = 0.; //BASIS
	openyakcha = 0; //미결제약정증감
	totq = 0; // 누적체결수량
	dbBPrice = 0.; //기준가
	ovsmkend = ''; // 장마감일

	// 차트용으로 추가
	szShortCode = '';	// 단축 코드
	szOrgTime = ''; //체결시간(현지) hhmmss
	szOrgDate = ''; //체결날짜(현지) yyyyMMdd

  fromMaster(Futures: any, masterObj: any) {
    this.iFutures = Futures;
    this.openyak = masterObj.openyak;
    this.k200jisu = masterObj.k200jisu;
    this.sbasis = masterObj.sbasis;
    this.openyakcha = masterObj.openyakcha;
    
    this.szCTime = masterObj.szCTime[Futures];
    this.dbCurPrice = masterObj.dbCurPrice[Futures];
    this.iTrdq = masterObj.iTrdq[Futures];
    this.szCGubun = masterObj.szCGubun[Futures];
    this.dbOpenPrice = masterObj.dbOpenPrice[Futures];
    this.dbHighPrice = masterObj.dbHighPrice[Futures];
    this.dbLowPrice = masterObj.dbLowPrice[Futures];
    this.dbYDiffPrice = masterObj.dbYDiffPrice[Futures];
    this.szYDiffSign = masterObj.szYDiffSign[Futures];
    this.dbChgRate = masterObj.dbChgRate[Futures];
    this.totq = masterObj.totq[Futures];
    this.dbBPrice = masterObj.dbBPrice[Futures];
    this.ovsmkend = masterObj.ovsmkend[Futures];
    
    this.szOrgTime = masterObj.szOrgTime[Futures];
    this.szOrgDate = masterObj.szOrgDate[Futures];
  }
}
  