import EventEmitter from 'eventemitter3';

// 브라우저 중복 로그인
export const Emit_ResetMoveToLogin = 'reset_move_to_login';
// 로그인 실패
export const Emit_LoginFailed = 'failed';
// 성공
export const Emit_Success = 'success';
// 네트원크 연결 종료
export const Emit_Disconnected = 'disconnected';
// 네트원크 오류
export const Emit_SocketError = 'socket_error';

// 체결
export const Emit_ContractData = 'contract_data';
// 호가
export const Emit_CallData = 'call_data';
// 주문의 실시간 평가, 손실 등을 업데이트
export const Emit_DrawTransProfit = 'draw_trans_profit';
// 입출금: 금액창 재설정
export const Emit_ResetAmount = 'reset_amount';
// 주문을 새로 그린다.(주문 리스트)
export const Emit_DrawTrans = 'draw_trans';
// 거래로그 완료
export const Emit_TradingLog = 'trading_log';
// 공지 내용
export const Emit_NoticeContent = 'notice_content';
// 계정정보
export const Emit_AccInfo = 'acc_info';
// 푸시 로그
export const Emit_DialogLog = 'dialog_log';
// 개별설정 
export const Emit_UserOption = 'user_option';
// 챗팅
export const Emit_Chat = 'chat';
// 차트 데이터
export const Emit_ChartData = 'chartData';
// 입출금로그 완료
export const Emit_TransferLog = 'transfer_log';
// 스탑로스로그 완료
export const Emit_StopLossLog = 'stoploss_log';


// 로그인 패스워드 변경
export const Emit_LoginPasswordUpdate = 'pwd_login_update';
// 계좌 패스워드 변경
export const Emit_AccountPasswordUpdate = 'pwd_account_update';

export default class NetworkEmitter extends EventEmitter {
}