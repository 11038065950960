import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "popup confirm",
  id: "app-popup"
}
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = ["innerHTML"]

import useBaseComponent from "@/baseComponent";
import { Emit_Chat, Emit_Success, Emit_LoginFailed, Emit_Disconnected, Emit_SocketError, Emit_ResetMoveToLogin, Emit_AccInfo, NetworkService } from "@/network"
import * as AccPwd from './constants/AccPwd';
import { ChatRoomObj, ChatMsg, eChatEvent_Init, eChatEvent_Message, eChatEvent_Counselor, eChatEvent_Transfer, eChatEvent_Logs} from './models';
import { Theme } from './constants';
import moment from './utils/moment';
import { DEVICE_TYPE_MOBILE } from "./constants/Global";
import * as Common from '@/utils/Common';
import * as Constant from "./constants/Global";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive, store, watch,openPopupOk,closePopup,onUnmounted} = useBaseComponent();
onMounted(() => {
    setDeviceTypeStore();
    window.addEventListener('resize', checkFoldState);
    NetworkService.emitter.on(Emit_Success, event_LoginSuccess );
    NetworkService.emitter.on(Emit_AccInfo, event_AccInfo);
    NetworkService.emitter.on(Emit_LoginFailed, event_LoginFailed );
    NetworkService.emitter.on(Emit_Disconnected, event_NetworkDisconnected );
    NetworkService.emitter.on(Emit_SocketError, event_NetworkError );
    NetworkService.emitter.on(Emit_ResetMoveToLogin, event_ResetMoveToLogin );
    NetworkService.emitter.on(Emit_Chat, event_Chat);

    modeSelect();

    let scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', '/customCall.js');
    document.head.appendChild(scriptElement);

    store.loadLoginPageInfo();
    store.loadLoginPageError();
    
    initSystem();

    setTimeout(() => {
            connectWebSocket();
        }, 300);
});

onUnmounted(()=> {
    window.removeEventListener('resize', checkFoldState);
    // window.removeEventListener('webkitflex', resizeEnded);
})

const setDeviceTypeStore = ()=> {
    // store.CommonState.resizeDeviceType = Number(Common.GetDeviceType);
    const deviceType = Common.GetDeviceType();
    store.setResizeDeviceType(deviceType);
}

const checkFoldState = () => {
    const deviceType = Common.GetDeviceType();
    if(store.CommonState.resizeDeviceType === deviceType){
        // alert(store.CommonState.resizeDeviceType);
        return;
    } else {
        // alert(store.CommonState.resizeDeviceType);
        //router.go(0);
        
        const lastViewPage = store.getLastViewPage();

        if(lastViewPage){
            if(deviceType === DEVICE_TYPE_MOBILE){
                switch(lastViewPage){
                    case 'TotalOrder':
                    case 'TransactionForm':
                    case 'TradeList':
                    case 'OverNight':
                        location.reload();
                        break;
                    default:
                        store.setLastViewPage('TotalOrder');
                        location.reload();
                        break;
                }
            } else {
                switch(lastViewPage){
                    case 'TotalOrder':
                    case 'TransactionForm':
                    case 'TradeList':
                    case 'OverNight':
                        location.reload();
                        break;
                    default:
                        store.setLastViewPage('TotalOrder');
                        location.reload();
                        break;
                }
            }
        } else {
            location.reload();
        }
    }
}

watch(store.CommonState, (newCommonState) => {
    if(window.innerWidth > 768) {
        if (newCommonState.themeMode === 'dark') {
            document.body.style.backgroundColor = '#191c23';
        } else {
            document.body.style.backgroundColor = '#ededed';
        }
    } else {
        if (newCommonState.themeMode === 'dark') {
            document.body.style.backgroundColor = '#191c23';
        } else {
            document.body.style.backgroundColor = 'white';
        }
    }
},{ deep: true });

const modeSelect = () => {
    if(window.innerWidth >768){
        if(store.CommonState.themeMode === 'dark'){
            document.body.style.backgroundColor = '#191c23';
        } else {
            document.body.style.backgroundColor = '#ededed';
        }
    } else {
        if(store.CommonState.themeMode === 'dark'){
            document.body.style.backgroundColor = '#191c23';
        } else {
            document.body.style.backgroundColor = 'white';
        }
    }
}

const initSystem = async () => { 
    await store.getItems();

    await Promise.all([        
        store.getSettings(), 
        store.getSettingsEx()
    ]);

    store.SystemState.storeInitailized = true;

    // 초기화가 안되있으면 새로게 초기화한다. - 버전 업그레이드로 인한
    // ??????
    //await store.initializeSettingsEx(false);
    
    if ( ! store.SystemState._initialized) {    
        await store.initializeItems();
    }
}

const closeWebSocket = () => { NetworkService.destorySocket(); }
const connectWebSocket = async () => {
    const options = {}
    const loginInfo = {}
    NetworkService.connect(options,loginInfo,true);
}


const event_ResetMoveToLogin = () => {
    // 로그인 페이지 아닐경우만
    const lastViewPage = store.getLastViewPage();
    if( ! lastViewPage || lastViewPage !== ''){
        router.push('/');
    }
}

const event_LoginFailed = (data: any) => {
    // 하는게 동일함...
    event_ResetMoveToLogin();
};

const event_LoginSuccess = (params: any) => {

    const lastViewPage = store.getLastViewPage();
    if (store.debugLog >= Constant.DebugLevel_Minimal)
        console.log('[event_LoginSuccess]', params, lastViewPage);

    store.LoginPageState.isPacketLoginAckReceived = true;
    
    if(store.LoginPageState.loginFrom === "loginPage") {
        const szMsg = process.env.VUE_APP_BRAND_NAME?.toString() + ` 스피드주문 전문 처리를 시작합니다.`;
        store.LogMsg(szMsg);

        // 계좌 비밀번호 설정 체크 생략
        // const configState = Number(store.SettingsState.settings.accPwdApply);
        // if(configState === AccPwd.cAccPwdApply_OncePopup || (configState === AccPwd.cAccPwdApply_Forever && store.getDomesticAccPwd() === '')) {
        //     // 설정 팝업
        //     if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
        //         router.push('/m/Setting');
        //     } else {
        //         router.push('/TotalOrder')
        //     }
        // }
        // else {
            // 일반 로그인
            if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
                router.push('/m/Home');
            } else {
                router.push('/TotalOrder')
            }
        // }
    }
    else if(store.LoginPageState.loginFrom === "autoLogin" && lastViewPage) {
        if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
            router.push('/m/' + lastViewPage);    
        } else {
            switch(lastViewPage){
                case 'TotalOrder':
                case 'TransactionForm':
                case 'TradeList':
                case 'OverNight':
                case 'HelpDesk':
                    router.push('/' + lastViewPage);
                    break;
                default:
                    router.push('/TotalOrder');
                    break;
            }
        }
    }
    else {
        // 어느 페이지로 포워딩 해야될지는 다음에...
        // 스토어에 마지막 페이지 저장해놓고 거기로 재접속........
        if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
            router.push('/m/Home');
        } else {
            router.push('/TotalOrder')
        }
    }
}

const event_AccInfo = (params: any) => {
    store.LoginPageState.isPacketAccInfoReceived = true;
    NetworkService.emitter.off(Emit_AccInfo, event_AccInfo);
}

const event_NetworkDisconnected = (params: any) => {
    //const { systemStore } = this.props;
    
    if (store.debugLog >= Constant.DebugLevel_Minimal)
        console.log('event_NetworkDisconnected', params);
    NetworkService.destorySocket();

    const { options, loginInfo, bRecon = false, message = '' } = params;
    if (message) {
        const reconnecting = async () => {
            await NetworkService.connect(options, loginInfo, bRecon);
            if (store.debugLog >= Constant.DebugLevel_Minimal)
                console.log(`재연결`);
        }
        if (bRecon) {
            store.setVisibleToast({message: message})
            reconnecting();
        } else {
            const message = '서버와의 연결이 종료되었습니다. 네트워크 상태를 확인해주세요.';

            // 자동 로그인 플래그 off
            store.updateLoginPageState(0);
            store.updateLoginPageError(1, message);
            store.LoginPageState.isReloadRequired = true;

            store.setVisibleDialog({ message: message, callback: function() {
                NetworkService.emitter.emit(Emit_ResetMoveToLogin, { message });
            } });

        }
    }
}

const event_NetworkError = (params: any) => {
    if (store.debugLog >= Constant.DebugLevel_Minimal)
        console.error('event_NetworkError', params);
}

const event_Chat = (params: any) => {
    const { event } = params;
    const lastViewPage = store.getLastViewPage();
    const isHelpDesk = lastViewPage === 'HelpDesk';

    if (event === eChatEvent_Init) {
        //Init();
    } else if (event === eChatEvent_Counselor) {
        const { bEnter } = params;

        const lpChatRoom = store.GetChatRoomPtr();
        if (bEnter) {
            const message = '상담원이 입장하였습니다. 실시간 상담이 시작되었습니다.';
            store.setVisibleToast({message: message, duration: 1200})

            // 유저 강제 입장
            lpChatRoom.UserState = ChatRoomObj.eState_Enter;
            lpChatRoom.CounselorState = ChatRoomObj.eState_Enter;
            Message( ! isHelpDesk, ChatMsg.eType_System, message);
        } else {
            lpChatRoom.CounselorState = ChatRoomObj.eState_Leave;
            Message( ! isHelpDesk, ChatMsg.eType_System, '상담원이 퇴장하였습니다. 대화가 종료되었습니다.');
        }
    } else if (event === eChatEvent_Message) {
        const { szMessage, MsgType, MsgTime } = params;
        Message(isHelpDesk, MsgType, szMessage, MsgTime);
    } else if (event === eChatEvent_Transfer) {
        const { bSendToServer, lpChatTransfer } = params;
        // console.log(`채팅 파일이 전송되었다. 보냄:${bSendToServer}`);
    } else if (event === eChatEvent_Logs) {
        // 채팅 로그를 받았다.
    }

    // 2023-09-26: 메세지 받으면 상담센터로 강제 이동
    if( ! isHelpDesk) {
        if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
            router.push('/m/HelpDesk');
        } else {
            router.push('/HelpDesk')
        }
    }
}

const Message = (isHelpDesk: boolean, MsgType: number, szMessage: string, lpChatTime = null, szNick = null) => {
    let szText, szSpeaker;
    const chatTime = lpChatTime ? lpChatTime : Date.now();
    let szTime = '';

    switch (MsgType) {
        case ChatMsg.eType_Fixed:
            ChatTextOut(isHelpDesk, MsgType, szTime, '', szMessage, Theme.chat.cColor_Fixed);
            break;

        case ChatMsg.eType_System:
            ChatTextOut(isHelpDesk, MsgType, szTime, '', szMessage, Theme.chat.cColor_System, ChatMsg.CFM_COLOR | ChatMsg.CFM_BOLD);
            break;

        case ChatMsg.eType_User:
            //szSpeaker = `${szNick ? szNick : `${AccountStore.szUserID}(${AccountStore.szName})`}`;
            szSpeaker = '';
            szTime = `[${moment(chatTime).format('HH:mm')}]`;
            szText = szMessage;
            ChatTextOut(isHelpDesk, MsgType, szTime, szSpeaker, szText, Theme.chat.cColor_User, ChatMsg.CFM_COLOR);
            break;

        case ChatMsg.eType_Counselor:
            szSpeaker = '상담원';
            szTime = `[${moment(chatTime).format('HH:mm')}]`;
            szText = szMessage;
            ChatTextOut(isHelpDesk, MsgType, szTime, szSpeaker, szText, Theme.chat.cColor_Counselor, ChatMsg.CFM_COLOR);
            break;

        case ChatMsg.eType_Info:
        case ChatMsg.eType_Transfer:
        default:
            szText = szMessage;
            ChatTextOut(isHelpDesk, MsgType, szTime, '', szText, Theme.chat.cColor_Info, ChatMsg.CFM_COLOR | ChatMsg.CFM_BOLD);
            break;
    }
}

const ChatTextOut = (isHelpDesk: boolean, MsgType: any, szTime: any, szSpeaker: any, szText: any, TextColor = Theme.mts.text, dwTextMask = ChatMsg.CFM_COLOR, Offset = ChatMsg.cEdit_DefOffset, Size = 0) => {
    const lpChatRoom = store.GetChatRoomPtr();

    let style = { } as any;
    style.color = TextColor;
    if (dwTextMask & ChatMsg.CFM_BOLD) {
        style.fontWeight = 'bold';
    }
    if (Offset !== 0) {
        style.marginTop = Offset;
    }
    if (Size > 0) {
        style.fontSize = Size;
    }

    lpChatRoom.Messages.push({
        MsgType,
        szTime,
        szSpeaker,
        szMessage: szText,
        style,
    });

    let type = 0;
    switch(MsgType){
        case ChatMsg.eType_Fixed: type = 0; break;	    // 고정 메세지
        case ChatMsg.eType_System: type = 0; break;		// 시스템 메세지
        case ChatMsg.eType_User: type = 1; break;		// 사용자 메세지
        case ChatMsg.eType_Counselor: type = 0; break;	// 상담원 메세지
        case ChatMsg.eType_Info: type = 0; break;		// 안내 메세지
        case ChatMsg.eType_Transfer: type = 0; break;	// 파일 전송
        case ChatMsg.eType_TransferFile: type = 0; break;    // 전송파일
    }

    const item = {
        message: szText,
        type: type,
        time: szTime
    }

    if(isHelpDesk && MsgType > ChatMsg.eType_Fixed) {
        if(store.HelpDeskStore.restoredLastTime && store.HelpDeskStore.restoredLastTime >= szTime) {
            // 안넣음
        }
        else {
            store.HelpDeskStore.chatArray.push(item);
            store.HelpDeskStore.restoredLastTime = item.time;
        }
        
    }
        
}


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MenuTab = _resolveComponent("MenuTab")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_unref(store).CommonState.themeMode === 'dark' ? 'dark' : '',{'devicePc' : _unref(store).SystemState._deviceType !== _unref(DEVICE_TYPE_MOBILE)}])
  }, [
    (_openBlock(), _createBlock(_component_router_view, {
      key: _ctx.$route.fullPath
    })),
    (!_unref(store).CommonState.isLoginPage && _unref(store).SystemState._deviceType === _unref(DEVICE_TYPE_MOBILE))
      ? (_openBlock(), _createBlock(_component_MenuTab, {
          key: 0,
          id: "MenuTab"
        }))
      : _createCommentVNode("", true),
    (_unref(store).SystemState.visibleDialog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["popup-inner", {'no-title' : _unref(store).SystemState.visibleDialogTitle === ''}])
          }, [
            (_unref(store).SystemState.visibleDialogTitle.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(store).SystemState.visibleDialogTitle), 1))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              class: "content",
              innerHTML: _unref(store).SystemState.visibleDialogMessage
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              (_unref(store).SystemState.bDialogOkCancel)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "cancel",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(store).setVisibleDialog({visible:false, okCancel:true})))
                  }, "취소"))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "ok",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(store).setVisibleDialog({visible:false})))
              }, "확인")
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_unref(store).CommonState.toastVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["toast-popup", [{'caution':_unref(store).CommonState.toastIsTypeWarning}]]),
          id: "app-toast",
          style: _normalizeStyle({animationDuration: ((_unref(store).CommonState.toastDuration / 1000) + 0.1 ) +'s'})
        }, [
          _createElementVNode("p", {
            class: "text",
            id: "app-toast-text",
            innerHTML: _unref(store).CommonState.toastMessage
          }, null, 8, _hoisted_5)
        ], 6))
      : _createCommentVNode("", true)
  ], 2))
}
}

})