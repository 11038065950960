import * as Common from '../constants/Common';

export class OrderObj {
	iOrderIdx = 0;			// 주문번호
	stOrderDate = null;	// 접수 시간
	stContractDate = null;	// 체결 시간
	iFutures = 0;			// 거래종목
	iTradeType = Common.G_TRADE_SELL;			// 매매구분
	iPriceType = Common.G_PRICE_MARKET;			// 가격유형
	dbOrderPrice = 0.0;	// 주문가격
	iOrderAmount = 0;		// 주문수량
	dbContractPrice = 0.0;	// 체결가격
	iContractedAmount = 0;	// 체결수량
	iLeftAmount = 0;		// 미체결수량
	iOppositeAmount = 0;	// 잔량(반대매매)
	iLeverage = 0;			// 레버리지
	iCommission = 0;			// 체결 수수료
	iPriorityCount = 0;		// 주문 우선순위(주문시 호가 잔량), 체결에따라 실시간으로 변동
	iOverTrading = Common.eOverTrading_Overnight;		// 장외거래 종류
	iOvernightState = Common.eOvernight_No;	// 오버나잇 신청상태
	iOvernightLogIdx = -1;	// 오버나잇 로그 번호
	iStopLossLogIdx = -1; // 스탑로스 로그
	iProfitTicks = 0;
	bUseLoss = 0; // 손실틱사용
	iLossTicks = 0;	// S/L 틱사용 여부, 0은 사용안함
	bUseStop = 0; // 이익틱사용
	iStopTicks = 0;
	dbAvePrice = 0.0;
}