import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/minus.svg'
import _imports_1 from '@/assets/images/plus.svg'


const _hoisted_1 = { class: "chartPopup" }
const _hoisted_2 = { class: "chartPopup" }
const _hoisted_3 = { class: "chartPopup" }
const _hoisted_4 = { class: "chart-wrap-box" }
const _hoisted_5 = {
  key: 0,
  class: "trade-control-box"
}
const _hoisted_6 = { class: "count" }
const _hoisted_7 = { class: "typing-box" }
const _hoisted_8 = {
  key: 0,
  class: "top-btn-box"
}
const _hoisted_9 = {
  key: 1,
  class: "top-btn-box"
}

import { ref, nextTick, IframeHTMLAttributes } from "vue";
import * as Constant from "../../src/constants/Global";
import * as Common from "../../src/constants/Common";
import * as TradingView from "../../public/charting_library";
import DatafeedSource from "../../public/custom_data/datafeed.js";
import * as Datafeed from "../../public/custom_data/datafeed";
import useBaseComponent from "@/baseComponent";
const Trade_Buy = 0;     //매수
const Trade_Sell = 1;    //매도


export default /*@__PURE__*/_defineComponent({
  __name: 'PcTVChartContainer',
  props: {
    initFuturesItem: {
        type: Object,
        default: () =>{
            return null
        }
    },
},
  emits: ["close","onActionMarketTrade"],
  setup(__props, { emit: __emit }) {

const { onMounted, onUnmounted, reactive, defineProps, store, defineEmits, watch } = useBaseComponent();
const emit = __emit;

const event_changeTheme = (customEvent: any) => {
    if (chartWidget && chartWidget.activeChart()) {
        if (typeof customEvent === "object")
            chartWidget.changeTheme(customEvent.detail.data);
            viewTypeColor();
    }
};

const chartContainer = ref();
let chartWidget = null as any;

// 거래 구분
const props = __props;

let state = reactive({
    tempChartInitialized: false,
    isShowChart: false,
    isReadyChart: false,
    timerShowChart: null as any,
    futuresItem: null as any,
    futures: -1,
    symbol: '',

    isShowAmountBox:false,
    orderAmount: '0',
    orderAmountNumber:0,
    //futures: Common.G_FUTURES_DAY,
    //symbol: Common.g_szFuturesNameMini[Common.G_FUTURES_DAY],
});

// onMounted(() => {
//     initChart();
// });

watch(props, (initFuturesItem) => {
    if(initFuturesItem){
        if(props.initFuturesItem.futures !== state.futures){
            state.futures = props.initFuturesItem.futures;
            state.symbol = Common.g_szFuturesNameMini[props.initFuturesItem.futures];
            initChart();
        }
    }
},{ deep: true });

onUnmounted(() => {
    // external event
    window.parent.removeEventListener("customTvSelectChartType", event_setChart);
    window.parent.removeEventListener("customTvSelectChartInterval", event_setResolution);
    window.parent.removeEventListener("customTvChangeTheme", event_changeTheme);

    if (chartWidget !== null) {
        chartWidget.remove();
        chartWidget = null;
    }

    if (state.timerShowChart){
        clearTimeout(state.timerShowChart);
    }
    state.tempChartInitialized = false;
});




const initChart = () => {
    state.isReadyChart = false;
    const isPrintLog = store.SystemState._debugChartLog >= Constant.DebugLevel_Normal;
    // if (isPrintLog) console.log("[TVChartContainer] onMounted");

    if(state.tempChartInitialized === true) {
        if(chartWidget && chartWidget.activeChart()) {
            chartWidget.activeChart().setSymbol(Common.g_szFuturesNameMini[state.futures]);
            state.isReadyChart = true;
        }
        return;
    }
    state.tempChartInitialized = true;

    const theme = store.CommonState.themeMode;
    
    // "light", "dark".
    if (theme && theme === "dark") widgetOptions.theme = theme;
    else widgetOptions.theme = "light";

    widgetOptions.symbol = Common.g_szFuturesNameMini[state.futures];

    widgetOptions.disabled_features = [
                "adaptive_logo",
                "go_to_date",
                "volume_force_overlay",
                "header_undo_redo",
                "header_symbol_search",
                "header_compare",
                "header_indicators",
                "header_resolutions",
                "header_chart_type",
                "header_saveload",
            ];

    // external event
    window.parent.addEventListener("customTvSelectChartType", event_setChart);
    window.parent.addEventListener("customTvSelectChartResolution", event_setResolution);
    window.parent.addEventListener("customTvChangeTheme", event_changeTheme);

    store.loadChartLayout();

    chartWidget = new TradingView.widget(widgetOptions);

    chartWidget.headerReady().then(function () {
        // 설정 안먹어서 여기서 다시 호출
        chartWidget.changeTheme(widgetOptions.theme);

        /*
        {
            let button = chartWidget.createButton({ align: "left" });
            button.setAttribute("title", "Symbol");
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="18" height="18"><path fill="currentColor" d="M3.5 8a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM8 2a6 6 0 1 0 3.65 10.76l3.58 3.58 1.06-1.06-3.57-3.57A6 6 0 0 0 8 2Z"></path></svg>';
            button.addEventListener("click", function () {
                chartWidget.activeChart().executeActionById("symbolSearch");
            });
        }
        */
        store.ChartLayoutStore.resolutionArray.forEach((data: any) => {
            let title = store.convertResolutionToText(data);
            {
                let button = chartWidget.createButton({ align: "left" });
                button.setAttribute("id", Common.CHART_LAYOUT_BUTTON_ID_PREFIX + data);
                button.setAttribute("title", title);
                button.addEventListener("click", function () {
                    event_setResolution(data);
                });
                button.textContent = title;
                store.addChartResolutionButton(button);
            }
        });

        {
            let button = chartWidget.createButton({ align: "left" });
            button.setAttribute("title", "Indicators");
            button.addEventListener("click", function () {
                chartWidget.activeChart().executeActionById("insertIndicator");
            });
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="none"><path stroke="currentColor" d="M20 17l-5 5M15 17l5 5M9 11.5h7M17.5 8a2.5 2.5 0 0 0-5 0v11a2.5 2.5 0 0 1-5 0"></path></svg>';
        }

        {
            let button = chartWidget.createButton({ align: "left" });
            //button.setAttribute('onmouseover', 'window.parent.customTvChartObj.showChartType();');
            // button.setAttribute('onclick', 'window.parent.customTvShowChartType();');
            button.setAttribute(
                "onclick",
                'document.querySelector(".chartViewTypeWrap").style.display = "block";'
            );
            button.id = `ViewTypeButton`;
            // button.setAttribute('onmouseleave', 'window.parent.customTvHideChartType();');
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path><path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path><path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path><path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path></svg>';
        }
    });
    chartWidget.onChartReady(() => {
        const isPrintLog = store.SystemState._debugChartLog >= Constant.DebugLevel_Normal;

        chartWidget.subscribe<"onAutoSaveNeeded">("onAutoSaveNeeded", function (){
            // if (isPrintLog){
            //     console.log( "[tvChart] auto save required", store.getCurrentChartLayoutSaveName() );
            // }
            chartWidget.saveChartToServer(
                null,
                function () {
                    console.error("[tvChart] auto save failed");
                },
                { chartName: store.getCurrentChartLayoutSaveName() }
            );
        });

      chartWidget.applyOverrides(widgetOptions.overrides);
      chartWidget.applyStudiesOverrides(widgetOptions.studies_overrides);
        
        // chartWidget.activeChart().setChartType(customEvent);
        chartWidget.activeChart().setSymbol(Common.g_szFuturesNameMini[state.futures]);

        nextTick(() => {
            const iframeElement = document.querySelector(
                "#chartContainer iframe"
            ) as HTMLElement;
            iframeElement.classList.add("myIframeClass");
            var iframe = document.querySelector(".myIframeClass") as HTMLElement;
            var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            //   var elementInsideButton = iframeDocument.querySelector("#testButton") as HTMLElement;
            var elementInsideButton = iframeDocument.body;
            var elementHead = iframeDocument.getElementsByTagName("head")[0];
            const createScript = document.createElement('script');
            createScript.innerHTML = `
                function customTvSelectChartType(d){ 
                    document.querySelector(".chartViewTypeWrap").style.display = "none";
                    window.parent.dispatchEvent(new CustomEvent("customTvSelectChartType",{ detail:  { data: d }})); 
                }
                function customTvSelectChartResolution(d) { window.parent.dispatchEvent(new CustomEvent("customTvSelectChartResolution",{ detail:  { data: d }})); } 
            `;
            const createStyle = document.createElement("style");
            if(store.CommonState.themeMode ==='white'){

                createStyle.innerHTML = `
                #ViewTypeButton {cursor: pointer; position:relative;}
                .chartViewTypeWrap{display: none; position:absolute; left:50%;top: 50%; background:#fff; z-index:99;transform:translate(-50%,-50%); box-shadow: 0 1px 6px #0003; border-radius:6px;}
                .chartViewTypeWrap .chartView-top {display:flex; justify-content:space-between; border-bottom:1px solid #e0e3eb; padding:17px 20px;}
                .chartViewTypeWrap .chartView-top button {border:none;background-color:transparent; cursor:pointer;}
                .chartViewTypeWrap .df {display:flex;}
                .chartViewTypeWrap .df-alc{display:flex; align-items: center; padding:3px 5px; cursor:pointer; min-width:141px; }
                .chartViewTypeWrap .df-alc:hover {background-color: #f0f3fa;}
                .chartViewTypeWrap .left-box {margin-right:10px;}
                .chartViewTypeWrap h1 {font-size:20px}
                #custom_tv_chart_type_popup {padding:16px 10px;}
                `;
            } else if(store.CommonState.themeMode ==='dark'){ 

                createStyle.innerHTML = `
                #ViewTypeButton {cursor: pointer; position:relative;}
                .chartViewTypeWrap{display: none; position:absolute; left:50%;top: 50%; background:#1e222d; z-index:99;transform:translate(-50%,-50%); box-shadow: 0 1px 6px #0003; border-radius:6px;}
                .chartViewTypeWrap .chartView-top {display:flex; justify-content:space-between; border-bottom:1px solid #e0e3eb; padding:17px 20px;}
                .chartViewTypeWrap .chartView-top button {border:none;background-color:transparent; cursor:pointer;}
                .chartViewTypeWrap .df {display:flex;}
                .chartViewTypeWrap .df-alc{display:flex; align-items: center; padding:3px 5px; cursor:pointer; min-width:141px; }
                .chartViewTypeWrap .df-alc:hover {background-color: #2a2e39;}
                .chartViewTypeWrap .left-box {margin-right:10px;}
                .chartViewTypeWrap h1 {font-size:20px}
                #custom_tv_chart_type_popup {padding:16px 10px;}
                `;
            } else{/*empty*/}
            

            elementHead.append(createScript);
            elementHead.append(createStyle);
            const createDiv = document.createElement("div");
            createDiv.classList.add('chartViewTypeWrap');
            createDiv.innerHTML = `
                <div class="chartView-top">
                    <h1 class="">차트 뷰 타입</h1>
                    <button class="close-BZKENkhT" data-name="close" type="button" onclick="document.querySelector('.chartViewTypeWrap').style.display = 'none';">
                        <span class="icon-BZKENkhT" data-name="close" data-role="button">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" width="17" height="17" fill="currentColor">
                                <path d="m.58 1.42.82-.82 15 15-.82.82z"></path>
                                <path d="m.58 15.58 15-15 .82.82-15 15z"></path>
                            </svg>
                        </span>
                    </button>
                </div>
                <div id="custom_tv_chart_type_popup" style="left: 814px;top: 703px;transform-origin: 50% -4px;">
                    <div >
                        <div class="df">
                            <div class="left-box">
                                <div onclick="customTvSelectChartType(0);" class="df-alc">
                                    <svg viewBox="0 0 28 28" width="28" height="28"><g fill="none" stroke="currentColor" stroke-linecap="square"><path d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20"></path></g></svg>
                                    <span>막대</span>
                                </div>
                                <div onclick="customTvSelectChartType(1);"  class="df-alc">
                                    <svg viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path><path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path><path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path><path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path></svg>
                                    <span>캔들</span>
                                </div>
                                <div onclick="customTvSelectChartType(9);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path><path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path><path d="M9 8v11h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5z"></path><path d="M10 4h1v5h-1zm0 14h1v5h-1zM8.5 9H10v1H8.5zM11 9h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11zm-1 1h1v1h-1zm-1.5 1H10v1H8.5zm2.5 0h1.5v1H11z"></path></svg>
                                    <span>할로우 캔들</span>
                                </div>
                                <div onclick="customTvSelectChartType(13);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="M12 7v14h5V7h-5Zm4 1h-3v12h3V8ZM19 15v6h5v-6h-5Zm4 1h-3v4h3v-4ZM5 12h5v9H5v-9Zm1 1h3v7H6v-7Z"></path></svg>
                                    <span>Columns</span>
                                </div>
                                <div onclick="customTvSelectChartType(2);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="m25.39 7.31-8.83 10.92-6.02-5.47-7.16 8.56-.76-.64 7.82-9.36 6 5.45L24.61 6.7l.78.62Z"></path></svg>
                                    <span>Line</span>
                                </div>
                                <div onclick="customTvSelectChartType(14);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path fill-rule="evenodd" d="m18.43 15.91 6.96-8.6-.78-.62-6.96 8.6a2.49 2.49 0 0 0-2.63.2l-2.21-2.02A2.5 2.5 0 0 0 10.5 10a2.5 2.5 0 1 0 1.73 4.3l2.12 1.92a2.5 2.5 0 1 0 4.08-.31ZM10.5 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm7.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"></path><path d="M8.37 13.8c.17.3.4.54.68.74l-5.67 6.78-.76-.64 5.75-6.88Z"></path></svg>
                                    <span>Line with markers</span>
                                </div>
                            </div>
                            <div>
                                <div onclick="customTvSelectChartType(15);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M19 5h5v1h-4v13h-6v-7h-4v12H5v-1h4V11h6v7h4V5Z"></path></svg>
                                    <span>Step line</span>
                                </div>
                                <div onclick="customTvSelectChartType(3);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" d="m25.35 5.35-9.5 9.5-.35.36-.35-.36-4.65-4.64-8.15 8.14-.7-.7 8.5-8.5.35-.36.35.36 4.65 4.64 9.15-9.14.7.7ZM2 21h1v1H2v-1Zm2-1H3v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1V9h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1H6v1H5v1H4v1Zm1 0v1H4v-1h1Zm1 0H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0H7v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v1h1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1H8v1H7v1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0H9v1h1v1h1v-1h1v1h1v-1h1v1h1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1H9v1h1v1Zm1 0v1h-1v-1h1Zm0-1v-1h-1v1h1Zm0 0v1h1v1h1v-1h-1v-1h-1Zm6 2v-1h1v1h-1Zm2 0v1h-1v-1h1Zm0-1h-1v-1h1v1Zm1 0h-1v1h1v1h1v-1h1v1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h1v1Zm1 0h-1v1h1v-1Zm0-1h1v1h-1v-1Zm0-1h1v-1h-1v1Zm0 0v1h-1v-1h1Zm-4 3v1h-1v-1h1Z"></path></svg>
                                    <span>Area</span>
                                </div>
                                <div onclick="customTvSelectChartType(16);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path fill-rule="evenodd" d="M22 3h1v1h-1V3Zm0 2V4h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1V9h-1V8h-1V7h-1V6h-1V5h-1v1H9v1H8v1H7v1H6v1H5v1H4v1h1v1H4v1h1v-1h1v-1h1v-1h1v-1h1V9h1V8h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1V9h1V8h1V7h1V6h1V5h-1Zm-1 1V5h1v1h-1Zm-1 1V6h1v1h-1Zm-1 1V7h1v1h-1Zm-1 1V8h1v1h-1Zm-1 1V9h1v1h-1Zm-1 1v-1h1v1h-1Zm-1 0v-1h-1V9h-1V8h-1V7h-1V6h-1v1H9v1H8v1H7v1H6v1H5v1h1v-1h1v-1h1V9h1V8h1V7h1v1h1v1h1v1h1v1h1Zm0 0h1v1h-1v-1Zm.84 6.37 7.5-7-.68-.74-7.15 6.67-4.66-4.65-.33-.34-.36.32-5.5 5 .68.74 5.14-4.68 4.67 4.66.34.35.35-.33ZM6 23H5v1h1v-1Zm0-1H5v-1h1v1Zm1 0v1H6v-1h1Zm0-1H6v-1h1v1Zm1 0v1H7v-1h1Zm0-1H7v-1h1v1Zm1 0v1H8v-1h1Zm0-1H8v-1h1v1Zm1 0v1H9v-1h1Zm0-1H9v-1h1v1Zm1 0h-1v1h1v1h1v1h1v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1v-1h-1v-1h-1v-1h-1v1h1v1Zm0 0h1v1h-1v-1Zm2 2v1h1v1h1v1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h1v-1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v1h-1v-1h-1v-1h-1Zm0 0v-1h-1v1h1Z"></path></svg>
                                    <span>HLC area</span>
                                </div>
                                <div onclick="customTvSelectChartType(10);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="m10.49 7.55-.42.7-2.1 3.5.86.5 1.68-2.8 1.8 2.82.84-.54-2.23-3.5-.43-.68Zm12.32 4.72-.84-.54 2.61-4 .84.54-2.61 4Zm-5.3 6.3 1.2-1.84.84.54-1.63 2.5-.43.65-.41-.65-1.6-2.5.85-.54 1.17 1.85ZM4.96 16.75l.86.52-2.4 4-.86-.52 2.4-4ZM3 14v1h1v-1H3Zm2 0h1v1H5v-1Zm2 0v1h1v-1H7Zm2 0h1v1H9v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Zm2 0v1h1v-1h-1Zm2 0h1v1h-1v-1Z"></path></svg>
                                    <span>Baseline</span>
                                </div>
                                <div onclick="customTvSelectChartType(12);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"><path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7H7v14h5V7H7.5zM8 20V8h3v12H8zm7.5-11H15v10h5V9h-4.5zm.5 9v-8h3v8h-3z"></path></svg>
                                    <span>Hign-low</span>
                                </div>
                                <div onclick="customTvSelectChartType(8);"  class="df-alc">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M9 8v12h3V8H9zm-1-.502C8 7.223 8.215 7 8.498 7h4.004c.275 0 .498.22.498.498v13.004a.493.493 0 0 1-.498.498H8.498A.496.496 0 0 1 8 20.502V7.498z"></path><path d="M10 4h1v3.5h-1z"></path><path d="M17 6v6h3V6h-3zm-1-.5c0-.276.215-.5.498-.5h4.004c.275 0 .498.23.498.5v7c0 .276-.215.5-.498.5h-4.004a.503.503 0 0 1-.498-.5v-7z"></path><path d="M18 2h1v3.5h-1z"></path></svg>
                                    <span>헤이킨 아시</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`;
            elementInsideButton.append(createDiv);
            state.timerShowChart = setTimeout(()=>{
                state.isShowChart = true;
                state.isReadyChart = true;
            }, 100)
        });
    });
}

const event_setChart = (customEvent: any) => {
    const dataState = Number(Datafeed.getCurrentState());
    //console.log('--- event_setChart ---', customEvent, dataState);
    if (
        dataState === Datafeed.FEED_STATE_NONE ||
        dataState === Datafeed.FEED_STATE_HISTORY_RENDERED
    ) {
        if (chartWidget && chartWidget.activeChart()) {
            if (typeof customEvent === "object")
                chartWidget.activeChart().setChartType(customEvent.detail.data);
            else chartWidget.activeChart().setChartType(customEvent);
        }
    }
};
const event_setResolution = (customEvent: any) => {
    const dataState = Number(Datafeed.getCurrentState());
    //console.log('--- event_setResolution ---', customEvent, dataState);
    if (
        dataState === Datafeed.FEED_STATE_NONE ||
        dataState === Datafeed.FEED_STATE_HISTORY_RENDERED
    ) {
        if (chartWidget && chartWidget.activeChart()) {
            if (typeof customEvent === "object")
                chartWidget.activeChart().setResolution(customEvent.detail.data);
            else chartWidget.activeChart().setResolution(customEvent);
        }
    }
};

let widgetOptions = {
    debug: false, // live: false, test: true
    datafeed: DatafeedSource,
    library_path: "/charting_library/",
    container: "chartContainer",
    symbol: Common.g_szFuturesNameMini[Common.G_FUTURES_DAY],
    interval: "1",
    locale: "ko",
    height: 500,
    //timezone: "Etc/UTC",
    timezone: "Asia/Seoul",
    //fullscreen: false,
    autosize: true,
    header_widget_buttons_mode: "adaptive", // fullsize, compact, adaptive
    disabled_features: [],
    enabled_features: [
        "tick_resolution",
        "use_localstorage_for_settings",
        "save_chart_properties_to_local_storage",
        "header_layouttoggle",
        "items_favoriting",
        "show_object_tree",
        "chart_template_storage",
    ],
    time_frames: [
        { text: "1D", resolution: "10", description: "1 일" },
        { text: "1H", resolution: "1", description: "1 시간" },
    ],

    auto_save_delay: 1,
    charts_storage_api_version: "1.1",
    load_last_chart: true,

    overrides: {
        "mainSeriesProperties.candleStyle.upColor": "#e12343",
        "mainSeriesProperties.candleStyle.downColor": "#1763b6",
        "mainSeriesProperties.candleStyle.borderUpColor": "#e12343",
        "mainSeriesProperties.candleStyle.borderDownColor": "#1763b6",
        "mainSeriesProperties.candleStyle.borderColor": "#378658",
    },

    studies_overrides: {
        "volume.volume.color.0": "#1763b6",
        "volume.volume.color.1": "#e12343",
    },

    custom_formatters: {
        // 우측 y축
        priceFormatterFactory: (symbolInfo: any, minTick: any) => {
            if (symbolInfo && symbolInfo.customDecimal && symbolInfo.customDecimal > 0) {
                return {
                    format: (price: any, signPositive: any) => {
                        // return the appropriate format
                        return Number(price).toFixed(symbolInfo.customDecimal);
                    },
                };
            }
            return null;
        },
    },

    save_load_adapter: {
        charts: store.ChartLayoutStore.charts,
        studyTemplates: store.ChartLayoutStore.studyTemplates,
        drawingTemplates: store.ChartLayoutStore.drawingTemplates,
        chartTemplates: store.ChartLayoutStore.chartTemplates,
        loadFromStore: function (order: any, dataArray: any) {
            if (dataArray) {
                switch (order) {
                case 0: {
                    dataArray.forEach((element: any) => {
                        this.charts.push(element);
                    });
                }
                }
            }
            return Promise.resolve(this.charts);
        },
        saveToLocalStorage: function () {
            store.saveChartLayout();
            return Promise.resolve();
        },
        getAllCharts: function () {
            return Promise.resolve(this.charts);
        },
        removeChart: function (id: any) {
            for (var i = 0; i < this.charts.length; ++i) {
                if (this.charts[i].id === id) {
                    this.charts.splice(i, 1);

                    this.saveToLocalStorage();
                    return Promise.resolve();
                }
            }
            console.error("removeChart error: " + id);
            return Promise.reject();
        },
        saveChart: function (chartData: any) {
            if (!chartData.id) chartData.id = Math.random().toString();
            else this.removeChart(chartData.id);

            chartData.timestamp = new Date().getTime() / 1000;

            this.charts.push(chartData);
            this.saveToLocalStorage();
            return Promise.resolve(chartData.id);
        },
        getChartContent: function (id: any) {
            for (var i = 0; i < this.charts.length; ++i) {
                if (this.charts[i].id === id) {
                return Promise.resolve(this.charts[i].content);
                }
            }
            console.error("getChartContent error: " + id);
            return Promise.reject();
        },
        removeStudyTemplate: function (studyTemplateData: any) {
            for (var i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                this.studyTemplates.splice(i, 1);
                this.saveToLocalStorage();
                return Promise.resolve();
                }
            }
            console.error("removeStudyTemplate error: " + studyTemplateData);
            return Promise.reject();
        },
        getStudyTemplateContent: function (studyTemplateData: any) {
            for (var i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                return Promise.resolve(this.studyTemplates[i]);
                }
            }
            console.error("getStudyTemplateContent error: " + studyTemplateData);
            return Promise.reject();
        },
        saveStudyTemplate: function (studyTemplateData: any) {
            for (var i = 0; i < this.studyTemplates.length; ++i) {
                if (this.studyTemplates[i].name === studyTemplateData.name) {
                    this.studyTemplates.splice(i, 1);
                    break;
                }
            }
            this.studyTemplates.push(studyTemplateData);
            this.saveToLocalStorage();
            return Promise.resolve();
        },
        getAllStudyTemplates: function () {
            return Promise.resolve(this.studyTemplates);
        },
        removeDrawingTemplate: function (toolName: any, templateName: any) {
            for (var i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    this.saveToLocalStorage();
                    return Promise.resolve();
                }
            }
            console.error("removeDrawingTemplate error: " + toolName, templateName);
            return Promise.reject();
        },
        loadDrawingTemplate: function (toolName: any, templateName: any) {
            for (var i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    return Promise.resolve(this.drawingTemplates[i].content);
                }
            }
            console.error("drawing: error");
            return Promise.reject();
        },
        saveDrawingTemplate: function (toolName: any, templateName: any, content: any) {
            for (var i = 0; i < this.drawingTemplates.length; ++i) {
                if (this.drawingTemplates[i].name === templateName) {
                    this.drawingTemplates.splice(i, 1);
                    break;
                }
            }
            this.drawingTemplates.push({ name: templateName, content: content });
            this.saveToLocalStorage();
            return Promise.resolve();
        },
        getDrawingTemplates: function () {
            return Promise.resolve(
                this.drawingTemplates.map(function (template: any) {
                    return template.name;
                })
            );
        },
        async getAllChartTemplates() {
            return this.chartTemplates.map((x: any) => x.name);
        },
        async saveChartTemplate(templateName: any, content: any) {
            const theme = this.chartTemplates.find((x: any) => x.name === templateName);
            if (theme) theme.content = content;
            else this.chartTemplates.push({ name: templateName, content });
            this.saveToLocalStorage();
        },
        async removeChartTemplate(templateName: any) {
            this.chartTemplates = this.chartTemplates.filter((x: any) => x.name !== templateName);
            this.saveToLocalStorage();
        },
        async getChartTemplateContent(templateName: any) {
            const theme = {} as any;
            const content = this.chartTemplates.find((x: any) => x.name === templateName)?.content;
            if (content) theme.content = structuredClone(content);
            return theme;
        },
    },
} as any;


//차트 내 박스
const onActionMarketTrade = (tradeType: number) => {
    if(state.orderAmountNumber <= 0){
        const szMsg = `주문 수량을 확인해 주세요.`;
        store.setVisibleToast({ message: szMsg, isTypeWarning: true, duration:1000 });
        return;
    }
    const symbol = store.getCurrentChartLayoutSaveName();
    emit("onActionMarketTrade", {symbol: symbol, tradeType: tradeType, orderAmount: state.orderAmountNumber})
    closeAmountBox();
}


const showAmountBox = () => {
    // if(props.initOrderLeft <= 0){
    //     const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${props.initOrderLeft})개 입니다.`;
    //     store.setVisibleToast({ message: szMsg, isTypeWarning: true, duration:1500 });
    //     return;
    // }

    // if(!props.initAccPassed){
    //     store.setVisibleToast({isTypeWarning: true, message: '계좌비밀번호가 설정되어 있어야 사용할 수 있는 기능입니다.'})
    //     return;
    // }

    state.isShowAmountBox = true;
}

const onChangeAmount = (value = 0 as number) =>{
    const amount = state.orderAmount.replace(/[^0-9]/g, "");
    state.orderAmountNumber = Number(amount);
    const totalAmount = state.orderAmountNumber + value;

    if(totalAmount < 0 ){
        state.orderAmountNumber = 0;
    }
    else {
        state.orderAmountNumber = totalAmount;
    }

    state.orderAmount = state.orderAmountNumber.toString();
}

const closeAmountBox = () => {
    state.isShowAmountBox = false;
    state.orderAmount = '0';
    state.orderAmountNumber = 0; 
}

const viewTypeColor = () => {
    const iframeElement = document.querySelector(
                "#chartContainer iframe"
            ) as HTMLElement;
            iframeElement.classList.add("myIframeClass");
            var iframe = document.querySelector(".myIframeClass") as HTMLElement;
            var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            var elementHead = iframeDocument.getElementsByTagName("head")[0];
            const createStyle = document.createElement("style");
            if(store.CommonState.themeMode ==='white'){
                createStyle.innerHTML = `
                #ViewTypeButton {cursor: pointer; position:relative;}
                .chartViewTypeWrap{display: none; position:absolute; left:50%;top: 50%; background:#fff; z-index:99;transform:translate(-50%,-50%); box-shadow: 0 1px 6px #0003; border-radius:6px;}
                .chartViewTypeWrap .chartView-top {display:flex; justify-content:space-between; border-bottom:1px solid #e0e3eb; padding:17px 20px;}
                .chartViewTypeWrap .chartView-top button {border:none;background-color:transparent; cursor:pointer;}
                .chartViewTypeWrap .df {display:flex;}
                .chartViewTypeWrap .df-alc{display:flex; align-items: center; padding:3px 5px; cursor:pointer; min-width:141px; }
                .chartViewTypeWrap .df-alc:hover {background-color: #f0f3fa;}
                .chartViewTypeWrap .left-box {margin-right:10px;}
                .chartViewTypeWrap h1 {font-size:20px}
                #custom_tv_chart_type_popup {padding:16px 10px;}
                `;
            } else if(store.CommonState.themeMode ==='dark'){ 
                createStyle.innerHTML = `
                #ViewTypeButton {cursor: pointer; position:relative;}
                .chartViewTypeWrap{display: none; position:absolute; left:50%;top: 50%; background:#1e222d; z-index:99;transform:translate(-50%,-50%); box-shadow: 0 1px 6px #0003; border-radius:6px;}
                .chartViewTypeWrap .chartView-top {display:flex; justify-content:space-between; border-bottom:1px solid #e0e3eb; padding:17px 20px;}
                .chartViewTypeWrap .chartView-top button {border:none;background-color:transparent; cursor:pointer;}
                .chartViewTypeWrap .df {display:flex;}
                .chartViewTypeWrap .df-alc{display:flex; align-items: center; padding:3px 5px; cursor:pointer; min-width:141px; }
                .chartViewTypeWrap .df-alc:hover {background-color: #2a2e39;}
                .chartViewTypeWrap .left-box {margin-right:10px;}
                .chartViewTypeWrap h1 {font-size:20px}
                #custom_tv_chart_type_popup {padding:16px 10px;}
                `;
            } else{/*empty*/}
            elementHead.append(createStyle);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _withDirectives(_createElementVNode("div", _hoisted_1, _cache[9] || (_cache[9] = [
        _createStaticVNode("<div class=\"lds-spinner\" data-v-0363e6e1><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div><div data-v-0363e6e1></div></div><div id=\"fountainTextG\" data-v-0363e6e1><div id=\"fountainTextG_1\" class=\"fountainTextG\" data-v-0363e6e1>L</div><div id=\"fountainTextG_2\" class=\"fountainTextG\" data-v-0363e6e1>o</div><div id=\"fountainTextG_3\" class=\"fountainTextG\" data-v-0363e6e1>a</div><div id=\"fountainTextG_4\" class=\"fountainTextG\" data-v-0363e6e1>d</div><div id=\"fountainTextG_5\" class=\"fountainTextG\" data-v-0363e6e1>i</div><div id=\"fountainTextG_6\" class=\"fountainTextG\" data-v-0363e6e1>n</div><div id=\"fountainTextG_7\" class=\"fountainTextG\" data-v-0363e6e1>g</div><div id=\"fountainTextG_8\" class=\"fountainTextG\" data-v-0363e6e1></div><div id=\"fountainTextG_9\" class=\"fountainTextG\" data-v-0363e6e1>.</div><div id=\"fountainTextG_10\" class=\"fountainTextG\" data-v-0363e6e1></div><div id=\"fountainTextG_11\" class=\"fountainTextG\" data-v-0363e6e1>.</div><div id=\"fountainTextG_12\" class=\"fountainTextG\" data-v-0363e6e1></div><div id=\"fountainTextG_13\" class=\"fountainTextG\" data-v-0363e6e1>.</div></div>", 2)
      ]), 512), [
        [_vShow, !_unref(state).isReadyChart]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "TVChartContainer",
            ref: _unref(state).chartContainer,
            id: "chartContainer"
          }, null, 512),
          (_unref(state).isShowAmountBox)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text" }, "주문수량", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "감소",
                      class: "minus",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (onChangeAmount(-1)))
                    }),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).orderAmount) = $event)),
                      onKeyup: _cache[2] || (_cache[2] = ($event: any) => (onChangeAmount()))
                    }, null, 544), [
                      [_vModelText, _unref(state).orderAmount]
                    ]),
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: "증가",
                      class: "plus",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (onChangeAmount(1)))
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        (!_unref(state).isShowAmountBox)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "market-order-btn buy",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (showAmountBox()))
              }, "시장가 매수"),
              _createElementVNode("button", {
                class: "market-order-btn sell",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (showAmountBox()))
              }, "시장가 매도")
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", {
                class: "market-order-btn cancel",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (closeAmountBox()))
              }, "닫기"),
              _createElementVNode("button", {
                class: "market-order-btn buy",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (onActionMarketTrade(Trade_Buy)))
              }, "시장가 매수"),
              _createElementVNode("button", {
                class: "market-order-btn sell",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (onActionMarketTrade(Trade_Sell)))
              }, "시장가 매도")
            ]))
      ])
    ], 512), [
      [_vShow, _unref(state).isReadyChart && _unref(state).isShowChart]
    ])
  ], 64))
}
}

})