<template>
    <!-- <div class="popup notice" v-if="props.initShowNotice" :class="{'popupSet': route.name === 'Home'}"> -->
    <div class="popupPc notice" v-if="props.initShowNotice" :class="{'indexNotice': props.viewType !== 'Detail' }">
        <div class="popupPc-inner">
			<div class="close-box" v-if="props.viewType !== 'Detail'">
                <p class="main-title">{{props.initTitle}}</p>
			</div>
			<div class="close-box" v-else>
				<img src="../../assets/images/icon-close.png" alt="" @click="close">
			</div>
			<p v-html="props.initTitle" class="title"  v-if="props.viewType === 'Detail'"></p>
            <p v-html="props.initNoticeMessage" class="content"></p>
            <div class="btn-box notice"  v-if="props.viewType !== 'Detail' ">
                <button class="noToday" @click="noToday">오늘은 그만보기</button>
                <button class="ok" @click="close" v-if="props.viewType !== 'Detail'">동의</button>
                <button class="ok" @click="close" v-else>확인</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive,} = useBaseComponent();

const props = defineProps({
    initShowNotice: {
        type: Boolean,
        default: false,
    },
    initTitle: {
        type: String,
        default: "",
    },
    initNoticeMessage: {
        type: String,
        default: "",
    },
	viewType: {
        type: String,
        default: "",
    },
});

const emit = defineEmits(["close","noToday"]);

const noToday = () => {
    emit("noToday")
}

const close = () => {
    emit("close");
};
</script>
