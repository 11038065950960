import * as NetConstants from './Constants';
import * as Constant from '../constants/Global';
import * as Common from '../constants/Common';
import * as Utils from '../utils/Common';
import moment from '../utils/moment';
import { NetworkService } from './NetworkService';

import {useStore} from "../pinia"

export class UserLog {
  // 유저로그
  static log(szLog: any, logEventPos = Common.eLogEventPos_ClientIntOrd) {
    NetworkService.send.userLog({ szLog, logEventPos });
  }

  // 종목코드 선택
  static changeFutures(Futures: any) {
    const store = useStore();
    const szLog = `통합주문클릭 종목변경 [${Common.g_szFuturesNameMini[Futures]}][${store.MarketState.szFutureCode[Futures]}]`;
    NetworkService.send.userLog({ szLog });
  }

  // 창 열기/닫기
  static window(szWindowName: any, bOpen = 1) {
    const szLog = `[${szWindowName}] 화면 [${bOpen ? '켜짐' : '꺼짐'}]`;
    NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_Menubar });
  }

  // 주문로그
  static order(Futures: any, TradeType: any, PriceType: any, OrderPrice: any, OrderAmount: any, OrderMethod: any, szExMessage = '') {
    const szLog = `[${Common.g_szFuturesNameMini[Futures]}][${Common.g_szTradeTypeName[TradeType]}][${Common.g_szPriceTypeName[PriceType]}][${Common.g_szOrderMethodName[OrderMethod]}] 주문 접수 요청(가격:${Utils.PriceFormatStr(OrderPrice, Futures)}, 수량:${OrderAmount}) ${szExMessage}`;
    NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd });
  }

  // 주문수정
  static modifyOrder(Futures: any, TradeType: any, OrderPrice: any, OrderAmount: any, ModifyMethod: any, OrderMethod: any, OrderIdx = Common.INDEX_NONE, szExMessage = '') {
    let szLog;
    if (ModifyMethod === Common.C_ORDERMODIFY_BatchSettle) {
      szLog = `[${Common.g_szFuturesNameMini[Futures]}][${Common.g_szTradeTypeName[TradeType]}][${Common.g_szOrderMethodName[OrderMethod]}] 주문 체결(${Common.g_szOrderModifyName[ModifyMethod]}) 요청(가격:${Utils.PriceFormatStr(OrderPrice, Futures)}, 수량:${OrderAmount}) ${szExMessage}`;
      NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd });
    } else {
      szLog = `[${Common.g_szFuturesNameMini[Futures]}][${Common.g_szTradeTypeName[TradeType]}][${Common.g_szOrderMethodName[OrderMethod]}] 주문 수정(${Common.g_szOrderModifyName[ModifyMethod]}) 요청(가격:${Utils.PriceFormatStr(OrderPrice, Futures)}, 수량:${OrderAmount}) ${szExMessage}`;
      NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd, OrderIdx: OrderIdx });
    }
  }

  // MIT 요청
  // MIT 접수는 서버에서 로그를 남기기 때문에 사용하지 않음(로그번호를 남겨야하기 때문)
  // MIT 수정은 클라에서 남김
  static MIT(Futures: any, TradeType: any, TouchPrice: any, OrderPrice: any, OrderAmount: any, szExMessage = '', LogIdx = Common.INDEX_NONE, bCancel = 0) {
    const szLog = `[${Common.g_szFuturesNameMini[Futures]}][${Common.g_szTradeTypeName[TradeType]}] MIT 주문 ${bCancel ? '취소' : '접수'} 요청(로그번호: ${LogIdx}, 검사가격:${Utils.PriceFormatStr(TouchPrice, Futures)}, 주문가격:${Utils.PriceFormatStr(OrderPrice, Futures)}, 수량:${OrderAmount}) ${szExMessage}`;
    NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd });
  }

  // 스탑로스
  // 스탑로스 접수는 서버에서 로그를 남기기 때문에 사용하지 않음(로그번호를 남겨야하기 때문)
  // 스탑로스 수정은 클라에서 남김
  static stopLoss(Futures: any, AvePrice: any, UseStop: any, StopTicks: any, UseLoss: any, LossTicks: any, szExMessage = '', LogIdx = Common.INDEX_NONE, bCancel = 0) {
    let szStopTick = '사용안함', szLossTick = '사용안함';
    if (UseStop) {
      szStopTick = `${StopTicks}`;
    }
    if (UseLoss) {
      szLossTick = `${LossTicks}`;
    }

    const szLog = `[${Common.g_szFuturesNameMini[Futures]}] S/L예약 ${bCancel ? '해제' : '설정'} 요청(로그번호: ${LogIdx}, 평균가:${Utils.PriceFormatStr(AvePrice, Futures)}, 이익틱:${szStopTick}, 손실틱:${szLossTick}) ${szExMessage}`;
    NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd });
  }

  // 오버나잇
  // List<OVERNIGHT_REQ> OvernightReqList
  static overnight(OvernightReqList: any, szExMessage = '') {
    OvernightReqList.forEach((overnightReq: any) => {
      const szLog = `[${Common.g_szFuturesNameMini[overnightReq.iFutures]}][${Common.g_szOverTradingName[overnightReq.iOverTrading]}] ${Common.g_szOvernightStateName[overnightReq.iOvernightState]}(구분:${Common.g_szTradeTypeName[overnightReq.iTradeType]}, 수량:${overnightReq.iOppositeAmount}) ${szExMessage}`;
      NetworkService.send.userLog({ szLog, logEventPos: Common.eLogEventPos_ClientIntOrd, OrderIdx: overnightReq.iOrderIdx });
    });
  }
}