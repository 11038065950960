import * as encryptStorage from '../utils/encryptStorage';
import { SettingsModel, SettingsExModel } from "@/db";
import { APP_SETTING_EX } from "@/pinia";
import { useStore } from '../pinia';
import * as Constant from '../constants/Global';

export default class SettingsService {
  static async getUserId() { return encryptStorage.instance.getItem('1') ?? ''; }
  static async saveUserId(d: any) { encryptStorage.instance.setItem('1', d); }
  static async getUserPwd() { return encryptStorage.instance.getItem('2') ?? ''; }
  static async saveUserPwd(d: any) { encryptStorage.instance.setItem('2', d); }

  static async getSettings() {
    const baseString = encryptStorage.instance.getItem<string>('3') ?? '';
    if(baseString !== undefined && baseString !== null && baseString !== '') {
      if(typeof baseString === 'string')
        return new SettingsModel(JSON.parse(baseString));
      else if(typeof baseString === 'object')
        return new SettingsModel(baseString);
      else {
        const store = useStore();
        if (store.debugLog >= Constant.DebugLevel_Minimal)
          console.error('failed to parse data: ' + baseString);
      }
    }
    return null;
  }

  static async getSettingsEx() {
    const baseString = await localStorage.getItem(APP_SETTING_EX);
    if(baseString) {
      if(typeof baseString === 'string')
        return new SettingsExModel(JSON.parse(baseString));
      else if(typeof baseString === 'object')
        return new SettingsExModel(baseString);
      else {
        const store = useStore();
        if (store.debugLog >= Constant.DebugLevel_Minimal)
          console.error('failed to parse data: ' + baseString);
      }
    }
      
    return null;
  }

  static async initializeSettings() {
    const store = useStore();
    const settings = new SettingsModel( { id: store.SettingsState.loginID} );
    encryptStorage.instance.setItem('3', JSON.stringify(settings));
    return settings;
  }

  static async initializeSettingsEx() {
    const store = useStore();
    const settingsEx = new SettingsExModel( { id: store.SettingsState.loginID} );
    await localStorage.setItem(APP_SETTING_EX, JSON.stringify(settingsEx));
    return settingsEx;
  }

  static async updateSettings({ ...settingsInfo }) {
    encryptStorage.instance.setItem('3', JSON.stringify(settingsInfo));
    return this.getSettings();
  }

  static async updateSettingsEx({ ...settingsExInfo }) {
    await localStorage.setItem(APP_SETTING_EX, JSON.stringify(settingsExInfo));
    return this.getSettingsEx();
  }
  
}