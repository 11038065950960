import { Buffer } from 'buffer';
import { Packet } from './Packet';
import { PacketID } from './PacketID';
import * as NetConstants from './Constants';
import * as Common from '../constants/Common';
import * as Utils from '../utils/Common';
import { NetworkService } from './NetworkService';
import { XingAPIService } from './XingAPIService';
import Datafeed from "../../public/custom_data/datafeed.js";
import { onChartDataReceived } from "../../public/custom_data/streaming.js";

import { 
  Emit_LoginFailed, Emit_LoginPasswordUpdate, Emit_AccountPasswordUpdate, Emit_ResetMoveToLogin,
  Emit_Success, Emit_ContractData, Emit_CallData, Emit_DrawTransProfit, Emit_ResetAmount, Emit_DrawTrans, Emit_TradingLog, Emit_NoticeContent, 
  Emit_AccInfo, Emit_UserOption, Emit_Chat, Emit_ChartData, Emit_TransferLog, Emit_StopLossLog,
} from './NetworkEmitter';
import moment from '../utils/moment';
import { 
  OrderObj, ContractObj, CallObj, TradingLog, NoticeObj, ChatTransfer, TransferLog, StopLossLog, ChatMsg,
  eChatEvent_Init, eChatEvent_Message, eChatEvent_Counselor, eChatEvent_Transfer, eChatEvent_Logs,
} from '../models';
import * as StopLoss from '../models/StopLoss';
import * as Constant from '../constants/Global';

import {useStore} from '../pinia'

export class PacketService {
  static async handler(packet) {
    const store = useStore();
    try {
      const packetId = packet.id;
      if (store.debugLog >= Constant.DebugLevel_Verbose) {
        //console.log(`패킷을 처리한다. ${packetId}`);
      }
      await this[packetId](packet);
    } catch(e) {
      if(packet.id) {
        switch(packet.id) {
          case 174: {
            console.warn('skip code.  new id: ' + packet.id);
            return;
          }
        }
      }
      if (store.debugLog >= Constant.DebugLevel_Verbose)
        console.error(e);
      
      throw e;
    }
    return;
  }
   
  static async [PacketID.PROTOCOL_NO](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_KEEPALIVE](packet) {
    const _packet = new Packet(PacketID.PROTOCOL_KEEPALIVE);

    _packet.shrink();
    NetworkService.socket.write(_packet.buffer, null, () => {
      // console.log('send PROTOCOL_KEEPALIVE success');
    });

    // console.log('send PROTOCOL_KEEPALIVE fetched');

    return;
  }

  static async [PacketID.PROTOCOL_BRANDINFO](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_ADMIN_CMMD](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_FUT_MASTER](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_FUT_t2101](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_FUT_FC0](packet) {
    // [트레이딩뷰 처리필요] 해외선물 체결가
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 주간 선물 체결 셋팅한다.
    const futCode = Common.G_FUTURES_DAY;

    if (store.debugLog >= Constant.DebugLevel_Verbose) {
      // console.log(`체결데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.dbCurPrice[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);
    }

    if ( !store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode] ) {
      return;
    }

    XingAPIService.MasterDayO(futCode, outBlock);
    
    //// console.log(`체결데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.dbCurPrice[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);
  
    // 거래창에 전달
    // 챠트에 전달
    if (store.AccountState.G_Future_Code === futCode || store.AccountState.ChartFutures === futCode) {
      // PostMessage(WM_CONTRACT)
      const contractData = new ContractObj();
      contractData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_ContractData, { contractData });

      
      // tradingView 전달
      const tmpDate =  moment(new Date()).format('YYYY-MM-DD') + ' ' + contractData.szCTime;
      //const tmpDate =  moment(new Date()).format('YYYY-MM-DD') + ' ' + contractData.szOrgTime;
      //const tmpDate =  moment(new Date()).format('YYYY-MM-DD') + ' ' + contractData.szCTime;

      const dataObj = {
        open: contractData.dbOpenPrice,
        high: contractData.dbHighPrice,
        low: contractData.dbLowPrice,
        close: contractData.dbCurPrice,
        time: new Date(tmpDate).getTime(),
        volume: contractData.iTrdq,
      }
      //// console.log('-- ', contractData);
      onChartDataReceived(dataObj);
    }
   
    // 데이터 받은거 설정
    store.MasterState.isRecvContract[futCode] = true;
  
    // 이전현재가와 현재가가 다르면.
    if (store.MasterState.dbBeforePrice[futCode] !== store.MasterState.dbCurPrice[futCode]) {
      // 현재가가 다르고 포지션 진입 상태이면 내 주문의 손실을 실시간 업데이트한다.
      // 평가손익, 실현손익, 평가 예탁금 등.
      // PostMessage(WM_TRANSGRID_PROFIT)
      const tradeType = store.AccountState.iOrderPos[futCode] === Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
      const totalOpposite = store.GetClientTotalOppositeAmount(futCode, tradeType);
      if (totalOpposite > 0) {
        // 변동 손익 화면 갱신
        NetworkService.emitter.emit(Emit_DrawTransProfit);
      }
  
      // 큐에 접어 넣는다.
      // mit, stoploss 처리용
      //CHTSClientMain::getSingletonPtr()->AddContractPriceQue(futCode, store.MasterState.dbCurPrice[futCode]);
  
      store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    }

    return;
  }

  static async [PacketID.PROTOCOL_FUT_FH0](packet) {
    const store = useStore();
    
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 주간 선물 호가 셋팅한다.
    const futCode = Common.G_FUTURES_DAY;
  
    if (!store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode]) {
      return;
    }
  
    if (store.debugLog >= Constant.DebugLevel_All) {
        // console.log(`호가데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.offerho1[futCode], futCode)}, ${Utils.PriceFormatStr(store.MasterState.bidho1[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);
    }

    XingAPIService.MasterDayB(futCode, outBlock);
  
    // 호가창에 전달
    if (store.AccountState.G_Future_Code === futCode) {
      // PostMessage(WM_HOGA);
      const callData = new CallObj();
      callData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_CallData, { callData });
    }

    store.MasterState.isRecvHoga[futCode] = true;
  
    return;
  }

  static async [PacketID.PROTOCOL_FUT_t2801](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_FUT_NC0](packet) {
    const store = useStore();

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 야간 선물 체결 셋팅한다.
    const futCode = Common.G_FUTURES_NIGHT;
    if ( !store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode] ) {
      return;
    }

    XingAPIService.MasterNightO(futCode, outBlock);
    
    //// console.log(`체결데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.dbCurPrice[futCode], futCode)}`);
  
    // 거래창에 전달
    // 챠트에 전달
    if (store.AccountState.G_Future_Code === futCode || store.AccountState.ChartFutures === futCode) {
      // PostMessage(WM_CONTRACT)
      const contractData = new ContractObj();
      contractData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_ContractData, { contractData });

      // tradingView 전달
      const tmpDate = `${contractData.szOrgDate.substring(0, 4)}-${contractData.szOrgDate.substring(4, 6)}-${contractData.szOrgDate.substring(6, 8)} ` + contractData.szCTime;
      const dataObj = {
        open: contractData.dbOpenPrice,
        high: contractData.dbHighPrice,
        low: contractData.dbLowPrice,
        close: contractData.dbCurPrice,
        time: new Date(tmpDate).getTime(),
        volume: contractData.iTrdq,
      }
      //// console.log('-- ', contractData);
      onChartDataReceived(dataObj);
    }
    
    // 데이터 받은거 설정
    store.MasterState.isRecvContract[futCode] = true;
  
    // 이전현재가와 현재가가 다르면.
    if (store.MasterState.dbBeforePrice[futCode] !== store.MasterState.dbCurPrice[futCode]) {
      // 현재가가 다르고 포지션 진입 상태이면 내 주문의 손실을 실시간 업데이트한다.
      // 평가손익, 실현손익, 평가 예탁금 등.
      // PostMessage(WM_TRANSGRID_PROFIT)
      const tradeType = store.AccountState.iOrderPos[futCode] === Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
      const totalOpposite = store.GetClientTotalOppositeAmount(futCode, tradeType);
      if (totalOpposite > 0) {
        // 변동 손익 화면 갱신
        NetworkService.emitter.emit(Emit_DrawTransProfit);
      }
  
      // 큐에 접어 넣는다.
      // mit, stoploss 처리용
      //CHTSClientMain::getSingletonPtr()->AddContractPriceQue(futCode, store.MasterState.dbCurPrice[futCode]);
  
      store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    }

    return;
  }

  static async [PacketID.PROTOCOL_FUT_NH0](packet) {
    const store = useStore();    

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 야간 선물 호가 셋팅한다.
    const futCode = Common.G_FUTURES_NIGHT;
  
    if (!store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode]) {
      return;
    }
  
    XingAPIService.MasterNightB(futCode, outBlock);
  
    // 호가창에 전달
    if (store.AccountState.G_Future_Code === futCode) {
      // PostMessage(WM_HOGA);
      const callData = new CallObj();
      callData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_CallData, { callData });
    }

    store.MasterState.isRecvHoga[futCode] = true;

    return;
  }

  static async [PacketID.PROTOCOL_FUT_OVC](packet) {
    // [트레이딩뷰 처리필요] 해외선물 체결가
    const store = useStore();    

    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 해외 선물 체결을 셋팅한다.
    let futCode = Common.G_FUTURES_MAX;
    // 종목코드
    const szSymbol = outBlock.readNString(8).trim();
    for(let idx = 0; idx < Common.G_FUTURES_MAX; idx++) {
      if (store.MarketState.szFutureCode[idx] === szSymbol) {
        futCode = idx;
        break;
      }
    }
  
    if (store.debugLog >= Constant.DebugLevel_Verbose) {
        // console.log(`체결데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.dbCurPrice[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);
    }

    if (futCode === Common.G_FUTURES_MAX) {
      return Common.NO_ERROR;
    }

    if (!store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode]) {
      return;
    }

    XingAPIService.MasterEuroO(futCode, outBlock);

    //// console.log(`체결데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.dbCurPrice[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);

    // 거래창에 전달
    // 챠트에 전달
    if (store.AccountState.G_Future_Code === futCode || store.AccountState.ChartFutures === futCode) {
      // PostMessage(WM_CONTRACT)
      const contractData = new ContractObj();
      contractData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_ContractData, { contractData });
    
      // tradingView 전달
      const tmpDate = `${contractData.szOrgDate.substring(0, 4)}-${contractData.szOrgDate.substring(4, 6)}-${contractData.szOrgDate.substring(6, 8)} ` + contractData.szCTime;
      const dataObj = {
        open: contractData.dbOpenPrice,
        high: contractData.dbHighPrice,
        low: contractData.dbLowPrice,
        close: contractData.dbCurPrice,
        time: new Date(tmpDate).getTime(),
        volume: contractData.iTrdq,
      }
      //// console.log('-- ', contractData);
      onChartDataReceived(dataObj);
    }

    // 데이터 받은거 설정
    store.MasterState.isRecvContract[futCode] = true;
  
    // 이전현재가와 현재가가 다르면.
    if (store.MasterState.dbBeforePrice[futCode] !== store.MasterState.dbCurPrice[futCode]) {
      // 현재가가 다르고 포지션 진입 상태이면 내 주문의 손실을 실시간 업데이트한다.
      // 평가손익, 실현손익, 평가 예탁금 등.
      // PostMessage(WM_TRANSGRID_PROFIT)
      const tradeType = store.AccountState.iOrderPos[futCode] === Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
      const totalOpposite = store.GetClientTotalOppositeAmount(futCode, tradeType);
      if (totalOpposite > 0) {
        // 변동 손익 화면 갱신
        NetworkService.emitter.emit(Emit_DrawTransProfit);
      }
  
      // 큐에 접어 넣는다.
      // mit, stoploss 처리용
      //CHTSClientMain::getSingletonPtr()->AddContractPriceQue(futCode, store.MasterState.dbCurPrice[futCode]);
  
      store.MasterState.dbBeforePrice[futCode] = store.MasterState.dbCurPrice[futCode];
    }

    return;
  }

  static async [PacketID.PROTOCOL_FUT_OVH](packet) {
    const store = useStore();    
    
    const outBlock = new Packet(packet.id, packet.size, packet.buffer, packet.readPos);
    // 해외 선물 호가를 셋팅한다.
    let futCode = Common.G_FUTURES_MAX;
    // 종목코드
    const szSymbol = outBlock.readNString(8).trim();
    for(let idx = 0; idx < Common.G_FUTURES_MAX; idx++) {
      if (store.MarketState.szFutureCode[idx] === szSymbol) {
        futCode = idx;
        break;
      }
    }
  
    if (store.debugLog >= Constant.DebugLevel_All) {
        // console.log(`호가데이터 : [${Common.g_szFuturesName[futCode]}] ${Utils.PriceFormatStr(store.MasterState.offerho1[futCode], futCode)}, ${Utils.PriceFormatStr(store.MasterState.bidho1[futCode], futCode)}, isReadyRECV: ${store.MasterState.isReadyRECV}`);
    }

    if (futCode === Common.G_FUTURES_MAX) {
      return Common.NO_ERROR;
    }

    if (!store.MasterState.isReadyRECV || !store.MarketState.bDrawMenu[futCode]) {
      return;
    }

    XingAPIService.MasterEuroB(futCode, outBlock);

    // 호가창에 전달
    if (store.AccountState.G_Future_Code === futCode) {
      // PostMessage(WM_HOGA);
      const callData = new CallObj();
      callData.fromMaster(futCode, store.MasterState);
      NetworkService.emitter.emit(Emit_CallData, { callData });
    }

    store.MasterState.isRecvHoga[futCode] = true;

    return;
  }

  static async [PacketID.PROTOCOL_FUT_FDC](packet) {
    // 주간선물 일봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const termPeriod = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_t8416OutBlock1(futures, termPeriod, blockCount, packet);
  
    return;
  }

  static async [PacketID.PROTOCOL_FUT_FMC](packet) {
    // 주간선물 분봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const term = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_t8415OutBlock1(futures, term, blockCount, packet);
    return;
  }

  static async [PacketID.PROTOCOL_FUT_FTC](packet) {
    // 주간선물 틱봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const term = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_t8414OutBlock1(futures, term, blockCount, packet);
    return;
  }

  static async [PacketID.PROTOCOL_FUT_OVDC](packet) {
    // 해외선물 일봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const termPeriod = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_o3128OutBlock1(futures, termPeriod, blockCount, packet);
    return;
  }

  static async [PacketID.PROTOCOL_FUT_OVMC](packet) {
    // 해외선물 분봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const term = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_o3123OutBlock1(futures, term, blockCount, packet);
    return;
  }

  static async [PacketID.PROTOCOL_FUT_OVTC](packet) {
    // 해외선물 틱봉
    const bLast = packet.readInt();
    const futures = packet.readInt();
    const term = packet.readInt();
    const blockCount = packet.readInt();

    const readPos = packet.readPos;
    XingAPIService.Append_o3137OutBlock1(futures, term, blockCount, packet);
    return;
  }

  static async [PacketID.PROTOCOL_EXCHAGE_RATE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_INDEX_DATA](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_SIGNUP](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CHECK_AGENT](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_REALDATASESSION](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CONTRACT_PRICE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CALL_PRICE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_ACCINFO](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_ACCINFO_DEPOSITS](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_REAL_ORDER](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_REAL_MODIFY_ORDER](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_REAL_CANCEL_ORDER](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_BANKACCOUNTS_INFO](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_KOSPI200_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_KOSPI200_ANS](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_FUT_EXPIRE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_BASE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOGIN_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOGIN_ACK](packet) { //미체결 리스트
    const store = useStore();

    const loginResult = packet.readInt();
    let loginMsg = packet.readString();
    if (loginResult === NetConstants.LoginResult.C_LOGIN_SUCCESS) {
      // 기존 주문 초기화
      // 화면 갱신
      // 로그인 성공 설정

      // 이거 다 하는중..???

      await store.loadLoginPageError();
      
      if(store.LoginPageState.errorState && store.LoginPageState.errorState === 1) {
        const message = "동일 브라우저에서 중복 로그인이 확인되었습니다";

        // 자동 로그인 플래그 off
        store.updateLoginPageState(0);
        store.updateLoginPageError(1, message);
        store.LoginPageState.isReloadRequired = true;

        store.setVisibleDialog({ message: message, callback: function() {
          NetworkService.emitter.emit(Emit_ResetMoveToLogin, { packetId: packet.id });
        } });
      }
      else {
        // 자동 로그인 플래그
        store.updateLoginPageState(1);

        // 메인화면 전환
        NetworkService.emitter.emit(Emit_Success, { packetId: packet.id });
      }
    } else {
      if (!loginMsg) {
        if (loginResult < NetConstants.LoginResult.C_LOGIN_MAX) {
          loginMsg = NetConstants.g_szLoginResultName[loginResult];
        } else {
          loginMsg = NetConstants.g_szLoginResultName[NetConstants.LoginResult.C_LOGIN_SYSTEM_ERR];
        }
      }

      // 자동 로그인 플래그 off
      store.updateLoginPageState(0);
      store.updateLoginPageError(1, loginMsg);
      store.LoginPageState.isReloadRequired = true;

      // 네트워크 재설정

      // 로그인 메세지
      const message = `로그인에 실패하였습니다.\n${loginMsg}`;
      store.setVisibleDialog({ message: message, callback: function() {
        NetworkService.emitter.emit(Emit_LoginFailed, { packetId: packet.id, loginResult: loginResult, loginMsg: loginMsg });
      } });
    }
    return;
  }

  static async [PacketID.PROTOCOL_USER_INFO_ACK](packet) {
    const store = useStore();

		//		사용자구분(INT)
    store.AccountState.iUserType = packet.readInt();
		//		회원등급(INT)
    store.AccountState.iGrade = packet.readInt();
		//		필명(string)
    const szNick = packet.readString();
		store.AccountState.szNick = szNick;
		//		이름(string)	: 20
    const szName = packet.readString();
		store.AccountState.szName = szName;
		//		예금주(string)	: 50
    const szBankHolder = packet.readString();
		store.AccountState.szBankHolder = szBankHolder;
		//		은행명(string)	: 50
    const szBank = packet.readString();
		store.AccountState.szBank = szBank;
		//		계좌번호(string): 50
    const szAccountNo = packet.readString();
		store.AccountState.szAccountNo = szAccountNo;
		//
    let iIDType = Common.C_IDTYPE_STD;
		//		회원탈퇴(BOOL)	: 1
		const bSeccession =  packet.readInt();
		if (bSeccession) {
			iIDType = Common.C_IDTYPE_SECESSION;
    }
		//		휴면계정(BOOL)	: 1
		const bDormant =  packet.readInt();
		if (bDormant) {
			iIDType = Common.C_IDTYPE_DORMANCY;
    }
		store.AccountState.iIDType = iIDType;
		//		IP(string)		: 30
    const szIP = packet.readString().trim();
		// 국내계좌비밀번호
    const szDomesticAccPwd = packet.readString();
		store.AccountState.szDomesticAccPwd = szDomesticAccPwd;
		// 해외계좌비밀번호
    const szOverseaAccPwd = packet.readString();
		store.AccountState.szOverseaAccPwd = szOverseaAccPwd;
		// 국내계좌비밀번호
    store.AccountState.iDomesticAccPwdErrCount = packet.readInt();
		// 해외계좌비밀번호
    store.AccountState.iOverseaAccPwdErrCount = packet.readInt();

    //// console.log(`szNick: ${szNick}, szName: ${szName}, szBank: ${szBank}, szAccountNo: ${szAccountNo}, szDomesticAccPwd: ${szDomesticAccPwd}, szOverseaAccPwd: ${szOverseaAccPwd}`);

    return;
  }

  static async [PacketID.PROTOCOL_USER_TIME_ACK](packet) {
    // 시간 동기화를 사용하지 않는다. 2014.09.11
    return;
  }

  static async [PacketID.PROTOCOL_USER_FUTURESTIME_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_FUTURESENABLE_ACK](packet) {
    const store = useStore();

    //		선물종류 (INT)	: 2
    const futures = packet.readInt();
    //		거래가능여부 (BOOL)	: 1
    store.MarketState.bExchangeable[futures] = Boolean(packet.readInt());

    return;
  }

  static async [PacketID.PROTOCOL_USER_FUTURESINFO_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_FUTURESINFO_ACK](packet) {
    const store = useStore();

    //		선물종류 (INT)	: 2
    const futures = packet.readInt();
    //		종목코드 (string)	: 8
		store.MarketState.szFutureCode[futures] = packet.readString();
    //		종목명 (string)	: 30
    const szFutureName = packet.readString();
    if (futures === Common.G_FUTURES_DAY) {
      store.MarketState.szFutureName[futures] = `KOSPI200 선물 ${szFutureName.substring(szFutureName.length - 2)}월물`;
    } else if (futures === Common.G_FUTURES_NIGHT) {
      store.MarketState.szFutureName[futures] = `CME 야간선물 ${szFutureName.substring(szFutureName.length - 2)}월물`;
    } else {
      store.MarketState.szFutureName[futures] = szFutureName;
    }
    // 개장여부
    store.MarketState.bTodayOpen[futures] = Boolean(packet.readInt());
    //		장개장시간 (string->COleDateTime)	: 19 yyyy-mm-dd hh:ss:ss 
    const szOpen = packet.readString();
    //		장종료시간 (string->COleDateTime)	: 19 yyyy-mm-dd hh:ss:ss 
    const szClose = packet.readString();
    if (szOpen !== '0000-00-00 00:00:00' && szOpen.trim() !== '') {
      store.MarketState.tOpen[futures] = moment(szOpen).toDate();
      // 동시호가 시간
      store.MarketState.tBuyOnOpening[futures] = store.MarketState.tOpen[futures];
      if (szClose.trim() !== '') {
        store.MarketState.tClose[futures] = moment(szClose).toDate();
      }
    }

    // 거래시간
    const tradeStart = packet.readString();
    const tradeEnd = packet.readString();
    if (tradeStart !== '0000-00-00 00:00:00' && tradeStart.trim() !== '') {
      store.MarketState.tTradeStart[futures] = moment(tradeStart).toDate();
      if (tradeEnd !== '0000-00-00 00:00:00' && tradeEnd.trim() !== '') {
        store.MarketState.tTradeEnd[futures] = moment(tradeEnd).toDate();
      }
    }
    //		운영수수료 (double)	: 11
    store.MarketState.dbCommission[futures] = packet.readDouble();
    //		최대계약 (INT)	: 5
    store.MarketState.iMaxContract[futures] = packet.readInt();
    //	거래종료일: yyyy-mm-dd 오전 HH:MM:ss 
    let szExpireDate = packet.readString();
    // 2023-03-09 오후 15:40:00, 2021-03-11 오전 5:40:00
    // 오전, 오후를 제거한다.
    szExpireDate = Utils.OleToSysTimeStr(szExpireDate)
    store.MarketState.szFutureExpireDate[futures] = szExpireDate;
    if (szExpireDate !== '0000-00-00 00:00:00' && szExpireDate.trim() !== '') {
      let expireDate = moment(szExpireDate).toDate();
      if (!isNaN(expireDate)) {
        // 시간은 0으로하고 남은 날자 계산
        if (store.MarketState.tOpen[futures] && !isNaN(store.MarketState.tOpen[futures])) {
          // 날자만 넣는다.
          expireDate = new Date(expireDate.getFullYear(), expireDate.getMonth(), expireDate.getDate());
          // 만기일은 종료날자 기준이다.
          let endDate = store.MarketState.tClose[futures];
          endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
          const diffDays = moment(expireDate).diff(moment(endDate), 'days');
          store.MarketState.iFutureExpireLeftDay[futures] = diffDays + 1;
        }

        // 해외는 만기일이 종료날자 기준이다.
        if (futures >= Common.G_FUTURES_OVERSEAS) {
          expireDate = moment(expireDate).add(-1, 'day').toDate();
        }
        // yyyy-mm-dd 오전 HH:MM:ss  -> yyyy-mm-dd HH:MM:ss 
        store.MarketState.szFutureExpireDate[futures] = moment(expireDate).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    // 오버나잇 가능여부
    store.MarketState.bOvernightOpenToday[futures] = Boolean(packet.readInt());
    // 유장
    const bUseBreak1 = Boolean(packet.readInt());
    const break1Start = packet.readString();
    const break1End = packet.readString();
    const bUseBreak2 = Boolean(packet.readInt());
    const break2Start = packet.readString();
    const break2End = packet.readString();
    // 주문시간 차이 설정
    let startSpan, endSpan;
    if (store.MarketState.tTradeStart[futures] && !isNaN(store.MarketState.tTradeStart[futures]) && store.MarketState.tOpen[futures] && !isNaN(store.MarketState.tOpen[futures])) {
      startSpan = store.MarketState.tTradeStart[futures].getTime() - store.MarketState.tOpen[futures].getTime();
    }
    if (store.MarketState.tTradeEnd[futures] && !isNaN(store.MarketState.tTradeEnd[futures]) && store.MarketState.tClose[futures] && !isNaN(store.MarketState.tClose[futures])) {
      endSpan = store.MarketState.tTradeEnd[futures].getTime() - store.MarketState.tClose[futures].getTime();
    }
    // 브레이크1
    store.MarketState.bUseBreak1[futures] = bUseBreak1;
    if (break1Start !== '0000-00-00 00:00:00' && break1Start.trim() !== '') {
      store.MarketState.tBreak1Start[futures] = new Date(moment(break1Start).toDate().getTime() + startSpan);
    }
    if (break1End !== '0000-00-00 00:00:00' && break1End.trim() !== '') {
      store.MarketState.tBreak1End[futures] = new Date(moment(break1End).toDate().getTime() + endSpan);
    }
    // 브레이크2
    store.MarketState.bUseBreak2[futures] = bUseBreak2;
    if (break2Start !== '0000-00-00 00:00:00' && break2Start.trim() !== '') {
      store.MarketState.tBreak2Start[futures] = new Date(moment(break2Start).toDate().getTime() + startSpan);
    }
    if (break2End !== '0000-00-00 00:00:00' && break2End.trim() !== '') {
      store.MarketState.tBreak2End[futures] = new Date(moment(break2End).toDate().getTime() + endSpan);
    }

    // 동시호가 시간을 확인하여 세팅.
    if(store.MarketState.tBuyOnOpeningMinute > 0) {
      store.MarketState.tBuyOnOpening[futures] = moment(store.MarketState.tBuyOnOpening[futures]).add(-store.MarketState.tBuyOnOpeningMinute, 'minute').toDate();
    }

    // 장정보를 새로 받으면 마스터 코드를 새로 그린다.
    store.InitMasterCode(); 
    //InitMasterCodeMini();

    return;
  }

  static async [PacketID.PROTOCOL_USER_MASTER_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MASTER_ACK](packet) {
    const futures = packet.readInt();
    const priceType = packet.readInt();
    const nullData = Buffer.allocUnsafe(NetConstants.PACKET_CvS_ID_LEN);
    packet.readData(nullData);

    //// console.log(`마스터: ${Common.g_szFuturesName[futures]}, ${priceType}`);

    if ( Common.G_FUTURES_DAY === futures ) {
      if ( Common.G_REALTYPE_CURRENT === priceType ) {
        XingAPIService.OnRecvMasterDayO(packet);
      } else if ( Common.G_REALTYPE_ASKING === priceType ) {
        XingAPIService.OnRecvMasterDayB(packet);
      }
    } else if ( Common.G_FUTURES_NIGHT === futures ) {
      if ( Common.G_REALTYPE_CURRENT === priceType ) {
        XingAPIService.OnRecvMasterNightO(packet);
      } else if ( Common.G_REALTYPE_ASKING === priceType ) {
        XingAPIService.OnRecvMasterNightB(packet);
      }
    } else {
      if ( Common.G_REALTYPE_CURRENT === priceType ) {
        XingAPIService.OnRecvMasterEuroO(packet);
      } else if ( Common.G_REALTYPE_ASKING === priceType ) {
        XingAPIService.OnRecvMasterEuroB(packet);
      }
    }

    return;
  }
  
  static async [PacketID.PROTOCOL_USER_ACCOUNT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_ACCOUNT_ACK](packet) {
    const store = useStore();

    if(store.MasterState.isReadyRECV) {
      return;
    }

    //		계좌번호(string)	: 15
    const szDepoAccNo = packet.readString();
    store.AccountState.szDepoAccNo = `${szDepoAccNo}-31`;
    store.AccountState.szDepoAccNoOversea = `${szDepoAccNo}-32`;
    //		레버리선물지(INT)	: 3
    const iLeverage = packet.readInt();
    store.AccountState.iLeverage = iLeverage;
    //		레버리지유로(INT)	: 3
    const iLeverageEuro = packet.readInt();
    store.AccountState.iLeverageEuro = iLeverageEuro;
    //		개장시예탁총액(INT)	: 10
    store.AccountState.iDepoAmountWhenOpen = packet.readInt();
    //		개장시예탁총액 해외(INT)	
    store.AccountState.iDepoAmountOverseaWhenOpen = packet.readInt();
    //		실현손익(INT)	: 10
    store.AccountState.iRealGainNLoss = packet.readInt();
    //		입금액(INT)	: 10
    store.AccountState.iDepositMoney = packet.readInt();
    //		입금횟수(INT)	: 3
    store.AccountState.iDepositNums = packet.readInt();
    //		출금액(INT)	: 10
    store.AccountState.iWithdrawMoney = packet.readInt();
    //		예탁총액(INT)	: 10
    const iDepoAmount = packet.readInt();
    store.AccountState.iDepoAmount = iDepoAmount;
    store.AccountState.iDeopAmountLeverage = iDepoAmount * iLeverage; //레버리지 반영 예탁 총액
    //		예탁총액 해외(INT)
    const iDepoAmountOversea = packet.readInt();
    store.AccountState.iDepoAmountOversea = iDepoAmountOversea;
    store.AccountState.iDeopAmountOverseaLeverage = iDepoAmountOversea * iLeverageEuro; //레버리지 반영 예탁 총액 해외
    //		수수료(INT)	: 10
    store.AccountState.iDepoCommission = packet.readInt();
    //		실현손익(INT)	: 10
    for(let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      store.AccountState.iRealGainNLossFutures[futCode] = packet.readInt();
    }
    //		수수료(INT)	: 10
    for(let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      store.AccountState.iDepoCommissionFutures[futCode] = packet.readInt();
    }
    //		이벤트 입금액(INT)	: 10
    store.AccountState.iEventDepositMoney = packet.readInt();

    // 계좌비번 저장 창이 열려있으면 계좌번호 업데이트
    NetworkService.emitter.emit(Emit_AccInfo);

    return;
  }

  static async [PacketID.PROTOCOL_USER_LOGOUT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOGOUT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_NOTICST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_NOTICST_ACK](packet) {
    const store = useStore();

    // 		제목 수(INT)		2 bytes		*  한패킷에 제목이 최대 8개라, NoTA를 5번 보낼 수 있음(한 패킷당 최대사이즈가 999bytes로 설정되어 한번에 보내는 수가 제한됨)
    const curCount = packet.readInt();
    // 		첫제목리스트(INT)		1 byte		* 첫번째 리스트여부, 1이면 첫리스트			
    const isFirst = packet.readInt();
    const isLast = packet.readInt();
  
    if (isFirst) {
      store.NoticeState.fetched = false;
      store.NoticeState.notices = [];
      store.NoticeState.curCount = 0;
      store.NoticeState.lastestIdx = Common.INDEX_NONE;
    }

    let curRow = store.NoticeState.curCount;
    for (let i = 0; i < curCount; i++) {
      // 		공지번호(INT)		4 bytes	<R>	
      const noticeidx = packet.readInt();
      //		필독여부(INT)		1 bytes	<R>	
      const isNotice = packet.readInt();
      //		게시날자(datetime yyyy-mm-dd hh:mm:ss)	19 bytes <R>	
      const regDate = packet.readString();
      regDate.trim();
      //		제목(String	80 bytes <R>
      const strTitle = packet.readString();
      strTitle.trim();
      //		작성자(String	20 bytes <R>
      const strAuthor = packet.readString();

      if (isNotice === Common.cNotice_Board) {
        // 전광판 공지 내용을 요청한다.
        NetworkService.send.noticeContentReq({ noticeIdx: noticeidx });
        continue;
      }

      let noticeObj = new NoticeObj();
      noticeObj.iNoticeIdx = noticeidx;
      noticeObj.iType = isNotice;
      noticeObj.szRegDate = regDate;
      noticeObj.szTitle = strTitle;
      noticeObj.szWriter = strAuthor;
      // 공지구분
      if (isNotice === Common.cNotice_Mustread) {
        noticeObj.szTypeName = '필수공지';
      } else if (isNotice === Common.cNotice_Urgent) {
        noticeObj.szTypeName = '긴급공지';
      } else {
        noticeObj.szTypeName = '일반공지';
      }

      store.NoticeState.notices.push(noticeObj);

      if (noticeidx > store.NoticeState.popupIdx) {
        store.NoticeState.popupIdx = noticeidx;
      }

      curRow++;
    }
    store.NoticeState.curCount = curRow;

    // 2023-09-18: 전성구 이사님 대화중 주석처리
    // 
    // if (isLast) {
    //   // 공지 내용 요청
    //   if (store.NoticeState.popupIdx !== Common.INDEX_NONE) {
    //     NetworkService.send.noticeContentReq({ noticeIdx: store.NoticeState.popupIdx });
    //   }
    //   store.NoticeState.fetched = true;
    // }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_NOTICSC_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_NOTICSC_ACK](packet) {
    const store = useStore();

    // 		공지번호(INT)		4 bytes
    const noticeidx = packet.readInt();
    // 공지종류
    const noticeType = packet.readInt();
    // 데이터 종류
    const bString = packet.readInt();
 
    // 일반 텍스트
    if (bString) {
      // 		전체번호(INT)		3 bytes
      const totalidx = packet.readInt();
      // 		현재번호(INT)		3 bytes
      const curidx = packet.readInt();
      //		내용 (String)		900 bytes
      const content = packet.readString();
      const bBoard = noticeType === Common.cNotice_Board;
      if (bBoard) {
        // 전광판은 스트링으로만 온다.
      } else {
        // 찾는다.
        const dataIdx = store.NoticeState.notices.findIndex(n => n.iNoticeIdx === noticeidx);
        if (dataIdx >= 0) {
          if (curidx === 0) {
            store.NoticeState.notices[dataIdx].szContents = '';
          }
  
          store.NoticeState.notices[dataIdx].szContents += content;

          if (curidx >= totalidx-1) {
            NetworkService.emitter.emit(Emit_NoticeContent, { notice: store.NoticeState.notices[dataIdx] });
          }
        }
      }
    } else {
      // rtf 형식
      const contentsLen = packet.readInt();
			// 		전체번호(INT)		3 bytes
      const maxSend = packet.readInt();
			// 		현재번호(INT)		3 bytes
      const curidx = packet.readInt();
			//		내용 (String)		900 bytes
      const dataLen = packet.readInt();

      const foundIdx = store.NoticeState.notices.findIndex(n => n.iNoticeIdx === noticeidx);
      if (foundIdx < 0) {
        // console.log(`해당 공지가 없습니다. ${noticeidx}`);
        return;
      }

			// 첫 데이터
			if (curidx === 0) {
        store.NoticeState.notices[foundIdx].contentBuff = Buffer.allocUnsafe(contentsLen);
			}
			const dataIdx = Common.cBlob_ContentsSize * curidx;
      packet.copy(store.NoticeState.notices[foundIdx].contentBuff, dataIdx, dataLen);

			// 마지막 데이터
			if (curidx >= maxSend - 1) {
        // // console.log(`rtf data: ${contentsLen}`);
        /*
        const contText = iconv.decode(NoticeState.notices[foundIdx].contentBuff, NetConstants.NETWORK_STRING_ENCODE);
        //const contText = 
        //`{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang1033{\\fonttbl{\\f0\\fnil\\fcharset0 Calibri;}}
        //{\\*\\generator Msftedit 5.41.21.2510;}\\viewkind4\\uc1\\pard\\sa200\\sl276\\slmult1\\lang9\\f0\\fs22 This \\fs44 is \\fs22 a \\b simple \\ul one \\i paragraph \\ulnone\\b0 document\\i0 .\\par
        //}`;
        RtfToHtml.fromString(contText, (err, html) => {
          // console.log(`rtf to html: ${html}`);
        });
        */
        NetworkService.emitter.emit(Emit_NoticeContent, { notice: store.NoticeState.notices[foundIdx] });
			}
    }
    return;
  }

  static async [PacketID.PROTOCOL_USER_EVENT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_EVENT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MONEYIN_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MONEYIN_ACK](packet) {
    const store = useStore();

    // 입출금 구분
    const isWithdraw = packet.readInt();
    const szName = Common.g_szTransTypeName[isWithdraw];// isWithdraw === Common.C_TRANS_WITHDRAW ? '출금' : isWithdraw === Common.C_TRANS_DEPOSIT ? '입금' : '대체';
    // 결과코드
    const resCode = packet.readInt();
    // 입출금 금액
    const amount = packet.readInt();
    // 선물 종류
    const futures = packet.readInt();
    // 가능금액
    let pAmount = packet.readInt();
    // 해외
    const isOversea = packet.readInt();
    const domesticOversea = Common.g_szNationName[isOversea];
  
    const szTitle = '입출금';
    let szMessage = '';
    // 결과값이 관리자 확인이면
    if (resCode === NetConstants.R_DEPONWITHDRAW_ADMINCONFIRM) {

      if (isWithdraw === Common.C_TRANS_WITHDRAW) {
        szMessage = `신청한 ${domesticOversea} 출금액이 처리 되었습니다.`;
      } else if(isWithdraw === Common.C_TRANS_DEPOSIT) {
        szMessage = `신청한 ${domesticOversea} 입금액이 처리 되었습니다.`;
      } else if (isWithdraw === Common.C_TRANS_TRANSFER) {
        const transferedTo = (isOversea ? '국내계좌로 대체' : '해외계좌로 대체');
        szMessage = `신청한 ${domesticOversea} 출금액이 \n${transferedTo} 처리 되었습니다.`;
      } else if(isWithdraw === Common.C_TRANS_EVENT) {
        szMessage = `이벤트 ${domesticOversea} 입금액이 처리 되었습니다.`;
      } else if(isWithdraw === Common.C_TRANS_ADMIN_DEPOSIT) {
        szMessage = `${domesticOversea} 입금처리가 완료 되었습니다.`;
      } else if(isWithdraw === Common.C_TRANS_ADMIN_WITHDRAW) {
        szMessage = `${domesticOversea} 출금처리가 완료 되었습니다.`;
      }

      // 팝업창을 띄운다.
      store.setVisibleDialog({ message: szMessage, title: szTitle });

    } else if(resCode === NetConstants.R_DEPONWITHDRAW_SUCCESS) {

      if (isWithdraw === Common.C_TRANS_TRANSFER) {
        const transferedTo = isOversea ? '해외계좌에서 국내계좌로 대체' : '국내계좌에서 해외계좌로 대체';
        szMessage = `${transferedTo}신청이 완료 되었습니다.\n신청하신 출금액 ${Utils.SetComma(amount)}원에 대한 대체신청을 완료했습니다.\n\n같은 내용으로 중복신청시 확인이 지연될 수 있으니 주의하십시오.`;
      } else if (isWithdraw === Common.C_TRANS_WITHDRAW) {
        szMessage = `출금신청이 완료 되었습니다.\n신청하신 출금액 ${Utils.SetComma(amount)}원에 대한 출금신청을 완료했습니다.\n\n같은 내용으로 중복신청시 확인이 지연될 수 있으니 주의하십시오.`
      } else {
        szMessage = `입금신청이 완료 되었습니다.\n입금신청액 : ${Utils.SetComma(amount)}원\n\n입금이 확인되면 WTS에 적용됩니다.`;
      }

      // 팝업창을 띄운다.
      store.setVisibleDialog({ message: szMessage, title: szTitle });

    } else if(resCode === NetConstants.R_DEPONWITHDRAW_FAIL_ADMIN) {

      szMessage = `${domesticOversea} ${szName}신청 금액이 취소 처리 되었습니다.`;

      // 팝업창을 띄운다.
      store.setVisibleDialog({ message: szMessage, title: szTitle });

    } else {

      if (isWithdraw === Common.C_TRANS_DEPOSIT && resCode === NetConstants.R_DEPONWITHDRAW_FAIL_EXCEED) {
        if (pAmount < 0) {
          pAmount = 0;
        } 
        szMessage = `${domesticOversea} 보유잔고를 초과 하여 ${Utils.SetComma(pAmount)}원만 입금 신청됩니다. \n초과 금액(${Utils.SetComma(amount-pAmount)}원)은 확인후 고객님 지정 계좌로 환불 됩니다.`;
      } else if (resCode === NetConstants.R_DEPONWITHDRAW_FAIL_DUPLICATED) {
        const szTrans = isWithdraw ? '출금' : '입금';
        szMessage = `${szTrans}신청 실패 - 이전 ${szTrans}신청이 완료되지 않았습니다.`;
      } else {
        szMessage = NetConstants.g_szDepoNWithdrawResultName[resCode];
      }
  
      // 팝업창을 띄운다.
      store.setVisibleDialog({ message: szMessage, title: szTitle });

    }
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    NetworkService.emitter.emit(Emit_ResetAmount, { isWithdraw });
  
    // console.log(`입출금 결과를 받았다: ${resCode === NetConstants.R_DEPONWITHDRAW_SUCCESS ? '성공' : '실패'}, ${Common.g_szFuturesNameMini[futures]}, ${amount}, ${szName}, ${domesticOversea}`);
  }

  static async [PacketID.PROTOCOL_USER_MONEY_ACK](packet) {
    const store = useStore();

    // 		예탁총액(INT)		10bytes		
    const depoAmount = packet.readInt();
    const depoAmountOversea = packet.readInt();
  
    //예탁총액 갱신
    store.AccountState.iDepoAmount = depoAmount;
    //예탁총액 해외 갱신
    store.AccountState.iDepoAmountOversea = depoAmountOversea;
  
    // 총 실현손익(INT)		10bytes		
    const realGainNLoss = packet.readInt();
    store.AccountState.iRealGainNLoss = realGainNLoss;
  
    // 실현손익 (INT)		10bytes		
    for (let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      const realGainNLossFutures = packet.readInt();
      store.AccountState.iRealGainNLossFutures[futCode] = realGainNLossFutures;
    }
  
    // 총 수수료(INT)		10bytes		
    const depoCommission = packet.readInt();
    store.AccountState.iDepoCommission = depoCommission;
  
    // 수수료 (INT)		10bytes		
    for (let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      const depoCommissionFutures = packet.readInt();
      store.AccountState.iDepoCommissionFutures[futCode] = depoCommissionFutures;
    }
  
    // 금일입금액(INT)		10bytes		
    const depositMoney = packet.readInt();
    store.AccountState.iDepositMoney = depositMoney;
  
    // 금일출금액(INT)		10bytes		
    const withdrawMoney = packet.readInt();
    store.AccountState.iWithdrawMoney = withdrawMoney;
  
    // 이벤트입금액(INT)		10bytes		
    const eventDepositMoney = packet.readInt();
    store.AccountState.iEventDepositMoney = eventDepositMoney;
  
    // 이벤트입금액해외(INT)		10bytes		
    const eventDepositMoneyOversea = packet.readInt();
    store.AccountState.iEventDepositMoneyOversea = eventDepositMoneyOversea;
  
    // 당일실현손익, 매매손익
    // RedrawTodayGainNLoss();
    // 평가 손익등 재 계산
    store.GetLeverageBalance();
  
    // 주문 그리드의 예탁총액 변경
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    const isWithdraw = Common.C_TRANS_MAX;
    NetworkService.emitter.emit(Emit_ResetAmount, { isWithdraw });

    // console.log(`예탁총액을 받았다: ${depoAmount}, 해외: ${depoAmountOversea}`);
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_BRANDINFO_ACK](packet) {
    const store = useStore();

    //		수익왕이벤트 사용(BOOL)	: 1
    store.BrandState.bEarningEvent = Boolean(packet.readInt());
    //		수익왕이벤트 사용(BOOL)	: 1
    store.BrandState.bEarningRateEvent = Boolean(packet.readInt());
    //		수익왕이벤트 사용(BOOL)	: 1
    store.BrandState.bRecomEvent = Boolean(packet.readInt());
    //		수익왕이벤트 사용(BOOL)	: 1
    store.BrandState.bLossEvent = Boolean(packet.readInt());
    //		브랜드 레버리지 정보(string)	: 50
    const szBrandLeverageInfo = packet.readString();
    store.BrandState.szBrandLeverageInfo = szBrandLeverageInfo;
    // Tokenize(" :") 처리
    const leverages = szBrandLeverageInfo.split(':');
    if (leverages.length > 0) {
      store.BrandState.leverages = leverages;
      store.BrandState.iMaxLeverage = parseInt(leverages[leverages.length - 1]);
    }
    //		입금 예금주(string)	: 50
    const szBrandAccountName = packet.readString();
		store.BrandState.szBrandAccountName = szBrandAccountName;
    //		입금 은행명(string)	: 50
    const szBrandAccountBank = packet.readString();
		store.BrandState.szBrandAccountBank = szBrandAccountBank;
    //		입금 계좌번호(string) : 50
    const szBrandAccountNo = packet.readString();
		store.BrandState.szBrandAccountNo = szBrandAccountNo;
    // 은행계좌 사용 여부
    store.BrandState.bBrandUseBank = Boolean(packet.readInt());
    // 계좌가 없을 때 기본 메세지
		store.BrandState.szBrandBankMessage = packet.readString();
    //		출금시작(string)	: 4
		store.BrandState.szBrandWithdrawO = packet.readString();
    //		출금종료(string)	: 4
		store.BrandState.szBrandWithdrawC = packet.readString();
    //		채팅시작(string)	: 4
		store.BrandState.szBrandChatO = packet.readString();
    //		채팅종료(string)	: 4
		store.BrandState.szBrandChatC = packet.readString();
    //		대여HTS(BOOL)	: 1
    store.BrandState.isRent = Boolean(packet.readInt());
    //		기준환율(DOUBLE)	: 11
    store.BrandState.ExchangeRate = packet.readDouble();
    store.BrandState.ExchangeRateHKD = packet.readDouble();
    //		주간 선물 틱 가치(INT)	: 6
    let iTickPrice = packet.readInt();
    store.BrandState.G_TICK_PRICE[Common.G_FUTURES_DAY] = iTickPrice;
    store.BrandState.G_MICRO_TICK_PRICE[Common.G_FUTURES_DAY] = Utils.GetMicroOneTick(Common.G_FUTURES_DAY);
    //		야간 선물 틱 가치(INT)	: 6
    iTickPrice = packet.readInt();
    store.BrandState.G_TICK_PRICE[Common.G_FUTURES_NIGHT] = iTickPrice;
    store.BrandState.G_MICRO_TICK_PRICE[Common.G_FUTURES_NIGHT] = Utils.GetMicroOneTick(Common.G_FUTURES_NIGHT);
    //		틱밸루(DOUBLE)	: 11
    for(let futCode = Common.G_FUTURES_OVERSEAS; futCode < Common.G_FUTURES_MAX; futCode++) {
      const tickValue = packet.readDouble();
      store.BrandState.G_TICK_PRICE[futCode] = Math.round(tickValue * (futCode === Common.G_FUTURES_HANGSENG ? store.BrandState.ExchangeRateHKD : store.BrandState.ExchangeRate));
      store.BrandState.G_MICRO_TICK_PRICE[futCode] = Utils.GetMicroOneTick(futCode);
    }
    //		고객센터(string)	: 50
    const szTel = packet.readString();
		store.BrandState.szTel = szTel;

    // 동시호가 시간 분단위
    store.MarketState.tBuyOnOpeningMinute = packet.readInt();

    // 국내/해외 선물 증거금
    store.BrandState.iDomesticFuturePrice = packet.readInt();
    store.BrandState.iOverseaFuturePrice = packet.readInt();
    // 국내/해외 오버 담보금
    store.BrandState.iDomesticOvernightDeposit = packet.readInt();
    store.BrandState.iOverseaOvernightDeposit = packet.readInt();
    // 오버나잇 마감 전
    store.BrandState.iOvernightMinBefore = packet.readInt();
    // 동시호가, 만기결제 담보금
    store.BrandState.iOverTradingDeposit = packet.readInt();

    // 채팅설정
    if(store.SystemState._helpdeskMode === 2){
      store.BrandState.bExternalChat = packet.readInt();
      store.BrandState.szTelegramUrl = packet.readString();
      store.BrandState.szKakaoUrl = packet.readString();
    }

    // 오버나잇 필요 담보금을 새로 그린다.

    // 입출금창의 입금 계좌번호 변경

    return;
  }

  static async [PacketID.PROTOCOL_USER_PASSWORD_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PASSWORD_ACK](packet) {
    const store = useStore();

    // 		성공(INT)		1 bytes (0:성공, 1:실패)	
    const nResult = packet.readInt();	
    let szMsg = '', callback = null;

    if (nResult === NetConstants.R_CHNGPWD_SUCCESS) {	
      szMsg = '접속 비밀번호를 변경하였습니다.\n\n앱을 재시작합니다.';	

      // 자동 로그인 플래그 off
      store.updateLoginPageState(0);
      store.updateLoginPageError(1, szMsg);
      store.LoginPageState.isReloadRequired = true;
      
      store.setVisibleDialog({ message: szMsg, callback: function() {
        NetworkService.emitter.emit(Emit_ResetMoveToLogin, { packetId: packet.id });
      } });
      
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_WRONGPWD) {	
      szMsg = '현재 비밀번호가 맞지 않습니다.\n정확한 비밀번호를 입력해주시기 바랍니다.';	
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_WRONGFMT) {	
      szMsg = '비밀번호는 6~8자리 영문, 숫자 여야 합니다.';	
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_SYSTEM) {	
      szMsg = '시스템 장애가 발생했습니다.\n잠시후에 다시 시도하여 주세요.';	
    }	
    store.setVisibleDialog({ message: szMsg, callback });	

    NetworkService.emitter.emit(Emit_LoginPasswordUpdate, { nResult: nResult, szMsg: szMsg });
    return;	
  }

  static async [PacketID.PROTOCOL_USER_LEVERAGE_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LEVERAGE_ACK](packet) {
    const store = useStore();

    // 		선물 종류(INT)		2 byte		
    const futures = packet.readInt();
    // 		새 레버리지(INT)		3byte		*성공하였을 경우 새로운 레버리지, 실패일 경우 기존 레버리지
    const newLeverage = packet.readInt();
    // 		변경결과(INT)		1byte		
    const resultCode = packet.readInt();
  
    if (resultCode === NetConstants.R_CHNGLEVERAGE_SUCCESS) {
      store.AccountState.iLeverage = newLeverage;
      store.AccountState.iLeverageEuro = newLeverage;
  
      store.GetLeverageBalance();

      let szMessage = `레버리지 설정이 변경되었습니다.`;
      store.setVisibleToast({ message: szMessage });
    } else {
      let szMessage = '';
      if (resultCode === NetConstants.R_CHNGLEVERAGE_FAIL_WRONG) {
        szMessage = `요청된 레베리지 1:${newLeverage}는 적용 불가능합니다.`;
      } else if (resultCode === NetConstants.R_CHNGLEVERAGE_FAIL_ORDERING) {
        szMessage = '주문 중에는 레베리지를 변경할 수 없습니다.';
      } else {
        szMessage = '시스템 장애가 발생했습니다.\n잠시후에 다시 시도하여 주세요.';
      }
  
      store.setVisibleDialog({ message: szMessage });
    }
    return;
  }

  static async [PacketID.PROTOCOL_USER_ORDER_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_ORDER_ACK](packet) {
    const store = useStore();

    // 		결과코드(INT)		1 byte		* 주문이 성공하였을 경우 주문번호	
    const resultCode = packet.readInt();
    // 		선물 종류(INT)		2 byte			
    const futures = packet.readInt();
    // 		주문번호(INT)		10 bytes		* 주문이 성공하였을 경우 주문번호	
    const orderIdx = packet.readInt();
    // 		매매구분(INT)		1 byte			
    const tradeType = packet.readInt();
    // 		가격유형(INT)		1 byte			
    const priceType = packet.readInt();
    // 		주문가격(DOUBLE)		11 bytes		00000.00000	
    const orderPrice = packet.readDouble();
    // 		주문수량(INT)		3 bytes			
    const orderAmount = packet.readInt();
    // 		주문방법(INT)		2 bytes			
    const orderMethod = packet.readInt();
    // 		틱(INT)		3 bytes		* 주문방법이 스탑로스일경우 틱수
    const ticks = packet.readInt();
    // 		로그번호(INT)		10 bytes		* 주문방법이 MIT, 스탑로스인경우 해당 로그번호
    const logNum = packet.readInt();
    // 		주문우선순위(INT)		5 bytes				
    const orderPriority = packet.readInt();
    // 장외거래
    const overTrading = packet.readInt();
    // 오버나잇 상태
    const overnightState = packet.readInt();
    // 오버나잇 로그 번호 - 없음
  
    if (resultCode !== NetConstants.R_ORDER_Success) {
      // 주문 실패 응답
 
      if (orderMethod === Common.C_ORDERMETHOD_StopLoss) {
        // 주문을 그린다.  WM_TRANSGRID
        NetworkService.emitter.emit(Emit_DrawTrans, { });
      }
  
      // console.log(`주문에 실패하였다: ${NetConstants.g_szOrderResultName[resultCode]}`);
  
      const szMsg = `[${Common.g_szFuturesNameMini[futures]}] 주문이 거부되었습니다. [CODE:${NetConstants.g_szOrderResultName[resultCode]}]`;
      store.LogMsg(szMsg, '', store.SettingsState.settings.bRefusePush);
      return;
    }
  
    // 주문을 추가
    const lpOrderMap = store.AccountState.FuturesOrders[futures][tradeType];
    let orderObj = new OrderObj();
    // 주문을 채워넣는다.
    orderObj.iOrderIdx = orderIdx;			// 주문번호
    orderObj.iFutures = futures;			// 거래종목
    orderObj.iTradeType = tradeType;			// 매매구분
    orderObj.dbOrderPrice = orderPrice;	// 주문가격
    orderObj.iOrderAmount = orderAmount;		// 주문수량
    orderObj.iLeftAmount = orderAmount;		// 미체결수량
    orderObj.iPriceType = priceType;
    orderObj.iPriorityCount = orderPriority;		// 주문 우선순위(주문시 호가 잔량), 체결에따라 실시간으로 변동
    orderObj.iOverTrading = overTrading;
    orderObj.iOvernightState = overnightState;
    orderObj.iOvernightLogIdx = -1;
    orderObj.iStopLossLogIdx = -1;
    orderObj.iProfitTicks = 0;
    orderObj.iLossTicks = 0;
    // 주문 맵에 넣는다.
    store.AccountState.FuturesOrders[futures][tradeType] = [ ...lpOrderMap, orderObj ];
  
    // 주문을 그린다.
    // 호가창 주문
    // DrawOrder(orderObj, FALSE, bDrawRemain); 
    store.GetLeverageBalance();
    // 주문리스트 그리드
    // WM_TRANSGRID
    // 잔량 추가 표시는 일반 계정
    const bDrawRemain = !store.AccountState.bMock;
    //DrawOrder(orderObj, FALSE, bDrawRemain);
    NetworkService.emitter.emit(Emit_DrawTrans, { });
  
    // 주문 및 체결 소리 알림 사용
  
    // 주문이 성공하였다.
    // console.log(`주문이 성공하였다: ${Common.g_szFuturesNameMini[futures]}, 주문번호:${orderIdx}, ${Common.g_szTradeTypeName[tradeType]}, ${Common.g_szPriceTypeName[priceType]}, ${orderPrice}, ${orderAmount}, ${Common.g_szOrderMethodName[orderMethod]}, 로스컷틱:${ticks}, 로그번호:${logNum}`);
  
    let strPrice;
    if (priceType === Common.G_PRICE_MARKET) {
      strPrice = Common.g_szPriceTypeName[priceType];
    } else {
      strPrice = Utils.PriceFormatStr(orderPrice, futures);
    }
  
    if (orderMethod === Common.C_ORDERMETHOD_StopLoss) {
      // 모든 대기 주문 활성화
      const oppTradeType = (tradeType === Common.G_TRADE_SELL ? Common.G_TRADE_BUY : Common.G_TRADE_SELL);
      const lpOppOrderMap = store.AccountState.FuturesOrders[futures][oppTradeType];
      if (lpOppOrderMap) {
        lpOppOrderMap.forEach(curOrd => {
          if (curOrd.iLeftAmount > 0) {
            NetworkService.send.tradeWait({ orderIdx: curOrd.iOrderIdx, bStop: false });
          }
        });
      }
    } else {
      // 주문내역과 포지션이 같을경우 스탑로스 여부 체크
      const lpStopLossObj = store.AccountState.FuturesStopLoss[futures];
      if (lpStopLossObj.iTradeType === tradeType) {
        if (lpStopLossObj.bUse && lpStopLossObj.dbOrderPrice > 0. && lpStopLossObj.bUseTick[StopLoss.C_StopLossTick_Loss]) {
          const lossTick = (lpStopLossObj.iTradeType===Common.G_TRADE_SELL ? -lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] : lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss]);
          const lossRow = lossTick + Utils.GetPriceLine(store.MasterState.sHoga[futures], lpStopLossObj.dbOrderPrice, futures);
  
          const priceRow = Utils.GetPriceLine(store.MasterState.sHoga[futures], orderPrice, futures);
          if (lpStopLossObj.iTradeType === Common.G_TRADE_SELL ? priceRow  <= lossRow : priceRow >= lossRow) {
            if(lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] > 0) {
              NetworkService.send.tradeWait({ orderIdx: orderIdx, bStop: true });
            }
          }
        }
      }
    }
  
    let szMsg;
    if (orderMethod === Common.C_ORDERMETHOD_Merge) {
      szMsg = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 주문 수정 성공. (주문번호:${orderIdx}, 가격:${strPrice}, 수량:${orderAmount})`;
    } else {
      szMsg = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szPriceTypeName[priceType]} ${Common.g_szTradeTypeName[tradeType]} 주문 접수 완료. (주문번호:${orderIdx}, 가격:${strPrice}, 수량:${orderAmount})`;
    }
  
    // 미체결 주문의 스탑로스 예시 그리기 설정
    store.SetClientStopLossExamples(futures);
  
    let isShowLog = true;
    if (orderMethod === Common.C_ORDERMETHOD_Merge) {
      isShowLog = store.SettingsState.settings.bModifyConfirm;
    }
    else {
      if(tradeType === Common.G_TRADE_SELL) isShowLog = store.SettingsState.settings.bSellConfirm;
      else if(tradeType === Common.G_TRADE_BUY) isShowLog = store.SettingsState.settings.bBuyConfirm;
    }
    
    store.LogMsg(szMsg, '', isShowLog);

    return;
  }

  static async [PacketID.PROTOCOL_USER_MODIFY_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MODIFY_ACK](packet) {
    const store = useStore();

    // 		결과코드(INT)		1 byte		* 주문이 성공하였을 경우 주문번호	
    const resultCode = packet.readInt();
    // 		선물 종류(INT)		2 byte			
    const futures = packet.readInt();
    // 		주문번호(INT)		10 bytes		* 주문이 성공하였을 경우 주문번호	
    const newOrderIdx = packet.readInt();
    // 원주문 번호
    const orgOrderIdx = packet.readInt();
    // 		매매구분(INT)		1 byte			
    const tradeType = packet.readInt();
    // 		수정구분(INT)		1 byte			
    const modifyMethod = packet.readInt();
    // 		기존 주문가격(DOUBLE)		11 bytes		00000.00000
    let oldOrderPrice = packet.readDouble();
    // 		새 주문가격(DOUBLE)		11 bytes		00000.00000
    const orderPrice = packet.readDouble();
    // 		주문수량(INT)		3 bytes			
    const orderAmount = packet.readInt();
    // 		주문방법(INT)		2 bytes			
    const orderMethod = packet.readInt();
    // 		틱(INT)		3 bytes		* 주문방법이 스탑로스일경우 틱수
    const ticks = packet.readInt();
    // 		로그번호(INT)		10 bytes		* 주문방법이 MIT, 스탑로스인경우 해당 로그번호
    const logNum = packet.readInt();
    // 		주문우선순위(INT)		5 bytes				
    const orderPriority = packet.readInt();
    // 오버나잇 상태, 오버나잇 로그 없음

    if (resultCode !== NetConstants.R_ORDER_Success) {
      // 주문 실패 응답
      // console.log(`주문수정에 실패하였다: ${NetConstants.g_szOrderResultName[resultCode]}`);
  
      const szMsg = `[${Common.g_szFuturesNameMini[futures]}] 수정 주문 실패 : ${NetConstants.g_szOrderResultName[resultCode]} (주문번호:${orgOrderIdx})`;
      store.LogMsg(szMsg);
      return;
    }
  
    // 기존 주문을 지운다.
    const lpOrderMap = store.AccountState.FuturesOrders[futures][tradeType];
    const orderObj = new OrderObj();
    if (modifyMethod === Common.C_ORDERMODIFY_BatchSettle) {
      const lpOrderPair = lpOrderMap.find(ord => ord.iOrderIdx === orgOrderIdx);
      if (lpOrderPair) {
        oldOrderPrice = lpOrderPair.dbContractPrice;
      }
    }
    // 기존 주문 삭제
    const delIdx = lpOrderMap.findIndex(ord => ord.iOrderIdx === orgOrderIdx);
    if (delIdx >= 0) {
      lpOrderMap.splice(delIdx, 1)
    }
  
    // 기존 체결 삭제 또는 잔량 추가 그리기
    // 잔량 추가 표시는 일반 계정
    /*
    const bDrawRemain = !store.AccountState.bMock;
    if( modifyMethod===Common.C_ORDERMODIFY_BatchSettle) {
      lpFutDlg->ClearOrder(tradeType, oldOrderPrice, CFuturesDlg::M_OrderDraw_Contracted, bDrawRemain);
    } else {
      lpFutDlg->DrawOrderChange(tradeType, oldOrderPrice, orderAmount, bDrawRemain);
    }
    */
  
    // 주문을 채워넣는다.
    if (modifyMethod===Common.C_ORDERMODIFY_AddAmount || modifyMethod===Common.C_ORDERMODIFY_SubAmount || modifyMethod===Common.C_ORDERMODIFY_ModifyPrice) {
      // 새로운 주문 생성
      orderObj.iOrderIdx = newOrderIdx;			// 주문번호
      orderObj.iFutures = futures;			// 거래종목
      orderObj.iTradeType = tradeType;			// 매매구분
      orderObj.dbOrderPrice = orderPrice;	// 주문가격
      orderObj.iOrderAmount = orderAmount;		// 주문수량
      orderObj.iLeftAmount = orderAmount;		// 미체결수량
      orderObj.iPriorityCount = orderPriority;		// 주문 우선순위(주문시 호가 잔량), 체결에따라 실시간으로 변동
      // 주문 맵에 넣는다.
      store.AccountState.FuturesOrders[futures][tradeType] = [ ...lpOrderMap, orderObj ];
  
      // 주문을 그린다.
      // lpFutDlg->DrawOrder(orderObj, 0, bDrawRemain);
    }
  
    // 취소인지 여부
    if (modifyMethod === Common.C_ORDERMODIFY_Cancel) {
      // 주문 및 체결 소리 알림 사용
  
      // 평가금액을 표시한다.
      store.GetLeverageBalance();
      // 평균가, 잔고수량등을 그린다.
      // DrawPosition(futures);
    } else {
      // 주문 및 체결 소리 알림 사용
    }
  
    // 주문수정이 성공하였다.
    // console.log(`주문수정이 성공하였다: ${Common.g_szFuturesNameMini[futures]}, 주문번호:${orgOrderIdx}(${newOrderIdx}), ${Common.g_szTradeTypeName[tradeType]}, ${Common.g_szOrderModifyName[modifyMethod]}, ${orderPrice}, ${orderAmount}, ${Common.g_szOrderMethodName[orderMethod]}, 로스컷틱:${ticks}, 로그번호:${logNum}`);
  
    // 미체결 주문의 스탑로스 예시 그리기 설정
    store.SetClientStopLossExamples(futures);
  
    NetworkService.emitter.emit(Emit_DrawTrans, { });
    // 호가창 다시 그리기 - 스탑로스 취소시 선을 지우기위해
    // WM_REFRESH_ASKING
  
    if (modifyMethod === Common.C_ORDERMODIFY_Cancel) {
      const strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 취소 주문 성공. (주문번호:${orgOrderIdx})`;
      store.LogMsg(strLog, '', store.SettingsState.settings.bCancelConfirm);
    } else {
      if (modifyMethod===Common.C_ORDERMODIFY_BatchSettle) {
        const strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 주문 성공. (주문번호:${orgOrderIdx})`;
        let isShowLog = true;
        if(tradeType === Common.G_TRADE_SELL) isShowLog = store.SettingsState.settings.bSellConfirm;
        else if(tradeType === Common.G_TRADE_BUY) isShowLog = store.SettingsState.settings.bBuyConfirm;
        store.LogMsg(strLog, '', isShowLog);
      } else {
        let strPrice;

        if(oldOrderPrice > 0.) {
          strPrice = Utils.PriceFormatStr(oldOrderPrice, futures);
        } else {
          strPrice = '시장가';
        }
        strPrice += `->${(orderPrice > 0.) ? Utils.PriceFormatStr(orderPrice, futures) : '시장가'}`;
  
        const lpStopLossObj = store.AccountState.FuturesStopLoss[futures];
        if (lpStopLossObj.bUse && lpStopLossObj.dbOrderPrice > 0. && lpStopLossObj.bUseTick[StopLoss.C_StopLossTick_Loss]) {
          const lossTick = (lpStopLossObj.iTradeType===Common.G_TRADE_SELL ? -lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] : lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss]);
          const lossRow = lossTick + Utils.GetPriceLine(store.MasterState.sHoga[futures], lpStopLossObj.dbOrderPrice, futures);

          const priceRow = Utils.GetPriceLine(store.MasterState.sHoga[futures], orderPrice, futures);
          if (lpStopLossObj.iTradeType===Common.G_TRADE_SELL ? priceRow  <= lossRow : priceRow >= lossRow) {
            if (lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] > 0) {
              NetworkService.send.tradeWait({ orderIdx: newOrderIdx, bStop: true });
            }
          } else {
            NetworkService.send.tradeWait({ orderIdx: newOrderIdx, bStop: false });
          }
        }
        
        const strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 주문 수정 성공. (주문번호:${orgOrderIdx}->${newOrderIdx}, 가격:${strPrice}, 수량:${orderAmount})`;
        store.LogMsg(strLog, '', store.SettingsState.settings.bModifyConfirm);
      }
    }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_ORDERLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_ORDERLIST_ACK](packet) {
    const store = useStore();

    let totalCount, curCount, isFirst, futures, orderIdx, tradeType, orderAmount, orderPriority;
    let orderPrice; // double
    // 		총주문리스트수(INT)		3 bytes		* 주문 수에따라 Body사이즈가 변함, <R>이 주문리스트 수만큼 반복됨
    totalCount = packet.readInt();
    // 		주문리스트 수(INT)		2 bytes		* 주문 수는 최대 20개, 현재 패킷이 가지고 있는 주문리스트 수, 총주문리스트에 따라 패킷이 여러 번 전송될 수 있다.
    curCount = packet.readInt();
    // 		첫주문리스트(INT)		1 byte		* 첫번째 주문리스트여부, 1이면 첫리스트			
    isFirst = packet.readInt();
    // 		선물 종류(INT)		2 byte			
    futures = packet.readInt();

    // console.log(`주문리스트를 받았다: ${Common.g_szFuturesNameMini[futures]}, ${totalCount}개`);
  
    // 첫 주문이거나 주문이 없다.
    if (totalCount === 0 || isFirst) {
      // 그리드에 기존 주문을 지운다.
  
      // 미체결 주문을 지운다.
      store.RemoveAllClientLeftAmount(futures);
    }
  
    if (totalCount > 0 && curCount > 0) {
      for (let orderCount = 0; orderCount < curCount; orderCount ++) {
        // 		주문번호(INT)		10 bytes	<R>	
        orderIdx = packet.readInt();
        // 		매매구분(INT)		1 byte	<R>		
        tradeType = packet.readInt();
        // 		주문가격(DOUBLE)		11 bytes	<R>	00000.00000
        orderPrice = packet.readDouble();
        // 		주문수량(INT)		3 bytes	<R>	
        orderAmount = packet.readInt();
        // 		주문우선순위(INT)		5 bytes	<R>				
        orderPriority = packet.readInt();
        // 		레버리지(INT)		3 bytes	<R>				
        const leverage = packet.readInt();
        const overTrading = packet.readInt();
        const overnightState = packet.readInt();
        const overnightLogIdx = packet.readInt();
        const iStopLossLogIdx = packet.readInt();
        const iProfitTicks = packet.readInt();
        const iLossTicks = packet.readInt();
        // 가격유형
        const iPriceType = packet.readInt();

        // 주문을 채워넣는다.
        let orderObj = new OrderObj();
        orderObj.iOrderIdx = orderIdx;			// 주문번호
        orderObj.iFutures = futures;			// 거래종목
        orderObj.iTradeType = tradeType;			// 매매구분
        orderObj.dbOrderPrice = orderPrice;	// 주문가격
        orderObj.iOrderAmount = orderAmount;		// 주문수량
        orderObj.iLeftAmount = orderAmount;		// 미체결수량
        orderObj.iPriorityCount = orderPriority;		// 주문 우선순위(주문시 호가 잔량), 체결에따라 실시간으로 변동
        orderObj.iLeverage = leverage; // 레버리지
        orderObj.iOverTrading = overTrading;
        orderObj.iOvernightState = overnightState;
        orderObj.iOvernightLogIdx = overnightLogIdx;
        orderObj.iStopLossLogIdx = iStopLossLogIdx;
        orderObj.iProfitTicks = iProfitTicks;
        orderObj.iLossTicks = iLossTicks;
        orderObj.iPriceType = iPriceType;
        // 주문 맵에 넣는다.
        store.AccountState.FuturesOrders[futures][tradeType] = [...store.AccountState.FuturesOrders[futures][tradeType], orderObj];
  
        // 스탑로스 비교후 대기 여부 결정
        const lpStopLossObj = store.AccountState.FuturesStopLoss[futures];
        if (lpStopLossObj.iTradeType === tradeType) {
          if (lpStopLossObj.bUse && lpStopLossObj.dbOrderPrice > 0. && lpStopLossObj.bUseTick[StopLoss.C_StopLossTick_Loss]) {
            let lossTick = (lpStopLossObj.iTradeType=== Common.G_TRADE_SELL ? -lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] : lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss]);
            let lossRow = lossTick + Utils.GetPriceLine(store.MasterState.sHoga[futures], lpStopLossObj.dbOrderPrice, futures);
            let priceRow = Utils.GetPriceLine(store.MasterState.sHoga[futures], orderPrice, futures);
  
            if (lpStopLossObj.iTradeType===Common.G_TRADE_SELL ? priceRow <= lossRow : priceRow >= lossRow) {
              if (lpStopLossObj.iTicks[StopLoss.C_StopLossTick_Loss] > 0) {
                NetworkService.send.tradeWait({ orderIdx, bStop: true });
              }
            }
          }
        }
  
        // console.log(`주문: ${orderCount+1}, 주문번호:${orderIdx}, ${Common.g_szTradeTypeName[tradeType]}, ${orderPrice}, 수량:${orderAmount}, 주문우선순위:${orderPriority}`);
      }
    }
  
    // 미체결 주문의 스탑로스 예시 그리기 설정
    store.SetClientStopLossExamples(futures);
  
    // 그리드 창에 주문을 그린다.
    store.DrawPossibleOrder();
    // 주문을 그린다.  WM_TRANSGRID
    NetworkService.emitter.emit(Emit_DrawTrans, { });

    return;
  }

  static async [PacketID.PROTOCOL_USER_PRIORITY_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CONTRACT_ACK](packet) {
    const store = useStore();

    // 		선물 종류(INT)		2 byte	
    const futures = packet.readInt();
    // 		주문번호(INT)		10 bytes			
    const orderIdx = packet.readInt();
    // 		매매구분(INT)		1 bytes			
    const tradeType = packet.readInt();
    // 		체결가격(DOUBLE)		11 bytes			
    const contracedPrice = packet.readDouble();
    // 		체결수량(INT)		3 bytes			
    const contractedAmount = packet.readInt();
    // 		체결방법		1 byte	
    // 	C_CONTRACMETHOD_MarketPrice ~ C_CONTRACMETHOD_Max
    const contractedMethod = packet.readInt();
  
    // 주문 및 체결 소리 알림 사용
    // console.log(`주문이 체결되었다: ${Common.g_szFuturesNameMini[futures]}, 주문번호:${orderIdx}, ${contracedPrice}, 수량:${contractedAmount}, ${Common.g_szContractedMethodName[contractedMethod]}`);
  
    let strLog;
    if (contractedMethod === Common.C_CONTRACMETHOD_SettleBtn) {
      strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 주문이 체결되었습니다. (주문번호:${orderIdx}, 시장가, 수량:${contractedAmount})`;
    } else {
      strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szTradeTypeName[tradeType]} 주문이 체결되었습니다. (주문번호:${orderIdx}, ${Common.g_szContractedMethodName[contractedMethod]}, 가격:${Utils.PriceFormatStr(contracedPrice, futures)}, 수량:${contractedAmount})`;
    }

    let isShowLog = true;
    if(tradeType === Common.G_TRADE_SELL) isShowLog = store.SettingsState.settings.bSellPush;
    else if(tradeType === Common.G_TRADE_BUY) isShowLog = store.SettingsState.settings.bBuyPush;
  
    store.LogMsg(strLog, '', isShowLog);

    return;
  }

  static async [PacketID.PROTOCOL_USER_CONTRACTLIST_ACK](packet) {  // 체결 리스트
    const store = useStore();

    let totalCount, curCount, isFirst, futures, orderIdx, tradeType, contractedAmount;
    // DOUBLE
    let contractedPrice;
    // 		총체결리스트수(INT)		3 bytes		* 체결 수에따라 Body사이즈가 변함, <R>이 체결리스트 수만큼 반복됨			
    totalCount = packet.readInt();
    // 		체결리스트 수(INT)		2 bytes		* 체결 수는 최대 20개, 현재 패킷이 가지고 있는 체결리스트 수, 총체결리스트에 따라 패킷이 여러 번 전송될 수 있다.			
    curCount = packet.readInt();
    // 		첫주문리스트(INT)		1 byte		* 첫번째 주문리스트여부, 1이면 첫리스트			
    isFirst = packet.readInt();
    // 		선물 종류(INT)		2 byte		* 총체결리스트, 체결리스트가 0이면 체결이 없는 것이다			
    futures = packet.readInt();
  
    // console.log(`체결리스트를 받았다: ${Common.g_szFuturesNameMini[futures]}, ${totalCount}개`);
  
    // 주문 포지션 초기화
    store.AccountState.iOrderPos[futures] = Common.C_ORDERPOS_NO;		
    // 첫 체결이거나 체결이 없다.
    if (totalCount===0 || isFirst) {
      if (totalCount===0) {
        // 체결된 내역이 없으면 모든 대기 주문 활성화.
        for (let tradeType = Common.G_TRADE_SELL; tradeType < Common.G_TRADE_MAX; tradeType++) {
          const lpOrderMap = store.AccountState.FuturesOrders[futures][tradeType];
          if (lpOrderMap) {
            lpOrderMap.forEach(curOrd => {
              if (curOrd.iLeftAmount>0) {
                NetworkService.send.tradeWait({ orderIdx: curOrd.iOrderIdx, bStop: false });
              }
            });
          }
        }
      }
  
      // 체결을 모두 지운다(주문은 나둔다)
      // 그리드에 기존 체결을 지운다.
  
      // 총 잔량(반대매매)을 지운다.
      store.RemoveAllClientOppositeAmount(futures);
    }
  
    if (totalCount > 0 && curCount > 0) {
      for (let contractedCount = 0; contractedCount < curCount; contractedCount ++) {
        // 		주문번호(INT)		10 bytes	<R>	
        orderIdx = packet.readInt();
        // 		매매구분(INT)		1 byte	<R>		
        tradeType = packet.readInt();
        // 		체결가격(DOUBLE)		11 bytes	<R>	00000.00000
        contractedPrice = packet.readDouble();
        // 		체결수량(INT)		3 bytes	<R>				
        contractedAmount = packet.readInt();
        // 		주문가격(DOUBLE)		11 bytes	<R>	00000.00000
        const orderPrice = packet.readDouble();
        // 		레버리지(INT)		3 bytes	<R>				
        const leverage = packet.readInt();
        // 장외거래
        const overTrading = packet.readInt();
        //		오버나잇 상태
        const overnightState = packet.readInt();
        const overnightLogIdx = packet.readInt();
        const iStopLossLogIdx = packet.readInt();
        const iProfitTicks = packet.readInt();
        const iLossTicks = packet.readInt();
        // 가격유형
        const iPriceType = packet.readInt();

        // 주문을 채워넣는다.
        let orderObj = new OrderObj();
        orderObj.iOrderIdx = orderIdx;			// 주문번호
        orderObj.iFutures = futures;			// 거래종목
        orderObj.iTradeType = tradeType;			// 매매구분
        orderObj.dbContractPrice = contractedPrice;	// 체결가격
        orderObj.iOrderAmount = contractedAmount;		// 주문수량
        orderObj.iOppositeAmount = contractedAmount;		// 잔량(반대매매)
        orderObj.dbOrderPrice = orderPrice;		// 주문가격
        orderObj.iLeverage = leverage; // 레버리지
        orderObj.iOverTrading = overTrading;
        orderObj.iOvernightState = overnightState;
        orderObj.iOvernightLogIdx = overnightLogIdx;
        orderObj.iStopLossLogIdx = iStopLossLogIdx;
        orderObj.iProfitTicks = iProfitTicks;
        orderObj.iLossTicks = iLossTicks;
        orderObj.iPriceType = iPriceType;
        // 주문 맵에 넣는다.
        store.AccountState.FuturesOrders[futures][tradeType] = [...store.AccountState.FuturesOrders[futures][tradeType], orderObj];

        // 주문 포지션 설정
        store.AccountState.iOrderPos[futures] = tradeType===Common.G_TRADE_SELL ? Common.C_ORDERPOS_SELL : Common.C_ORDERPOS_BUY;		
  
        // console.log(`체결: ${contractedCount+1}, 주문번호:${orderIdx}, ${Common.g_szTradeTypeName[tradeType]}, ${contractedPrice}, 수량:${contractedAmount}`);
      }
    }
  
    // 주문관련 표시 수정
    // 평가금액을 표시한다.
    store.GetLeverageBalance();
    // 체결을 그린다.
    // 평균가, 잔고수량등을 그린다.
  
    // StopLoss를 설정한다.
    const lpStopLossObj = store.AccountState.FuturesStopLoss[futures];
    if (lpStopLossObj.bUse) {
      lpStopLossObj.iTradeType = Common.G_TRADE_MAX;
      lpStopLossObj.dbOrderPrice = 0.0;
      lpStopLossObj.iRow = Common.INDEX_NONE;
      lpStopLossObj.drawLine = false;
    }
  
    // 체결 주문을 확인
    if (futures !== Common.G_FUTURES_MAX && store.AccountState.iOrderPos[futures] !== Common.C_ORDERPOS_NO) {
      const tradeType = store.AccountState.iOrderPos[futures]===Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
      // 총 잔량(반대매매)의 수량과 총 가격을 얻는다.
      const [oppAmount, oppAverage, oppLine] = store.GetClientTotalOppositeCountNAverage(futures, tradeType);
      if (oppAmount > 0 && lpStopLossObj.bUse) {
        lpStopLossObj.iTradeType = tradeType;
        lpStopLossObj.dbOrderPrice = oppAverage;
      }
  
      lpStopLossObj.drawLine = oppLine;
    }
  
    // 주문 체결에서 청산인 경우 스탑로스 사용은 취소되도록 수정한다.
    // 체결이되면 스탑로스 라인이 설정된다. 청산은 라인이 없어진다.
    if (futures !== Common.G_FUTURES_MAX && lpStopLossObj.bUse && !lpStopLossObj.drawLine) {
      // 주문창의 스탑로스를 초기화한다.
      lpStopLossObj.bUseTick[StopLoss.C_StopLossTick_Profit] = false;
      lpStopLossObj.bUseTick[StopLoss.C_StopLossTick_Loss] = false;
      // 주문창 스탑로스 삭제
    }
  
    // 그리드 창에 주문을 그린다.
    // 주문을 그린다.  WM_TRANSGRID
    NetworkService.emitter.emit(Emit_DrawTrans, { });

    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMO_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMO_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOC_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOC_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOR_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MEMOTA_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOSSCUT_ACK](packet) {
    const store = useStore();

    // 0 = 국내, 1 = 해외, 2 = 국내/해외
    const isOversea = packet.readInt();
  
    for (let futures = 0; futures < Common.G_FUTURES_MAX; futures ++) {
      // 모든 주문을 지운다.
      if (isOversea === Common.eNation_Max || (isOversea === Common.eNation_Domestic && futures < Common.G_FUTURES_OVERSEAS) || (isOversea === Common.eNation_Oversea && futures >= Common.G_FUTURES_OVERSEAS && futures < Common.G_FUTURES_MAX)) {
        store.RemoveAllClientOrders(futures);
        // StopLoss 설정 초기화
        store.InitStopLoss(futures);
  
        // MIT 설정 초기화
        for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType++) {
          const lpMITMap = store.AccountState.FuturesMIT[futures][tradeType];
          if (lpMITMap) {
            // 모두 삭제
            lpMITMap.splice(0); 
          }
        }
      }
    }

    // 주문을 그린다.
    // WM_TRANSGRID
    NetworkService.emitter.emit(Emit_DrawTrans, { });
  
    // 팝업창을 띄운다.
    const szLosscutMsg = `${Common.g_szNationName[isOversea]} 로스컷 처리 되었습니다.`;
    store.setVisibleDialog({ message: szLosscutMsg, title: '로스컷 알림' });

    // console.log(szLosscutMsg);
  
    store.LogMsg(szLosscutMsg);

    return;
  }

  static async [PacketID.PROTOCOL_USER_MIT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MIT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MITM_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MITC_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MITA_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSS_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSS_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSSM_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSSC_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSSA_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_BATCHCANCLE_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_BATCHCANCLE_ACK](packet) {
    const store = useStore();

    // 		선물 종류(INT)		2 byte	
    const futures = packet.readInt();
    // 		취소 결과							
    const isSuccess = packet.readInt();
    // 		일괄요청(INT)		1 byte					
    const batchCmd = packet.readInt();
  
    if (isSuccess) {
      switch (batchCmd) {
      case Common.C_BATCH_OrderSellCancel :			// 주문매도취소
        // 그리드에 기존 주문을 지운다.
  
        // 미체결 주문을 지운다.
        store.RemoveAllClientLeftAmount(futures, Common.G_TRADE_SELL);
        // 미체결 주문의 스탑로스 예시 그리기 설정
        store.SetClientStopLossExamples(futures);
  
        // 주문 및 체결 소리 알림 사용
  
        break;
  
      case Common.C_BATCH_OrderBuyCancel :				// 주문매수취소
        // 그리드에 기존 주문을 지운다.
  
        // 미체결 주문을 지운다.
        store.RemoveAllClientLeftAmount(futures, Common.G_TRADE_BUY);
        // 미체결 주문의 스탑로스 예시 그리기 설정
        store.SetClientStopLossExamples(futures);
  
        // 주문 및 체결 소리 알림 사용

        break;
  
      case Common.C_BATCH_MITSellCancel :				// MIT매도취소
        {
          // 기존 MIT 그리드 지우기
          const lpMITMap = store.AccountState.FuturesMIT[futures][Common.G_TRADE_SELL];
          if (lpMITMap) {
            // 모두 삭제
            lpMITMap.splice(0); 
          }
        }
        break;
  
      case Common.C_BATCH_MITBuyCancel :				// MIT매수취소
        {
          const lpMITMap = store.AccountState.FuturesMIT[futures][Common.G_TRADE_BUY];
          if (lpMITMap) {
            // 모두 삭제
            lpMITMap.splice(0); 
          }
        }
        break;
  
      case Common.C_BATCH_OrderCancel :				// 주문일괄취소		* MIT, StopLoss, 주문취소
        {
          // 그리드에 기존 주문을 지운다.
  
          // 미체결 주문을 지운다.
          if (futures === Common.G_FUTURES_MAX) // 전종목 취소
          {
            for (let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
              store.RemoveAllClientLeftAmount(futCode);
  
              for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
                const lpMITMap = store.AccountState.FuturesMIT[futCode][tradeType];
                if (lpMITMap) {
                  // 모두 삭제
                  lpMITMap.splice(0); 
                }
              }
  
              // StopLoss 설정 초기화
              store.InitStopLoss(futCode);
            }
          } else {
            store.RemoveAllClientLeftAmount(futures);
  
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
              const lpMITMap = store.AccountState.FuturesMIT[futures][tradeType];
              if (lpMITMap) {
                // 모두 삭제
                lpMITMap.splice(0); 
              }
            }
  
            // StopLoss 설정 초기화
            store.InitStopLoss(futures);
          }
  
          // 주문 및 체결 소리 알림 사용
  
          // 팝업
          // (futures === Common.G_FUTURES_MAX) ? POPUP_MSG_ALL_CANCEL : POPUP_MSG_ALL_CANCEL_ONE
          const szMsg = `${futures === Common.G_FUTURES_MAX ? '전종목' : '현종목'}취소 처리가 완료되었습니다.`;
  
        }
        break;
  
      case Common.C_BATCH_Sattle :						// 일괄청산			* MIT, StopLoss, 주문취소, 체결(반대매매)처리
        {
          // 그리드에 기존 주문을 지운다.
  
          // 미체결 주문을 지운다.
          if (futures === Common.G_FUTURES_MAX) // 전종목 취소
          {
            for (let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
              store.RemoveAllClientLeftAmount(futCode);
  
              for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
                const lpMITMap = store.AccountState.FuturesMIT[futCode][tradeType];
                if (lpMITMap) {
                  // 모두 삭제
                  lpMITMap.splice(0); 
                }
              }
  
              // StopLoss 설정 초기화
              store.InitStopLoss(futCode);
            }
          } else {
            store.RemoveAllClientLeftAmount(futures);
  
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
              const lpMITMap = store.AccountState.FuturesMIT[futures][tradeType];
              if (lpMITMap) {
                // 모두 삭제
                lpMITMap.splice(0); 
              }
            }
  
            // StopLoss 설정 초기화
            store.InitStopLoss(futures);
          }
  
          // 팝업
          // (futures === Common.G_FUTURES_MAX) ? POPUP_MSG_ALL_CLEAR : POPUP_MSG_ALL_CLEAR_ONE
          const szMsg = `${futures === Common.G_FUTURES_MAX ? '전종목' : '현종목'}청산 처리가 완료되었습니다.`;
        }
        break;
  
      case Common.C_BATCH_Max :				// impossible
      default:
        break;
      }

      // 평가금액을 표시한다.
      store.GetLeverageBalance();
      // 평균가, 잔고수량등을 그린다.
      //DrawPosition(futures);
     
    }
  
    const szResult = isSuccess ? '성공' : '실패';
    // console.log(`일괄처리 응답을 받았다: ${Common.g_szFuturesNameMini[futures]}, ${szResult}, ${Common.g_szBatchCmdName[batchCmd]}`);
  
    let strLog = '';
    if (batchCmd === Common.C_BATCH_OrderCancel) { // 취소
      if (futures === Common.G_FUTURES_MAX) {
        strLog = `[전종목] 취소 처리가 ${szResult} 하였습니다.`;
      } else {
        strLog = `[${Common.g_szFuturesNameMini[futures]}] 현종목취소 처리가 ${szResult} 하였습니다.`;
      }
    } else if(batchCmd === Common.C_BATCH_Sattle) { // 청산 
      if (futures === Common.G_FUTURES_MAX) {
        strLog = `[전종목] 청산 처리가 ${szResult} 하였습니다.`;
      } else {
        strLog = `[${Common.g_szFuturesNameMini[futures]}] 현종목청산 처리가 ${szResult} 하였습니다.`;
      }
    } else {
      strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szBatchCmdName[batchCmd]} 처리가 ${szResult} 하였습니다.`;
    }
  
    store.LogMsg(strLog);
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_RECOMMAND_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_RECOMMAND_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_TRADELIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_TRADELIST_ACK](packet) {
    const store = useStore();

    // 		선물 종류(INT)		2 bytes
    const _futures = packet.readInt();
    // 국내/해외
    const isOversea = packet.readInt();
    // 		총리스트수(INT)		3 bytes		*  거래내역 수는 최대 12개, 현재 패킷이 가지고 있는 거래내역리스트 수, 총거래내역리스트에 따라 패킷이 여러 번 전송될 수 있다.
    const totalCount = packet.readInt();
    // 		현재리스트 수(INT)	2 bytes
    const curCount = packet.readInt();
    // 		첫리스트여부(INT)	1 bytes
    const isFirst = packet.readInt();
  
    if (totalCount === 0) {
      store.AccountState.TradingLogTotalCount = 0;
      store.AccountState.TradingLogCurCount = 0;
      store.AccountState.TradingLogs = [];
  
      const szMsg = '거래 내역이 없습니다.';
      store.setVisibleDialog({ message: szMsg });
      // // console.log(szMsg);
  
      return;
    }

    store.AccountState.TradingLogTotalCount = totalCount;

    if (isFirst) {
      store.AccountState.TradingLogCurCount = 0;
      store.AccountState.TradingLogs = [];
    }
  
    for (let i = 0; i < curCount; i++) {
      // 		로그번호(INT)		10 bytes	<R>	
      const logIdx = packet.readInt();
      // 		주문번호(INT)		10 bytes	<R>	
      const orderIdx = packet.readInt();
      // 		원주문번호(INT)		10 bytes	<R>	
      const orgOrderIdx = packet.readInt();
      // 		주문시간			14 bytes	<R>	
      let szOrderDate = packet.readString();
      szOrderDate = szOrderDate.trim();
      // 		체결시간			14 bytes	<R>	
      let szContractDate = packet.readString();
      szContractDate = szContractDate.trim();
      // 		선물종류			2 bytes	<R>	
      const futures = packet.readInt();
      // 		매매구분			1 bytes	<R>	
      const tradeType = packet.readInt();
      // 		가격유형			1 bytes	<R>	
      const priceType = packet.readInt();
      // 		주문방법			20 bytes<R>	
      let szOrderType = packet.readString();
      szOrderType = szOrderType.trim();
      // 		주문가격			11 bytes	<R>	
      const orderPrice = packet.readDouble();
      // 		주문수량			3 bytes	<R>	
      const orderAmount = packet.readInt();
      // 		체결가격			11 bytes	<R>	bidPrice
      const contractPrice = packet.readDouble();
      // 		체결수량			3 bytes	<R>	nobidAmount
      const contractAmount = packet.readInt();
      //- 		미체결수량			3 bytes	<R>	nobidAmount
      const leftAmount = packet.readInt();
      //- 		잔고수량			3 bytes	<R>	 residualAmount
      const oppAmount = packet.readInt();
      // 		수수료			8 bytes	<R>	
      const commission = packet.readInt();
      // 		레버리지			3 bytes	<R>	
      const leverage = packet.readInt();
      // 		접수상태			1 bytes	<R>	
      const receiptState = packet.readInt();
      // 		손익			8 bytes	<R>	
      const myProfit = packet.readInt();
      // 		총손익			8 bytes	<R>	
      const myTotalProfit = packet.readInt();
      // 		보유잔고		8 bytes	<R>	
      const myAmount = packet.readInt();
      // 		평가금액		8 bytes	<R>	
      const myLeverageAmount = packet.readInt();
      //매도1호가 20
      //매수1호가 21
      //현재가 22
      const sellPrice1 = packet.readDouble();
      const buyPrice1 = packet.readDouble();
      const curPrice = packet.readDouble();
      // 오버나잇 상태
      const strOvernightState = packet.readString();
      // 장외거래
      const strOverTrading = packet.readString();
      // 메모
      const strMemo = packet.readString();

      let tradingLog = new TradingLog();
      tradingLog.iLogIndex = logIdx;
      tradingLog.iOrderIdx = orderIdx;			// 주문번호
      tradingLog.iOrgOrderIdx = orgOrderIdx;			// 원주문번호
      /*
      if (szOrderDate) {
        tradingLog.szOrderDate = `${szOrderDate.substring(2,4)}-${szOrderDate.substring(4,6)}-${szOrderDate.substring(6,8)} ${szOrderDate.substring(8,10)}:${szOrderDate.substring(10,12)}:${szOrderDate.substring(12,14)}`;	// 주문일시 YYYY-MM-DD hh:mm:ss
      } else {
        tradingLog.szOrderDate = szOrderDate;	// 주문일시 YYYY-MM-DD hh:mm:ss
      }
      if (szContractDate) {
        tradingLog.szContractedDate = `${szContractDate.substring(2,4)}-${szContractDate.substring(4,6)}-${szContractDate.substring(6,8)} ${szContractDate.substring(8,10)}:${szContractDate.substring(10,12)}:${szContractDate.substring(12,14)}`;	// 주문일시 YYYY-MM-DD hh:mm:ss
      } else {
        tradingLog.szContractedDate = szContractDate;	// 주문일시 YYYY-MM-DD hh:mm:ss
      }
      */
      tradingLog.szOrderDate = szOrderDate ? moment(szOrderDate, 'YYYYMMDDHHmmss').format('YY-MM-DD HH:mm:ss') : '';
      tradingLog.szContractedDate = szContractDate ? moment(szContractDate, 'YYYYMMDDHHmmss').format('YY-MM-DD HH:mm:ss') : '';
      tradingLog.iFutures = futures;			// 선물종류
      tradingLog.iTradeType = tradeType;			// 매매구분
      tradingLog.iPriceType = priceType;			// 가격유형
      tradingLog.szOrderMethod = szOrderType;		// 주문방법
      tradingLog.dbOrderPrice = orderPrice;	// 주문가격
      tradingLog.iOrderAmount = orderAmount;		// 주문수량
      tradingLog.dbContractedPrice = contractPrice;	// 체결가격
      tradingLog.iContractedAmount = contractAmount;	// 체결 수량
      tradingLog.iLeftAmount = leftAmount;		// 미체결 수량
      tradingLog.iOppositeAmount = oppAmount;		// 잔고수량
      tradingLog.iCommission = commission;			// 수수료
      tradingLog.iLeverage = leverage;			// 레버리지
      tradingLog.iAcceptState = receiptState;		// 접수상태
      tradingLog.iProfit = myProfit;  // 평가손익
      tradingLog.iTotalProfit = myTotalProfit;
      tradingLog.iDepoAmount = myAmount;
      tradingLog.nEvaluateProfit = myLeverageAmount;
      tradingLog.dbBuyPrice1 = sellPrice1;
      tradingLog.dbSellPrice1 = buyPrice1;
      tradingLog.dbCurPrice = curPrice;
      tradingLog.szOvernightState = strOvernightState;
      tradingLog.szOverTrading = strOverTrading;
      tradingLog.szMemo = strMemo;

      store.AccountState.TradingLogs.push(tradingLog);
    }

    store.AccountState.TradingLogCurCount += curCount;

    // 마지막인가?
    if (store.AccountState.TradingLogCurCount >= store.AccountState.TradingLogTotalCount) {
      // 마지막 로그를 받았다.
      NetworkService.emitter.emit(Emit_TradingLog);
    }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_TLCONTRACT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_TLLEFT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PROFITLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PROFITLIST_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MITLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MITLIST_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSSLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_STOPLOSSLIST_ACK](packet) {
    const store = useStore();

    // 		선물 종류(INT)		2 bytes
    const futures = packet.readInt();
    // 		총리스트수(INT)		3 bytes		*  스탑로스 로그수는 최대 10개, 현재 패킷이 가지고 있는 거래내역리스트 수, 총거래내역리스트에 따라 패킷이 여러 번 전송될 수 있다.
    const totalCount = packet.readInt();
    // 		현재리스트 수(INT)	2 bytes
    const curCount = packet.readInt();
    // 		첫리스트여부(INT)	1 bytes
    const isFirst = packet.readInt();

    if (totalCount === 0) {
      store.AccountState.StopLossLogTotalCount = 0;
      store.AccountState.StopLossLogCurCount = 0;
      store.AccountState.StopLossLogs = [];
  
      const szMsg = 'S/L 사용 내역이 없습니다.';
      // // console.log(szMsg);
      // store.setVisibleDialog({ message: szMsg });
  
      return;
    }

    store.AccountState.StopLossLogTotalCount = totalCount;

    if (isFirst) {
      store.AccountState.StopLossLogCurCount = 0;
      store.AccountState.StopLossLogs = [];
    }

    for (let i = 0; i < curCount; i++) {
      // 		로그코드(INT)		10 bytes	<R>	
      const logIdx = packet.readInt();
      // 		선물종류(INT)		2 bytes	<R>	
      const futType = packet.readInt();
      // 		매매구분(INT)		1 bytes	<R>	
      const tradeType = packet.readInt();
      // 		사용구분(string)	30 bytes<R>
      let szUse = packet.readString();
      //szUse = szUse.trim();
      // 		스탑가격(DOUBLE)	11 bytes<R> 00000.000000
      const stopPrice = packet.readDouble();
      // 		체결가격(DOUBLE)	11 bytes	<R>	00000.000000
      const orderPrice = packet.readDouble();
      // 		평균가격(DOUBLE)	11 bytes	<R>	00000.000000
      const avrPrice = packet.readDouble();
      // 		이익틱사용(INT)		1 bytes	<R>	
      const stopTickUse = packet.readInt();
      // 		이익틱(INT)		3 bytes	<R>	
      const stopTick = packet.readInt();
      // 		손실틱사용(INT)		1 bytes	<R>	
      const lossTickUse = packet.readInt();
      // 		손실틱(INT)		3 bytes	<R>	
      const lossTick = packet.readInt();
      // 		상태(string)		30 bytes <R>	
      const szState = packet.readString();
      //szState = szState.trim();
      if (szState === '수정취소') {
        szUse = '수정취소';
      }
      // 		등록시간			6 bytes	<R>	
      const regDate = packet.readString();
      const szRegDate = regDate ? moment(regDate, 'YYYYMMDDHHmmss').format('YY-MM-DD HH:mm:ss') : '';
      // 		주문시간			6 bytes	<R>	
      const finDate = packet.readString();
      const szOrderDate = finDate ? moment(finDate, 'YYYYMMDDHHmmss').format('YY-MM-DD HH:mm:ss') : '';
      // 매체
      const bMobile = packet.readInt();
      // S/L 예약
      const bReserved = packet.readInt();
      // 주문번호
      const iOrderIdx = packet.readInt();

      // 적용된 틱
      // 1: 이익틱 적용, 2: 손실틱 적용
      let usedTick = 0;
      if (szUse === '사용') {
        if ((tradeType === Common.G_TRADE_SELL && stopPrice < avrPrice) || (tradeType === Common.G_TRADE_BUY && stopPrice > avrPrice)) {
          // 손실
          usedTick = 2;
        } else {
          // 이익
          usedTick = 1;
        }
      }
      if (szUse === '사용') {
        szUse = '완료';
      } else if (szUse === '취소(일괄청산버튼클릭)') {
        szUse = '취소(일괄청산클릭)';
      }

      let stopLossLog = new StopLossLog();
      stopLossLog.iLogIdx = logIdx;
      stopLossLog.iFutures = futType;			
      stopLossLog.iTradeType = tradeType;			
      stopLossLog.szUse = szUse;			
      stopLossLog.dbAvePrice = avrPrice;			
      stopLossLog.dbStopPrice = stopPrice;			
      stopLossLog.dbContractPrice = orderPrice;			
      stopLossLog.bUseStop = stopTickUse;			
      stopLossLog.iStopTicks = stopTick;			
      stopLossLog.bUseLoss = lossTickUse;			
      stopLossLog.iLossTicks = lossTick;			
      stopLossLog.szState = szState;			
      stopLossLog.szRegDate = szRegDate;			
      stopLossLog.szUseDate = szOrderDate;			
      stopLossLog.bMobile = bMobile;			
      stopLossLog.bReserved = bReserved;			
      stopLossLog.iOrderIdx = iOrderIdx;			
      stopLossLog.iUsedTick = usedTick;			

      store.AccountState.StopLossLogs.push(stopLossLog);
    }

    store.AccountState.StopLossLogCurCount += curCount;

    // 마지막인가?
    // // console.log('마지막인가?');
    // // console.log(store.AccountState.StopLossLogCurCount);
    // // console.log(store.AccountState.StopLossLogTotalCount);
    if (store.AccountState.StopLossLogCurCount >= store.AccountState.StopLossLogTotalCount) {
      // 마지막 로그를 받았다.
      NetworkService.emitter.emit(Emit_StopLossLog, { });
    }

    return;
  }

  static async [PacketID.PROTOCOL_USER_DEPOSITLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_DEPOSITLIST_ACK](packet) {
    const store = useStore();

    // 대체 여부
    const bTransfer = packet.readInt();
    const transferIdx = bTransfer ? 1 : 0;
    // 		총리스트수(INT)		3 bytes		*  입출금 리스트수는 최대 10개, 현재 패킷이 가지고 있는 리스트에 따라 패킷이 여러 번 전송될 수 있다.
    const totalCount = packet.readInt();
    // 		현재리스트 수(INT)	2 bytes
    const curCount = packet.readInt();
    // 		첫리스트여부(INT)	1 bytes
    const isFirst = packet.readInt();
  
    if (totalCount === 0) {
      store.AccountState.TransferLogTotalCount[transferIdx] = 0;
      store.AccountState.TransferLogCurCount[transferIdx] = 0;
      store.AccountState.TransferLogs[transferIdx] = [];
  
      const szMsg = `${bTransfer ? '대체' : '입/출금'} 내역이 없습니다.`;
      // // console.log(szMsg);
      store.setVisibleDialog({ message: szMsg });
  
      return;
    }

    store.AccountState.TransferLogTotalCount[transferIdx] = totalCount;

    if (isFirst) {
      store.AccountState.TransferLogCurCount[transferIdx] = 0;
      store.AccountState.TransferLogs[transferIdx] = [];
    }

    for (let i = 0; i < curCount; i++) {
      // 		로그코드(INT)		10 bytes	<R>	
      const Idx = packet.readInt();
      // 		입금출금(INT)		1 bytes	<R>	
      const nDepo = packet.readInt();
      // 		신청일시(datetime)	14 bytes	<R>	
      const orderDate = packet.readString();
      const szRequestDate = moment(orderDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss');
      // 		처리일시(datetime)	14 bytes	<R>	
      const finDate = packet.readString();
      const szProcessDate = finDate != '00000000000000' ? moment(finDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : '';
      // 		누적입금액(INT)		10 bytes	<R>	
      const totalDeposit = packet.readInt();
      // 		누적출금액(INT)		10 bytes	<R>	
      const totalWithdraw = packet.readInt();
      // 		금일 입금액(INT)		10bytes
      const todayDeopsit = packet.readInt();
      // 		금일 출금액(INT)		10bytes
      const todayWithdraw = packet.readInt();
      // 		입금이전잔고(INT)	10 bytes	<R>	
      const beforeDepositMoney = packet.readInt();
      // 		출금이전잔고(INT)	10 bytes	<R>	
      const beforeWithdrawMoney = packet.readInt();
      // 		입/출금액(INT)		10 bytes	<R>	
      const money = packet.readInt();
      // 		입금이후잔고(INT)	10 bytes	<R>	
      const afterDepositMoney = packet.readInt();
      // 		출금이후잔고(INT)	10 bytes	<R>	
      const afterWithdrawMoney = packet.readInt();
      // 		처리상태(string)	1 bytes	<R>	
      const result = packet.readInt();
      // 		입출금인(string)	50 bytes	<R>	
      const bank_owner = packet.readString();
      // 		이벤트타입(INT)		1 bytes	<R>	
      const evt_type = packet.readInt();
      // 		메모(string)		40 bytes	<R>	
      const memo = packet.readString();
      // 국내해외
      const domesticOversea = packet.readString();
      const bOversea = domesticOversea === Common.g_szNationName[Common.eNation_Oversea];
			// 		이벤트(string)
      const eventName = packet.readString();

      let transferLog = new TransferLog();
      transferLog.iLogIdx = Idx;
      transferLog.iDepoNWithdraw = nDepo;
      transferLog.szRequestDate = szRequestDate;
      transferLog.szProcessDate = szProcessDate;
      transferLog.iTotalDepoAmount = totalDeposit;
      transferLog.iTotalWithdrawAmount = totalWithdraw;
      transferLog.iTodayDepoAmount = todayDeopsit;
      transferLog.iTodayWithdrawAmount = todayWithdraw;
      transferLog.iBeforeDepositAmount = beforeDepositMoney;
      transferLog.iBeforeWithdrawAmount = beforeWithdrawMoney;
      transferLog.iDepoNWithdrawAmount = money;
      transferLog.iAfterDepositAmount = afterDepositMoney;
      transferLog.iAfterWithdrawAmount = afterWithdrawMoney;
      transferLog.iState = result;
      transferLog.szRequestName = bank_owner;
      transferLog.iEventType = evt_type;
      transferLog.szMemo = memo;
      transferLog.szDomesticOversea = domesticOversea;
      transferLog.szEventName = eventName;

      store.AccountState.TransferLogs[transferIdx].push(transferLog);
    }
  
    store.AccountState.TransferLogCurCount[transferIdx] += curCount;

    // 마지막인가?
    if (store.AccountState.TransferLogCurCount[transferIdx] >= store.AccountState.TransferLogTotalCount[transferIdx]) {
      // 마지막 로그를 받았다.
      NetworkService.emitter.emit(Emit_TransferLog, { transferIdx });
    }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_TICKVALUE_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PROFITDETAIL_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PROFITDETAIL_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOSSCUTINFO_ACK](packet) {
    const store = useStore();

    // 		로스컷%(INT)
    const lossDomestic = packet.readInt();
    const lossOversea = packet.readInt();

    store.MarketState.iLosscutDomestic = lossDomestic;
    store.MarketState.iLosscutOversea = lossOversea;

    return;
  }

  static async [PacketID.PROTOCOL_USER_QUIT_ACK](packet) {
    const store = useStore();
    const exitReason = packet.readInt();

    let message = '';
    if (exitReason === NetConstants.C_ForceExitReason_Max) {
      message = '새로운 버전의 WTS가 있습니다.\n신규 WTS를 e-mail로 요청해주세요.';
    }
    else if (exitReason !== NetConstants.C_ForceExitReason_ServerClose) {
      message = `WTS가 종료됩니다.\n종료사유 : ${NetConstants.g_szForceExitReason[exitReason]}`;
    } else {
      message = `새로운 장을 시작하기 위해 WTS가 종료됩니다.\n다시 접속하여 주시기 바랍니다.`;
    }

    // 자동 로그인 플래그 off
    store.updateLoginPageState(0);
    store.updateLoginPageError(1, message);
    store.LoginPageState.isReloadRequired = true;

    // 네트워크 종료
    const bLogout = true;   // 자동 재연결 시도를 하지 않는다.
    NetworkService.destorySocket(bLogout);

    // 팝업 닫으면 로그인페이지로 이동
    // 오류정보 넣어놨으니, 로그인 페이지에서 페이지 새로고침
    store.setVisibleDialog({ message: message, callback: function() {
      NetworkService.emitter.emit(Emit_ResetMoveToLogin, { packetId: packet.id });
    } });
    
    // , callback: () => {
    //   // 앱 종료
    //   // RNExitApp.exitApp();
    // }});
    return;
  }

  static async [PacketID.PROTOCOL_CMM_MEMOANSER_ACK](packet) {
    // 		아이디(string)		20 bytes
    const szUserId = packet.readString();
    // 주문 및 체결 소리 알림 사용
    // 팝업창을 띄운다.
    const szMsg = '쪽지가 도착했습니다\n\n빨리 확인해 주세요.';
    // console.log(`UserID: ${szUserId}, ${szMsg}`);

    return;
  }

  static async [PacketID.PROTOCOL_USER_TRADINGREPORT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_TRADINGREPORT_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_PAUSE_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_DEBUGLOG_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_OVERNIGHT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_OVERNIGHT_ACK](packet) {
    const store = useStore();

    const resultCode = packet.readInt();
    const overnightState = packet.readInt();

    // console.log(`오버나잇 신청 응답을 받았다: ${Common.g_szOvernightStateName[overnightState]}, 결과코드:${resultCode}`);
  
    // 해외선물 마감 1일전이 지나 신청 불가
    let szMsg = '';
    if (resultCode === NetConstants.r_Overnight_Success) {
      szMsg = '오버나잇 신청이 요청되었습니다.\n최종승인은 장마감 후 평가예탁금을 재평가하여 승인됩니다.\n평가예탁금이 오버나잇 기준금에 미달하면 자동으로 취소됩니다.';
    } else if (resultCode === NetConstants.r_Overnight_Cancellation_Success) {
      szMsg = '오버나잇 해제가 처리되었습니다.';
    } else if (resultCode === NetConstants.r_Overnight_1DaysBefore) {
      szMsg = '선물 만기일로 신청이 불가합니다.';
    } else if (resultCode === NetConstants.r_Overnight_PaymentNotAllow) {
      szMsg = '만기결제는 만기일에 가능합니다.';
    } else if (resultCode === NetConstants.r_Overnight_Deadline) {
      szMsg = '오버나잇 신청시간이 마감되었습니다.';
    } else if (resultCode === NetConstants.r_Overnight_Exceed) {
      szMsg = '평가담보금이 오버나잇에 필요한 담보금보다 부족합니다.';
    } else if (resultCode === NetConstants.r_Overnight_CancelByContract) {
      szMsg = '주문 체결로 오버나잇이 해제되었습니다.';
    } else if (resultCode === NetConstants.r_Overnight_CancelBySettle) {
      szMsg = '주문 청산으로 오버나잇이 해제되었습니다.';
    } else { //if (resultCode === NetConstants.r_Overnight_FailSystem || resultCode === NetConstants.r_Overnight_WrongOrder || resultCode === NetConstants.r_Overnight_WrongState) 
      szMsg = '오버나잇 요청이 잘못되어 처리할 수 없습니다.';
    }

    store.setVisibleDialog({ message: szMsg });
    // // console.log(szMsg);

    const count = packet.readInt();
    for (let idx = 0; idx < count; idx++) {
      const orderIdx = packet.readInt();
      const futures = packet.readInt();
      const tradeType = packet.readInt();
      const oppositAmount = packet.readInt();
      const overTrading = packet.readInt();
      const overnightState = packet.readInt();

      const strLog = `[${Common.g_szFuturesNameMini[futures]}] ${Common.g_szOverTradingName[overTrading]} ${Common.g_szOvernightStateName[overnightState]}. (구분:${Common.g_szTradeTypeName[tradeType]}, 수량:${oppositAmount})`;
      store.LogMsg(strLog);
    }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_OVERNIGHTLIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_OVERNIGHTLIST_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_NEWACCPWD_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_NEWACCPWD_ACK](packet) {
    const store = useStore();

    // 		성공(INT)		1 bytes (0:성공, 1:실패)
    const nResult = packet.readInt();
    const domesticPwd = packet.readString();
    const overseaPwd = packet.readString();

    let szMsg = '';
    let isResetPassword = false;

    if (nResult === NetConstants.R_CHNGPWD_SUCCESS) {
      if (domesticPwd) {
        store.AccountState.szDomesticAccPwd = domesticPwd;
      }
      if (overseaPwd) {
        store.AccountState.szOverseaAccPwd = overseaPwd;
      }
      store.AccountState.bAccPassed = false;
      szMsg = '비밀번호를 변경하였습니다.';
      
      //로그인후 비밀번호 설정 시에는 Dialog 를 해당 페이지에서 처리
      const res = await store.getSettingAccountPopupState().then((res)=>{
        if(res === 1) {
          isResetPassword = true
        }
      });
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_WRONGPWD) {
      szMsg = '기존 비밀번호가 일치하지 않습니다.';
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_WRONGFMT) {
      szMsg = '비밀번호는 4자리 숫자 여야 합니다.';
    } else if (nResult === NetConstants.R_CHNGPWD_FAIL_SYSTEM) {
      szMsg = '시스템 장애가 발생했습니다.\n잠시후에 다시 시도하여 주세요.';
    }
    if(!isResetPassword){
      store.setVisibleDialog({ message: szMsg });
    }
    NetworkService.emitter.emit(Emit_AccountPasswordUpdate, { nResult: nResult, szMsg: szMsg, isResetPassword: isResetPassword });
    return;
  }

  static async [PacketID.PROTOCOL_USER_ACCPWD_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_ACCPWD_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_USERLOG_LIST_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_USERLOG_LIST_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_ACK](packet) {
    const store = useStore();

    const bEnter = packet.readInt();
    const nResult = packet.readInt();
  
    if (nResult === Common.NOERROR) {
      // 성공!  관리자 응답을 기다린다.
    } else {
      let szText;
      // 실패
      if (bEnter) {
        szText = '상담원과 연결에 실패하였습니다.\n잠시 후 다시 요청해주시기 바랍니다.';
      } else {
        szText = '채팅방 퇴장에 실패하였습니다.\n채팅방을 초기화 합니다.';
      }
      
      store.setVisibleDialog({ message: szText, callback: () => {
        // 채팅창 리셋
        NetworkService.emitter.emit(Emit_Chat, { event: eChatEvent_Init });
      }});
    }

    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_COUNSELOR](packet) {
    // 채팅창 상담원 입장(퇴장)
    const bEnter = packet.readInt();

    NetworkService.emitter.emit(Emit_Chat, { event: eChatEvent_Counselor, bEnter  });

    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_MESSAGE_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_MESSAGE_ACK](packet) {
    const msgType = packet.readInt();
    const szTime = packet.readString();
    const msgTime = moment(szTime).toDate();
    const szMessage = packet.readString();

    NetworkService.emitter.emit(Emit_Chat, { event: eChatEvent_Message, szMessage, MsgType: msgType, MsgTime: msgTime  });
    // 창 깜박임
    
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_TRANSFER_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_TRANSFER_ACK](packet) {
    // 채팅창 메세지
    const lpChatTransfer = new ChatTransfer();
    lpChatTransfer.Type = packet.readInt();
    lpChatTransfer.bUser = packet.readInt();
    lpChatTransfer.bUpload = packet.readInt();
    lpChatTransfer.bClipboard = packet.readInt();
    lpChatTransfer.TransferType = packet.readInt();
    const szTime = packet.readString();
    const msgTime = moment(szTime).toDate();
    lpChatTransfer.stTime = msgTime;
    const iCount = packet.readInt();
    for (let idx = 0; idx < iCount; idx++) {
      const szName = packet.readString();
      lpChatTransfer.szFileNames.push(szName);
      const szRemote = packet.readString();
      lpChatTransfer.szRemoteNames.Add(szRemote);
    }

    const bSendToServer = false;
    NetworkService.emitter.emit(Emit_Chat, { event: eChatEvent_Transfer, bSendToServer, lpChatTransfer  });
    // 창 깜박임

    return;
  }

  static async [PacketID.PROTOCOL_CMM_POPUP_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CMM_NEWNOTIC_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_IMAGE_TRANSFER_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_IMAGE_TRANSFER_ACK](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_SL_RESERVED_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_SL_RESERVED_ACK](packet) {
    const store = useStore();

    const resultCode = packet.readInt();
    const slReserved = packet.readInt();
    const profitTicks = packet.readInt();
    const lossTicks = packet.readInt();
    const orderIdx = packet.readInt();
    const futures = packet.readInt();
    const tradeType = packet.readInt();

    // console.log(`S/L 예약 신청 응답을 받았다: ${Common.g_szSLReservedName[slReserved]}, 결과코드:${resultCode}`);
  
    let szMsg = '';
    if (resultCode === NetConstants.r_SLReserved_Success) {
      szMsg = `S/L예약이 ${Common.g_szSLReservedName[slReserved]} 처리되었습니다.`;
    } else if (resultCode === NetConstants.r_SLReserved_Cancellation_Success) {
      szMsg = 'S/L예약 해제가 처리되었습니다.';
    } else if (resultCode === NetConstants.r_SLReserved_WrongOrder) {
      szMsg = 'S/L예약 요청할 주문상태를 확인해주세요.';
    } else { //if (resultCode === NetConstants.r_SLReserved_FailSystem || resultCode === NetConstants.r_SLReserved_Request || resultCode === NetConstants.r_SLReserved_WrongState) 
      szMsg = 'S/L예약 요청을 처리할 수 없습니다.';
    }

    store.setVisibleDialog({ message: szMsg });

    const strLog = `[S/L예약 ${Common.g_szSLReservedName[slReserved]} ${resultCode === NetConstants.r_SLReserved_Success || resultCode === NetConstants.r_SLReserved_Cancellation_Success ? '성공' : '실패'}]` +
      `익:${profitTicks}틱, 손:${lossTicks}틱, 주문(No: ${orderIdx}, ${Common.g_szFuturesNameMini[futures]}, ${Common.g_szTradeTypeName[tradeType]})`;
    store.LogMsg(strLog);
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_LOG_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHAT_LOG_ACK](packet) {
    const store = useStore();

    //         총리스트수(INT)        3 bytes        
    const totalCount = packet.readInt();
    //         현재리스트 수(INT)    2 bytes
    const curCount = packet.readInt();
    //         첫리스트여부(INT)    1 bytes
    const isFirst = packet.readInt();
  
    if (totalCount === 0) {
      store.AccountState.ChatLogTotalCount = 0;
      store.AccountState.ChatLogCurCount = 0;
      store.AccountState.ChatLogs = [];
  
      return;
    }

    store.AccountState.ChatLogTotalCount = totalCount;

    // 채팅 로그
    // 첫 패킷이면
    if (isFirst) {
      store.AccountState.ChatLogCurCount = 0;
      store.AccountState.ChatLogs = [];
    }

    for (let i = 0; i < curCount; i++) {
      
      const szName = packet.readString();
      const msgType = packet.readInt();
      const szTime = packet.readString();
      const msgTime = moment(szTime).toDate();
      const szMessage = packet.readString();
      const transferType = packet.readInt();

      let chatLog = new ChatMsg({
        Type: msgType,
        stTime: msgTime,
        szMsg: szMessage,
        TransferType: transferType,
        szName: szName,
      });

      store.AccountState.ChatLogs.push(chatLog);
    }
  
    store.AccountState.ChatLogCurCount += curCount;

    // 모든 리스트를 다 받았으면(마지막 패킷이면)
    if (store.AccountState.ChatLogCurCount >= store.AccountState.ChatLogTotalCount) {
      // 마지막 로그를 받았다.
      NetworkService.emitter.emit(Emit_Chat, { event: eChatEvent_Logs });
    }
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHATTIME_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_CHATTIME_ACK](packet) {
    const store = useStore();
    
    const szChatTime = packet.readString();
    const bAlarm = packet.readInt();
    const szChatLogTime = packet.readString();

    let chatTime = Date.now();
    if (szChatTime !== '0000-00-00 00:00:00' && szChatTime.trim() !== '') {
      chatTime = moment(szChatTime).toDate();
    }
    store.AccountState.stChatTime = chatTime;
    // 알림을 사용.
    if (bAlarm) {
      let chatLogTime = chatTime;
      if (szChatLogTime !== '0000-00-00 00:00:00' && szChatLogTime.trim() !== '') {
        chatLogTime = moment(szChatLogTime).toDate();
      }
      // 채팅 로그시간이 유효하고 채팅시간이 로그시간보다 이전인경우(관리자가 유저 로그 off시 채팅을 남긴경우) 알림사용
      let bPopup = chatTime < chatLogTime;
      //if (__DEV__) bPopup = true;
      store.AccountState.bChatPopup = bPopup;
      /*
      if (bPopup) {
        // 팝업창을 띄운다.
        const szMsg = '관리자가 메세지를 남겼습니다.\n\n[상담센터]에서 메세지를 확인하시겠습니까?';
        SystemStore.setVisibleDialog({ message: szMsg, okCancel: true, callback: 'Customer' });
      }
      */
    }
    
    return;
  }

  static async [PacketID.PROTOCOL_CHART_BASE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CHART_DATA_REQ](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_CHART_DATA_ACK](packet) {
    const reqId = packet.readInt();
    const futures = packet.readInt();
    // cPeriod_None, ...
    const period = packet.readInt();
    // cTerm_None, ...
    const term = packet.readInt();
    const count = packet.readInt();
    const szStartDate = packet.readString();
    const szEndDate = packet.readString();

    // 챠트에 전달
    // PostMessage(WM_CHART_DATA_ANS)

    const chartData = {
      ReqId: reqId, 
      Futures: futures, 
      Period: period, 
      Term: term, 
      Count: count, 
      szStartDate: szStartDate, 
      szEndDate: szEndDate,
    };
    NetworkService.emitter.emit(Emit_ChartData, { chartData });

    Datafeed.onHistoryReceiveFinished(chartData);

    return;
  }

  static async [PacketID.PROTOCOL_AGENT_LOGIN](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGENT_DAILY_LIST](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGENT_DAILY_REPORT](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGENT_USER_LIST](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGENT_TRADE](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGNET_CONTRACTED_S](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_AGNET_LEFT_S](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_USER_MARKET_TIME](packet) {
    const store = useStore();
  
    const msgCode = packet.readInt();
    const nRemain = packet.readInt();
    const serverTime = packet.readString();
  
    let strMsg = '';
    // 사운드 및 메세지 정의
    if (msgCode === Common.kTimeMsgMarketOpen || msgCode === Common.kTimeMsgMarketOpenOverSea || msgCode === Common.kTimeMsgMarketNightOpen || msgCode === Common.kTimeMsgMarketHangsengOpen || 
      msgCode === Common.kTimeMsgMarketHangseng2Open || msgCode === Common.kTimeMsgMarketA50Open || msgCode === Common.kTimeMsgMarketDAXOpen)
    {
      // 개장
      if(nRemain === 0) {
        if (msgCode === Common.kTimeMsgMarketOpen) {
          strMsg = '금일장이 시작되었습니다.';
        } else {
          strMsg = '거래가 시작되었습니다.';
        }
      }
  
      if (nRemain > 0) {
        strMsg = `장개시 ${nRemain}분전 입니다.`;
      }
  
      // 서비스 중인 것만 보낸다.
      if ((!store.MarketState.bDrawMenu[Common.G_FUTURES_NIGHT] && msgCode===Common.kTimeMsgMarketNightOpen) &&
        (!store.MarketState.bDrawMenu[Common.G_FUTURES_HANGSENG] && (msgCode===Common.kTimeMsgMarketHangsengOpen || msgCode===Common.kTimeMsgMarketHangseng2Open)) &&
        (!store.MarketState.bDrawMenu[Common.G_FUTURES_CHINA50] && msgCode===Common.kTimeMsgMarketA50Open) &&
        (!store.MarketState.bDrawMenu[Common.G_FUTURES_DAX] && msgCode===Common.kTimeMsgMarketDAXOpen))
      {
        strMsg = '';
      }
    }  
    else if (msgCode === Common.kTimeMsgMarketDayClose || msgCode === Common.kTimeMsgMarketClose || msgCode === Common.kTimeMsgMarketHangsengClose || msgCode === Common.kTimeMsgMarketHangseng2Close || msgCode === Common.kTimeMsgMarketA50Close)
    {
      // 패장
      if (nRemain === 0) {
        if (msgCode === Common.kTimeMsgMarketClose) {
          strMsg = '금일장이 모두 마감 되었습니다.';
        } else {
          strMsg = '장이 마감 되었습니다.';
        }
  
        // 장미 마감 되었으므로 스탑로스 및 MIT 초기화
        let futCode = Common.G_FUTURES_MAX;
        if (msgCode === Common.kTimeMsgMarketDayClose) futCode = Common.G_FUTURES_DAY;
        else if (msgCode === Common.kTimeMsgMarketHangsengClose || msgCode === Common.kTimeMsgMarketHangseng2Close) futCode = Common.G_FUTURES_HANGSENG;
        else if (msgCode === Common.kTimeMsgMarketA50Close) futCode = Common.G_FUTURES_CHINA50;
  
        if (futCode === Common.G_FUTURES_MAX) {
          // 주문창이 없는 선물도 MIT 및 스탑로스 초기화
          for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
            // StopLoss 설정 초기화
            store.InitStopLoss(futures);

            // MIT 초기화
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
              const lpMITMap = store.AccountState.FuturesMIT[futures][tradeType];
              if (lpMITMap) {
                // 모두 삭제
                lpMITMap.splice(0); 
              }
            }
          }
        } else {
          // StopLoss 설정 초기화
          store.InitStopLoss(futCode);

          // MIT 초기화
          for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
            const lpMITMap = store.AccountState.FuturesMIT[futCode][tradeType];
            if (lpMITMap) {
              // 모두 삭제
              lpMITMap.splice(0); 
            }
          }
        }
      }
  
      if(nRemain > 0) {
        strMsg = `장마감 ${nRemain}분전 입니다.`;
      }
  
      // 서비스 중인 것만 보낸다.
      if ((!store.MarketState.bDrawMenu[Common.G_FUTURES_DAY] && msgCode===Common.kTimeMsgMarketDayClose) &&
        (!store.MarketState.bDrawMenu[Common.G_FUTURES_HANGSENG] && (msgCode===Common.kTimeMsgMarketHangsengClose || msgCode===Common.kTimeMsgMarketHangseng2Close)) &&
        (!store.MarketState.bDrawMenu[Common.G_FUTURES_CHINA50] && msgCode===Common.kTimeMsgMarketA50Close))
      {
        strMsg = '';
      }
    }
  
    const szMsgCode = Common.g_szTimeMsgCode[msgCode];
    if (szMsgCode) {
      strMsg = `[${szMsgCode}] ${strMsg}`;
    }
  
    // 주문 및 체결 소리 알림 사용
  
    // 팝업창을 띄운다.
    // POPUP_MSG_MARKET
  
    // 종합창 메세지
    store.LogMsg(strMsg, serverTime);
  
    return;
  }

  static async [PacketID.PROTOCOL_USER_SERVICE](packet) {
    const store = useStore();

    for(let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      const nServece = packet.readInt();
      //// console.log(`서비스 ${Common.g_szFuturesName[futCode]}, ${nServece}`);
      store.MarketState.bDrawMenu[futCode] = nServece;
    }

    // 월물을 그린다.

    return;
  }

  static async [PacketID.PROTOCOL_USER_OPTIONS](packet) {
    const store = useStore();

    const bIndivCommission = packet.readInt();
    store.MarketState.bIndivCommission = bIndivCommission;
    let dbCommission;
    for(let futCode = Common.G_FUTURES_DAY; futCode < Common.G_FUTURES_MAX; futCode++) {
      dbCommission = packet.readDouble();
      store.MarketState.dbIndivCommission[futCode] =dbCommission;
    }

    const bIndivMaxContract = packet.readInt();
    const iDomesticMaxContract = packet.readInt();
    const iOverseaMaxContract = packet.readInt();
    store.MarketState.bIndivMaxContract = bIndivMaxContract;
    store.MarketState.iDomesticMaxContract = iDomesticMaxContract;
    store.MarketState.iOverseaMaxContract = iOverseaMaxContract;
  
    // 수수료가 변경되었기에 마스터 코드를 새로 그린다.
    store.DrawPossibleOrder();
    NetworkService.emitter.emit(Emit_UserOption);
    return;
  }

  static async [PacketID.PROTOCOL_USER_LOG](packet) {
    return;
  }

  static async [PacketID.PROTOCOL_MAX](packet) {
    return;
  }
}
