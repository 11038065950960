<template>
	<div class="right">
		<div class="stock-list-box designBox">
			<div class="title-box">
				<h1 class="title">실시간 선물시세</h1>
			</div>
			<div class="table-box">
				<div class="table-head flex-center">
					<div class="item name bdrWhite">
						<p>종목</p>
					</div>
					<div class="item live bdrWhite">
						<p>현재가</p>
					</div>
					<div class="item before bdrWhite">
						<p>대비</p>
					</div>
					<div class="item updown bdrWhite">
						<p>등락률</p>
					</div>
					<div class="item amount">
						<p>거래량</p>
					</div>
				</div>
				<div class="table-body flex-center" v-for="(item, idx) in state.marketPrices" :key="idx">
					<div class="item name" :class="{'on-event' : props.initIsOnEvent}" @click="onChangeFutures(item.코드)">
						<p>{{ item.이름 }}<br/><span>{{ item.코드 }}({{ item.만기일 }})</span></p>
					</div>
					<div class="item live" :class="[{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]">
						<p>{{ Utils.PriceFormatStr(item.현재가, item.종목) }}</p>
					</div>
					<div class="item before" :class="[{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]">
						<p>{{ XingAPIService.GetSignData(item.전일대비기호) }} {{ Utils.PriceFormatStr(item.전일대비, item.종목) }}</p>
					</div>
					<div class="item updown" :class="[{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]">
						<p>{{ item.등락률?.toFixed(2) }}%</p>
					</div>
					<div class="item amount">
						<p>{{ toLocaleString(item.거래량) }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive, defineProps, route, defineEmits } = useBaseComponent();

import * as Common from '@/constants/Common';
import * as Utils from '@/utils/Common';
import { toLocaleString } from '@/utils/number';
import { XingAPIService } from '@/network/XingAPIService';

import * as NetConstants from '@/network/Constants';
import moment from '@/utils/moment';
import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import iconv from 'iconv-lite';

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

const REFRESH_INTERVAL = 200;

const props = defineProps({
    initIsOnEvent: {
        type: Boolean,
        default: false,
    },
});

let state = reactive({
	refreshing : false,
    refreshCount : 0,
    marketPrices: [] as any,
});

onMounted(()=>{
    const autoRefreshing = async () => {
        onRefresh();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
})

// onUnmounted(()=> { 
// })

const onRefresh = async () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    //this.refreshing = true;

    let marketPrices = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        
        if (store.MarketState.bDrawMenu[futures]) {
            // // EURO FX 6월물, EURO FX 12월물
            const monthIdx = store.MarketState.szFutureName[futures]?.length - 4;
            const month = store.MarketState.szFutureName[futures]?.substring(monthIdx, monthIdx + 2);
            const yearIdx = store.MarketState.szFutureCode[futures]?.length - 2;
            const year = store.MarketState.szFutureCode[futures]?.substring(yearIdx);
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            let expiryDay = _expiryDate;
            if (futures < Common.G_FUTURES_OVERSEAS) {
                expiryDay = `${_expiryDate.substring(2, 5)}${month}`;
            } else {
                expiryDay = `${year}-${month}`;
            }

            const _marketPrice = {
                종목: futures,
                이름: store.MarketState.szFutureName[futures], //Common.g_szFuturesName[futures],
                코드: store.MarketState.szFutureCode[futures],
                만기일: expiryDay,
                전일가: store.MasterState.dbBPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbBPrice[futures], futures),
                시가: store.MasterState.dbOpenPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbOpenPrice[futures], futures),
                고가: store.MasterState.dbHighPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbHighPrice[futures], futures),
                저가: store.MasterState.dbLowPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbLowPrice[futures], futures),
                현재가: store.MasterState.dbCurPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbCurPrice[futures], futures),
                전일대비기호: store.MasterState.szYDiffSign[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                전일대비: store.MasterState.dbYDiffPrice[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                등락률: store.MasterState.dbChgRate[futures], //`${store.MasterState.dbChgRate[futures]?.toFixed(2)}%`,
                거래량: store.MasterState.totq[futures],
            };
            
            marketPrices.push(_marketPrice);
        }
    }
    state.marketPrices = marketPrices;
    state.refreshCount++;

    //this.refreshing = false;

}

const onChangeFutures = (code: any) => {
    if(props.initIsOnEvent){
        reqChangeFutures(code);
    }
}

const emit = defineEmits(["reqChangeFutures"]);

const reqChangeFutures = (code: any) => { 
    emit("reqChangeFutures", {code: code}); 
};

</script>
