import * as Common from '../constants/Common';

export class NoticeObj {
  iNoticeIdx = 0;   // 공지 번호
  iType = Common.cNotice_Normal;  // 공지 구분
  szTypeName = '';  // 공지구분 이름: 일반공지, 필수공지, 긴급공지, 전광판
  szRegDate = '';   // 게시 시간 yyyy-mm-dd hh:mm:ss
  szTitle = '';   // 제목
  szWriter = '';   // 작성자
  szContents = '';  // 공지내용
  contentBuff = null;  // rtf 공지내용
}