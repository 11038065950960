<template>
    <div class="wrap">
        <div class="total-order">
            <div class="top flex">
                <div class="bank-account">
                    <div class="dropdown-sort">
                        <div class="select-sort">
                            <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickFuturesOpen" />
                            <label for="dropPageCount" class="for-dropsort pageCount">
                                <div class="drop-out-wrap">
                                    <p class="out " v-html="state.pickFuturesDesc"></p>
                                    <i class="icon-down" :class="[{ on: state.pickFuturesOpen }]" />
                                </div>
                                <!-- <p class="out"> <span v-html="state.pickFuturesDesc"></span><i class="icon-down" :class="[{ on: state.pickFuturesOpen }]" /></p> -->
                            </label>
                            <div class="section-sort" v-if="state.pickFuturesValue">
                                <p v-for="(item, index) in state.pickFuturesItems" :key="index" @click="onPickFuturesChangeValue(item)" class="text" :class="{ active: item.value === state.pickFuturesValue }">
                                    <span class="big-span">{{ item.label }}</span>
                                    <span class="small-span">{{ item.desc }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="search" @click="stockSearch()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="26" height="26" fill="white">
                        <path fill="white" d="M3.5 8a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM8 2a6 6 0 1 0 3.65 10.76l3.58 3.58 1.06-1.06-3.57-3.57A6 6 0 0 0 8 2Z"></path>
                    </svg>
                </button>
                <button class="chart-btn" @click="viewChart()">
                    차트
                </button>
            </div>
            <div class="mid">
                <div class="flex price">
                    <h1  :style="{color:state.priceColor}"> {{ Utils.PriceFormatStr(state.현재가, state.futures) }} </h1>
                        <div class="flex" :style="{color:state.diffColor}">
                            {{ XingAPIService.GetSignData(state.전일대비기호) }} 
                            <p :style="{color:state.diffColor}">{{ Utils.PriceFormatStr(state.전일대비, state.futures) }}</p>
                        </div>
                        <p :style="{color:state.diffColor}">{{ state.등락률?.toFixed(2) }}%</p>
                </div>
                <div class="account flex">
                    <p>{{ state.pickAccDesc }}</p>
                    <!-- <button  style="cursor: inherit;" v-if="state.bAccPassed">****</button> -->
                    <div class="password-box">
                        <input type="password" placeholder="계좌비밀번호" v-model="state.password" :readonly="state.bAccPassed"/>
                    </div>
                </div>
                <div class="row tab">
                    <div class="col" @click="onTabPress(Trade_Buy)" :class="{ active1: state.tradingIdx === Trade_Buy }">
                        <p>매수</p>
                    </div>
                    <div class="col" @click="onTabPress(Trade_Sell)" :class="{ active2: state.tradingIdx === Trade_Sell }">
                        <p>매도</p>
                    </div>
                    <div class="col" @click="onTabPress(Trade_Stoploss)" :class="{ active3: state.tradingIdx === Trade_Stoploss }">
                        <p>S/L예약주문</p>
                    </div>
                    <div class="col" @click="onTabPress(Trade_Correct)" :class="{ active4: state.tradingIdx === Trade_Correct }">
                        <p>정정/취소</p>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="flex buy"  :style="{height: state.bottomHeight + 'px'}">
                    <div class="left">
                        <div class="title-box">
                            <div @click="onQuoteTabPress(Quote_Call)" class="resize" :class="{ on: state.tabIdx === Quote_Call }">호가</div>
                            <div @click="onQuoteTabPress(Quote_Contract)" class="conclusion" :class="{ on: state.tabIdx === Quote_Contract }">체결</div>
                        </div>
                        <div class="table-scroll" :style="{height: state.tableHeight + 'px'}">
                            <table v-if="state.tabIdx === Quote_Call">
                                <!-- 매도 -->
                                <tr class="sell" v-for="(item, idx) in state.sellData[0]" :key="idx" :class="'tr' + idx" @click="onPricePress(item.title)">
                                    <td v-if="idx === 0" :style="item.textColor">{{ item.title }}</td>
                                    <td class="price-text" v-if="idx === 0" :style="state.sellData[1][idx].textColor">{{ state.sellData[1][idx].title }}</td>

                                    <td v-if="idx > 0" :style="{'border': '1px solid' + item.textColor.color}" :class="{no: item.bCurrent !== true}"><p :style="item.textColor">{{ item.title }} <span>{{ item.changeRate }}</span></p></td>
                                    <td  v-if="idx > 0">{{ state.sellData[1][idx].title }}</td>
                                </tr>
                                <!-- 매수 -->
                                <tr class="purchase" v-for="(item, idx) in state.buyData[0]" :key="idx" :class="'tr' + idx" @click="onPricePress(item.title)" >
                                    <td v-if="idx >= BUY_LINE_MAX" :style="item.textColor">{{ item.title }}</td>
                                    <td class="price-text" v-if="idx >= BUY_LINE_MAX" :style="state.buyData[1][idx].textColor">{{ state.buyData[1][idx].title }}</td>

                                    <td v-if="idx < BUY_LINE_MAX" :style="{'border': '1px solid' + item.textColor.color}" :class="{no: item.bCurrent !== true}"><p :style="item.textColor">{{ item.title }} <span>{{ item.changeRate }}</span></p></td>
                                    <td v-if="idx < BUY_LINE_MAX">{{ state.buyData[1][idx].title }}</td>
                                </tr>
                            </table>
                            <table v-else-if="state.tabIdx === Quote_Contract">
                                <tr class="gray-box">
                                    <td colspan="2">
                                        <div class="flex">
                                            <p>시가</p>
                                            <p class="num" :style="{color: state.infoData.시가.color}">{{ state.infoData.시가.value }}</p>
                                        </div>
                                        <div class="flex">
                                            <p>고가</p>
                                            <p class="num" :style="{color: state.infoData.고가.color}">{{ state.infoData.고가.value }}</p>
                                        </div>
                                        <div class="flex">
                                            <p>저가</p>
                                            <p class="num" :style="{color: state.infoData.저가.color}">{{ state.infoData.저가.value }}</p>
                                        </div>
                                        <div class="flex">
                                            <p>거래량</p>
                                            <p class="num" :style="{color: state.infoData.거래량.color}">{{ state.infoData.거래량.value }}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-for="(quote, idx) in state.quoteData.slice().reverse()" :key="idx" class="change" :class="'change' + idx">
                                    <td class="first"><p :style="{color: getQuoteDataColor('price', quote)}">{{ Utils.PriceFormatStr(quote.dbCurPrice, quote.iFutures) }}</p></td>
                                    <td class="conclusion" :style="{color: getQuoteDataColor('count', quote)}">{{ quote.iTrdq }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="ratio-box" style="z-index: 2;">
                            <td colspan="2" class="ratio"  >{{ state.clock }}</td>
                        </div>
                    </div>
                    <div class="right flex" >
                        <div class="justifySpaceBetween">
                            <!-- 국내 예탁금(kospi) 해외 예탁금(나머지) -->
                            <!-- <div class="deposit-info" v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell">
                                <div class="deposit-info-box marginB10">
                                    <p class="text value">{{`${Common.g_szNationName[state.isOversea]}예탁금`}}</p>
                                    <p class="text amount" v-if="state.isOversea === Common.eNation_Domestic">{{ toLocaleString(state.depoAmount) }}</p>
                                    <p class="text amount" v-else>{{ toLocaleString(state.depoAmountOversea) }}</p>
                                </div>
                                <div class="deposit-info-box">
                                    <p class="text value">{{`${Common.g_szNationName[state.isOversea]}평가예탁금`}}</p>
                                    <p class="text amount" v-if="state.isOversea === Common.eNation_Domestic">{{ toLocaleString(state.estimatedAmount) }}</p>
                                    <p class="text amount" v-else>{{ toLocaleString(state.estimatedAmountOversea) }}</p>
                                </div>
                            </div> -->
                            <!-- /기존 지정가 시장가 드롭다운/ -->
                            <!-- <div class="dropdown" v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell">
                                <button
                                    class="btn btn-secondary dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    v-text="state.pickPriceDesc"
                                ></button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li v-for="item in state.pickPriceItems" :key="item.value" @click="onPickPriceChangeValue(item)">
                                        <a class="dropdown-item" v-html="item.label"></a>
                                    </li>
                                </ul>
                            </div> -->
                            <div v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell">
                                <div class="market-price-select-box">
                                    <!-- <p class="text">가격종류</p> -->
                                    <div class="radio-wrap">
                                        <label class="label-radio first">
                                            <input type="radio" :value="Common.G_PRICE_APPOINT" v-model="state.selectedPriceType" @change="onChangePriceType">
                                            지정가
                                        </label>
                                        <label class="label-radio">
                                            <input type="radio" :value="Common.G_PRICE_MARKET" v-model="state.selectedPriceType" @change="onChangePriceType">
                                            시장가
                                        </label>
                                    </div>
                                </div>
                                <div class="able-amount">
                                    <div class="main name">
                                        <p class="category">예탁금</p>
                                        <p class="value" v-if="state.isOversea === Common.eNation_Domestic">{{ toLocaleString(state.depoAmount) }}</p>
                                        <p class="value" v-else>{{ toLocaleString(state.depoAmountOversea) }}</p>
                                    </div>
                                </div>
                                <div class="possible" >
                                    <p>주문가능</p>
                                    <a @click="refreshOrderCount('orderLeft')">신규(주문)</a>
                                    <a @click="refreshOrderCount('settleLeft')">청산</a>
                                </div>
                            </div>
                            <div class="check flex typing-box" v-else-if="state.tradingIdx === Trade_Correct">
                                <input class="orgOrderNo" type="text" placeholder="주문번호" v-model="state.orgOrderNo" readonly />
                                <p @click="onLeftPress()">미체결</p>
                                <!-- <p>체결</p> -->
                            </div>
                            <div class="count">
                                <p>주문수량</p>
                                <div class="typing-box" :class="{ gray : state.tradingIdx === Trade_Stoploss }">
                                    <img src="@/assets/images/minus.svg" alt="감소" class="minus" v-if="state.tradingIdx !== Trade_Stoploss && state.tradingIdx !== Trade_Correct" @click="onChangeAmount(-1)"  />
                                    <input type="text" v-model="state.amount" :readonly="state.tradingIdx === Trade_Stoploss || state.tradingIdx === Trade_Correct" @keyup="onChangeAmount()" />
                                    <img src="@/assets/images/plus.svg" alt="증가" class="plus" v-if="state.tradingIdx !== Trade_Stoploss && state.tradingIdx !== Trade_Correct" @click="onChangeAmount(1)" />
                                </div>
                                <p>주문가격</p>
                                <div class="typing-box" :class="{ gray : state.tradingIdx === Trade_Stoploss }">
                                    <img src="@/assets/images/minus.svg" alt="감소" class="minus" v-if="state.tradingIdx !== Trade_Stoploss" @click="onChangePrice(-1)" />
                                    <input type="text" value="시장가" v-if="Number(state.selectedPriceType) === Common.G_PRICE_MARKET && (state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell)" readonly/>
                                    <input type="text" v-model="state.price" v-else :readonly="state.tradingIdx === Trade_Stoploss"/>
                                    <img src="@/assets/images/plus.svg" alt="증가" class="plus" v-if="state.tradingIdx !== Trade_Stoploss" @click="onChangePrice(1)" />
                                </div>
                                <div class="sl" v-if="state.tradingIdx === Trade_Stoploss">
                                    <p>청산조건(틱)</p>
                                    <div class="flex">
                                        <p class="condition">이익</p>
                                        <div class="typing-box">
                                            <img src="@/assets/images/minus.svg" alt="감소" class="minus" @click="onProfitAmount(-1)"/>
                                            <input type="text" placeholder="이익" v-model="state.profitTicks" />
                                            <img src="@/assets/images/plus.svg" alt="증가" class="plus" @click="onProfitAmount(1)"/>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <p class="condition">손실</p>
                                        <div class="typing-box last">
                                            <img src="@/assets/images/minus.svg" alt="감소" class="minus" @click="onLossAmount(-1)"/>
                                            <input type="text" placeholder="손실" v-model="state.lossTicks" />
                                            <img src="@/assets/images/plus.svg" alt="증가" class="plus" @click="onLossAmount(1)"/>
                                        </div>
                                    </div>
                                </div>
                                <a @click="openLeverage" v-if="store.SystemState._appMode === 2 && (state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell)">
                                    레버리지[1 : {{ state.isOversea === Common.eNation_Domestic ? store.AccountState.iLeverage : store.AccountState.iLeverageEuro }}]
                                </a>
                            </div>
                            <!-- <div class="my-amount" v-if="state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell">
                                <div class="top-box">
                                    <h3 class="title">보유자산(KRW)</h3>
                                    <div class="my-amount-list">
                                        <p class="menu" v-if="state.tradingIdx === Trade_Buy">매수평균가</p>
                                        <p class="menu" v-else>매도평균가</p>
                                        <p class="value">88,888,888,888</p>
                                    </div>
                                    <div class="my-amount-list">
                                        <p class="menu">평가금액</p>
                                        <p class="value">88,888,888,888</p>
                                    </div>
                                    <div class="my-amount-list">
                                        <p class="menu">평가손익</p>
                                        <p class="value minus">88,888,888,888</p>
                                    </div>
                                    <div class="my-amount-list">
                                        <p class="menu">수익률</p>
                                        <p class="value plus">88,888,888,888</p>
                                    </div>
                                </div>
                                <div class="bottom-box">
                                    <div class="my-amount-list">
                                        <p class="menu" >최소주문금액</p>
                                        <p class="value">888,888 KRW</p>
                                    </div>
                                    <div class="my-amount-list">
                                        <p class="menu" >수수료(부가세 포함)</p>
                                        <p class="value">888,888</p>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="buttons" v-if="state.tradingIdx === Trade_Buy" :class="{'buyType':state.tradingIdx === Trade_Buy}">
                            <button class="reset" @click="onPressInit()">초기화</button>
                            <button class="next"  @click="onActionPress()" style="    width: calc(70% - 10px);">매수</button>
                        </div>
                        <div class="buttons" v-else-if="state.tradingIdx === Trade_Sell">
                            <button class="reset sell"  @click="onPressInit()">초기화</button>
                            <button class="next sell"  @click="onActionPress()" style="    width: calc(70% - 10px);">매도</button>
                        </div>
                        <div class="buttons" v-else-if="state.tradingIdx === Trade_Stoploss">
                            <button class="on" @click="onActionPress()">S/L집행</button>
                            <button class="cancel" @click="onCancelCorrect()">S/L취소</button>
                        </div>
                        <div class="buttons" v-else-if="state.tradingIdx === Trade_Correct">
                            <button class="on" @click="onActionPress()">정정주문</button>
                            <button class="cancel" @click="onCancelCorrect()">취소주문</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PopupLeftOrders :isShowPopup="state.isShowPopupLeftOrders" :initLeftOrders="state.leftOrders" @onCorrectSelectedPress="onCorrectSelectedPress" />
    </div>
        <div class="body-box subPage transactionForm tradeList" style="padding-top:12px">
            <div class="con-box bottom">
                <div class="left index">
                    <div class="trade-list-box">
                        <PcTradeTable :initBAccPAssed="state.bAccPassed"/>
                    </div>
                </div>
            </div>
        </div>
        <PopupStockSearch :isShowPopup="state.isShowPopupStockSearch" :type="'left'" :initFuturesItems="state.pickFuturesItems" @onPickFuturesChangeValue="onPickFuturesChangeValue" @close="close('PopupStockSearch')"/>
        <PopupChart :initShowPopup="state.initIsShowPopupChart" :initFuturesItem="state.pickFuturesSelectedItem" :initFutures="state.futures" :type="state.popupChartType" :popupHeight="state.popupHeight" :initOrderLeft="state.orderLeft" :initAccPassed="state.bAccPassed" @close="close('PopupChart')" @onActionMarketTrade="onActionMarketTrade" v-if="state.initIsShowPopupChart"/>
        <PopupNotice :initShowNotice="state.initShowNoticePopup" :initTitle="SLNoticeTitle" :initNoticeMessage="SLNoticeContent" @noToday="onPressNotToday" @close="close('PopupNotice')" />
        <LeverageSetting :isShowPopup="state.isShowLeverageSetting" :type="'left'"  @close="close('LeverageSetting')" v-if="state.isShowLeverageSetting"/>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive, watch } = useBaseComponent();

import { Theme } from '../constants';
// import i18n from '../services/i18n';
import moment from '../utils/moment';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import * as Utils from '../utils/Common';
import { NetworkService, Emit_DrawTrans, Emit_UserOption, Emit_ResetAmount, XingAPIService, Emit_ContractData, Emit_CallData, Emit_Success } from '../network';
import { UserLog } from '../network/UserLog';
import * as AccPwd from '../constants/AccPwd';
import * as Constant from '../constants/Global';

// 탭 구분
const Trade_Buy = 0;     //매수
const Trade_Sell = 1;    //매도
const Trade_Stoploss = 2; //S/L예약주문
const Trade_Correct = 3; //정정/취소
// const Trade_Max = 4;

// 화면 갱신
const REFRESH_INTERVAL = 300;  //100;

// 호가/체결 탭 구분
const Quote_Call = 0; // 초가
const Quote_Contract = 1;   // 체결
// const Quote_Max = 2;

const SELL_LINE_MAX = 5;
const BUY_LINE_MAX = 5;
// const QUOTE_LINE_MAX = (SELL_LINE_MAX + BUY_LINE_MAX + 2); // + 고가 / 저가

// 체결 데이터 수
const QUOTE_DATA_MAX = 20;

const GRID_SELL_COLOR = { backgroundColor: Theme.mts.gridSellBg };
const GRID_BUY_COLOR = { backgroundColor: Theme.mts.gridBuyBg };
// const GRID_CENTER_COLOR = { backgroundColor: Theme.mts.gridCenter };
// const GRID_CURPRICE_COLOR = { backgroundColor: Theme.mts.bgCurPrice };
// const GRID_TEXT_CURPRICE_COLOR = { color: Theme.mts.textCurPrice };
const GRID_TEXT_BASEPRICE_COLOR = { color: Theme.mts.textBasePrice };
// const TEXT_COLOR = { color: Theme.mts.text };

const TradingInfo = [
    // Trade_Buy
    { name: '매수', button: '매수', color: '#e74e36', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#f7392c', },
    // Trade_Sell
    { name: '매도', button: '매도', color: '#2754a0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#35569a', }, 
    // Trade_Stoploss
    { name: 'S/L 주문', button: 'S/L 집행', button2: 'S/L 취소', color: '#0bbfe0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#68badb', }, 
    // Trade_Correct
    { name: '정정/취소', button: '정정주문', button2: '취소주문', color: '#2ec751', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#5fc25b', },
];

// S/L 예약 유의사항
const SLNoticeTitle = '해외선물 STOP 예약주문 유의사항';
const SLNoticeContent = `
    <div class="totalOrder-text">
        <p class="text blue">
            해외선물 STOP 예약주문은 네트워크 장애, 시세지연, 천재지변등 다양한 요인에 의해 정상적으로 작동하지 않을 수 있으니 반드시 아래의 사용법과 유의사항을 숙지하신 후 이용하여 주시기 바랍니다.
        </p>
        <p class="text ">
            1. STOP 예약주문의 정의 <br /> 기본 개념은 손절매을 위한 주문으로 조건 주문 중 하나, 현재가격이 설정한 조건가격에 도달하게 되면 조건주문이 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            2. STOP 예약주문 집행 방식 및 절차 <br /> 현재가격이 조건가격에 다다를 경우 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            현재가 >= 조건가 (이익/손실 틱)> 시장가 주문
        </p>
        <p class="text red">
            [S/L 집행] 실행 이후 조건을 변경 하면  [S/L 집행] 를 다시 실행해야 합니다. 
        </p>
        <p class="text ">
            3. STOP 예약주문 유의사항 <br /> STOP 예약주문은 현재가격이 조건가격에 <br /> 도달했을 시 시장가 주문으로 접수 / 처리 됩니다.
        </p>
        <p class="text red">
            이로 인해 시장이 급등락하거나, 호가공백이 발생할 경우 체결이 되지 않거나 STOP 가격보다 불리한 가격에 체결이 될 가능성이 있으니 이점 반드시 숙지하시기 바랍니다.
        </p>
    </div>`;

let state = reactive({

    // 종목 선택
    pickFuturesOpen: false,
    pickFuturesValue: '',
    pickFuturesItems: [ ] as any,
    pickFuturesDesc: '',
    pickFuturesSelectedItem: {} as any,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    // 가격 타입 선택(지정가, 시장가)
    // pickPriceValue: 1,
    // pickPriceDesc: '',
    // pickPriceItems: [] as any,

    //미체결 리스트
    leftOrders: [] as any,
    leftSelected: -1,

    futures: Common.G_FUTURES_DAY,
    oneTick: Common.G_ONETICK[Common.G_FUTURES_DAY],
    isOversea: Common.eNation_Domestic,

    depoAmount: 0,				// 국내예탁금액
    depoAmountOversea: 0,		// 해외예탁금액
    estimatedAmount: 0,			// 국내평가예탁금액
    estimatedAmountOversea: 0,	// 해외평가예탁금액
    totalProfit: 0,				// 총평가손익
    netDomestic: 0,				// 국내실현손익
    netOversea: 0,				// 해외실현손익

    //현재 거래 탭
    tradingIdx: Trade_Buy,

    //가격 종류 0:시장가 1:지정가
    selectedPriceType: Common.G_PRICE_APPOINT,  //라디오 버튼
    // priceType: Common.G_PRICE_APPOINT,
    amount: '0',
    amountNumber: 0,
    price: '0',
    
    //원 주문 정보
    orgOrderNo: '', //원주문번호
    orgPrice: '0', // 정정시 원 가격
    orgTradeType: Common.G_TRADE_SELL, // 정정시 원 유형

    // 주문가능
    orderLeft: 0,

    // 청산가능
    settleLeft: 0,

    // 미체결 / 체결
    leftAmount: 0,
    oppositeAmount: 0,

    // 익절 / 손절
    oppOrder: null as any,
    profitTicks: '',
    lossTicks: '',

    전일가: 0.,
    현재가: 0.,
    전일대비기호: '1',
    전일대비: 0.,
    등락률: 0.,
    priceColor: '',
    diffColor: '', 

    // 매수
    buyData: [[], [], [],] as any,
    // 매도
    sellData: [[], [], [],] as any,
    // 정보
    infoData: {
        //종목: Common.G_FUTURES_DAY,
        //현재가: 0,
        //전일가: 0,
        //대비기호: '',
        //대비: 0,
        //대비율: 0,
        시가: 0,
        고가: 0,
        저가: 0,
        //만기일: '',
        //환율: '',
        //틱가치: '',
        //수수료: '',
        //잔존일: 0,
        거래량: 0,
    } as any,
    // 체결
    quoteData: [] as any,
    // 합계: 건수 매도 가격 매수 건수
    totalData: [] as any,

    refreshingTime: null as any,

    // 현재시간
    clock: '',
    timerClock: null as any,

    // 현재 호가/체결 탭
    tabIdx: Quote_Call,
    
    // 미체결 리스트 팝업
    isShowPopupLeftOrders: false,
    // 상단 돋보기 버튼
    isShowPopupStockSearch:false,
    // 차트 팝업
    initIsShowPopupChart:false,
    popupChartType:'left',
    // 공지 팝업
    initShowNoticePopup:false,
    //레버리지 팝업
    isShowLeverageSetting:false,

    // 종목 선택 세팅 타이머
    timerInitFuturesPicker: null as any,
    
    // 계좌 정보 세팅
    timerInitData: null as any,

    //totalOrder height
    bottomHeight:0,
    tableHeight:0,
    popupHeight:0,
    resizeTimer: null as any,
});

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TotalOrder');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_UserOption, event_UserOption);
    NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);

    NetworkService.emitter.on(Emit_ContractData, event_ContractData);
    NetworkService.emitter.on(Emit_CallData, event_CallData);
    // NetworkService.emitter.on(Emit_UserOption, event_UserOption);
    resizeHandler();
    window.addEventListener('resize', resizeEnded);
    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_UserOption, event_UserOption);
    NetworkService.emitter.off(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.off(Emit_ContractData, event_ContractData);
    NetworkService.emitter.off(Emit_CallData, event_CallData);
    // NetworkService.emitter.off(Emit_UserOption, event_UserOption);

    window.removeEventListener('resize', resizeEnded);
    if(state.timerClock){
        clearTimeout(state.timerClock);
    }
    if(state.timerInitFuturesPicker){
        clearTimeout(state.timerInitFuturesPicker);
    }
    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
})

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 업데이트
    refreshOrderCount();
    if (state.tradingIdx === Trade_Stoploss) {
        refreshStoploss();
    }
}

const event_UserOption = (params: any) => {
    // 유저 설정
    // 체결 / 미체결 업데이트
    refreshOrderCount();

    //아래 Quote 부분 
    // const futures = state.futures;
    // if (futures === Common.G_FUTURES_MAX) {
    //   return;
    // }

    // const commissionRate = store.GetFutureCommissionRate(futures);
    // let szCommission = `${commissionRate.toFixed(4)}%`;
    // if (futures >= Common.G_FUTURES_OVERSEAS) {
    //     szCommission = `${futures === Common.G_FUTURES_HANGSENG ? 'HKD' : 'USD'} ${commissionRate.toFixed(2)}`;
    // }
    // 수수료 표시 안함
    //this.infoData.수수료 = szCommission;
}

const event_ResetAmount = (params: any) => {
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    // 체결 / 미체결 업데이트
    refreshOrderCount();
}

const event_ContractData = (params: any) => {
    // ContractObj
    const { contractData } = params;

    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = contractData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    const numOfQuoteData = QUOTE_DATA_MAX;
    // const onContractData = state.onContractData;

    const item = {
        iFutures: contractData.iFutures,
        szCTime: contractData.szCTime,
        iTrdq: contractData.iTrdq,
        dbCurPrice: contractData.dbCurPrice,
        dbBPrice: contractData.dbBPrice,
        szCGubun: contractData.szCGubun,
    };
    state.quoteData.push(item);
    if (state.quoteData.length > numOfQuoteData) {
        state.quoteData.shift();
    }

    updateInfoData(futures, contractData);
    onContractData(contractData);
}

const event_CallData = async (params: any) => {
    // CallObj
    const { callData } = params;
    const futures = callData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    // 포커스 중일 때만 그린다.
    // const { isFocused } = this.props;
    // if (!isFocused) {
    //     return;
    // }

    const now = Date.now();
    if (state.refreshingTime === null || (now - state.refreshingTime) > REFRESH_INTERVAL) {
        // 마스터에 이미 업데이트 되었다.
        onRefresh();

        state.refreshingTime = now;
    }
}

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_UserOption, event_UserOption);
        NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
        NetworkService.emitter.on(Emit_ContractData, event_ContractData);
        NetworkService.emitter.on(Emit_CallData, event_CallData);
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    state.futures = futures;
    // state.quoteFutures = futures;   // Quotes 세팅
    
    initFuturesPicker();
    // initAccPicker();
    // initPricePicker();
    initClock();

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
}

// 종목 선택 세팅
const initFuturesPicker = async () => {
    if (!store.MasterState.isReadyRECV) {
        state.timerInitFuturesPicker = setTimeout(() => {initFuturesPicker()}, 200);
        return;
    }

    let pickItems = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        if (store.MarketState.bDrawMenu[futures]) {
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            const expiryDay = _expiryDate.substring(2, 10); // YY-MM-DD
            const futuresName = Common.g_szFuturesNameMini[futures];
            const code = store.MarketState.szFutureCode[futures];
            pickItems.push({
                label: futuresName,
                value: code,
                futures,
                expiryDate: expiryDay,
                desc: `${code} (${expiryDay})`,
                selected: false
            });
        }
    }
    let futIdx = pickItems.findIndex(p => p.futures === state.futures);
    if (futIdx < 0) {
        futIdx = 0;
    }
    const pickValue = pickItems[futIdx]?.value;
    state.pickFuturesItems = pickItems;
    state.pickFuturesValue = pickValue;
    state.pickFuturesDesc = pickItems[futIdx]?.label + '<br/>' +  pickItems[futIdx]?.desc;
    state.pickFuturesSelectedItem = pickItems[futIdx];

    onPickFuturesChangeValue(pickItems[futIdx]);
}

const onPickFuturesChangeValue = (param: any) => {
    state.pickFuturesOpen = false;

    state.pickFuturesItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            // state.selectedItem = item;
            state.pickFuturesValue = item.value;
            state.pickFuturesDesc = item.desc;
            state.pickFuturesDesc = item.label + '<span>' + item.desc+ '</span>' ;
            // state.pickFuturesDesc = item.label + '<br/>' + item.desc;
            state.pickFuturesItems[index].selected = true;
            state.futures = item.futures;
            state.pickFuturesSelectedItem = item;
            onFuturesChange(item.futures);

        } else {
            state.pickFuturesItems[index].selected = false;
        }
    });

    initAccPicker();
}

// 계좌 선택 세팅
const initAccPicker = () => {
    const pickItems = [];
    // const futures = store.FuturesByNow();
    const futures = state.futures;
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {            
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

// // 가격 종류 세팅
// const initPricePicker = () => {
//     let pickPriceItems = [];
//     // 시장가
//     pickPriceItems.push({
//         label: Common.g_szPriceTypeName[Common.G_PRICE_MARKET],
//         value: Common.G_PRICE_MARKET,
//     });
//     // 지정가
//     pickPriceItems.push({
//         label: Common.g_szPriceTypeName[Common.G_PRICE_APPOINT],
//         value: Common.G_PRICE_APPOINT,
//     });
//     const pickPriceValue = pickPriceItems[Common.G_PRICE_APPOINT].value;
//     state.pickPriceItems = pickPriceItems;
//     state.pickPriceDesc = pickPriceItems[Common.G_PRICE_APPOINT].label;
//     state.pickPriceValue = pickPriceValue;
// }

// const onPickPriceChangeValue = (param: any) => {
//     state.pickPriceItems.forEach((item: any, index: number) => {
//         if (item.value === param.value) {
//             state.pickPriceValue = item.value;
//             state.pickPriceDesc = item.label;
//             if (state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell) {
//                 state.priceType = item.value;
//             }
//             if(state.pickPriceValue === Common.G_PRICE_MARKET){
//                 state.price = '시장가';
//             }
//         }
//     });

//     //지정가를 선택한 경우 기타 입력 및 선택 부분들 초기화
//     if(param.value === Common.G_PRICE_APPOINT){
//         onPressInit();
//     }
// }

// 현재 시간
const initClock = () => {
    const tick_on = async () => {
        state.clock = moment(new Date()).format('HH:mm:ss');
        
        state.timerClock = setTimeout(tick_on, 1000);
    }
  
    tick_on();
}

// 체결 정보창
const initInfoData = () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = state.futures;
    if (futures === Common.G_FUTURES_MAX) {
        return;
    }

    // 2자리 년도 제거
    // const expiryDate = store.MarketState.szFutureExpireDate[futures]?.substring(2, 10);
    // const expiryLeftDay = store.MarketState.iFutureExpireLeftDay[futures];
    // const exchRate = futures === Common.G_FUTURES_HANGSENG ? store.BrandState.ExchangeRateHKD : store.BrandState.ExchangeRate;
    // const commissionRate = store.GetFutureCommissionRate(futures);
    // let szCommission = `${commissionRate.toFixed(4)}%`;
    // if (futures >= Common.G_FUTURES_OVERSEAS) {
    //     szCommission = `${futures === Common.G_FUTURES_HANGSENG ? 'HKD' : 'USD'} ${commissionRate.toFixed(2)}`;
    // }

    // const 현재가 = store.MasterState.dbCurPrice[futures];
    const 전일가 = store.MasterState.dbBPrice[futures];
    const 시가 = store.MasterState.dbOpenPrice[futures];
    const 고가 = store.MasterState.dbHighPrice[futures];
    const 저가 = store.MasterState.dbLowPrice[futures];
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = store.MasterState.szYDiffSign[futures];
    // const 대비 = store.MasterState.dbYDiffPrice[futures];
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = store.MasterState.totq[futures];

    // let infoData = {} as any;
    //infoData.종목 = { value: futures, color: null };
    //infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //infoData.대비율 = { value: `${store.MasterState.dbChgRate[futures]}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    //infoData.만기일 = { value: expiryDate, color: Theme.mts.text };
    //infoData.환율 = { value: `₩${toLocaleString(exchRate.toFixed(2))}`, color: Theme.mts.text };
    //infoData.틱가치 = { value: `${toLocaleString(BrandStore.G_TICK_PRICE[futures])}원`, color: Theme.mts.text };
    //infoData.수수료 = { value: szCommission, color: Theme.mts.text };
    //infoData.잔존일 = { value: `${expiryLeftDay}일`, color: Theme.mts.text };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };

    // state.infoData = infoData;
}

// 체결 정보창 갱신
const updateInfoData = (futures: any, contractData: any) => {
    // const 현재가 = contractData.dbCurPrice;
    const 전일가 = contractData.dbBPrice;
    const 시가 = contractData.dbOpenPrice;
    const 고가 = contractData.dbHighPrice;
    const 저가 = contractData.dbLowPrice;
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = contractData.szYDiffSign;
    // const 대비 = contractData.dbYDiffPrice;
    // const 대비율 = contractData.dbChgRate;
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = contractData.totq;

    //this.infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //this.infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //this.infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //this.infoData.대비율 = { value: `${대비율}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };
}

const onRefresh = async() => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    // 종목 찾기
    const futures = state.futures;
    if (futures >= Common.G_FUTURES_MAX) {
        return;
    }

    try {
        // 매도 1호가
        // const open = store.MasterState.dbOpenPrice[futures];  // 시작가
        const base = store.MasterState.dbBPrice[futures]; // 기준가
        const high = store.MasterState.dbHighPrice[futures]; // 고가
        const low = store.MasterState.dbLowPrice[futures]; // 저가
        const current = store.MasterState.dbCurPrice[futures]; // 현재가
        const offerhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.offerho5[futures], store.MasterState.offerrem5[futures], store.MasterState.offerno5[futures], ],
            [ store.MasterState.offerho4[futures], store.MasterState.offerrem4[futures], store.MasterState.offerno4[futures], ],
            [ store.MasterState.offerho3[futures], store.MasterState.offerrem3[futures], store.MasterState.offerno3[futures], ],
            [ store.MasterState.offerho2[futures], store.MasterState.offerrem2[futures], store.MasterState.offerno2[futures], ],
            [ store.MasterState.offerho1[futures], store.MasterState.offerrem1[futures], store.MasterState.offerno1[futures], ],
        ];
        const bidhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.bidho1[futures], store.MasterState.bidrem1[futures], store.MasterState.bidno1[futures], ],
            [ store.MasterState.bidho2[futures], store.MasterState.bidrem2[futures], store.MasterState.bidno2[futures], ],
            [ store.MasterState.bidho3[futures], store.MasterState.bidrem3[futures], store.MasterState.bidno3[futures], ],
            [ store.MasterState.bidho4[futures], store.MasterState.bidrem4[futures], store.MasterState.bidno4[futures], ],
            [ store.MasterState.bidho5[futures], store.MasterState.bidrem5[futures], store.MasterState.bidno5[futures], ],
        ];
        // 매도
        let sell1Data = [], sell2Data = [], sell3Data = [];
        // 고가
        sell1Data.push({
            title: '고가',
            textColor: { color: Theme.mts.lightSlate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        sell2Data.push({
            title: Utils.PriceFormatStr(high, futures),
            textColor: high > base ? { color: Theme.mts.up } : high < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        sell3Data.push({ title: '' });
        // 5호가
        for (let idx = 0; idx < SELL_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const offerho = offerhoData[idx];
            const price = offerho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_SELL_COLOR;
            // 잔량
            data2.title = `${offerho[1]}`;
            data2.bgColor = GRID_SELL_COLOR;
            // 건수
            data3.title = `${offerho[2]}`;
            data3.bgColor = GRID_SELL_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            sell1Data.push(data1);
            sell2Data.push(data2);
            sell3Data.push(data3);
        }

        // 매수
        let buy1Data = [], buy2Data = [], buy3Data = [];
        // 5호가
        for (let idx = 0; idx < BUY_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const bidho = bidhoData[idx];
            const price = bidho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_BUY_COLOR;
            // 잔량
            data2.title = `${bidho[1]}`;
            data2.bgColor = GRID_BUY_COLOR;
            // 건수
            data3.title = `${bidho[2]}`;
            data3.bgColor = GRID_BUY_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            buy1Data.push(data1);
            buy2Data.push(data2);
            buy3Data.push(data3);
        }
        // 저가
        buy1Data.push({
            title: '저가',
            textColor: { color: Theme.mts.lightSlate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        buy2Data.push({
            title: Utils.PriceFormatStr(low, futures),
            textColor: low > base ? { color: Theme.mts.up } : low < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        buy3Data.push({ title: '' });

        // 합계: 건수 매도 가격 매수 건수
        let totalSell = [], totalBuy = [];
        const totoffercnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerno1[futures] + store.MasterState.offerno2[futures] + store.MasterState.offerno3[futures] + store.MasterState.offerno4[futures] + store.MasterState.offerno5[futures] : store.MasterState.totoffercnt[futures];
        const totofferrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerrem1[futures] + store.MasterState.offerrem2[futures] + store.MasterState.offerrem3[futures] + store.MasterState.offerrem4[futures] + store.MasterState.offerrem5[futures] : store.MasterState.totofferrem[futures];
        totalSell.push('');   // 가격
        totalSell.push(totofferrem); // 잔량
        totalSell.push(totoffercnt); // 건수
        const totbidcnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidno1[futures] + store.MasterState.bidno2[futures] + store.MasterState.bidno3[futures] + store.MasterState.bidno4[futures] + store.MasterState.bidno5[futures] : store.MasterState.totbidcnt[futures];
        const totbidrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidrem1[futures] + store.MasterState.bidrem2[futures] + store.MasterState.bidrem3[futures] + store.MasterState.bidrem4[futures] + store.MasterState.bidrem5[futures] : store.MasterState.totbidrem[futures];
        totalBuy.push('');   // 가격
        totalBuy.push(totbidrem); // 잔량
        totalBuy.push(totbidcnt); // 건수

        // 화면 갱신 속도 조절
        state.sellData = [ sell1Data, sell2Data, sell3Data ];
        state.buyData = [ buy1Data, buy2Data, buy3Data ];
        state.totalData = [ totalSell, totalBuy ];
    } catch (e) {
        if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.error(`데이터 리플래시 중 예외발생: ${JSON.stringify(e)}`);
    }
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const onChangePriceType = (e:any) => {
    state.selectedPriceType = e.target.value;
}

const onChangeAmount = (value = 0 as number) =>{
    // const totalAmount = Number(state.amount) + value;

    // if (totalAmount > state.orderLeft) {
    //     state.amount = state.orderLeft.toString();
    // } else if(totalAmount < 0){
    //     state.amount = '0';
    // } else {
    //     state.amount = totalAmount.toString();
    // }

    const amount = state.amount.replace(/[^0-9]/g, "");
    state.amountNumber = Number(amount);
    const totalAmount = state.amountNumber + value;

    if(totalAmount < 0 ){
        state.amountNumber = 0;
    } else if (totalAmount > state.orderLeft) {
        state.amountNumber = state.orderLeft;
    } else {
        state.amountNumber = totalAmount;
    }

    state.amount = state.amountNumber.toString();
}

const onChangePrice = (tick: number) => {
    const digit = Common.g_bFuturesUnitOfDigit[state.futures];
    const calcTick = state.oneTick * tick;
    const totalPrice = Number(state.price) + calcTick;
    state.price = totalPrice.toFixed(digit);
}

const onProfitAmount = (value: number) =>{
    const totalProfit = Number(state.profitTicks) + value;

    if(totalProfit < 1){
        state.profitTicks = '1';
    } else {
        state.profitTicks = totalProfit.toString();
    }
}

const onLossAmount = (value: number) =>{
    const totalLoss = Number(state.lossTicks) + value;

    if(totalLoss < 1){
        state.lossTicks = '1';
    } else {
        state.lossTicks = totalLoss.toString();
    }
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        
        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const onTabPress = (tradingIdx: any) => {
    const options = store.SettingsState.settingsEx.options;

    if (state.tradingIdx !== tradingIdx) {
        state.tradingIdx = tradingIdx;

        const futures = state.futures;
        onFuturesChange(futures);

        if (tradingIdx === Trade_Buy || tradingIdx === Trade_Sell) {
            // 매수/매도 가격유형 복원
            const pickValue = state.selectedPriceType;
            state.pickAccValue = pickValue.toString();
        } else {
            // S/L, 정정 가격유형 지정가 고정
            const pickValue = Common.G_PRICE_APPOINT;
            state.pickAccValue = pickValue.toString();
        }

        // S/L인 경우 체결된 주문을 넣는다.
        if (tradingIdx === Trade_Stoploss) {
            // 오늘하루 안보기 설정
            const today = moment(Date.now()).format('YYYY-MM-DD');
            if(options.stoplossToday !== today){
                state.initShowNoticePopup = true;
                document.body.style.overflow = "hidden";
            }

            refreshStoploss();
        }
    }
}

const onQuoteTabPress = (tabIdx: any) => {
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;
        // if (tabIdx === Quote_Contract) {
        //     this.scrollToTop();
        // }
        
    }
}

const onPressNotToday = () => {
    // 오늘하루 안보기 설정
    const today = moment(Date.now()).format('YYYY-MM-DD');
    store.updateSettingsEx({ options: { stoplossToday: today } });

    close('PopupNotice');
}

const refreshStoploss = () => {
    const futures = state.futures;

    // const tradeType = store.AccountState.iOrderPos[futures]===Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    // 총 잔량(반대매매)의 수량과 총 가격을 얻는다.
    const oppositeOrders = store.GetClientOppositeList(futures);
    const oppOrder = oppositeOrders?.length > 0 ? oppositeOrders[0] : null;
    let oppAverage = store.MasterState.dbCurPrice[futures];
    if (oppOrder) {
        const oppAmount = oppOrder.iOppositeAmount;
        const profitTicks = oppOrder.iProfitTicks;
        const lossTicks = oppOrder.iLossTicks;
        oppAverage = oppOrder.dbContractPrice;

        state.amount = `${oppAmount}`;
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = `${profitTicks === 0 ? '' : profitTicks}`;
        state.lossTicks = `${lossTicks === 0 ? '' : lossTicks}`;
    } else {
        state.amount = '';
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = '';
        state.lossTicks = '';
    }
    state.oppOrder = oppOrder;
}

const onFuturesChange = (futures: any) => {

    store.setLastFuturesSelected(futures);
    store.AccountState.G_Future_Code = futures;

    state.futures = futures;
    state.oneTick = Common.G_ONETICK[futures];
    state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;

    // 마지막 체결가
    state.전일가 = store.MasterState.dbBPrice[futures];
    state.현재가 = store.MasterState.dbCurPrice[futures];
    state.전일대비기호 = store.MasterState.szYDiffSign[futures];
    state.전일대비 = store.MasterState.dbYDiffPrice[futures];
    state.등락률 = store.MasterState.dbChgRate[futures];
    state.priceColor = state.현재가 > state.전일가 ? Theme.mts.up : state.현재가 < state.전일가 ? Theme.mts.down : Theme.mts.normal;
    state.diffColor = state.전일대비기호 === '1' || state.전일대비기호 === '2' ? Theme.mts.up : state.전일대비기호 === '4' || state.전일대비기호 === '5' ? Theme.mts.down : Theme.mts.normal;

    store.AccountState.G_Future_Code = futures;

    state.quoteData = [];
    // 최종 체결가 넣기
    if (store.MasterState.isReadyRECV) {
        const quote = {
            iFutures: futures,
            szCTime: store.MasterState.szCTime[futures],
            iTrdq: store.MasterState.iTrdq[futures],
            dbCurPrice: store.MasterState.dbCurPrice[futures],
            dbBPrice: store.MasterState.dbBPrice[futures],
            szCGubun: store.MasterState.szCGubun[futures],
        };
        state.quoteData.push(quote);
    }

    initValue(futures);
    initInfoData();
    refreshOrderCount();
    onRefresh();

    // const { onFuturesChange } = this.props;
    // onFuturesChange && onFuturesChange(futures);
    // onFuturesChange(futures);

    // this.scrollToCenter(this.quoteLayoutHeight, this.quoteContentHeight);
}

const initValue = (futures: any) => {
    state.amount = '';
    const curPrice = store.MasterState.dbCurPrice[futures];
    state.price = Utils.PriceFormatStr(curPrice, futures);

    state.orgOrderNo = '';

    state.profitTicks = '';
    state.lossTicks = '';
}

const onPressInit = () => {
    const futures = state.futures;
    const tradingIdx  = state.tradingIdx;

    // S/L인 경우 체결된 주문을 넣는다.
    if (tradingIdx === Trade_Stoploss) {
      refreshStoploss();
    } else {
      initValue(futures);
    }
}

const onPricePress = (price: string) => {

    if(price === '고가' || price === '저가'){return;}
    
    if (state.tradingIdx === Trade_Stoploss) {
        // S/L인 경우 손/익 틱을 변동시킨다.
    } else {
        state.price = price;
    }
}

const onContractData = (contractData: any) => {
    // 마스터 수신여부, 종목 등, 이미 걸러진 상태로 들어온다.
    // store.MasterState.isReadyRECV, this.futures==contractData.iFutures

    // ContractObj
    const { dbCurPrice, dbBPrice, szYDiffSign, dbYDiffPrice, dbChgRate } = contractData;

    // 마지막 체결가
    state.전일가 = dbBPrice;
    state.현재가 = dbCurPrice;
    state.전일대비기호 = szYDiffSign;
    state.전일대비 = dbYDiffPrice;
    state.등락률 = dbChgRate;
}

const refreshOrderCount = (eventType = '' as string) => {
    /*
    // 계정 비번 확인
    if (!this.checkAccPwd()) {
        return;
    }
    */

    const futures = state.futures;
    const tradingIdx = state.tradingIdx;

    store.AccountState.G_Future_Code = state.futures;
    store.DrawPossibleOrder();

    const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    const oppTradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
    const orderLeft = store.AccountState.iOrderMax[futures][tradeType];
    state.orderLeft = orderLeft;
    // 체결의 청산가능
    const settleAmount = store.GetClientTotalOppositeAmount(futures, oppTradeType);
    state.settleLeft = settleAmount;

    // 전체 체결/미체결
    const oppAmount = store.GetClientTotalOppositeAmount(futures);
    state.leftAmount = store.GetClientLeftAmountCounts(futures);
    state.oppositeAmount = oppAmount;

    if(eventType !== ''){
        if(eventType === 'orderLeft'){
            //신규(주문)
            state.amount = state.orderLeft.toString();
        } else if(eventType === 'settleLeft'){
            //청산
            state.amount = state.settleLeft.toString();
        }
    }
}

const onActionPress = (bCancel = false) => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const price = state.price;
    const amount = state.amount;
    const orderLeft = state.orderLeft;
    const tradingIdx = state.tradingIdx;

    // const priceType = state.pickPriceValue;
    const orderPriceType = state.selectedPriceType;

    /*
    if (tradingIdx === Trade_Stoploss) {
        const szMsg = '곧 지원할 예정입니다.';
        SystemStore.setVisibleDialog({ message: szMsg, title: 'S/L 주문 실패' });
        return;
    }
    */

    const futures = store.GetFuturesNExchangeable(state.futures, true);
    if (futures >= Common.G_FUTURES_MAX) {
        // 원래 종목 값이 들어간다.
        store.TimeMsg(state.futures);
        return;
    }

    const info = TradingInfo[tradingIdx];

    // 주문수량 확인
    const orderAmount = parseInt(amount);

    const orderPrice = parseFloat(price);
    if (orderPriceType === Common.G_PRICE_APPOINT && (orderPrice <= 0 || isNaN(orderPrice))) {
        const szMsg = '가격을 확인해 주세요.';
        store.setVisibleDialog({ message: szMsg, title: info.name });
        return;
    }

    const orderMethod = Common.C_ORDERMETHOD_MobileOverall;

    if (tradingIdx === Trade_Correct) {
        const tradeType = state.orgTradeType;
        
        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 주문 정정 (가격 정정)
        const orgOrderNo = parseInt(state.orgOrderNo);
        if (isNaN(orgOrderNo) || !orgOrderNo) {
            const szMsg = '원주문번호를 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 정정인경우 가격이 같으면 정정이 안된다.
        if (!bCancel) {
            if ((state.price === state.orgPrice)) {
                const szMsg = '정정 주문가격이 원 주문가격과 같습니다.';
                store.setVisibleDialog({ message: szMsg, title: info.name });
                return;
            }
        }

        const callback = () => {
            const modifyMethod = bCancel ? Common.C_ORDERMODIFY_Cancel : Common.C_ORDERMODIFY_ModifyPrice;

            UserLog.modifyOrder(futures, tradeType, orderPrice, orderAmount, modifyMethod, orderMethod, orgOrderNo);
            NetworkService.send.modifyOrderReq({
                futures: futures, 
                tradeType: tradeType,
                modifyMethod: modifyMethod, 
                orderIdx: orgOrderNo,
                orderPrice: orderPrice, 
                orderAmount: orderAmount, 
                orderMethod: orderMethod, 
            });
        }

        const szModify = bCancel ? '취소' : '정정';
        const szMsg = `주문을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `주문 ${szModify}`, okCancel: true, callback });

    } else if (tradingIdx === Trade_Stoploss) {
        // S/L 집행/취소
        const oppOrder = state.oppOrder;

        // 주문 확인
        if (!oppOrder) {
            const szMsg = '체결된 주문을 선택해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        const _profitTicks = parseInt(state.profitTicks);
        const _lossTicks = parseInt(state.lossTicks);
        const profitTicks = isNaN(_profitTicks) || _profitTicks < 0 ? 0 : _profitTicks;
        const lossTicks = isNaN(_lossTicks) || _lossTicks < 0 ? 0 : _lossTicks;

        const { iFutures, iTradeType, iOrderIdx, iOppositAmount, iStopLossLogIdx, } = oppOrder;

        // 취소가 아닌경우
        if (bCancel) {
        if (iStopLossLogIdx === Common.INDEX_NONE) {
            const szMsg = '예약된 S/L이 없습니다. S/L집행만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        } else {
        if (iStopLossLogIdx >= 0) {
            const szMsg = '예약된 S/L이 있습니다. S/L취소만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 익절/손절 확인
        if (profitTicks === 0 && lossTicks === 0) {
            const szMsg = '이익틱 또는 손절틱을 설정해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        // 익절/손절 수정 확인
        if (profitTicks === oppOrder.iProfitTicks && lossTicks === oppOrder.iLossTicks) {
            const szMsg = '이익틱, 손절틱이 같아 수정할 수 없습니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        }

        // S/L이 사용되는 매매구분, stoploss 로그용.
        // const slTradeType = (iTradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const iSLReserved = bCancel ? Common.eSLReserved_Cancel : iStopLossLogIdx === Common.INDEX_NONE ?  Common.eSLReserved_Request : Common.eSLReserved_Modify;
        const szModify = iSLReserved === Common.eSLReserved_Request ? '집행' : iSLReserved === Common.eSLReserved_Modify ? '수정' : '취소';

        const callback = () => {
            const useStop = profitTicks > 0;
            const useLoss = lossTicks > 0;
            const szExMessage = `S/L ${szModify}`;
            UserLog.stopLoss(iFutures, orderPrice, useStop, profitTicks, useLoss, lossTicks, szExMessage, iStopLossLogIdx, bCancel ? 1 : 0 );
            NetworkService.send.stopLossReservedReq({
                iSLReserved, 
                iOrderIdx,
                iFutures, 
                iTradeType,
                iOppositAmount, 
                iProfitTicks: profitTicks, 
                iLossTicks: lossTicks, 
            });
        }

        const szMsg = `S/L을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `S/L ${szModify}`, okCancel: true, callback });

    } else {
        // 매수/매도
        const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;

        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '주문 수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        if (orderAmount > orderLeft) {
            const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        /*
        const curPrice = store.MasterState.dbCurPrice[futures];
        // 지정가인데 시장가로 넣어야되는 경우 변경
        if (orderPriceType === Common.G_PRICE_APPOINT) {
        // 바로 체결될 지정가는 시장가로 넣는다.
        if ((tradeType === Common.G_TRADE_SELL && orderPrice <= curPrice) || (tradeType === Common.G_TRADE_BUY && orderPrice >= curPrice)) {
            orderPriceType = Common.G_PRICE_MARKET;
            if (store.debugLog >= Constant.DebugLevel_Minimal)
                console.log(`지정가를 시장가로 넣는다. ${Common.g_szFuturesNameMini[futures]}, ${Common.g_szTradeTypeName[tradeType]}, 주문가격:${orderPrice}, 현재가:${curPrice}`);
        }
        }
        */

        // 매도/매수 주문
        UserLog.order(futures, tradeType, orderPriceType, orderPrice, orderAmount, orderMethod);
        NetworkService.send.registOrderReq({
            futures: futures, 
            tradeType: tradeType, 
            priceType: orderPriceType, 
            orderPrice: orderPrice, 
            orderAmount: orderAmount, 
            orderMethod: orderMethod, 
        });
    }
}

const onActionMarketTrade = (param: any) => {

    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }
    
    const symbol = param.symbol;
    const paramTradeType = param.tradeType;
    const tradeType = paramTradeType === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    const orderAmount = param.orderAmount;
    
    const futures = state.pickFuturesItems.find((p: any) => p.label === symbol).futures;
    const info = TradingInfo[paramTradeType];
    const priceType = Common.G_PRICE_MARKET;
    const orderMethod = Common.C_ORDERMETHOD_MobileOverall;
    
    if(futures){
        const orderLeft = store.AccountState.iOrderMax[futures][tradeType]; //그래프로 선택된 종목의 구매가능 수량

        if (orderAmount > orderLeft) {
            const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 매도/매수 주문
        UserLog.order(futures, tradeType, priceType, 0, 1, orderMethod);
        NetworkService.send.registOrderReq({
            futures: futures, 
            tradeType: tradeType, 
            priceType: priceType, 
            orderPrice: 0, 
            orderAmount: orderAmount, 
            orderMethod: orderMethod, 
        });
    }

    // 챠트 팝업을 닫을 경우
    // state.initIsShowPopupChart = false;
}

// 미체결 버튼
const onLeftPress = () => {
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;

    if (!leftOrders || leftOrders.length === 0) {
        const szMsg = '미체결 주문이 없습니다.';
        store.setVisibleDialog({ message: szMsg, title: '알림' });
        return;
    }

    state.leftOrders = leftOrders;
    // state.leftSelected = -1;
    // state.visibleDialog = true;
    state.isShowPopupLeftOrders = true;
}

// 미체결 선택(주문 정정)
const onCorrectSelectedPress = (params: any) => {
    const leftOrders = state.leftOrders ;
    const leftSelected = params.selectedIdx;
    const selectedLeftOrder = params.selectedOrder;

    if (leftSelected >= 0 && leftSelected < leftOrders.length) {
        state.amount = `${selectedLeftOrder.iLeftAmount}`;
        const price = Utils.PriceFormatStr(selectedLeftOrder.dbOrderPrice, selectedLeftOrder.iFutures);
        state.price = price;
        state.orgPrice = price;
        state.orgTradeType = selectedLeftOrder.iTradeType;
        state.orgOrderNo = `${selectedLeftOrder.iOrderIdx}`;
    }

    state.isShowPopupLeftOrders = false;
}

const onCancelCorrect = () => {
    /*
    const leftSelected = -1;
    this.setState({ leftSelected });
    this.onFuturesChange(this.futures);
    */
    const bCancel = true;
    onActionPress(bCancel);
}

const stockSearch = () => {
    state.isShowPopupStockSearch = true;
    document.body.style.overflow = "hidden";
}

const getQuoteDataColor = (type: string, quote: any) => {
    if(type === 'count'){
        let color = Theme.mts.slate;

        if (quote.szCGubun === '+') {
            color = Theme.mts.up;
        } else if (quote.szCGubun === '-') {
            color = Theme.mts.down;
        }
        return color;
    } else if(type === 'price'){
        let priceColor = Theme.mts.slate;
        if (quote.dbCurPrice > quote.dbBPrice) {
            priceColor = Theme.mts.up;
        } else {
            priceColor = Theme.mts.down;
        }
        return priceColor;
    }
}

const viewChart = () => {
    state.initIsShowPopupChart = true;
    document.body.style.overflow = "hidden";
}

const close = (popupType: string) => {
    switch (popupType) {
        case "PopupStockSearch":
            document.body.style.overflow = "auto";
            state.isShowPopupStockSearch = false;
            break;
        case "PopupChart":
            state.initIsShowPopupChart = false;
            document.body.style.overflow = "auto";
            break;
        case "PopupNotice":
            state.initShowNoticePopup = false;
            document.body.style.overflow = "auto";
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            document.body.style.overflow = "auto";
            break;
    }
}

const resizeEnded = () => {
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
    state.resizeTimer = setTimeout(()=>{
        resizeHandler()
    }, 500);
}

const resizeHandler = () => {
    var currWinWidth = window.innerWidth;       //resize 후 브라우저 가로 길이
    var currWinHeight = window.innerHeight;     //resize 후 브라우저 세로 길이
    if(currWinWidth > 700){
        state.popupHeight = currWinHeight - 168;
        state.bottomHeight = currWinHeight - 449;
        state.tableHeight = state.bottomHeight - 110;
    } else if(currWinWidth > 600) {
        state.popupHeight = currWinHeight - 168;
        state.bottomHeight = currWinHeight - 399;
        state.tableHeight = state.bottomHeight - 113;
    } else if(currWinWidth > 500) {
        state.popupHeight = currWinHeight - 128;
        state.bottomHeight = currWinHeight - 345;
        state.tableHeight = state.bottomHeight - 77;
    } else if(currWinWidth > 375) {
        state.popupHeight = currWinHeight - 140;
        state.bottomHeight = currWinHeight - 302;
        state.tableHeight = state.bottomHeight - 55;
    } else if(currWinWidth > 360) {
        state.popupHeight = currWinHeight - 118;
        state.bottomHeight = currWinHeight - 290;
        state.tableHeight = state.bottomHeight- 45;
    } else {
        state.popupHeight = currWinHeight - 118;
        state.bottomHeight = currWinHeight - 290;
        state.tableHeight = state.bottomHeight- 45;
    }
}

const openLeverage = () => {
    state.isShowLeverageSetting = true;
    document.body.style.overflow = "hidden";
}
</script>
