import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../assets/images/pc/logo-pc.png'
import _imports_1 from '../../assets/images/pc/profile.png'
import _imports_2 from '../../assets/images/pc/setting-pc.png'


const _hoisted_1 = { class: "headerPC" }
const _hoisted_2 = { class: "inner-area" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "menu-area" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "user-info-box" }
const _hoisted_7 = { class: "nickname" }

import useBaseComponent from "@/baseComponent";
import { NetworkService, Emit_Success } from '../../network';
import * as AccPwd from '../../constants/AccPwd';
// import * as Common from '../constants/Common';
// import * as Utils from '../utils/Common';
// import { toLocaleString } from '../utils/number';
// import { XingAPIService } from '../network/XingAPIService';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcHeader',
  setup(__props) {

const { router, store, onMounted, onUnmounted, reactive,route } = useBaseComponent();

let state = reactive({
	// // popup
	// isShowHelpDesk:false,
	// isShowNotice:false,
	// isShowSetting:false,
	// isShowLeverage:false,
	initShowHelpDesk:false,
	initHelpDeskTitle:'상담센터',
});

onMounted(()=>{
	NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
	// 계좌 비밀번호 체크 생략
	// if (store.LoginPageState.isPacketLoginAckReceived) checkAccPwdApply();
})
onUnmounted(()=> {
	NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
})

const event_LoginSuccess = (params: any) => {
	// 계좌 비밀번호 체크 생략
    // if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
    //     checkAccPwdApply();
    // }
};

const checkAccPwdApply = () => {
	if(route.name !== 'Login'){
		const configState = Number(store.SettingsState.settings.accPwdApply);
		if(configState === AccPwd.cAccPwdApply_OncePopup || (configState === AccPwd.cAccPwdApply_Forever && store.getDomesticAccPwd() === '')) {
			store.getSettingAccountPopupState().then(result => {
				if(result === 1) {
					store.CommonState.isShowSetting = true;
				}
			});
		}
	}
}

const movePage = (page:string) => {
	router.push(page)
}

const showPopup = (name: string) => {
	document.body.style.overflow = "hidden";
	switch (name) {
        // case "HelpDesk":
		// store.CommonState.isShowHelpDesk = true;
        //     break;
        case "Notice":
			store.CommonState.isShowNotice = true;
            break;
        case "Setting":
			store.CommonState.isShowSetting = true;
            break;
        case "Leverage":
			store.CommonState.isShowLeverage = true;
            break;
    }
}

const clickHelpDesk = (page: string) => {
	if (!store.LoginPageState.isPacketLoginAckReceived) return;
	
	if(store.BrandState.bExternalChat === 0) {
		router.push(page)
	} else {
		document.body.style.overflow = "auto";
		state.initShowHelpDesk = true; 
	}
}

const popupClose = (name: string) => {
	document.body.style.overflow = "auto";
    switch (name) {
        // case "HelpDesk":
		// 	store.CommonState.isShowHelpDesk = false;
        //     break;
        case "Notice":
			store.CommonState.isShowNotice = false;
            break;
        case "Setting":
			store.CommonState.isShowSetting = false;
            break;
        case "Leverage":
			store.CommonState.isShowLeverage = false;
            break;
        case "PopupHelpDesk":
			state.initShowHelpDesk =false;
            break;
	};
}

return (_ctx: any,_cache: any) => {
  const _component_PcNotice = _resolveComponent("PcNotice")!
  const _component_PcSetting = _resolveComponent("PcSetting")!
  const _component_PcLeveragePopup = _resolveComponent("PcLeveragePopup")!
  const _component_PcPopupHelpDesk = _resolveComponent("PcPopupHelpDesk")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "logo",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (movePage('/TotalOrder')))
          }, _cache[13] || (_cache[13] = [
            _createElementVNode("img", {
              class: "logo-pc",
              src: _imports_0,
              alt: ""
            }, null, -1)
          ])),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["menu-item", {'on':_unref(route).name === 'PcIndex'}]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (movePage('/TotalOrder')))
            }, "통합주문", 2),
            _createElementVNode("div", {
              class: _normalizeClass(["menu-item", {'on':_unref(route).name === 'PcTransactionForm'}]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (movePage('/TransactionForm')))
            }, "입/출금", 2),
            _createElementVNode("div", {
              class: _normalizeClass(["menu-item", {'on':_unref(route).name === 'PcTradeList'}]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (movePage('/TradeList')))
            }, "거래내역", 2),
            (_unref(store).SystemState._appMode === 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["menu-item", {'on':_unref(route).name === 'PcOverNight'}]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (movePage('/OverNight')))
                }, "오버나잇", 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["menu-item", {'on':_unref(route).name === 'PcHelpDesk'}]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (clickHelpDesk('/HelpDesk')))
            }, "상담센터", 2),
            (_unref(store).SystemState._appMode === 2)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "menu-item",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (showPopup('Leverage')))
                }, "레버리지"))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "menu-item",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (showPopup('Notice')))
            }, "공지사항")
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[14] || (_cache[14] = _createElementVNode("img", {
              class: "profile-img",
              src: _imports_1,
              alt: ""
            }, null, -1)),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(store).SettingsState.loginID), 1)
          ]),
          _createElementVNode("button", {
            class: "set-box",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (showPopup('Setting')))
          }, _cache[15] || (_cache[15] = [
            _createElementVNode("img", {
              class: "set-img",
              src: _imports_2,
              alt: ""
            }, null, -1)
          ]))
        ])
      ])
    ]),
    _createVNode(_component_PcNotice, {
      initIsShowPopup: _unref(store).CommonState.isShowNotice,
      onClose: _cache[9] || (_cache[9] = ($event: any) => (popupClose('Notice')))
    }, null, 8, ["initIsShowPopup"]),
    _createVNode(_component_PcSetting, {
      initIsShowPopup: _unref(store).CommonState.isShowSetting,
      onClose: _cache[10] || (_cache[10] = ($event: any) => (popupClose('Setting')))
    }, null, 8, ["initIsShowPopup"]),
    _createVNode(_component_PcLeveragePopup, {
      initIsShowPopup: _unref(store).CommonState.isShowLeverage,
      onClose: _cache[11] || (_cache[11] = ($event: any) => (popupClose('Leverage')))
    }, null, 8, ["initIsShowPopup"]),
    _createVNode(_component_PcPopupHelpDesk, {
      initShowNotice: _unref(state).initShowHelpDesk,
      initTitle: _unref(state).initHelpDeskTitle,
      onClose: _cache[12] || (_cache[12] = ($event: any) => (popupClose('PopupHelpDesk')))
    }, null, 8, ["initShowNotice", "initTitle"])
  ], 64))
}
}

})