import * as Common from '../constants/Common';

// 호가 데이터
export class CallObj {
  constructor(data: any) {
    if (data) {
      const {
        iFutures = Common.G_FUTURES_MAX,
        dbCurPrice = 0., //현재가
        dbOpenPrice = 0., //시가
        dbHighPrice = 0., //고가
        dbLowPrice = 0., //저가
        dbBPrice = 0., //기준가
      
        offerho5 = 0., //매도5호가
        offerho4 = 0., //매도4호가
        offerho3 = 0., //매도3호가
        offerho2 = 0., //매도2호가
        offerho1 = 0., //매도1호가
      
        bidho1 = 0., //매수1호가
        bidho2 = 0., //매수2호가
        bidho3 = 0., //매수3호가
        bidho4 = 0., //매수4호가
        bidho5 = 0., //매수5호가
      
        offerno5 = 0, //매도5 건수
        offerno4 = 0, //매도4 건수
        offerno3 = 0, //매도3 건수
        offerno2 = 0, //매도2 건수
        offerno1 = 0, //매도1 건수
      
        bidno1 = 0, //매수1건수
        bidno2 = 0, //매수2건수
        bidno3 = 0, //매수3건수
        bidno4 = 0, //매수4건수
        bidno5 = 0, //매수5건수
      
        offerrem1 = 0, //매도1 수량
        offerrem2 = 0, //매도2 수량
        offerrem3 = 0, //매도3 수량
        offerrem4 = 0, //매도4 수량
        offerrem5 = 0, //매도5 수량
      
        bidrem1 = 0, //매수1수량
        bidrem2 = 0, //매수2수량
        bidrem3 = 0, //매수3수량
        bidrem4 = 0, //매수4수량
        bidrem5 = 0, //매수5수량
      
        totoffercnt = 0, //매도호가 총 건수
        totofferrem = 0, //매도호가 총 수량
        totbidcnt = 0, //매수호가 총 건수
        totbidrem = 0, //매수호가 총 수량
      } = data;

      this.iFutures = iFutures;
      this.dbCurPrice = dbCurPrice;
      this.dbOpenPrice = dbOpenPrice;
      this.dbHighPrice = dbHighPrice;
      this.dbLowPrice = dbLowPrice;
      this.dbBPrice = dbBPrice;

      this.offerho5 = offerho5;
      this.offerho4 = offerho4;
      this.offerho3 = offerho3;
      this.offerho2 = offerho2;
      this.offerho1 = offerho1;

      this.bidho1 = bidho1;
      this.bidho2 = bidho2;
      this.bidho3 = bidho3;
      this.bidho4 = bidho4;
      this.bidho5 = bidho5;

      this.offerno5 = offerno5;
      this.offerno4 = offerno4;
      this.offerno3 = offerno3;
      this.offerno2 = offerno2;
      this.offerno1 = offerno1;

      this.bidno1 = bidno1;
      this.bidno2 = bidno2;
      this.bidno3 = bidno3;
      this.bidno4 = bidno4;
      this.bidno5 = bidno5;

      this.offerrem1 = offerrem1;
      this.offerrem2 = offerrem2;
      this.offerrem3 = offerrem3;
      this.offerrem4 = offerrem4;
      this.offerrem5 = offerrem5;

      this.bidrem1 = bidrem1;
      this.bidrem2 = bidrem2;
      this.bidrem3 = bidrem3;
      this.bidrem4 = bidrem4;
      this.bidrem5 = bidrem5;

      this.totoffercnt = totoffercnt;
      this.totofferrem = totofferrem;
      this.totbidcnt = totbidcnt;
      this.totbidrem = totbidrem;
    }
  }

	iFutures = Common.G_FUTURES_MAX;
	dbCurPrice = 0.; //현재가
	dbOpenPrice = 0.; //시가
	dbHighPrice = 0.; //고가
	dbLowPrice = 0.; //저가
	dbBPrice = 0.; //기준가

  offerho5 = 0.; //매도5호가
	offerho4 = 0.; //매도4호가
	offerho3 = 0.; //매도3호가
	offerho2 = 0.; //매도2호가
	offerho1 = 0.; //매도1호가

	bidho1 = 0.; //매수1호가
	bidho2 = 0.; //매수2호가
	bidho3 = 0.; //매수3호가
	bidho4 = 0.; //매수4호가
	bidho5 = 0.; //매수5호가

	offerno5 = 0; //매도5 건수
	offerno4 = 0; //매도4 건수
	offerno3 = 0; //매도3 건수
	offerno2 = 0; //매도2 건수
	offerno1 = 0; //매도1 건수

	bidno1 = 0; //매수1건수
	bidno2 = 0; //매수2건수
	bidno3 = 0; //매수3건수
	bidno4 = 0; //매수4건수
	bidno5 = 0; //매수5건수

	offerrem1 = 0; //매도1 수량
	offerrem2 = 0; //매도2 수량
	offerrem3 = 0; //매도3 수량
	offerrem4 = 0; //매도4 수량
	offerrem5 = 0; //매도5 수량

	bidrem1 = 0; //매수1수량
	bidrem2 = 0; //매수2수량
	bidrem3 = 0; //매수3수량
	bidrem4 = 0; //매수4수량
	bidrem5 = 0; //매수5수량

	totoffercnt = 0; //매도호가 총 건수
	totofferrem = 0; //매도호가 총 수량
	totbidcnt = 0; //매수호가 총 건수
	totbidrem = 0; //매수호가 총 수량

  fromMaster(Futures: any, masterObj: any) {
    this.iFutures = Futures;
		this.dbCurPrice	= masterObj.dbCurPrice[Futures]	; //현재가
		this.dbBPrice	= masterObj.dbBPrice[Futures]	; //기준가
		this.dbOpenPrice = masterObj.dbOpenPrice[Futures]	; //시가
		this.dbHighPrice = masterObj.dbHighPrice[Futures]	; //고가
		this.dbLowPrice	= masterObj.dbLowPrice[Futures]	; //저가

		this.offerho5		= masterObj.offerho5[Futures]	; //매도5호가
		this.offerho4		= masterObj.offerho4[Futures]	; //매도4호가
		this.offerho3		= masterObj.offerho3[Futures]	; //매도3호가
		this.offerho2		= masterObj.offerho2[Futures]	; //매도2호가
		this.offerho1		= masterObj.offerho1[Futures]	; //매도1호가

		this.bidho1		= masterObj.bidho1[Futures]	; //매수1호가
		this.bidho2		= masterObj.bidho2[Futures]	; //매수2호가
		this.bidho3		= masterObj.bidho3[Futures]	; //매수3호가
		this.bidho4		= masterObj.bidho4[Futures]	; //매수4호가
		this.bidho5		= masterObj.bidho5[Futures]	; //매수5호가

		this.offerno5		= masterObj.offerno5[Futures]	; //매도5 건수
		this.offerno4		= masterObj.offerno4[Futures]	; //매도4 건수
		this.offerno3		= masterObj.offerno3[Futures]	; //매도3 건수
		this.offerno2		= masterObj.offerno2[Futures]	; //매도2 건수
		this.offerno1		= masterObj.offerno1[Futures]	; //매도1 건수

		this.bidno1		= masterObj.bidno1[Futures]	; //매수1건수
		this.bidno2		= masterObj.bidno2[Futures]	; //매수2건수
		this.bidno3		= masterObj.bidno3[Futures]	; //매수3건수
		this.bidno4		= masterObj.bidno4[Futures]	; //매수4건수
		this.bidno5		= masterObj.bidno5[Futures]	; //매수5건수

		this.offerrem1	= masterObj.offerrem1[Futures]; //매도1 수량
		this.offerrem2	= masterObj.offerrem2[Futures]; //매도2 수량
		this.offerrem3	= masterObj.offerrem3[Futures]; //매도3 수량
		this.offerrem4	= masterObj.offerrem4[Futures]; //매도4 수량
		this.offerrem5	= masterObj.offerrem5[Futures]; //매도5 수량

		this.bidrem1 = masterObj.bidrem1[Futures]	; //매수1수량
		this.bidrem2 = masterObj.bidrem2[Futures]	; //매수2수량
		this.bidrem3 = masterObj.bidrem3[Futures]	; //매수3수량
		this.bidrem4 = masterObj.bidrem4[Futures]	; //매수4수량
		this.bidrem5 = masterObj.bidrem5[Futures]	; //매수5수량

		this.totoffercnt	= masterObj.totoffercnt[Futures]; //매도호가 총 건수
		this.totofferrem	= masterObj.totofferrem[Futures]; //매도호가 총 수량
		this.totbidcnt	= masterObj.totbidcnt[Futures]; //매수호가 총 건수
		this.totbidrem	= masterObj.totbidrem[Futures]; //매수호가 총 수량
  }
}
  