<template>
	<div class="headerPC">
		<div class="inner-area">
			<div class="left">
				<div class="logo" @click="movePage('/TotalOrder')">
					<img class="logo-pc" src="../../assets/images/pc/logo-pc.png" alt="">
				</div>
				<div class="menu-area">
					<div class="menu-item" :class="{'on':route.name === 'PcIndex'}" @click="movePage('/TotalOrder')">통합주문</div>
					<div class="menu-item" :class="{'on':route.name === 'PcTransactionForm'}" @click="movePage('/TransactionForm')">입/출금</div>
					<div class="menu-item" :class="{'on':route.name === 'PcTradeList'}" @click="movePage('/TradeList')">거래내역</div>
					<div class="menu-item" :class="{'on':route.name === 'PcOverNight'}" @click="movePage('/OverNight')" v-if="store.SystemState._appMode === 1">오버나잇</div>
					<div class="menu-item" :class="{'on':route.name === 'PcHelpDesk'}" @click="clickHelpDesk('/HelpDesk')">상담센터</div>
					<!-- <div class="menu-item" @click="showPopup('HelpDesk')">상담센터</div> -->
					<div class="menu-item" @click="showPopup('Leverage')" v-if="store.SystemState._appMode === 2">레버리지</div>
					<div class="menu-item" @click="showPopup('Notice')">공지사항</div>
				</div>
			</div>
			<div class="right">
				<div class="user-info-box">
					<img class="profile-img" src="../../assets/images/pc/profile.png" alt="">
					<p class="nickname">{{ store.SettingsState.loginID }}</p>
				</div>
				<button class="set-box" @click="showPopup('Setting')">
					<img class="set-img" src="../../assets/images/pc/setting-pc.png" alt="">
				</button>
			</div>
		</div>
	</div>
	<PcNotice :initIsShowPopup="store.CommonState.isShowNotice" @close="popupClose('Notice')"/>
	<PcSetting :initIsShowPopup="store.CommonState.isShowSetting" @close="popupClose('Setting')"/>
	<PcLeveragePopup :initIsShowPopup="store.CommonState.isShowLeverage" @close="popupClose('Leverage')"/>
	<PcPopupHelpDesk :initShowNotice="state.initShowHelpDesk"
        :initTitle="state.initHelpDeskTitle"
        @close="popupClose('PopupHelpDesk')"/>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive,route } = useBaseComponent();

import { NetworkService, Emit_Success } from '../../network';
import * as AccPwd from '../../constants/AccPwd';
// import * as Common from '../constants/Common';
// import * as Utils from '../utils/Common';
// import { toLocaleString } from '../utils/number';
// import { XingAPIService } from '../network/XingAPIService';

let state = reactive({
	// // popup
	// isShowHelpDesk:false,
	// isShowNotice:false,
	// isShowSetting:false,
	// isShowLeverage:false,
	initShowHelpDesk:false,
	initHelpDeskTitle:'상담센터',
});

onMounted(()=>{
	NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
	// 계좌 비밀번호 체크 생략
	// if (store.LoginPageState.isPacketLoginAckReceived) checkAccPwdApply();
})
onUnmounted(()=> {
	NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
})

const event_LoginSuccess = (params: any) => {
	// 계좌 비밀번호 체크 생략
    // if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
    //     checkAccPwdApply();
    // }
};

const checkAccPwdApply = () => {
	if(route.name !== 'Login'){
		const configState = Number(store.SettingsState.settings.accPwdApply);
		if(configState === AccPwd.cAccPwdApply_OncePopup || (configState === AccPwd.cAccPwdApply_Forever && store.getDomesticAccPwd() === '')) {
			store.getSettingAccountPopupState().then(result => {
				if(result === 1) {
					store.CommonState.isShowSetting = true;
				}
			});
		}
	}
}

const movePage = (page:string) => {
	router.push(page)
}

const showPopup = (name: string) => {
	document.body.style.overflow = "hidden";
	switch (name) {
        // case "HelpDesk":
		// store.CommonState.isShowHelpDesk = true;
        //     break;
        case "Notice":
			store.CommonState.isShowNotice = true;
            break;
        case "Setting":
			store.CommonState.isShowSetting = true;
            break;
        case "Leverage":
			store.CommonState.isShowLeverage = true;
            break;
    }
}

const clickHelpDesk = (page: string) => {
	if (!store.LoginPageState.isPacketLoginAckReceived) return;
	
	if(store.BrandState.bExternalChat === 0) {
		router.push(page)
	} else {
		document.body.style.overflow = "auto";
		state.initShowHelpDesk = true; 
	}
}

const popupClose = (name: string) => {
	document.body.style.overflow = "auto";
    switch (name) {
        // case "HelpDesk":
		// 	store.CommonState.isShowHelpDesk = false;
        //     break;
        case "Notice":
			store.CommonState.isShowNotice = false;
            break;
        case "Setting":
			store.CommonState.isShowSetting = false;
            break;
        case "Leverage":
			store.CommonState.isShowLeverage = false;
            break;
        case "PopupHelpDesk":
			state.initShowHelpDesk =false;
            break;
	};
}
</script>
