import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/images/icon-close.png'


const _hoisted_1 = { class: "popup-inner" }
const _hoisted_2 = {
  key: 0,
  class: "close-box"
}
const _hoisted_3 = { class: "main-title" }
const _hoisted_4 = {
  key: 1,
  class: "close-box"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 3,
  class: "btn-box notice"
}

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PopupNotice',
  props: {
    initShowNotice: {
        type: Boolean,
        default: false,
    },
    initTitle: {
        type: String,
        default: "",
    },
    initNoticeMessage: {
        type: String,
        default: "",
    },
},
  emits: ["close","noToday"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits, defineProps, onMounted, reactive,} = useBaseComponent();

const props = __props;

const emit = __emit;

const noToday = () => {
    emit("noToday")
}

const close = () => {
    emit("close");
};

return (_ctx: any,_cache: any) => {
  return (props.initShowNotice)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["popup notice", {'indexNotice': _unref(route).name === 'Home' || _unref(route).name === 'TotalOrder'}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_unref(route).name === 'Home')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(props.initTitle), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  onClick: close
                })
              ])),
          (_unref(route).name !== 'Home')
            ? (_openBlock(), _createElementBlock("p", {
                key: 2,
                innerHTML: props.initTitle,
                class: "title"
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("p", {
            innerHTML: props.initNoticeMessage,
            class: "content"
          }, null, 8, _hoisted_6),
          (_unref(route).name === 'Home' || _unref(route).name === 'TotalOrder')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "noToday",
                  onClick: noToday
                }, "오늘은 그만보기"),
                (_unref(route).name === 'TotalOrder')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "ok",
                      onClick: close
                    }, "동의"))
                  : (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "ok",
                      onClick: close
                    }, "확인"))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})