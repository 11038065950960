import bytes from 'bytes';
import commaNumber from 'comma-number';


export function toLocaleString(number: any, toFixed = 4) {
  //const options = toFixed ? { 'minimumFractionDigits': toFixed, 'maximumFractionDigits': toFixed } : {};
  //return number.toLocaleString(undefined, options);
  return commaNumber(number);
}

// * toLocaleString() is not supported in Android
// https://github.com/facebook/react-native/issues/15717
export const toNumWithComma = (num: any) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const prettyBytes = (num: any) => bytes(num, { unitSeparator: ' ' }).toLowerCase();

export const prettySec = (microSec: any) => {
  const ms = 1024;
  const s = ms * ms;

  if (microSec >= s) {
    return `${toNumWithComma((microSec / s).toFixed(2))} s`;
  }

  if (microSec >= ms) {
    return `${toNumWithComma((microSec / ms).toFixed(2))} ms`;
  }

  return `${toNumWithComma(microSec)} µs`;
};
