import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "tradeList innerHeight" }
const _hoisted_3 = { class: "con-box" }
const _hoisted_4 = { class: "top-box" }
const _hoisted_5 = { class: "bank-account" }
const _hoisted_6 = { class: "dropdown-sort" }
const _hoisted_7 = { class: "select-sort" }
const _hoisted_8 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_9 = { class: "out" }
const _hoisted_10 = {
  key: 0,
  class: "section-sort"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "btn-box" }
const _hoisted_13 = { class: "mid-box" }
const _hoisted_14 = { class: "big-box" }
const _hoisted_15 = { class: "small-box" }
const _hoisted_16 = { class: "white-box" }
const _hoisted_17 = { class: "text" }
const _hoisted_18 = { class: "small-box" }
const _hoisted_19 = { class: "white-box bdrn" }
const _hoisted_20 = { class: "text" }
const _hoisted_21 = { class: "big-box" }
const _hoisted_22 = { class: "small-box" }
const _hoisted_23 = { class: "white-box" }
const _hoisted_24 = { class: "text" }
const _hoisted_25 = { class: "small-box" }
const _hoisted_26 = { class: "white-box bdrn" }
const _hoisted_27 = { class: "text" }
const _hoisted_28 = { class: "big-box three" }
const _hoisted_29 = { class: "small-box" }
const _hoisted_30 = { class: "white-box" }
const _hoisted_31 = { class: "text" }
const _hoisted_32 = { class: "small-box" }
const _hoisted_33 = { class: "white-box" }
const _hoisted_34 = { class: "text" }
const _hoisted_35 = { class: "small-box" }
const _hoisted_36 = { class: "white-box" }
const _hoisted_37 = { class: "text" }
const _hoisted_38 = { class: "bottom-box" }
const _hoisted_39 = { class: "body-box subPage transactionForm tradeList" }
const _hoisted_40 = { class: "con-box bottom" }
const _hoisted_41 = { class: "left index" }
const _hoisted_42 = { class: "trade-list-box" }

import useBaseComponent from "@/baseComponent";
import { Theme } from '../constants';
import { NetworkService, Emit_DrawTrans, Emit_TradingLog, Emit_StopLossLog, Emit_Success } from '../network';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import { UserLog } from '../network/UserLog';
import * as Utils from '../utils/Common';
import moment from '../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../constants/AccPwd';

const Order_All = 99; // 체결
const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;


export default /*@__PURE__*/_defineComponent({
  __name: 'TradeList',
  setup(__props) {

const { router, store, onMounted, onUnmounted, reactive } = useBaseComponent();

let state = reactive({

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결 / 미체결", value: Order_All},
        {title: "체결", value: Order_Contract},
        {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_Contract,
    selectedItem: null as any,
    
    //체결내역
    oppositeOrders: [] as any,
    renderOppositeList: [] as any,

    //미체결내역
    leftOrders: [] as any,
    renderLeftsList: [] as any,
    
    //거래내역
    orderLogs: [] as any,
    renderLogList: [] as any,
    
    //S/L내역
    stopLossLogs: [] as any,
    renderSLLogsList: [] as any,

    isOversea: Common.eNation_Domestic,
    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,
});

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TradeList');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_TradingLog, event_TradingLog);
        NetworkService.emitter.on(Emit_StopLossLog, event_StopLossLog);
        initData();
    }
});

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.off(Emit_StopLossLog, event_StopLossLog);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    state.isOversea = isOversea;

    initAccPicker();

    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        state.refreshCount++;

        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();

    // renderOrders(state.tabIdx, state.refreshCount);
}
const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
        // 비밀번호 인증 패스 여부
        state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    }
    
    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 리스트 업데이트
    refreshOrders();
}

const event_TradingLog = (params: any) => {
    // 거래 로그 수신 완료.
    const TradingLogs = store.AccountState.TradingLogs;
    state.orderLogs = TradingLogs;
    refreshOrders();
}

const event_StopLossLog = (params: any) => {
    // 스탑로스 로그 수신 완료.
    const StopLossLogs = store.AccountState.StopLossLogs;
    state.stopLossLogs = StopLossLogs;
    refreshOrders();
}

const onSearchPress = () => {
    if(!checkAccPwd()) {
        return;
    }

    if (state.selectedItem) {
        state.isOversea = state.selectedItem.bOversea;
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();

        if(state.tabIdx === Order_Contract || state.tabIdx === Order_Left){
            // 체결 / 미체결 리스트 업데이트
            refreshOrders();   
        } else if (state.tabIdx === Order_Log){
            // 거래내역 요청
            tradingLogsReq();
        } else if (state.tabIdx === Order_SL){
            // 스탑로스내역 요청
            stopLossLogsReq();
        }
    }
}

const refreshOrders = () => {
    // 체결 리스트를 얻는다.
    const oppositeOrders = store.GetClientOppositeList();
    //const oppositeOrders = TEST_ORDERS;
    state.oppositeOrders = oppositeOrders;
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;
    state.leftOrders = leftOrders;

    renderOrders();
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const onTabPress = (tabIdx: number) => {
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;

        if(state.bAccPassed){
            onSearchPress();
        }
    }
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

// 거래내역 요청
const tradingLogsReq = () => {
    // 30일 요청
    //const szStartDate = moment(_MarketStore.tOpen[Common.G_FUTURES_OVERSEAS]).format('YYYYMMDD');
    const szStartDate = moment(Date.now()).add(-30, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체
    // 0 = 국내, 1 = 해외, 2 = 국내/해외
    const isOversea = Common.eNation_Max;

    const szUserLog = `[${Common.g_szNationName[isOversea]} 거래내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.transactionListReq({
        futures, 
        isOversea, 
        szStartDate, 
        szEndDate,
    });
}

// 스탑로스내역 요청
const stopLossLogsReq = () => {
    // 30일 요청
    const szStartDate = moment(Date.now()).add(-30, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체

    const szUserLog = `[${Common.g_szFuturesNameMini[futures]} S/L내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.stopLossLogReq({
        futures, 
        szStartDate, 
        szEndDate,
    });
}

// 청산 / 취소
const onActionPress = (order: any) => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderPrice = order.dbOrderPrice;
    const orderIdx = order.iOrderIdx;

    // 체결창
    if (state.tabIdx === Order_Contract) {
        // 청산
        if (order.iOppositeAmount === 0) {
            store.setVisibleDialog({ message: '체결된 주문만 청산이 가능합니다.' });
            return;
        }

        // 청산주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_BatchSettle;
        const orderMethod = Common.C_ORDERMETHOD_SellBtn;
        const oppTradeType = (tradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const orderMax = store.AccountState.iOrderMax[futures][oppTradeType];
        if (orderMax < order.iOppositeAmount) {
            store.setVisibleDialog({ message: `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.` });
            return;
        } else {
            UserLog.modifyOrder(futures, tradeType, orderPrice, order.iOppositeAmount, modifyMethod, orderMethod, orderIdx);
            NetworkService.send.modifyOrderReq({
                futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iOppositeAmount, orderMethod,
            });
        }
    } else if (state.tabIdx === Order_Left) {
        // 미체결창
        // 취소
        if (order.iLeftAmount === 0) {
            store.setVisibleDialog({ message: '미체결된 주문만 취소가 가능합니다.' });
            return;
        }

        // 취소주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_Cancel;
        const orderMethod = Common.C_ORDERMETHOD_CancelBtn;
        UserLog.modifyOrder(futures, tradeType, orderPrice, order.iLeftAmount, modifyMethod, orderMethod, orderIdx);
        NetworkService.send.modifyOrderReq({
            futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iLeftAmount, orderMethod,
        });
    }
}

//체결 리스트
const renderOpposite = (orderList: any) => {
    state.renderOppositeList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);
        const objOrder = {
            order: order,
            futures: order.iFutures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            amount: order.iOppositeAmount,
            profit: profit,
            fee: fee,
            // 2023-10-27: 당일 누적 금액으로 변경
            //net: profit - fee,
            //net: ' / ' + store.AccountState.iRealGainNLossFutures[futures] + ' / ' + store.AccountState.iDepoCommissionFutures[futures]
            //net: store.AccountState.iDepoCommissionFutures[futures]
            net: store.AccountState.iRealGainNLossFutures[futures] - store.AccountState.iDepoCommissionFutures[futures]
        }
        state.renderOppositeList.push(objOrder)
    })
}

//미체결 리스트
const renderLefts = (orderList: any) => {
    state.renderLeftsList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const objOrder = {
            order: order,
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            priceType: Common.g_szPriceTypeName[order.iPriceType],
            orderIdx: order.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[Common.C_ORDERACCEPT_Acceptance],
            amount: order.iOrderAmount,
            oppAmount: order.iOppositeAmount,
        }
        state.renderLeftsList.push(objOrder)
    })
}

//거래내역
const renderLogs = (orderLog: any) => {
    // TradingLog
    state.renderLogList = [];
    orderLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(log.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            priceType: Common.g_szPriceTypeName[log.iPriceType],
            orderIdx: log.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[log.iAcceptState],
            amount: log.iOrderAmount,
            contAmount: log.iContractedAmount,
            averagePrice: Utils.MicroPriceFormatStr(log.dbContractedPrice, futures),   // 평균
            contractDate: log.szContractedDate, // 체결시
            overTrading: log.szOverTrading,
            leverage: log.iLeverage,
            profit: toLocaleString(log.iProfit),
            fee: toLocaleString(log.iCommission),
            totalProfit: toLocaleString(log.iTotalProfit),
            evalProfit: toLocaleString(log.nEvaluateProfit),
            depoAmount: toLocaleString(log.iDepoAmount),
        }
        state.renderLogList.push(objOrder)
    })
}

//SL내역
const renderSLs = (stopLossLog: any) => {
    // TradingLog
    state.renderSLLogsList = [];
    stopLossLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            state: log.szState,
            averagePrice: Utils.MicroPriceFormatStr(log.dbAvePrice, futures),   // 평균가,
            stopPrice: Utils.MicroPriceFormatStr(log.dbStopPrice, futures),   // 스탑가,
            contractPrice: Utils.MicroPriceFormatStr(log.dbContractPrice, futures),   // 체결가,
            stopTicks: log.bUseStop ? `${log.iStopTicks}` : '사용안함',
            stopColor: log.iUsedTick === 1 ? Theme.palette.red : Theme.text.color,
            stopWeight: log.iUsedTick === 1 ? 'bold' : 'normal',
            lossTicks: log.bUseLoss ? `${log.iLossTicks}` : '사용안함',
            lossColor: log.iUsedTick === 2 ? Theme.palette.red : Theme.text.color,
            lossWeight: log.iUsedTick === 2 ? 'bold' : 'normal',
            slName: log.bReserved ? 'S/L' : '',
            orderIdx: log.iOrderIdx >= 0 ? `${log.iOrderIdx}` : '',
            regDate: log.szRegDate, // 등록시간,
            useDate: log.szUseDate, // 완료시긴,
        }
        state.renderSLLogsList.push(objOrder)
    });
}

const renderOrders = () => {
    const orderIdx = state.tabIdx;
    
    if(orderIdx === Order_Contract){
        //체결
        renderOpposite(state.oppositeOrders)
    } else if(orderIdx === Order_Left){
        //미체결
        renderLefts(state.leftOrders)
    } else if(orderIdx === Order_Log) {
        //거래내역
        renderLogs(state.orderLogs)
    } else {
        //SL내역
        renderSLs(state.stopLossLogs);
    }
}


return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_PcTradeTable = _resolveComponent("PcTradeTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { text: '거래내역' }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  class: "drop-sort",
                  type: "checkbox",
                  id: "dropPageCount",
                  name: "dropPageCount",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickAccOpen) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _unref(state).pickAccOpen]
                ]),
                _createElementVNode("label", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, [
                    _createTextVNode(_toDisplayString(_unref(state).pickAccDesc), 1),
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-down", [{ on: _unref(state).pickAccOpen }]])
                    }, null, 2)
                  ])
                ]),
                (_unref(state).pickAccValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickAccItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          onClick: ($event: any) => (onPickAccChangeValue(item)),
                          class: _normalizeClass(["text", { active: item.value === _unref(state).pickAccValue }])
                        }, _toDisplayString(item.desc), 11, _hoisted_11))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("button", {
              class: "inquire",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (onSearchPress()))
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("span", { class: "text" }, "조회", -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "국내예탁금")
              ], -1)),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "국내평가예탁금")
              ], -1)),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(toLocaleString)(_unref(state).estimatedAmount)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "해외예탁금")
              ], -1)),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "해외평가예탁금")
              ], -1)),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(toLocaleString)(_unref(state).estimatedAmountOversea)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "총평가손익")
              ], -1)),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("p", _hoisted_31, _toDisplayString(_unref(toLocaleString)(_unref(state).totalProfit)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "국내실현손익")
              ], -1)),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("p", _hoisted_34, _toDisplayString(_unref(toLocaleString)(_unref(state).netDomestic)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "sky-box" }, [
                _createElementVNode("p", { class: "text" }, "해외실현손익")
              ], -1)),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("p", _hoisted_37, _toDisplayString(_unref(toLocaleString)(_unref(state).netOversea)), 1)
              ])
            ])
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "caution" }, "*본 화면은 실시간이 아닌 조회 시점 기준의 정보입니다.", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_38, [
        _createElementVNode("div", _hoisted_39, [
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_component_PcTradeTable, {
                  initBAccPAssed: _unref(state).bAccPassed
                }, null, 8, ["initBAccPAssed"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})