import { createApp, watch } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import { useStore } from '@/pinia'


import "@/assets/css/reset.css";
import "@/assets/css/index.css";
import "@/assets/css/header.css";
import "@/assets/css/menuTab.css";
import "@/assets/css/login.css";
import "@/assets/css/popup.css";

import "@/assets/css/resetDark.css";
import "@/assets/css/indexDark.css";
import "@/assets/css/headerDark.css";
import "@/assets/css/menuTabDark.css";
// import "@/assets/css/loginDark.css";
import "@/assets/css/popupDark.css";

import "@/assets/css/pc.css"
import "@/assets/css/pcDark.css"

import Header from "@/components/Header.vue";
import MenuTab from "@/components/MenuTab.vue";
import PopupChart from "@/components/TVChartContainer.vue"
import PopupConfirm from "@/components/popup/PopupConfirm.vue";
import PopupNotice from "@/components/popup/PopupNotice.vue";
import PopupHelpDesk from "@/components/popup/PopupHelpDesk.vue";
import PopupTwoButton from "@/components/popup/PopupTwoButton.vue";
import PopupLeftOrders from "@/components/popup/PopupLeftOrders.vue"
import PopupStockSearch from "@/components/popup/PopupStockSearch.vue"
import SetPassword from "@/components/SettingPopup/SetPassword.vue";
import ChangePassword from "@/components/SettingPopup/ChangePassword.vue";
import LoginPassword from "@/components/SettingPopup/LoginPassword.vue";
import LeverageSetting from "@/components/SettingPopup/LeverageSetting.vue";
import TVChartContainer from "@/components/TVChartContainer.vue";
import LiveAlarm from "@/components/popup/PopupLiveAlarm.vue";

// /PC/
import PcHeader from "@/components/PC/PcHeader.vue";
import PcStockList from "@/components/PC/PcStockList.vue";
import PcTradeTable from "@/components/PC/PcTradeTable.vue";
//import PcHelpDesk from "@/components/PC/PcHelpDesk.vue";
import PcNotice from "@/components/PC/PcNotice.vue";
import PcSetting from "@/components/PC/PcSetting.vue";
import PcPopupNotice from "@/components/PC/PcPopupNotice.vue";
import PcPopupHelpDesk from "@/components/PC/PcPopupHelpDesk.vue";
import PcSetPassword from "@/components/PC/PcSetPassword.vue";
import PcChangePassword from "@/components/PC/PcChangePassword.vue";
import PcLoginPassword from "@/components/PC/PcLoginPassword.vue";
import PcPopupLiveAlarm from "@/components/PC/PcPopupLiveAlarm.vue";
import PcAccountInfoPopup from "@/components/PC/PcAccountInfoPopup.vue";
import PcTVChartContainer from "@/components/PcTVChartContainer.vue";
import PcLeveragePopup from "@/components/PC/PcLeveragePopup.vue";
import PcLeftOrdersPopup from "@/components/PC/PcLeftOrdersPopup.vue";


const app = createApp(App);
const pinia = createPinia();

// const useI18n = i18n();
app.use(router);
app.use(pinia);
// app.use(useI18n);

app.component("Header", Header);
app.component("MenuTab", MenuTab);
app.component("PopupChart", PopupChart);
app.component("PopupConfirm", PopupConfirm);
app.component("PopupNotice", PopupNotice);
app.component("PopupHelpDesk", PopupHelpDesk);
app.component("PopupTwoButton", PopupTwoButton);
app.component("PopupLeftOrders", PopupLeftOrders);
app.component("PopupStockSearch", PopupStockSearch);
app.component("SetPassword", SetPassword);
app.component("LeverageSetting", LeverageSetting);
app.component("ChangePassword", ChangePassword);
app.component("LoginPassword", LoginPassword);
app.component("TVChartContainer", TVChartContainer);
app.component("LiveAlarm", LiveAlarm);
// pc
app.component("PcHeader", PcHeader);
app.component("PcStockList", PcStockList);
app.component("PcTradeTable", PcTradeTable);
//app.component("PcHelpDesk", PcHelpDesk);
app.component("PcNotice", PcNotice);
app.component("PcPopupNotice", PcPopupNotice);
app.component("PcPopupHelpDesk", PcPopupHelpDesk);
app.component("PcSetting", PcSetting);
app.component("PcSetPassword", PcSetPassword);
app.component("PcChangePassword", PcChangePassword);
app.component("PcLoginPassword", PcLoginPassword);
app.component("PcPopupLiveAlarm", PcPopupLiveAlarm);
app.component("PcAccountInfoPopup", PcAccountInfoPopup);
app.component("PcTVChartContainer", PcTVChartContainer);
app.component("PcLeveragePopup", PcLeveragePopup);
app.component("PcLeftOrdersPopup", PcLeftOrdersPopup);

app.mount("#app");

const store = useStore();
store.GetTimeGap();