export const NOT_FOUND =		-1;
export const ERROR_VALUE	=	-1;
export const INDEX_NONE	=	-1;

export const NO_ERROR = 0;
export const NOERROR = 0;

// 기본 resolution 설정값: 2023-09-01
//'1T', 
//'1', '3', '5', '10', '20',
//'1D', '1W', '1M'],
export const DEFAULT_RESOLUTION_ARRAY = [1, 3, 5, 10, '1D', '1W', '1M'];

// 정상적인 마스트 시작과 끝 호가 격차 ticks
// 예측값이며 실제 범위를 벗어나면 조절하여야한다.
export const C_CORRECT_HOGA_DIFF_TICKS =	100000;
// 호가 화면에 표시되는 최대 틱
export const C_GRID_HOGA_DIFF_TICKS =	15000;

// 최대 입금액
export const C_DEPOSIT_AMOUNT_MAX			= 100000000;
// 최대 동시 실시간 호가 갯수
export const C_REALDATA_SESSION_MAX			= 3;

// 사용자 구분
export const C_USERTYPE_STD			= 0;		// 일반유저
export const C_USERTYPE_ADMIN	= 1;					// 관리자
export const C_USERTYPE_TEST	= 2;					// 테스트
export const C_USERTYPE_MAX	= 3;	

// 사용자 등급
export const C_USERGRADE_STD			= 0;
export const C_USERGRADE_VIP	= 1;
export const C_USERGRADE_MAX = 11;		// 일반 1, VIP 10
// VIP 은행계좌
export const C_VIP_BANKACCOUNT_MAX = 10;

// 계정 구분
export const C_IDTYPE_STD			= 0;		// 정상 계정
export const C_IDTYPE_SECESSION = 1;					// 탈퇴 계정
export const C_IDTYPE_DORMANCY = 2;					// 휴면 계정
export const C_IDTYPE_MAX = 3;

// Dedicated server(client)의 수
export const G_DS_MAX		= 100;			// broker에 붙는 dedicated server의 수
export const G_CLIENT_MAX	= 1000;			// dedicated server에 붙어있는 client 수
export const G_ADMIN_MAX		= 10;			// dedicated server에 붙는 admin의 수

// User ID 길이
export const G_USERID_LEN		= 20;
// User Pwd 길이
export const G_USERPWD_LEN		= 20;
// Brand Name 길이
export const G_BRANDNAME_LEN		= 30;
// Signup Brand Name 길이
export const G_SIGNUP_BRANDNAME_LEN	= 30;
// Signup User ID 길이
export const G_SIGNUP_USERID_LEN		= 20;
// Signup User Pwd 길이
export const G_SIGNUP_USERPWD_LEN	= 20;
// Signup 이름
export const G_SIGNUP_NAME_LEN		= 30;
// Signup 핸드폰번호
export const G_SIGNUP_HP_LEN			= 30;
// Signup 이메일
export const G_SIGNUP_EMAIL_LEN			= 50;
// Signup 은행
export const G_SIGNUP_BANK_LEN		= 30;
// Signup 계좌번호
export const G_SIGNUP_ACCOUNTNO_LEN	= 30;
// Signup 예금주
export const G_SIGNUP_BANKHOLDER_LEN	= 30;
// Signup 가입IP
export const G_SIGNUP_IP_LEN			= 20;
// Signup 추천인IP
export const G_SIGNUP_RECOMMENDER_LEN	= 20;

// 계좌 비밀번호 길이
export const G_ACCPWD_LEN		= 4;
// 비번 입력 오류 횟수 제한
export const G_PWD_WRONG_LIMIT = 5;
// 월/년
export const G_MONTH_MAX = 12;

// 공지 내용 1회 사이즈
export const cBlob_ContentsSize = 7500;

// 선물의 종류
export const G_FUTURES_DAY	= 0;		// 주간 선물
export const G_FUTURES_NIGHT = 1;					// 야간 선물
export const G_FUTURES_EURO = 2;						// 유로 선물
export const G_FUTURES_OIL = 3;						// 크루드 오일
export const G_FUTURES_GAS = 4;						// 천연가스
export const G_FUTURES_GOLD = 5;						// 금 (Gold)
export const G_FUTURES_SILVER = 6;					// 은
export const G_FUTURES_COPPER = 7;					// 구리
export const G_FUTURES_YEN = 8;						// 일본엔(JY)
export const G_FUTURES_POUND = 9;					// 영국파운드(BP)
export const G_FUTURES_CANADA = 10;					// 캐나다달러
export const G_FUTURES_AUS = 11;						// 호주달러
export const G_FUTURES_SNP500 = 12;					// 미니S&P 500
export const G_FUTURES_NASDAQ = 13;					// 미니 나스닥 100
export const G_FUTURES_DOW = 14;						// 미니 다우존스 100
export const G_FUTURES_HANGSENG = 15;					// 항셍지수
export const G_FUTURES_CHINA50 = 16;					// 차이나 A50
export const G_FUTURES_DAX = 17;						// 독일DAX
export const G_FUTURES_NIKKEI = 18;					// 니케이지수
export const G_FUTURES_MAX = 19;						// 구분자
export const G_FUTURES_OVERSEAS = G_FUTURES_EURO;	// 미국 증권 거래소 (뉴욕, 시카고)
export const G_FUTURES_HKEX = G_FUTURES_HANGSENG;		// 홍콩 증권 거래소
export const G_FUTURES_OVERSEA_MAX = (G_FUTURES_MAX - G_FUTURES_OVERSEAS);

// G_FUTURES_MAX
export const FUTURES_ARRAY_BOOL = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
export const FUTURES_ARRAY_DOUBLE = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
export const FUTURES_ARRAY_INT = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export const FUTURES_ARRAY_STRING = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];

// 선물의 이름
export const g_szFuturesName = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	'KOSPI200 선물',
	// 	G_FUTURES_NIGHT,					// 야간 선물
	'CME 야간선물',
	// 	G_FUTURES_EURO,						// 유로 선물
	'EURO FX',
	//	G_FUTURES_OIL						// 크루드 오일
	'크루드 오일',
	// G_FUTURES_GAS,						// 천연가스
	'천연가스',
	//	G_FUTURES_GOLD						// 일본엔(JY)
	'금(Gold)',
	// G_FUTURES_SILVER,					// 은
	'은(Silver)',
	// G_FUTURES_COPPER,					// 구리
	'구리(COPPER)',
	//	G_FUTURES_YEN						// 일본엔(JY)
	'일본엔(JY)',
	//	G_FUTURES_POUND					// 영국파운드(BP)
	'영국파운드(BP)',
	// G_FUTURES_CANADA,					// 캐나다달러
	'캐나다 달러(CD)',
	// G_FUTURES_AUS,						// 호주달러
	'호주 달러(AD)',
	//	G_FUTURES_SNP500					// 미니S&P500
	'미니 S&P500',
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	'미니 나스닥100',
	// G_FUTURES_DOW,						// 미니 다우존스 100
	'미니 다우존스',
	// 	G_FUTURES_HANGSENG					// 홍콩 항셍지수
	'항셍지수',
	// G_FUTURES_CHINA50					// 차이나 A50지수
	'차이나 A50',
	// G_FUTURES_DAX,						// 독일DAX
	'독일DAX',
	// G_FUTURES_NIKKEI,					// 니케이지수
	'니케이지수',
	// 	G_FUTURES_MAX						// 구분자
	'전종목',
];

// 선물의 이름 짧게
export const g_szFuturesNameMini = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	'KOSPI200', //'주간선물',
	// 	G_FUTURES_NIGHT,					// 야간 선물
	'야간(CME)', //'야간선물',
	// 	G_FUTURES_EURO,						// 유로 선물
	'EURO FX',
	//	G_FUTURES_OIL						// 크루드 오일
	'크루드오일',
	// G_FUTURES_GAS,						// 천연가스
	'천연가스',
	//	G_FUTURES_GOLD						// 일본엔(JY)
	'금(Gold)',
	// G_FUTURES_SILVER,					// 은
	'은(Silver)',
	// G_FUTURES_COPPER,					// 구리
	'구리',
	//	G_FUTURES_YEN						// 일본엔(JY)
	'일본엔',
	//	G_FUTURES_POUND						// 영국파운드(BP)
	'영국파운드',
	// G_FUTURES_CANADA,					// 캐나다달러
	'캐나다달러',
	// G_FUTURES_AUS,						// 호주달러
	'호주달러',
	//	G_FUTURES_SNP500					// 미니S&P500
	'S&P500',
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	'나스닥100',
	// G_FUTURES_DOW,						// 미니 다우존스 100
	'다우존스',
	// G_FUTURES_HANGSENG					// 홍콩 항셍 지수
	'항셍지수',
	// G_FUTURES_CHINA50					// 차이나 A50지수
	'차이나 A50',
	// G_FUTURES_DAX,						// 독일DAX
	'독일DAX',
	// G_FUTURES_NIKKEI,					// 니케이지수
	'니케이지수',
	// 	G_FUTURES_MAX						// 구분자
	'전종목',
];

export function CMMGetFuturesByName(lpFuturesName: any) {
	for (let futures = 0; futures < G_FUTURES_MAX; futures ++) {
		if (g_szFuturesName[futures] === lpFuturesName) {
			return futures;
		}
	}

	return INDEX_NONE;
}

// 단축메뉴
export const g_szFuturesMenuName = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	'주간',
	// 	G_FUTURES_NIGHT,					// 야간 선물
	'야간',
	// 	G_FUTURES_EURO,						// 유로 선물
	'유로',
	//	G_FUTURES_OIL						// 크루드 오일
	'오일',
	// G_FUTURES_GAS,						// 천연가스
	'가스',
	//	G_FUTURES_GOLD						// 일본엔(JY)
	'금',
	// G_FUTURES_SILVER,					// 은
	'은',
	// G_FUTURES_COPPER,					// 구리
	'구리',
	//	G_FUTURES_YEN						// 일본엔(JY)
	'엔',
	//	G_FUTURES_POUND						// 영국파운드(BP)
	'파운드',
	// G_FUTURES_CANADA,					// 캐나다달러
	'캐나다',
	// G_FUTURES_AUS,						// 호주달러
	'호주',
	//	G_FUTURES_SNP500					// 미니S&P500
	'S&P500',
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	'나스닥',
	// G_FUTURES_DOW,						// 미니 다우존스 100
	'다우',
	// G_FUTURES_HANGSENG					// 홍콩 항셍 지수
	'항셍',
	// G_FUTURES_CHINA50					// 차이나 A50지수
	'차이나',
	// G_FUTURES_DAX,						// 독일DAX
	'닥스',
	// G_FUTURES_NIKKEI,					// 니케이지수
	'니케이',
	// 	G_FUTURES_MAX						// 구분자
	'전종목',
];

// 선물 1틱
export const G_ONETICK = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	0.05,
	// 	G_FUTURES_NIGHT,					// 야간 선물
	0.05,
	// 	G_FUTURES_EURO,						// 유로 선물
	0.00005,
	// G_FUTURES_OIL,						// 크루드 오일
	0.01,
	// G_FUTURES_GAS,						// 천연가스
	0.001,
	// G_FUTURES_GOLD,						// 금 (Gold)
	0.1,
	// G_FUTURES_SILVER,					// 은
	0.005,
	// G_FUTURES_COPPER,					// 구리
	0.0005,
	// G_FUTURES_YEN,						// 일본엔(JY)
	0.5,
	// G_FUTURES_POUND,					// 영국파운드(BP)
	0.0001,
	// G_FUTURES_CANADA,					// 캐나다달러
	0.00005,
	// G_FUTURES_AUS,						// 호주달러
	0.00005,
	// G_FUTURES_SNP500,					// 미니S&P 500
	0.25,
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	0.25,
	// G_FUTURES_DOW,						// 미니 다우존스 100
	1.00,
	// G_FUTURES_HANGSENG,					// 홍콩 항셍지수
	1.0,
	// G_FUTURES_CHINA50					// 차이나 A50지수
	2.5,
	// G_FUTURES_DAX,						// 독일DAX
	0.5,
	// G_FUTURES_NIKKEI,						// 니케이지수
	5,
	// G_FUTURES_MAX			// 구분자
];

// 주문 병합용 선물 1틱
export const G_MICRO_ONETICK = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	0.001,
	// 	G_FUTURES_NIGHT,					// 야간 선물
	0.001,
	// 	G_FUTURES_EURO,						// 유로 선물
	0.000001,
	// G_FUTURES_OIL,						// 크루드 오일
	0.001,
	// G_FUTURES_GAS,						// 천연가스
	0.0001,
	// G_FUTURES_GOLD,						// 금 (Gold)
	0.01,
	// G_FUTURES_SILVER,					// 은
	0.0001,
	// G_FUTURES_COPPER,					// 구리
	0.00001,
	// G_FUTURES_YEN,						// 일본엔(JY)
	0.01,
	// G_FUTURES_POUND,					// 영국파운드(BP)
	0.00001,
	// G_FUTURES_CANADA,					// 캐나다달러
	0.000001,
	// G_FUTURES_AUS,						// 호주달러
	0.000001,
	// G_FUTURES_SNP500,					// 미니S&P 500
	0.001,
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	0.001,
	// G_FUTURES_DOW,						// 미니 다우존스 100
	0.1,
	// G_FUTURES_HANGSENG,					// 항셍지수
	0.1,
	// G_FUTURES_CHINA50					// 차이나 A50지수
	0.01,
	// G_FUTURES_DAX,						// 독일DAX
	0.01,
	// G_FUTURES_NIKKEI,						// 니케이지수
	0.1,
	// G_FUTURES_MAX			// 구분자
];

// 소수점 이하 자리수
export const g_bFuturesUnitOfDigit = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	2,
	// 	G_FUTURES_NIGHT,					// 야간 선물
	2,
	// 	G_FUTURES_EURO,						// 유로 선물
	5,
	// 	G_FUTURES_OIL,						// 크루드 오일
	2,
	// G_FUTURES_GAS,						// 천연가스
	3,
	// 	G_FUTURES_GOLD,						// 금(Gold)
	1,
	// G_FUTURES_SILVER,					// 은
	3,
	// G_FUTURES_COPPER,					// 구리
	4,
	// 	G_FUTURES_YEN,						// 일본엔(JY)
	1,
	// 	G_FUTURES_POUND,					// 영국파운드(BP)
	4,
	// G_FUTURES_CANADA,					// 캐나다달러
	5,
	// G_FUTURES_AUS,						// 호주달러
	5,
	// 	G_FUTURES_SNP500,					// 미니S&P 500
	2,
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	2,
	// G_FUTURES_DOW,						// 미니 다우존스 100
	2,
	// G_FUTURES_HANGSENG,					// 항셍지수
	1,
	// G_FUTURES_CHINA50,					// 차이나 A50지수
	1,
	// G_FUTURES_DAX,						// 독일DAX
	1,
	// G_FUTURES_NIKKEI,						// 니케이지수
	0,
	// 	G_FUTURES_MAX			// 구분자
];

// 시간 타임존 수정값
export const g_timezoneModifier = [
	// 	G_FUTURES_DAY			= 0,		// 주간 선물
	0,
	// 	G_FUTURES_NIGHT,					// 야간 선물
	3600000*15,
	// 	G_FUTURES_EURO,						// 유로 선물
	3600000*15,
	// 	G_FUTURES_OIL,						// 크루드 오일
	3600000*14,
	// G_FUTURES_GAS,						// 천연가스
	3600000*14,
	// 	G_FUTURES_GOLD,						// 금(Gold)
	3600000*14,
	// G_FUTURES_SILVER,					// 은
	3600000*14,
	// G_FUTURES_COPPER,					// 구리
	3600000*14,
	// 	G_FUTURES_YEN,						// 일본엔(JY)
	3600000*15,
	// 	G_FUTURES_POUND,					// 영국파운드(BP)
	3600000*15,
	// G_FUTURES_CANADA,					// 캐나다달러
	3600000*15,
	// G_FUTURES_AUS,						// 호주달러
	3600000*15,
	// 	G_FUTURES_SNP500,					// 미니S&P 500
	3600000*15,
	// G_FUTURES_NASDAQ,					// 미니 나스닥 100
	3600000*15,
	// G_FUTURES_DOW,						// 미니 다우존스 100
	3600000*15,
	// G_FUTURES_HANGSENG,					// 항셍지수
	3600000,
	// G_FUTURES_CHINA50,					// 차이나 A50지수
	3600000*15,
	// G_FUTURES_DAX,						// 독일DAX
	3600000*15,
	// G_FUTURES_NIKKEI,						// 니케이지수
	3600000*15,	
];

// 반올림
export function ROUNDOFF(DblNum: any) { return Math.round(DblNum); }
export function ROUNDOFFOFF(x: any, dig: any) { return (Math.floor(x * Math.pow(10.0, dig) + 0.5) / Math.pow(10.0, dig)); } 

// 선물 장시간 잔여 시간
export const G_HOURLEFT_10MIN	= 0;		// 10분
export const G_HOURLEFT_5MIN	= 1;						// 5분
export const G_HOURLEFT_1MIN	= 2;						// 1분
export const G_HOURLEFT_ONTIME	= 3;						// 시작(정각)
export const G_HOURLEFT_AFTER15MIN	= 4;					// 이후 15분
export const G_HOURLEFT_MAX	= 5;							// 구분자

// 선물 장 시간 IOCP
export const kTimeMsgMarketOpen	= 0;				// 주간장 개시
export const kTimeMsgMarketOpenOverSea	= 1;			// 해외선물 개시
export const kTimeMsgMarketClose	= 2;				// 장종료
export const kTimeMsgMarketDayClose	= 3;				// 주간장 종료
export const kTimeMsgMarketNightOpen	= 4;			// 야간장 개시
export const kTimeMsgMarketHangsengOpen	= 5;			// 항셍 오전장 개시
export const kTimeMsgMarketHangsengClose	= 6;		// 항셍 오전장 종료
export const kTimeMsgMarketHangseng2Open	= 7;		// 항셍 오후장 오픈
export const kTimeMsgMarketHangseng2Close	= 8;		// 항셍 오후장 종료	
export const kTimeMsgMarketA50Open	= 9;				// 차이나 A50 개시
export const kTimeMsgMarketA50Close	= 10;				// 차이나 A50 종료
export const kTimeMsgMarketDAXOpen	= 11;				// 독일DAX 개시
export const kTimeMsgMarketMax	= 12;
export const g_szTimeMsgCode = [
	// kTimeMsgMarketOpen = 0,				// 주간장 개시
	'',
	// kTimeMsgMarketOpenOverSea			// 해외선물 개시
	'해외선물',
	// kTimeMsgMarketClose,				// 장종료
	'',
	// kTimeMsgMarketDayClose,				// 주간장 종료
	'주간선물',
	// kTimeMsgMarketNightOpen,			// 야간장 개시
	'야간선물',
	// kTimeMsgMarketHangsengOpen,			// 항셍 오전장 개시
	'항셍지수:오전',
	// kTimeMsgMarketHangsengClose,		// 항셍 오전장 종료
	'항셍지수:오전',
	// kTimeMsgMarketHangseng2Open,		// 항셍 오후장 오픈
	'항셍지수:오후',
	// kTimeMsgMarketHangseng2Close,		// 항셍 오후장 종료	
	'항셍지수:오후',
	// kTimeMsgMarketA50Open,				// 차이나 A50 개시
	'차이나A50',
	// kTimeMsgMarketA50Close,				// 차이나 A50 종료
	'차이나A50',
	// 	kTimeMsgMarketDAXOpen,				// 독일DAX 개시
	'독일DAX',
	// kTimeMsgMarketMax
];

// 매매 구분
export const G_TRADE_SELL	= 0;	// 매도
export const G_TRADE_BUY = 1;					// 매수
export const G_TRADE_MAX = 2;
// 매매 구분
export const g_szTradeTypeName = [
	// 	G_TRADE_SELL			= 0,	// 매도
	'매도',
	// 	G_TRADE_BUY,					// 매수
	'매수',
	// 	G_TRADE_MAX
	'',
];

// 거래가격 유형
export const G_PRICE_MARKET	= 0;	// 시장가
export const G_PRICE_APPOINT = 1;			// 지정가
export const G_PRICE_MAX = 2;
export const g_szPriceTypeName = [
	// 	G_PRICE_MARKET		= 0,	// 시장가
	'시장가',
	// 	G_PRICE_APPOINT,				// 지정가
	'지정가',
	// 	G_PRICE_MAX
];

// 실시간 데이터 타입
export const G_REALTYPE_CURRENT	= 0;	// 현재가
export const G_REALTYPE_ASKING = 1;				// 호가
export const G_REALTYPE_MAX = 2;

// 장외거래(Over-the-counter trading) 종류
export const eOverTrading_Overnight = 0;	// 오버나잇
export const eOverTrading_Settlement = 1;		// 동시청산
export const eOverTrading_Payment = 2;			// 만기결제
export const eOverTrading_Max = 3;
// 장외거래(Over-the-counter trading) 종류
export const g_szOverTradingName = [
	// eOverTrading_Overnight = 0,	// 오버나잇
	'오버나잇',
	// eOverTrading_Settlement,		// 동시청산
	'동시청산',
	// eOverTrading_Payment,			// 만기결제
	'만기결제',
	// eOverTrading_Max
	'',
];

// 오버나잇 신청상태
export const eOvernight_No = 0;	// 오버나잇 신청하지 않음.
export const eOvernight_Request = 1;			// 오버나잇 신청
export const eOvernight_Cancel = 2;			// 오버나잇 취소(유저)
export const eOvernight_Refused = 3;			// 오버나잇 거절(평가담보금 부족)
export const eOvernight_Granted = 4;			// 승인
export const eOvernight_Max = 5;
// 오버나잇 신청상태
export const g_szOvernightStateName = [
	// eOvernight_No = 0,	// 오버나잇 신청하지 않음.
	'미사용',
	// eOvernight_Request,			// 오버나잇 신청
	'신청',
	// eOvernight_Cancel,			// 오버나잇 취소(유저)
	'취소',
	// eOvernight_Refused,			// 오버나잇 거절(평가담보금 부족)
	'거절',
	// eOvernight_Granted,			// 승인
	'승인',
	// eOvernight_Max
];

// 주문 포지션
export const C_ORDERPOS_NO			= 0;		// 주문상태 없음
export const C_ORDERPOS_SELL = 1;					// 매도 포지션
export const C_ORDERPOS_BUY = 2;						// 매수 포지션
export const C_ORDERPOS_MAX = 3;

// 주문방법
export const C_ORDERMETHOD_GridClick = 0;	// 	그리드 클릭		
export const C_ORDERMETHOD_MouseDrag = 1;			// 	마우스드래그		* 주문요청(OrdR)에서는 사용안함
export const C_ORDERMETHOD_CancelSellBtn = 2;		// 	취소매도버튼		
export const C_ORDERMETHOD_CancelBuyBtn = 3;			// 	취소매수버튼		
export const C_ORDERMETHOD_MarketSellBtn = 4;		// 	시장가매도버튼		
export const C_ORDERMETHOD_MarketBuyBtn = 5;			// 	시장가매수버튼		
export const C_ORDERMETHOD_LimitSellBtn = 6;			//	지정가매도버튼	
export const C_ORDERMETHOD_LimitBuyBtn = 7;			//	지정가매수버튼	
export const C_ORDERMETHOD_BatchSettleBtn = 8;		// 	현종목청산버튼		
export const C_ORDERMETHOD_BatchSettleAllBtn = 9;	// 	일괄청산버튼		
export const C_ORDERMETHOD_MIT = 10;					// 	MIT		
export const C_ORDERMETHOD_StopLoss = 11;				// 	스탑로스		
export const C_ORDERMETHOD_OppositeTrade = 12;		// 	서버반대매매		
export const C_ORDERMETHOD_LossCut = 13;				// 	로스컷		
export const C_ORDERMETHOD_MarketClose = 14;			// 	장종료		
export const C_ORDERMETHOD_Admin = 15;				// 	관리자	
export const C_ORDERMETHOD_ServerTrade = 16;			//	서버매매
export const C_ORDERMETHOD_TradeMerge = 17;			//	주문병합
export const C_ORDERMETHOD_ServerClose = 18;			//	서버종료
export const C_ORDERMETHOD_BatchCancel = 19;			//	현종목취소버튼
export const C_ORDERMETHOD_BatchCancelAll = 20;		//	일괄취소버튼
export const C_ORDERMETHOD_SystemError = 21;			//	시스템오류
export const C_ORDERMETHOD_Merge = 22;				//	주문 정정(취소 후 병합)
export const C_ORDERMETHOD_CancelBtn = 23;            //    거래내역그리드 취소버튼
export const C_ORDERMETHOD_SellBtn = 24;              //    거래내역그리드 청산버튼
export const C_ORDERMETHOD_StopLossCancelBtn = 25;    //    거래내역그리드 스탑로스 취소
export const C_ORDERMETHOD_MitCancelBtn = 26;			//    거래내역그리드 MIT 취소
export const C_ORDERMETHOD_OverTradingSettle = 27;	//  동시호가청산
export const C_ORDERMETHOD_OverTradingPayment = 28;	//  만기결제청산
export const C_ORDERMETHOD_Overnight = 29;			//  오버나잇
export const C_ORDERMETHOD_SLReserved = 30;			//  S/L예약
export const C_ORDERMETHOD_MobileOverall = 31;		//  통합주문(MTS,WTS)
export const C_ORDERMETHOD_Max = 32;
// 주문방법
export const g_szOrderMethodName = [
	// 	C_ORDERMETHOD_GridClick		= 0,	// 	그리드 클릭		
	'그리드 클릭',
	// 	C_ORDERMETHOD_MouseDrag,			// 	마우스드래그		* 주문요청(OrdR)에서는 사용안함
	'마우스드래그',
	// 	C_ORDERMETHOD_CancelSellBtn,		// 	취소매도버튼		
	'취소매도버튼 클릭',
	// 	C_ORDERMETHOD_CancelBuyBtn,			// 	취소매수버튼		
	'취소매수버튼 클릭',
	// 	C_ORDERMETHOD_MarketSellBtn,		// 	시장가매도버튼		
	'시장가매도버튼 클릭',
	// 	C_ORDERMETHOD_MarketBuyBtn,			// 	시장가매수버튼		
	'시장가매수버튼 클릭',
	// 	C_ORDERMETHOD_LimitSellBtn,			//	지정가매도버튼	
	'지정가매도버튼 클릭',
	// 	C_ORDERMETHOD_LimitBuyBtn,			//	지정가매수버튼	
	'지정가매수버튼 클릭',
	// 	C_ORDERMETHOD_BatchSettleBtn,		// 	현종목청산버튼		
	'현종목청산버튼 클릭',
	// 	C_ORDERMETHOD_BatchSettleAllBtn,	// 	일괄청산버튼		
	'일괄청산버튼 클릭',
	// 	C_ORDERMETHOD_MIT,					// 	MIT		
	'MIT',
	// 	C_ORDERMETHOD_StopLoss,				// 	스탑로스		
	'스탑로스',
	// 	C_ORDERMETHOD_OppositeTrade,		// 	서버반대매매		
	'서버반대매매',
	// 	C_ORDERMETHOD_LossCut,				// 	로스컷		
	'로스컷',
	// 	C_ORDERMETHOD_MarketClose,			// 	장종료		
	'장종료',
	// 	C_ORDERMETHOD_Admin,				// 	관리자	
	'관리자',
	// 	C_ORDERMETHOD_ServerTrade,			//	서버매매
	'서버매매',
	// 	C_ORDERMETHOD_TradeMerge,			//	주문병합
	'주문병합',
	// 	C_ORDERMETHOD_ServerClose,			//	서버종료
	'서버종료',
	// 	C_ORDERMETHOD_BatchCancel,			//	현종목취소버튼
	'현종목취소버튼',
	// 	C_ORDERMETHOD_BatchCancelAll,		//	일괄취소버튼
	'일괄취소버튼',
	// 	C_ORDERMETHOD_SystemError,			//	시스템오류
	'시스템오류',
	// 	C_ORDERMETHOD_Merge,				//	주문 정정(취소 후 병합)
	'주문병합',
	// 	C_ORDERMETHOD_CancelBtn,            //    거래내역그리드 취소버튼
	'취소버튼클릭',
	// 	C_ORDERMETHOD_SellBtn,              //    거래내역그리드 청산버튼
	'청산버튼클릭',
	// 	C_ORDERMETHOD_StopLossCancelBtn,    //    거래내역그리드 스탑로스 취소
	'스탑로스취소',
	// 	C_ORDERMETHOD_MitCancelBtn,			//    거래내역그리드 MIT 취소
	'MIT취소',
	// 	C_ORDERMETHOD_OverTradingSettle,	//  동시호가청산
	'동시호가청산',
	// 	C_ORDERMETHOD_OverTradingPayment,	//  만기결제청산
	'만기결제청산',
	// 	C_ORDERMETHOD_Overnight,			//  오버나잇
	'오버나잇',
	//	C_ORDERMETHOD_SLReserved,			//  S/L예약
	'S/L예약',
	//	C_ORDERMETHOD_MobileOverall,		//  통합주문(MTS,WTS)
	'통합주문(MTS,WTS)',
	// 	C_ORDERMETHOD_Max
];

// 주문수정구분
export const C_ORDERMODIFY_AddAmount = 0;	//  수량 추가
export const C_ORDERMODIFY_SubAmount = 1;			//	수량 감소
export const C_ORDERMODIFY_ModifyPrice = 2;			//	가격 정정
export const C_ORDERMODIFY_Cancel = 3;				//	주문 취소
export const C_ORDERMODIFY_BatchSettle = 4;			//	일괄 청산(시장가 매매요청)
export const C_ORDERMODIFY_Max = 5;
export const g_szOrderModifyName = [
	// 	C_ORDERMODIFY_AddAmount		= 0,	//  수량 추가
	'수량 추가',
	// 	C_ORDERMODIFY_SubAmount,			//	수량 감소
	'수량 감소',
	// 	C_ORDERMODIFY_ModifyPrice,			//	가격 정정
	'가격 정정',
	// 	C_ORDERMODIFY_Cancel,				//	주문 취소
	'주문 취소',
	// 	C_ORDERMODIFY_BatchSettle,			//	일괄 청산(시장가 매매요청)
	'일괄 청산',
	// 	C_ORDERMODIFY_Max
];

// 주문접수 상태
export const C_ORDERACCEPT_Acceptance = 0;	// 접수
export const C_ORDERACCEPT_Modified = 1;				// 정정
export const C_ORDERACCEPT_Canceled = 2;				// 취소
export const C_ORDERACCEPT_Finished = 3;				// 완료
export const C_ORDERACCEPT_Remain = 4;				// 체결
export const C_ORDERACCEPT_Settle = 5;				// 완료(청산)
export const C_ORDERACCEPT_CanceledMerge = 6;		// 취소(병합)
export const C_ORDERACCEPT_ModifiedOvernight = 7;	// 오버나잇 수정
export const C_ORDERACCEPT_StopLoss = 8;				// 스탑로스 수정
export const C_ORDERACCEPT_Max = 9;
export const g_szOrderAcceptName = [
	// 	C_ORDERACCEPT_Acceptance	= 0,	// 접수
	'접수',
	// 	C_ORDERACCEPT_Modified,				// 정정
	'정정',
	// 	C_ORDERACCEPT_Canceled,				// 취소
	'취소',
	// 	C_ORDERACCEPT_Finished,				// 완료
	'완료',
	// 	C_ORDERACCEPT_Remain,				// 체결
	'체결',
	// 	C_ORDERACCEPT_Settle,				// 완료(청산)
	'완료(청산)',
	// 	C_ORDERACCEPT_CanceledMerge,		// 취소(병합)
	'취소(병합)',
	//	C_ORDERACCEPT_ModifiedOvernight,	// 오버나잇 수정
	'장외거래수정',
	//	C_ORDERACCEPT_StopLoss,				// 스탑로스 수정
	'S/L수정',
	// 	C_ORDERACCEPT_Max
];

// 체결방법
export const C_CONTRACMETHOD_MarketPrice = 0;	// 시장가
export const C_CONTRACMETHOD_Appoint = 1;				// 지정가
export const C_CONTRACMETHOD_OppositeTrade = 2;			// 서버반대매매
export const C_CONTRACMETHOD_LossCut = 3;				// 로스컷
export const C_CONTRACMETHOD_MarketClose = 4;			// 장종료
export const C_CONTRACMETHOD_Admin = 5;					// 관리자
export const C_CONTRACMETHOD_ServerClose = 6;			// 서버종료
export const C_CONTRACMETHOD_BatchSettleBtn = 7;			// 일괄청산버튼
export const C_CONTRACMETHOD_SettleBtn = 8;				// 그리드 청산버튼 클릭
export const C_CONTRACMETHOD_OverTrading = 9;			// 장외거래(동시호가청산, 만기결제청산)
export const C_CONTRACMETHOD_Overnight = 10;				// 오버나잇
export const C_CONTRACMETHOD_Max = 11;
// 체결방법
export const g_szContractedMethodName = [
	// 	C_CONTRACMETHOD_MarketPrice		= 0,	// 시장가
	'시장가',
	// 	C_CONTRACMETHOD_Appoint,				// 지정가
	'지정가',
	// 	C_CONTRACMETHOD_OppositeTrade,			// 서버반대매매
	'서버반대매매',
	// 	C_CONTRACMETHOD_LossCut,				// 로스컷
	'로스컷',
	// 	C_CONTRACMETHOD_MarketClose,			// 장종료
	'장종료',
	// 	C_CONTRACMETHOD_Admin,					// 관리자
	'관리자',
	// 	C_CONTRACMETHOD_ServerClose,			// 서버종료
	'서버종료',
	// 	C_CONTRACMETHOD_BatchSettleBtn,			// 일괄청산버튼
	'일괄청산버튼',
	//	C_CONTRACMETHOD_SettleBtn,				// 그리드 청산버튼 클릭
	'청산버튼',
	//	C_CONTRACMETHOD_OverTrading,			// 장외거래(동시호가청산, 만기결제청산)
	'체결방법 - 장외거래',
	//	C_CONTRACMETHOD_Overnight,				// 오버나잇
	'체결방법 - 오버나잇',
	// 	C_CONTRACMETHOD_Max
];

// 입출금 종류
export const C_TRANS_DEPOSIT = 0;	// 입금
export const C_TRANS_WITHDRAW = 1;			// 출금
export const C_TRANS_TRANSFER = 2;			// 대체
export const C_TRANS_EVENT = 3;				// 이벤트 입금
export const C_TRANS_ADMIN_DEPOSIT = 4;		// 관리자 입금
export const C_TRANS_ADMIN_WITHDRAW = 5;		// 관리자 출금
export const C_TRANS_MAX = 6;
export const g_szTransTypeName = [ 
	// 	C_TRANS_DEPOSIT		= 0,	// 입금
	'입금',
	// 	C_TRANS_WITHDRAW,				// 출금
	'출금',
	// 	C_TRANS_TRANSFER,				// 대체
	'대체',
	// 	C_TRANS_EVENT,				// 이벤트 입금
	'이벤트 입금',
	// 	C_TRANS_ADMIN_DEPOSIT,		// 관리자 입금
	'관리자 입금',
	// 	C_TRANS_ADMIN_WITHDRAW,		// 관리자 출금
	'관리자 출금',
	// 	C_TRANS_MAX
];

// 이벤트 타입
export const C_EVENT_PROFITKING = 0;					// 수익왕
export const C_EVENT_PROFITRATE = 1;					// 수익율
export const C_EVENT_RECOMMANDER = 2;				// 추천인
export const C_EVENT_LOSS = 3;						// 손실금
export const C_EVENT_FIRSTDEPO = 4;					// 첫입금
export const C_EVENT_TERMDEPO = 5;					// 특정시간입금
export const C_EVENT_NOLIMITDEPO = 6;				// 무한입금
export const C_EVENT_SIGNUP = 7;						// 가입축하
export const C_EVENT_MAX = 8;
// 이벤트 타입
export const g_szEventTypeName = [
	// 	C_EVENT_PROFITKING,					// 수익왕
	'수익왕이벤트',
	// 	C_EVENT_PROFITRATE,					// 수익율
	'수익률이벤트',
	// 	C_EVENT_RECOMMANDER,				// 추천인
	'추천인이벤트',
	// 	C_EVENT_LOSS,						// 손실금
	'손실금이벤트',
	// 	C_EVENT_FIRSTDEPO,					// 첫입금
	'첫입금이벤트',
	// 	C_EVENT_TERMDEPO,					// 특정시간입금
	'특정시간입금이벤트',
	// 	C_EVENT_NOLIMITDEPO,				// 무한입금
	'무한입금이벤트',
	// 	C_EVENT_SIGNUP,						// 가입축하
	'가입축하이벤트',
	// 	C_EVENT_MAX
];

// 입출금처리 상태
export const C_TRANSSTATE_REQ = 0;	// 신청
export const C_TRANSSTATE_CANCEL = 1;		// 취소
export const C_TRANSSTATE_DONE = 2;			// 완료
export const C_TRANSSTATE_DUPLICATE = 3;		// 중복
export const C_TRANSSTATE_WAIT = 4;			// 대기
export const C_TRANSSTATE_MAX = 5;
export const g_szTransStateName = [
	// 	C_TRANSSTATE_REQ	= 0,	// 신청
	'신청',
	// 	C_TRANSSTATE_CANCEL,		// 취소
	'취소',
	// 	C_TRANSSTATE_DONE,			// 완료
	'완료',
	// 	C_TRANSSTATE_DUPLICATE,		// 중복
	'중복',
	// 	C_TRANSSTATE_WAIT,			// 대기
	'대기',
	// 	C_TRANSSTATE_MAX
];

// 유저 로그 발생 위치
export const eLogEventPos_No = 0;		// 표시 안함
export const eLogEventPos_ClientIntOrd = 1;	// 통합호가클릭주문
export const eLogEventPos_Server = 2;		// 체결서버
export const eLogEventPos_Menubar = 3;		// 메뉴툴바
export const eLogEventPos_Max = 4;
// 로그 발생위치
export const g_szLogEventPosName = [
	//eLogEventPos_No = 0,		// 표시 안함
	'',
	//eLogEventPos_ClientIntOrd,	// 통합호가클릭주문
	'통합호가클릭주문',
	//eLogEventPos_Server,		// 체결서버
	'체결서버',
	//eLogEventPos_Menubar,		// 메뉴툴바
	'메뉴툴바',
	//eLogEventPos_Max
];

// 일괄처리 종류
export const C_BATCH_OrderSellCancel = 0;	// 주문매도취소
export const C_BATCH_OrderBuyCancel = 1;				// 주문매수취소
export const C_BATCH_MITSellCancel = 2;				// MIT매도취소
export const C_BATCH_MITBuyCancel = 3;				// MIT매수취소
export const C_BATCH_OrderCancel = 4;				// 주문일괄취소		* MIT, StopLoss, 주문취소
export const C_BATCH_Sattle = 5;						// 일괄청산			* MIT, StopLoss, 주문취소, 체결(반대매매)처리
export const C_BATCH_Max = 6;
// 일괄처리 종류
export const g_szBatchCmdName = [
	// 	C_BATCH_OrderSellCancel,			// 주문매도취소
	'매도주문취소',
	// 	C_BATCH_OrderBuyCancel,				// 주문매수취소
	'매수주문취소',
	// 	C_BATCH_MITSellCancel,				// MIT매도취소
	'MIT매도취소',
	// 	C_BATCH_MITBuyCancel,				// MIT매수취소
	'MIT매수취소',
	// 	C_BATCH_OrderCancel,				// 주문일괄취소		* MIT, StopLoss, 주문취소
	'주문일괄취소',
	// 	C_BATCH_Sattle,						// 일괄청산			* MIT, StopLoss, 주문취소, 체결(반대매매)처리
	'일괄청산',
	// 	C_BATCH_Max
];

// 국내/해외
export const eNation_Domestic = 0;
export const eNation_Oversea = 1;
export const eNation_Max = 2;
export const g_szNationName = [
	//	eNation_Domestic = 0,
	'국내',
	//	eNation_Oversea,
	'해외',
	//	eNation_Max
	'국내/해외',
];

// 선물 가격 - 25만원
export const G_FUTURE_PRICE	= 250000;

// 공지 종류
export const cNotice_Normal = 0;	// 일반
export const cNotice_Mustread = 1;			// 필독
export const cNotice_Urgent = 2;				// 긴급
export const cNotice_Board = 3;				// 전광판
export const cNotice_Max = 4;

// S/L 예약 요청
export const eSLReserved_Request = 0;			//  S/L 예약 신청
export const eSLReserved_Modify = 1;			//  S/L 예약 수정
export const eSLReserved_Cancel = 2;			//  S/L 예약 취소(유저)
export const eSLReserved_Max = 3;
// S/L예약 신청상태
export const g_szSLReservedName =[
	//	eSLReserved_Request = 0,			//  S/L 예약 신청
	'신청',
	//	eSLReserved_Modify,			//  S/L 예약 수정
	'수정',
	//	eSLReserved_Cancel,			//  S/L 예약 취소(유저)
	'취소',
	//	eSLReserved_Max
];

// 매체
export const cMedia_HTS = 0;
export const cMedia_MTS = 1;
export const cMedia_WTS = 2;
export const cMedia_Max = 3;
export const g_szMediaName = [
	//	cMedia_HTS = 0,
	'HTS',
	//	cMedia_MTS,
	'MTS',
	//	cMedia_WTS,
	'WTS',
	//	cMedia_Max
];

export const CHART_LAYOUT_BUTTON_ID_PREFIX = "custom_tv_btn_resolution_";