import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/pc/popup-close.png'


const _hoisted_1 = {
  key: 0,
  class: "popupSet"
}
const _hoisted_2 = { class: "changePopup" }
const _hoisted_3 = { class: "liveAlarm Notice" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "title" }

import useBaseComponent from "../../baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'PcPopupLiveAlarm',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive, watch } = useBaseComponent();

const props = __props;

watch(props, (isShowPopup) => {
    if (isShowPopup) {
        initLiveAlarm();
    } else {
        clearLiveAlarm();
    }
},{ deep: true });

let state = reactive({
    dataArray: [] as any,
    lastTiemstamp: 0,
})

// onMounted(() => {
// });
// onUnmounted(()=> { 
// })

const initLiveAlarm = () => {
    store.DialogState.m_LogMsg.forEach((item: any)=> {
        if(item.timestamp > state.lastTiemstamp) {
            state.lastTiemstamp = item.timestamp;
            state.dataArray.push(item);                
        }
    });
}

const clearLiveAlarm = () => {
    state.dataArray = [];
    state.lastTiemstamp = 0;
}

const emit = __emit;

const close = () => { 
    emit("close"); 
};


return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", { class: "title-box flex-center-center" }, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text" }, "실시간 알람", -1)),
              _createElementVNode("img", {
                class: "close",
                src: _imports_0,
                alt: "",
                onClick: close
              })
            ]),
            _createElementVNode("table", null, [
              _cache[1] || (_cache[1] = _createElementVNode("tr", null, [
                _createElementVNode("th", { class: "col-2 left" }, "시간"),
                _createElementVNode("th", { class: "col-8 right" }, "알림 메세지")
              ], -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).dataArray, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", { key: i }, [
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(item.szRegTime), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(item.szMessage), 1)
                  ])
                ]))
              }), 128))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})