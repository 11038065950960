<template>
    <div class="wrap">
        <Header :text="'거래내역'" />
        <div class="tradeList innerHeight">
            <div class="con-box">
                <div class="top-box">
                    <div class="bank-account">
                        <div class="dropdown-sort">
                            <div class="select-sort">
                                <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickAccOpen" />
                                <label for="dropPageCount" class="for-dropsort pageCount">
                                    <p class="out">{{ state.pickAccDesc }}<i class="icon-down" :class="[{ on: state.pickAccOpen }]" /></p>
                                </label>
                                <div class="section-sort" v-if="state.pickAccValue">
                                    <p v-for="(item, index) in state.pickAccItems" :key="index" @click="onPickAccChangeValue(item)" class="text" :class="{ active: item.value === state.pickAccValue }">
                                        {{ item.desc }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="bank-password">
                        <input type="password" placeholder="계좌비밀번호" v-model="state.password" :readonly="state.bAccPassed" />
                    </div> -->
                    <div class="btn-box">
                        <button class="inquire" @click="onSearchPress()">
                            <span class="text">조회</span>
                        </button>
                    </div>
                </div>

                <!-- <div class="dropdown-sort page-count">
                    <div class="select-sort">
                        
                    </div>
                </div> -->
                
                <div class="mid-box">
                    <div class="big-box">
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">국내예탁금</p>
                            </div>
                            <div class="white-box">
                                <p class="text">{{ toLocaleString(state.depoAmount) }}</p>
                            </div>
                        </div>
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">국내평가예탁금</p>
                            </div>
                            <div class="white-box bdrn">
                                <p class="text">{{ toLocaleString(state.estimatedAmount) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="big-box">
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">해외예탁금</p>
                            </div>
                            <div class="white-box">
                                <p class="text">{{ toLocaleString(state.depoAmountOversea) }}</p>
                            </div>
                        </div>
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">해외평가예탁금</p>
                            </div>
                            <div class="white-box bdrn">
                                <p class="text">{{ toLocaleString(state.estimatedAmountOversea) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="big-box three">
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">총평가손익</p>
                            </div>
                            <div class="white-box">
                                <p class="text">{{ toLocaleString(state.totalProfit) }}</p>
                            </div>
                        </div>
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">국내실현손익</p>
                            </div>
                            <div class="white-box">
                                <p class="text">{{ toLocaleString(state.netDomestic) }}</p>
                            </div>
                        </div>
                        <div class="small-box">
                            <div class="sky-box">
                                <p class="text">해외실현손익</p>
                            </div>
                            <div class="white-box">
                                <p class="text">{{ toLocaleString(state.netOversea) }}</p>
                            </div>
                        </div>
                    </div>
                    <p class="caution">*본 화면은 실시간이 아닌 조회 시점 기준의 정보입니다.</p>
                </div>
            </div>
            <div class="bottom-box">
                <!-- <div class="tab-area"> 
                    <div class="tab" :class="[{ on: state.tabIdx === item.value }, `no`+ item.value]" v-for="(item) in state.tabList" :key="item.value" @click="onTabPress(item.value)">
                        <p class="text">{{ item.title }}</p>
                    </div>
                </div> -->
                <div class="body-box subPage transactionForm tradeList">
                    <div class="con-box bottom">
                        <div class="left index">
                            <div class="trade-list-box">
                                <PcTradeTable :initBAccPAssed="state.bAccPassed"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="info-box fill-log" v-if="state.tabIdx === Order_Contract">
                    <div class="info-title">
                        <div class="left">
                            <div class="box rectangle mb-line">
                                <p class="text">거래종목</p>
                            </div>
                            <div class="box rectangle mlr-line mb-line">
                                <p class="text">매매구분</p>
                            </div>
                            <div class="box rectangle mb-line" >
                                <p class="text">보유체결</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">평균단가</p>
                            </div>
                            <div class="box rectangle mlr-line">
                                <p class="text">평가손익</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">실현손익</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="box square">
                                <p class="text">청산</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-item-scroll">
                        <div class="info-title item" v-for="(item, idx) in state.renderOppositeList" :key="idx">
                            <div class="left">
                                <div class="box rectangle mb-line">
                                    <p class="text">{{item.name}}</p>
                                </div>
                                <div class="box rectangle mlr-line mb-line">
                                    <p class="text">{{ item.tradeType }}</p>
                                </div>
                                <div class="box rectangle mb-line">
                                    <p class="text">{{ item.amount }}</p>
                                </div>
                                <div class="box rectangle">
                                    <p class="text">{{ item.price }}</p>
                                </div>
                                <div class="box rectangle mlr-line">
                                    <p class="text">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.profit / store.AccountState.iLeverageEuro) : toLocaleString(item.profit / store.AccountState.iLeverage) }}</p>
                                </div>
                                <div class="box rectangle">
                                    <p class="text">{{ item.futures >= Common.G_FUTURES_OVERSEAS ? toLocaleString(item.net) : toLocaleString(item.net) }}</p>
                                </div>
                            </div>
                            <div class="right">
                                <div class="box square">
                                    <button class="settlement" @click="onActionPress(item.order)">
                                        <span class="text">청산</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="info-box unfill-log" v-else-if=" state.tabIdx === Order_Left">
                    <div class="info-title">
                        <div class="left">
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">거래종목</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">매매구분</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">주문번호</p>
                            </div>
                            <div class="box rectangle mb-line">
                                <p class="text">주문량</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">주문가격</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">가격유형</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">접수상태</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">체결량</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="box square">
                                <p class="text">청산</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-item-scroll">
                        <div class="info-title item" v-for="(item, idx) in state.renderLeftsList" :key="idx">
                            <div class="left">
                                <div class="box rectangle mb-line mr-line">
                                    <p class="text">{{ item.name }}</p>
                                </div>
                                <div class="box rectangle mb-line mr-line">
                                    <p class="text">{{ item.tradeType }}</p>
                                </div>
                                <div class="box rectangle mb-line mr-line">
                                    <p class="text">{{ item.orderIdx }}</p>
                                </div>
                                <div class="box rectangle mb-line">
                                    <p class="text">{{ item.amount }}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{ item.price }}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{ item.priceType }}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{ item.state }}</p>
                                </div>
                                <div class="box rectangle">
                                    <p class="text">{{ item.oppAmount }}</p>
                                </div>
                            </div>
                            <div class="right">
                                <div class="box square">
                                    <button class="settlement" @click="onActionPress(item.order)">
                                        <span class="text">취소</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- 거래내역 -->
                <!-- <div class="info-box trade-log" v-else-if="state.tabIdx === Order_Log">
                    <div class="info-title top">
                        <div class="left">
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">거래종목</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">매매구분</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">주문번호</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">주문량</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">평균단가</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">{{store.SystemState._appMode === 2 ? '레버리지' : '오버나잇'}}</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">수수료</p>
                            </div>
                            <div class="box rectangle  mb-line">
                                <p class="text">평가금액</p>
                            </div>
                        </div>
                        <div class="left">
                            <div class="box rectangle mr-line">
                                <p class="text">주문가격</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">가격유형</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">접수상태</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">체결량</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">체결시간</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">손익</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">총손익</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">보유잔고</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-item-scroll" >
                        <div class="info-title item" v-for="(item, idx) in state.renderLogList" :key="idx">
                            <div class="left">
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.name}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.tradeType}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.orderIdx}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.amount}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.averagePrice}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{store.SystemState._appMode === 2 ? item.leverage : item.overTrading}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.fee}}</p>
                                </div>
                                <div class="box rectangle mb-line">
                                    <p class="text">{{item.evalProfit}}</p>
                                </div>
                            </div>
                            <div class="left">
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.price}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.priceType}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.acceptState}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.contAmount}}</p>
                                </div>
                                <div class="box rectangle mr-line rotate-wrap">
                                    <p class="text rotate-text">{{item.contractDate}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.profit}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.totalProfit}}</p>
                                </div>
                                <div class="box rectangle ">
                                    <p class="text">{{item.depoAmount}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- S/L내역 -->
                <!-- <div class="info-box sl-log" v-else-if="state.tabIdx === Order_SL">
                    <div class="info-title">
                        <div class="left">
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">거래종목</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">상태</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">스탑가격</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">이익틱</p>
                            </div>
                            <div class="box rectangle mr-line mb-line">
                                <p class="text">S/L여부</p>
                            </div>
                            <div class="box rectangle mb-line">
                                <p class="text">등록시간</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">매매구분</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">평균가</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">체결가격</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">손실틱</p>
                            </div>
                            <div class="box rectangle mr-line">
                                <p class="text">주문번호</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">완료시간</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-item-scroll">
                        <div class="info-title item" v-for="(item, idx) in state.renderSLLogsList" :key="idx">
                            <div class="left">
                                <div class="box rectangle mr-line mb-line rotate-wrap">
                                    <p class="text rotate-text">{{item.name}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.state}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.stopPrice}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.stopTicks}}</p>
                                </div>
                                <div class="box rectangle mr-line mb-line">
                                    <p class="text">{{item.slName}}</p>
                                </div>
                                <div class="box rectangle mb-line rotate-wrap">
                                    <p class="text rotate-text">{{item.regDate}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.tradeType}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.averagePrice}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.contractPrice}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.lossTicks}}</p>
                                </div>
                                <div class="box rectangle mr-line">
                                    <p class="text">{{item.orderIdx}}</p>
                                </div>
                                <div class="box rectangle rotate-wrap">
                                    <p class="text rotate-text">{{item.useDate}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!-- <MenuTab /> -->
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive } = useBaseComponent();

import { Theme } from '../constants';
import { NetworkService, Emit_DrawTrans, Emit_TradingLog, Emit_StopLossLog, Emit_Success } from '../network';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import { UserLog } from '../network/UserLog';
import * as Utils from '../utils/Common';
import moment from '../utils/moment';
// import { PassThrough } from "stream";
import * as AccPwd from '../constants/AccPwd';

const Order_All = 99; // 체결
const Order_Contract = 0; // 체결
const Order_Left = 1;   // 미체결
const Order_Log = 2;    // 거래내역
const Order_SL = 3;    // S/L내역
// const Order_Max = 4;

const REFRESH_INTERVAL = 1000;

let state = reactive({

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    tabList: [
        {title: "체결 / 미체결", value: Order_All},
        {title: "체결", value: Order_Contract},
        {title: "미체결", value: Order_Left},
        {title: "거래내역", value: Order_Log},
        {title: "S/L내역", value: Order_SL},
    ],
    tabIdx: Order_Contract,
    selectedItem: null as any,
    
    //체결내역
    oppositeOrders: [] as any,
    renderOppositeList: [] as any,

    //미체결내역
    leftOrders: [] as any,
    renderLeftsList: [] as any,
    
    //거래내역
    orderLogs: [] as any,
    renderLogList: [] as any,
    
    //S/L내역
    stopLossLogs: [] as any,
    renderSLLogsList: [] as any,

    isOversea: Common.eNation_Domestic,
    // 예탁금액
    depoAmount: 0,
    depoAmountOversea: 0,
    // 평가예탁금액
    estimatedAmount: 0,
    estimatedAmountOversea: 0,
    // 총평가손익
    totalProfit: 0,
    // 국내실현손익
    netDomestic: 0,
    // 해외실현손익
    netOversea: 0,

    refreshCount: 0,

    // 계좌 정보 세팅
    timerInitData: null as any,
});

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TradeList');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);

    if (store.LoginPageState.isPacketLoginAckReceived) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_TradingLog, event_TradingLog);
        NetworkService.emitter.on(Emit_StopLossLog, event_StopLossLog);
        initData();
    }
});

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TradingLog, event_TradingLog);
    NetworkService.emitter.off(Emit_StopLossLog, event_StopLossLog);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    state.isOversea = isOversea;

    initAccPicker();

    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        state.refreshCount++;

        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();

    // renderOrders(state.tabIdx, state.refreshCount);
}
const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
        // 비밀번호 인증 패스 여부
        state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    }
    
    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 리스트 업데이트
    refreshOrders();
}

const event_TradingLog = (params: any) => {
    // 거래 로그 수신 완료.
    const TradingLogs = store.AccountState.TradingLogs;
    state.orderLogs = TradingLogs;
    refreshOrders();
}

const event_StopLossLog = (params: any) => {
    // 스탑로스 로그 수신 완료.
    const StopLossLogs = store.AccountState.StopLossLogs;
    state.stopLossLogs = StopLossLogs;
    refreshOrders();
}

const onSearchPress = () => {
    if(!checkAccPwd()) {
        return;
    }

    if (state.selectedItem) {
        state.isOversea = state.selectedItem.bOversea;
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();

        if(state.tabIdx === Order_Contract || state.tabIdx === Order_Left){
            // 체결 / 미체결 리스트 업데이트
            refreshOrders();   
        } else if (state.tabIdx === Order_Log){
            // 거래내역 요청
            tradingLogsReq();
        } else if (state.tabIdx === Order_SL){
            // 스탑로스내역 요청
            stopLossLogsReq();
        }
    }
}

const refreshOrders = () => {
    // 체결 리스트를 얻는다.
    const oppositeOrders = store.GetClientOppositeList();
    //const oppositeOrders = TEST_ORDERS;
    state.oppositeOrders = oppositeOrders;
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;
    state.leftOrders = leftOrders;

    renderOrders();
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const onTabPress = (tabIdx: number) => {
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;

        if(state.bAccPassed){
            onSearchPress();
        }
    }
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

// 거래내역 요청
const tradingLogsReq = () => {
    // 30일 요청
    //const szStartDate = moment(_MarketStore.tOpen[Common.G_FUTURES_OVERSEAS]).format('YYYYMMDD');
    const szStartDate = moment(Date.now()).add(-30, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체
    // 0 = 국내, 1 = 해외, 2 = 국내/해외
    const isOversea = Common.eNation_Max;

    const szUserLog = `[${Common.g_szNationName[isOversea]} 거래내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.transactionListReq({
        futures, 
        isOversea, 
        szStartDate, 
        szEndDate,
    });
}

// 스탑로스내역 요청
const stopLossLogsReq = () => {
    // 30일 요청
    const szStartDate = moment(Date.now()).add(-30, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    const futures = Common.G_FUTURES_MAX; // 전체

    const szUserLog = `[${Common.g_szFuturesNameMini[futures]} S/L내역] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);

    NetworkService.send.stopLossLogReq({
        futures, 
        szStartDate, 
        szEndDate,
    });
}

// 청산 / 취소
const onActionPress = (order: any) => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderPrice = order.dbOrderPrice;
    const orderIdx = order.iOrderIdx;

    // 체결창
    if (state.tabIdx === Order_Contract) {
        // 청산
        if (order.iOppositeAmount === 0) {
            store.setVisibleDialog({ message: '체결된 주문만 청산이 가능합니다.' });
            return;
        }

        // 청산주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_BatchSettle;
        const orderMethod = Common.C_ORDERMETHOD_SellBtn;
        const oppTradeType = (tradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const orderMax = store.AccountState.iOrderMax[futures][oppTradeType];
        if (orderMax < order.iOppositeAmount) {
            store.setVisibleDialog({ message: `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderMax})개 입니다.` });
            return;
        } else {
            UserLog.modifyOrder(futures, tradeType, orderPrice, order.iOppositeAmount, modifyMethod, orderMethod, orderIdx);
            NetworkService.send.modifyOrderReq({
                futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iOppositeAmount, orderMethod,
            });
        }
    } else if (state.tabIdx === Order_Left) {
        // 미체결창
        // 취소
        if (order.iLeftAmount === 0) {
            store.setVisibleDialog({ message: '미체결된 주문만 취소가 가능합니다.' });
            return;
        }

        // 취소주문을 보낸다.
        const modifyMethod = Common.C_ORDERMODIFY_Cancel;
        const orderMethod = Common.C_ORDERMETHOD_CancelBtn;
        UserLog.modifyOrder(futures, tradeType, orderPrice, order.iLeftAmount, modifyMethod, orderMethod, orderIdx);
        NetworkService.send.modifyOrderReq({
            futures, tradeType, orderIdx, modifyMethod, orderPrice, orderAmount: order.iLeftAmount, orderMethod,
        });
    }
}

//체결 리스트
const renderOpposite = (orderList: any) => {
    state.renderOppositeList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);
        const objOrder = {
            order: order,
            futures: order.iFutures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            amount: order.iOppositeAmount,
            profit: profit,
            fee: fee,
            // 2023-10-27: 당일 누적 금액으로 변경
            //net: profit - fee,
            //net: ' / ' + store.AccountState.iRealGainNLossFutures[futures] + ' / ' + store.AccountState.iDepoCommissionFutures[futures]
            //net: store.AccountState.iDepoCommissionFutures[futures]
            net: store.AccountState.iRealGainNLossFutures[futures] - store.AccountState.iDepoCommissionFutures[futures]
        }
        state.renderOppositeList.push(objOrder)
    })
}

//미체결 리스트
const renderLefts = (orderList: any) => {
    state.renderLeftsList = [];
    orderList.forEach((order: any)=> {
        const futures = order.iFutures;
        const objOrder = {
            order: order,
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            priceType: Common.g_szPriceTypeName[order.iPriceType],
            orderIdx: order.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[Common.C_ORDERACCEPT_Acceptance],
            amount: order.iOrderAmount,
            oppAmount: order.iOppositeAmount,
        }
        state.renderLeftsList.push(objOrder)
    })
}

//거래내역
const renderLogs = (orderLog: any) => {
    // TradingLog
    state.renderLogList = [];
    orderLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(log.dbOrderPrice, futures),
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            priceType: Common.g_szPriceTypeName[log.iPriceType],
            orderIdx: log.iOrderIdx,
            acceptState: Common.g_szOrderAcceptName[log.iAcceptState],
            amount: log.iOrderAmount,
            contAmount: log.iContractedAmount,
            averagePrice: Utils.MicroPriceFormatStr(log.dbContractedPrice, futures),   // 평균
            contractDate: log.szContractedDate, // 체결시
            overTrading: log.szOverTrading,
            leverage: log.iLeverage,
            profit: toLocaleString(log.iProfit),
            fee: toLocaleString(log.iCommission),
            totalProfit: toLocaleString(log.iTotalProfit),
            evalProfit: toLocaleString(log.nEvaluateProfit),
            depoAmount: toLocaleString(log.iDepoAmount),
        }
        state.renderLogList.push(objOrder)
    })
}

//SL내역
const renderSLs = (stopLossLog: any) => {
    // TradingLog
    state.renderSLLogsList = [];
    stopLossLog.forEach((log: any)=> {
        const futures = log.iFutures;
        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            tradeType: Common.g_szTradeTypeName[log.iTradeType],
            state: log.szState,
            averagePrice: Utils.MicroPriceFormatStr(log.dbAvePrice, futures),   // 평균가,
            stopPrice: Utils.MicroPriceFormatStr(log.dbStopPrice, futures),   // 스탑가,
            contractPrice: Utils.MicroPriceFormatStr(log.dbContractPrice, futures),   // 체결가,
            stopTicks: log.bUseStop ? `${log.iStopTicks}` : '사용안함',
            stopColor: log.iUsedTick === 1 ? Theme.palette.red : Theme.text.color,
            stopWeight: log.iUsedTick === 1 ? 'bold' : 'normal',
            lossTicks: log.bUseLoss ? `${log.iLossTicks}` : '사용안함',
            lossColor: log.iUsedTick === 2 ? Theme.palette.red : Theme.text.color,
            lossWeight: log.iUsedTick === 2 ? 'bold' : 'normal',
            slName: log.bReserved ? 'S/L' : '',
            orderIdx: log.iOrderIdx >= 0 ? `${log.iOrderIdx}` : '',
            regDate: log.szRegDate, // 등록시간,
            useDate: log.szUseDate, // 완료시긴,
        }
        state.renderSLLogsList.push(objOrder)
    });
}

const renderOrders = () => {
    const orderIdx = state.tabIdx;
    
    if(orderIdx === Order_Contract){
        //체결
        renderOpposite(state.oppositeOrders)
    } else if(orderIdx === Order_Left){
        //미체결
        renderLefts(state.leftOrders)
    } else if(orderIdx === Order_Log) {
        //거래내역
        renderLogs(state.orderLogs)
    } else {
        //SL내역
        renderSLs(state.stopLossLogs);
    }
}

</script>
