import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "Notice innerHeight" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "date" }

import useBaseComponent from "@/baseComponent";
import { EMFJS, RTFJS, WMFJS } from "rtf.js";
import iconv from "iconv-lite";
import * as NetConstants from "../network/Constants";
import { NetworkService, Emit_Success, Emit_NoticeContent } from "@/network";
import * as Constant from '../constants/Global';


export default /*@__PURE__*/_defineComponent({
  __name: 'Notice',
  setup(__props) {

const { router, store, onMounted, onUnmounted, reactive, } = useBaseComponent();
RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

let state = reactive({
    initShowNotice: false,
    initNoticeTitle: "",
    initNoticeDate: "",
    initNoticeContent: "",

    selectedNoticeIdx: 0,

    // timerReqNotice: null as any,
    // regDate: [] as any,
});

onMounted(async () => {
    if (store.LoginPageState.loginState != 1) {
        router.push("/");
        return;
    }
    store.setLastViewPage("Notice");

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_NoticeContent, event_NoticeContent);

    store.NoticeState.notices = [];
    if (store.LoginPageState.isPacketLoginAckReceived){
        NetworkService.send.noticeTitleReq();
        NetworkService.send.noticeTitleReq();
    } 
});

onUnmounted(() => {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_NoticeContent, event_NoticeContent);
    // if(state.timerReqNotice){
    //     clearInterval(state.timerReqNotice);
    // }
});

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        store.NoticeState.notices = [];
        NetworkService.send.noticeTitleReq();
    }
};

const openNotice = (notice: any) => {
    // // 공지 팝업 띄우기 전 항상 이전 공지 내용을 초기화(달라질 수 있음)
    // store.removeNoticeContent(notice.iNoticeIdx);

    // state.initNoticeTitle = "[" + notice.szTypeName + "] " + notice.szTitle;
    state.initNoticeTitle = notice.szTitle;
    state.initNoticeDate = notice.szRegDate;
    state.selectedNoticeIdx = notice.iNoticeIdx;

    //웹소켓을 통해 공지 내용 요청
    NetworkService.send.noticeContentReq({ noticeIdx: notice.iNoticeIdx });
};

const event_NoticeContent = () => {
    const resNoticeContent = store.existNoticeCotent(state.selectedNoticeIdx);    
    if (resNoticeContent.result) {

        //contentBuff 에 내용이 입력이 되었을 경우 -> RTF 변환 후 HTML 로 변환후 출력
        if (resNoticeContent.contentBuff !== null) {
            const rtfText = iconv.decode(
                resNoticeContent.contentBuff,
                NetConstants.NETWORK_STRING_ENCODE
            );
            const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
            const div = document.createElement("div");
            doc.render().then((htmlElements) => {
                div.append(...htmlElements);
                state.initNoticeContent = div.outerHTML;
                state.initShowNotice = true;
                document.body.style.overflow = "hidden";
            })
            .catch((error) => {
                if (store.debugLog >= Constant.DebugLevel_Minimal)
                    console.error(error);
            });
        } else if (resNoticeContent.szContents !== "") {
            //szContent 에 내용이 입력된 경우 그대로 출력
            state.initNoticeContent = resNoticeContent.szContents;
            state.initShowNotice = true;
            document.body.style.overflow = "hidden";
        }
        // clearInterval(state.timerReqNotice);
    }
}


const stringToArrayBuffer = (string: any) => {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
};

const close = (popupType: string) => {
    switch (popupType) {
        case "PopupNotice":
            state.initShowNotice = false;
            document.body.style.overflow = "auto";
            state.initNoticeContent = "";
            state.initNoticeTitle = "";
            break;
    }
};

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!
  const _component_PopupNotice = _resolveComponent("PopupNotice")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header, { text: '공지사항' }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", null, [
          _cache[2] || (_cache[2] = _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "col-2 left" }, "일자/시간"),
            _createElementVNode("th", { class: "col-8 right" }, "공지사항")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).NoticeState.notices, (notice) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: notice.iNoticeIdx,
              onClick: ($event: any) => (openNotice(notice))
            }, [
              _createElementVNode("td", null, [
                _createElementVNode("p", _hoisted_4, [
                  _createTextVNode(_toDisplayString(notice.szRegDate.split(" ")[0]), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                  _createTextVNode(" " + _toDisplayString(notice.szRegDate.split(" ")[1].substring(0, 5)), 1)
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("p", null, _toDisplayString(notice.szTitle), 1)
              ])
            ], 8, _hoisted_3))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_PopupNotice, {
      initShowNotice: _unref(state).initShowNotice,
      initTitle: _unref(state).initNoticeTitle,
      initNoticeDate: _unref(state).initNoticeDate,
      initNoticeMessage: _unref(state).initNoticeContent,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (close('PopupNotice')))
    }, null, 8, ["initShowNotice", "initTitle", "initNoticeDate", "initNoticeMessage"])
  ], 64))
}
}

})