// 멀티바이트 한글
export const NETWORK_STRING_ENCODE = 'euc-kr';
// 수신 버퍼 크기
export const RECEIVE_BUFFSIZE = (1024 * 128);
  
// 클라<->서버간 패킷 ID길이
export const PACKET_CvS_ID_LEN = 4;
  
  // 로그인 결과
  export const LoginResult = {
	  C_LOGIN_SUCCESS: 0,	// 정상
	  C_LOGIN_BLOCK: 1,						// 블럭된 계정
	  C_LOGIN_SECESSION: 2,					// 회원탈퇴 계정
	  C_LOGIN_NO_ID: 3,						// 계정없음
	  C_LOGIN_PWD_ERR: 4,					// 패스워드 불일치
	  C_LOGIN_DUPLICATED: 5,					// 중복 로그인
	  C_LOGIN_TIMEOUT: 6,					// 로그인 시간 초과
	  C_LOGIN_SYSTEM_ERR: 7,					// 시스템 장애
	  C_LOGIN_BLOCKED_IP: 8,					// 차단된 IP
	  C_LOGIN_DORMANCY: 9,					// 휴면계정
	  C_LOGIN_BLOCK_PWDERR: 10,				// 비번오류 블럭된 계정
	  C_LOGIN_CLIENT_VERSION: 11,				// 클라이언트 버전 다음
	  C_LOGIN_ACCTYPE_ERR: 12,				// 계정 타입 에러 실계정 또는 모의(테스트)계정 불일치
	  C_LOGIN_HOLIDAY: 13,					// 주말, 공휴일
	  C_LOGIN_MAINTENANCE: 14,				// 임시점검
  
	  C_LOGIN_MAX: 15
  };
  export const g_szLoginResultName = [ 
	  // 	C_LOGIN_SUCCESS				= 0,	// 정상
	  '로그인 성공',
	  // 	C_LOGIN_BLOCK,						// 블럭된 계정
	  //'블럭된 계정',
	  '\n[고객센터로 문의 바랍니다.]',
	  // 	C_LOGIN_SECESSION,					// 회원탈퇴 계정
	  '회원탈퇴 계정',
	  // 	C_LOGIN_NO_ID,						// 계정없음
	  '없는 계정',
	  // 	C_LOGIN_PWD_ERR,					// 패스워드 불일치
	  '패스워드 불일치',
	  // 	C_LOGIN_DUPLICATED,					// 중복 로그인
	  '중복 로그인',
	  // 	C_LOGIN_TIMEOUT,					// 로그인 시간 초과
	  '로그인 시간초과',
	  // 	C_LOGIN_SYSTEM_ERR,					// 시스템 장애
	  '시스템 장애',
	  // 	C_LOGIN_BLOCKED_IP,					// 차단된 IP
	  '\n[고객센터로 문의 바랍니다.]',
	  // 	C_LOGIN_DORMANCY,					// 휴면계정
	  '휴면계정 입니다.\n\n[고객센터로 문의 바랍니다.]',
	  // 	C_LOGIN_BLOCK_PWDERR,				// 비번오류 블럭된 계정
	  '비번오류로 블럭된 계정입니다.\n\n[고객센터로 문의 바랍니다.]',
	  // C_LOGIN_CLIENT_VERSION
	  'WTS가 최신 버전이 아닙니다.',
	  // C_LOGIN_ACCTYPE_ERR
	  '실투자 또는 모의투자 계정인지 확인하시기 바랍니다.\n\n[실투자 계정은 실투자 로그인, 모의투자 계정은 모의투자 로그인만 가능합니다.]',
	  // C_LOGIN_HOLIDAY,					// 주말, 공휴일
	  '주말, 공휴일은 접속이 불가능합니다.',
	  // C_LOGIN_MAINTENANCE,				// 임시점검
	  '임시점검 중입니다. 나중에 다시 접속하시기 바랍니다.',
	  // 	C_LOGIN_MAX
  ];
  
  // 강제종료 이유
  export const C_ForceExitReason_Admin = 0;		// 관리자 요청
  export const C_ForceExitReason_ServerClose = 1;			// 서버종료
  export const C_ForceExitReason_DuplicatedLogin = 2;		// 중복로그인
  export const C_ForceExieason_BlockedIP = 3;			// 블럭된 IP
  export const C_ForceExitReason_BlockedAccount = 4;		// 블럭된 계정
  export const C_ForceExitReason_Cheating = 5;				// 부정행위
  export const C_ForceExitReason_SystemCheck = 6;			// 시스템 정검
  export const C_ForceExitReason_SystemError = 7;			// 시스템장애
  export const C_ForceExitReason_Max = 8;
  // 강제종료 이유
  export const g_szForceExitReason = [ 
	  // 	C_ForceExitReason_Admin			= 0,		// 관리자 요청
	  '관리자 요청',
	  // 	C_ForceExitReason_ServerClose,			// 서버종료
	  '서버종료',
	  // 	C_ForceExitReason_DuplicatedLogin,		// 중복로그인
	  '중복로그인',
	  // 	C_ForceExitReason_BlockedIP,			// 블럭된 IP
	  '블럭된 IP',
	  // 	C_ForceExitReason_BlockedAccount,		// 블럭된 계정
	  '블럭된 계정',
	  // 	C_ForceExitReason_Cheating,				// 부정행위
	  '부정행위',
	  // 	C_ForceExitReason_SystemCheck,			// 시스템 정검
	  '시스템 정검',
	  // 	C_ForceExitReason_SystemError,			// 시스템장애
	  '시스템장애',
	  // 	C_ForceExitReason_Max
  ];
  
  // 입출금신청 결과
  export const R_DEPONWITHDRAW_SUCCESS = 0;	// 입출금 신청 성공
  export const R_DEPONWITHDRAW_FAIL_EXCEED = 1;		// 실패 - 입금 금액초과
  export const R_DEPONWITHDRAW_FAIL_INSUFF = 2;		// 실패 - 출금 잔액 부족
  export const R_DEPONWITHDRAW_FAIL_AMOUNT = 3;		// 실패 - 입출금 신청금액오류
  export const R_DEPONWITHDRAW_FAIL_DUPLICATED = 4;	// 실패 - 중복된 신청
  export const R_DEPONWITHDRAW_FAIL_SYSTEM = 5;		// 실패 - 시스템 장애
  export const R_DEPONWITHDRAW_FAIL_ADMIN = 6;			// 실패 - 관리자 취소
  export const R_DEPONWITHDRAW_FAIL_WAIT = 7;			// 실패 - 관리자 대기
  export const R_DEPONWITHDRAW_ADMINCONFIRM = 8;		// 관리자 확인
  export const R_DEPONWITHDRAW_MAX = 9;
  export const g_szDepoNWithdrawResultName = [
	  // 	R_DEPONWITHDRAW_SUCCESS		= 0,	// 입출금 신청 성공
	  '입출금신청 성공',
	  // 	R_DEPONWITHDRAW_FAIL_EXCEED,		// 실패 - 입금 금액초과
	  '입출금신청 실패 - 입금 금액초과',
	  // 	R_DEPONWITHDRAW_FAIL_INSUFF,		// 실패 - 출금 잔액 부족
	  '입출금신청 실패 - 출금 잔액 부족',
	  // 	R_DEPONWITHDRAW_FAIL_AMOUNT,		// 실패 - 입출금 신청금액오류
	  '입출금신청 실패 - 신청금액오류',
	  // 	R_DEPONWITHDRAW_FAIL_DUPLICATED,	// 실패 - 중복된 신청
	  '입출금신청 실패 - 중복된 신청',
	  // 	R_DEPONWITHDRAW_FAIL_SYSTEM,		// 실패 - 시스템 장애
	  '입출금신청 실패 - 시스템 장애',
	  // 	R_DEPONWITHDRAW_FAIL_ADMIN,			// 실패 - 관리자 취소
	  '입출금신청 실패 - 관리자 취소',
	  // 	R_DEPONWITHDRAW_FAIL_WAIT,			// 실패 - 관리자 대기
	  '입출금신청 실패 - 관리자 대기',
	  // 	R_DEPONWITHDRAW_ADMINCONFIRM,		// 관리자 확인
	  '관리자 확인',
	  // 	R_DEPONWITHDRAW_MAX
  ];
  
  // 비밀번호변경 신청 결과
  export const R_CHNGPWD_SUCCESS = 0;	// 성공
  export const R_CHNGPWD_FAIL_WRONGPWD = 1;		// 기존 비번이 틀리다.
  export const R_CHNGPWD_FAIL_WRONGFMT = 2;		// 새 비번이 길거나 짧다.
  export const R_CHNGPWD_FAIL_SYSTEM = 3;			// 시스템 장애
  export const R_CHNGPWD_MAX = 4;
  export const g_szRChngPwdName = [
	  // 	R_CHNGPWD_SUCCESS		= 0,	// 성공
	  '비번변경 성공',
	  // 	R_CHNGPWD_FAIL_WRONGPWD,		// 기존 비번이 틀리다.
	  '비번변경 실패 - 비번불일치',
	  // 	R_CHNGPWD_FAIL_WRONGFMT,		// 새 비번이 길거나 짧다.
	  '비번변경 실패 - 잘못된형식',
	  // 	R_CHNGPWD_FAIL_SYSTEM,			// 시스템 장애
	  '비번변경 실패 - 시스템장애',
	  // 	R_CHNGPWD_MAX
  ];
  
  // 레버리지 변경요청 결과
  export const R_CHNGLEVERAGE_SUCCESS = 0;	// 성공
  export const R_CHNGLEVERAGE_FAIL_WRONG = 1;			// 요청된 레버리지는 적용불가능(없는 레버리지)
  export const R_CHNGLEVERAGE_FAIL_ORDERING = 2;		// 주문 요청 중이라 변경불가
  export const R_CHNGLEVERAGE_FAIL_SYSTEM = 3;			// 시스템 장애
  export const R_CHNGLEVERAGE_MAX = 4;
  export const g_szRChngLeverageName = [
	  // 	R_CHNGLEVERAGE_SUCCESS		= 0,	// 성공
	  '레버리지변경 성공',
	  // 	R_CHNGLEVERAGE_FAIL_WRONG,			// 요청된 레버리지는 적용불가능(없는 레버리지)
	  '레버리지변경 실패 - 적용불가능',
	  // 	R_CHNGLEVERAGE_FAIL_ORDERING,		// 주문 요청 중이라 변경불가
	  '레버리지변경 실패 - 주문 요청중',
	  // 	R_CHNGLEVERAGE_FAIL_SYSTEM,			// 시스템 장애
	  '레버리지변경 실패 - 시스템장애',
	  // 	R_CHNGLEVERAGE_MAX
  ];
  
  // 주문에대한 결과
  export const R_ORDER_Success = 0;	// 	성공	
  export const R_ORDER_FailWrongOrder = 1;			// 	실패 - 잘못된 주문
  export const R_ORDER_FailWrongAmount = 2;		// 	실패 - 잘못된 수량	
  export const R_ORDER_FailWrongPrice = 3;			// 	실패 - 잘못된 주문가격	
  export const R_ORDER_FailInsuffBalance = 4;		// 	실패 - 잔액부족	
  export const R_ORDER_FailInsuffAmount = 5;		// 	실패 - 수량한도 부족	
  export const R_ORDER_FailWrongOrderIdx = 6;		//	실패 - 잘못된 주문번호
  export const R_ORDER_FailSystem = 7;				// 	실패 - 시스템 장애
  export const R_ORDER_Max = 8;
  export const g_szOrderResultName = [
	  // 	R_ORDER_Success			= 0,	// 	성공
	  '주문신청 성공',
	  // 	R_ORDER_FailWrongOrder,			// 	실패 - 잘못된 주문
	  '주문신청 실패 - 잘못된 주문',
	  // 	R_ORDER_FailWrongAmount,		// 	실패 - 잘못된 수량	
	  '주문신청 실패 - 잘못된 수량',
	  // 	R_ORDER_FailWrongPrice,			// 	실패 - 잘못된 주문가격	
	  '주문신청 실패 - 잘못된 주문가격',
	  // 	R_ORDER_FailInsuffBalance,		// 	실패 - 잔액 부족	
	  '주문신청 실패 - 잔액 부족',
	  // 	R_ORDER_FailInsuffAmount,		// 	실패 - 수량한도 부족	
	  '주문신청 실패 - 수량한도 부족',
	  // 	R_ORDER_FailWrongOrderIdx,		//	실패 - 잘못된 주문번호
	  '주문신청 실패 - 잘못된 주문번호',
	  // 	R_ORDER_FailSystem,				// 	실패 - 시스템 장애
	  '주문신청 실패 - 시스템 장애',
	  // 	R_ORDER_Max
  ];
  
  // 오버나잇 요청 결과
  export const r_Overnight_Success = 0;
  export const r_Overnight_Cancellation_Success = 1;	// 취소 성공
  export const r_Overnight_FailSystem = 2;			// 서버 실패
  export const r_Overnight_WrongOrder = 3;		// 잘못된 주문번호
  export const r_Overnight_1DaysBefore = 4;		// 해외선물 마감 1일전이 지나 신청 불가
  export const r_Overnight_Deadline = 5;		// 장종료 3분전이 지나 신청불가
  export const r_Overnight_WrongState = 6;		// 오버나잇 상태가 잘못됐다.
  export const r_Overnight_Exceed = 7;			// 평가금액 초과
  export const r_Overnight_CancelByContract = 8;	// 체결에 의한 취소
  export const r_Overnight_CancelBySettle = 9;	// 청산에 의한 취소
  export const r_Overnight_PaymentNotAllow = 10;		// 만기결제는 만기에 가능
  export const r_Overnight_Max = 11;
  
  // S/L 예약 요청 결과
  export const r_SLReserved_Success = 0;
  export const r_SLReserved_Cancellation_Success = 1;	// 취소 성공
  export const r_SLReserved_FailSystem = 2;			// 서버 실패
  export const r_SLReserved_Request = 3;			// 잘못된 요청
  export const r_SLReserved_WrongOrder = 4;		// 잘못된 주문번호
  export const r_SLReserved_WrongState = 5;		// 요청 상태가 잘못됐다.
  export const r_SLReserved_Max = 6;
  