import * as Common from '../constants/Common';

export class TransferLog {
  iLogIdx = Common.INDEX_NONE;			// 로그코드
  iDepoNWithdraw = Common.C_TRANS_DEPOSIT;			// 입금출금(0 입금, 1 출금, 2 이벤트 입금), C_TRANS_DEPOSIT ~ C_TRANS_ADMIN_WITHDRAW
  szRequestDate = '';		// 신청일시
  szProcessDate = '';		// 처리일시
  iTotalDepoAmount = 0;		// 총입금액
  iTotalWithdrawAmount = 0;	// 총출금액
  iTodayDepoAmount = 0;		// 금일입금액
  iTodayWithdrawAmount = 0;	// 금일출금액
  iBeforeDepositAmount = 0;			// 적용이전 입금잔고
  iBeforeWithdrawAmount = 0;			// 적용이전 출금잔고
  iDepoNWithdrawAmount = 0;	// 입출금액
  iAfterDepositAmount = 0;			// 적용이후 입금잔고
  iAfterWithdrawAmount = 0;			// 적용이후 출금금잔고
  iState = Common.C_TRANSSTATE_REQ;					// 처리상태(신청, 취소, 완료, 중복)
  szRequestName = '';		// 입출금인
  iEventType = Common.C_EVENT_MAX;				// 이벤트타입 (0 수익왕, 1 수익율, 2 추천인, 3 손실금)
  szMemo = '';				// 메모
  szDomesticOversea = '';	// 국내해외
  szEventName = '';	// 이벤트이름, iEventType
}
