// 차트 조회건수
export const cChartData_Count =	900;  //1000
// 차트 조회 최대
export const cChartData_Max	= 1000; //2000

// 차트 기간
export const cPeriod_None = -1;
export const cPeriod_Tick = 0;
//export const cPeriod_Second = 1;
export const cPeriod_Minute = 1;
//export const cPeriod_Hour = 2;
export const cPeriod_Daily = 2;
export const cPeriod_Weekly = 3;
export const cPeriod_Monthly = 4;
//export const cPeriod_Yearly = 5;
export const cPeriod_Max = 5;
//
export const szPeriodName = [
  // cPeriod_None = -1;
  // cPeriod_Tick = 0;
  '틱',
  // cPeriod_Minute = 1;
  '분',
  // cPeriod_Daily = 2;
  '일',
  // cPeriod_Weekly = 3;
  '주',
  // cPeriod_Monthly = 4;
  '월',
  // cPeriod_Max = 5;
];

// 차트 간격 1 3 5 10 20 30
export const cTerm_None = -1;
export const cTerm_1 = 0;
export const cTerm_3 = 1;
export const cTerm_5 = 2;
export const cTerm_10 = 3;
export const cTerm_20 = 4;
export const cTerm_30 = 5;
export const cTerm_Max = 6;
//
export const TermUnit = [
  // cTerm_None = -1;
  // cTerm_1 = 0;
  1,
  // cTerm_3 = 1;
  3,
  // cTerm_5 = 2;
  5,
  // cTerm_10 = 3;
  10,
  // cTerm_20 = 4;
  20,
  // cTerm_30 = 5;
  30,
  // cTerm_Max = 6;
];
