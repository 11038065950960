import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/header-back.png'


const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["innerHTML"]

import useBaseComponent from "@/baseComponent";

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  props: {
    text: {
        type: String,
        default: "",
    },
    type: {
        type: String,
        default: "",
    },
},
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

const props = __props;

const close = () => {
    if(route.name === 'LiveAlarm') {
        router.push('/m/Setting')
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: _normalizeClass(["back-btn", { 'on': props.type === 'left' }]),
      src: _imports_0,
      alt: "",
      onClick: close
    }, null, 2),
    _createElementVNode("p", {
      innerHTML: props.text
    }, null, 8, _hoisted_2)
  ]))
}
}

})