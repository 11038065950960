import * as AppConfig from '../config/app';
import * as AccPwd from '../constants/AccPwd';

export const SETTINGS_KEY = 'settings_v1';
export const SETTINGS_EX_KEY = 'settingsEx';

export class SettingsModel {
  
  id = undefined;
  
  appPincodeEnabled = false;  
  pincodeCreated = false;  
  debug = false;
  autoLockTime = AppConfig.ReactiveAutoLockDelay;

  keepAwake = true;
  bBuyConfirm = true;
  bSellConfirm = true;
  bModifyConfirm = true;
  bCancelConfirm = true;
  
  bBuyPush = true;  
  bSellPush = true;  
  bRefusePush = true;  
  bDepoWithPush = true;

  accPwdApply = AccPwd.cAccPwdApply_Forever;

  domesticAccPwd = '';
  overseaAccPwd = '';

  bAccPassed = false;
  theme = '';

  constructor(data) {
    if (data) {
      const {
        id,
        
        appPincodeEnabled = false,
        pincodeCreated = false,
        debug = false,
        autoLockTime = AppConfig.ReactiveAutoLockDelay,

        bBuyConfirm = true,
        bSellConfirm = true,
        bModifyConfirm = true,
        bCancelConfirm = true,
      
        bBuyPush = true,
        bSellPush = true,
        bRefusePush = true,      

        bDepoWithPush = true,

        accPwdApply = AccPwd.cAccPwdApply_Forever,
        domesticAccPwd = '',
        overseaAccPwd = '',

        bAccPassed = false,
        theme = '',
      } = data;

      this.id = id;
      
      this.appPincodeEnabled = appPincodeEnabled;
      this.pincodeCreated = pincodeCreated;
      this.debug = debug;
      this.autoLockTime = autoLockTime;

      this.bBuyConfirm = bBuyConfirm;
      this.bSellConfirm = bSellConfirm;
      this.bModifyConfirm = bModifyConfirm;
      this.bCancelConfirm = bCancelConfirm;
    
      this.bBuyPush = bBuyPush;
      this.bSellPush = bSellPush;
      this.bRefusePush = bRefusePush;
    
      this.bDepoWithPush = bDepoWithPush;

      this.accPwdApply = accPwdApply;
      this.domesticAccPwd = domesticAccPwd;
      this.overseaAccPwd = overseaAccPwd;

      this.bAccPassed = bAccPassed;
      this.theme = theme;
    }
  }

  static get placeholder() {
    return new SettingsModel();
  }
}



export class SettingsExModel {

  id = undefined;


  options = {
    forceRestart: false,
    deepLink: {},
    pushReadIdx: 0,
    pushPermission: true,
    pushEnabled: true,
    tokensEnable: {},
    watchingToday: '',
    stoplossToday: ''
  }

  constructor(data) {
    if (data) {
      const {
        id,
        options = {
          forceRestart: false,
          deepLink: {},
          pushReadIdx: 0,
          pushPermission: true,
          pushEnabled: true,
          tokensEnable: {},
          watchingToday: '',
          stoplossToday: ''
        }
      } = data;

      this.id = id;
      this.options = options;
    }
  }

  static get placeholder() {
    return new SettingsExModel();
  }
}
