<template>
    <div class="popupSet" v-if="props.isShowPopup">
        <div class="changePopup">
            <div class="setPassword  Setting changePassword ">
                <div class="title-box flex-center-center">
                    <p class="text ">계좌 비밀번호 변경</p>
                </div>
                <div class="top-box">
                    <div class="bank-account">
                        <div class="dropdown-sort">
                            <div class="select-sort">
                                <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickOpen" />
                                <label for="dropPageCount" class="for-dropsort pageCount">
                                    <p class="out">{{ state.pickDesc }}<i class="icon-down" :class="[{ on: state.pickOpen }]" /></p>
                                </label>
                                <div class="section-sort">
                                    <p v-for="(item, index) in state.accountArray" :key="index" @click="onPickChangeValue(item)" class="text" >
                                        {{ item.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                <div class="bank-password">
                    <input type="password" placeholder="계좌비밀번호" v-model="state.inputPassword" />
                </div>
                <div class="btn-box">
                    <button class="inquire" @click="onSearch()">
                        <span class="text">조회</span>
                    </button>
                </div>
            </div>
                <div class="menu-box" v-if="state.isPasswordChecked">
                    <p class="title">계좌비밀번호 일괄저장</p>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" id="allCheck" >
                                    <label for="allCheck"><i class="checkBox on" /></label>
                                </th>
                                <th>계좌번호</th>
                                <th>계좌명</th>
                                <th>계좌비밀번호</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, i) in state.accountArray" :key="i">
                                <td>
                                    <input type="checkbox" :id="`no`+i" @change="onAccountChecked(i)">
                                    <label :for="`no`+i"><i class="checkBox" :class="{'on': item.checked }"/></label>
                                </td>
                                <td>{{ item.id }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.pwd }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="save-box" v-if="state.isPasswordChecked">
                    <div class="input-box">
                        <div class="left">
                            <p class="text">계좌비밀번호 변경</p>
                        </div>
                        <div class="right">
                            <input type="password" placeholder="비밀번호를 입력해주세요" v-model="state.inputNewPassword1">
                        </div>
                    </div>
                    <div class="input-box mt10">
                        <div class="left">
                            <p class="text">계좌비밀번호 확인</p>
                        </div>
                        <div class="right">
                            <input type="password" placeholder="비밀번호를 입력해주세요" v-model="state.inputNewPassword2">
                        </div>
                    </div>
                    <div class="radio-wrap">
                        <label class="label-radio first">
                            <input type="radio" value="0" v-model="state.selectChange" @change="toggleSelect">
                                모든 계좌 변경
                        </label>
                        <label class="label-radio">
                            <input type="radio" value="1" v-model="state.selectChange" @change="toggleSelect" disabled>
                                선택 계좌 변경
                        </label>
                    </div>
                </div>
                <p class="caution">*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.</p>
                <p class="caution">*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.</p>
                <p class="caution">*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능  이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.</p>
                <div class="btn-box">
                    <button class="cancel" @click="close">
                        <span class="text">취소</span>
                    </button>
                    <button class="ok" @click="ok   ">
                        <span class="text">확인</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "../../baseComponent";
const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
import { NetworkService, Emit_AccountPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
});


let state = reactive({
    accountHeaderChecked: 1,
    accountArray: [
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
            arrayIdx: 0,
        },
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
            arrayIdx: 1,
        }
    ],

    inputPassword: '',

    passwordUpdateType:0,
    inputNewPassword1: '',
    inputNewPassword2: '',

    isPasswordChecked: false,



    templateIdx:0,
    inputRoot:"",
    screenOnCheck:false,//프로그램시작시 계좌비밀번호 일괄저장 화면 띄우기
    savePassword:false,

    exList2: [
        {
            string: "해외예탁금",
            number: "94,225,626",
        },
        {
            string: "계좌번호",
            number: "102,225,626",
        },
        {
            string: "해외평가예탁금",
            number: "102,225,626",
        },
        {
            string: "해외평가예탁금",
            number: "102,225,626",
        },
    ],
    selectChange:0,
    accountName:[
        { value: 0, text: "02-13322-31 [김병목 / durkts123] (국내)" },
    ],
    selectedAccountNameIdx:0,
    //example
    pickOpen: false,
    pickDesc: '계좌를 선택하세요',
    pickValue: '',
    pickItems: [] as any,

    accPwdApply: null as any,
})

onMounted(() => {
    NetworkService.emitter.on(Emit_AccountPasswordUpdate, event_AccountPasswordResult );

    state.accountArray[0].id = store.AccountState.szDepoAccNo;
    state.accountArray[1].id = store.AccountState.szDepoAccNoOversea;
    state.accountArray[0].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Domestic]})`;
    state.accountArray[1].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Oversea]})`;
    state.accountArray[0].pwd = store.AccountState.szDomesticAccPwd ? "****" : "";
    state.accountArray[1].pwd = store.AccountState.szOverseaAccPwd ? "****" : "";
    
    state.accPwdApply = store.SettingsState.settings.accPwdApply;
 });
onUnmounted(()=> { NetworkService.emitter.off(Emit_AccountPasswordUpdate, event_AccountPasswordResult ); })
const event_AccountPasswordResult = (params: any) => {
    const { nResult, szMsg } = params;
    if(nResult=== NetConstants.R_CHNGPWD_SUCCESS) {
        close();
    }
    else {
        // PacketService 에서 처리중
    }
}; 

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const onSearch = () => {
    let errorMessage = '';
    let isChecked = false;
    if(state.pickValue + '' === '0') isChecked = store.AccountState.szDomesticAccPwd === '' || state.inputPassword === store.AccountState.szDomesticAccPwd;
    if(state.pickValue + '' === '1') isChecked = store.AccountState.szOverseaAccPwd === '' || state.inputPassword === store.AccountState.szOverseaAccPwd;;

    state.isPasswordChecked = isChecked;

    if( ! isChecked) {
        if( ! state.inputPassword) {
            errorMessage = '비밀번호를 입력해주세요.';
        }
        else {
            errorMessage = '비밀번호가 일치하지 않습니다.';
        }
    }
    
    if(errorMessage)
        alert(errorMessage);
}

const toggleSelect = () => {
    //console.log(state.selectChange);
}

const emit = defineEmits(["close", "ok"]);

const close = () => { 
    state.inputPassword = '';
    state.inputNewPassword1 = '';
    state.inputNewPassword2 = '';
    state.isPasswordChecked = false;
    state.pickValue = '';
    emit("close"); 
};
const ok = () => { 
    let errorMessage = '';
    if( !state.inputNewPassword1 || !state.inputNewPassword2) {
        errorMessage = '비밀번호를 입력해주세요';
    }
    else if(state.inputNewPassword1 != state.inputNewPassword2) {
        errorMessage = '비밀번호가 일치하지 않습니다.';
    }

    if(errorMessage)
        alert(errorMessage);
    else
        NetworkService.send.newAccPwdReq({ DomesticPwd: state.inputNewPassword1, OverseaPwd: state.inputNewPassword1 });
};

const onPickChangeValue = (item:any) => {
    state.inputPassword = '';
    state.pickOpen = false;
    state.pickDesc = item.name;
    state.pickValue = item.arrayIdx;
}

</script>
