<template>
    <div class="popupPc" v-if="props.isShowPopup && props.initLeftOrders?.length > 0">
    <!-- <div class="popupPc" > -->
        <div class="popupPc-inner leftOrder">
            <div class="title-box flex-center-center">
                <p class="text ">미체결 조회</p>
			</div>
            <div class="table-box">
                <div class="table-head">
                    <div class="item name">
                        <p>거래종목</p>
                    </div>
                    <div class="item type">
                        <p>구분</p>
                    </div>
                    <div class="item amount">
                        <p>주문량</p>
                    </div>
                    <div class="item price">
                        <p>주문가</p>
                    </div>
                    <div class="item status">
                        <p>미체결</p>
                    </div>
                </div>
                <div class="table-body"  v-for="(order, idx) in props.initLeftOrders" :key="idx" :id="'list' + idx" @click="selectLeftItem(Number(idx), order)" :class="{'blue': state.selectedIdx === Number(idx) }">
                    <div class="item name bdr">
                        <p>{{ Common.g_szFuturesNameMini[order.iFutures] }}</p>
                    </div>
                    <div class="item type bdr">
                        <p>{{ Common.g_szTradeTypeName[order.iTradeType]}}</p>
                    </div>
                    <div class="item amount bdr">
                        <p>{{ order.iOrderAmount }}</p>
                    </div>
                    <div class="item price bdr">
                        <p>{{ Utils.PriceFormatStr(order.dbOrderPrice, order.iFutures) }}</p>
                    </div>
                    <div class="item status ">
                        <p>{{ order.iLeftAmount }}</p>
                    </div>
                </div>
            </div>
            <!-- <table>
                <tr class="noTouch">
                    <th>거래종목</th>
                    <th>구분</th>
                    <th>주문량</th>
                    <th>주문가</th>
                    <th>미체결</th>
                </tr>
                <tr v-for="(order, idx) in props.initLeftOrders" :key="idx" :id="'list' + idx" @click="selectLeftItem(Number(idx), order)" :class="{'blue': state.selectedIdx === Number(idx) }">
                    <td class="what">{{ Common.g_szFuturesNameMini[order.iFutures] }}</td>
                    <td class="division">{{ Common.g_szTradeTypeName[order.iTradeType]}}</td>
                    <td class="quantity">{{ order.iOrderAmount }}</td>
                    <td class="price">{{ Utils.PriceFormatStr(order.dbOrderPrice, order.iFutures) }}</td>
                    <td class="no">{{ order.iLeftAmount }}</td>
                </tr>
            </table> -->
            <button @click="onCorrectSelectedPress()">확인</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits, defineProps, onMounted, reactive } = useBaseComponent();

import * as Common from '../../constants/Common';
import * as Utils from '../../utils/Common';

const props = defineProps({
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initLeftOrders: {
        type: Object,
        defualt: []
        // default(){
        //     return []
        // }
    }
});

const state = reactive({
    selectedIdx: -1,
    selectedOrder: {} as any
});

const emit = defineEmits(["onCorrectSelectedPress"]);

const onCorrectSelectedPress = () => {

    emit("onCorrectSelectedPress", {selectedIdx: state.selectedIdx, selectedOrder: state.selectedOrder});
    state.selectedIdx = -1;
    state.selectedOrder = {};

    // props.initLeftOrders?.forEach((item: any, index: number) => {
    //     delClass('list' + index, 'blue');
    // });
};

const selectLeftItem = (idx: number, order: any) => {

    if(state.selectedIdx === idx){
        //선택 해제한 경우
        state.selectedIdx = -1;
        state.selectedOrder = {};
        return;
    } else {
        //미체결 건을 선택한 경우
        state.selectedIdx = idx;
        state.selectedOrder = order;

    }
    
    // if(document.getElementById('list' + idx)?.classList.contains('blue') === false){
    //     props.initLeftOrders?.forEach((item: any, index: number) => {
    //         delClass('list' + index, 'blue');
    //     });

    //     addClass('list' + idx, 'blue');
    // } else {
    //     delClass('list' + idx, 'blue');
    // };
};

const addClass = (id: string, classe: string) => {
    try {
        let element = document.getElementById(id);
        let classes = element?.className.split(" ") as string[];
        let getIndex = classes.indexOf(classe);
        if (getIndex === -1) {
            classes.push(classe);
            if (element) element.className = classes.join(" ");
        }
    } catch (err) {
        console.error(err);
    }
};

const delClass = (id: string, classe: string) => {
    try {
        let element = document.getElementById(id);
        let classes = element?.className.split(" ") as string[];
        let getIndex = classes.indexOf(classe);
        if (getIndex > -1) {
            classes.splice(getIndex, 1);
        }
        if (element) element.className = classes.join(" ");
    } catch (err) {
        console.error(err);
    }
};
</script>
