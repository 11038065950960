import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

import * as AccPwd from '../constants/AccPwd';
import * as Constant from '../constants/Global';
import * as Common from '../constants/Common';
// import * as NetConstants from '../network/Constants';
import * as Utils from '../utils/Common';
import moment from '../utils/moment';

import { StopLossObj } from '../models/StopLoss';
import { ChatRoomObj } from '../models/ChatRoomObj';
// import { ChatMsg } from '../models/ChatMsg';
import { PushLogObj } from '../models';

import { SettingsModel, SettingsExModel } from '../db';
import SettingsService from '../services/SettingsService';


const M_MESSAGE_LIST_NUM_MAX = 20;

const APP_INITIALIZED = 'APP_INITIALIZED';
const APP_LANGUAGE = 'APP_LANGUAGE';
const APP_PINCODE_ENABLE = 'APP_PINCODE_ENABLE';
const APP_BIOMATRICS_ENABLE = 'APP_BIOMATRICS_ENABLE';
const APP_EMERGENCY_LAUNCH = 'APP_EMERGENCY_LAUNCH';
const APP_LAST_UPDATES_VERSION = 'APP_LAST_UPDATES_VERSION';
const APP_FONT_SCALE = 'APP_FONT_SCALE';
const APP_DEBUG_LOG = 'APP_DEBUG_LOG';
const APP_LOGIN_INFO = 'APP_LOGIN_INFO';
const APP_LOGIN_ERROR = 'APP_LOGIN_ERROR';
const APP_ACCOUNT_POPUP_CHECK = 'APP_ACCOUNT_POPUP_CHECK';
const APP_LAST_VIEW_PAGE = 'APP_LAST_VIEW_PAGE';
const APP_NOTICE_HIDE_TODAY_1 = 'APP_NOTICE_HIDE_TODAY_1';
const APP_NOTICE_HIDE_TODAY_2 = 'APP_NOTICE_HIDE_TODAY_2';
const APP_CHAT_LAST_OPEN_TIME = 'APP_CHAT_LAST_OPEN_TIME';
const APP_LAST_FUTURES_SELECTED = 'APP_LAST_FUTURES_SELECTED';

export const APP_SETTING_EX = 'APP_SETTING_EX';

const TV_LAYOUT_BASE = 'TV_LAYOUT_BASE';
const TV_LAYOUT_STUDY = 'TV_LAYOUT_STUDY';
const TV_LAYOUT_DRAWING = 'TV_LAYOUT_DRAWING';
const TV_LAYOUT_CHART = 'TV_LAYOUT_CHART';
const TV_LAYOUT_INTERVAL = 'TV_LAYOUT_INTERVAL';

export interface themeMode {
    mode: string;
}

export const useStore = defineStore("root-store", {
    state: () => ({

        AccountState: {
            // 유저 정보
            szUserID : '',
            szUserPwd : '',
            szBrandName : process.env.VUE_APP_BRAND_NAME?.toString(), // 브랜드 이름
            bMock : false,            // 모의투자
            iUserType : 0,        // 사용자 구분
            iGrade : 0,            // 회원등급
            iIDType : 0,        // 계정 형태
            szNick : '',            // 필명
            szName : '',            // 이름
            szBankHolder : '',    // 예금주
            szBank : '',            // 은행명
            szAccountNo : '',    // 계좌번호
            szDomesticAccPwd : '',    // 국내계좌비밀번호
            szOverseaAccPwd : '',    // 해외계좌비밀번호
            iDomesticAccPwdErrCount : 0,    // 국내계좌비번 오류 횟수
            iOverseaAccPwdErrCount : 0,    // 해외계좌비번 오류 횟수

            bAccPassed : false,        // 계좌 비번 입력 여부

            // 예탁계좌
            bReadDepoAcc : false,    // 예탁계좌 정보를 DB에서 읽었는지 여부 (서버용)
            szDepoAccType : '',    // 계좌구분(예탁)
            szDepoAccNo : '',    // 계좌번호(예탁)
            szDepoAccNoOversea : '',    // 해외 계좌번호(예탁)
            iLeverage : 0,        // 레버리지
            iLeverageEuro : 0,    // 유로 레버리지
            iDepoAmountWhenOpen : 0,    // 개장시 예탁총액
            iDepoAmountOverseaWhenOpen : 0,    // 개장시 예탁총액 해외
            iRealGainNLoss : 0,    // 실현손익
            iRealGainNLossTotal : 0, // 총 누적 실현손익
            iRealGainNLossFutures : new Array(Common.G_FUTURES_MAX),        // 실현손익선물
            iRealGainNLossFuturesTotal : new Array(Common.G_FUTURES_MAX),    // 실현손익 누적
            iDepositMoney : 0,        // 입금액
            iDepositNums : 0,        // 입금횟수
            iEventDepositMoney : 0,        // 이벤트입금액
            iEventDepositMoneyOversea : 0,        // 이벤트입금액해외
            iWithdrawMoney : 0,    // 출금액
            iDepoCommission : 0,    // 수수료
            iDepoCommissionTotal : 0,    // 총 누적 수수료
            iDepoCommissionFutures : new Array(Common.G_FUTURES_MAX),    // 수수료선물
            iDepoCommissionFuturesTotal : new Array(Common.G_FUTURES_MAX),    // 수수료 누적
            iDepoAmount : 0,    // 예탁총액
            iDepoAmountOversea : 0,    // 예탁총액
            iDeopAmountLeverage : 0,    // 레버리지 반영 금액
            iDeopAmountOverseaLeverage : 0,    // 레버리지 반영 금액 해외

            // 주문 정보
            // 주문 포지션
            iOrderPos : new Array(Common.G_FUTURES_MAX).fill(0),
            //평균 단가
            dAverage : new Array(Common.G_FUTURES_MAX).fill(0.0),
        // INT
            dProfit : new Array(Common.G_FUTURES_MAX).fill(0), //보유종목 평가손익.
            // 주문(또는 계약된) 총수량
            iOrderedAmount : Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0),
            // 주문(계약) 최대수량
            iContractMax : new Array(Common.G_FUTURES_MAX).fill(0),
            // 잔고 대비 남은 주문 계약 수량 NetAgent.cpp 에서 체크하여 추가 주문을 금지시킨다.
            iOrderMax : Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0),

            // 주문 리스트
            // [G_FUTURES_MAX][G_TRADE_MAX]
            // OrderObj Map
            FuturesOrders : Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, []),
            // StopLoss
            // StopLossObj
            FuturesStopLoss : new Array(Common.G_FUTURES_MAX).fill({}).map(v => new StopLossObj()),
            // MIT
            // MITObj
            // [G_FUTURES_MAX][G_TRADE_MAX]
            FuturesMIT : Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, []),

            // 주문창 종목
            G_Future_Code : Common.G_FUTURES_MAX,
            // 차트창 종목
            ChartFutures : Common.G_FUTURES_MAX,

            // 마지막 채팅시간
            stChatTime: new Date(),
            bChatPopup: false,

            // 채팅내역
            ChatLogTotalCount: 0,
            ChatLogCurCount: 0,
            ChatLogs: [] as any,

            // 거래내역
            TradingLogTotalCount : 0,
            TradingLogCurCount : 0,
            TradingLogs : [],

            // 입출금로그
            // 입/출금: 0, 대체: 1
            TransferLogTotalCount : [0, 0],
            TransferLogCurCount : [0, 0],
            TransferLogs : new Array(2).fill('').map(v => []),

            // 스탑로스로그
            StopLossLogTotalCount : 0,
            StopLossLogCurCount : 0,
            StopLossLogs : [],

            accounts : [],

            fetched : false,

            // 로그인완료, 앱 간편인증(핀코드, 바이오) 완료
            // @observable
            _appAuthenticated : false,

        },
        BrandState: {
            //브랜드 정보
            bEarningEvent : false,		//수익왕 이벤트 사용
            bEarningRateEvent : false,	//수익률 이벤트 사용
            bRecomEvent : false,		//추천인 이벤트 사용
            bLossEvent : false,		//손실금 이벤트 사용
            leverages: [] as any,
            iMaxLeverage : 0,
            szBrandLeverageInfo : '',	//브랜드 레버리지 정보
            szBrandAccountName : '',	//입금 예금주
            szBrandAccountBank : '',	//입금 은행명
            szBrandAccountNo : '',	//입금 계좌번호
            bBrandUseBank : false,	// 은행 사용여부
            szBrandBankMessage : '',	// 계좌가 없을 때 기본 메세지
            szBrandWithdrawO : '',	//출금시작
            szBrandWithdrawC : '',	//출금종료
            szBrandChatO : '',	//채팅시작
            szBrandChatC : '',	//채팅종료
            szTel : '',	// 고객센터 전화번호

            isRent : false,	// 대여HTS
            ExchangeRate : 0.0, // 환율
            ExchangeRateHKD : 0.0, // 환율 HKD

            // 국내/해외 선물 증거금
            iDomesticFuturePrice : 0,
            iOverseaFuturePrice : 0,
            // 국내/해외 오버 담보금
            iDomesticOvernightDeposit : 0,
            iOverseaOvernightDeposit : 0,
            // 오버나잇 마감 전
            iOvernightMinBefore : 0,
            // 동시호가, 만기결제 담보금
            iOverTradingDeposit : 0,

            // 채팅설정
            bExternalChat: 0,
            szTelegramUrl: '',
            szKakaoUrl: '',

            G_TICK_PRICE : new Array(Common.G_FUTURES_MAX),
            G_MICRO_TICK_PRICE : new Array(Common.G_FUTURES_MAX),

        },
        ChatRoomState: {
            m_ChatRoom : new ChatRoomObj(),

        },
        CommonState: {   
            isLoginPage: false, 
            isChangePwdSetting: false,
            themeMode: useStorage("themeMode", String),
            toastVisible:false,
            toastMessage:"",
            toastIsTypeWarning:false,
            toastDuration: 2000,
            toastCurrTimer: null as any,
            resizeDeviceType:useStorage("deviceType", Number),

            isShowNotice: false,
            isShowSetting: false,
            isShowLeverage: false,
        },
        DialogState: {
            // 종합창 로그
            // PushLogObj
            m_LogMsg : [] as PushLogObj[],

        },
        MarketState: {
            //거래가능 여부
            bExchangeable : new Array(Common.G_FUTURES_MAX), // 거래가능 여부
            bTodayOpen : new Array(Common.G_FUTURES_MAX), //금일장 개장 여부

            szFutureCode : new Array(Common.G_FUTURES_MAX), //종목코드
            szFutureName : new Array(Common.G_FUTURES_MAX), //종목명
            // COleDateTime
            tOpen : new Array(Common.G_FUTURES_MAX), //장개장시간
            tClose : new Array(Common.G_FUTURES_MAX), //장종료시간
            tTradeStart : new Array(Common.G_FUTURES_MAX), //거래시작시간
            tTradeEnd : new Array(Common.G_FUTURES_MAX), //거래종료시간
            tBuyOnOpening : new Array(Common.G_FUTURES_MAX), // 동시호가 시작시간
            szFutureExpireDate : new Array(Common.G_FUTURES_MAX),
            iFutureExpireLeftDay : new Array(Common.G_FUTURES_MAX),    // 잔존일
            // 오버나잇 가능여부
            bOvernightOpenToday : new Array(Common.G_FUTURES_MAX),

            bUseBreak1 : new Array(Common.G_FUTURES_MAX),
            tBreak1Start : new Array(Common.G_FUTURES_MAX), // 휴식1시간 시작
            tBreak1End : new Array(Common.G_FUTURES_MAX), // 휴식1시간 종료
            bUseBreak2 : new Array(Common.G_FUTURES_MAX),
            tBreak2Start : new Array(Common.G_FUTURES_MAX), // 휴식2시간 시작
            tBreak2End : new Array(Common.G_FUTURES_MAX), // 휴식2시간 종료

            dbCommission : new Array(Common.G_FUTURES_MAX), //운용수수료
            iMaxContract : new Array(Common.G_FUTURES_MAX), // 최대 계약

            // 개별 수수료 
            bIndivCommission : false,
            // G_FUTURES_MAX
            dbIndivCommission : new Array(Common.G_FUTURES_MAX),
            // 개인별 최대 계약수
            bIndivMaxContract : false,
            iDomesticMaxContract : 0,
            iOverseaMaxContract : 0,

            // 로스컷 %
            iLosscutDomestic : 0,
            iLosscutOversea : 0,

            // 동시호가 시간 (분단위)
            // INT
            tBuyOnOpeningMinute : 0,

            // 메뉴 사용 여부
            // G_FUTURES_MAX
            bDrawMenu : new Array(Common.G_FUTURES_MAX),

        },
        MasterState: {
            isReadyRECV : false, //호가 및 체결 받을 준비

            // G_FUTURES_MAX
            isRecvHoga : new Array(Common.G_FUTURES_MAX), // 마스터코드를 받고 첫번째 호가 수신여부
            isRecvContract : new Array(Common.G_FUTURES_MAX), // 마스터코드를 받고 첫번째 체결가 수신여부
            dbYDiffPrice : new Array(Common.G_FUTURES_MAX), //전일대비
            szYDiffSign : new Array(Common.G_FUTURES_MAX), //전일대비 기호
            dbChgRate : new Array(Common.G_FUTURES_MAX), // 등락율
            dbBPrice : new Array(Common.G_FUTURES_MAX), //기준가
            dbOpenPrice : new Array(Common.G_FUTURES_MAX), //시가
            dbHighPrice : new Array(Common.G_FUTURES_MAX), //고가
            dbLowPrice : new Array(Common.G_FUTURES_MAX), //저가
            k200jisu : 0.0, //KOSPI200
            sbasis : 0.0, //BASIS
            openyak : 0, //코스피 200
            openyakcha : 0, //미결제약정증감
            kasis : 0., // 괴리율
            theoryprice : 0., // 이론가

            // INT
            totq : new Array(Common.G_FUTURES_MAX), // 누적체결수량
            value : new Array(Common.G_FUTURES_MAX), // 누적거래대금
            // CString
            ovsmkend : new Array(Common.G_FUTURES_MAX), // 장마감일

            szCTime : new Array(Common.G_FUTURES_MAX), //체결시간
            szOrgTime : new Array(Common.G_FUTURES_MAX), //체결시간 현지
            szOrgDate : new Array(Common.G_FUTURES_MAX), //체결날자 현지
            dbBeforePrice : new Array(Common.G_FUTURES_MAX), //이전 현재가
            dbCurPrice : new Array(Common.G_FUTURES_MAX), //현재가
            iTrdq : new Array(Common.G_FUTURES_MAX), //체결수량
            szCGubun : new Array(Common.G_FUTURES_MAX), //체결구분

            // DOUBLE 
            offerho5 : new Array(Common.G_FUTURES_MAX), //매도5호가
            offerho4 : new Array(Common.G_FUTURES_MAX), //매도4호가
            offerho3 : new Array(Common.G_FUTURES_MAX), //매도3호가
            offerho2 : new Array(Common.G_FUTURES_MAX), //매도2호가
            offerho1 : new Array(Common.G_FUTURES_MAX), //매도1호가

            // DOUBLE 
            bidho1 : new Array(Common.G_FUTURES_MAX), //매수1호가
            bidho2 : new Array(Common.G_FUTURES_MAX), //매수2호가
            bidho3 : new Array(Common.G_FUTURES_MAX), //매수3호가
            bidho4 : new Array(Common.G_FUTURES_MAX), //매수4호가
            bidho5 : new Array(Common.G_FUTURES_MAX), //매수5호가

            // int 
            offerno5 : new Array(Common.G_FUTURES_MAX), //매도5 건수
            offerno4 : new Array(Common.G_FUTURES_MAX), //매도4 건수
            offerno3 : new Array(Common.G_FUTURES_MAX), //매도3 건수
            offerno2 : new Array(Common.G_FUTURES_MAX), //매도2 건수
            offerno1 : new Array(Common.G_FUTURES_MAX), //매도1 건수

            // int 
            bidno1 : new Array(Common.G_FUTURES_MAX), //매수1건수
            bidno2 : new Array(Common.G_FUTURES_MAX), //매수2건수
            bidno3 : new Array(Common.G_FUTURES_MAX), //매수3건수
            bidno4 : new Array(Common.G_FUTURES_MAX), //매수4건수
            bidno5 : new Array(Common.G_FUTURES_MAX), //매수5건수

            // int 
            offerrem1 : new Array(Common.G_FUTURES_MAX), //매도1 수량
            offerrem2 : new Array(Common.G_FUTURES_MAX), //매도2 수량
            offerrem3 : new Array(Common.G_FUTURES_MAX), //매도3 수량
            offerrem4 : new Array(Common.G_FUTURES_MAX), //매도4 수량
            offerrem5 : new Array(Common.G_FUTURES_MAX), //매도5 수량

            // int 
            bidrem1 : new Array(Common.G_FUTURES_MAX), //매수1수량
            bidrem2 : new Array(Common.G_FUTURES_MAX), //매수2수량
            bidrem3 : new Array(Common.G_FUTURES_MAX), //매수3수량
            bidrem4 : new Array(Common.G_FUTURES_MAX), //매수4수량
            bidrem5 : new Array(Common.G_FUTURES_MAX), //매수5수량

            // int 
            totoffercnt : new Array(Common.G_FUTURES_MAX), //매도호가 총 건수
            totofferrem : new Array(Common.G_FUTURES_MAX), //매도호가 총 수량
            totbidcnt : new Array(Common.G_FUTURES_MAX), //매수호가 총 건수
            totbidrem : new Array(Common.G_FUTURES_MAX), //매수호가 총 수량

            // 그리드용
            // int 
            TopRow : new Array(Common.G_FUTURES_MAX), //그리드 Top Row
            fixedRow : new Array(Common.G_FUTURES_MAX), // fixedRow
            //DOUBLE 
            sHoga : new Array(Common.G_FUTURES_MAX), // Row 계산을 위해

        },
        NoticeState: {
            notices : [] as any,
            curCount : 0,
            lastestIdx : 0,
            // 팝업 공지의 iNoticeIdx
            popupIdx : Common.INDEX_NONE,
            fetched : false,

        },
        PincodeState: {
            accountPincode : '',
            appPincode : '',

        },
        SettingsState: {
            settings : SettingsModel.placeholder,
            settingsEx : SettingsExModel.placeholder,
            signinPwd : '',
            loginID: '',

            // 시카고 증권 거래소
            timeGapCME: 0,
            // 뉴욕증권거래소
            timeGapNYMEX: 0,
            // 홍콩 증권거래소
            timeGapHKEX: 0,
            // 싱가포르 증권 거래소
            timeGapSGX: 0,

        },
        SystemState: {
            _initialized : false,
            _version : '',
            _language : '',
            _pincodeEnabled : false,
            _biomatricsEnabled : false,
            _isEmergencyLaunch : false,
            _lastUpdatesVersion : '',
            _fontScale : 1,
            storeInitailized : false,
            visibleDialog : false,
            visibleDialogMessage : '',
            visibleDialogTitle : '',
            visibleDialogCallback : null as any,
            bDialogOkCancel : false,
            _debugLog : Constant.DebugLevel_No,
            _debugChartLog : Constant.DebugLevel_Normal,
            _deviceType: Constant.DEVICE_TYPE_MOBILE,
            _appMode: 1,
            _helpdeskMode: 1,
        },

        LoginPageState: {
            // localstorage
            id: '',
            isChecked1: false,
            isChecked2: false,
            loginState: 0,
            loginFrom: '',
            
            // localstorage
            errorState: 0,
            errorMessage: '',

            // memory cache
            isReloadRequired: false,
            isPacketLoginAckReceived: false,
            isPacketAccInfoReceived: false,
        },

        ChartLayoutStore: {
            initialized: false,
            charts: [] as any,
            studyTemplates: [] as any,
            drawingTemplates: [] as any,
            chartTemplates: [] as any,
            // extra info
            dataIdx: null,
            dataName: '',
            dataResolution: '',
            // interval button
            resolutionArray: [] as any,
            resolutionButton: [] as any,
        },

        HelpDeskStore: {            
            chatArray: [] as any,
            restoredLastTime: ''
        },
    }),
    getters: {

        /*************************** Account Store Getters ***************************/

        appAuthenticated(): any{
            return this.AccountState._appAuthenticated;
        },

        /*****************************************************************************/


        /*************************** Settings Store Getters ***************************/

        settings_initialized(): boolean {
            return Boolean(this.SettingsState.settings.id);
        },

        initializedEx(): boolean {
            return Boolean(this.SettingsState.settingsEx.id);
        },

        /*****************************************************************************/


        /*************************** System Store Getters ***************************/

        system_initialized(): boolean {
            return Boolean(this.SystemState._initialized);
        },

        version(): string {
            return this.SystemState._version;
        },

        language(): string {
            return this.SystemState._language;
        },

        pincodeEnabled(): boolean {
            return this.SystemState._pincodeEnabled;
        },

        biomatricsEnabled(): boolean {
            return this.SystemState._biomatricsEnabled;
        },

        isEmergencyLaunch(): boolean {
            return this.SystemState._isEmergencyLaunch;
        },

        lastUpdatesVersion(): string {
            return this.SystemState._lastUpdatesVersion;
        },

        fontScale(): number {
            return this.SystemState._fontScale;
        },

        debugLog(): any {
            return this.SystemState._debugLog;
        },

        /*****************************************************************************/

    },
    actions: {

        /*************************** Account Store Actions ***************************/

        //   @action
        setAppAuthenticated(authenticated: any) {
            this.AccountState._appAuthenticated = authenticated;
        },

        // 스탑로스 초기화
        InitStopLoss(futures: any) {
            let lpStopLossObj = this.AccountState.FuturesStopLoss[futures];

            // StopLoss 설정 초기화
            lpStopLossObj = new StopLossObj();

            // 로그 등록 응답을 받았을때만 사용 중으로 처리.
            lpStopLossObj.bUse = true;
            lpStopLossObj.iTradeType = Common.G_TRADE_MAX;
            lpStopLossObj.dbOrderPrice = .0;
            lpStopLossObj.iRow = Common.INDEX_NONE;
            lpStopLossObj.drawLine = false;
        },

        // 모든 주문을 지운다.
        RemoveAllClientOrders(Futures: any) {
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
                const lpOrderMap = this.AccountState.FuturesOrders[Futures][tradeType];
                if (lpOrderMap) {
                    lpOrderMap.splice(0);
                }
            }

            return Common.NO_ERROR;
        },

        // 미체결 주문을 지운다.
        RemoveAllClientLeftAmount(Futures: any, TradeType = Common.G_TRADE_MAX) {
            for (let trType = 0; trType < Common.G_TRADE_MAX; trType++) {
                if (TradeType === Common.G_TRADE_MAX || trType === TradeType) {
                    const lpOrderMap = this.AccountState.FuturesOrders[Futures][trType];
                    if (lpOrderMap) {
                    // 체결된 주문만 남겨둔다. iLeftAmount > 0 삭제
                    const contOrders = lpOrderMap.filter((curOrd:any) => curOrd.iLeftAmount === 0);
                    this.AccountState.FuturesOrders[Futures][trType] = contOrders;
                    }
                }
            }

            return Common.NO_ERROR;
        },

        // 총 잔량(반대매매)을 지운다.
        RemoveAllClientOppositeAmount(Futures: any) {
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType ++) {
                const lpOrderMap = this.AccountState.FuturesOrders[Futures][tradeType];
                if (lpOrderMap) {
                    // 체결 주문을 지운다. iOppositeAmount > 0 삭제
                    const leftOrders = lpOrderMap.filter((curOrd: any) => curOrd.iOppositeAmount === 0);
                    this.AccountState.FuturesOrders[Futures][tradeType] = leftOrders;
                }
            }

            return Common.NO_ERROR;
        },

        // 미체결의 수량과 스탑로스 라인 표시모드(단일)를 얻는다.
        GetClientTotalLeftCount(Futures: any) {
            let TradeType = Common.G_TRADE_SELL;
            let LeftAmount = 0;
            let LeftPrice = 0.0;
            let oppLine = true;

            for (let trade = 0; trade < Common.G_TRADE_MAX; trade++) {
                const futOrders = this.AccountState.FuturesOrders[Futures][trade];
                if (futOrders) {
                    const leftOrder = futOrders.find((ord: any) => ord.iLeftAmount > 0);
                    if (leftOrder) {
                        LeftAmount = leftOrder.iLeftAmount;
                        LeftPrice = leftOrder.dbOrderPrice;
                        TradeType = trade;
                        oppLine = true;
                        return [TradeType, LeftAmount, LeftPrice, oppLine];
                    }
                }
            }

            return [TradeType, LeftAmount, LeftPrice, oppLine];
        },

        // 미체결 주문의 스탑로스 예시 그리기 설정
        SetClientStopLossExamples(futures: any) {
            if (futures >= Common.G_FUTURES_MAX) {
                return;
            }
        
            // 미체결 주문을 확인
            if (futures !== Common.G_FUTURES_MAX && this.AccountState.iOrderPos[futures] === Common.C_ORDERPOS_NO) {
                // StopLoss를 설정한다.
                const lpStopLossObj = this.AccountState.FuturesStopLoss[futures];
                if (lpStopLossObj.bUse) {
                    lpStopLossObj.iTradeType = Common.G_TRADE_MAX;
                    lpStopLossObj.dbOrderPrice = 0.0;
                    lpStopLossObj.iRow = Common.INDEX_NONE;
                    lpStopLossObj.drawLine = false;
                    lpStopLossObj.bExam = false;
                }
        
                // 주문 스탑로스 예시
                // 미체결의 수량과 스탑로스 라인 표시모드(단일)를 얻는다.
                const [tradeType, leftAmount, orderPrice, oppLine] = this.GetClientTotalLeftCount(futures);
                if (typeof leftAmount === 'number' && leftAmount > 0 && lpStopLossObj.bUse) {
                    lpStopLossObj.iTradeType = typeof tradeType === 'number' ? tradeType : 0;
                    lpStopLossObj.dbOrderPrice = typeof orderPrice === 'number' ? orderPrice : 0.0;
                    lpStopLossObj.bExam = true;
                }
        
                lpStopLossObj.drawLine = typeof oppLine === 'boolean' ? oppLine : true;
            }
        },

        // 총 잔량(반대매매)의 수량과 평균가와 스탑로스 라인 표시모드(단일)를 얻는다.
        GetClientTotalOppositeCountNAverage(Futures: any, TradeType: any) {
            let OppAmount = 0;
            let OppAverage = 0.0;
            let oppLine = true;
            let OppCount = 0;
            let tmpPrice = 0.0;
            let TotalPrice = 0.0;

            const lpOrderMap = this.AccountState.FuturesOrders[Futures][TradeType];
            if (lpOrderMap) {
                lpOrderMap.forEach((curOrd: any) => {
                    if (curOrd.iOppositeAmount > 0) {
                        OppAmount += curOrd.iOppositeAmount;
                        TotalPrice += curOrd.iOppositeAmount * curOrd.dbContractPrice;
                        if (tmpPrice !== curOrd.dbContractPrice) {
                            tmpPrice = curOrd.dbContractPrice;
                            OppCount ++;
                        }
                    }
                });
            }

            if (OppAmount > 0) {
                OppAverage = TotalPrice / OppAmount;
            }

            if (OppCount > 1) {
                oppLine = false;
            }

            return [OppAmount, OppAverage, oppLine];
        },

        // 총 잔량(반대매매)을 얻는다.
        GetClientTotalOppositeAmount(FuturesType: any, TradeType = Common.G_TRADE_MAX) {
            let totalOpposite = 0;

            for (let trType = 0; trType < Common.G_TRADE_MAX; trType++) {
                if (TradeType === Common.G_TRADE_MAX || trType === TradeType) {
                    const lpOrderMap = this.AccountState.FuturesOrders[FuturesType][trType];
                    if (lpOrderMap) {
                        lpOrderMap.forEach((curOrd: any) => {
                            if (curOrd.iOppositeAmount > 0) {
                                totalOpposite += curOrd.iOppositeAmount;
                            }
                        });
                    }
                }
            }

            return totalOpposite;
        },

        // 미체결 카운트를 얻는다.
        GetClientLeftAmountCounts(FuturesType: any, TradeType = Common.G_TRADE_MAX) {
            let leftCounts = 0;

            for (let trType = 0; trType < Common.G_TRADE_MAX; trType++) {
                if (TradeType === Common.G_TRADE_MAX || trType === TradeType) {
                    const lpOrderMap = this.AccountState.FuturesOrders[FuturesType][trType];
                    if (lpOrderMap) {
                        lpOrderMap.forEach((curOrd: any) => {
                            if (curOrd.iLeftAmount > 0) {
                                leftCounts += curOrd.iLeftAmount;
                            }
                        });
                    }
                }
            }

            return leftCounts;
        },

        // 모든 선물의 포지션 잔량(반대매매) + 미체결, 또는 주문 중 가장 큰 값을 얻는다.
        GetClientMaxPositionAmount(IsOversea: any) {
            const tradePosition = [0, 0];

            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                // const tradeType = this.AccountState.iOrderPos[futures] === Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;

                for (let trade = 0; trade < Common.G_TRADE_MAX; trade++) {
                    const lpOrderMap = this.AccountState.FuturesOrders[futures][trade];
                    if (lpOrderMap) {
                        lpOrderMap.forEach((curOrd: any) => {
                            if (curOrd.iOrderAmount > 0) {
                                if (!IsOversea && futures < Common.G_FUTURES_OVERSEAS) {
                                    tradePosition[trade] += curOrd.iOrderAmount;
                                } else if (IsOversea && futures >= Common.G_FUTURES_OVERSEAS) {
                                    tradePosition[trade] += curOrd.iOrderAmount;
                                }
                            }
                        });
                    }
                }
            }

            return Math.max(tradePosition[Common.G_TRADE_SELL], tradePosition[Common.G_TRADE_BUY]);
        },

        // 체결 리스트를 얻는다.
        GetClientOppositeList(Futures = Common.G_FUTURES_MAX) {
            const oppositeOrders = [];

            for (let futCode = 0; futCode < Common.G_FUTURES_MAX; futCode++) {
                if (Futures === Common.G_FUTURES_MAX || futCode === Futures ) {
                    for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType++) {
                        const lpOrderMap = this.AccountState.FuturesOrders[futCode][tradeType];
                        if (lpOrderMap) {
                            // 미체결 주문을 가져온다.
                            const opposites = lpOrderMap.filter((curOrd: any) => curOrd.iOppositeAmount > 0);
                            oppositeOrders.push(...opposites);
                        }
                    }
                }
            }
            return oppositeOrders;
        },

        // 미체결 리스트를 얻는다.
        GetClientLeftList(Futures = Common.G_FUTURES_MAX) {
            const leftOrders = [];

            for (let futCode = 0; futCode < Common.G_FUTURES_MAX; futCode++) {
                if (Futures === Common.G_FUTURES_MAX || futCode === Futures ) {
                    for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType++) {
                        const lpOrderMap = this.AccountState.FuturesOrders[futCode][tradeType];
                        if (lpOrderMap) {
                            // 미체결 주문을 가져온다.
                            const lefts = lpOrderMap.filter((curOrd: any) => curOrd.iLeftAmount > 0);
                            leftOrders.push(...lefts);
                        }
                    }
                }
            }
            return leftOrders;
        },

        // 실현 손익을 구한다.
        GetClientProfit(Futures: any) {
            let totalOpposite = 0;
            let totalAmount = 0.;
            let gap = 0.;

            //레버리지
            const Leverage = this.AccountState.iLeverage;
            const curPrice = this.MasterState.dbCurPrice[Futures];
            for (let tradeType = 0; tradeType < Common.G_TRADE_MAX; tradeType++) {
                const lpOrderMap = this.AccountState.FuturesOrders[Futures][tradeType];
                if (lpOrderMap) {
                    lpOrderMap.forEach((curOrd: any) => {
                        if (curOrd.iOppositeAmount > 0) {
                            totalOpposite += curOrd.iOppositeAmount;
                            totalAmount += (curOrd.iOppositeAmount * curOrd.dbContractPrice);
                        }
                    });
                }
            }

            // 만약 잔량이 있으면 평균가를 저장
            if(totalOpposite) { //잔고가 있으면 
                //평균가
                this.AccountState.dAverage[Futures] = (totalAmount / totalOpposite);

                //평가손익(매도) = ((평균가 - 현재가) / 호가단위) * TICK_PRICE * 잔고수량
                //평가손익(매수) = ((현재가 - 평균가) / 호가단위) * TICK_PRICE * 잔고수량
                if (this.AccountState.iOrderPos[Futures] === Common.C_ORDERPOS_SELL) {
                    gap = this.AccountState.dAverage[Futures] - curPrice;
                } else if(this.AccountState.iOrderPos[Futures] === Common.C_ORDERPOS_BUY) {
                    gap = curPrice - this.AccountState.dAverage[Futures];
                } else { //이것은 오류 잔고가 있는데 포지션이 없다(?)
                    this.AccountState.dProfit[Futures] = 0;
                return -1;
                }

                //주문가와 현재가의 갭이 없으면 계산하지 말고 그냥 리턴 평가손익 0
                if (gap !== 0.) {
                    this.AccountState.dProfit[Futures] = Common.ROUNDOFF((Common.ROUNDOFFOFF(gap, 6) / Common.G_MICRO_ONETICK[Futures]) * this.BrandState.G_MICRO_TICK_PRICE[Futures] * totalOpposite);
                    this.AccountState.dProfit[Futures] = this.AccountState.dProfit[Futures] / Leverage;
                } else {
                    this.AccountState.dProfit[Futures] = 0;
                }

            } else {
                this.AccountState.dProfit[Futures] = 0;
            }

            return Common.NO_ERROR;
        },

        // 총 실현손익을 구한다.
        GetClientProfitAll() {
            let ProfitDomestic = 0, ProfitOversea = 0;
            let dProfitTotal = 0;

            //먼저 실현 손익을 구한다.
            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                if (this.GetClientProfit(futures) === Common.NO_ERROR) {
                    if (futures < Common.G_FUTURES_OVERSEAS) {
                        ProfitDomestic += this.AccountState.dProfit[futures];
                    } else {
                        ProfitOversea += this.AccountState.dProfit[futures];
                    }
                    dProfitTotal += this.AccountState.dProfit[futures];
                }
            }

            return [dProfitTotal, ProfitDomestic, ProfitOversea];
        },

        //평가금액을 구한다.
        GetLeverageBalance() {
            //먼저 실현손익을 구한다.
            // 체결 실현손익
            const [ProfitDomestic, ProfitOversea] = this.GetClientProfitAll();

            // 평가금액(평가담보금)
            const iEvaluationDomestic = this.AccountState.iDepoAmount + ProfitDomestic;
            const iEvaluationOversea = this.AccountState.iDepoAmountOversea + ProfitOversea;

            // 평가금액(평가예탁금) 반영
            this.AccountState.iDeopAmountLeverage = iEvaluationDomestic * this.AccountState.iLeverage;
            this.AccountState.iDeopAmountOverseaLeverage = iEvaluationOversea * this.AccountState.iLeverageEuro;

            // 매수 가능, 매도 가능을 그린다.
            this.DrawPossibleOrder();

            // 하단 손익 내역
            //DrawTransGridProfit();
        },

        // 당일 실현손익을 구한다.
        GetClientTodayGainNLoss() {
            const gainNLossTotal = this.AccountState.iRealGainNLoss;
            let gainNLossDomestic = 0;
            let gainNLossOversea = 0;

            //먼저 실현 손익을 구한다.
            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                if (this.GetClientProfit(futures) === Common.NO_ERROR) {
                    if (futures < Common.G_FUTURES_OVERSEAS) {
                        gainNLossDomestic += this.AccountState.iRealGainNLossFutures[futures];
                    } else {
                        gainNLossOversea += this.AccountState.iRealGainNLossFutures[futures];
                    }
                }
            }

            return [gainNLossTotal, gainNLossDomestic, gainNLossOversea];
        },

        // 당일 수수료 구한다.
        GetClientTodayCommission() {
            const commissionTotal = this.AccountState.iDepoCommission;
            let commissionDomestic = 0;
            let commissionOversea = 0;

            //먼저 실현 손익을 구한다.
            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                if (this.GetClientProfit(futures) === Common.NO_ERROR) {
                    if (futures < Common.G_FUTURES_OVERSEAS) {
                        commissionDomestic += this.AccountState.iDepoCommissionFutures[futures];
                    } else {
                        commissionOversea += this.AccountState.iDepoCommissionFutures[futures];
                    }
                }
            }

            return [commissionTotal, commissionDomestic, commissionOversea];
        },

        DrawPossibleOrder() {
            let balance = 0;
            const isOversea = this.AccountState.G_Future_Code >= Common.G_FUTURES_OVERSEAS;

            // 평가금액을 가져온다.
            if (!isOversea) {
                balance = this.AccountState.iDepoAmount * this.AccountState.iLeverage;
            } else {
                balance = this.AccountState.iDepoAmountOversea * this.AccountState.iLeverageEuro;
            }

            const CurOrder = Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0);// 현재 체결 잔고
            const LeftOrder = Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0); // 현재 미 체결 잔고
            const ClearLeftOrder = Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0); // 청산을 위한 현재 미 체결 잔고
            const PositionBalance = new Array(Common.G_FUTURES_MAX).fill(0); // 현재 주문 및 체결에 사용된 금액
            const totalCommission = 0;
            
            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures ++) {
                const cFuturePrice = this.GetFuturePirce(futures);

                const tradeType = this.AccountState.iOrderPos[futures]===Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;

                // 총 잔량(반대매매)의 수량과 총 가격을 얻는다.
                const [data1] = this.GetClientTotalOppositeAmountNAverageNCommission(futures, tradeType);
                let oppAmount = data1;
                //totalCommission += oppCommission;
                if (oppAmount > 0) {
                    CurOrder[futures][tradeType] = oppAmount; // 선물별 체결 수량

                    // 체결된 금액은 무조건 빼준다.
                    PositionBalance[futures] += cFuturePrice * oppAmount;
                }

                // 미체결 주문을 얻는다.
                for (let trade = 0; trade < Common.G_TRADE_MAX; trade ++) {
                    const tradeType = (trade === Common.G_TRADE_BUY) ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
                    oppAmount = this.GetClientLeftAmountCounts(futures, trade);

                    if (oppAmount > 0) {
                            // 미체결 주문이 있으면 청산을 위한 주문인지 확인해서 빼준다.
                        if (CurOrder[futures][tradeType] > 0) {
                            // 같은포지션의 주문이 아니면 청산을 위한 주문도 포함되어 있다.
                            if(trade !== tradeType) {
                                ClearLeftOrder[futures][trade] = (CurOrder[futures][tradeType] > oppAmount) ? oppAmount : CurOrder[futures][tradeType];
                                oppAmount -= CurOrder[futures][tradeType];
                            }
                        }

                        if(oppAmount > 0) {
                            LeftOrder[futures][trade] = oppAmount;
                        }
                    }
                }

                // 더 많은 주문을 기준으로 잡는다.
                if (LeftOrder[futures][Common.G_TRADE_BUY] > LeftOrder[futures][Common.G_TRADE_SELL]) {
                    PositionBalance[futures] += cFuturePrice * LeftOrder[futures][Common.G_TRADE_BUY];
                }
                else {
                    PositionBalance[futures] += cFuturePrice * LeftOrder[futures][Common.G_TRADE_SELL];
                }
            }

            balance += totalCommission;

            const postBalance = [0, 0, 0];

            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                // 국내 / 해외 주문, 평가금액 구분
                if (!isOversea && futures < Common.G_FUTURES_OVERSEAS) {
                    balance -= PositionBalance[futures];
                } else if (isOversea && futures >= Common.G_FUTURES_OVERSEAS) {
                    balance -= PositionBalance[futures];
                }

                if (futures < Common.G_FUTURES_OVERSEAS) {
                    postBalance[futures] = PositionBalance[futures];
                } else {
                    postBalance[Common.G_FUTURES_OVERSEAS] += PositionBalance[futures];
                }
            }

            // 최대 계약 가능
            const iMaxOrder = Utils.array2d(Common.G_FUTURES_OVERSEAS+1, Common.G_TRADE_MAX, 0);
            for (let futures = 0; futures < Common.G_FUTURES_MAX; futures++) {
                const cFuturePrice = this.GetFuturePirce(futures);
                const fut_Code = (futures < Common.G_FUTURES_OVERSEAS) ? futures : Common.G_FUTURES_OVERSEAS;

                let iBalance = balance;
                const mMaxOrder = this.MarketState.bIndivMaxContract ? (fut_Code < Common.G_FUTURES_OVERSEAS ? this.MarketState.iDomesticMaxContract : this.MarketState.iOverseaMaxContract) : this.MarketState.iMaxContract[fut_Code];
                if (iBalance  + postBalance[fut_Code] > mMaxOrder * cFuturePrice) {
                    iBalance = mMaxOrder * cFuturePrice;
                    iBalance -= postBalance[fut_Code];
                }

                const BALANCE_TMP = iBalance / cFuturePrice;
                const BALANCE_MAX    = parseInt(Math.floor(BALANCE_TMP).toString());
                for (let tradType = 0; tradType < Common.G_TRADE_MAX; tradType++) {
                    iMaxOrder[fut_Code][tradType] = (BALANCE_MAX < mMaxOrder) ? BALANCE_MAX : mMaxOrder;
                }
            }

            const iMaxOrderFut = Utils.array2d(Common.G_FUTURES_MAX, Common.G_TRADE_MAX, 0);
            // 각 선물별 최대 거래 가능 갯수를 구한다.
            for(let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
                const futCode = (futures < Common.G_FUTURES_OVERSEAS) ? futures : Common.G_FUTURES_OVERSEAS;

                iMaxOrderFut[futures][Common.G_TRADE_SELL] = (iMaxOrder[futCode][Common.G_TRADE_SELL] > 0) ? iMaxOrder[futCode][Common.G_TRADE_SELL] : 0;
                iMaxOrderFut[futures][Common.G_TRADE_BUY] = (iMaxOrder[futCode][Common.G_TRADE_BUY] > 0) ? iMaxOrder[futCode][Common.G_TRADE_BUY] : 0;

                // 체결잔고가 있으면 청산을 위해 주문을 더해준다.
                if (CurOrder[futures][Common.G_TRADE_SELL] > 0 || CurOrder[futures][Common.G_TRADE_BUY] > 0) {
                    if (CurOrder[futures][Common.G_TRADE_SELL] > 0) {
                        iMaxOrderFut[futures][Common.G_TRADE_BUY] += (CurOrder[futures][Common.G_TRADE_SELL] - ClearLeftOrder[futures][Common.G_TRADE_BUY]);
                    }

                    if (CurOrder[futures][Common.G_TRADE_BUY] > 0) {
                        iMaxOrderFut[futures][Common.G_TRADE_SELL] += (CurOrder[futures][Common.G_TRADE_BUY] - ClearLeftOrder[futures][Common.G_TRADE_SELL]);
                    }
                }

                if (LeftOrder[futures][Common.G_TRADE_BUY] > LeftOrder[futures][Common.G_TRADE_SELL]) {
                    iMaxOrderFut[futures][Common.G_TRADE_SELL] += (LeftOrder[futures][Common.G_TRADE_BUY] - LeftOrder[futures][Common.G_TRADE_SELL]);
                }

                if (LeftOrder[futures][Common.G_TRADE_SELL] > LeftOrder[futures][Common.G_TRADE_BUY]) {
                    iMaxOrderFut[futures][Common.G_TRADE_BUY] += (LeftOrder[futures][Common.G_TRADE_SELL] - LeftOrder[futures][Common.G_TRADE_BUY]);
                }

                this.AccountState.iOrderMax[futures][Common.G_TRADE_SELL] = iMaxOrderFut[futures][Common.G_TRADE_SELL];
                this.AccountState.iOrderMax[futures][Common.G_TRADE_BUY] = iMaxOrderFut[futures][Common.G_TRADE_BUY];
            }

            return;
        },

        // 총 잔량(반대매매)의 수량과 평균가를 얻는다.
        GetClientTotalOppositeAmountNAverage(Futures: any, TradeType: any) {
            let OppAmount = 0;
            let OppAverage = 0.;
            let TotalPrice = 0.;

            const lpOrderMap = this.AccountState.FuturesOrders[Futures][TradeType];
            if (lpOrderMap) {
                lpOrderMap.forEach((curOrd: any) => {
                    if (curOrd.iOppositeAmount > 0) {
                                OppAmount += curOrd.iOppositeAmount;
                                TotalPrice += curOrd.iOppositeAmount * curOrd.dbContractPrice;
                    }
                });
            }

            if (OppAmount > 0) {
                OppAverage = TotalPrice / OppAmount;
            }

            return [OppAmount, OppAverage];
        },

        // 총 잔량(반대매매)의 수량과 평균가와 수수료를 얻는다.
        GetClientTotalOppositeAmountNAverageNCommission(Futures: any, TradeType: any) {
            let OppAmount = 0;
            let OppAverage = 0.;
            let oppCommission = 0;
            let TotalPrice = 0.;

            const lpOrderMap = this.AccountState.FuturesOrders[Futures][TradeType];
            if (lpOrderMap) {
                lpOrderMap.forEach((curOrd: any) => {
                    if (curOrd.iOppositeAmount > 0) {
                        OppAmount += curOrd.iOppositeAmount;
                        TotalPrice += curOrd.iOppositeAmount * curOrd.dbContractPrice;

                        oppCommission += this.GetFutureCommission(Futures, curOrd.dbContractPrice);
                    }
                });
            }

            if (OppAmount > 0) {
                OppAverage = TotalPrice / OppAmount;
            }

            return [OppAmount, OppAverage, oppCommission];
        },

        // 현재 선물과 거래가능여부를 얻는다.
        GetFuturesNExchangeable(futCode: any, buyOnOpening = false) {
            if(this.AccountState.iLeverage > this.BrandState.iMaxLeverage) {
                return Common.G_FUTURES_MAX;
            }

            if (this.MarketState.bTodayOpen[futCode]) {
                const timeNow = new Date(Date.now());
                if (buyOnOpening) {
                    if (this.IsTradingTime(futCode, timeNow))
                    {
                        if (this.MarketState.bExchangeable[futCode]) {
                            return futCode;
                        }
                    }
                } else {
                    if (this.IsTradingTime(futCode, timeNow)) {
                        if(this.MarketState.bExchangeable[futCode])
                        {
                            return futCode;
                        }
                    }
                }
            }

            return Common.G_FUTURES_MAX;
        },

        // 입금 가능여부 확인
        PossibleDeposit() {
            if (!this.BrandState.bBrandUseBank && !this.AccountState.bMock && this.AccountState.iUserType === Common.C_USERTYPE_STD) {
                return false;
            }

            return true;
        },
        
        /*****************************************************************************/

        /*************************** Brand Store Actions ***************************/

        // 선물 증거금 - 선물 가격
        GetFuturePirce(Futures: any) {
            if (Futures < Common.G_FUTURES_OVERSEAS) {
                return this.BrandState.iDomesticFuturePrice;
            } else {
                return this.BrandState.iOverseaFuturePrice;
            }
        },

        // 국내/해외 오버 담보금
        GetOvernightDeposit(Futures: any, OverTrading: any) {
            if (OverTrading === Common.eOverTrading_Overnight) {
                if (Futures < Common.G_FUTURES_OVERSEAS) {
                    return this.BrandState.iDomesticOvernightDeposit;
                } else {
                    return this.BrandState.iOverseaOvernightDeposit;
                }
            } else if (OverTrading === Common.eOverTrading_Settlement || OverTrading === Common.eOverTrading_Payment) {
                return this.BrandState.iOverTradingDeposit;
            }

            return 0;
        },

        /*****************************************************************************/

        /*************************** ChatRoom Store Actions ***************************/

        GetChatRoomPtr() {
            return this.ChatRoomState.m_ChatRoom;
        },

        /*****************************************************************************/

        /*************************** Common Store Actions ***************************/

        setThemeMode(mode:string){
            this.CommonState.themeMode = mode;
        },

        setResizeDeviceType(type:number){
            this.CommonState.resizeDeviceType =type;
        },

        setVisibleToast({isTypeWarning = false, message='', duration=2000}){
            if(this.CommonState.toastCurrTimer){
                this.setUnvisibleToast();
            } 

            setTimeout(()=> {
                const replaceMessage = message.replace("\n", "<br/>")

                this.CommonState.toastVisible = true;
                this.CommonState.toastIsTypeWarning = isTypeWarning;
                this.CommonState.toastMessage = replaceMessage;
                this.CommonState.toastDuration = duration;

                this.CommonState.toastCurrTimer = setTimeout(() => {
                    this.setUnvisibleToast();
                }, duration);
            }, 100)
        },

        setUnvisibleToast(){
            this.CommonState.toastVisible = false;
            clearTimeout(this.CommonState.toastCurrTimer);
            this.CommonState.toastCurrTimer = null;
        },

        /*****************************************************************************/

        /*************************** Dialog Store Actions ***************************/

        LogMsg(szMsg: any, szServerTime = '', bShowToast = true) {
            // 시간이 제외된 메세지
            if (bShowToast) {
                this.setVisibleToast({message: szMsg})
            }

            const timeNow = new Date(Date.now());

            const pushLog = new PushLogObj();
            pushLog.timestamp = timeNow.getTime();
            pushLog.szRegTime = `${moment(timeNow).format('HH:mm:ss')}`;
            pushLog.szSeverTime = szServerTime;
            pushLog.szMessage = szMsg;
            pushLog.szExtraMessage = '';
            
            this.DialogState.m_LogMsg.push(pushLog);
            const addedIndex = this.DialogState.m_LogMsg.length;
            // 정해진 용량에 찾거나 메모리 부족인가?
            if (addedIndex >= M_MESSAGE_LIST_NUM_MAX) {
                this.DialogState.m_LogMsg.shift();
            }
        },

        InitMasterCode() {
            // this.accountStore.GetLeverageBalance();
        },

        TimeMsg(Futures: any) {
            if (this.AccountState.iLeverage > this.BrandState.iMaxLeverage) {
                const szMsg = `레버리지 변경후 거래 가능합니다.\n고객님의 현재 설정 레버리지는 1:${this.AccountState.iLeverage}입니다.\n\n레버리지 변경은 상단 메뉴의 [설정>레버리지설정]에서 가능합니다.`;
                this.setVisibleDialog({ message: szMsg });
                return;
            }
            
            if (!this.MarketState.bExchangeable[Futures]) {
                const szMsg = `[${Common.g_szFuturesNameMini[Futures]}] 거래 정지 중입니다.\n청산이나 취소거래만 가능합니다.`;
                this.setVisibleDialog({ message: szMsg });
                return;
            }
            
            const timeNow = new Date(Date.now());
            if ( !this.IsTradingTime(Futures, timeNow)) {
                // 동시 호가 시간이면 지정가 주문만 가능하다는 메세지
                if ((timeNow >= this.MarketState.tBuyOnOpening[Futures] && timeNow < this.MarketState.tClose[Futures]) &&
                    (!this.MarketState.bUseBreak1[Futures] || timeNow < this.MarketState.tBreak1Start[Futures] || timeNow >= this.MarketState.tBreak1End[Futures]) &&
                    (!this.MarketState.bUseBreak2[Futures] || timeNow < this.MarketState.tBreak2Start[Futures] || timeNow >= this.MarketState.tBreak2End[Futures]))
                {
                    const szMsg = `[${Common.g_szFuturesNameMini[Futures]}] 동시호가 거래시간입니다.\n시장가와 지정가 주문만 가능합니다.`;
                    this.setVisibleDialog({ message: szMsg });
                }
                else
                {
                    const szMsg = `[${Common.g_szFuturesNameMini[Futures]}] 거래 시간이 아닙니다.`;
                    this.setVisibleDialog({ message: szMsg });
                }
            }
        },

        /*****************************************************************************/

        /*************************** Market Store Actions ***************************/

        // 선물별 선물1개의 수수료를 구한다.
        // 수수료는 레버리지, 선물 담보금에 상관없이 고정 G_FUTURE_PRICE 로 계산한다.
        GetFutureCommission(futures: any, IndexPrice: any) {
            const commission = this.MarketState.bIndivCommission ? this.MarketState.dbIndivCommission[futures] : this.MarketState.dbCommission[futures];
            if (futures <= Common.G_FUTURES_NIGHT) {
                return Common.ROUNDOFF((IndexPrice * Common.G_FUTURE_PRICE * commission) / 100.0);
                //return Common.ROUNDOFF((IndexPrice * this.iDomesticFuturePrice * commission) / 100.0);
            } else {
                return Common.ROUNDOFF(commission * (futures === Common.G_FUTURES_HANGSENG ? this.BrandState.ExchangeRateHKD : this.BrandState.ExchangeRate));
            }
        },

        // 선물별 수수료율을 구한다.
        // 수수료는 레버리지, 선물 담보금에 상관없이 고정 G_FUTURE_PRICE 로 계산한다.
        GetFutureCommissionRate(futures: any) {
            const commission = this.MarketState.bIndivCommission ? this.MarketState.dbIndivCommission[futures] : this.MarketState.dbCommission[futures];
            if (futures <= Common.G_FUTURES_NIGHT) {
                return commission;
            } else {
                // 수수료 / 환율
                //return commission / G_FUTURE_PRICE * 100;
                return commission;
            }
        },

        IsTradingTime(Futures: any, TimeNow: any) {
            if (TimeNow >= this.MarketState.tTradeStart[Futures] && TimeNow < this.MarketState.tTradeEnd[Futures] &&
                (!this.MarketState.bUseBreak1[Futures] || TimeNow < this.MarketState.tBreak1Start[Futures] || TimeNow >= this.MarketState.tBreak1End[Futures]) &&
                (!this.MarketState.bUseBreak2[Futures] || TimeNow < this.MarketState.tBreak2Start[Futures] || TimeNow >= this.MarketState.tBreak2End[Futures]))
            {
                return true;
            }

            return false;
        },

        // 현재 시간에 맞는 선물(주간, 해외.유로)를 얻는다.
        FuturesByNow() {
            // 2023-10-24: 마지막 선택한 종목 유지하게
            const lastFutures = this.getLastFuturesSelected();
            if(lastFutures !== '')
                return Number(lastFutures);

            // 해외선물을 기본으로 설정
            return Common.G_FUTURES_NASDAQ;


            // let futCode = Common.G_FUTURES_DAY;

            // const now = Date.now();

            // const szTime = moment(now).format('HHmm');
            // let curTime = parseInt(szTime);
            // let h_Min = 530;
            // let h_Max = 1545;

            // if (curTime > h_Min && curTime < h_Max) { // 05:30 ~ 15:45분까지는 주간
            //   futCode = Common.G_FUTURES_DAY;
            // } else {
            //   futCode = Common.G_FUTURES_EURO;
            // }

            // // 선택한 종목이 서비스 중이여야한다.
            // for (let futures = futCode; futures < Common.G_FUTURES_MAX; futures++) {
            //   if (this.bDrawMenu[futures]) {
            //     futCode = futures;
            //     break;
            //   }
            // }

            // return futCode;
        },

        /*****************************************************************************/

        /*************************** Master Store Actions ***************************/

        // 수량과 가격을 넣으면 평가손익을 돌려준다.
        GetGainNLoss(tradeType: any, t_price: any, Qty: any, futCode: any) {
            let iGainLoss = 0.;
            if (futCode >= Common.G_FUTURES_MAX) {
                return iGainLoss;
            }

            let iTick = 0., gap = 0.;
            if (tradeType === Common.G_TRADE_SELL) {
                gap = t_price - this.MasterState.dbCurPrice[futCode];
            } else {
                gap = this.MasterState.dbCurPrice[futCode] - t_price;
            }
            iTick = (Common.ROUNDOFFOFF(gap, 6) / Common.G_MICRO_ONETICK[futCode]);
            iGainLoss = Common.ROUNDOFF(iTick * Qty * this.BrandState.G_MICRO_TICK_PRICE[futCode]);

            return iGainLoss;
        },

        /*****************************************************************************/

        /*************************** Notice Store Actions ***************************/

        // 공지사항 내용 저장(일반 텍스트)
        setNoticeContent(noticeIdx: any, noticeContent: any){
            for(let i = 0; i < this.NoticeState.notices.length; i++){
                if(this.NoticeState.notices[i].iNoticeIdx === noticeIdx){
                    this.NoticeState.notices[i].szContents = noticeContent;
                }
            }
        },

        //공지사항 내용 저장(버퍼값)
        setNoticeContentBuff(noticeIdx: any, contentBuff: any){
            for(let i = 0; i < this.NoticeState.notices.length; i++){
                if(this.NoticeState.notices[i].iNoticeIdx === noticeIdx){
                    this.NoticeState.notices[i].contentBuff = contentBuff;
                }
            }
        },

        //특정 공지사항 내용 삭제(팝업 출력전 초기화 목적)
        removeNoticeContent(noticeIdx: any){
            for(let i = 0; i < this.NoticeState.notices.length; i++){
                if(this.NoticeState.notices[i].iNoticeIdx === noticeIdx){
                    this.NoticeState.notices[i].contentBuff = null;
                    this.NoticeState.notices[i].szContents = '';
                }
            }
        }, 

        //특정 공지사항 내용이 있는지 체크(없으면 공지사항 팝업을 띄우지 않음)
        existNoticeCotent(noticeIdx: any){
            for(let i = 0; i < this.NoticeState.notices.length; i++){
                if(this.NoticeState.notices[i].iNoticeIdx === noticeIdx){
                    if(this.NoticeState.notices[i].contentBuff !== null || this.NoticeState.notices.szContents !== ''){
                        return {
                            result: true, 
                            contentBuff: this.NoticeState.notices[i].contentBuff,
                            szContents: this.NoticeState.notices[i].szContents
                        };
                    }
                }
            }
            return {result: false};
        },

        /*****************************************************************************/
        

        /*************************** Settings Store Actions ***************************/

        GetTimeGap() {
            // https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
            // 서울
            const kstTime = moment().utcOffset();
            // 시카고
            const cstTime = moment().tz('America/Chicago').utcOffset();
            this.SettingsState.timeGapCME = (kstTime - cstTime) / 60;
          
            // 뉴욕
            const edtTime = moment().tz('America/New_York').utcOffset();
            this.SettingsState.timeGapNYMEX = (kstTime - edtTime) / 60;
        
            // 홍콩
            const hktTime = moment().tz('Asia/Hong_Kong').utcOffset();
            this.SettingsState.timeGapHKEX = (kstTime - hktTime) / 60;
        
            // 싱가포르
            const sgtTime = moment().tz('Asia/Singapore').utcOffset();
            this.SettingsState.timeGapSGX = (kstTime - sgtTime) / 60;
        
            // console.log(`Time zone from seoul: 시카고: ${this.SettingsState.timeGapCME}, 뉴욕: ${this.SettingsState.timeGapNYMEX}, 홍콩: ${this.SettingsState.timeGapHKEX}, 싱가포르: ${this.SettingsState.timeGapSGX}`);
        },
          
        GetOverseaToKorTime(Futures: any, DateTime: any) {
        
            // 시카고 시간으로 값이 넘어오기 때문에 한국시간 기준으로 맞춘다.
            let krTime = DateTime;
            if (Futures === Common.G_FUTURES_OIL || Futures === Common.G_FUTURES_GOLD || Futures === Common.G_FUTURES_SILVER || Futures === Common.G_FUTURES_GAS || Futures === Common.G_FUTURES_COPPER)
            {
              // 뉴욕거래소
                krTime += this.SettingsState.timeGapNYMEX * 60 * 60;
            }
            else if (Futures === Common.G_FUTURES_HANGSENG)
            {
                // 홍콩 거래소
                krTime += this.SettingsState.timeGapHKEX * 60 * 60;
            }
            else if (Futures === Common.G_FUTURES_CHINA50)
            {
                // 싱가포르 거래소
                krTime += this.SettingsState.timeGapSGX * 60 * 60;
            }
            else if (Futures >= Common.G_FUTURES_OVERSEAS)
            {
                // 시카고거래소
                krTime += this.SettingsState.timeGapCME * 60 * 60;
            }
        
            //console.log(`Time zone from seoul: 시카고: ${this.timeGapCME}, 뉴욕: ${this.timeGapNYMEX}, 홍콩: ${this.timeGapHKEX}, 싱가포르: ${this.timeGapSGX}`);
            //console.log(`Time zone from seoul: ${Futures}, DateTime: ${DateTime}, krTime: ${krTime}`);
        
            return krTime;
        },

        // @action
        setSettings(settings: any) {
            this.SettingsState.settings = settings || SettingsModel.placeholder;
        },

        // @action
        setSettingsEx(settingsEx: any) {
            this.SettingsState.settingsEx = settingsEx || SettingsExModel.placeholder;
        },

        // @action
        async getSettings() {
            /*
            return SettingsService.getSettings().then(settings => {
            this.setSettings(settings);
            });
            */
            const [signedId, signinPwd, settings] = await Promise.all([
                SettingsService.getUserId(),
                SettingsService.getUserPwd(),
                SettingsService.getSettings()
            ]);
            this.SettingsState.loginID = signedId;
            this.SettingsState.signinPwd = signinPwd;
            this.setSettings(settings);
        },

        // @action
        async getSettingsEx() {
            const settingsEx = await SettingsService.getSettingsEx();

            this.setSettingsEx(settingsEx);
        },

        // @action
        initializeSettings(bForce = false) {
            if (!bForce && this.settings_initialized) {
                return;
            }

            return SettingsService.initializeSettings().then(settings => {
                this.setSettings(settings);
                // 처음 설치되면 getSettings() => initializeSettings() 순으로 처리
                // ios에서 재설치시 기존 설치에 사용된 keychain 영역이 남아있어 비빌 번호가 남아있다. 그래서 초기화 한다.
                // const signinPwd = '';
                // SettingsService.saveUserPwd(signinPwd);
                // this.SettingsState.signinPwd = signinPwd;
            });
        },

        // @action
        initializeSettingsEx(bForce = false) {
            if (!bForce && this.initializedEx) {
                return;
            }

            return SettingsService.initializeSettingsEx().then(settingsEx => {
                this.setSettingsEx(settingsEx);
            });
        },

        // @action
        updateLoginId( id : string ) { SettingsService.saveUserId(id).then(async () => { this.SettingsState.loginID = id.toLowerCase(); }); },

        // @action
        updateLoginPassword( pwd : string ) { SettingsService.saveUserPwd(pwd).then(async () => { this.SettingsState.signinPwd = pwd; }); },

        // @action
        updateSettings({ ...settingsInfo }) {
            if (!Object.keys(settingsInfo).length) {
                return;
            }

            return SettingsService.updateSettings({
                ...settingsInfo,
                id: this.SettingsState.settings.id
            }).then(settings => {
                this.setSettings(settings);
            }).catch(e => {
                const store = useStore();
                if (store.debugLog >= Constant.DebugLevel_Minimal)
                    console.warn(e);
                throw e;
            });
        },

        // @action
        updateSettingsEx(settingsExInfo: any) {
            const { options } = settingsExInfo;

            if (options === undefined) {
                return SettingsService.updateSettingsEx({
                    ...settingsExInfo,
                    id: this.SettingsState.settingsEx.id
                }).then(settingsEx => {
                    this.setSettingsEx(settingsEx);
                });
            }
            return SettingsService.updateSettingsEx({
                ...settingsExInfo,
                options: { ...this.SettingsState.settingsEx.options, ...options },
                id: this.SettingsState.settingsEx.id
            }).then(settingsEx => {
                this.setSettingsEx(settingsEx);
            });
        },

        // @action
        initAccPwd() {
            return SettingsService.updateSettings({
                id: this.SettingsState.settings.id,
                accPwdApply: AccPwd.cAccPwdApply_Forever,
                domesticAccPwd: '',
                overseaAccPwd: '',
                bAccPassed: false,
            }).then(settings => {
                this.setSettings(settings);
            }).catch(e => {
                const store = useStore();
                if (store.debugLog >= Constant.DebugLevel_Minimal)
                    console.warn(e);
                throw e;
            });
        },

        // @action
        checkAccountPassword({pwd1 = null as any, pwd2 = null as any, checkType = null as any}) {
            const bAccPassed = pwd1 !== null && pwd1 === this.AccountState.szDomesticAccPwd ? true : pwd2 !== null && pwd2 === this.AccountState.szOverseaAccPwd ? true : false;
            const updateObj = {
                id: this.SettingsState.settings.id,
                accPwdApply: checkType !== null ? checkType : this.SettingsState.settings.accPwdApply,
                domesticAccPwd: pwd1 !== null ? pwd1 : this.getDomesticAccPwd(),
                overseaAccPwd: pwd2 !== null ? pwd2 : this.getOverseaAccPwd(),
                bAccPassed: bAccPassed
            }

            if(checkType === AccPwd.cAccPwdApply_Every) {
                updateObj.domesticAccPwd = '';
                updateObj.overseaAccPwd = '';
                updateObj.bAccPassed = false;
            }
            return SettingsService.updateSettings(updateObj).then(settings => { this.setSettings(settings); }).catch(e => {
                const store = useStore();
                if (store.debugLog >= Constant.DebugLevel_Minimal)
                    console.warn(e);
                 throw e; });
        },

        getDomesticAccPwd(isRaw = true as boolean) { 
            const data = this.SettingsState.settings.domesticAccPwd;
            if(isRaw) return data ? data : "";
            return data ? "****" : "";
         },

        getOverseaAccPwd(isRaw = true as boolean) { 
            const data = this.SettingsState.settings.overseaAccPwd;
            if(isRaw) return data ? data : "";
            return data ? "****" : "";
        },

        getAccPassed() { 
            // 계좌 비밀번호 체크 생략
            // return this.SettingsState.settings.bAccPassed;
            return true;
        },

        /*****************************************************************************/

        /*************************** System Store Actions ***************************/

        async getItems() {
            const result1 = await localStorage.getItem(APP_INITIALIZED);
            const result2 = await localStorage.getItem(APP_LANGUAGE);
            const result3 = await localStorage.getItem(APP_PINCODE_ENABLE);
            const result4 = await localStorage.getItem(APP_BIOMATRICS_ENABLE);
            const result5 = await localStorage.getItem(APP_EMERGENCY_LAUNCH);
            const result6 = await localStorage.getItem(APP_LAST_UPDATES_VERSION);
            const result7 = await localStorage.getItem(APP_FONT_SCALE);
            const result8 = await localStorage.getItem(APP_DEBUG_LOG);

            const fonstScale = result7 && !isNaN(parseInt(result7)) ? parseInt(result7) : Constant.FONT_SIZE_DEFAULT;

            this.SystemState._initialized         = result1 && result1 === 'true' ? true : false;
            this.SystemState._language            = result2 ? result2 : '';
            this.SystemState._pincodeEnabled      = result3 && result3 === 'true' ? true : false;
            this.SystemState._biomatricsEnabled   = result4 && result4 === 'true' ? true : false;
            this.SystemState._isEmergencyLaunch   = result5 && result5 === 'true' ? true : false;
            this.SystemState._lastUpdatesVersion  = result6 ? result6 : '';
            this.SystemState._fontScale           = fonstScale;
            this.SystemState._debugLog            = result8 && !isNaN(parseInt(result8)) ? parseInt(result8) : Constant.DebugLevel_No;
            this.SystemState._deviceType          = Utils.GetDeviceType();
            this.SystemState._appMode             = process.env.VUE_APP_MODE !== undefined ? Number(process.env.VUE_APP_MODE) : 1;
            this.SystemState._helpdeskMode        = process.env.VUE_APP_HELPDESK_MODE !== undefined ? Number(process.env.VUE_APP_HELPDESK_MODE) : 1;
        },

        // @action
        async initializeItems(initialized = true) {
            await this.updateItems({
                initialized: initialized,
                language: '',
                pincodeEnabled: false,
                biomatricsEnabled: true,
                isEmergencyLaunch: false,
                lastUpdatesVersion: '',
                fontScale: Constant.FONT_SIZE_DEFAULT,
                debugLog: Constant.DebugLevel_No,
                deviceType: Utils.GetDeviceType(),
                appMode: process.env.VUE_APP_MODE !== undefined ? Number(process.env.VUE_APP_MODE) : 1,
                helpdeskMode: process.env.VUE_APP_HELPDESK_MODE !== undefined ? Number(process.env.VUE_APP_HELPDESK_MODE) : 1,
            });
        },

        // @action
        async updateItems(items: any) {
            const { initialized, language, pincodeEnabled, biomatricsEnabled, isEmergencyLaunch, lastUpdatesVersion, fontScale, debugLog, deviceType, appMode, helpdeskMode } = items;

            if (initialized !== undefined) { 
                await localStorage.setItem(APP_INITIALIZED, initialized ? 'true' : 'false'); 
                this.SystemState._initialized = initialized; 
            }
            if (language !== undefined) { 
                await localStorage.setItem(APP_LANGUAGE, language); 
                this.SystemState._language = language; 
            }
            if (pincodeEnabled !== undefined) { 
                await localStorage.setItem(APP_PINCODE_ENABLE, pincodeEnabled ? 'true' : 'false'); 
                this.SystemState._pincodeEnabled = pincodeEnabled; 
            }
            if (biomatricsEnabled !== undefined) { 
                await localStorage.setItem(APP_BIOMATRICS_ENABLE, biomatricsEnabled ? 'true' : 'false'); 
                this.SystemState._biomatricsEnabled = biomatricsEnabled; 
            }
            if (isEmergencyLaunch !== undefined) { 
                await localStorage.setItem(APP_EMERGENCY_LAUNCH, isEmergencyLaunch ? 'true' : 'false'); 
                this.SystemState._isEmergencyLaunch = isEmergencyLaunch; 
            }
            if (lastUpdatesVersion == undefined) { 
                await localStorage.setItem(APP_LAST_UPDATES_VERSION, lastUpdatesVersion); 
                this.SystemState._lastUpdatesVersion = lastUpdatesVersion; 
            }
            if (fontScale !== undefined) { 
                await localStorage.setItem(APP_FONT_SCALE, fontScale.toString()); 
                this.SystemState._fontScale = fontScale; 
            }
            if (debugLog !== undefined) { 
                await localStorage.setItem(APP_DEBUG_LOG, debugLog.toString()); 
                this.SystemState._fontScale = debugLog; 
            }
            if(deviceType !== undefined) {
                this.SystemState._deviceType = deviceType;
            }
            if(appMode !== undefined ){
                this.SystemState._appMode = appMode;
            }
            if(helpdeskMode !== undefined ){
                this.SystemState._helpdeskMode = helpdeskMode;
            }
        },

        // @action
        setVisibleDialog({ visible = true, message = '', title = '', okCancel = false, callback = null as any }) {

            const replaceMessage = message.replace("\n", "<br/>")
            // 변수 순서
            if (visible) {  //dialog open
                this.SystemState.bDialogOkCancel = okCancel;
                this.SystemState.visibleDialogMessage = replaceMessage;
                this.SystemState.visibleDialogTitle = title;
                this.SystemState.visibleDialogCallback = callback;
                this.SystemState.visibleDialog = visible;
            } else {    //dialog close
                if(!okCancel && this.SystemState.visibleDialogCallback){
                    //확인 버튼을 눌렀을 경우 callback이 있으면 실행
                    this.SystemState.visibleDialogCallback();
                } 

                //dialog 값 초기화
                this.SystemState.visibleDialog = visible;
                this.SystemState.visibleDialogMessage = '';
                this.SystemState.visibleDialogTitle = '';
                this.SystemState.visibleDialogCallback = null;
                this.SystemState.bDialogOkCancel = false;
            }
        },

        // @action
        setDebugLog(debug: any) {
            //this.debugLog = debug;
            this.updateItems({ debugLog: debug })
        },


        // @action
        convertResolutionToText(resolution: any) {
            switch('' + resolution) {
                case '1T': return '1틱';
                case '3T': return '3틱';
                case '5T': return '5틱';
                case '10T': return '10틱';
                case '20T': return '20틱';
                case '30T': return '30틱';
                case '1': return '1분';
                case '3': return '3분';
                case '5': return '5분';
                case '10': return '10분';
                case '20': return '20분';
                case '30': return '30분';
                case '1D': return '1일';
                case '1W': return '1주';
                case '1M': return '1달';
                default: {
                    const store = useStore();
                    if (store.debugLog >= Constant.DebugLevel_Minimal)
                        console.error('out of resolution: ' + resolution);
                }
            }
            return null;
        },
        // @action
        setCurrentChartLayoutInfo(idx: any, name: any, resolution: any) {
            this.ChartLayoutStore.dataIdx = idx;
            this.ChartLayoutStore.dataName = name;
            this.ChartLayoutStore.dataResolution = resolution;
        },
        // @action
        getCurrentChartLayoutSaveName() {
            return this.ChartLayoutStore.dataName;
        },
        // @action
        async loadChartLayout() {
            if( ! this.ChartLayoutStore.initialized) {
                const result1 = await localStorage.getItem(TV_LAYOUT_BASE);
                const result2 = await localStorage.getItem(TV_LAYOUT_STUDY);
                const result3 = await localStorage.getItem(TV_LAYOUT_DRAWING);
                const result4 = await localStorage.getItem(TV_LAYOUT_CHART);
                const result5 = await localStorage.getItem(TV_LAYOUT_INTERVAL);

                if(result1) { const data = JSON.parse(result1); if(data) data.forEach((element: any) => { this.ChartLayoutStore.charts.push(element); }); }
                if(result2) { const data = JSON.parse(result2); if(data) data.forEach((element: any) => { this.ChartLayoutStore.studyTemplates.push(element); }); }
                if(result3) { const data = JSON.parse(result3); if(data) data.forEach((element: any) => { this.ChartLayoutStore.drawingTemplates.push(element); }); }
                if(result4) { const data = JSON.parse(result4); if(data) data.forEach((element: any) => { this.ChartLayoutStore.chartTemplates.push(element); }); }

                if(result5) { const data = JSON.parse(result5); if(data) data.forEach((element: any) => { if(element && element != '' && typeof(element) != 'object') this.ChartLayoutStore.resolutionArray.push(element); }); }
                this.saveChartLayout();
                if(this.ChartLayoutStore.resolutionArray.length === 0) { Common.DEFAULT_RESOLUTION_ARRAY.forEach((element: any) => { this.ChartLayoutStore.resolutionArray.push(element); }); }
                
                this.ChartLayoutStore.initialized = true;
            }
        },
        // @action
        async saveChartLayout() {
            await localStorage.setItem(TV_LAYOUT_BASE, JSON.stringify(this.ChartLayoutStore.charts));
            await localStorage.setItem(TV_LAYOUT_STUDY, JSON.stringify(this.ChartLayoutStore.studyTemplates));
            await localStorage.setItem(TV_LAYOUT_DRAWING, JSON.stringify(this.ChartLayoutStore.drawingTemplates));
            await localStorage.setItem(TV_LAYOUT_CHART, JSON.stringify(this.ChartLayoutStore.chartTemplates));

            for(let i = this.ChartLayoutStore.resolutionArray.length-1; i >= 0; i--) {
                if(typeof(this.ChartLayoutStore.resolutionArray[i]) === 'object') {
                    this.ChartLayoutStore.resolutionArray.splice(i, 1);
                }
            }
            await localStorage.setItem(TV_LAYOUT_INTERVAL, JSON.stringify(this.ChartLayoutStore.resolutionArray));
        },
        // @action
        addChartResolutionButton(btn: any) { this.ChartLayoutStore.resolutionButton.push(btn); },
        // @action
        removeChartResolutionButton(id: any) {
            id = Common.CHART_LAYOUT_BUTTON_ID_PREFIX + id;
            if(id) {
                for(let i = 0; i < this.ChartLayoutStore.resolutionButton.length; i++) {
                    if(this.ChartLayoutStore.resolutionButton[i] && this.ChartLayoutStore.resolutionButton[i].id === id) {
                        this.ChartLayoutStore.resolutionArray.splice(i, 1);
                        const selectedArray = this.ChartLayoutStore.resolutionButton.splice(i, 1);
                        if(selectedArray && selectedArray.length > 0) {
                            const selectedObj = selectedArray[0];
                            const parentObj = selectedObj.parentNode.parentNode;
                            if(parentObj) {
                                const separator = parentObj.nextElementSibling;
                                if(separator) {
                                    separator.remove();
                                }
                                parentObj.remove();
                            }
                        }
                        
                    }
                }
            }
            this.saveChartLayout();
        },

        /*****************************************************************************/


        /*************************** Login Page Store Actions ***************************/
        // @action
        async loadLoginPageInfo() {
            const result1 = await localStorage.getItem(APP_LOGIN_INFO);
            if(result1) { 
                const data = JSON.parse(result1); 
                if(data) {
                    this.LoginPageState.id = data.id;
                    this.LoginPageState.isChecked1 = data.check1;
                    this.LoginPageState.isChecked2 = data.check2;
                    this.LoginPageState.loginState = data.loginState;

                    // 강제 소문자 변환
                    if(this.LoginPageState.id)
                        this.LoginPageState.id = this.LoginPageState.id.toLowerCase();
                }
            }
        },
        // @action
        async saveLoginPageInfo(id: string, check1: boolean, check2: boolean) {
            this.LoginPageState.id = check1 ? id : '';
            this.LoginPageState.isChecked1 = check1;
            this.LoginPageState.isChecked2 = check2;

            // 강제 소문자 변환
            if(this.LoginPageState.id)
                this.LoginPageState.id = this.LoginPageState.id.toLowerCase();
            
            await this._saveLoginPageInfo();
        },
        // @action
        async updateLoginPageState(loginState: any) {
            this.LoginPageState.loginState = loginState;
            await this._saveLoginPageInfo();
        },
        async _saveLoginPageInfo() {
            const obj = {
                id: this.LoginPageState.id,
                check1: this.LoginPageState.isChecked1,
                check2: this.LoginPageState.isChecked2,
                loginState: this.LoginPageState.loginState,
            }
            await localStorage.setItem(APP_LOGIN_INFO, JSON.stringify(obj));
        },
        // @action
        async loadLoginPageError() {
            const result1 = await localStorage.getItem(APP_LOGIN_ERROR);
            if(result1) { 
                const data = JSON.parse(result1); 
                if(data) {
                    this.LoginPageState.errorState = data.errorState;
                    this.LoginPageState.errorMessage = data.errorMessage;
                }
            }
        },
        // @action
        async updateLoginPageError(errorState: any, errorMessage: any) {
            this.LoginPageState.errorState = errorState;
            if(errorMessage != null && errorMessage != undefined){
                this.LoginPageState.errorState = errorState;
            }
            await localStorage.setItem(APP_LOGIN_ERROR, JSON.stringify({
                errorState: errorState,
                errorMessage: errorMessage,
            }));
        },


        async getSettingAccountPopupState() {
            const result1 = await localStorage.getItem(APP_ACCOUNT_POPUP_CHECK);
            return result1 ? Number(result1) : 0;
        },
        async setSettingAccountPopupState(value : any) {
            await localStorage.setItem(APP_ACCOUNT_POPUP_CHECK, value);
        },
        /*****************************************************************************/

        getLastViewPage() {
            const result1 = localStorage.getItem(APP_LAST_VIEW_PAGE);
            if(result1)
                return result1;
            return '';
        },
        setLastViewPage(data: string) {
            localStorage.setItem(APP_LAST_VIEW_PAGE, data);
        },

        getHideToday1() {
            const result1 = localStorage.getItem(APP_NOTICE_HIDE_TODAY_1);
            if(result1)
                return result1;
            return '';
        },
        setHideToday1(data: string) {
            localStorage.setItem(APP_NOTICE_HIDE_TODAY_1, data);
        },
        getHideToday2() {
            const result1 = localStorage.getItem(APP_NOTICE_HIDE_TODAY_2);
            if(result1)
                return result1;
            return '';
        },
        setHideToday2(data: string) {
            localStorage.setItem(APP_NOTICE_HIDE_TODAY_2, data);
        },

        getChatLastOpenTime() {
            const result1 = localStorage.getItem(APP_CHAT_LAST_OPEN_TIME);
            if(result1)
                return result1;
            return '';
        },
        setChatLastOpenTime(data: string) {
            localStorage.setItem(APP_CHAT_LAST_OPEN_TIME, data);
        },
        getLastFuturesSelected() {
            const result1 = localStorage.getItem(APP_LAST_FUTURES_SELECTED);
            if(result1)
                return result1;
            return '';
        },
        setLastFuturesSelected(data: string) {
            localStorage.setItem(APP_LAST_FUTURES_SELECTED, data);
        },
    }
});