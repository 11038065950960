import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/images/dropdown-arrow.svg'


const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "transactionForm innerHeight" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "top" }
const _hoisted_6 = { class: "bank-account" }
const _hoisted_7 = { class: "dropdown-sort" }
const _hoisted_8 = { class: "select-sort" }
const _hoisted_9 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_10 = { class: "out" }
const _hoisted_11 = { class: "section-sort" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "input-box send" }
const _hoisted_15 = { class: "left" }
const _hoisted_16 = { class: "text" }
const _hoisted_17 = { class: "right" }
const _hoisted_18 = { class: "input-box send" }
const _hoisted_19 = { class: "left" }
const _hoisted_20 = { class: "text" }
const _hoisted_21 = { class: "right" }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "account-box" }
const _hoisted_24 = { class: "input-box" }
const _hoisted_25 = { class: "right" }
const _hoisted_26 = { class: "input-box mb-0" }
const _hoisted_27 = { class: "right" }
const _hoisted_28 = { class: "input-box send" }
const _hoisted_29 = { class: "left" }
const _hoisted_30 = { class: "text" }
const _hoisted_31 = { class: "right" }
const _hoisted_32 = { class: "input-box send" }
const _hoisted_33 = { class: "right" }
const _hoisted_34 = { class: "input-box send" }
const _hoisted_35 = { class: "right" }
const _hoisted_36 = { class: "input-box send all-in" }
const _hoisted_37 = { class: "all-in-box" }
const _hoisted_38 = { key: 2 }
const _hoisted_39 = { class: "input-box" }
const _hoisted_40 = { class: "left" }
const _hoisted_41 = { class: "text" }
const _hoisted_42 = { class: "right" }
const _hoisted_43 = { class: "input-box" }
const _hoisted_44 = { class: "right" }
const _hoisted_45 = { class: "input-box" }
const _hoisted_46 = { class: "right" }
const _hoisted_47 = {
  key: 0,
  class: "input-box"
}
const _hoisted_48 = { class: "right" }
const _hoisted_49 = { class: "input-box" }
const _hoisted_50 = { class: "left" }
const _hoisted_51 = { class: "text" }
const _hoisted_52 = { class: "right" }
const _hoisted_53 = { class: "input-box send" }
const _hoisted_54 = { class: "right" }
const _hoisted_55 = { class: "input-box send all-in" }
const _hoisted_56 = { class: "all-in-box" }
const _hoisted_57 = { class: "btn-box" }
const _hoisted_58 = { class: "text" }
const _hoisted_59 = { class: "top" }
const _hoisted_60 = { class: "right" }
const _hoisted_61 = {
  key: 0,
  class: "text"
}
const _hoisted_62 = {
  key: 1,
  class: "text"
}
const _hoisted_63 = ["onClick"]
const _hoisted_64 = { class: "text" }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { class: "btn-box" }
const _hoisted_67 = { class: "mid" }
const _hoisted_68 = {
  class: "scroll-area",
  style: {"height":"450px","overflow":"scroll"}
}
const _hoisted_69 = { class: "left" }
const _hoisted_70 = { class: "box rectangle mb-line" }
const _hoisted_71 = { class: "box rectangle" }
const _hoisted_72 = { class: "right" }
const _hoisted_73 = { class: "box square mlr-line" }
const _hoisted_74 = { class: "box square mr-line" }
const _hoisted_75 = { class: "box square mr-line price" }
const _hoisted_76 = { class: "box square mr-line price" }
const _hoisted_77 = { class: "box square mr-line price" }
const _hoisted_78 = { class: "box square" }
const _hoisted_79 = {
  class: "scroll-area",
  style: {"height":"450px","overflow":"scroll"}
}
const _hoisted_80 = { class: "left" }
const _hoisted_81 = { class: "box rectangle mb-line" }
const _hoisted_82 = { class: "box rectangle" }
const _hoisted_83 = { class: "right" }
const _hoisted_84 = { class: "box square mlr-line" }
const _hoisted_85 = { class: "box square mr-line price" }
const _hoisted_86 = { class: "box square mr-line price" }
const _hoisted_87 = { class: "box square mr-line price" }
const _hoisted_88 = { class: "box square" }
const _hoisted_89 = { class: "scroll-area" }
const _hoisted_90 = { class: "left" }
const _hoisted_91 = { class: "box rectangle mb-line" }
const _hoisted_92 = { class: "box rectangle" }
const _hoisted_93 = { class: "right" }
const _hoisted_94 = { class: "box square mlr-line" }
const _hoisted_95 = { class: "left" }
const _hoisted_96 = { class: "box rectangle mb-line" }
const _hoisted_97 = { class: "box rectangle" }
const _hoisted_98 = { class: "right" }
const _hoisted_99 = { class: "box square mlr-line price" }
const _hoisted_100 = { class: "left" }
const _hoisted_101 = { class: "box rectangle mb-line" }
const _hoisted_102 = { class: "box rectangle" }
const _hoisted_103 = { class: "right" }
const _hoisted_104 = { class: "box square ml-line" }

import useBaseComponent from "@/baseComponent";
import {DEVICE_TYPE_MOBILE} from "@/constants/Global";
import moment from '../utils/moment';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import * as Utils from '../utils/Common';
import { UserLog } from '../network/UserLog';
// import { zeropad } from '../utils/zeropad';
import { NetworkService, Emit_ResetAmount, Emit_DrawTrans, Emit_TransferLog, Emit_Success } from '../network';
import { onUnmounted } from "vue";
import * as AccPwd from '../constants/AccPwd';

const Transfer_Deposit = 0; // 입금
const Transfer_Withdraw = 1; // 출금
const Transfer_Transfer = 2; // 대체
// const Transfer_Max = 3; // 구분자


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionForm',
  setup(__props) {

const { router, store, onMounted, reactive } = useBaseComponent();

// import { Theme } from '../constants';
const TransferInfo = [
    // Transfer_Deposit
    {
        idx: Transfer_Deposit,
        name: '입금',
        key: 'deposit',
    },
    // Transfer_Withdraw
    {
        idx: Transfer_Withdraw,
        name: '출금',
        key: 'withdraw',
    },
    // Transfer_Transfer
    {
        idx: Transfer_Transfer,
        name: '대체',
        key: 'transfer',
    },
];

let state = reactive({

    transType: Common.C_TRANS_DEPOSIT,
    tabIdx: Transfer_Deposit,
    refreshCount: 0,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    selectedItem: null as any,

    isOversea: Common.eNation_Domestic,
    
    depoAmount: '0',
    withdrawableAmount: '0',

    bWaitResponse: false,
    bDepositable: false,

    accNo: '',
    toAccNo: '',
    amount: '',
    sender: '',
    bankName: '상담센터로',
    bankAcc: '문의 주시기',
    bankHolder: '바랍니다.',

    transferLogs: [] as any,
    renderDepositList: [] as any,
    renderWithdrawList: [] as any,
    renderTransferList: [] as any,

    isShowTransactionLog:false,

    timerWaitResponse: null as any,

    // 계좌 정보 세팅
    timerInitData: null as any,
})

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TransactionForm');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_TransferLog, event_TransferLog);

    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TransferLog, event_TransferLog);

    if(state.timerWaitResponse){
        clearTimeout(state.timerWaitResponse);
    }
    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const event_ResetAmount = (params: any) => {
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    // const { isWithdraw } = params;
    state.amount = '';
    // 예탁잔고, 출금가능액 
    calcuAmount(state.isOversea);

    state.refreshCount++;
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    state.amount = '';
    // 예탁잔고, 출금가능액 
    calcuAmount(state.isOversea);

    state.refreshCount++;
}
const event_TransferLog = (params: any) => {
    const { transferIdx } = params;

    if (!((state.transType === Common.C_TRANS_DEPOSIT && transferIdx === 0) || (state.transType === Common.C_TRANS_WITHDRAW && transferIdx === 0) ||
      (state.transType === Common.C_TRANS_TRANSFER && transferIdx === 1))) {
      return;
    }

    // Transfer 로그 수신 완료.
    const transferLogs = store.AccountState.TransferLogs[transferIdx].filter((tr: any) => tr.iDepoNWithdraw === state.transType);
    state.transferLogs = transferLogs;
    state.refreshCount++;

    renderLogs();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    state.isOversea = isOversea;

    initAccPicker();
    
    // 보내는 사람 은행정보
    //const bankInfo = `(${AccountStore.szBank}) ${AccountStore.szAccountNo} ${AccountStore.szBankHolder}`;
    const bankInfo = store.AccountState.szBankHolder;
    state.sender = bankInfo;

    // 예탁잔고, 출금가능액 
    calcuAmount(isOversea);

    // 입금 가능여부
    const bDepositable = store.PossibleDeposit();
    if (state.transType === Common.C_TRANS_DEPOSIT) {
      if (bDepositable) {
        state.bankName = store.BrandState.szBrandAccountBank;
        state.bankAcc = store.BrandState.szBrandAccountNo;
        state.bankHolder = store.BrandState.szBrandAccountName;
      }
    } else if (state.transType === Common.C_TRANS_WITHDRAW) {
        state.bankName = store.AccountState.szBank;
        state.bankAcc = store.AccountState.szAccountNo;
        state.bankHolder = store.AccountState.szBankHolder;
    }
    state.bDepositable = bDepositable;

    // onPressRetrieve();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }
    
    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.isOversea = item.bOversea;
            state.pickAccItems[index].selected = true;
            state.accNo = `${item.value}  ${item.desc}`;
            state.accNo = `${item.desc}`;
            calcuAmount(state.selectedItem.bOversea);
        } else {
            state.pickAccItems[index].selected = false;
            if(state.transType === Common.C_TRANS_TRANSFER){
                state.toAccNo = `${item.value}  ${item.desc}`;
            }
        }
    });
}

const onTabPress = (tab: any) => {
    if (state.tabIdx !== tab.idx) {
        resetInfo();
        state.tabIdx = tab.idx;
        state.transType = tab.key === 'deposit' ? Common.C_TRANS_DEPOSIT : tab.key === 'withdraw' ? Common.C_TRANS_WITHDRAW : Common.C_TRANS_TRANSFER;
        initData();
    } else {
        return;
    }
    
    if(!state.isShowTransactionLog){
        initAccPicker();
    } else {
        onPressRetrieve();
    }
}

const checkAccPwd = () => {
    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const resetInfo = () => {
    //계좌 정보
    state.pickAccOpen =  false;
    // state.pickAccDesc =  '계좌를 선택하세요';
    // state.pickAccValue =  '';
    // state.pickAccItems =  [];
    // state.password =  '';

    state.amount = '';
    // state.bAccPassed =  false;
    // state.selectedItem =  null;
    // state.isOversea =  Common.eNation_Domestic;
    // state.depoAmount =  '0';
    // state.withdrawableAmount =  '0';
    // state.accNo =  '';
    // state.toAccNo =  '';
    // state.amount =  '';
    // state.sender =  '';
    // state.bankName =  '상담센터로';
    // state.bankAcc =  '문의 주시기';
    // state.bankHolder =  '바랍니다.';
}

const calcuAmount = (isOversea: any) => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 모든 선물의 포지션 잔량(반대매매) + 미체결, 또는 주문 중 가장 큰 값을 얻는다.
    const positionCount = store.GetClientMaxPositionAmount(false);
    const positionCountOversea = store.GetClientMaxPositionAmount(true);
    const cOvernightDeposit = store.GetOvernightDeposit(Common.G_FUTURES_DAY, Common.eOverTrading_Overnight);
    const cOvernightDepositOversea = store.GetOvernightDeposit(Common.G_FUTURES_OVERSEAS, Common.eOverTrading_Overnight);
    // 총 실현 손익을 구한다.
    const [totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmount = depoAmount + totalProfitDomestic;
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    // 평가예탁금에서 포지션이 잡힌 수만큼의  오버나잇 필요담보금은 제외하고 출금 가능
    const leftMoney = estimatedAmount - (positionCount * cOvernightDeposit);
    const leftMoneyOversea = estimatedAmountOversea - (positionCountOversea * cOvernightDepositOversea);

    // 출금가능금액
    let outMoney = Math.max(leftMoney, 0);
    let outMoneyOversea = Math.max(leftMoneyOversea, 0);
    // 포지션이 있을 경우 출금액을 제한한다.
    // 포지션 제외하고 출금을 원할 경우는 아래를 주석처리하면 된다.
    if (positionCount > 0) {
        outMoney = 0;
    }
    if (positionCountOversea > 0) {
        outMoneyOversea = 0;
    }

    if (isOversea === 1) {
        state.depoAmount = toLocaleString(depoAmountOversea);
        state.withdrawableAmount = toLocaleString(outMoneyOversea);
    } else {
        state.depoAmount = toLocaleString(depoAmount);
        state.withdrawableAmount = toLocaleString(outMoney);
    }
}

const waitReponse = () => {
    state.bWaitResponse = true;
    state.timerWaitResponse = setTimeout(() => {
        state.bWaitResponse = false;
    }, 1000);
}

const onDepositPress = () => {
    // const { systemStore } = this.props;

    waitReponse();

    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const szTransName = Common.g_szTransTypeName[state.transType];
    const amounts = parseInt(state.amount);
    if (amounts <= 0) {
        const message = `${szTransName}할 금액을 입력해주세요.`;
        store.setVisibleToast({isTypeWarning: true, message: message})
        return;
    }

    const futures = Common.G_FUTURES_DAY; // 고정
    const isWithraw = state.transType;
    const isOversea = state.isOversea;

    // 입금불가 계정은 상담센터로
    if (isWithraw === Common.C_TRANS_DEPOSIT && !state.bDepositable) {
        // this.moveScreen('Customer');
        if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
          router.push('/m/HelpDesk');
        } else {
          router.push('/HelpDesk')
        }
        return;
    }

    // 입금액 확인
    if (isWithraw === Common.C_TRANS_DEPOSIT && amounts > Common.C_DEPOSIT_AMOUNT_MAX) {
        const message = `입금 최대 신청금액은 ${toLocaleString(Common.C_DEPOSIT_AMOUNT_MAX)}원 입니다.`;
        store.setVisibleDialog({ message });
        return;
    }

    // 출금일 경우 출금 가능액 확인
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
        const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
        if (amounts > withdrawableAmount) {
            const message = `신청금액이 ${szTransName}가능금액보다 많습니다.\n최대 ${toLocaleString(withdrawableAmount)}원 ${szTransName} 가능합니다.`;
            store.setVisibleDialog({ message });
            return;
        }
        const depoAmount = parseInt(state.depoAmount.replace(/,/g, ''));
        if (amounts > depoAmount) {
            const message = `신청금액이 보유금액보다 많습니다.\n최대 ${toLocaleString(depoAmount)}원 ${szTransName} 가능합니다.`;
            store.setVisibleDialog({ message });
            return;
        }

        // 시작 hhmm, 종료시간hhmm(string)으로 현재 시간이 해당 시간인지 확인
        const bInTime = Utils.IsOpenTime(store.BrandState.szBrandWithdrawO, store.BrandState.szBrandWithdrawC);
        if (!bInTime) {
            const message = `${szTransName} 가능시간이 아닙니다.\n${szTransName} 가능시간을 확인해주세요.`;
            store.setVisibleDialog({ message });
            return;
        }
    }

    const szUserLog = `[${Common.g_szNationName[isOversea]} ${szTransName}신청] 금액:${Utils.SetComma(amounts)}원`;
    UserLog.log(szUserLog);
  
    NetworkService.send.depoNWithrawReq({futures, isWithraw, isOversea, amounts});
}

const onAllPress = () => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
      return;
    }

    const isWithraw = state.transType;

    let amounts = 0;
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
        const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
        amounts = withdrawableAmount;
    }

    state.amount = amounts.toString();
}

const onPressAppendAmount = (data: any) => {
  // 계정 비번 확인
  if (!checkAccPwd()) {
    return;
  }

  const isWithraw = state.transType;

  // 금액 수정
  if(data === 0 ) {
    // 금액 0
    state.amount = '';
  }
  else {
    let amounts = Number(state.amount);
    amounts += data;
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
      const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
      if(amounts > withdrawableAmount)
        amounts = withdrawableAmount;
    }
    state.amount = amounts.toString();
  }
}

// 입출금내역 요청
const transferLogsReq = (transType: any) => {
    // 90일 요청
    const szStartDate = moment(Date.now()).add(-90, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    transType = state.transType === Common.C_TRANS_TRANSFER ? Common.C_TRANS_TRANSFER : Common.C_TRANS_MAX;
    const transState = Common.C_TRANSSTATE_MAX;
    const bTransfer = state.transType === Common.C_TRANS_TRANSFER ? 1 : 0;

    const szUserLog = `[${transType === Common.C_TRANS_TRANSFER ? '대체내역' : '입출금내역'}] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);
  
    NetworkService.send.depositNWithdrawListReq({
        transType, 
        transState, 
        szStartDate, 
        szEndDate,
        bTransfer,
    });
}

const onPressRetrieve = () => {
    transferLogsReq(Common.C_TRANS_DEPOSIT);
    transferLogsReq(Common.C_TRANS_TRANSFER);
}

const renderDeposit = () => {

    state.renderDepositList = [];
    state.transferLogs.forEach((log: any)=> {
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szTransName: Common.g_szTransTypeName[log.iDepoNWithdraw],
            szDomesticOversea: log.szDomesticOversea,
            // szEventType: Common.g_szEventTypeName[log.iEventType],
            szEventType: log.szEventName,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: toLocaleString(log.iBeforeDepositAmount),
            szAfterAmount: toLocaleString(log.iAfterDepositAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderDepositList.push(objOrder);
    });
}

const renderWithdraw = () => {
    state.renderWithdrawList = [];
    state.transferLogs.forEach((log: any)=> {
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szTransName: Common.g_szTransTypeName[log.iDepoNWithdraw],
            szDomesticOversea: log.szDomesticOversea,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: toLocaleString(log.iBeforeWithdrawAmount),
            szAfterAmount: toLocaleString(log.iAfterWithdrawAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderWithdrawList.push(objOrder);
    });
}

const renderTransfer = () => {
    state.renderTransferList = [];
    state.transferLogs.forEach((log: any)=> {
        const szDomesticOversea = log.szDomesticOversea;
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szDomesticOversea: szDomesticOversea,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iBeforeWithdrawAmount) : toLocaleString(log.iBeforeDepositAmount),
            szAfterAmount: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iAfterWithdrawAmount) : toLocaleString(log.iAfterDepositAmount),
            szBeforeAmountOversea: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iBeforeDepositAmount) : toLocaleString(log.iBeforeWithdrawAmount),
            szAfterAmountOversea: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iAfterDepositAmount) : toLocaleString(log.iAfterWithdrawAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderTransferList.push(objOrder);
    });
}

const renderLogs = () => {
    state.transType === Common.C_TRANS_DEPOSIT ? renderDeposit() : state.transType === Common.C_TRANS_WITHDRAW ? renderWithdraw() :renderTransfer();
}

const onToggleTransactionLog = () => {
    if(state.isShowTransactionLog){
        state.isShowTransactionLog = false;
    } else {
        state.isShowTransactionLog = true;
        onPressRetrieve()
    }
}

return (_ctx: any,_cache: any) => {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { text: '입/출금' }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["tab-area", {'hide':_unref(state).isShowTransactionLog}])
      }, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(TransferInfo, (item, i) => {
          return _createElementVNode("div", {
            class: _normalizeClass(["tab", [{ on: _unref(state).tabIdx === i },`no`+ i]]),
            key: i,
            onClick: ($event: any) => (onTabPress(item))
          }, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(item.name), 1)
          ], 10, _hoisted_3)
        }), 64))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["big-box", {'hide':_unref(state).isShowTransactionLog}])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  class: "drop-sort",
                  type: "checkbox",
                  id: "dropPageCount",
                  name: "dropPageCount",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickAccOpen) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _unref(state).pickAccOpen]
                ]),
                _createElementVNode("label", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_unref(state).pickAccDesc), 1),
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-down", [{ on: _unref(state).pickAccOpen }]])
                    }, null, 2)
                  ])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickAccItems, (item, index) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: index,
                      onClick: ($event: any) => (onPickAccChangeValue(item)),
                      class: _normalizeClass(["text", { active: item.value === _unref(state).pickAccValue }])
                    }, _toDisplayString(item.desc), 11, _hoisted_12))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["deposit-area mid", [{'withdraw-area':Common.C_TRANS_WITHDRAW}]])
        }, [
          (_unref(state).transType === Common.C_TRANS_WITHDRAW)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("p", _hoisted_16, _toDisplayString(`${Common.g_szNationName[_unref(state).isOversea]} 보유금액`), 1)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).depoAmount) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).depoAmount]
                    ]),
                    _cache[27] || (_cache[27] = _createElementVNode("p", { class: "currency" }, "원", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("p", _hoisted_20, _toDisplayString(`${Common.g_szNationName[_unref(state).isOversea]} 출금가능액`), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).withdrawableAmount) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).withdrawableAmount]
                    ]),
                    _cache[28] || (_cache[28] = _createElementVNode("p", { class: "currency" }, "원", -1))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(state).transType === Common.C_TRANS_TRANSFER)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "보내는 계좌")
                    ], -1)),
                    _createElementVNode("div", _hoisted_25, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).accNo) = $event)),
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _unref(state).accNo]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "받을 계좌")
                    ], -1)),
                    _createElementVNode("div", _hoisted_27, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).toAccNo) = $event)),
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _unref(state).toAccNo]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["price-box", {'mt10':_unref(state).transType === Common.C_TRANS_TRANSFER}])
                }, [
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("p", _hoisted_30, _toDisplayString(`${Common.g_szNationName[_unref(state).isOversea]} 보유금액`), 1)
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(state).depoAmount) = $event)),
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _unref(state).depoAmount]
                      ]),
                      _cache[31] || (_cache[31] = _createElementVNode("p", { class: "currency" }, "원", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _cache[33] || (_cache[33] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "대체 가능금액")
                    ], -1)),
                    _createElementVNode("div", _hoisted_33, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(state).withdrawableAmount) = $event)),
                        readonly: ""
                      }, null, 512), [
                        [_vModelText, _unref(state).withdrawableAmount]
                      ]),
                      _cache[32] || (_cache[32] = _createElementVNode("p", { class: "currency" }, "원", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _cache[35] || (_cache[35] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" }, "대체 신청금액")
                    ], -1)),
                    _createElementVNode("div", _hoisted_35, [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(state).amount) = $event)),
                        placeholder: "금액을 입력해주세요"
                      }, null, 512), [
                        [_vModelText, _unref(state).amount]
                      ]),
                      _cache[34] || (_cache[34] = _createElementVNode("p", { class: "currency" }, "원", -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _cache[41] || (_cache[41] = _createElementVNode("div", { class: "left" }, [
                      _createElementVNode("p", { class: "text" })
                    ], -1)),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("button", {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (onPressAppendAmount(100000))),
                        class: "flex-center-center"
                      }, _cache[36] || (_cache[36] = [
                        _createElementVNode("span", { class: "text" }, "+10만", -1)
                      ])),
                      _createElementVNode("button", {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (onPressAppendAmount(1000000))),
                        class: "flex-center-center"
                      }, _cache[37] || (_cache[37] = [
                        _createElementVNode("span", { class: "text" }, "+100만", -1)
                      ])),
                      _createElementVNode("button", {
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (onPressAppendAmount(10000000))),
                        class: "flex-center-center"
                      }, _cache[38] || (_cache[38] = [
                        _createElementVNode("span", { class: "text" }, "+1000만", -1)
                      ])),
                      _createElementVNode("button", {
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (onAllPress())),
                        class: "flex-center-center"
                      }, _cache[39] || (_cache[39] = [
                        _createElementVNode("span", { class: "text" }, "전액", -1)
                      ])),
                      _createElementVNode("button", {
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (onPressAppendAmount(0))),
                        class: "flex-center-center"
                      }, _cache[40] || (_cache[40] = [
                        _createElementVNode("span", { class: "text" }, "초기화", -1)
                      ]))
                    ])
                  ])
                ], 2)
              ]))
            : _createCommentVNode("", true),
          (_unref(state).transType === Common.C_TRANS_DEPOSIT || _unref(state).transType === Common.C_TRANS_WITHDRAW)
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("p", _hoisted_41, _toDisplayString(`${Common.g_szTransTypeName[_unref(state).transType]} 계좌`), 1)
                  ]),
                  _createElementVNode("div", _hoisted_42, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(state).accNo) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).accNo]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "left" }, [
                    _createElementVNode("p", { class: "text" }, "은행명")
                  ], -1)),
                  _createElementVNode("div", _hoisted_44, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(state).bankName) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).bankName]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_45, [
                  _cache[43] || (_cache[43] = _createElementVNode("div", { class: "left" }, [
                    _createElementVNode("p", { class: "text" }, "계좌번호")
                  ], -1)),
                  _createElementVNode("div", _hoisted_46, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(state).bankAcc) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).bankAcc]
                    ])
                  ])
                ]),
                (_unref(state).transType === Common.C_TRANS_DEPOSIT)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                      _cache[44] || (_cache[44] = _createElementVNode("div", { class: "left" }, [
                        _createElementVNode("p", { class: "text" }, "예금주")
                      ], -1)),
                      _createElementVNode("div", _hoisted_48, [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(state).bankHolder) = $event)),
                          readonly: ""
                        }, null, 512), [
                          [_vModelText, _unref(state).bankHolder]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("p", _hoisted_51, _toDisplayString(_unref(state).transType === Common.C_TRANS_DEPOSIT ? '보내시는분' : '받으실 분'), 1)
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(state).sender) = $event)),
                      readonly: ""
                    }, null, 512), [
                      [_vModelText, _unref(state).sender]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_53, [
                  _cache[46] || (_cache[46] = _createElementVNode("div", { class: "left" }, [
                    _createElementVNode("p", { class: "text" }, "신청금액")
                  ], -1)),
                  _createElementVNode("div", _hoisted_54, [
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(state).amount) = $event)),
                      placeholder: "금액을 입력해주세요"
                    }, null, 512), [
                      [_vModelText, _unref(state).amount]
                    ]),
                    _cache[45] || (_cache[45] = _createElementVNode("p", { class: "currency" }, "원", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _cache[52] || (_cache[52] = _createElementVNode("div", { class: "left" }, [
                    _createElementVNode("p", { class: "text" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_56, [
                    _createElementVNode("button", {
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (onPressAppendAmount(100000))),
                      class: "flex-center-center"
                    }, _cache[47] || (_cache[47] = [
                      _createElementVNode("span", { class: "text" }, "+10만", -1)
                    ])),
                    _createElementVNode("button", {
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (onPressAppendAmount(1000000))),
                      class: "flex-center-center"
                    }, _cache[48] || (_cache[48] = [
                      _createElementVNode("span", { class: "text" }, "+100만", -1)
                    ])),
                    _createElementVNode("button", {
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (onPressAppendAmount(10000000))),
                      class: "flex-center-center"
                    }, _cache[49] || (_cache[49] = [
                      _createElementVNode("span", { class: "text" }, "+1000만", -1)
                    ])),
                    (_unref(state).transType === Common.C_TRANS_WITHDRAW)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          onClick: _cache[22] || (_cache[22] = ($event: any) => (onAllPress())),
                          class: "flex-center-center"
                        }, _cache[50] || (_cache[50] = [
                          _createElementVNode("span", { class: "text" }, "전액", -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (onPressAppendAmount(0))),
                      class: "flex-center-center"
                    }, _cache[51] || (_cache[51] = [
                      _createElementVNode("span", { class: "text" }, "초기화", -1)
                    ]))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("button", {
              class: "deposit btn",
              onClick: _cache[24] || (_cache[24] = ($event: any) => (onDepositPress()))
            }, [
              _createElementVNode("span", _hoisted_58, _toDisplayString(`${Common.g_szTransTypeName[_unref(state).transType]}신청`), 1)
            ])
          ])
        ], 2)
      ], 2),
      (_unref(state).tabIdx === Transfer_Deposit || _unref(state).tabIdx === Transfer_Withdraw || _unref(state).tabIdx === Transfer_Transfer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["upComponent", {'top':_unref(state).isShowTransactionLog}])
          }, [
            _createElementVNode("div", _hoisted_59, [
              _createElementVNode("div", {
                class: "click-area",
                onClick: _cache[25] || (_cache[25] = ($event: any) => (onToggleTransactionLog()))
              }, [
                _cache[53] || (_cache[53] = _createElementVNode("div", { class: "left" }, [
                  _createElementVNode("p", { class: "text" }, "입/출금,대체내역")
                ], -1)),
                _createElementVNode("div", _hoisted_60, [
                  (!_unref(state).isShowTransactionLog)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_61, "표시"))
                    : (_openBlock(), _createElementBlock("p", _hoisted_62, "숨김")),
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "",
                    class: _normalizeClass({ 'on': _unref(state).isShowTransactionLog === false })
                  }, null, 2)
                ])
              ])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-area", {'hide':!_unref(state).isShowTransactionLog}])
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(TransferInfo, (item, i) => {
                return _createElementVNode("div", {
                  class: _normalizeClass(["tab", [{ on: _unref(state).tabIdx === i },`no`+ i]]),
                  key: i,
                  onClick: ($event: any) => (onTabPress(item))
                }, [
                  _createElementVNode("p", _hoisted_64, _toDisplayString(item.name), 1)
                ], 10, _hoisted_63)
              }), 64))
            ], 2),
            (_unref(state).isShowTransactionLog)
              ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                  _createElementVNode("div", _hoisted_66, [
                    _createElementVNode("button", {
                      class: "look-up",
                      onClick: _cache[26] || (_cache[26] = ($event: any) => (onPressRetrieve()))
                    }, _cache[54] || (_cache[54] = [
                      _createElementVNode("span", { class: "text" }, "조회", -1)
                    ]))
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    (_unref(state).transType === Common.C_TRANS_DEPOSIT)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["info-box", {'deposit':_unref(state).transType === Common.C_TRANS_DEPOSIT}])
                        }, [
                          _cache[55] || (_cache[55] = _createStaticVNode("<div class=\"info-title\"><div class=\"left\"><div class=\"box rectangle mb-line\">신청일시</div><div class=\"box rectangle\">처리일시</div></div><div class=\"right\"><div class=\"box square mlr-line\">국내/해외</div><div class=\"box square mr-line event\">이벤트 <br>종류</div><div class=\"box square mr-line price\">이전금액</div><div class=\"box square mr-line price\">신청금액</div><div class=\"box square mr-line price\">이후잔고</div><div class=\"box square\">처리상태</div></div></div>", 1)),
                          _createElementVNode("div", _hoisted_68, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderDepositList, (item, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "info-item",
                                key: idx
                              }, [
                                _createElementVNode("div", _hoisted_69, [
                                  _createElementVNode("div", _hoisted_70, _toDisplayString(item.szRequestDate), 1),
                                  _createElementVNode("div", _hoisted_71, _toDisplayString(item.szProcessDate), 1)
                                ]),
                                _createElementVNode("div", _hoisted_72, [
                                  _createElementVNode("div", _hoisted_73, _toDisplayString(item.szDomesticOversea), 1),
                                  _createElementVNode("div", _hoisted_74, _toDisplayString(item.szEventType), 1),
                                  _createElementVNode("div", _hoisted_75, _toDisplayString(item.szBeforeAmount), 1),
                                  _createElementVNode("div", _hoisted_76, _toDisplayString(item.szAmount), 1),
                                  _createElementVNode("div", _hoisted_77, _toDisplayString(item.szAfterAmount), 1),
                                  _createElementVNode("div", _hoisted_78, _toDisplayString(item.szState), 1)
                                ])
                              ]))
                            }), 128))
                          ])
                        ], 2))
                      : (_unref(state).transType === Common.C_TRANS_WITHDRAW)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["info-box", {'withdraw':_unref(state).transType === Common.C_TRANS_WITHDRAW}])
                          }, [
                            _cache[56] || (_cache[56] = _createStaticVNode("<div class=\"info-title\"><div class=\"left\"><div class=\"box rectangle mb-line\">신청일시</div><div class=\"box rectangle\">처리일시</div></div><div class=\"right\"><div class=\"box square mlr-line\">국내/해외</div><div class=\"box square mr-line price\">이전금액</div><div class=\"box square mr-line price\">신청금액</div><div class=\"box square mr-line price\">이후잔고</div><div class=\"box square\">처리상태</div></div></div>", 1)),
                            _createElementVNode("div", _hoisted_79, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderWithdrawList, (item, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "info-item",
                                  key: idx
                                }, [
                                  _createElementVNode("div", _hoisted_80, [
                                    _createElementVNode("div", _hoisted_81, _toDisplayString(item.szRequestDate), 1),
                                    _createElementVNode("div", _hoisted_82, _toDisplayString(item.szProcessDate), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_83, [
                                    _createElementVNode("div", _hoisted_84, _toDisplayString(item.szDomesticOversea), 1),
                                    _createElementVNode("div", _hoisted_85, _toDisplayString(item.szBeforeAmount), 1),
                                    _createElementVNode("div", _hoisted_86, _toDisplayString(item.szAmount), 1),
                                    _createElementVNode("div", _hoisted_87, _toDisplayString(item.szAfterAmount), 1),
                                    _createElementVNode("div", _hoisted_88, _toDisplayString(item.szState), 1)
                                  ])
                                ]))
                              }), 128))
                            ])
                          ], 2))
                        : (_unref(state).transType === Common.C_TRANS_TRANSFER)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 2,
                              class: _normalizeClass(["info-box", {'transfer':_unref(state).transType === Common.C_TRANS_TRANSFER}])
                            }, [
                              _cache[57] || (_cache[57] = _createStaticVNode("<div class=\"info-title\"><div class=\"left\"><div class=\"box rectangle mb-line\">신청일시</div><div class=\"box rectangle\">처리일시</div></div><div class=\"right mlr-line\"><div class=\"box square\">신청계좌</div></div><div class=\"left\"><div class=\"box rectangle mb-line\">이전국내잔고</div><div class=\"box rectangle\">이전해외잔고</div></div><div class=\"right mlr-line\"><div class=\"box square price\">대체금액</div></div><div class=\"left\"><div class=\"box rectangle mb-line\">이후국내잔고</div><div class=\"box rectangle\">이후해외잔고</div></div><div class=\"right ml-line\"><div class=\"box square\">처리상태</div></div></div>", 1)),
                              _createElementVNode("div", _hoisted_89, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).renderTransferList, (item, idx) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "info-item",
                                    key: idx
                                  }, [
                                    _createElementVNode("div", _hoisted_90, [
                                      _createElementVNode("div", _hoisted_91, _toDisplayString(item.szRequestDate), 1),
                                      _createElementVNode("div", _hoisted_92, _toDisplayString(item.szProcessDate), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_93, [
                                      _createElementVNode("div", _hoisted_94, _toDisplayString(item.szDomesticOversea), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_95, [
                                      _createElementVNode("div", _hoisted_96, _toDisplayString(item.szBeforeAmount), 1),
                                      _createElementVNode("div", _hoisted_97, _toDisplayString(item.szBeforeAmountOversea), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_98, [
                                      _createElementVNode("div", _hoisted_99, _toDisplayString(item.szAmount), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_100, [
                                      _createElementVNode("div", _hoisted_101, _toDisplayString(item.szAfterAmount), 1),
                                      _createElementVNode("div", _hoisted_102, _toDisplayString(item.szAfterAmountOversea), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_103, [
                                      _createElementVNode("div", _hoisted_104, _toDisplayString(item.szState), 1)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ], 2))
                          : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})