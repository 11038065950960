<template>
    <div class="wrap">
        <Header :text="'오버나잇'" />
        <div class="overNight innerHeight ">
            <div class="top-box">
                <div class="bank-account">
                    <div class="dropdown-sort">
                        <div class="select-sort">
                            <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickAccOpen" />
                            <label for="dropPageCount" class="for-dropsort pageCount">
                                <p class="out">{{ state.pickAccDesc }}<i class="icon-down" :class="[{ on: state.pickAccOpen }]" /></p>
                            </label>
                            <div class="section-sort" v-if="state.pickAccValue">
                                <p v-for="(item, index) in state.pickAccItems" :key="index" @click="onPickAccChangeValue(item)" class="text" :class="{ active: item.value === state.pickAccValue }">
                                    {{ item.desc }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="bank-password">
                    <input type="password" placeholder="계좌비밀번호" v-model="state.password" :readonly="state.bAccPassed" />
                </div> -->
                <div class="btn-box">
                    <button class="inquire" @click="onSearchPress()">
                        <span class="text">조회</span>
                    </button>
                </div>
            </div>
            <div class="mid-box">
                <p class="title">오버나잇 가능 기준 (담보금액)</p>
                <div class="text-box">
                    <p class="text">국내선물:<span>{{ toLocaleString(store.BrandState.iDomesticOvernightDeposit) }}원</span></p>
                    <p class="text">해외선물:<span>{{ toLocaleString(store.BrandState.iOverseaOvernightDeposit) }}원</span></p>
                </div>
                <p class="caution">*보다 자세한 사항은 공지사항을 통해 확인해주십시오.</p>
            </div>
            <div class="bottom-box">
                <div class="info-box" >
                    <div class="info-title head">
                        <div class="left">
                            <div class="box rectangle mb-line">
                                <p class="text">거래종목</p>
                            </div>
                            <div class="box rectangle mlr-line mb-line">
                                <p class="text">매매구분</p>
                            </div>
                            <div class="box rectangle mb-line">
                                <p class="text">보유체결</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">평균단가</p>
                            </div>
                            <div class="box rectangle mlr-line">
                                <p class="text">평가손익</p>
                            </div>
                            <div class="box rectangle">
                                <p class="text">실현손익</p>
                            </div>
                        </div>
                        <div class="right">
                            <div class="box square">
                                <p class="text">OV</p>
                            </div>
                        </div>
                    </div>
                    <div class="info-item-scroll">
                        <div class="info-title item" v-for="(item, index) in state.renderOvernightList" :key="index">
                            <div class="left">
                                <div class="box rectangle mb-line">
                                    <p class="text">{{ item.name }}</p>
                                </div>
                                <div class="box rectangle  mlr-line mb-line">
                                    <p class="text">{{ item.tradeType }}</p>
                                </div>
                                <div class="box rectangle mb-line">
                                    <p class="text">{{ item.amount }}</p>
                                </div>
                                <div class="box rectangle">
                                    <p class="text">{{ item.price }}</p>
                                </div>
                                <div class="box rectangle  mlr-line">
                                    <p class="text">{{ toLocaleString(item.profit) }}</p>
                                </div>
                                <div class="box rectangle">
                                    <p class="text">{{ toLocaleString(item.net) }}</p>
                                </div>
                            </div>
                            <div class="right">
                                <div class="box square">
                                    <button class="settlement" :class="{'off':item.bOvernight}" @click="onActionPress(item, item.bOvernight)">
                                        <span>
                                            {{ item.bOvernight ? '취소' : item.bRequest ? '오버나잇' : Common.g_szOvernightStateName[item.ovState] }}
                                        </span>
                                        <!-- <span class="text" v-if="state.btnType">오버나잇</span>
                                        <span class="text" v-else>취소</span> -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <MenuTab /> -->
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, onUnmounted, reactive } = useBaseComponent();


// import { Theme } from '../constants';
// import i18n from '../services/i18n';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import { NetworkService, Emit_DrawTrans, Emit_Success } from '../network';
import { UserLog } from '../network/UserLog';
import * as Utils from '../utils/Common';
import { OvernightReq } from '../models';
import * as AccPwd from '../constants/AccPwd';

let state = reactive({
    btnType:false,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    selectedItem: null as any,
    overnights: [] as any,
    isOversea: Common.eNation_Domestic,

    renderOvernightList: [] as any,

    // 계좌 정보 세팅
    timerInitData: null as any,
})

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('OverNight');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);


    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);

    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})


const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 오버나잇 리스트 업데이트
    refreshOrders();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS;
    state.isOversea = isOversea ? 1 : 0;

    initAccPicker();
    // 비밀번호 확인
    if (state.bAccPassed) {
        onSearchPress();
    }
    // onSearchPress();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.isOversea = item.bOversea;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

const checkAccPwd = () => {
    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const onSearchPress = () => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const selectedItem = state.selectedItem;

    if (selectedItem) {
        state.isOversea = selectedItem.bOversea;

        // 오버나잇 리스트 업데이트
        refreshOrders();
    }

    const message = '오버나잇(체결주문)을 조회하였습니다.';
    store.setVisibleToast({message: message, duration: 1200})
}

const refreshOrders = () => {
    // 체결 리스트를 얻는다.
    const overnights = store.GetClientOppositeList();
    //const overnights = TEST_ORDERS;
    state.overnights = overnights;
    renderOvernight();
}

// 오버나잇 / 취소
const onActionPress = (order: any, bCancel: any) => {
     // 계정 비번 확인
     if (!checkAccPwd()) {
        return;
    }

    const futures = order.iFutures;
    const tradeType = order.iTradeType;
    const orderIdx = order.iOrderIdx;
    const ovState = order.iOvernightState;

    let overnight = new OvernightReq();
    overnight.iOrderIdx = orderIdx;
    overnight.iFutures = futures;
    overnight.iTradeType = tradeType;
    overnight.iOppositeAmount = order.iOppositeAmount;
    overnight.iOverTrading = Common.eOverTrading_Overnight;

    // 오버나잇
    if (!bCancel) {

        if (order.iOppositeAmount === 0 || ovState !== Common.eOvernight_No) {
            store.setVisibleDialog({ message: '오버나잇 신청이 불가능한 주문입니다.' });
            return;
        }

        // 오버나잇 신청주문을 보낸다.
        overnight.iOvernightState = Common.eOvernight_Request;
        // OvernightReq list
        let reqList = [
            overnight
        ];

        UserLog.overnight(reqList);
        NetworkService.send.overnightReq({
            reqList,
        });

    } else {

        // 취소
        if (order.iOppositeAmount === 0 || ovState !== Common.eOvernight_Request) {
            store.setVisibleDialog({ message: '오버나잇 취소가 불가능한 주문입니다.' });
            return;
        }

        // 오버나잇 신청주문을 보낸다.
        overnight.iOvernightState = Common.eOvernight_Cancel;
        // OvernightReq list
        let reqList = [
            overnight
        ];
        UserLog.overnight(reqList);
        NetworkService.send.overnightReq({
            reqList,
        });
    }
}

const renderOvernight = () => {

    state.renderOvernightList = [];
    state.overnights.forEach((order: any)=> {
        const futures = order.iFutures;
        const profit = store.GetGainNLoss(order.iTradeType, order.dbContractPrice, order.iOrderAmount, futures);
        const fee = store.GetFutureCommission(futures, order.dbContractPrice);

        const objOrder = {
            futures: futures,
            name: Common.g_szFuturesNameMini[futures],
            price: Utils.MicroPriceFormatStr(order.dbContractPrice, futures),
            tradeType: Common.g_szTradeTypeName[order.iTradeType],
            amount: order.iOppositeAmount,
            iFutures: order.iFutures,
            iOrderIdx: order.iOrderIdx,
            iTradeType: order.iTradeType,
            iOppositeAmount: order.iOppositeAmount,
            iOvernightState: order.iOvernightState,
            profit:profit,
            fee: fee,
            net: profit - fee,
            ovState: order.iOvernightState,
            bOvernight: order.iOvernightState === Common.eOvernight_Request,
            bRequest: order.iOvernightState === Common.eOvernight_No,
        }
        state.renderOvernightList.push(objOrder)
    })
}


</script>
