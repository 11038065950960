// import dayjs from "dayjs";
import { defineEmits, defineProps, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch, nextTick } from "vue";
// import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import axios from "./axios";
import {useStore} from "./pinia"

export default function useBaseComponent() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // const i18n = useI18n({ useScope: "global" });
    
    const openToast = (message:string) => {
        const toast = document.querySelector("#app-toast");
        toast!.classList.remove("display-none");
        document.querySelector("#app-toast-text")!.innerHTML = message;
        setTimeout(() => {
            toast!.classList.add("display-none");
        }, 3000);
    };

    const closeToast = () => {
        const toast = document.querySelector('#app-toast');
        toast!.classList.add("display-none");
    };

    const openPopupOk = (message:string) => {
        const popup = document.querySelector("#app-popup");
        popup!.classList.remove("display-none");
        document.querySelector("#app-popup-text")!.innerHTML = message;
    };

    const openPopupTwo = (message:string) => {
        const popup = document.querySelector("#app-popup");
        popup!.classList.remove("display-none");
        document.querySelector("#app-popup-text")!.innerHTML = message;
    };

    const closePopup = () => {
        const popup = document.querySelector('#app-popup');
        popup!.classList.add("display-none");
    };

    return {
        axios,
        // dayjs,
        // i18n,
        route,
        router,
        store,
        defineEmits,
        defineProps,
        onBeforeMount,
        onBeforeUnmount,
        onMounted,
        onUnmounted,
        reactive,
        ref,
        watch,
        nextTick,
        openPopupOk,
        closePopup,
        openToast,
        closeToast,
    };
}
