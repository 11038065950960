import { Buffer } from 'buffer';
import iconv from 'iconv-lite';

import { NETWORK_STRING_ENCODE } from './Constants';


export const PACKET_BUFFER_SIZE = 8192;
export const PACKET_HEADER_SIZE = (4+2+2);  // size(INT) + protocolID(WORD) + extraCode(WORD)

const PACKET_SIZE_POS = 0;
const PACKET_ID_POS = 4;
const PACKET_EXTRA_POS = 6;

export class Packet {
  _buffer = null as any;
  _readPos = 0;
  _writePos = 0;

  constructor(id = 0, buffSize = PACKET_BUFFER_SIZE, sourceBuff = {} as Buffer, readPos = PACKET_HEADER_SIZE, writePos = PACKET_HEADER_SIZE) {
    this._buffer = Buffer.allocUnsafe(buffSize);
    if (sourceBuff === null) {
      this.clear();
      this.setId(id);
    } else {
      sourceBuff.copy(this._buffer, 0, 0, buffSize);
      this._readPos = readPos;
      this._writePos = writePos;
    }
  }

  from(packet:any) {
    this._buffer.from(packet.Buffer());
    this._readPos = packet.readPos;
    this._writePos = packet.writePos;
  }

  // 패킷을 초기화 한다.
  clear() {
    this._buffer.fill(0);
    // packet size
    this._buffer.writeInt32LE(PACKET_HEADER_SIZE, 0);
    this._readPos = PACKET_HEADER_SIZE;
    this._writePos = PACKET_HEADER_SIZE;

    this.setId(0);
  }

  // 패키 버퍼를 write 사이즈(writePos)에 맞게 조절한다.
  shrink() {
    const dataSize = this.writePos;
    if (this.bufferLength > dataSize) {
      const shBuff = Buffer.allocUnsafe(dataSize);
      this._buffer.copy(shBuff, 0, 0, dataSize);
      this._buffer = shBuff;
    }
  }

  get buffer() {
    return this._buffer;
  }

  setReadPos(pos: any) {
    this._readPos = pos;
  }

  get readPos() {
    return this._readPos;
  }

  setWritePos(pos: any) {
    this._writePos = pos;
  }

  get writePos() {
    return this._writePos;
  }

  // 패킷 ID
  setId(id: any) {
    this._buffer.writeInt16LE(id, PACKET_ID_POS);
  }

  get id() {
    return this._buffer.readInt16LE(PACKET_ID_POS);
  }

  // 패킷 Size
  setSize(size: any) {
    this._buffer.writeInt32LE(size, PACKET_SIZE_POS);
  }

  get size() {
    return this._buffer.readInt32LE(PACKET_SIZE_POS);
  }

  // 패킷 Extra code
  setExtraCode(extraCode: any) {
    this._buffer.writeInt16LE(extraCode, PACKET_EXTRA_POS);
  }

  get extraCode() {
    return this._buffer.readInt16LE(PACKET_EXTRA_POS);
  }

  get bufferLength() {
    return this._buffer !== null ? this._buffer.length : 0;
  }

  writeData(srcBuff: any) {
    const size = srcBuff.length;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    srcBuff.copy(this._buffer, this._writePos, 0, size);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeDataPos(pos: any, srcBuff: any) {
    const size = srcBuff.length;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    srcBuff.copy(this._buffer, writePos, 0, size);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readData(trgBuff: any) {
    const size = trgBuff.length;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.copy(trgBuff, 0, this._readPos, this._readPos + size);
    this._readPos += size;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readDataPos(pos: any, trgBuff: any) {
    const size = trgBuff.length;
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.copy(trgBuff, 0, readPos, readPos + size);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);
  }

  copy(trgBuff: any, trgStart: any, size: any) {
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.copy(trgBuff, trgStart, this._readPos, this._readPos + size);
    this._readPos += size;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  copyPos(pos: any, trgBuff: any, trgStart: any, size: any) {
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.copy(trgBuff, trgStart, readPos, readPos + size);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);
  }


  writeBool(value: any) {
    const size = 1;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeInt8(value, this._writePos);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeBoolPos(pos: any, value: any) {
    const size = 1;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeInt8(value, writePos);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readBool() {
    const size = 1;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readInt8(this._readPos);
    this._readPos += size;

    return value;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readBoolPos(pos: any) {
    const size = 1;
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readInt8(readPos);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);

    return value;
  }

  writeInt(value: any) {
    const size = 4;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeInt32LE(value, this._writePos);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeIntPos(pos: any, value: any) {
    const size = 4;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeInt32LE(value, writePos);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readInt() {
    const size = 4;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readInt32LE(this._readPos);
    this._readPos += size;

    return value;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readIntPos(pos: any) {
    const size = 4;
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readInt32LE(readPos);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);

    return value;
  }

  writeInt64(value: any) {
    const size = 8;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeBigInt64LE(value, this._writePos);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeInt64Pos(pos: any, value: any) {
    const size = 8;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeBigInt64LE(value, writePos);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readInt64() {
    const size = 8;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readBigInt64LE(this._readPos);
    this._readPos += size;

    return value;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readInt64Pos(pos: any) {
    const size = 8;
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readBigInt64LE(readPos);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);

    return value;
  }

  writeDouble(value: any) {
    const size = 8;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeDoubleLE(value, this._writePos);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeDoublePos(pos: any, value: any) {
    const size = 8;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    this._buffer.writeDoubleLE(value, writePos);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readDouble() {
    const size = 8;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readDoubleLE(this._readPos);
    this._readPos += size;

    return value;
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readDoublePos(pos: any) {
    const size = 8;
    let readPos = pos + PACKET_HEADER_SIZE;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    const value = this._buffer.readDoubleLE(readPos);
    readPos += size;
    this._readPos = Math.max(this._readPos, readPos);

    return value;
  }

  writeString(value: any) {
    // 멀티바이트로 변환
    const buff = iconv.encode(value, NETWORK_STRING_ENCODE);
    const strLen = buff.length;
    const size = strLen + 1;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    buff.copy(this._buffer, this._writePos, 0, strLen);
    // string 종료문자(\0) 포함 시킨다.
    this._buffer.writeInt8(0, this._writePos + strLen);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeStringPos(pos: any, value: any) {
    // 멀티바이트로 변환
    const buff = iconv.encode(value, NETWORK_STRING_ENCODE);
    const strLen = buff.length;
    const size = strLen + 1;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    buff.copy(this._buffer, writePos, 0, strLen);
    // string 종료문자(\0) 포함 시킨다.
    this._buffer.writeInt8(0, writePos + strLen);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  readString() {
    // 스트링 사이즈를 구한다.
    const packetSize = this.size;
    let strLen = 0, size = 0;
    for (let idx = this._readPos; idx < packetSize; idx++) {
      const char = this._buffer[idx];
      if (char === 0) {
        strLen = idx - this._readPos;
        break;
      }
    }
    size = strLen + 1;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    if (strLen > 0) {
      // iconv.decode()에서 Buffer에서 0x00을 포함시키면 eur-kr string으로, 0x00을 빼면 unicode로 변환된다.
      const buff = Buffer.alloc(strLen);
      this._buffer.copy(buff, 0, this._readPos, this._readPos + strLen);
      this._readPos += size;
  
      const value = iconv.decode(buff, NETWORK_STRING_ENCODE);

      return value;
    } else {
      // '\0'(empty string) 포함
      this._readPos += size;
    }

    return '';
  }

  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readStringPos(pos: any) {
    // 스트링 사이즈를 구한다.
    const packetSize = this.size;
    let strLen = 0, size = 0;
    let readPos = pos + PACKET_HEADER_SIZE;
    for (let idx = readPos; idx < packetSize; idx++) {
      const char = this._buffer[idx];
      if (char === 0) {
        strLen = idx - readPos;
        break;
      }
    }
    size = strLen + 1;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    if (strLen > 0) {
      // iconv.decode()에서 Buffer에서 0x00을 포함시키면 eur-kr string으로, 0x00을 빼면 unicode로 변환된다.
      const buff = Buffer.alloc(strLen);
      this._buffer.copy(buff, 0, readPos, readPos + strLen);
      readPos += size;
      this._readPos = Math.max(this._readPos, readPos);
  
      const value = iconv.decode(buff, NETWORK_STRING_ENCODE);

      return value;
    } else {
      // '\0'(empty string) 포함
      readPos += size;
      this._readPos = Math.max(this._readPos, readPos);
    }

    return '';
  }

  // 문자 사이즈 만큼 쓴다. \0포함 n+1
  writeNString(value: any, maxLen: any) {
    // 멀티바이트로 변환
    const buff = iconv.encode(value, NETWORK_STRING_ENCODE);
    const strLen = Math.min(buff.length, maxLen);
    const size = maxLen + 1;
    if (this._writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${this._writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    buff.copy(this._buffer, this._writePos, 0, strLen);
    // string 종료문자(\0) 포함 시킨다.
    this._buffer.writeInt8(0, this._writePos + strLen);
    this._writePos += size;
    this.setSize(this._writePos);
  }

  // 문자 사이즈 만큼 쓴다. \0포함 n+1
  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  writeNStringPos(pos: any, value: any, maxLen: any) {
    // 멀티바이트로 변환
    const buff = iconv.encode(value, NETWORK_STRING_ENCODE);
    const strLen = Math.min(buff.length, maxLen);
    const size = maxLen + 1;
    let writePos = pos + PACKET_HEADER_SIZE;
    if (writePos + size > this.bufferLength) {
      const message = `쓰려는 버퍼 사이즈가 초과한다. => buffer size: ${writePos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    buff.copy(this._buffer, writePos, 0, strLen);
    // string 종료문자(\0) 포함 시킨다.
    this._buffer.writeInt8(0, writePos + strLen);
    writePos += size;
    this._writePos = Math.max(this._writePos, writePos);
    this.setSize(this._writePos);
  }

  // 문자 사이즈 만큼 읽는다. \0포함 n+1
  readNString(maxLen: any) {
    // 스트링 사이즈를 구한다.
    const packetSize = this.size;
    let strLen = maxLen, size = 0;
    const maxPos = Math.min(this._readPos + maxLen, packetSize);
    for (let idx = this._readPos; idx < maxPos; idx++) {
      const char = this._buffer[idx];
      if (char === 0) {
        strLen = idx - this._readPos;
        break;
      }
    }
    size = maxLen + 1;
    if (this._readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${this._readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    if (strLen > 0) {
      // iconv.decode()에서 Buffer에서 0x00을 포함시키면 eur-kr string으로, 0x00을 빼면 unicode로 변환된다.
      const buff = Buffer.alloc(strLen);
      this._buffer.copy(buff, 0, this._readPos, this._readPos + strLen);
      this._readPos += size;
  
      const value = iconv.decode(buff, NETWORK_STRING_ENCODE);

      return value;
    } else {
      // '\0'(empty string) 포함
      this._readPos += size;
    }

    return '';
  }

  // 문자 사이즈 만큼 읽는다. \0포함 n+1
  // pos: 해더를 제외한 데이터 베이스(0) 사이즈
  readNStringPos(pos: any, maxLen: any) {
    // 스트링 사이즈를 구한다.
    const packetSize = this.size;
    let readPos = pos + PACKET_HEADER_SIZE;
    let strLen = maxLen, size = 0;
    const maxPos = Math.min(readPos + maxLen, packetSize);
    for (let idx = readPos; idx < maxPos; idx++) {
      const char = this._buffer[idx];
      if (char === 0) {
        strLen = idx - readPos;
        break;
      }
    }
    size = maxLen + 1;
    if (readPos + size > this.bufferLength) {
      const message = `읽으려는 버퍼 사이즈가 초과한다. => buffer size: ${readPos}, src size: ${size}`;
      console.warn(message);
      throw Error(message);
    }

    if (strLen > 0) {
      // iconv.decode()에서 Buffer에서 0x00을 포함시키면 eur-kr string으로, 0x00을 빼면 unicode로 변환된다.
      const buff = Buffer.alloc(strLen);
      this._buffer.copy(buff, 0, readPos, readPos + strLen);
      readPos += size;
      this._readPos = Math.max(this._readPos, readPos);
  
      const value = iconv.decode(buff, NETWORK_STRING_ENCODE);

      return value;
    } else {
      // '\0'(empty string) 포함
      readPos += size;
      this._readPos = Math.max(this._readPos, readPos);
    }

    return '';
  }
}
