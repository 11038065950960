<template>
	<div class="wrapPC">
	<!-- /헤더/ -->
	<PcHeader />
	<!-- /몸통/ -->
	<div class="body-box subPage transactionForm">
		<div class="con-box">
			<div class="left">
				<div class="designBox">
                    <div class="flex-center-between border-box">
                        <ul class="select-tab bdbn">
                            <li class="tab-item" :class="[{ on: state.tabIdx === i }, `no` + i]" v-for="(item, i) in TransferInfo" :key="i" @click="onTabPress(item)" > {{ item.name }} </li>
                        </ul>
                        <div class="mid flex-center-between account">
                            <div class="big-box"  style="padding-right:10px;">
                                <div class="top flex-center-between m-0">
                                    <div class="bank-account">
                                        <div class="dropdown-sort">
                                            <div class="select-sort">
                                                <input class="drop-sort" type="checkbox" id="dropPageCount" name="dropPageCount" v-model="state.pickAccOpen" />
                                                <label for="dropPageCount" class="for-dropsort pageCount">
                                                    <p class="out"> {{ state.pickAccDesc }}<i class="icon-down" :class="[{ on: state.pickAccOpen }]" /> </p>
                                                </label>
                                                <div class="section-sort">
                                                    <p v-for="(item, index) in state.pickAccItems" :key="index" @click="onPickAccChangeValue(item)" class="text" :class="{ active: item.value === state.pickAccValue }" > {{ item.desc }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="bank-password">
                                        <input type="password" placeholder="계좌비밀번호" v-model="state.password" :readonly="state.bAccPassed"/>
                                    </div> -->
                                    <button class="look-up"  @click="onPressRetrieve()">조회</button>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="mid">
						<!-- /입금/ -->
						<div class="deposit-area" :class="[{ 'withdraw-area': Common.C_TRANS_WITHDRAW }]" >
							<div v-if="state.transType === Common.C_TRANS_WITHDRAW">
								<div class="input-box send">
									<div class="left-box">
										<p class="text"> {{ `${Common.g_szNationName[state.isOversea]} 보유금액` }} </p>
									</div>
									<div class="right">
										<input type="text" v-model="state.depoAmount" readonly />
										<p class="currency">원</p>
									</div>
								</div>
								<div class="input-box send">
									<div class="left-box">
										<p class="text"> {{ `${Common.g_szNationName[state.isOversea]} 출금가능액` }} </p>
									</div>
									<div class="right">
										<input type="text" v-model="state.withdrawableAmount" readonly />
										<p class="currency">원</p>
									</div>
								</div>
							</div>
							<div v-if="state.transType === Common.C_TRANS_TRANSFER">
								<div class="account-box">
									<div class="input-box">
										<div class="left-box">
											<p class="text">보내는 계좌</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.accNo" readonly />
										</div>
									</div>
									<div class="input-box mb-0">
										<div class="left-box">
											<p class="text">받을 계좌</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.toAccNo" readonly />
										</div>
									</div>
								</div>
								<div class="price-box" :class="{ mt10: state.transType === Common.C_TRANS_TRANSFER }" >
									<div class="input-box send">
										<div class="left-box">
											<p class="text"> {{ `${Common.g_szNationName[state.isOversea]} 보유금액` }}	</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.depoAmount" readonly />
											<p class="currency">원</p>
										</div>
									</div>
									<div class="input-box send">
										<div class="left-box">
											<p class="text">대체 가능금액</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.withdrawableAmount" readonly />
											<p class="currency">원</p>
										</div>
									</div>
									<div class="input-box send">
										<div class="left-box">
											<p class="text">대체 신청금액</p>
										</div>
										<div class="right">
											<input type="number" v-model="state.amount" placeholder="금액을 입력해주세요"/>
											<p class="currency">원</p>
										</div>
									</div>
                                <div class="input-box send all-in deposit">
                                    <div class="left-box">
                      <p class="text"></p>
                    </div>
                                    <div class="all-in-box">
                                        <button @click="onPressAppendAmount(100000)" class="flex-center-center"><span class="text">+10만</span></button>
                                        <button @click="onPressAppendAmount(1000000)" class="flex-center-center"><span class="text">+100만</span></button>
                                        <button @click="onPressAppendAmount(10000000)" class="flex-center-center"><span class="text">+1000만</span></button>
                                        <button @click="onAllPress()" class="flex-center-center"><span class="text">전액</span></button>
                                        <button @click="onPressAppendAmount(0)" class="flex-center-center"><span class="text">초기화</span></button>
                                    </div>
                                </div>
								</div>
							</div>
								<div v-if=" state.transType === Common.C_TRANS_DEPOSIT || state.transType === Common.C_TRANS_WITHDRAW "	>
									<div class="input-box">
										<div class="left-box">
											<p class="text"> {{ `${Common.g_szTransTypeName[state.transType]} 계좌` }} </p>
										</div>
										<div class="right">
											<input type="text" v-model="state.accNo" readonly />
										</div>
									</div>
									<div class="input-box">
										<div class="left-box">
											<p class="text">은행명</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.bankName" readonly />
										</div>
									</div>
									<div class="input-box">
										<div class="left-box">
											<p class="text">계좌번호</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.bankAcc" readonly />
										</div>
									</div>
									<div class="input-box" v-if="state.transType === Common.C_TRANS_DEPOSIT" >
										<div class="left-box">
											<p class="text">예금주</p>
										</div>
										<div class="right">
											<input type="text" v-model="state.bankHolder" readonly />
										</div>
									</div>

                  <div class="input-box">
                    <div class="left-box">
                      <p class="text">{{	state.transType === Common.C_TRANS_DEPOSIT	? "보내시는분"	: "받으실 분"}}	</p>
                    </div>
                    <div class="right">
                      <input type="text" v-model="state.sender" readonly />
                    </div>
                  </div>

									<div class="input-box send">
										<div class="left-box">
											<p class="text">신청금액</p>
										</div>
										<div class="right">
											<input type="number" v-model="state.amount" placeholder="금액을 입력해주세요" />
											<p class="currency">원</p>
										</div>
									</div>

                  <div class="input-box send all-in">
                    <div class="left-box">
                      <p class="text"></p>
                    </div>

                    <div class="all-in-box" >
                      <button @click="onPressAppendAmount(100000)" class="flex-center-center"><span class="text">+10만</span></button>
                      <button @click="onPressAppendAmount(1000000)" class="flex-center-center"><span class="text">+100만</span></button>
                      <button @click="onPressAppendAmount(10000000)" class="flex-center-center"><span class="text">+1000만</span></button>
                      <button v-if="state.transType === Common.C_TRANS_WITHDRAW" @click="onAllPress()" class="flex-center-center"><span class="text">전액</span></button>
                      <button @click="onPressAppendAmount(0)" class="flex-center-center"><span class="text">초기화</span></button>
                    </div>
                  </div>

								</div>
								<div class="btn-box">
									<button class="deposit btn" @click="onDepositPress()">
										<span class="text">{{`${Common.g_szTransTypeName[state.transType]}신청`	}}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="mid under">
							<div class="title-box flex-center-between">
								<p class="title">입/출금,대체내역</p>
							</div>
							<div class="scroll-wrap">
								<!-- /입금표/ -->
								<div class="table-box" v-if="state.transType === Common.C_TRANS_DEPOSIT">
									<div class="table-head flex-center">
										<div class="item date">
											<p class="text">신청일시</p>
										</div>
										<div class="item date">
											<p class="text">처리일시</p>
										</div>
										<div class="item event">
											<p class="text">국내/해외</p>
										</div>
										<div class="item event">
											<p class="text">이벤트종류</p>
										</div>
										<div class="item price">
											<p class="text">이전금액</p>
										</div>
										<div class="item price">
											<p class="text">신청금액</p>
										</div>
										<div class="item price">
											<p class="text">이후잔고</p>
										</div>
										<div class="item status">
											<p class="text">처리상태</p>
										</div>
									</div>
									<div class="table-body flex-center" v-for="(item,idx) in state.renderDepositList" :key="idx">
										<div class="item date">
											<p class="text">{{ item.szRequestDate }}</p>
										</div>
										<div class="item date">
											<p class="text">{{ item.szProcessDate }}</p>
										</div>
										<div class="item event">
											<p class="text">{{ item.szDomesticOversea }}</p>
										</div>
										<div class="item event">
											<p class="text">{{ item.szEventType }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szBeforeAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAfterAmount }}</p>
										</div>
										<div class="item status">
											<p class="text">{{ item.szState }}</p>
										</div>
									</div>
								</div>
								<!-- /출금표/ -->
								<div class="table-box" v-else-if="state.transType === Common.C_TRANS_WITHDRAW">
									<div class="table-head flex-center">
										<div class="item date">
											<p class="text">신청일시</p>
										</div>
										<div class="item date">
											<p class="text">처리일시</p>
										</div>
										<div class="item event">
											<p class="text">국내/해외</p>
										</div>
										<div class="item event">
											<p class="text">이벤트종류</p>
										</div>
										<div class="item price">
											<p class="text">이전금액</p>
										</div>
										<div class="item price">
											<p class="text">신청금액</p>
										</div>
										<div class="item price">
											<p class="text">이후잔고</p>
										</div>
										<div class="item status">
											<p class="text">처리상태</p>
										</div>
									</div>
									<div class="table-body flex-center" v-for="(item,idx) in state.renderWithdrawList" :key="idx">
										<div class="item date">
											<p class="text">{{ item.szRequestDate }}</p>
										</div>
										<div class="item date">
											<p class="text">{{ item.szProcessDate }}</p>
										</div>
										<div class="item event">
											<p class="text">{{ item.szDomesticOversea }}</p>
										</div>
										<div class="item event">
											<p class="text">{{ item.szEventType }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szBeforeAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAfterAmount }}</p>
										</div>
										<div class="item status">
											<p class="text">{{ item.szState }}</p>
										</div>
									</div>
								</div>
								<!-- /대채표/ -->
								<div class="table-box" v-else-if="state.transType === Common.C_TRANS_TRANSFER">
									<div class="table-head flex-center">
										<div class="item date">
											<p class="text">신청일시</p>
										</div>
										<div class="item date">
											<p class="text">처리일시</p>
										</div>
										<div class="item date">
											<p class="text">신청계좌</p>
										</div>
										<div class="item price">
											<p class="text">이전국내잔고</p>
										</div>
										<div class="item price">
											<p class="text">이전해외잔고</p>
										</div>
										<div class="item price">
											<p class="text">대체금액</p>
										</div>
										<div class="item price">
											<p class="text">이후국내잔고</p>
										</div>
										<div class="item price">
											<p class="text">이후해외잔고</p>
										</div>
										<div class="item status">
											<p class="text">처리상태</p>
										</div>
									</div>
									<div class="table-body flex-center" v-for="(item,idx) in state.renderTransferList" :key="idx">
										<div class="item date">
											<p class="text">{{ item.szRequestDate }}</p>
										</div>
										<div class="item date">
											<p class="text">{{ item.szProcessDate }}</p>
										</div>
										<div class="item date">
											<p class="text">{{ item.szDomesticOversea }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szBeforeAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szBeforeAmountOversea }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAfterAmount }}</p>
										</div>
										<div class="item price">
											<p class="text">{{ item.szAfterAmountOversea }}</p>
										</div>
										<div class="item status">
											<p class="text">{{ item.szState }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<PcStockList />
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { router, store, onMounted, reactive } = useBaseComponent();

// import { Theme } from '../constants';
import moment from '@/utils/moment';
import * as Common from '@/constants/Common';
import { toLocaleString } from '@/utils/number';
import * as Utils from '@/utils/Common';
import { UserLog } from '@/network/UserLog';
// import { zeropad } from '../utils/zeropad';
import { NetworkService, Emit_ResetAmount, Emit_DrawTrans, Emit_TransferLog, Emit_Success } from '@/network';
import { onUnmounted } from "vue";
import * as AccPwd from '@/constants/AccPwd';
import {DEVICE_TYPE_MOBILE} from "@/constants/Global";

const Transfer_Deposit = 0; // 입금
const Transfer_Withdraw = 1; // 출금
const Transfer_Transfer = 2; // 대체
// const Transfer_Max = 3; // 구분자

const TransferInfo = [
    // Transfer_Deposit
    {
        idx: Transfer_Deposit,
        name: '입금',
        key: 'deposit',
    },
    // Transfer_Withdraw
    {
        idx: Transfer_Withdraw,
        name: '출금',
        key: 'withdraw',
    },
    // Transfer_Transfer
    {
        idx: Transfer_Transfer,
        name: '대체',
        key: 'transfer',
    },
];

let state = reactive({

    transType: Common.C_TRANS_DEPOSIT,
    tabIdx: Transfer_Deposit,
    refreshCount: 0,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    selectedItem: null as any,

    isOversea: Common.eNation_Domestic,
    
    depoAmount: '0',
    withdrawableAmount: '0',

    bWaitResponse: false,
    bDepositable: false,

    accNo: '',
    toAccNo: '',
    amount: '',
    sender: '',
    bankName: '상담센터로',
    bankAcc: '문의 주시기',
    bankHolder: '바랍니다.',

    transferLogs: [] as any,
    renderDepositList: [] as any,
    renderWithdrawList: [] as any,
    renderTransferList: [] as any,

    isShowTransactionLog:false,

    timerWaitResponse: null as any,

    // 계좌 정보 세팅
    timerInitData: null as any,
})

onMounted(()=>{
    
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TransactionForm');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_TransferLog, event_TransferLog);

    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=>{
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_TransferLog, event_TransferLog);

    if(state.timerWaitResponse){
        clearTimeout(state.timerWaitResponse);
    }
    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
})

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        initData();
    }
};

const event_ResetAmount = (params: any) => {
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    // const { isWithdraw } = params;
    state.amount = '';
    // 예탁잔고, 출금가능액 
    calcuAmount(state.isOversea);

    state.refreshCount++;
}

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    state.amount = '';
    // 예탁잔고, 출금가능액 
    calcuAmount(state.isOversea);

    state.refreshCount++;
}
const event_TransferLog = (params: any) => {
    const { transferIdx } = params;

    if (!((state.transType === Common.C_TRANS_DEPOSIT && transferIdx === 0) || (state.transType === Common.C_TRANS_WITHDRAW && transferIdx === 0) ||
      (state.transType === Common.C_TRANS_TRANSFER && transferIdx === 1))) {
      return;
    }

    // Transfer 로그 수신 완료.
    const transferLogs = store.AccountState.TransferLogs[transferIdx].filter((tr: any) => tr.iDepoNWithdraw === state.transType);
    state.transferLogs = transferLogs;
    state.refreshCount++;

    renderLogs();
}

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    state.isOversea = isOversea;

    initAccPicker();
    
    // 보내는 사람 은행정보
    //const bankInfo = `(${AccountStore.szBank}) ${AccountStore.szAccountNo} ${AccountStore.szBankHolder}`;
    const bankInfo = store.AccountState.szBankHolder;
    state.sender = bankInfo;

    // 예탁잔고, 출금가능액 
    calcuAmount(isOversea);

    // 입금 가능여부
    const bDepositable = store.PossibleDeposit();
    if (state.transType === Common.C_TRANS_DEPOSIT) {
      if (bDepositable) {
        state.bankName = store.BrandState.szBrandAccountBank;
        state.bankAcc = store.BrandState.szBrandAccountNo;
        state.bankHolder = store.BrandState.szBrandAccountName;
      }
    } else if (state.transType === Common.C_TRANS_WITHDRAW) {
        state.bankName = store.AccountState.szBank;
        state.bankAcc = store.AccountState.szAccountNo;
        state.bankHolder = store.AccountState.szBankHolder;
    }
    state.bDepositable = bDepositable;

    // onPressRetrieve();
}

const initAccPicker = () => {
    const pickItems = [];
    const futures = store.FuturesByNow();
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }
    
    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            state.selectedItem = item;
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.isOversea = item.bOversea;
            state.pickAccItems[index].selected = true;
            state.accNo = `${item.value}  ${item.desc}`;
            state.accNo = `${item.desc}`;
            calcuAmount(state.selectedItem.bOversea);
        } else {
            state.pickAccItems[index].selected = false;
            if(state.transType === Common.C_TRANS_TRANSFER){
                state.toAccNo = `${item.value}  ${item.desc}`;
            }
        }
    });
}

const onTabPress = (tab: any) => {
    if (state.tabIdx !== tab.idx) {
        resetInfo();
        state.tabIdx = tab.idx;
        state.transType = tab.key === 'deposit' ? Common.C_TRANS_DEPOSIT : tab.key === 'withdraw' ? Common.C_TRANS_WITHDRAW : Common.C_TRANS_TRANSFER;
        initData();
    } else {
        return;
    }
    
    if(!state.isShowTransactionLog){
        initAccPicker();
    } else {
        onPressRetrieve();
    }
}

const checkAccPwd = () => {
    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const resetInfo = () => {
    //계좌 정보
    state.pickAccOpen =  false;
    // state.pickAccDesc =  '계좌를 선택하세요';
    // state.pickAccValue =  '';
    // state.pickAccItems =  [];
    // state.password =  '';

    state.amount = '';
    // state.bAccPassed =  false;
    // state.selectedItem =  null;
    // state.isOversea =  Common.eNation_Domestic;
    // state.depoAmount =  '0';
    // state.withdrawableAmount =  '0';
    // state.accNo =  '';
    // state.toAccNo =  '';
    // state.amount =  '';
    // state.sender =  '';
    // state.bankName =  '상담센터로';
    // state.bankAcc =  '문의 주시기';
    // state.bankHolder =  '바랍니다.';
}

const calcuAmount = (isOversea: any) => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 모든 선물의 포지션 잔량(반대매매) + 미체결, 또는 주문 중 가장 큰 값을 얻는다.
    const positionCount = store.GetClientMaxPositionAmount(false);
    const positionCountOversea = store.GetClientMaxPositionAmount(true);
    const cOvernightDeposit = store.GetOvernightDeposit(Common.G_FUTURES_DAY, Common.eOverTrading_Overnight);
    const cOvernightDepositOversea = store.GetOvernightDeposit(Common.G_FUTURES_OVERSEAS, Common.eOverTrading_Overnight);
    // 총 실현 손익을 구한다.
    const [totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmount = depoAmount + totalProfitDomestic;
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    // 평가예탁금에서 포지션이 잡힌 수만큼의  오버나잇 필요담보금은 제외하고 출금 가능
    const leftMoney = estimatedAmount - (positionCount * cOvernightDeposit);
    const leftMoneyOversea = estimatedAmountOversea - (positionCountOversea * cOvernightDepositOversea);

    // 출금가능금액
    let outMoney = Math.max(leftMoney, 0);
    let outMoneyOversea = Math.max(leftMoneyOversea, 0);
    // 포지션이 있을 경우 출금액을 제한한다.
    // 포지션 제외하고 출금을 원할 경우는 아래를 주석처리하면 된다.
    if (positionCount > 0) {
        outMoney = 0;
    }
    if (positionCountOversea > 0) {
        outMoneyOversea = 0;
    }

    if (isOversea === 1) {
        state.depoAmount = toLocaleString(depoAmountOversea);
        state.withdrawableAmount = toLocaleString(outMoneyOversea);
    } else {
        state.depoAmount = toLocaleString(depoAmount);
        state.withdrawableAmount = toLocaleString(outMoney);
    }


    state.amount = '';
    // 포매터 추가 필요
}

const waitReponse = () => {
    state.bWaitResponse = true;
    state.timerWaitResponse = setTimeout(() => {
        state.bWaitResponse = false;
    }, 1000);
}

const onDepositPress = () => {
    // const { systemStore } = this.props;

    waitReponse();

    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const szTransName = Common.g_szTransTypeName[state.transType];
    const amounts = parseInt(state.amount);
    if (amounts <= 0) {
        const message = `${szTransName}할 금액을 입력해주세요.`;
        store.setVisibleToast({isTypeWarning: true, message: message})
        return;
    }

    const futures = Common.G_FUTURES_DAY; // 고정
    const isWithraw = state.transType;
    const isOversea = state.isOversea;

    // 입금불가 계정은 상담센터로
    if (isWithraw === Common.C_TRANS_DEPOSIT && !state.bDepositable) {
        // this.moveScreen('Customer');
        if(store.SystemState._deviceType === DEVICE_TYPE_MOBILE){
          router.push('/m/HelpDesk');
        } else {
          router.push('/HelpDesk')
        }
        return;
    }

    // 입금액 확인
    if (isWithraw === Common.C_TRANS_DEPOSIT && amounts > Common.C_DEPOSIT_AMOUNT_MAX) {
        const message = `입금 최대 신청금액은 ${toLocaleString(Common.C_DEPOSIT_AMOUNT_MAX)}원 입니다.`;
        store.setVisibleDialog({ message });
        return;
    }

    // 출금일 경우 출금 가능액 확인
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
        const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
        if (amounts > withdrawableAmount) {
            const message = `신청금액이 ${szTransName}가능금액보다 많습니다.\n최대 ${toLocaleString(withdrawableAmount)}원 ${szTransName} 가능합니다.`;
            store.setVisibleDialog({ message });
            return;
        }
        const depoAmount = parseInt(state.depoAmount.replace(/,/g, ''));
        if (amounts > depoAmount) {
            const message = `신청금액이 보유금액보다 많습니다.\n최대 ${toLocaleString(depoAmount)}원 ${szTransName} 가능합니다.`;
            store.setVisibleDialog({ message });
            return;
        }

        // 시작 hhmm, 종료시간hhmm(string)으로 현재 시간이 해당 시간인지 확인
        const bInTime = Utils.IsOpenTime(store.BrandState.szBrandWithdrawO, store.BrandState.szBrandWithdrawC);
        if (!bInTime) {
            const message = `${szTransName} 가능시간이 아닙니다.\n${szTransName} 가능시간을 확인해주세요.`;
            store.setVisibleDialog({ message });
            return;
        }
    }

    const szUserLog = `[${Common.g_szNationName[isOversea]} ${szTransName}신청] 금액:${Utils.SetComma(amounts)}원`;
    UserLog.log(szUserLog);
  
    NetworkService.send.depoNWithrawReq({futures, isWithraw, isOversea, amounts});
}

const onAllPress = () => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
      return;
    }

    const isWithraw = state.transType;

    let amounts = 0;
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
        const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
        amounts = withdrawableAmount;
    }

    state.amount = amounts.toString();
}

const onPressAppendAmount = (data: any) => {
  // 계정 비번 확인
  if (!checkAccPwd()) {
    return;
  }

  const isWithraw = state.transType;

  // 금액 수정
  if(data === 0 ) {
    // 금액 0
    state.amount = '';
  }
  else {
    let amounts = Number(state.amount);
    amounts += data;
    if (isWithraw === Common.C_TRANS_WITHDRAW || isWithraw === Common.C_TRANS_TRANSFER) {
      const withdrawableAmount = parseInt(state.withdrawableAmount.replace(/,/g, ''));
      if(amounts > withdrawableAmount)
        amounts = withdrawableAmount;
    }
    state.amount = amounts.toString();
  }
}

// 입출금내역 요청
const transferLogsReq = (transType: any) => {
    // 90일 요청
    const szStartDate = moment(Date.now()).add(-90, 'days').format('YYYYMMDD');
    const szEndDate = moment(Date.now()).format('YYYYMMDD');

    transType = state.transType === Common.C_TRANS_TRANSFER ? Common.C_TRANS_TRANSFER : Common.C_TRANS_MAX;
    const transState = Common.C_TRANSSTATE_MAX;
    const bTransfer = state.transType === Common.C_TRANS_TRANSFER ? 1 : 0;

    const szUserLog = `[${transType === Common.C_TRANS_TRANSFER ? '대체내역' : '입출금내역'}] 검색 ${szStartDate}~${szEndDate}`;
    UserLog.log(szUserLog);
  
    NetworkService.send.depositNWithdrawListReq({
        transType, 
        transState, 
        szStartDate, 
        szEndDate,
        bTransfer,
    });
}

const onPressRetrieve = () => {
    transferLogsReq(Common.C_TRANS_DEPOSIT);
    transferLogsReq(Common.C_TRANS_TRANSFER);
}

const renderDeposit = () => {

    state.renderDepositList = [];
    state.transferLogs.forEach((log: any)=> {
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szTransName: Common.g_szTransTypeName[log.iDepoNWithdraw],
            szDomesticOversea: log.szDomesticOversea,
            // szEventType: Common.g_szEventTypeName[log.iEventType],
            szEventType: log.szEventName,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: toLocaleString(log.iBeforeDepositAmount),
            szAfterAmount: toLocaleString(log.iAfterDepositAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderDepositList.push(objOrder);
    });
}

const renderWithdraw = () => {
    state.renderWithdrawList = [];
    state.transferLogs.forEach((log: any)=> {
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szTransName: Common.g_szTransTypeName[log.iDepoNWithdraw],
            szDomesticOversea: log.szDomesticOversea,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: toLocaleString(log.iBeforeWithdrawAmount),
            szAfterAmount: toLocaleString(log.iAfterWithdrawAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderWithdrawList.push(objOrder);
    });
}

const renderTransfer = () => {
    state.renderTransferList = [];
    state.transferLogs.forEach((log: any)=> {
        const szDomesticOversea = log.szDomesticOversea;
        const objOrder = {
            szRequestDate: log.szRequestDate,
            szProcessDate: log.szProcessDate,
            szDomesticOversea: szDomesticOversea,
            szAmount: toLocaleString(log.iDepoNWithdrawAmount),
            szBeforeAmount: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iBeforeWithdrawAmount) : toLocaleString(log.iBeforeDepositAmount),
            szAfterAmount: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iAfterWithdrawAmount) : toLocaleString(log.iAfterDepositAmount),
            szBeforeAmountOversea: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iBeforeDepositAmount) : toLocaleString(log.iBeforeWithdrawAmount),
            szAfterAmountOversea: szDomesticOversea === Common.g_szNationName[Common.eNation_Domestic] ? toLocaleString(log.iAfterDepositAmount) : toLocaleString(log.iAfterWithdrawAmount),
            szState: Common.g_szTransStateName[log.iState]
        }
        state.renderTransferList.push(objOrder);
    });
}

const renderLogs = () => {
    state.transType === Common.C_TRANS_DEPOSIT ? renderDeposit() : state.transType === Common.C_TRANS_WITHDRAW ? renderWithdraw() :renderTransfer();
}

</script>