import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "popupPc" }
const _hoisted_2 = { class: "popupPc-inner" }
const _hoisted_3 = {
  key: 0,
  class: "popupSet"
}
const _hoisted_4 = { class: "changePopup" }
const _hoisted_5 = { class: "setPassword Setting" }
const _hoisted_6 = { class: "scroll-wrap-set" }
const _hoisted_7 = { class: "menu-box" }
const _hoisted_8 = { class: "click-box pt-0" }
const _hoisted_9 = { class: "radio-wrap" }
const _hoisted_10 = { class: "radio-wrap" }
const _hoisted_11 = { class: "radio-wrap" }
const _hoisted_12 = { class: "radio-wrap on bdbn" }
const _hoisted_13 = { for: "inputRoot4" }
const _hoisted_14 = { class: "checkbox-wrap" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "menu-box" }
const _hoisted_17 = { for: "allCheck" }
const _hoisted_18 = ["id", "onChange"]
const _hoisted_19 = ["for"]
const _hoisted_20 = { class: "save-box" }
const _hoisted_21 = { class: "input-box" }
const _hoisted_22 = { class: "right" }
const _hoisted_23 = { class: "radio-wrap" }
const _hoisted_24 = { class: "label-radio first" }
const _hoisted_25 = { class: "label-radio" }
const _hoisted_26 = { class: "label-radio" }
const _hoisted_27 = { class: "btn-box" }

import useBaseComponent from "../../baseComponent";
import { NetworkService, Emit_AccountPasswordUpdate } from "@/network"
import * as NetConstants from '../../network/Constants';
import * as Common from '../../constants/Common';
import * as AccPwd from '../../constants/AccPwd';


export default /*@__PURE__*/_defineComponent({
  __name: 'PcSetPassword',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    type: {
        type: String,
        default: "",
    },
    isIgnoreClose: {
        type: Boolean,
        default: false,
    }
},
  emits: ["close", "ok", "settingAllClose"],
  setup(__props, { emit: __emit }) {

const { store, route, router, defineEmits, defineProps, onMounted, onUnmounted, reactive } = useBaseComponent();
const props = __props;


let state = reactive({
    accountHeaderChecked: 1,
    accountArray: [
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
        },
        {
            checked: 1, 
            id: "",
            name: "",
            pwd: "",
        }
    ],

    accPwdApply: 0,     // 계좌번호 입력방식, AccPwd
    screenOnCheck:true, //프로그램시작시 계좌비밀번호 일괄저장 화면 띄우기

    passwordUpdateType:0,
    inputPassword: '',

    timerInitData: null as any,
})

onMounted(() => {
    initData();
 });



const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    state.accountArray[0].id = store.AccountState.szDepoAccNo;
    state.accountArray[1].id = store.AccountState.szDepoAccNoOversea;
    state.accountArray[0].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Domestic]})`;
    state.accountArray[1].name = `${store.AccountState.szName}(${Common.g_szNationName[Common.eNation_Oversea]})`;
    state.accountArray[0].pwd = store.getDomesticAccPwd(false);
    state.accountArray[1].pwd = store.getOverseaAccPwd(false);
    
    state.accPwdApply = store.SettingsState.settings.accPwdApply;
}

const screenOnCheck = () => {
    // 개별 변경기능 없대서 일단 주석
    //console.log(state.screenOnCheck);
    //state.screenOnCheck = !state.screenOnCheck;
}

const onAccountHeaderChecked = () => { 
    // 개별 변경기능 없대서 일단 주석
    //state.accountHeaderChecked = (state.accountHeaderChecked === 1 ? 0:1); 
    //state.accountArray[0].checked = state.accountHeaderChecked;
    //state.accountArray[1].checked = state.accountHeaderChecked;
}
const onAccountChecked = (i:any) => {
    // 개별 변경기능 없대서 일단 주석
    //state.accountArray[i].checked = state.accountArray[i].checked === 1 ? 0 : 1;
    //state.accountHeaderChecked = (state.accountArray[0].checked && state.accountArray[1].checked && state.accountArray[0].checked === 1) ? 1 : 0;
}

const onPasswordUpdateTypeChanged = () => {
    //console.log(state.passwordUpdateType);
}

const emit = __emit;

const settingAllClose = () => {
    state.inputPassword = '';
    emit("settingAllClose");
}

const close = () => { 
    state.inputPassword = '';
    emit("close");
};

const ok = () => {
    if(Number(state.accPwdApply) === 1){
        //계좌비밀번호 매번 입력 선택한 경우 패스워드 체크없이 창 닫음
        store.checkAccountPassword({checkType: state.accPwdApply});
        state.inputPassword = '';

        //패스워드 설정 후 store 통해서 액션 감지하도록 함
        store.CommonState.isChangePwdSetting = true;
        close();
        if(props.isIgnoreClose){
            //로그인 후 강제 비밀번호 설정 시
            store.setSettingAccountPopupState(0);
            store.setVisibleDialog({message: '비밀번호를 변경하였습니다.', callback : () =>{
                settingAllClose();
            }})
        } 
        return;
    }

    if(state.inputPassword !== '') {
        
        store.checkAccountPassword({pwd1: state.inputPassword, pwd2: state.inputPassword, checkType: state.accPwdApply});
        // API 호출이 아니라 그냥 비교만 하면됨
        //NetworkService.send.newAccPwdReq({ DomesticPwd: state.inputPassword, OverseaPwd: state.inputPassword });

        // WTS 에서는 국내/해외 동일 취급
        const bAccPassed = state.inputPassword === store.AccountState.szDomesticAccPwd ? true : state.inputPassword === store.AccountState.szOverseaAccPwd ? true : false;
        // if(bAccPassed) {
        //     //
        // }
        // else {
        //     //
        // }

        //패스워드 설정 후 store 통해서 액션 감지하도록 함
        store.CommonState.isChangePwdSetting = true;
        close();
        if(props.isIgnoreClose){
            //로그인 후 강제 비밀번호 설정 시
            // router.push('/m/Home');
            settingAllClose();
        }
    }
    else {
        store.setVisibleDialog({message: '계좌비밀번호를 입력해 주세요.'});
        return;
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (props.isShowPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "title-box flex-center-center" }, [
                  _createElementVNode("p", { class: "text" }, "계좌 비밀번호 변경")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[13] || (_cache[13] = _createElementVNode("p", { class: "title" }, "계좌비밀번호 입력방식", -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          id: "inputRoot1",
                          value: "0",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).accPwdApply) = $event))
                        }, null, 512), [
                          [_vModelRadio, _unref(state).accPwdApply]
                        ]),
                        _cache[8] || (_cache[8] = _createElementVNode("label", { for: "inputRoot1" }, [
                          _createTextVNode("계좌비밀번호 한번만 입력후 계속 저장"),
                          _createElementVNode("span", null, "(설정한 기기에서만 적용되며, 재로그인시에도 유지)")
                        ], -1))
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          id: "inputRoot2",
                          value: "1",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(state).accPwdApply) = $event)),
                          disabled: ""
                        }, null, 512), [
                          [_vModelRadio, _unref(state).accPwdApply]
                        ]),
                        _cache[9] || (_cache[9] = _createElementVNode("label", { for: "inputRoot2" }, "계좌비밀번호 매번 입력", -1))
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          id: "inputRoot3",
                          value: "2",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(state).accPwdApply) = $event)),
                          disabled: ""
                        }, null, 512), [
                          [_vModelRadio, _unref(state).accPwdApply]
                        ]),
                        _cache[10] || (_cache[10] = _createElementVNode("label", { for: "inputRoot3" }, "계좌비밀번호 한번만 입력후 저장", -1))
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_12, [
                          _withDirectives(_createElementVNode("input", {
                            type: "radio",
                            id: "inputRoot4",
                            value: "3",
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).accPwdApply) = $event)),
                            disabled: ""
                          }, null, 512), [
                            [_vModelRadio, _unref(state).accPwdApply]
                          ]),
                          _createElementVNode("label", _hoisted_13, [
                            _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text" }, "계좌비밀번호 일괄저장(로그아웃시 저장해제)", -1)),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("input", {
                                type: "checkbox",
                                id: "optionA",
                                disabled: _unref(state).accPwdApply != 3,
                                onChange: screenOnCheck
                              }, null, 40, _hoisted_15),
                              _createElementVNode("label", {
                                class: _normalizeClass(["checkBox-label", {'disabled':_unref(state).accPwdApply !== 3 }]),
                                for: "optionA"
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["checkBox", {'on':_unref(state).screenOnCheck && _unref(state).accPwdApply === 3}])
                                }, null, 2),
                                _cache[11] || (_cache[11] = _createTextVNode(" 프로그램 시작시 계좌비밀번호 일괄저장 화면 띄우기"))
                              ], 2)
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[17] || (_cache[17] = _createElementVNode("p", { class: "title" }, "계좌비밀번호 일괄저장", -1)),
                    _createElementVNode("table", null, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              id: "allCheck",
                              onChange: onAccountHeaderChecked
                            }, null, 32),
                            _createElementVNode("label", _hoisted_17, [
                              _createElementVNode("i", {
                                class: _normalizeClass(["checkBox", {'on': _unref(state).accountHeaderChecked }])
                              }, null, 2)
                            ])
                          ]),
                          _cache[14] || (_cache[14] = _createElementVNode("th", null, "계좌번호", -1)),
                          _cache[15] || (_cache[15] = _createElementVNode("th", null, "계좌명", -1)),
                          _cache[16] || (_cache[16] = _createElementVNode("th", null, "계좌비밀번호", -1))
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).accountArray, (item, i) => {
                          return (_openBlock(), _createElementBlock("tr", { key: i }, [
                            _createElementVNode("td", null, [
                              _createElementVNode("input", {
                                type: "checkbox",
                                id: `no`+i,
                                onChange: ($event: any) => (onAccountChecked(i))
                              }, null, 40, _hoisted_18),
                              _createElementVNode("label", {
                                for: `no`+i
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(["checkBox", {'on': item.checked }])
                                }, null, 2)
                              ], 8, _hoisted_19)
                            ]),
                            _createElementVNode("td", null, _toDisplayString(item.id), 1),
                            _createElementVNode("td", null, _toDisplayString(item.name), 1),
                            _createElementVNode("td", null, _toDisplayString(item.pwd), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[18] || (_cache[18] = _createElementVNode("div", { class: "left" }, [
                        _createElementVNode("p", { class: "text" }, "계좌비밀번호")
                      ], -1)),
                      _createElementVNode("div", _hoisted_22, [
                        _withDirectives(_createElementVNode("input", {
                          type: "password",
                          placeholder: "비밀번호를 입력해주세요",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(state).inputPassword) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(state).inputPassword]
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("label", _hoisted_24, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          value: "0",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(state).passwordUpdateType) = $event)),
                          onChange: onPasswordUpdateTypeChanged
                        }, null, 544), [
                          [_vModelRadio, _unref(state).passwordUpdateType]
                        ]),
                        _cache[19] || (_cache[19] = _createTextVNode(" 모든계좌에 저장 "))
                      ]),
                      _createElementVNode("label", _hoisted_25, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          value: "1",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(state).passwordUpdateType) = $event)),
                          onChange: onPasswordUpdateTypeChanged,
                          disabled: ""
                        }, null, 544), [
                          [_vModelRadio, _unref(state).passwordUpdateType]
                        ]),
                        _cache[20] || (_cache[20] = _createTextVNode(" 선택계좌에 저장 "))
                      ]),
                      _createElementVNode("label", _hoisted_26, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          value: "2",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(state).passwordUpdateType) = $event)),
                          onChange: onPasswordUpdateTypeChanged,
                          disabled: ""
                        }, null, 544), [
                          [_vModelRadio, _unref(state).passwordUpdateType]
                        ]),
                        _cache[21] || (_cache[21] = _createTextVNode(" 모든계좌 저장해제 "))
                      ])
                    ])
                  ]),
                  _cache[22] || (_cache[22] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호를 저장하신 후 저장해제를 하지 않는 한 프로그램 종료시까지 계좌비밀번호는 저장되어 있으니 반드시 화면 잠금기능을 사용하거나 프로그램을 종료하시기 바랍니다.", -1)),
                  _cache[23] || (_cache[23] = _createElementVNode("p", { class: "caution" }, "*프로그램 이용중 계좌비밀번호를 변경하셨을 경우에는 기존 저장되어 있던 계좌비밀번호는 저장해제 되오니 다시 계좌비밀번호를 저장하시기 바랍니다.", -1)),
                  _cache[24] || (_cache[24] = _createElementVNode("p", { class: "caution" }, "*계좌비밀번호 저장기능은 고객님의 부주의로 의도치 않은 주문이 실행될 수 있고, 제 3자가 고객님의 정보조회 및 주문 등을 실행할 수 있으므로 사용에 각별한 주의를 당부드리며, 본 기능 이용중 발생한 의도치 않은 주문 실행 등 피해의 책임은 고객님께 있으며, 당사에 귀책사유가 없음을 고지합니다.", -1))
                ]),
                _createElementVNode("div", _hoisted_27, [
                  (props.isIgnoreClose === false)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "cancel",
                        onClick: close
                      }, _cache[25] || (_cache[25] = [
                        _createElementVNode("span", { class: "text" }, "취소", -1)
                      ])))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: "ok",
                    onClick: ok
                  }, _cache[26] || (_cache[26] = [
                    _createElementVNode("span", { class: "text" }, "확인", -1)
                  ]))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})