import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../assets/images/mb-right-arrow.png'


const _hoisted_1 = { class: "menu-tab" }

import useBaseComponent from "@/baseComponent";
import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MenuTab',
  setup(__props) {

const { route, router, defineEmits, defineProps, onMounted,onUnmounted, reactive, store } = useBaseComponent();

let state = reactive({
    timer: null as any,
    initShowHelpDeskPopup:false,
})

onMounted(()=>{
    window.addEventListener('scroll',handleEnded)
})

onUnmounted(()=> {
    window.removeEventListener('scroll', handleEnded);
})

const handleEnded = () => {
    if(state.timer){
        clearTimeout(state.timer);
    }
    state.timer = setTimeout(()=>{
        scrollHandler();
    }, 500);
}
const scrollPosition = ref(0);

const isLeftArrowOn = ref(false);

const isRightArrowOff = ref(false);

const scrollHandler = () => {
    scrollPosition.value = document.getElementById('menuTab')?.scrollLeft;
    isLeftArrowOn.value = scrollPosition.value > 34;
    isRightArrowOff.value = scrollPosition.value> 200;
}

const goLeft = () => {
    const scroll = document.getElementById('menuTab') as HTMLElement;
    scroll.scrollTo({left:0,behavior:'smooth'})
}
const goRight = () => {
    const scroll = document.getElementById('menuTab') as HTMLElement;
    scroll.scrollTo({left:9999,behavior:'smooth'})
}

const clickHelpDesk = (page: string) => {
    if (!store.LoginPageState.isPacketLoginAckReceived) return;
    
	if(store.BrandState.bExternalChat === 0) {
		router.push(page)
	} else {
		document.body.style.overflow = "hidden";
		state.initShowHelpDeskPopup = true; 
	}
}
const close = (popupType: string) => {
    switch (popupType) {
        case "PopupHelpDesk":
            document.body.style.overflow = "auto";
            state.initShowHelpDeskPopup = false;
            break;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_PopupHelpDesk = _resolveComponent("PopupHelpDesk")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: _normalizeClass(["left-arrow", { on: isLeftArrowOn.value }]),
        src: _imports_0,
        alt: "",
        onClick: goLeft
      }, null, 2),
      _createElementVNode("div", {
        class: "row",
        id: "menuTab",
        onScroll: scrollHandler
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/Home' }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/m/Home')))
        }, "종합시세", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/TotalOrder' }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/m/TotalOrder')))
        }, "통합주문", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/TradeList' }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push('/m/TradeList')))
        }, "거래내역", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/TransactionForm' }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/m/TransactionForm')))
        }, "입/출금", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/HelpDesk' }]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (clickHelpDesk('/m/HelpDesk')))
        }, "상담센터", 2),
        (_unref(store).SystemState._appMode === 1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/OverNight' }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/m/OverNight')))
            }, "오버나잇", 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/Notice' }]),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(router).push('/m/Notice')))
        }, "공지사항", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col", { select: _ctx.$route.path === '/m/Setting' }]),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(router).push('/m/Setting')))
        }, "설정", 2)
      ], 32),
      _createElementVNode("img", {
        class: _normalizeClass(["right-arrow", { off: isRightArrowOff.value }]),
        src: _imports_0,
        alt: "",
        onClick: goRight
      }, null, 2)
    ]),
    _createVNode(_component_PopupHelpDesk, {
      initShowNotice: _unref(state).initShowHelpDeskPopup,
      initTitle: '상담센터',
      onClose: _cache[8] || (_cache[8] = ($event: any) => (close('PopupHelpDesk')))
    }, null, 8, ["initShowNotice"])
  ], 64))
}
}

})