<template>
    <!-- <div class="popup notice" v-if="props.initShowNotice" :class="{'popupSet': route.name === 'Home'}"> -->
    <div class="popupPc notice popupHelp" v-if="props.initShowNotice" :class="{'indexNotice': props.viewType !== 'Detail' }">
        <div class="popupPc-inner">
			<div class="close-box" >
				<img src="../../assets/images/icon-close.png" alt="" @click="close">
			</div>
            <p class="main-title">{{props.initTitle}}</p>
            <div class="img-box flex-center">
                <div class="url-box Kakao" v-if="store.BrandState.szKakaoUrl" @click="newUrl('kakao')">
                    <img src="../../assets/images/url-kakao.png" alt="">
                    <p class="text">Kakao talk</p>
                </div>
                <div class="url-box Telegram" v-if="store.BrandState.szTelegramUrl"  @click="newUrl('telegram')">
                    <img src="../../assets/images/url-tel.png" alt="">
                    <p class="text">Telegram</p>
                </div>

                <!-- <img class="url-img Kakao" :class="{'w50': store.BrandState.szKakaoUrl && store.BrandState.szTelegramUrl}" src="../../assets/images/url-kakao.jpg" alt="" v-if="store.BrandState.szKakaoUrl" @click="newUrl('kakao')"> -->
                <!-- <img class="url-img Tel" :class="{'w50': store.BrandState.szKakaoUrl && store.BrandState.szTelegramUrl}" src="../../assets/images/url-tel.jpg" alt="" v-if="store.BrandState.szTelegramUrl"  @click="newUrl('telegram')"> -->
            </div>
            {{ props.viewType }}
			<!-- <p v-html="props.initTitle" class="title"  v-if="props.viewType === 'Detail'"></p> -->
            <!-- <p v-html="props.initNoticeMessage" class="content"></p> -->
            <div class="btn-box notice"  v-if="props.viewType !== 'Detail' ">
                <button class="ok" @click="close" >닫기</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import useBaseComponent from "@/baseComponent";
const { route, router, defineEmits,store, defineProps, onMounted, reactive,} = useBaseComponent();

const props = defineProps({
    initShowNotice: {
        type: Boolean,
        default: false,
    },
    initTitle: {
        type: String,
        default: "",
    },
	viewType: {
        type: String,
        default: "",
    },
});

const newUrl = (type:string) => { 
    if(type === 'kakao'){
        window.open(store.BrandState.szKakaoUrl, '_blank');
    } else if (type === 'telegram') {
        window.open(store.BrandState.szTelegramUrl, '_blank');
    } else {
        // 
    }
}

const emit = defineEmits(["close","noToday"]);

const noToday = () => {
    emit("noToday")
}

const close = () => {
    emit("close");
};
</script>
