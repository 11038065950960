import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popup confirm"
}
const _hoisted_2 = { class: "popup-inner" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "btn-box" }

import useBaseComponent from "@/baseComponent";
import * as Constant from "../../../src/constants";

export default /*@__PURE__*/_defineComponent({
  __name: 'PopupConfirm',
  props: {
    isShowPopup: {
        type: Boolean,
        default: false,
    },
    initPopupMessage1: {
        type: String,
        default: "",
    },
    initPopupMessage2: {
        type: String,
        default: "",
    },
    initPopupMessage3: {
        type: String,
        default: "",
    },
    popupType: {
        type: Number,
        default: 0,
    },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { route, router, defineEmits, defineProps, onMounted, reactive,store} = useBaseComponent();

const props = __props;

const emit = __emit;

const close = () => {
    emit("close");
};

return (_ctx: any,_cache: any) => {
  return (props.isShowPopup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", {
            innerHTML: props.initPopupMessage1,
            class: "confirm-text"
          }, null, 8, _hoisted_3),
          (props.popupType === 1)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                innerHTML: props.initPopupMessage2,
                class: _normalizeClass({ blue: _unref(route).name === 'TransactionForm' })
              }, null, 10, _hoisted_4))
            : _createCommentVNode("", true),
          (props.popupType === 2)
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                innerHTML: props.initPopupMessage3
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: close,
              class: _normalizeClass({ suc: _unref(route).name === 'TransactionForm' && props.popupType === 1 })
            }, "확인", 2)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})