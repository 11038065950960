import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, vModelText as _vModelText, vModelRadio as _vModelRadio, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/minus.svg'
import _imports_1 from '@/assets/images/plus.svg'


const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "total-order" }
const _hoisted_3 = { class: "top flex" }
const _hoisted_4 = { class: "bank-account" }
const _hoisted_5 = { class: "dropdown-sort" }
const _hoisted_6 = { class: "select-sort" }
const _hoisted_7 = {
  for: "dropPageCount",
  class: "for-dropsort pageCount"
}
const _hoisted_8 = { class: "drop-out-wrap" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "section-sort"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "big-span" }
const _hoisted_13 = { class: "small-span" }
const _hoisted_14 = { class: "mid" }
const _hoisted_15 = { class: "flex price" }
const _hoisted_16 = { class: "account flex" }
const _hoisted_17 = { class: "password-box" }
const _hoisted_18 = ["readonly"]
const _hoisted_19 = { class: "row tab" }
const _hoisted_20 = { class: "bottom" }
const _hoisted_21 = { class: "left" }
const _hoisted_22 = { class: "title-box" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { key: 3 }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { key: 3 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "gray-box" }
const _hoisted_30 = { colspan: "2" }
const _hoisted_31 = { class: "flex" }
const _hoisted_32 = { class: "flex" }
const _hoisted_33 = { class: "flex" }
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = { class: "first" }
const _hoisted_36 = {
  class: "ratio-box",
  style: {"z-index":"2"}
}
const _hoisted_37 = {
  colspan: "2",
  class: "ratio"
}
const _hoisted_38 = { class: "right flex" }
const _hoisted_39 = { class: "justifySpaceBetween" }
const _hoisted_40 = { key: 0 }
const _hoisted_41 = { class: "market-price-select-box" }
const _hoisted_42 = { class: "radio-wrap" }
const _hoisted_43 = { class: "label-radio first" }
const _hoisted_44 = ["value"]
const _hoisted_45 = { class: "label-radio" }
const _hoisted_46 = ["value"]
const _hoisted_47 = { class: "able-amount" }
const _hoisted_48 = { class: "main name" }
const _hoisted_49 = {
  key: 0,
  class: "value"
}
const _hoisted_50 = {
  key: 1,
  class: "value"
}
const _hoisted_51 = { class: "possible" }
const _hoisted_52 = {
  key: 1,
  class: "check flex typing-box"
}
const _hoisted_53 = { class: "count" }
const _hoisted_54 = ["readonly"]
const _hoisted_55 = {
  key: 1,
  type: "text",
  value: "시장가",
  readonly: ""
}
const _hoisted_56 = ["readonly"]
const _hoisted_57 = {
  key: 0,
  class: "sl"
}
const _hoisted_58 = { class: "flex" }
const _hoisted_59 = { class: "typing-box" }
const _hoisted_60 = { class: "flex" }
const _hoisted_61 = { class: "typing-box last" }
const _hoisted_62 = {
  key: 1,
  class: "buttons"
}
const _hoisted_63 = {
  key: 2,
  class: "buttons"
}
const _hoisted_64 = {
  key: 3,
  class: "buttons"
}
const _hoisted_65 = {
  class: "body-box subPage transactionForm tradeList",
  style: {"padding-top":"12px"}
}
const _hoisted_66 = { class: "con-box bottom" }
const _hoisted_67 = { class: "left index" }
const _hoisted_68 = { class: "trade-list-box" }

import useBaseComponent from "@/baseComponent";
import { Theme } from '../constants';
// import i18n from '../services/i18n';
import moment from '../utils/moment';
import * as Common from '../constants/Common';
import { toLocaleString } from '../utils/number';
import * as Utils from '../utils/Common';
import { NetworkService, Emit_DrawTrans, Emit_UserOption, Emit_ResetAmount, XingAPIService, Emit_ContractData, Emit_CallData, Emit_Success } from '../network';
import { UserLog } from '../network/UserLog';
import * as AccPwd from '../constants/AccPwd';
import * as Constant from '../constants/Global';

// 탭 구분
const Trade_Buy = 0;     //매수
const Trade_Sell = 1;    //매도
const Trade_Stoploss = 2; //S/L예약주문
const Trade_Correct = 3; //정정/취소
// const Trade_Max = 4;

// 화면 갱신
const REFRESH_INTERVAL = 300;  //100;

// 호가/체결 탭 구분
const Quote_Call = 0; // 초가
const Quote_Contract = 1;   // 체결
// const Quote_Max = 2;

const SELL_LINE_MAX = 5;
const BUY_LINE_MAX = 5;
// const QUOTE_LINE_MAX = (SELL_LINE_MAX + BUY_LINE_MAX + 2); // + 고가 / 저가

// 체결 데이터 수
const QUOTE_DATA_MAX = 20;

const SLNoticeTitle = '해외선물 STOP 예약주문 유의사항';
const SLNoticeContent = `
    <div class="totalOrder-text">
        <p class="text blue">
            해외선물 STOP 예약주문은 네트워크 장애, 시세지연, 천재지변등 다양한 요인에 의해 정상적으로 작동하지 않을 수 있으니 반드시 아래의 사용법과 유의사항을 숙지하신 후 이용하여 주시기 바랍니다.
        </p>
        <p class="text ">
            1. STOP 예약주문의 정의 <br /> 기본 개념은 손절매을 위한 주문으로 조건 주문 중 하나, 현재가격이 설정한 조건가격에 도달하게 되면 조건주문이 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            2. STOP 예약주문 집행 방식 및 절차 <br /> 현재가격이 조건가격에 다다를 경우 시장가 주문으로 전환되어 나가는 주문.
        </p>
        <p class="text ">
            현재가 >= 조건가 (이익/손실 틱)> 시장가 주문
        </p>
        <p class="text red">
            [S/L 집행] 실행 이후 조건을 변경 하면  [S/L 집행] 를 다시 실행해야 합니다. 
        </p>
        <p class="text ">
            3. STOP 예약주문 유의사항 <br /> STOP 예약주문은 현재가격이 조건가격에 <br /> 도달했을 시 시장가 주문으로 접수 / 처리 됩니다.
        </p>
        <p class="text red">
            이로 인해 시장이 급등락하거나, 호가공백이 발생할 경우 체결이 되지 않거나 STOP 가격보다 불리한 가격에 체결이 될 가능성이 있으니 이점 반드시 숙지하시기 바랍니다.
        </p>
    </div>`;


export default /*@__PURE__*/_defineComponent({
  __name: 'TotalOrder',
  setup(__props) {

const { router, store, onMounted, onUnmounted, reactive, watch } = useBaseComponent();

const GRID_SELL_COLOR = { backgroundColor: Theme.mts.gridSellBg };
const GRID_BUY_COLOR = { backgroundColor: Theme.mts.gridBuyBg };
// const GRID_CENTER_COLOR = { backgroundColor: Theme.mts.gridCenter };
// const GRID_CURPRICE_COLOR = { backgroundColor: Theme.mts.bgCurPrice };
// const GRID_TEXT_CURPRICE_COLOR = { color: Theme.mts.textCurPrice };
const GRID_TEXT_BASEPRICE_COLOR = { color: Theme.mts.textBasePrice };
// const TEXT_COLOR = { color: Theme.mts.text };

const TradingInfo = [
    // Trade_Buy
    { name: '매수', button: '매수', color: '#e74e36', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#f7392c', },
    // Trade_Sell
    { name: '매도', button: '매도', color: '#2754a0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#35569a', }, 
    // Trade_Stoploss
    { name: 'S/L 주문', button: 'S/L 집행', button2: 'S/L 취소', color: '#0bbfe0', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#68badb', }, 
    // Trade_Correct
    { name: '정정/취소', button: '정정주문', button2: '취소주문', color: '#2ec751', bgColor: '#ffffff', pannelColor: '#ffffff', btnColor: '#5fc25b', },
];

// S/L 예약 유의사항
let state = reactive({

    // 종목 선택
    pickFuturesOpen: false,
    pickFuturesValue: '',
    pickFuturesItems: [ ] as any,
    pickFuturesDesc: '',
    pickFuturesSelectedItem: {} as any,

    // 계좌 선택
    pickAccOpen: false,
    pickAccValue: '',
    pickAccDesc: '계좌를 선택하세요',
    pickAccItems: [] as any,
    password: '',
    bAccPassed: false,

    // 가격 타입 선택(지정가, 시장가)
    // pickPriceValue: 1,
    // pickPriceDesc: '',
    // pickPriceItems: [] as any,

    //미체결 리스트
    leftOrders: [] as any,
    leftSelected: -1,

    futures: Common.G_FUTURES_DAY,
    oneTick: Common.G_ONETICK[Common.G_FUTURES_DAY],
    isOversea: Common.eNation_Domestic,

    depoAmount: 0,				// 국내예탁금액
    depoAmountOversea: 0,		// 해외예탁금액
    estimatedAmount: 0,			// 국내평가예탁금액
    estimatedAmountOversea: 0,	// 해외평가예탁금액
    totalProfit: 0,				// 총평가손익
    netDomestic: 0,				// 국내실현손익
    netOversea: 0,				// 해외실현손익

    //현재 거래 탭
    tradingIdx: Trade_Buy,

    //가격 종류 0:시장가 1:지정가
    selectedPriceType: Common.G_PRICE_APPOINT,  //라디오 버튼
    // priceType: Common.G_PRICE_APPOINT,
    amount: '0',
    amountNumber: 0,
    price: '0',
    
    //원 주문 정보
    orgOrderNo: '', //원주문번호
    orgPrice: '0', // 정정시 원 가격
    orgTradeType: Common.G_TRADE_SELL, // 정정시 원 유형

    // 주문가능
    orderLeft: 0,

    // 청산가능
    settleLeft: 0,

    // 미체결 / 체결
    leftAmount: 0,
    oppositeAmount: 0,

    // 익절 / 손절
    oppOrder: null as any,
    profitTicks: '',
    lossTicks: '',

    전일가: 0.,
    현재가: 0.,
    전일대비기호: '1',
    전일대비: 0.,
    등락률: 0.,
    priceColor: '',
    diffColor: '', 

    // 매수
    buyData: [[], [], [],] as any,
    // 매도
    sellData: [[], [], [],] as any,
    // 정보
    infoData: {
        //종목: Common.G_FUTURES_DAY,
        //현재가: 0,
        //전일가: 0,
        //대비기호: '',
        //대비: 0,
        //대비율: 0,
        시가: 0,
        고가: 0,
        저가: 0,
        //만기일: '',
        //환율: '',
        //틱가치: '',
        //수수료: '',
        //잔존일: 0,
        거래량: 0,
    } as any,
    // 체결
    quoteData: [] as any,
    // 합계: 건수 매도 가격 매수 건수
    totalData: [] as any,

    refreshingTime: null as any,

    // 현재시간
    clock: '',
    timerClock: null as any,

    // 현재 호가/체결 탭
    tabIdx: Quote_Call,
    
    // 미체결 리스트 팝업
    isShowPopupLeftOrders: false,
    // 상단 돋보기 버튼
    isShowPopupStockSearch:false,
    // 차트 팝업
    initIsShowPopupChart:false,
    popupChartType:'left',
    // 공지 팝업
    initShowNoticePopup:false,
    //레버리지 팝업
    isShowLeverageSetting:false,

    // 종목 선택 세팅 타이머
    timerInitFuturesPicker: null as any,
    
    // 계좌 정보 세팅
    timerInitData: null as any,

    //totalOrder height
    bottomHeight:0,
    tableHeight:0,
    popupHeight:0,
    resizeTimer: null as any,
});

onMounted(()=>{
    if(store.LoginPageState.loginState != 1){
        router.push('/');
        return;
    }
    store.setLastViewPage('TotalOrder');

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.on(Emit_UserOption, event_UserOption);
    NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);

    NetworkService.emitter.on(Emit_ContractData, event_ContractData);
    NetworkService.emitter.on(Emit_CallData, event_CallData);
    // NetworkService.emitter.on(Emit_UserOption, event_UserOption);
    resizeHandler();
    window.addEventListener('resize', resizeEnded);
    if (store.LoginPageState.isPacketLoginAckReceived) initData();
})

onUnmounted(()=> {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_DrawTrans, event_DrawTrans);
    NetworkService.emitter.off(Emit_UserOption, event_UserOption);
    NetworkService.emitter.off(Emit_ResetAmount, event_ResetAmount);
    NetworkService.emitter.off(Emit_ContractData, event_ContractData);
    NetworkService.emitter.off(Emit_CallData, event_CallData);
    // NetworkService.emitter.off(Emit_UserOption, event_UserOption);

    window.removeEventListener('resize', resizeEnded);
    if(state.timerClock){
        clearTimeout(state.timerClock);
    }
    if(state.timerInitFuturesPicker){
        clearTimeout(state.timerInitFuturesPicker);
    }
    if(state.timerInitData){
        clearTimeout(state.timerInitData);
    }
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
})

const event_DrawTrans = (params: any) => {
    // 주문 업데이트
    // 체결 / 미체결 업데이트
    refreshOrderCount();
    if (state.tradingIdx === Trade_Stoploss) {
        refreshStoploss();
    }
}

const event_UserOption = (params: any) => {
    // 유저 설정
    // 체결 / 미체결 업데이트
    refreshOrderCount();

    //아래 Quote 부분 
    // const futures = state.futures;
    // if (futures === Common.G_FUTURES_MAX) {
    //   return;
    // }

    // const commissionRate = store.GetFutureCommissionRate(futures);
    // let szCommission = `${commissionRate.toFixed(4)}%`;
    // if (futures >= Common.G_FUTURES_OVERSEAS) {
    //     szCommission = `${futures === Common.G_FUTURES_HANGSENG ? 'HKD' : 'USD'} ${commissionRate.toFixed(2)}`;
    // }
    // 수수료 표시 안함
    //this.infoData.수수료 = szCommission;
}

const event_ResetAmount = (params: any) => {
    // 입출금액 초기화, 예탁잔고, 평가금액, 출금가능금액을 업데이트한다.
    // 체결 / 미체결 업데이트
    refreshOrderCount();
}

const event_ContractData = (params: any) => {
    // ContractObj
    const { contractData } = params;

    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = contractData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    const numOfQuoteData = QUOTE_DATA_MAX;
    // const onContractData = state.onContractData;

    const item = {
        iFutures: contractData.iFutures,
        szCTime: contractData.szCTime,
        iTrdq: contractData.iTrdq,
        dbCurPrice: contractData.dbCurPrice,
        dbBPrice: contractData.dbBPrice,
        szCGubun: contractData.szCGubun,
    };
    state.quoteData.push(item);
    if (state.quoteData.length > numOfQuoteData) {
        state.quoteData.shift();
    }

    updateInfoData(futures, contractData);
    onContractData(contractData);
}

const event_CallData = async (params: any) => {
    // CallObj
    const { callData } = params;
    const futures = callData.iFutures;
    if (futures !== state.futures) {
        return;
    }

    // 포커스 중일 때만 그린다.
    // const { isFocused } = this.props;
    // if (!isFocused) {
    //     return;
    // }

    const now = Date.now();
    if (state.refreshingTime === null || (now - state.refreshingTime) > REFRESH_INTERVAL) {
        // 마스터에 이미 업데이트 되었다.
        onRefresh();

        state.refreshingTime = now;
    }
}

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        NetworkService.emitter.on(Emit_DrawTrans, event_DrawTrans);
        NetworkService.emitter.on(Emit_UserOption, event_UserOption);
        NetworkService.emitter.on(Emit_ResetAmount, event_ResetAmount);
        NetworkService.emitter.on(Emit_ContractData, event_ContractData);
        NetworkService.emitter.on(Emit_CallData, event_CallData);
        initData();
    }
};

const initData = () => {

    //로그인 후 계좌정보가 store에 저장됐는지
    if(!store.LoginPageState.isPacketAccInfoReceived){
        state.timerInitData = setTimeout(() => {
            initData();
        }, 100)
        return;
    }

    const futures = store.FuturesByNow();
    state.futures = futures;
    // state.quoteFutures = futures;   // Quotes 세팅
    
    initFuturesPicker();
    // initAccPicker();
    // initPricePicker();
    initClock();

    const autoRefreshing = async () => {
        // 예탁금, 평가예탁금, 평가손익
        calcuAmount();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
}

// 종목 선택 세팅
const initFuturesPicker = async () => {
    if (!store.MasterState.isReadyRECV) {
        state.timerInitFuturesPicker = setTimeout(() => {initFuturesPicker()}, 200);
        return;
    }

    let pickItems = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        if (store.MarketState.bDrawMenu[futures]) {
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            const expiryDay = _expiryDate.substring(2, 10); // YY-MM-DD
            const futuresName = Common.g_szFuturesNameMini[futures];
            const code = store.MarketState.szFutureCode[futures];
            pickItems.push({
                label: futuresName,
                value: code,
                futures,
                expiryDate: expiryDay,
                desc: `${code} (${expiryDay})`,
                selected: false
            });
        }
    }
    let futIdx = pickItems.findIndex(p => p.futures === state.futures);
    if (futIdx < 0) {
        futIdx = 0;
    }
    const pickValue = pickItems[futIdx]?.value;
    state.pickFuturesItems = pickItems;
    state.pickFuturesValue = pickValue;
    state.pickFuturesDesc = pickItems[futIdx]?.label + '<br/>' +  pickItems[futIdx]?.desc;
    state.pickFuturesSelectedItem = pickItems[futIdx];

    onPickFuturesChangeValue(pickItems[futIdx]);
}

const onPickFuturesChangeValue = (param: any) => {
    state.pickFuturesOpen = false;

    state.pickFuturesItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {
            // state.selectedItem = item;
            state.pickFuturesValue = item.value;
            state.pickFuturesDesc = item.desc;
            state.pickFuturesDesc = item.label + '<span>' + item.desc+ '</span>' ;
            // state.pickFuturesDesc = item.label + '<br/>' + item.desc;
            state.pickFuturesItems[index].selected = true;
            state.futures = item.futures;
            state.pickFuturesSelectedItem = item;
            onFuturesChange(item.futures);

        } else {
            state.pickFuturesItems[index].selected = false;
        }
    });

    initAccPicker();
}

// 계좌 선택 세팅
const initAccPicker = () => {
    const pickItems = [];
    // const futures = store.FuturesByNow();
    const futures = state.futures;
    const isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;
    const accPwdApply = Number(store.SettingsState.settings.accPwdApply);

    // 국내 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNo,
        value: store.AccountState.szDepoAccNo,
        desc: `${store.AccountState.szDepoAccNo} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Domestic]})`,
        pwd: store.AccountState.szDomesticAccPwd,
        bOversea: 0,
        selected: false
    });
    // 해외 계좌 / 비밀번호
    pickItems.push({
        label: store.AccountState.szDepoAccNoOversea,
        value: store.AccountState.szDepoAccNoOversea,
        desc: `${store.AccountState.szDepoAccNoOversea} [${store.AccountState.szName}](${Common.g_szNationName[Common.eNation_Oversea]})`,
        pwd: store.AccountState.szOverseaAccPwd,
        bOversea: 1,
        selected: false
    });

    // 계좌 비밀번호 체크 생략
    state.bAccPassed = true;

    // if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
    //     // 비밀번호 인증 패스 여부
    //     state.bAccPassed = store.getAccPassed();

        // 국내/해외 패스워드
        if (state.bAccPassed) {
            if(isOversea === 0){
                state.password = store.getDomesticAccPwd();
            } else {
                state.password = store.getOverseaAccPwd();
            }
        }
    // }

    state.pickAccItems = pickItems;
    onPickAccChangeValue(pickItems[isOversea]);
}

const onPickAccChangeValue = (param: any) => {
    state.pickAccOpen = false;

    state.pickAccItems.forEach((item: any, index: number) => {
        if (item.value === param.value) {            
            state.pickAccValue = item.value;
            state.pickAccDesc = item.desc;
            state.pickAccItems[index].selected = true;
        } else {
            state.pickAccItems[index].selected = false;
        }
    });
}

// // 가격 종류 세팅
// const initPricePicker = () => {
//     let pickPriceItems = [];
//     // 시장가
//     pickPriceItems.push({
//         label: Common.g_szPriceTypeName[Common.G_PRICE_MARKET],
//         value: Common.G_PRICE_MARKET,
//     });
//     // 지정가
//     pickPriceItems.push({
//         label: Common.g_szPriceTypeName[Common.G_PRICE_APPOINT],
//         value: Common.G_PRICE_APPOINT,
//     });
//     const pickPriceValue = pickPriceItems[Common.G_PRICE_APPOINT].value;
//     state.pickPriceItems = pickPriceItems;
//     state.pickPriceDesc = pickPriceItems[Common.G_PRICE_APPOINT].label;
//     state.pickPriceValue = pickPriceValue;
// }

// const onPickPriceChangeValue = (param: any) => {
//     state.pickPriceItems.forEach((item: any, index: number) => {
//         if (item.value === param.value) {
//             state.pickPriceValue = item.value;
//             state.pickPriceDesc = item.label;
//             if (state.tradingIdx === Trade_Buy || state.tradingIdx === Trade_Sell) {
//                 state.priceType = item.value;
//             }
//             if(state.pickPriceValue === Common.G_PRICE_MARKET){
//                 state.price = '시장가';
//             }
//         }
//     });

//     //지정가를 선택한 경우 기타 입력 및 선택 부분들 초기화
//     if(param.value === Common.G_PRICE_APPOINT){
//         onPressInit();
//     }
// }

// 현재 시간
const initClock = () => {
    const tick_on = async () => {
        state.clock = moment(new Date()).format('HH:mm:ss');
        
        state.timerClock = setTimeout(tick_on, 1000);
    }
  
    tick_on();
}

// 체결 정보창
const initInfoData = () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    const futures = state.futures;
    if (futures === Common.G_FUTURES_MAX) {
        return;
    }

    // 2자리 년도 제거
    // const expiryDate = store.MarketState.szFutureExpireDate[futures]?.substring(2, 10);
    // const expiryLeftDay = store.MarketState.iFutureExpireLeftDay[futures];
    // const exchRate = futures === Common.G_FUTURES_HANGSENG ? store.BrandState.ExchangeRateHKD : store.BrandState.ExchangeRate;
    // const commissionRate = store.GetFutureCommissionRate(futures);
    // let szCommission = `${commissionRate.toFixed(4)}%`;
    // if (futures >= Common.G_FUTURES_OVERSEAS) {
    //     szCommission = `${futures === Common.G_FUTURES_HANGSENG ? 'HKD' : 'USD'} ${commissionRate.toFixed(2)}`;
    // }

    // const 현재가 = store.MasterState.dbCurPrice[futures];
    const 전일가 = store.MasterState.dbBPrice[futures];
    const 시가 = store.MasterState.dbOpenPrice[futures];
    const 고가 = store.MasterState.dbHighPrice[futures];
    const 저가 = store.MasterState.dbLowPrice[futures];
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = store.MasterState.szYDiffSign[futures];
    // const 대비 = store.MasterState.dbYDiffPrice[futures];
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = store.MasterState.totq[futures];

    // let infoData = {} as any;
    //infoData.종목 = { value: futures, color: null };
    //infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //infoData.대비율 = { value: `${store.MasterState.dbChgRate[futures]}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    //infoData.만기일 = { value: expiryDate, color: Theme.mts.text };
    //infoData.환율 = { value: `₩${toLocaleString(exchRate.toFixed(2))}`, color: Theme.mts.text };
    //infoData.틱가치 = { value: `${toLocaleString(BrandStore.G_TICK_PRICE[futures])}원`, color: Theme.mts.text };
    //infoData.수수료 = { value: szCommission, color: Theme.mts.text };
    //infoData.잔존일 = { value: `${expiryLeftDay}일`, color: Theme.mts.text };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };

    // state.infoData = infoData;
}

// 체결 정보창 갱신
const updateInfoData = (futures: any, contractData: any) => {
    // const 현재가 = contractData.dbCurPrice;
    const 전일가 = contractData.dbBPrice;
    const 시가 = contractData.dbOpenPrice;
    const 고가 = contractData.dbHighPrice;
    const 저가 = contractData.dbLowPrice;
    // const priceColor = 현재가 > 전일가 ? Theme.mts.up : 현재가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const openColor = 시가 > 전일가 ? Theme.mts.up : 시가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const highColor = 고가 > 전일가 ? Theme.mts.up : 고가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    const lowColor = 저가 > 전일가 ? Theme.mts.up : 저가 < 전일가 ? Theme.mts.down : Theme.mts.slate;
    // const 대비기호 = contractData.szYDiffSign;
    // const 대비 = contractData.dbYDiffPrice;
    // const 대비율 = contractData.dbChgRate;
    // const diffColor = 대비기호 === '1' || 대비기호 === '2' ? Theme.mts.up : 대비기호 === '4' || 대비기호 === '5' ? Theme.mts.down : Theme.mts.slate;
    const 거래량 = contractData.totq;

    //this.infoData.현재가 = { value: Utils.PriceFormatStr(현재가, futures), color: priceColor };
    //this.infoData.전일가 = { value: Utils.PriceFormatStr(전일가, futures), color: Theme.mts.slate};
    //this.infoData.대비 = { value: `${XingAPIService.GetSignData(대비기호)}${Utils.PriceFormatStr(대비, futures)}`, color: diffColor };
    //this.infoData.대비율 = { value: `${대비율}%`, color: diffColor };
    state.infoData.시가 = { value: Utils.PriceFormatStr(시가, futures), color: openColor };
    state.infoData.고가 = { value: Utils.PriceFormatStr(고가, futures), color: highColor };
    state.infoData.저가 = { value: Utils.PriceFormatStr(저가, futures), color: lowColor };
    state.infoData.거래량 = { value: toLocaleString(거래량), color: store.CommonState.themeMode === 'dark' ? Theme.mts.white : Theme.mts.text };
}

const onRefresh = async() => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    // 종목 찾기
    const futures = state.futures;
    if (futures >= Common.G_FUTURES_MAX) {
        return;
    }

    try {
        // 매도 1호가
        // const open = store.MasterState.dbOpenPrice[futures];  // 시작가
        const base = store.MasterState.dbBPrice[futures]; // 기준가
        const high = store.MasterState.dbHighPrice[futures]; // 고가
        const low = store.MasterState.dbLowPrice[futures]; // 저가
        const current = store.MasterState.dbCurPrice[futures]; // 현재가
        const offerhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.offerho5[futures], store.MasterState.offerrem5[futures], store.MasterState.offerno5[futures], ],
            [ store.MasterState.offerho4[futures], store.MasterState.offerrem4[futures], store.MasterState.offerno4[futures], ],
            [ store.MasterState.offerho3[futures], store.MasterState.offerrem3[futures], store.MasterState.offerno3[futures], ],
            [ store.MasterState.offerho2[futures], store.MasterState.offerrem2[futures], store.MasterState.offerno2[futures], ],
            [ store.MasterState.offerho1[futures], store.MasterState.offerrem1[futures], store.MasterState.offerno1[futures], ],
        ];
        const bidhoData = [ 
        // 가격, 잔량, 건수
            [ store.MasterState.bidho1[futures], store.MasterState.bidrem1[futures], store.MasterState.bidno1[futures], ],
            [ store.MasterState.bidho2[futures], store.MasterState.bidrem2[futures], store.MasterState.bidno2[futures], ],
            [ store.MasterState.bidho3[futures], store.MasterState.bidrem3[futures], store.MasterState.bidno3[futures], ],
            [ store.MasterState.bidho4[futures], store.MasterState.bidrem4[futures], store.MasterState.bidno4[futures], ],
            [ store.MasterState.bidho5[futures], store.MasterState.bidrem5[futures], store.MasterState.bidno5[futures], ],
        ];
        // 매도
        let sell1Data = [], sell2Data = [], sell3Data = [];
        // 고가
        sell1Data.push({
            title: '고가',
            textColor: { color: Theme.mts.lightSlate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        sell2Data.push({
            title: Utils.PriceFormatStr(high, futures),
            textColor: high > base ? { color: Theme.mts.up } : high < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        sell3Data.push({ title: '' });
        // 5호가
        for (let idx = 0; idx < SELL_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const offerho = offerhoData[idx];
            const price = offerho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_SELL_COLOR;
            // 잔량
            data2.title = `${offerho[1]}`;
            data2.bgColor = GRID_SELL_COLOR;
            // 건수
            data3.title = `${offerho[2]}`;
            data3.bgColor = GRID_SELL_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            sell1Data.push(data1);
            sell2Data.push(data2);
            sell3Data.push(data3);
        }

        // 매수
        let buy1Data = [], buy2Data = [], buy3Data = [];
        // 5호가
        for (let idx = 0; idx < BUY_LINE_MAX; idx++) {
            let data1 = { title: '' } as any, data2 = { title: '' } as any, data3 = { title: '' } as any;
            const bidho = bidhoData[idx];
            const price = bidho[0];
            const szPrice = Utils.PriceFormatStr(price, futures);
            // 가격
            data1.title = szPrice;
            const changeRate = (price - base) / base * 100;
            data1.changeRate = `${changeRate.toFixed(2)}%`;
            data1.bgColor = GRID_BUY_COLOR;
            // 잔량
            data2.title = `${bidho[1]}`;
            data2.bgColor = GRID_BUY_COLOR;
            // 건수
            data3.title = `${bidho[2]}`;
            data3.bgColor = GRID_BUY_COLOR;
            // 저 ~ 고 배경색
            /*
            if (price <= high && price >= low) {
                data1.bgColor = GRID_CENTER_COLOR;
            }
            */
            // 가격의 색
            if (szPrice === Utils.PriceFormatStr(base, futures)) {
                data1.textColor = GRID_TEXT_BASEPRICE_COLOR;
            } else if (price < base) {
                data1.textColor = { color: Theme.mts.down };
            } else if (price > base) {
                data1.textColor = { color: Theme.mts.up };
            }
            // 현재가
            if (szPrice === Utils.PriceFormatStr(current, futures)) {
                //data1.textColor = GRID_TEXT_CURPRICE_COLOR;
                //data1.bgColor = GRID_CURPRICE_COLOR;
                data1.bCurrent = true;
            }

            buy1Data.push(data1);
            buy2Data.push(data2);
            buy3Data.push(data3);
        }
        // 저가
        buy1Data.push({
            title: '저가',
            textColor: { color: Theme.mts.lightSlate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        buy2Data.push({
            title: Utils.PriceFormatStr(low, futures),
            textColor: low > base ? { color: Theme.mts.up } : low < base ? { color: Theme.mts.down } : { color: Theme.mts.slate },
            bgColor: { backgroundColor: Theme.mts.white },
        });
        buy3Data.push({ title: '' });

        // 합계: 건수 매도 가격 매수 건수
        let totalSell = [], totalBuy = [];
        const totoffercnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerno1[futures] + store.MasterState.offerno2[futures] + store.MasterState.offerno3[futures] + store.MasterState.offerno4[futures] + store.MasterState.offerno5[futures] : store.MasterState.totoffercnt[futures];
        const totofferrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.offerrem1[futures] + store.MasterState.offerrem2[futures] + store.MasterState.offerrem3[futures] + store.MasterState.offerrem4[futures] + store.MasterState.offerrem5[futures] : store.MasterState.totofferrem[futures];
        totalSell.push('');   // 가격
        totalSell.push(totofferrem); // 잔량
        totalSell.push(totoffercnt); // 건수
        const totbidcnt = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidno1[futures] + store.MasterState.bidno2[futures] + store.MasterState.bidno3[futures] + store.MasterState.bidno4[futures] + store.MasterState.bidno5[futures] : store.MasterState.totbidcnt[futures];
        const totbidrem = futures < Common.G_FUTURES_OVERSEAS ? store.MasterState.bidrem1[futures] + store.MasterState.bidrem2[futures] + store.MasterState.bidrem3[futures] + store.MasterState.bidrem4[futures] + store.MasterState.bidrem5[futures] : store.MasterState.totbidrem[futures];
        totalBuy.push('');   // 가격
        totalBuy.push(totbidrem); // 잔량
        totalBuy.push(totbidcnt); // 건수

        // 화면 갱신 속도 조절
        state.sellData = [ sell1Data, sell2Data, sell3Data ];
        state.buyData = [ buy1Data, buy2Data, buy3Data ];
        state.totalData = [ totalSell, totalBuy ];
    } catch (e) {
        if (store.debugLog >= Constant.DebugLevel_Minimal)
            console.error(`데이터 리플래시 중 예외발생: ${JSON.stringify(e)}`);
    }
}

const calcuAmount = () => {
    const depoAmount = store.AccountState.iDepoAmount;
    const depoAmountOversea = store.AccountState.iDepoAmountOversea;

    // 총 실현 손익을 구한다.
    const [totalProfit, totalProfitDomestic, totalProfitOversea] = store.GetClientProfitAll();
    // 평가예탁금액
    const estimatedAmountOversea = depoAmountOversea + totalProfitOversea;
    const estimatedAmount = depoAmount + totalProfitDomestic;

    // 평가예탁금
    state.estimatedAmount = estimatedAmount * store.AccountState.iLeverage;
    state.estimatedAmountOversea = estimatedAmountOversea * store.AccountState.iLeverageEuro;
    // 총평가손익
    state.totalProfit = totalProfit;
    // 실현손익
    // 당일 실현손익 & 수수료
    const [gainNLossTotal, gainNLossDomestic, gainNLossOversea] = store.GetClientTodayGainNLoss();
    const [commissionTotal, commissionDomestic, commissionOversea] = store.GetClientTodayCommission();
    const netDomestic = gainNLossDomestic - commissionDomestic;
    const netOversea = gainNLossOversea - commissionOversea;
    state.netDomestic = netDomestic;
    state.netOversea = netOversea;

    // 예탁금: 마지막에 대입
    state.depoAmountOversea = depoAmountOversea;
    state.depoAmount = depoAmount;
}

const onChangePriceType = (e:any) => {
    state.selectedPriceType = e.target.value;
}

const onChangeAmount = (value = 0 as number) =>{
    // const totalAmount = Number(state.amount) + value;

    // if (totalAmount > state.orderLeft) {
    //     state.amount = state.orderLeft.toString();
    // } else if(totalAmount < 0){
    //     state.amount = '0';
    // } else {
    //     state.amount = totalAmount.toString();
    // }

    const amount = state.amount.replace(/[^0-9]/g, "");
    state.amountNumber = Number(amount);
    const totalAmount = state.amountNumber + value;

    if(totalAmount < 0 ){
        state.amountNumber = 0;
    } else if (totalAmount > state.orderLeft) {
        state.amountNumber = state.orderLeft;
    } else {
        state.amountNumber = totalAmount;
    }

    state.amount = state.amountNumber.toString();
}

const onChangePrice = (tick: number) => {
    const digit = Common.g_bFuturesUnitOfDigit[state.futures];
    const calcTick = state.oneTick * tick;
    const totalPrice = Number(state.price) + calcTick;
    state.price = totalPrice.toFixed(digit);
}

const onProfitAmount = (value: number) =>{
    const totalProfit = Number(state.profitTicks) + value;

    if(totalProfit < 1){
        state.profitTicks = '1';
    } else {
        state.profitTicks = totalProfit.toString();
    }
}

const onLossAmount = (value: number) =>{
    const totalLoss = Number(state.lossTicks) + value;

    if(totalLoss < 1){
        state.lossTicks = '1';
    } else {
        state.lossTicks = totalLoss.toString();
    }
}

const checkAccPwd = () => {

    if (!state.bAccPassed) {
        const selectedItem = state.pickAccItems?.find((item: any) => item.value === state.pickAccValue);
        const accPwdApply = Number(store.SettingsState.settings.accPwdApply);
        const pwd1 = selectedItem.bOversea === 0 ? state.password : null;
        const pwd2 = selectedItem.bOversea === 1 ? state.password : null;

        if(accPwdApply !== AccPwd.cAccPwdApply_Every) {
            store.checkAccountPassword({pwd1: pwd1, pwd2: pwd2, checkType: accPwdApply});
        }

        // 비번 확인
        if (state.password === '') {
            store.setVisibleDialog({ message: '비밀번호를 입력해주세요.' });
            return false;
        }

        
        const password = selectedItem?.pwd;
        if (state.password !== password) {
            const message = `${Common.g_szNationName[selectedItem?.bOversea]} 계좌 비밀번호가 일치하지 않습니다.\n정확한 비밀번호를 입력해주세요.`;
            store.setVisibleDialog({ message, title: '계좌 비밀번호 확인' });
            return false;
        }
        
        state.bAccPassed = true;
        store.setVisibleToast({message:'비밀번호가 확인되었습니다.', duration:1200});
    }

    return true;
}

const onTabPress = (tradingIdx: any) => {
    const options = store.SettingsState.settingsEx.options;

    if (state.tradingIdx !== tradingIdx) {
        state.tradingIdx = tradingIdx;

        const futures = state.futures;
        onFuturesChange(futures);

        if (tradingIdx === Trade_Buy || tradingIdx === Trade_Sell) {
            // 매수/매도 가격유형 복원
            const pickValue = state.selectedPriceType;
            state.pickAccValue = pickValue.toString();
        } else {
            // S/L, 정정 가격유형 지정가 고정
            const pickValue = Common.G_PRICE_APPOINT;
            state.pickAccValue = pickValue.toString();
        }

        // S/L인 경우 체결된 주문을 넣는다.
        if (tradingIdx === Trade_Stoploss) {
            // 오늘하루 안보기 설정
            const today = moment(Date.now()).format('YYYY-MM-DD');
            if(options.stoplossToday !== today){
                state.initShowNoticePopup = true;
                document.body.style.overflow = "hidden";
            }

            refreshStoploss();
        }
    }
}

const onQuoteTabPress = (tabIdx: any) => {
    if (state.tabIdx !== tabIdx) {
        state.tabIdx = tabIdx;
        // if (tabIdx === Quote_Contract) {
        //     this.scrollToTop();
        // }
        
    }
}

const onPressNotToday = () => {
    // 오늘하루 안보기 설정
    const today = moment(Date.now()).format('YYYY-MM-DD');
    store.updateSettingsEx({ options: { stoplossToday: today } });

    close('PopupNotice');
}

const refreshStoploss = () => {
    const futures = state.futures;

    // const tradeType = store.AccountState.iOrderPos[futures]===Common.C_ORDERPOS_SELL ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    // 총 잔량(반대매매)의 수량과 총 가격을 얻는다.
    const oppositeOrders = store.GetClientOppositeList(futures);
    const oppOrder = oppositeOrders?.length > 0 ? oppositeOrders[0] : null;
    let oppAverage = store.MasterState.dbCurPrice[futures];
    if (oppOrder) {
        const oppAmount = oppOrder.iOppositeAmount;
        const profitTicks = oppOrder.iProfitTicks;
        const lossTicks = oppOrder.iLossTicks;
        oppAverage = oppOrder.dbContractPrice;

        state.amount = `${oppAmount}`;
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = `${profitTicks === 0 ? '' : profitTicks}`;
        state.lossTicks = `${lossTicks === 0 ? '' : lossTicks}`;
    } else {
        state.amount = '';
        state.price = Utils.PriceFormatStr(oppAverage, futures);

        state.profitTicks = '';
        state.lossTicks = '';
    }
    state.oppOrder = oppOrder;
}

const onFuturesChange = (futures: any) => {

    store.setLastFuturesSelected(futures);
    store.AccountState.G_Future_Code = futures;

    state.futures = futures;
    state.oneTick = Common.G_ONETICK[futures];
    state.isOversea = futures >= Common.G_FUTURES_OVERSEAS ? 1 : 0;

    // 마지막 체결가
    state.전일가 = store.MasterState.dbBPrice[futures];
    state.현재가 = store.MasterState.dbCurPrice[futures];
    state.전일대비기호 = store.MasterState.szYDiffSign[futures];
    state.전일대비 = store.MasterState.dbYDiffPrice[futures];
    state.등락률 = store.MasterState.dbChgRate[futures];
    state.priceColor = state.현재가 > state.전일가 ? Theme.mts.up : state.현재가 < state.전일가 ? Theme.mts.down : Theme.mts.normal;
    state.diffColor = state.전일대비기호 === '1' || state.전일대비기호 === '2' ? Theme.mts.up : state.전일대비기호 === '4' || state.전일대비기호 === '5' ? Theme.mts.down : Theme.mts.normal;

    store.AccountState.G_Future_Code = futures;

    state.quoteData = [];
    // 최종 체결가 넣기
    if (store.MasterState.isReadyRECV) {
        const quote = {
            iFutures: futures,
            szCTime: store.MasterState.szCTime[futures],
            iTrdq: store.MasterState.iTrdq[futures],
            dbCurPrice: store.MasterState.dbCurPrice[futures],
            dbBPrice: store.MasterState.dbBPrice[futures],
            szCGubun: store.MasterState.szCGubun[futures],
        };
        state.quoteData.push(quote);
    }

    initValue(futures);
    initInfoData();
    refreshOrderCount();
    onRefresh();

    // const { onFuturesChange } = this.props;
    // onFuturesChange && onFuturesChange(futures);
    // onFuturesChange(futures);

    // this.scrollToCenter(this.quoteLayoutHeight, this.quoteContentHeight);
}

const initValue = (futures: any) => {
    state.amount = '';
    const curPrice = store.MasterState.dbCurPrice[futures];
    state.price = Utils.PriceFormatStr(curPrice, futures);

    state.orgOrderNo = '';

    state.profitTicks = '';
    state.lossTicks = '';
}

const onPressInit = () => {
    const futures = state.futures;
    const tradingIdx  = state.tradingIdx;

    // S/L인 경우 체결된 주문을 넣는다.
    if (tradingIdx === Trade_Stoploss) {
      refreshStoploss();
    } else {
      initValue(futures);
    }
}

const onPricePress = (price: string) => {

    if(price === '고가' || price === '저가'){return;}
    
    if (state.tradingIdx === Trade_Stoploss) {
        // S/L인 경우 손/익 틱을 변동시킨다.
    } else {
        state.price = price;
    }
}

const onContractData = (contractData: any) => {
    // 마스터 수신여부, 종목 등, 이미 걸러진 상태로 들어온다.
    // store.MasterState.isReadyRECV, this.futures==contractData.iFutures

    // ContractObj
    const { dbCurPrice, dbBPrice, szYDiffSign, dbYDiffPrice, dbChgRate } = contractData;

    // 마지막 체결가
    state.전일가 = dbBPrice;
    state.현재가 = dbCurPrice;
    state.전일대비기호 = szYDiffSign;
    state.전일대비 = dbYDiffPrice;
    state.등락률 = dbChgRate;
}

const refreshOrderCount = (eventType = '' as string) => {
    /*
    // 계정 비번 확인
    if (!this.checkAccPwd()) {
        return;
    }
    */

    const futures = state.futures;
    const tradingIdx = state.tradingIdx;

    store.AccountState.G_Future_Code = state.futures;
    store.DrawPossibleOrder();

    const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    const oppTradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
    const orderLeft = store.AccountState.iOrderMax[futures][tradeType];
    state.orderLeft = orderLeft;
    // 체결의 청산가능
    const settleAmount = store.GetClientTotalOppositeAmount(futures, oppTradeType);
    state.settleLeft = settleAmount;

    // 전체 체결/미체결
    const oppAmount = store.GetClientTotalOppositeAmount(futures);
    state.leftAmount = store.GetClientLeftAmountCounts(futures);
    state.oppositeAmount = oppAmount;

    if(eventType !== ''){
        if(eventType === 'orderLeft'){
            //신규(주문)
            state.amount = state.orderLeft.toString();
        } else if(eventType === 'settleLeft'){
            //청산
            state.amount = state.settleLeft.toString();
        }
    }
}

const onActionPress = (bCancel = false) => {
    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }

    const price = state.price;
    const amount = state.amount;
    const orderLeft = state.orderLeft;
    const tradingIdx = state.tradingIdx;

    // const priceType = state.pickPriceValue;
    const orderPriceType = state.selectedPriceType;

    /*
    if (tradingIdx === Trade_Stoploss) {
        const szMsg = '곧 지원할 예정입니다.';
        SystemStore.setVisibleDialog({ message: szMsg, title: 'S/L 주문 실패' });
        return;
    }
    */

    const futures = store.GetFuturesNExchangeable(state.futures, true);
    if (futures >= Common.G_FUTURES_MAX) {
        // 원래 종목 값이 들어간다.
        store.TimeMsg(state.futures);
        return;
    }

    const info = TradingInfo[tradingIdx];

    // 주문수량 확인
    const orderAmount = parseInt(amount);

    const orderPrice = parseFloat(price);
    if (orderPriceType === Common.G_PRICE_APPOINT && (orderPrice <= 0 || isNaN(orderPrice))) {
        const szMsg = '가격을 확인해 주세요.';
        store.setVisibleDialog({ message: szMsg, title: info.name });
        return;
    }

    const orderMethod = Common.C_ORDERMETHOD_MobileOverall;

    if (tradingIdx === Trade_Correct) {
        const tradeType = state.orgTradeType;
        
        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 주문 정정 (가격 정정)
        const orgOrderNo = parseInt(state.orgOrderNo);
        if (isNaN(orgOrderNo) || !orgOrderNo) {
            const szMsg = '원주문번호를 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 정정인경우 가격이 같으면 정정이 안된다.
        if (!bCancel) {
            if ((state.price === state.orgPrice)) {
                const szMsg = '정정 주문가격이 원 주문가격과 같습니다.';
                store.setVisibleDialog({ message: szMsg, title: info.name });
                return;
            }
        }

        const callback = () => {
            const modifyMethod = bCancel ? Common.C_ORDERMODIFY_Cancel : Common.C_ORDERMODIFY_ModifyPrice;

            UserLog.modifyOrder(futures, tradeType, orderPrice, orderAmount, modifyMethod, orderMethod, orgOrderNo);
            NetworkService.send.modifyOrderReq({
                futures: futures, 
                tradeType: tradeType,
                modifyMethod: modifyMethod, 
                orderIdx: orgOrderNo,
                orderPrice: orderPrice, 
                orderAmount: orderAmount, 
                orderMethod: orderMethod, 
            });
        }

        const szModify = bCancel ? '취소' : '정정';
        const szMsg = `주문을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `주문 ${szModify}`, okCancel: true, callback });

    } else if (tradingIdx === Trade_Stoploss) {
        // S/L 집행/취소
        const oppOrder = state.oppOrder;

        // 주문 확인
        if (!oppOrder) {
            const szMsg = '체결된 주문을 선택해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        const _profitTicks = parseInt(state.profitTicks);
        const _lossTicks = parseInt(state.lossTicks);
        const profitTicks = isNaN(_profitTicks) || _profitTicks < 0 ? 0 : _profitTicks;
        const lossTicks = isNaN(_lossTicks) || _lossTicks < 0 ? 0 : _lossTicks;

        const { iFutures, iTradeType, iOrderIdx, iOppositAmount, iStopLossLogIdx, } = oppOrder;

        // 취소가 아닌경우
        if (bCancel) {
        if (iStopLossLogIdx === Common.INDEX_NONE) {
            const szMsg = '예약된 S/L이 없습니다. S/L집행만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        } else {
        if (iStopLossLogIdx >= 0) {
            const szMsg = '예약된 S/L이 있습니다. S/L취소만 가능합니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 익절/손절 확인
        if (profitTicks === 0 && lossTicks === 0) {
            const szMsg = '이익틱 또는 손절틱을 설정해주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        // 익절/손절 수정 확인
        if (profitTicks === oppOrder.iProfitTicks && lossTicks === oppOrder.iLossTicks) {
            const szMsg = '이익틱, 손절틱이 같아 수정할 수 없습니다.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        }

        // S/L이 사용되는 매매구분, stoploss 로그용.
        // const slTradeType = (iTradeType === Common.G_TRADE_SELL) ? Common.G_TRADE_BUY : Common.G_TRADE_SELL;
        const iSLReserved = bCancel ? Common.eSLReserved_Cancel : iStopLossLogIdx === Common.INDEX_NONE ?  Common.eSLReserved_Request : Common.eSLReserved_Modify;
        const szModify = iSLReserved === Common.eSLReserved_Request ? '집행' : iSLReserved === Common.eSLReserved_Modify ? '수정' : '취소';

        const callback = () => {
            const useStop = profitTicks > 0;
            const useLoss = lossTicks > 0;
            const szExMessage = `S/L ${szModify}`;
            UserLog.stopLoss(iFutures, orderPrice, useStop, profitTicks, useLoss, lossTicks, szExMessage, iStopLossLogIdx, bCancel ? 1 : 0 );
            NetworkService.send.stopLossReservedReq({
                iSLReserved, 
                iOrderIdx,
                iFutures, 
                iTradeType,
                iOppositAmount, 
                iProfitTicks: profitTicks, 
                iLossTicks: lossTicks, 
            });
        }

        const szMsg = `S/L을 ${szModify}하시겠습니까?`;
        store.setVisibleDialog({ message: szMsg, title: `S/L ${szModify}`, okCancel: true, callback });

    } else {
        // 매수/매도
        const tradeType = tradingIdx === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;

        // 주문수량 확인
        if (orderAmount === 0 || isNaN(orderAmount)) {
            const szMsg = '주문 수량을 확인해 주세요.';
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }
        if (orderAmount > orderLeft) {
            const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        /*
        const curPrice = store.MasterState.dbCurPrice[futures];
        // 지정가인데 시장가로 넣어야되는 경우 변경
        if (orderPriceType === Common.G_PRICE_APPOINT) {
        // 바로 체결될 지정가는 시장가로 넣는다.
        if ((tradeType === Common.G_TRADE_SELL && orderPrice <= curPrice) || (tradeType === Common.G_TRADE_BUY && orderPrice >= curPrice)) {
            orderPriceType = Common.G_PRICE_MARKET;
            if (store.debugLog >= Constant.DebugLevel_Minimal)
                console.log(`지정가를 시장가로 넣는다. ${Common.g_szFuturesNameMini[futures]}, ${Common.g_szTradeTypeName[tradeType]}, 주문가격:${orderPrice}, 현재가:${curPrice}`);
        }
        }
        */

        // 매도/매수 주문
        UserLog.order(futures, tradeType, orderPriceType, orderPrice, orderAmount, orderMethod);
        NetworkService.send.registOrderReq({
            futures: futures, 
            tradeType: tradeType, 
            priceType: orderPriceType, 
            orderPrice: orderPrice, 
            orderAmount: orderAmount, 
            orderMethod: orderMethod, 
        });
    }
}

const onActionMarketTrade = (param: any) => {

    // 계정 비번 확인
    if (!checkAccPwd()) {
        return;
    }
    
    const symbol = param.symbol;
    const paramTradeType = param.tradeType;
    const tradeType = paramTradeType === Trade_Sell ? Common.G_TRADE_SELL : Common.G_TRADE_BUY;
    const orderAmount = param.orderAmount;
    
    const futures = state.pickFuturesItems.find((p: any) => p.label === symbol).futures;
    const info = TradingInfo[paramTradeType];
    const priceType = Common.G_PRICE_MARKET;
    const orderMethod = Common.C_ORDERMETHOD_MobileOverall;
    
    if(futures){
        const orderLeft = store.AccountState.iOrderMax[futures][tradeType]; //그래프로 선택된 종목의 구매가능 수량

        if (orderAmount > orderLeft) {
            const szMsg = `주문가능 수량을 초과했습니다.\n현재 주문 가능수량은 (${orderLeft})개 입니다.`;
            store.setVisibleDialog({ message: szMsg, title: info.name });
            return;
        }

        // 매도/매수 주문
        UserLog.order(futures, tradeType, priceType, 0, 1, orderMethod);
        NetworkService.send.registOrderReq({
            futures: futures, 
            tradeType: tradeType, 
            priceType: priceType, 
            orderPrice: 0, 
            orderAmount: orderAmount, 
            orderMethod: orderMethod, 
        });
    }

    // 챠트 팝업을 닫을 경우
    // state.initIsShowPopupChart = false;
}

// 미체결 버튼
const onLeftPress = () => {
    // 미체결 리스트를 얻는다.
    const leftOrders = store.GetClientLeftList();
    //const leftOrders = TEST_ORDERS;

    if (!leftOrders || leftOrders.length === 0) {
        const szMsg = '미체결 주문이 없습니다.';
        store.setVisibleDialog({ message: szMsg, title: '알림' });
        return;
    }

    state.leftOrders = leftOrders;
    // state.leftSelected = -1;
    // state.visibleDialog = true;
    state.isShowPopupLeftOrders = true;
}

// 미체결 선택(주문 정정)
const onCorrectSelectedPress = (params: any) => {
    const leftOrders = state.leftOrders ;
    const leftSelected = params.selectedIdx;
    const selectedLeftOrder = params.selectedOrder;

    if (leftSelected >= 0 && leftSelected < leftOrders.length) {
        state.amount = `${selectedLeftOrder.iLeftAmount}`;
        const price = Utils.PriceFormatStr(selectedLeftOrder.dbOrderPrice, selectedLeftOrder.iFutures);
        state.price = price;
        state.orgPrice = price;
        state.orgTradeType = selectedLeftOrder.iTradeType;
        state.orgOrderNo = `${selectedLeftOrder.iOrderIdx}`;
    }

    state.isShowPopupLeftOrders = false;
}

const onCancelCorrect = () => {
    /*
    const leftSelected = -1;
    this.setState({ leftSelected });
    this.onFuturesChange(this.futures);
    */
    const bCancel = true;
    onActionPress(bCancel);
}

const stockSearch = () => {
    state.isShowPopupStockSearch = true;
    document.body.style.overflow = "hidden";
}

const getQuoteDataColor = (type: string, quote: any) => {
    if(type === 'count'){
        let color = Theme.mts.slate;

        if (quote.szCGubun === '+') {
            color = Theme.mts.up;
        } else if (quote.szCGubun === '-') {
            color = Theme.mts.down;
        }
        return color;
    } else if(type === 'price'){
        let priceColor = Theme.mts.slate;
        if (quote.dbCurPrice > quote.dbBPrice) {
            priceColor = Theme.mts.up;
        } else {
            priceColor = Theme.mts.down;
        }
        return priceColor;
    }
}

const viewChart = () => {
    state.initIsShowPopupChart = true;
    document.body.style.overflow = "hidden";
}

const close = (popupType: string) => {
    switch (popupType) {
        case "PopupStockSearch":
            document.body.style.overflow = "auto";
            state.isShowPopupStockSearch = false;
            break;
        case "PopupChart":
            state.initIsShowPopupChart = false;
            document.body.style.overflow = "auto";
            break;
        case "PopupNotice":
            state.initShowNoticePopup = false;
            document.body.style.overflow = "auto";
            break;
        case "LeverageSetting":
            state.isShowLeverageSetting = false;
            document.body.style.overflow = "auto";
            break;
    }
}

const resizeEnded = () => {
    if(state.resizeTimer){
        clearTimeout(state.resizeTimer);
    }
    state.resizeTimer = setTimeout(()=>{
        resizeHandler()
    }, 500);
}

const resizeHandler = () => {
    var currWinWidth = window.innerWidth;       //resize 후 브라우저 가로 길이
    var currWinHeight = window.innerHeight;     //resize 후 브라우저 세로 길이
    if(currWinWidth > 700){
        state.popupHeight = currWinHeight - 168;
        state.bottomHeight = currWinHeight - 449;
        state.tableHeight = state.bottomHeight - 110;
    } else if(currWinWidth > 600) {
        state.popupHeight = currWinHeight - 168;
        state.bottomHeight = currWinHeight - 399;
        state.tableHeight = state.bottomHeight - 113;
    } else if(currWinWidth > 500) {
        state.popupHeight = currWinHeight - 128;
        state.bottomHeight = currWinHeight - 345;
        state.tableHeight = state.bottomHeight - 77;
    } else if(currWinWidth > 375) {
        state.popupHeight = currWinHeight - 140;
        state.bottomHeight = currWinHeight - 302;
        state.tableHeight = state.bottomHeight - 55;
    } else if(currWinWidth > 360) {
        state.popupHeight = currWinHeight - 118;
        state.bottomHeight = currWinHeight - 290;
        state.tableHeight = state.bottomHeight- 45;
    } else {
        state.popupHeight = currWinHeight - 118;
        state.bottomHeight = currWinHeight - 290;
        state.tableHeight = state.bottomHeight- 45;
    }
}

const openLeverage = () => {
    state.isShowLeverageSetting = true;
    document.body.style.overflow = "hidden";
}

return (_ctx: any,_cache: any) => {
  const _component_PopupLeftOrders = _resolveComponent("PopupLeftOrders")!
  const _component_PcTradeTable = _resolveComponent("PcTradeTable")!
  const _component_PopupStockSearch = _resolveComponent("PopupStockSearch")!
  const _component_PopupChart = _resolveComponent("PopupChart")!
  const _component_PopupNotice = _resolveComponent("PopupNotice")!
  const _component_LeverageSetting = _resolveComponent("LeverageSetting")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  class: "drop-sort",
                  type: "checkbox",
                  id: "dropPageCount",
                  name: "dropPageCount",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(state).pickFuturesOpen) = $event))
                }, null, 512), [
                  [_vModelCheckbox, _unref(state).pickFuturesOpen]
                ]),
                _createElementVNode("label", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", {
                      class: "out",
                      innerHTML: _unref(state).pickFuturesDesc
                    }, null, 8, _hoisted_9),
                    _createElementVNode("i", {
                      class: _normalizeClass(["icon-down", [{ on: _unref(state).pickFuturesOpen }]])
                    }, null, 2)
                  ])
                ]),
                (_unref(state).pickFuturesValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).pickFuturesItems, (item, index) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: index,
                          onClick: ($event: any) => (onPickFuturesChangeValue(item)),
                          class: _normalizeClass(["text", { active: item.value === _unref(state).pickFuturesValue }])
                        }, [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(item.label), 1),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(item.desc), 1)
                        ], 10, _hoisted_11))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("button", {
            class: "search",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (stockSearch()))
          }, _cache[41] || (_cache[41] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 18 18",
              width: "26",
              height: "26",
              fill: "white"
            }, [
              _createElementVNode("path", {
                fill: "white",
                d: "M3.5 8a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM8 2a6 6 0 1 0 3.65 10.76l3.58 3.58 1.06-1.06-3.57-3.57A6 6 0 0 0 8 2Z"
              })
            ], -1)
          ])),
          _createElementVNode("button", {
            class: "chart-btn",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (viewChart()))
          }, " 차트 ")
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("h1", {
              style: _normalizeStyle({color:_unref(state).priceColor})
            }, _toDisplayString(Utils.PriceFormatStr(_unref(state).현재가, _unref(state).futures)), 5),
            _createElementVNode("div", {
              class: "flex",
              style: _normalizeStyle({color:_unref(state).diffColor})
            }, [
              _createTextVNode(_toDisplayString(_unref(XingAPIService).GetSignData(_unref(state).전일대비기호)) + " ", 1),
              _createElementVNode("p", {
                style: _normalizeStyle({color:_unref(state).diffColor})
              }, _toDisplayString(Utils.PriceFormatStr(_unref(state).전일대비, _unref(state).futures)), 5)
            ], 4),
            _createElementVNode("p", {
              style: _normalizeStyle({color:_unref(state).diffColor})
            }, _toDisplayString(_unref(state).등락률?.toFixed(2)) + "%", 5)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("p", null, _toDisplayString(_unref(state).pickAccDesc), 1),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("input", {
                type: "password",
                placeholder: "계좌비밀번호",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(state).password) = $event)),
                readonly: _unref(state).bAccPassed
              }, null, 8, _hoisted_18), [
                [_vModelText, _unref(state).password]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", {
              class: _normalizeClass(["col", { active1: _unref(state).tradingIdx === Trade_Buy }]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (onTabPress(Trade_Buy)))
            }, _cache[42] || (_cache[42] = [
              _createElementVNode("p", null, "매수", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col", { active2: _unref(state).tradingIdx === Trade_Sell }]),
              onClick: _cache[5] || (_cache[5] = ($event: any) => (onTabPress(Trade_Sell)))
            }, _cache[43] || (_cache[43] = [
              _createElementVNode("p", null, "매도", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col", { active3: _unref(state).tradingIdx === Trade_Stoploss }]),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (onTabPress(Trade_Stoploss)))
            }, _cache[44] || (_cache[44] = [
              _createElementVNode("p", null, "S/L예약주문", -1)
            ]), 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col", { active4: _unref(state).tradingIdx === Trade_Correct }]),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (onTabPress(Trade_Correct)))
            }, _cache[45] || (_cache[45] = [
              _createElementVNode("p", null, "정정/취소", -1)
            ]), 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", {
            class: "flex buy",
            style: _normalizeStyle({height: _unref(state).bottomHeight + 'px'})
          }, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", {
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (onQuoteTabPress(Quote_Call))),
                  class: _normalizeClass(["resize", { on: _unref(state).tabIdx === Quote_Call }])
                }, "호가", 2),
                _createElementVNode("div", {
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (onQuoteTabPress(Quote_Contract))),
                  class: _normalizeClass(["conclusion", { on: _unref(state).tabIdx === Quote_Contract }])
                }, "체결", 2)
              ]),
              _createElementVNode("div", {
                class: "table-scroll",
                style: _normalizeStyle({height: _unref(state).tableHeight + 'px'})
              }, [
                (_unref(state).tabIdx === Quote_Call)
                  ? (_openBlock(), _createElementBlock("table", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).sellData[0], (item, idx) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: _normalizeClass(["sell", 'tr' + idx]),
                          key: idx,
                          onClick: ($event: any) => (onPricePress(item.title))
                        }, [
                          (idx === 0)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 0,
                                style: _normalizeStyle(item.textColor)
                              }, _toDisplayString(item.title), 5))
                            : _createCommentVNode("", true),
                          (idx === 0)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 1,
                                class: "price-text",
                                style: _normalizeStyle(_unref(state).sellData[1][idx].textColor)
                              }, _toDisplayString(_unref(state).sellData[1][idx].title), 5))
                            : _createCommentVNode("", true),
                          (idx > 0)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 2,
                                style: _normalizeStyle({'border': '1px solid' + item.textColor.color}),
                                class: _normalizeClass({no: item.bCurrent !== true})
                              }, [
                                _createElementVNode("p", {
                                  style: _normalizeStyle(item.textColor)
                                }, [
                                  _createTextVNode(_toDisplayString(item.title) + " ", 1),
                                  _createElementVNode("span", null, _toDisplayString(item.changeRate), 1)
                                ], 4)
                              ], 6))
                            : _createCommentVNode("", true),
                          (idx > 0)
                            ? (_openBlock(), _createElementBlock("td", _hoisted_25, _toDisplayString(_unref(state).sellData[1][idx].title), 1))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_24))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).buyData[0], (item, idx) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          class: _normalizeClass(["purchase", 'tr' + idx]),
                          key: idx,
                          onClick: ($event: any) => (onPricePress(item.title))
                        }, [
                          (idx >= BUY_LINE_MAX)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 0,
                                style: _normalizeStyle(item.textColor)
                              }, _toDisplayString(item.title), 5))
                            : _createCommentVNode("", true),
                          (idx >= BUY_LINE_MAX)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 1,
                                class: "price-text",
                                style: _normalizeStyle(_unref(state).buyData[1][idx].textColor)
                              }, _toDisplayString(_unref(state).buyData[1][idx].title), 5))
                            : _createCommentVNode("", true),
                          (idx < BUY_LINE_MAX)
                            ? (_openBlock(), _createElementBlock("td", {
                                key: 2,
                                style: _normalizeStyle({'border': '1px solid' + item.textColor.color}),
                                class: _normalizeClass({no: item.bCurrent !== true})
                              }, [
                                _createElementVNode("p", {
                                  style: _normalizeStyle(item.textColor)
                                }, [
                                  _createTextVNode(_toDisplayString(item.title) + " ", 1),
                                  _createElementVNode("span", null, _toDisplayString(item.changeRate), 1)
                                ], 4)
                              ], 6))
                            : _createCommentVNode("", true),
                          (idx < BUY_LINE_MAX)
                            ? (_openBlock(), _createElementBlock("td", _hoisted_27, _toDisplayString(_unref(state).buyData[1][idx].title), 1))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_26))
                      }), 128))
                    ]))
                  : (_unref(state).tabIdx === Quote_Contract)
                    ? (_openBlock(), _createElementBlock("table", _hoisted_28, [
                        _createElementVNode("tr", _hoisted_29, [
                          _createElementVNode("td", _hoisted_30, [
                            _createElementVNode("div", _hoisted_31, [
                              _cache[46] || (_cache[46] = _createElementVNode("p", null, "시가", -1)),
                              _createElementVNode("p", {
                                class: "num",
                                style: _normalizeStyle({color: _unref(state).infoData.시가.color})
                              }, _toDisplayString(_unref(state).infoData.시가.value), 5)
                            ]),
                            _createElementVNode("div", _hoisted_32, [
                              _cache[47] || (_cache[47] = _createElementVNode("p", null, "고가", -1)),
                              _createElementVNode("p", {
                                class: "num",
                                style: _normalizeStyle({color: _unref(state).infoData.고가.color})
                              }, _toDisplayString(_unref(state).infoData.고가.value), 5)
                            ]),
                            _createElementVNode("div", _hoisted_33, [
                              _cache[48] || (_cache[48] = _createElementVNode("p", null, "저가", -1)),
                              _createElementVNode("p", {
                                class: "num",
                                style: _normalizeStyle({color: _unref(state).infoData.저가.color})
                              }, _toDisplayString(_unref(state).infoData.저가.value), 5)
                            ]),
                            _createElementVNode("div", _hoisted_34, [
                              _cache[49] || (_cache[49] = _createElementVNode("p", null, "거래량", -1)),
                              _createElementVNode("p", {
                                class: "num",
                                style: _normalizeStyle({color: _unref(state).infoData.거래량.color})
                              }, _toDisplayString(_unref(state).infoData.거래량.value), 5)
                            ])
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).quoteData.slice().reverse(), (quote, idx) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: idx,
                            class: _normalizeClass(["change", 'change' + idx])
                          }, [
                            _createElementVNode("td", _hoisted_35, [
                              _createElementVNode("p", {
                                style: _normalizeStyle({color: getQuoteDataColor('price', quote)})
                              }, _toDisplayString(Utils.PriceFormatStr(quote.dbCurPrice, quote.iFutures)), 5)
                            ]),
                            _createElementVNode("td", {
                              class: "conclusion",
                              style: _normalizeStyle({color: getQuoteDataColor('count', quote)})
                            }, _toDisplayString(quote.iTrdq), 5)
                          ], 2))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
              ], 4),
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("td", _hoisted_37, _toDisplayString(_unref(state).clock), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                (_unref(state).tradingIdx === Trade_Buy || _unref(state).tradingIdx === Trade_Sell)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                      _createElementVNode("div", _hoisted_41, [
                        _createElementVNode("div", _hoisted_42, [
                          _createElementVNode("label", _hoisted_43, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: Common.G_PRICE_APPOINT,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(state).selectedPriceType) = $event)),
                              onChange: onChangePriceType
                            }, null, 40, _hoisted_44), [
                              [_vModelRadio, _unref(state).selectedPriceType]
                            ]),
                            _cache[50] || (_cache[50] = _createTextVNode(" 지정가 "))
                          ]),
                          _createElementVNode("label", _hoisted_45, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: Common.G_PRICE_MARKET,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(state).selectedPriceType) = $event)),
                              onChange: onChangePriceType
                            }, null, 40, _hoisted_46), [
                              [_vModelRadio, _unref(state).selectedPriceType]
                            ]),
                            _cache[51] || (_cache[51] = _createTextVNode(" 시장가 "))
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, [
                          _cache[52] || (_cache[52] = _createElementVNode("p", { class: "category" }, "예탁금", -1)),
                          (_unref(state).isOversea === Common.eNation_Domestic)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_49, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmount)), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_50, _toDisplayString(_unref(toLocaleString)(_unref(state).depoAmountOversea)), 1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _cache[53] || (_cache[53] = _createElementVNode("p", null, "주문가능", -1)),
                        _createElementVNode("a", {
                          onClick: _cache[12] || (_cache[12] = ($event: any) => (refreshOrderCount('orderLeft')))
                        }, "신규(주문)"),
                        _createElementVNode("a", {
                          onClick: _cache[13] || (_cache[13] = ($event: any) => (refreshOrderCount('settleLeft')))
                        }, "청산")
                      ])
                    ]))
                  : (_unref(state).tradingIdx === Trade_Correct)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                        _withDirectives(_createElementVNode("input", {
                          class: "orgOrderNo",
                          type: "text",
                          placeholder: "주문번호",
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(state).orgOrderNo) = $event)),
                          readonly: ""
                        }, null, 512), [
                          [_vModelText, _unref(state).orgOrderNo]
                        ]),
                        _createElementVNode("p", {
                          onClick: _cache[15] || (_cache[15] = ($event: any) => (onLeftPress()))
                        }, "미체결")
                      ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_53, [
                  _cache[57] || (_cache[57] = _createElementVNode("p", null, "주문수량", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass(["typing-box", { gray : _unref(state).tradingIdx === Trade_Stoploss }])
                  }, [
                    (_unref(state).tradingIdx !== Trade_Stoploss && _unref(state).tradingIdx !== Trade_Correct)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _imports_0,
                          alt: "감소",
                          class: "minus",
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (onChangeAmount(-1)))
                        }))
                      : _createCommentVNode("", true),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(state).amount) = $event)),
                      readonly: _unref(state).tradingIdx === Trade_Stoploss || _unref(state).tradingIdx === Trade_Correct,
                      onKeyup: _cache[18] || (_cache[18] = ($event: any) => (onChangeAmount()))
                    }, null, 40, _hoisted_54), [
                      [_vModelText, _unref(state).amount]
                    ]),
                    (_unref(state).tradingIdx !== Trade_Stoploss && _unref(state).tradingIdx !== Trade_Correct)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 1,
                          src: _imports_1,
                          alt: "증가",
                          class: "plus",
                          onClick: _cache[19] || (_cache[19] = ($event: any) => (onChangeAmount(1)))
                        }))
                      : _createCommentVNode("", true)
                  ], 2),
                  _cache[58] || (_cache[58] = _createElementVNode("p", null, "주문가격", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass(["typing-box", { gray : _unref(state).tradingIdx === Trade_Stoploss }])
                  }, [
                    (_unref(state).tradingIdx !== Trade_Stoploss)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _imports_0,
                          alt: "감소",
                          class: "minus",
                          onClick: _cache[20] || (_cache[20] = ($event: any) => (onChangePrice(-1)))
                        }))
                      : _createCommentVNode("", true),
                    (Number(_unref(state).selectedPriceType) === Common.G_PRICE_MARKET && (_unref(state).tradingIdx === Trade_Buy || _unref(state).tradingIdx === Trade_Sell))
                      ? (_openBlock(), _createElementBlock("input", _hoisted_55))
                      : _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 2,
                          type: "text",
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(state).price) = $event)),
                          readonly: _unref(state).tradingIdx === Trade_Stoploss
                        }, null, 8, _hoisted_56)), [
                          [_vModelText, _unref(state).price]
                        ]),
                    (_unref(state).tradingIdx !== Trade_Stoploss)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 3,
                          src: _imports_1,
                          alt: "증가",
                          class: "plus",
                          onClick: _cache[22] || (_cache[22] = ($event: any) => (onChangePrice(1)))
                        }))
                      : _createCommentVNode("", true)
                  ], 2),
                  (_unref(state).tradingIdx === Trade_Stoploss)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                        _cache[56] || (_cache[56] = _createElementVNode("p", null, "청산조건(틱)", -1)),
                        _createElementVNode("div", _hoisted_58, [
                          _cache[54] || (_cache[54] = _createElementVNode("p", { class: "condition" }, "이익", -1)),
                          _createElementVNode("div", _hoisted_59, [
                            _createElementVNode("img", {
                              src: _imports_0,
                              alt: "감소",
                              class: "minus",
                              onClick: _cache[23] || (_cache[23] = ($event: any) => (onProfitAmount(-1)))
                            }),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              placeholder: "이익",
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(state).profitTicks) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(state).profitTicks]
                            ]),
                            _createElementVNode("img", {
                              src: _imports_1,
                              alt: "증가",
                              class: "plus",
                              onClick: _cache[25] || (_cache[25] = ($event: any) => (onProfitAmount(1)))
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_60, [
                          _cache[55] || (_cache[55] = _createElementVNode("p", { class: "condition" }, "손실", -1)),
                          _createElementVNode("div", _hoisted_61, [
                            _createElementVNode("img", {
                              src: _imports_0,
                              alt: "감소",
                              class: "minus",
                              onClick: _cache[26] || (_cache[26] = ($event: any) => (onLossAmount(-1)))
                            }),
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              placeholder: "손실",
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_unref(state).lossTicks) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(state).lossTicks]
                            ]),
                            _createElementVNode("img", {
                              src: _imports_1,
                              alt: "증가",
                              class: "plus",
                              onClick: _cache[28] || (_cache[28] = ($event: any) => (onLossAmount(1)))
                            })
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(store).SystemState._appMode === 2 && (_unref(state).tradingIdx === Trade_Buy || _unref(state).tradingIdx === Trade_Sell))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        onClick: openLeverage
                      }, " 레버리지[1 : " + _toDisplayString(_unref(state).isOversea === Common.eNation_Domestic ? _unref(store).AccountState.iLeverage : _unref(store).AccountState.iLeverageEuro) + "] ", 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_unref(state).tradingIdx === Trade_Buy)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["buttons", {'buyType':_unref(state).tradingIdx === Trade_Buy}])
                  }, [
                    _createElementVNode("button", {
                      class: "reset",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => (onPressInit()))
                    }, "초기화"),
                    _createElementVNode("button", {
                      class: "next",
                      onClick: _cache[30] || (_cache[30] = ($event: any) => (onActionPress())),
                      style: {"width":"calc(70% - 10px)"}
                    }, "매수")
                  ], 2))
                : (_unref(state).tradingIdx === Trade_Sell)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                      _createElementVNode("button", {
                        class: "reset sell",
                        onClick: _cache[31] || (_cache[31] = ($event: any) => (onPressInit()))
                      }, "초기화"),
                      _createElementVNode("button", {
                        class: "next sell",
                        onClick: _cache[32] || (_cache[32] = ($event: any) => (onActionPress())),
                        style: {"width":"calc(70% - 10px)"}
                      }, "매도")
                    ]))
                  : (_unref(state).tradingIdx === Trade_Stoploss)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                        _createElementVNode("button", {
                          class: "on",
                          onClick: _cache[33] || (_cache[33] = ($event: any) => (onActionPress()))
                        }, "S/L집행"),
                        _createElementVNode("button", {
                          class: "cancel",
                          onClick: _cache[34] || (_cache[34] = ($event: any) => (onCancelCorrect()))
                        }, "S/L취소")
                      ]))
                    : (_unref(state).tradingIdx === Trade_Correct)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                          _createElementVNode("button", {
                            class: "on",
                            onClick: _cache[35] || (_cache[35] = ($event: any) => (onActionPress()))
                          }, "정정주문"),
                          _createElementVNode("button", {
                            class: "cancel",
                            onClick: _cache[36] || (_cache[36] = ($event: any) => (onCancelCorrect()))
                          }, "취소주문")
                        ]))
                      : _createCommentVNode("", true)
            ])
          ], 4)
        ])
      ]),
      _createVNode(_component_PopupLeftOrders, {
        isShowPopup: _unref(state).isShowPopupLeftOrders,
        initLeftOrders: _unref(state).leftOrders,
        onOnCorrectSelectedPress: onCorrectSelectedPress
      }, null, 8, ["isShowPopup", "initLeftOrders"])
    ]),
    _createElementVNode("div", _hoisted_65, [
      _createElementVNode("div", _hoisted_66, [
        _createElementVNode("div", _hoisted_67, [
          _createElementVNode("div", _hoisted_68, [
            _createVNode(_component_PcTradeTable, {
              initBAccPAssed: _unref(state).bAccPassed
            }, null, 8, ["initBAccPAssed"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_PopupStockSearch, {
      isShowPopup: _unref(state).isShowPopupStockSearch,
      type: 'left',
      initFuturesItems: _unref(state).pickFuturesItems,
      onOnPickFuturesChangeValue: onPickFuturesChangeValue,
      onClose: _cache[37] || (_cache[37] = ($event: any) => (close('PopupStockSearch')))
    }, null, 8, ["isShowPopup", "initFuturesItems"]),
    (_unref(state).initIsShowPopupChart)
      ? (_openBlock(), _createBlock(_component_PopupChart, {
          key: 0,
          initShowPopup: _unref(state).initIsShowPopupChart,
          initFuturesItem: _unref(state).pickFuturesSelectedItem,
          initFutures: _unref(state).futures,
          type: _unref(state).popupChartType,
          popupHeight: _unref(state).popupHeight,
          initOrderLeft: _unref(state).orderLeft,
          initAccPassed: _unref(state).bAccPassed,
          onClose: _cache[38] || (_cache[38] = ($event: any) => (close('PopupChart'))),
          onOnActionMarketTrade: onActionMarketTrade
        }, null, 8, ["initShowPopup", "initFuturesItem", "initFutures", "type", "popupHeight", "initOrderLeft", "initAccPassed"]))
      : _createCommentVNode("", true),
    _createVNode(_component_PopupNotice, {
      initShowNotice: _unref(state).initShowNoticePopup,
      initTitle: SLNoticeTitle,
      initNoticeMessage: SLNoticeContent,
      onNoToday: onPressNotToday,
      onClose: _cache[39] || (_cache[39] = ($event: any) => (close('PopupNotice')))
    }, null, 8, ["initShowNotice"]),
    (_unref(state).isShowLeverageSetting)
      ? (_openBlock(), _createBlock(_component_LeverageSetting, {
          key: 1,
          isShowPopup: _unref(state).isShowLeverageSetting,
          type: 'left',
          onClose: _cache[40] || (_cache[40] = ($event: any) => (close('LeverageSetting')))
        }, null, 8, ["isShowPopup"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})