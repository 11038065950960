// // App info
export const AppVersion =  1;
export const Version = '0.0.1';
export const AppName = 'AppNameRequired';


// 글자 확대 축소
export const FONT_SIZE_DEFAULT = 1;
export const FONT_SIZE_BIG = 1.2;
export const globalFontScale = FONT_SIZE_DEFAULT;

// 디버그 로그 레벨
export const DebugLevel_No = 0;
export const DebugLevel_Minimal = 1;
export const DebugLevel_Normal = 2;
export const DebugLevel_Verbose = 3;
export const DebugLevel_All = 4;
export const DebugLevelName = [
    '',
    '디버그(최소)',
    '디버그(일반)',
    '디버그(많음)',
    '디비그(전체)',
];


// 레이아웃 지원
// 1. 모바일: 휴대폰 (갤럭시폴드 포함), 폴드는 변동 가능..
// 2. PC: PC, 태블릿
export const DEVICE_TYPE_MOBILE = 1;
export const DEVICE_TYPE_PC_TABLET = 2;


export const SOCKET_STATE_NONE = 0;
export const SOCKET_STATE_OPEN = 1;
export const SOCKET_STATE_ERROR = 2;
export const SOCKET_STATE_CLOSE = 3;

// 해당 시간내 채팅내역 복구
export const CHAT_RESTORE_MINUTE = 10;