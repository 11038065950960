import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '../../assets/images/pc/popup-close.png'


const _hoisted_1 = {
  key: 0,
  class: "popupPc"
}
const _hoisted_2 = { class: "popupPc-inner" }
const _hoisted_3 = { class: "Notice scroll-wrap" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "date" }

import useBaseComponent from "@/baseComponent";
import { EMFJS, RTFJS, WMFJS } from "rtf.js";
import iconv from "iconv-lite";
import * as NetConstants from "@/network/Constants";
import { NetworkService, Emit_Success, Emit_NoticeContent } from "@/network";


export default /*@__PURE__*/_defineComponent({
  __name: 'PcNotice',
  props: {
    initIsShowPopup: {
        type: Boolean,
        default: false,
    },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const { router, store, onMounted, onUnmounted, reactive, defineProps, defineEmits } = useBaseComponent();
const props = __props;


let state = reactive({
	initShowNotice: false,
    initNoticeTitle: "",
    initNoticeDate: "",
    initNoticeContent: "",

    selectedNoticeIdx: 0,

    // timerReqNotice: null as any,
    // regDate: [] as, any,
	viewType:'Detail'
});

onMounted(async () => {
    if (store.LoginPageState.loginState != 1) {
        router.push("/");
        return;
    }
    store.setLastViewPage("Notice");

    NetworkService.emitter.on(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.on(Emit_NoticeContent, event_NoticeContent);

    store.NoticeState.notices = [];
    if (store.LoginPageState.isPacketLoginAckReceived){
        NetworkService.send.noticeTitleReq();
    } 
});

onUnmounted(() => {
    NetworkService.emitter.off(Emit_Success, event_LoginSuccess);
    NetworkService.emitter.off(Emit_NoticeContent, event_NoticeContent);
    // if(state.timerReqNotice){
    //     clearInterval(state.timerReqNotice);
    // }
});

const event_LoginSuccess = (params: any) => {
    if (store.LoginPageState.loginState === 1 && store.LoginPageState.errorState !== 1) {
        store.NoticeState.notices = [];
        NetworkService.send.noticeTitleReq();
    }
};

const openNotice = (notice: any) => {
    // // 공지 팝업 띄우기 전 항상 이전 공지 내용을 초기화(달라질 수 있음)
    // store.removeNoticeContent(notice.iNoticeIdx);

    // state.initNoticeTitle = "[" + notice.szTypeName + "] " + notice.szTitle;
    state.initNoticeTitle = notice.szTitle;
    state.initNoticeDate = notice.szRegDate;
    state.selectedNoticeIdx = notice.iNoticeIdx;

    //웹소켓을 통해 공지 내용 요청
    NetworkService.send.noticeContentReq({ noticeIdx: notice.iNoticeIdx });

    // // state.initNoticeTitle = "[" + notice.szTypeName + "] " + notice.szTitle;
    // state.initNoticeTitle = notice.szTitle;
    // state.initNoticeDate = notice.szRegDate;

    // let timerCount = 0;

    // //요청한 공지 내용이 정상적으로 세팅되면 팝업을 띄움
    // state.timerReqNotice = setInterval(() => {
    //     timerCount++;
    //     //pinia 에 content 내용이 세팅된 상태라면
    //     const resNoticeContent = store.existNoticeCotent(notice.iNoticeIdx);
    //     // console.log(resNoticeContent);
    //     if (resNoticeContent.result) {
    //         //contentBuff 에 내용이 입력이 되었을 경우 -> RTF 변환 후 HTML 로 변환후 출력
    //         if (resNoticeContent.contentBuff !== null) {
    //             const rtfText = iconv.decode(
    //                 resNoticeContent.contentBuff,
    //                 NetConstants.NETWORK_STRING_ENCODE
    //             );
    //             const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
    //             const div = document.createElement("div");
    //             doc.render().then((htmlElements) => {
    //                 div.append(...htmlElements);
    //                 state.initNoticeContent = div.outerHTML;
    //                 state.initShowNotice = true;
    //                 document.body.style.overflow = "hidden";
    //             })
    //             .catch((error) => console.error(error));
    //         } else if (resNoticeContent.szContents !== "") {
    //             //szContent 에 내용이 입력된 경우 그대로 출력
    //             state.initNoticeContent = resNoticeContent.szContents;
    //             state.initShowNotice = true;
    //             document.body.style.overflow = "hidden";
    //         }
    //         clearInterval(state.timerReqNotice);
    //     }

    //     //20번 체크 후 내용이 세팅이 되지 않으면 팝업 생성 안함
    //     if (timerCount > 20) {
    //         clearInterval(state.timerReqNotice);
    //     }
    // }, 100);
};

const event_NoticeContent = () => {
    const resNoticeContent = store.existNoticeCotent(state.selectedNoticeIdx);
    // console.log(resNoticeContent);
    // console.log(resNoticeContent);
    if (resNoticeContent.result) {
        //contentBuff 에 내용이 입력이 되었을 경우 -> RTF 변환 후 HTML 로 변환후 출력
        if (resNoticeContent.contentBuff !== null) {
            const rtfText = iconv.decode(
                resNoticeContent.contentBuff,
                NetConstants.NETWORK_STRING_ENCODE
            );
            const doc = new RTFJS.Document(stringToArrayBuffer(rtfText), {});
            const div = document.createElement("div");
            doc.render().then((htmlElements) => {
                div.append(...htmlElements);
                state.initNoticeContent = div.outerHTML;
                state.initShowNotice = true;
                document.body.style.overflow = "hidden";
            })
            .catch((error) => console.error(error));
        } else if (resNoticeContent.szContents !== "") {
            //szContent 에 내용이 입력된 경우 그대로 출력
            state.initNoticeContent = resNoticeContent.szContents;
            state.initShowNotice = true;
            document.body.style.overflow = "hidden";
        }
        // clearInterval(state.timerReqNotice);
    }
}


const stringToArrayBuffer = (string: any) => {
    const buffer = new ArrayBuffer(string.length);
    const bufferView = new Uint8Array(buffer);
    for (let i = 0; i < string.length; i++) {
        bufferView[i] = string.charCodeAt(i);
    }
    return buffer;
};

const emit = __emit;

const close = () => { 
    emit("close"); 
};

const closePopupNotice = (popupType: any) => {
	switch (popupType) {
        case "PopupNotice":
            state.initShowNotice = false;
            document.body.style.overflow = "auto";
            state.initNoticeContent = "";
            state.initNoticeTitle = "";
            break;
    }
}



return (_ctx: any,_cache: any) => {
  const _component_PcPopupNotice = _resolveComponent("PcPopupNotice")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.initIsShowPopup)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", { class: "title-box flex-center-center" }, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text" }, "공지사항", -1)),
              _createElementVNode("img", {
                class: "close",
                src: _imports_0,
                alt: "",
                onClick: close
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("table", null, [
                _cache[3] || (_cache[3] = _createElementVNode("tr", null, [
                  _createElementVNode("th", { class: "col-2 left" }, "일자/시간"),
                  _createElementVNode("th", { class: "col-8 right" }, "공지사항")
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).NoticeState.notices, (notice) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    class: "scroll-wrap",
                    key: notice.iNoticeIdx,
                    onClick: ($event: any) => (openNotice(notice))
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("p", _hoisted_5, [
                        _createTextVNode(_toDisplayString(notice.szRegDate.split(" ")[0]), 1),
                        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(notice.szRegDate.split(" ")[1].substring(0, 5)), 1)
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("p", null, _toDisplayString(notice.szTitle), 1)
                    ])
                  ], 8, _hoisted_4))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_PcPopupNotice, {
      initShowNotice: _unref(state).initShowNotice,
      initTitle: _unref(state).initNoticeTitle,
      initNoticeDate: _unref(state).initNoticeDate,
      initNoticeMessage: _unref(state).initNoticeContent,
      viewType: _unref(state).viewType,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (closePopupNotice('PopupNotice')))
    }, null, 8, ["initShowNotice", "initTitle", "initNoticeDate", "initNoticeMessage", "viewType"])
  ], 64))
}
}

})