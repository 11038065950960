import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "right" }
const _hoisted_2 = { class: "stock-list-box designBox" }
const _hoisted_3 = { class: "table-box" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "item amount" }

import useBaseComponent from "@/baseComponent";
import * as Common from '@/constants/Common';
import * as Utils from '@/utils/Common';
import { toLocaleString } from '@/utils/number';
import { XingAPIService } from '@/network/XingAPIService';

import * as NetConstants from '@/network/Constants';
import moment from '@/utils/moment';
import {EMFJS, RTFJS, WMFJS} from 'rtf.js';
import iconv from 'iconv-lite';

const REFRESH_INTERVAL = 200;


export default /*@__PURE__*/_defineComponent({
  __name: 'PcStockList',
  props: {
    initIsOnEvent: {
        type: Boolean,
        default: false,
    },
},
  emits: ["reqChangeFutures"],
  setup(__props, { emit: __emit }) {

const { router, store, onMounted, onUnmounted, reactive, defineProps, route, defineEmits } = useBaseComponent();

RTFJS.loggingEnabled(false);
WMFJS.loggingEnabled(false);
EMFJS.loggingEnabled(false);

const props = __props;

let state = reactive({
	refreshing : false,
    refreshCount : 0,
    marketPrices: [] as any,
});

onMounted(()=>{
    const autoRefreshing = async () => {
        onRefresh();
        setTimeout(autoRefreshing, REFRESH_INTERVAL);
    };

    autoRefreshing();
})

// onUnmounted(()=> { 
// })

const onRefresh = async () => {
    if (!store.MasterState.isReadyRECV) {
        return;
    }

    //this.refreshing = true;

    let marketPrices = [];
    for (let futures = Common.G_FUTURES_DAY; futures < Common.G_FUTURES_MAX; futures++) {
        
        if (store.MarketState.bDrawMenu[futures]) {
            // // EURO FX 6월물, EURO FX 12월물
            const monthIdx = store.MarketState.szFutureName[futures]?.length - 4;
            const month = store.MarketState.szFutureName[futures]?.substring(monthIdx, monthIdx + 2);
            const yearIdx = store.MarketState.szFutureCode[futures]?.length - 2;
            const year = store.MarketState.szFutureCode[futures]?.substring(yearIdx);
            const _expiryDate = store.MarketState.szFutureExpireDate[futures];
            let expiryDay = _expiryDate;
            if (futures < Common.G_FUTURES_OVERSEAS) {
                expiryDay = `${_expiryDate.substring(2, 5)}${month}`;
            } else {
                expiryDay = `${year}-${month}`;
            }

            const _marketPrice = {
                종목: futures,
                이름: store.MarketState.szFutureName[futures], //Common.g_szFuturesName[futures],
                코드: store.MarketState.szFutureCode[futures],
                만기일: expiryDay,
                전일가: store.MasterState.dbBPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbBPrice[futures], futures),
                시가: store.MasterState.dbOpenPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbOpenPrice[futures], futures),
                고가: store.MasterState.dbHighPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbHighPrice[futures], futures),
                저가: store.MasterState.dbLowPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbLowPrice[futures], futures),
                현재가: store.MasterState.dbCurPrice[futures], //Utils.PriceFormatStr(store.MasterState.dbCurPrice[futures], futures),
                전일대비기호: store.MasterState.szYDiffSign[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                전일대비: store.MasterState.dbYDiffPrice[futures], //`${store.MasterState.szYDiffSign[futures]} ${Utils.PriceFormatStr(store.MasterState.dbYDiffPrice[futures], futures)}`,
                등락률: store.MasterState.dbChgRate[futures], //`${store.MasterState.dbChgRate[futures]?.toFixed(2)}%`,
                거래량: store.MasterState.totq[futures],
            };
            
            marketPrices.push(_marketPrice);
        }
    }
    state.marketPrices = marketPrices;
    state.refreshCount++;

    //this.refreshing = false;

}

const onChangeFutures = (code: any) => {
    if(props.initIsOnEvent){
        reqChangeFutures(code);
    }
}

const emit = __emit;

const reqChangeFutures = (code: any) => { 
    emit("reqChangeFutures", {code: code}); 
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-box" }, [
        _createElementVNode("h1", { class: "title" }, "실시간 선물시세")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"table-head flex-center\"><div class=\"item name bdrWhite\"><p>종목</p></div><div class=\"item live bdrWhite\"><p>현재가</p></div><div class=\"item before bdrWhite\"><p>대비</p></div><div class=\"item updown bdrWhite\"><p>등락률</p></div><div class=\"item amount\"><p>거래량</p></div></div>", 1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).marketPrices, (item, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "table-body flex-center",
            key: idx
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["item name", {'on-event' : props.initIsOnEvent}]),
              onClick: ($event: any) => (onChangeFutures(item.코드))
            }, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(item.이름), 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("span", null, _toDisplayString(item.코드) + "(" + _toDisplayString(item.만기일) + ")", 1)
              ])
            ], 10, _hoisted_4),
            _createElementVNode("div", {
              class: _normalizeClass(["item live", [{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]])
            }, [
              _createElementVNode("p", null, _toDisplayString(Utils.PriceFormatStr(item.현재가, item.종목)), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["item before", [{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]])
            }, [
              _createElementVNode("p", null, _toDisplayString(_unref(XingAPIService).GetSignData(item.전일대비기호)) + " " + _toDisplayString(Utils.PriceFormatStr(item.전일대비, item.종목)), 1)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["item updown", [{ up: Number(item.전일대비기호) === 1 || Number(item.전일대비기호) === 2}, {down: Number(item.전일대비기호) === 4 || Number(item.전일대비기호) === 5}]])
            }, [
              _createElementVNode("p", null, _toDisplayString(item.등락률?.toFixed(2)) + "%", 1)
            ], 2),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", null, _toDisplayString(_unref(toLocaleString)(item.거래량)), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})